import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { UserContext } from 'globalContext/UserContext';
import moment, { Moment } from 'moment';
import {
  timesheetsUrl,
  userProfileUrl,
  workTimeFullReportUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledDatePicker from 'components/ControlledDatePicker';
import { Checkbox } from 'components/Table/Table.css';
import { FullReport, FullReportDay } from 'components/Table/types';
import { AccountResponse } from 'modules/UserProfile/types';
import { dateFormatMoment, formatMonthsToTwoDigits } from 'utils/dateFormats';
import { mapISODurationToString } from 'utils/durationFunctions';
import { getFetch, patchFetch } from 'utils/fetchFunctions';
import { returnHourDate } from 'utils/formatters';
import { useToggleState } from 'utils/hooks/useToggleState';
import { DateData, DateRangeDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogContentWrapperFlex,
  DialogParagraph,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const DateRangeDialog = ({
  isDialogOpen,
  handleShowClicked,
  selectedUser,
  row,
  fetchData,
  date,
}: DateRangeDialogProps) => {
  const { control, handleSubmit, getValues } = useForm<DateData>();
  const { username } = useContext(UserContext);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOverwrite, toggleShouldOverwrite] = useToggleState(true);

  const getDates = (dateStart: Moment, dateEnd: Moment) => {
    let dateArray = [];
    while (dateStart.isSameOrBefore(dateEnd)) {
      dateArray.push(dateStart.format(dateFormatMoment));
      dateStart = dateStart.add(1, 'days');
    }
    return dateArray;
  };
  const getDays = (data: FullReport) => {
    return data?.days.map((entry: FullReportDay) => ({
      ...entry,
      dayId: entry.id,
      startTime: entry.startTime && returnHourDate(entry.startTime),
      endTime: entry.endTime && returnHourDate(entry.endTime),
      manHours: entry.manHours && mapISODurationToString(entry.manHours),
      breakTime:
        entry.breakTime &&
        returnHourDate(mapISODurationToString(entry.breakTime)),
      multiplicator: {
        label: entry.multiplicator,
        value: entry.multiplicator,
        name: intl.formatMessage({
          id: `MULTIPLICATOR.${entry.multiplicator}`,
        }),
      },
    }));
  };

  const convertToEndTime = (startTime: string) => {
    const entryTime = startTime.split(':');
    const hours = parseInt(entryTime[0]) + 8;
    const minutes = parseInt(entryTime[1]);
    if (minutes === 0) {
      return hours + ':00';
    } else if (minutes.toString().length === 1) {
      return hours + ':0' + minutes;
    }
    return hours + ':' + minutes;
  };

  const mappingDays = (
    datesArrayFiltered: string[],
    days: FullReportDay[],
    profile: AccountResponse,
  ) => {
    return datesArrayFiltered.map((date) => {
      const day = days.find((day: { date: string }) => day.date === date);
      if (!day) {
        return <></>;
      }
      return {
        breakTime: 'PT0S',
        client: { name: row.clientName, id: row.clientId },
        comment: day.comment,
        date: day.date,
        startTime: profile.startTime,
        id: day.id,
        multiplicator: 'STANDARD_100',
        project: { name: row.projectName, id: row.projectId },
        endTime: convertToEndTime(profile.startTime),
      };
    });
  };

  const filterAndPrepareDaysToSend = (
    datesArrayEnds: string[],
    days: any,
    profile: AccountResponse,
  ) => {
    const result = datesArrayEnds.filter((date) => {
      const day = days.find((day: { date: string }) => day.date === date);
      if (!day) {
        return <></>;
      }
      return !(
        day.dayOfWeek === 'SATURDAY' ||
        day.dayOfWeek === 'SUNDAY' ||
        day.holiday ||
        day.bankHoliday
      );
    });
    return mappingDays(result, days, profile);
  };

  const filterAndPrepareDaysToSendWithoutOverwriting = (
    datesArrayEnds: string[],
    days: any,
    profile: AccountResponse,
  ) => {
    const result = datesArrayEnds.filter((date) => {
      const day = days.find((day: { date: string }) => day.date === date);
      return !(
        day.dayOfWeek === 'SATURDAY' ||
        day.dayOfWeek === 'SUNDAY' ||
        day.holiday ||
        day.bankHoliday ||
        day.startTime !== null ||
        day.multiplicator.label === 'STANDARD_VACATION_0' ||
        day.multiplicator.label === 'OTHER_VACATION_0' ||
        day.multiplicator.label === 'MEDICAL_LEAVE_0'
      );
    });
    return mappingDays(result, days, profile);
  };

  if (!date) {
    return <></>;
  }
  const onSubmit = handleSubmit(async () => {
    setIsLoading(true);
    const data: FullReport = await getFetch({
      url: `${timesheetsUrl}/${
        selectedUser?.username || username
      }/${workTimeFullReportUrl}?yearMonth=${date.getFullYear()}-${formatMonthsToTwoDigits(
        date.getMonth() + 1,
      )}`,
    });

    const profile: AccountResponse = await getFetch({
      url: `${userProfileUrl}/${username}/profile`,
    });

    const days = getDays(data);
    const datesArrayEnds = getDates(
      moment(getValues().dateStart),
      moment(getValues().dateEnd),
    );

    const body = {
      days: shouldOverwrite
        ? filterAndPrepareDaysToSend(datesArrayEnds, days, profile)
        : filterAndPrepareDaysToSendWithoutOverwriting(
            datesArrayEnds,
            days,
            profile,
          ),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    await patchFetch({
      url: `${timesheetsUrl}/${username}/${workTimeFullReportUrl}`,
      intl,
      body: body,
      label: 'QUICK_REPORT.DATE_RANGE',
    });
    fetchData();

    handleShowClicked();
    setIsLoading(false);
  });

  const isDisabled =
    !moment(getValues().dateStart).isSameOrBefore(
      moment(getValues().dateEnd),
    ) || isLoading;

  const minDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const dateRangeStartDate = 1;
  const dateRangeEndDate = 15;

  const handleOnChange = () => {
    if (toggleShouldOverwrite) {
      toggleShouldOverwrite();
    }
  };

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isDialogOpen}
      onClose={handleShowClicked}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: `QUICK_REPORT.DIALOG_HEADER`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton aria-label="close" onClick={handleShowClicked}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledDatePicker
                name="dateStart"
                control={control}
                view="date"
                minDate={minDate}
                maxDate={maxDate}
                label={intl.formatMessage({
                  id: 'QUICK_REPORT.START_DATE',
                })}
                defaultValue={
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    dateRangeStartDate,
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledDatePicker
                name="dateEnd"
                control={control}
                view="date"
                minDate={minDate}
                maxDate={maxDate}
                label={intl.formatMessage({
                  id: 'QUICK_REPORT.END_DATE',
                })}
                defaultValue={
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    dateRangeEndDate,
                  )
                }
              />
            </GridItem>
            <DialogContentWrapperFlex>
              <Checkbox
                id="Overwrite"
                onChange={() => handleOnChange()}
                checked={shouldOverwrite}
              />
              <DialogParagraph>
                {intl.formatMessage({
                  id: 'WORK_TIME.CONFIRMATION_OVERWRITE_DIALOG.CONTENT',
                })}
              </DialogParagraph>
            </DialogContentWrapperFlex>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={handleShowClicked}
            size="large"
            color="secondary"
            type="submit"
            id="cancelButton"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            id="submitButton"
            onClick={onSubmit}
            size="large"
            type="submit"
            disabled={isDisabled}
          >
            {intl.formatMessage({
              id: `MODAL.BUTTON.SUBMIT`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default DateRangeDialog;
