import { SetStateAction } from 'react';
import {
  businessTripCurrenciesUrl,
  countryDietsUrl,
  userProfileUrl,
} from 'router/url';
import { getFetch } from 'utils/fetchFunctions';
import { CountryDiet } from './../types';
import { DEFAULT_CURRENCY } from './constants';
import { CurrenciesData, UserData } from './types';

export const fetchPersonalData = async (
  username: string,
  setPersonalData: (value: SetStateAction<UserData | undefined>) => void,
) => {
  if (!username) {
    return null;
  }
  const personalData = await getFetch({
    url: `${userProfileUrl}/${username}/info`,
  });

  const { name, surname, personalId } = personalData;

  const { contractType } = await getFetch({
    url: `${userProfileUrl}/${username}/profile`,
  });
  setPersonalData({ name, surname, personalId, contractType });
};

export const fetchCurrencies = async (
  setCurrenciesData: (value: SetStateAction<CurrenciesData[]>) => void,
) => {
  try {
    const currencies = await getFetch({
      url: `${businessTripCurrenciesUrl}`,
    });
    setCurrenciesData(
      currencies.map((currencyName: string, id: number) => {
        return {
          value: id,
          name: currencyName,
        };
      }),
    );
  } catch {
    setCurrenciesData(DEFAULT_CURRENCY);
  }
};

export const fetchCountriesDietsList = async (
  setCountriesDiets: (value: React.SetStateAction<CountryDiet[]>) => void,
) => {
  const diets = await getFetch({
    url: `${countryDietsUrl}`,
  });
  setCountriesDiets(diets);
};
