import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormHelperText from '@material-ui/core/FormHelperText';
import palette from 'assets/palette';
import { HeaderTooltipText, InformationIcon } from 'components/Table/Table.css';
import { ControllerSelectProps } from './types';
import { TooltipLower } from './ControlledCreatableSelect.css';

const ControlledCreatableSelect = <T extends object>({
  control,
  options,
  label,
  name,
  isDisabled,
  required,
  rules,
  helperText,
  errorMessage,
  defaultValue,
  menuPlacement = 'auto',
  isClearable = true,
  handleChange,
  errorMessageStyle,
  noOptionMessage,
  maxMenuHeight = 150,
  isTooltipEnabled,
  id,
  tooltipInfo,
}: ControllerSelectProps<T>) => {
  const intl = useIntl();

  const generateCustomStyles = () => {
    const getControlBorder = (isFocused: boolean) => {
      if (isFocused) {
        return `1px solid ${palette.ACTION}`;
      }
      return '1px solid #ddd';
    };

    const customStyles: StylesConfig = {
      control: (provided, state) => ({
        ...provided,
        minWidth: '150px',
        minHeight: 56,
        border: getControlBorder(state.isFocused),
        boxShadow: state.isFocused ? `0 0 0 1px ${palette.ACTION}` : 'none',
        '&:hover': {
          border: state.isDisabled
            ? `1px solid #ddd`
            : `1px solid ${palette.ACTION}`,
          boxShadow: state.isDisabled ? `none` : `0 0 0 1px ${palette.ACTION}`,
        },
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 11,
      }),
    };

    return customStyles;
  };

  const handleCustomSelectChange = useCallback(
    (e, onChange) => {
      onChange(e);
      handleChange && handleChange(e);
    },
    [handleChange],
  );

  return (
    <>
      {helperText && (
        <FormHelperText>
          {intl.formatMessage({ id: label })}
          {isTooltipEnabled && (
            <TooltipLower
              title={
                <HeaderTooltipText>
                  {intl.formatMessage({
                    id: tooltipInfo ?? '',
                  })}
                </HeaderTooltipText>
              }
              placement="top"
              style={{ marginBottom: '-3px' }}
            >
              <InformationIcon />
            </TooltipLower>
          )}
        </FormHelperText>
      )}

      <Controller
        rules={{ ...rules, required }}
        name={name}
        control={control}
        type={name}
        defaultValue={defaultValue || null}
        as={(props) => (
          <CreatableSelect
            {...props}
            isClearable={isClearable}
            options={options}
            styles={generateCustomStyles()}
            getOptionLabel={(option: any) => option.name ?? option.label}
            noOptionsMessage={() =>
              noOptionMessage ||
              intl.formatMessage({ id: 'TABLE_FILTER.NO_OPTION' })
            }
            placeholder={intl.formatMessage({ id: label })}
            isDisabled={isDisabled}
            maxMenuHeight={maxMenuHeight}
            menuPlacement={menuPlacement}
            onChange={(e) => handleCustomSelectChange(e, props.onChange)}
            inputId={id || name}
            components={isTooltipEnabled}
            menuShouldBlockScroll
            createOptionPosition="first"
            formatCreateLabel={(inputText) =>
              `${intl.formatMessage({ id: 'CREATABLE.ADD' })} "${inputText}"`
            }
          />
        )}
      />
      {errorMessage && (
        <FormHelperText error style={errorMessageStyle}>
          {intl.formatMessage({ id: errorMessage })}
        </FormHelperText>
      )}
    </>
  );
};

export default ControlledCreatableSelect;
