import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface DateContextInterface {
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
}

export const initialContextState: DateContextInterface = {
  date: null,
  setDate: () => {},
};

const DateContext = createContext<DateContextInterface>(initialContextState);
const DateContextProvider = DateContext.Provider;

interface Props {
  children: ReactNode;
}

const DateProvider = ({ children }: Props) => {
  const [date, setDate] = useState<Date | null>(null);

  return (
    <DateContextProvider value={{ date, setDate }}>
      {children}
    </DateContextProvider>
  );
};

DateProvider.displayName = 'DateProvider';

export { DateProvider, DateContext };
