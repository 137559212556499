import styled from 'styled-components';

export const AboutBox = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AboutContent = styled.div`
  width: 80%;
  margin: 20px auto;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

export const AboutSubTitle = styled.div`
  font-size: 22px;
  font-weight: bolder;
  margin-bottom: 10px;
`;
export const AboutLine = styled.div`
  font-size: 15px;
  margin-bottom: 3px;
`;

export const CompanyLogo = styled.img`
  width: 30px;
`;
