import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  InfoContainer,
  InputTitle,
  StepGroup,
  StepTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext';
import { getPersonalData } from './utils';

const PersonalData = () => {
  const intl = useIntl();
  const { state } = useContext(BenefitContext);

  return (
    <StepGroup container spacing={3} justifyStart>
      <StepTitle>
        {intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.PERSONAL_DATA' })}
      </StepTitle>
      {state.benefitsData &&
        getPersonalData(intl, state.benefitsData.employee)?.map(
          ({ content, title }) =>
            content ? (
              <InfoContainer item xs={6} key={title}>
                <InputTitle>{title}</InputTitle>
                <span>{content}</span>
              </InfoContainer>
            ) : null,
        )}
    </StepGroup>
  );
};

export default PersonalData;
