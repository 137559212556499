import { ProjectsData } from './renameKeys';

export const filterDropdownProjectData = ({
  projectsData,
  clientNameFromClient,
}: FilterProjectsDataProps) =>
  projectsData.filter((project) =>
    clientNameFromClient
      ? project.client?.clientName === clientNameFromClient
      : project,
  );

export const setClientsValue = ({
  setValue,
  projectNameFromProject,
  clientNameFromClient,
  clientNameFromProject,
  clientIdFromProject,
}: SetClientsValueProps) => {
  const isProjectSelected = projectNameFromProject && !clientNameFromClient;

  isProjectSelected &&
    setValue('client', {
      name: clientNameFromProject,
      clientName: clientNameFromProject,
      value: clientIdFromProject,
    });
};

export const getRenamedProjectsData = (projectsData: ProjectsData[]) => {
  const projectNamesArray = projectsData.map((data) => data.projectName);
  const duplicated = projectNamesArray.filter(
    (name, index) => projectNamesArray.indexOf(name) !== index,
  );

  return projectsData.map((data) => {
    if (duplicated.includes(data.projectName)) {
      return {
        ...data,
        name: `${data.projectName} (${data.client.clientName})`,
      };
    }
    return data;
  });
};

interface SetClientsValueProps {
  setValue: (name: string, value: Object | string, config?: Object) => void;
  projectNameFromProject?: string | null;
  clientNameFromClient?: string | null;
  clientNameFromProject?: string | null;
  clientIdFromProject?: number;
}
interface FilterProjectsDataProps {
  projectsData: ProjectsData[];
  clientNameFromClient?: string | null;
}
