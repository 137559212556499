import { useIntl } from 'react-intl';
import { Chip, LinearProgress } from '@material-ui/core';
import {
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
} from 'components/Table/Table.css';
import { dateFormatFrontend, formatDate } from 'utils/dateFormats';
import { UploadHistoryEntry } from '../../types';
import { TABLE_HEAD_DATA as head } from './consts';

interface Props {
  loading: boolean;
  data: UploadHistoryEntry[];
}

export const UploadHistoryTable = ({ loading, data }: Props) => {
  const intl = useIntl();

  const renderTableHeadRow = () =>
    head.map((headData, index) => (
      <TableCell
        align={headData.align}
        key={`${index} - ${headData.key}`}
        width={headData.width}
      >
        {intl.formatMessage({ id: headData.label })}
      </TableCell>
    ));

  return (
    <TableContainer component={Paper} $loading={loading}>
      {loading && <LinearProgress />}
      <TableComponent>
        <TableHeadComponent>
          <TableRow $main>{renderTableHeadRow()}</TableRow>
        </TableHeadComponent>
        <TableBody>
          {data?.map((entry) => (
            <TableRow key={entry.uploadDate}>
              <TableCell align="left">{entry.account.fullName}</TableCell>
              <TableCell align="left">
                {formatDate(entry.uploadDate, dateFormatFrontend)}
              </TableCell>
              <TableCell align="left">
                {formatDate(entry.endDate, dateFormatFrontend)}
              </TableCell>
              <TableCell align="left">{entry.filename}</TableCell>
              <TableCell align="left">
                <Chip label={entry.importStatus} color="secondary" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  );
};
