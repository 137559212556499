import { useIntl } from 'react-intl';
import ToolTipCell from 'components/ToolTipCell';

interface Props {
  text: string;
  tooltip: string;
  id: string;
}

const ToolTipBenefitCell = ({ text, tooltip, id }: Props) => {
  const intl = useIntl();

  return (
    <ToolTipCell
      id={id}
      text={intl.formatMessage({
        id: text,
      })}
      tooltip={intl.formatMessage({
        id: tooltip,
      })}
      styles={{
        wrapperStyles: { marginTop: '0', marginBottom: '0' },
        cellTooltipStyles: { justifyContent: 'center' },
      }}
    />
  );
};

export default ToolTipBenefitCell;
