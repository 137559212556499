import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { UserContext } from 'globalContext/UserContext';
import { userProfileUrl } from 'router/url';
import MenuBar from 'components/MenuBar';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { getFetch } from 'utils/fetchFunctions';
import useQuery from 'utils/hooks/useQuery';
import BenefitsTab from './Screens/Benefits';
import { BenefitProvider } from './Screens/Benefits/BenefitContext';
import GeneralTab from './Screens/General/GeneralTab';
import { VIEW_MODES } from './consts';
import { AccountResponse } from './types';

export const TAB_LABELS_FOR_B2B = [{ label: 'USER_PROFILE.GENERAL', key: 0 }];

export const TAB_LABELS = [{ label: 'USER_PROFILE.GENERAL', key: 0 }];

const UserProfile = () => {
  const query = useQuery();
  const [viewMode, setViewMode] = useState(VIEW_MODES.GENERAL);
  const [isB2B, setIsB2B] = useState(false);
  const { username } = useContext(UserContext);

  const tabFromQuery = useMemo(() => Number(query.get('tab')), [query]);

  const getContractTypeInfo = useCallback(async () => {
    if (!username) {
      return;
    }
    const data: AccountResponse = await getFetch({
      url: `${userProfileUrl}/${username}/profile`,
    });
    setIsB2B(data?.contractType === ContractTypes.B2B);
  }, [username]);

  useEffect(() => {
    getContractTypeInfo();
  }, [getContractTypeInfo]);

  useEffect(() => {
    if (tabFromQuery && tabFromQuery in VIEW_MODES) {
      setViewMode(tabFromQuery);
    }
  }, [tabFromQuery]);

  const renderTab = useCallback((viewMode: number) => {
    switch (viewMode) {
      case VIEW_MODES.GENERAL:
        return <GeneralTab setIsB2B={setIsB2B} />;
      case VIEW_MODES.BENEFITS:
        return (
          <BenefitProvider>
            <BenefitsTab />
          </BenefitProvider>
        );
      default:
        return <GeneralTab setIsB2B={setIsB2B} />;
    }
  }, []);

  return (
    <>
      <MenuBar
        value={viewMode}
        setValue={setViewMode}
        options={isB2B ? TAB_LABELS_FOR_B2B : TAB_LABELS}
      />
      <Box overflow="auto" padding="2rem" minHeight="100%">
        {renderTab(viewMode)}
      </Box>
    </>
  );
};

export default UserProfile;
