import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { GlobalContext } from 'globalContext/GlobalContext';
import { UserContext } from 'globalContext/UserContext';
import { businessTripsUrl } from 'router/url';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FilesUploadDialog from 'components/Dialog/FilesUploadDialog/FilesUploadDialog';
import { FileUploadOptions } from 'components/Dialog/FilesUploadDialog/types';
import EmptyDataRow from 'components/Table/EmptyDataRow';
import {
  DeleteIcon,
  EditIcon,
  HeaderTooltipText,
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableIconButton,
  TablePagination,
  TableRow,
} from 'components/Table/Table.css';
import { renderTableCellData } from 'components/Table/tableUtils';
import { TableBusinessTripsProps } from 'components/Table/types';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import { showVacationDates } from 'utils/dateFormats';
import {
  deleteFetch,
  getFetch,
  patchFetch,
  postFetch,
} from 'utils/fetchFunctions';
import { returnRoleInclusion } from 'utils/helpers';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import { BusinessTripsContext } from '../BusinessTripManagerContext/Context';
import { ActionKind as ActionKindBusinessTrip } from '../BusinessTripManagerContext/types';
import BusinessTripDialog from '../Dialog';
import { BusinessTripFormValues } from '../Dialog/types';
import { BusinessTrip, TripStatuses } from '../types';
import { preparePreviousData } from '../utils';
import BusinessTripDetails from './BusinessTripDetails';
import { SmallFileCounter, SmallUploadFileIcon } from '../BusinessTrips.css';

const BusinessTripsTable = ({
  fetchTrips,
  setRowsPerPage,
  setCurrentPage,
  withPagination = false,
  loading = false,
  currentPage = 0,
  pageCount = 1,
  limit = 10,
  head,
  data,
  isAccountPrivileged,
  isLeader,
  withNameColumn,
  instantlyChangeTripState,
  tripsChanged,
  showDescription,
  selectedId,
  renderIcon,
}: TableBusinessTripsProps) => {
  const { roles, username, teamLeader } = useContext(UserContext);

  const intl = useIntl();
  const [uploadedTripFiles, setUploadedTripFiles] = useState([]);
  const [selectedTripData, setSelectedTripData] = useState({
    username: '',
    id: -1,
    status: '',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showEditDialog, handleToggleEditDialog] = useToggleState();
  const [showUploadDialog, handleToggleUploadDialog] = useToggleState();
  const [displayReminder, setDisplayReminder] = useState(false);
  const [showConfirmationDialog, handleToggleConfirmationDialog] =
    useToggleState();

  const [previousData, setPreviousData] = useState<BusinessTripFormValues>();

  const [isEditOpen, setIsEditOpen] = useState(false);

  const { dispatch: businessTripDispatch } = useContext(BusinessTripsContext);

  const handleEditDialogOpen = useCallback(
    (entry: BusinessTrip) => {
      setIsEditOpen(true);
      const result = preparePreviousData(entry, intl);
      setPreviousData(result);
      businessTripDispatch({
        type: ActionKindBusinessTrip.SetFormState,
        payload: result,
      });
    },
    [businessTripDispatch, intl],
  );

  const isOnlyLeader = useMemo(() => {
    const isPrivileged = returnRoleInclusion(roles, [
      RoleTypes.RoleAccounting,
      RoleTypes.RoleAdministration,
    ]);
    return !isPrivileged && teamLeader;
  }, [roles, teamLeader]);

  const attachmentsVisibility = useCallback(
    (name: string) => (isOnlyLeader ? name === username : true),
    [isOnlyLeader, username],
  );

  const isEditAllowed = useCallback(
    (name: string, status: TripStatuses) =>
      name === username &&
      status !== TripStatuses.CANCELED &&
      status !== TripStatuses.SETTLED,
    [username],
  );

  const fetchTripFiles = useCallback(async (id: number) => {
    const fetchedFiles = await getFetch({
      url: `${businessTripsUrl}/${id}/files`,
    });
    setUploadedTripFiles(fetchedFiles);
  }, []);

  const handleFileUploadClick = useCallback(
    (tripId: number, username: string, status: string) => async () => {
      await fetchTripFiles(tripId);
      setSelectedTripData({ username, id: tripId, status: status });
      handleToggleUploadDialog();
    },
    [fetchTripFiles, handleToggleUploadDialog],
  );

  const getUrl = (uploadOption?: FileUploadOptions, id?: number) => {
    switch (uploadOption) {
      case 'ForMe':
        return `${businessTripsUrl}/${id}/files`;
      case 'AdminForAccountancy':
        return `${businessTripsUrl}/${id}/files-by-adm?added-for=accounting`;
      default:
        return `${businessTripsUrl}/${id}/files-by-adm?added-for=employees`;
    }
  };

  const sendFile = useCallback(
    async (file: File, uploadOption?: FileUploadOptions) => {
      const formFile = new FormData();
      formFile.append('file', file);
      const { id } = selectedTripData;

      if (!id) {
        return null;
      }
      const { ok } = await postFetch({
        url: getUrl(uploadOption, id),
        body: formFile,
        contentType: 'formdata',
        intl,
        label: 'ADD_FILES_TO_DAILYTIME',
      });
      if (!ok) {
        throw new Error();
      }
    },
    [selectedTripData, intl],
  );

  const deleteFile = useCallback(
    async (fileId: number) => {
      const { id } = selectedTripData;

      if (!id) {
        return null;
      }
      const { ok } = await deleteFetch({
        url: `${businessTripsUrl}/${id}/files/${fileId}`,
        intl,
        label: 'DELETE_FILES_FROM_DAILYTIME',
      });
      return ok;
    },
    [intl, selectedTripData],
  );

  const exportFile = (response: BlobPart, fileTitle: string) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileTitle);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = useCallback(
    async (fileId: number, fileName: string, tripId?: number) => {
      const { username, id } = selectedTripData;

      if ((!username || !id) && !tripId) {
        return null;
      }
      try {
        if (!localStorage.getItem('tpToken')) {
          return null;
        }
        const res = await fetch(
          `${businessTripsUrl}/${tripId ?? id}/files/${fileId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('tpToken')}`,
              Accept: '*/*',
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': 'attachment; filename="picture.png"',
            },
          },
        );

        const blob = await res.blob();
        exportFile(blob, fileName);
      } catch {
        return null;
      }
    },
    [selectedTripData],
  );

  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);

  const handleDeleteAttempt = useCallback(
    (id, name) => {
      setSelectedTripData({ username: name, id, status: '' });
      handleToggleConfirmationDialog();
    },
    [handleToggleConfirmationDialog],
  );
  const handleSubmitCancel = async (body: string) => {
    if (!selectedTripData.id) {
      return null;
    }
    setIsButtonDisabled(true);
    try {
      await patchFetch({
        url: `${businessTripsUrl}/${selectedTripData.id}/cancel`,
        intl,
        label: 'BUSINESS_TRIP_CANCEL',
        body: { cancelReason: body },
      });
      instantlyChangeTripState &&
        instantlyChangeTripState(selectedTripData.id, TripStatuses.CANCELED);

      handleToggleConfirmationDialog();
      setSelectedTripData({ username: '', id: -1, status: '' });
    } catch (e) {
      return null;
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const renderEditDialog = useCallback(() => {
    return (
      showEditDialog && (
        <BusinessTripDialog
          addChecked={showEditDialog}
          handleAddChanged={handleToggleEditDialog}
          editClicked
          tripsChanged={tripsChanged}
        />
      )
    );
  }, [handleToggleEditDialog, showEditDialog, tripsChanged]);

  const renderCellData = useCallback(
    (entry: BusinessTrip, index: number, key: keyof BusinessTrip) => {
      switch (key) {
        case 'client':
          return renderTableCellData(entry?.client?.clientName);
        case 'project':
          return renderTableCellData(entry?.project?.projectName);
        case 'name':
          return `${renderTableCellData(entry.name)} ${renderTableCellData(
            entry.surname,
          )}`;
        case 'status':
          return renderTableCellData(
            entry?.status
              ? intl.formatMessage({
                  id: `BUSINESS_TRIP.STATUS.${entry?.status}`,
                })
              : '',
          );
        case 'dates':
          return renderTableCellData(
            showVacationDates(entry.startTime, entry.endTime),
          );

        case 'details':
          return renderIcon(index);

        case 'actions':
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              {
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: 'BUSINESS_TRIP.TOOLTIP.CANCEL',
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <TableIconButton
                    onClick={() =>
                      handleDeleteAttempt(entry.id, entry.account.username)
                    }
                    disabled={
                      entry.status === TripStatuses.CANCELED ||
                      entry.status === TripStatuses.SETTLED
                    }
                  >
                    <DeleteIcon />
                  </TableIconButton>
                </Tooltip>
              }
              {
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: 'BUSINESS_TRIP.TOOLTIP.EDIT',
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <TableIconButton
                    id="editButton"
                    onClick={() => handleEditDialogOpen(entry)}
                    disabled={
                      !isEditAllowed(entry.account.username, entry.status)
                    }
                  >
                    <EditIcon />
                  </TableIconButton>
                </Tooltip>
              }
              {attachmentsVisibility(entry.account.username) && (
                <>
                  <Tooltip
                    title={
                      <HeaderTooltipText>
                        {intl.formatMessage({
                          id: 'BUSINESS_TRIP.TOOLTIP.UPLOAD_FILE',
                        })}
                      </HeaderTooltipText>
                    }
                    placement="top"
                  >
                    <TableIconButton
                      onClick={handleFileUploadClick(
                        entry.id,
                        entry.account.username,
                        entry.status,
                      )}
                      disabled={
                        entry.attachmentAmount === 0 &&
                        entry.status === TripStatuses.SETTLED
                      }
                    >
                      <SmallUploadFileIcon
                        style={
                          !entry.attachmentAmount ? { marginRight: '5px' } : {}
                        }
                      />
                    </TableIconButton>
                  </Tooltip>
                  {entry.attachmentAmount > 0 && (
                    <SmallFileCounter style={{ marginLeft: '-12px' }}>
                      {entry.attachmentAmount}
                    </SmallFileCounter>
                  )}
                </>
              )}
            </Box>
          );

        default:
          return renderTableCellData(entry[key]);
      }
    },
    [
      attachmentsVisibility,
      handleDeleteAttempt,
      handleEditDialogOpen,
      handleFileUploadClick,
      intl,
      isEditAllowed,
      renderIcon,
    ],
  );
  return (
    <>
      <TableContainer component={Paper} $loading={loading}>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${intl.formatMessage({
                id: 'TABLE.PAGINATION.OF_COUNT',
              })} ${count}`
            }
            rowsPerPageOptions={[]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
        <TableComponent>
          <TableLoadingWrapper loading={loading}>
            <>
              <TableHeadComponent>
                <TableRow $main>
                  {!loading &&
                    head.map(({ align, key, label }, index) =>
                      label === 'TABLE.HEAD.NAME' ? (
                        (isAccountPrivileged || isLeader) &&
                        withNameColumn && (
                          <TableCell
                            align={align}
                            key={`cell - ${index} - ${key}`}
                          >
                            {intl.formatMessage({ id: label })}
                          </TableCell>
                        )
                      ) : (
                        <TableCell
                          align={align}
                          key={`cell - ${index} - ${key}`}
                        >
                          {intl.formatMessage({ id: label })}
                        </TableCell>
                      ),
                    )}
                </TableRow>
              </TableHeadComponent>

              <TableBody>
                {!data?.length && !loading && (
                  <EmptyDataRow colSpan={head.length} />
                )}
                {data?.length
                  ? data.map((entry, index: number) => (
                      <>
                        <TableRow
                          key={`row - ${entry} - ${index}`}
                          index={index}
                          $selected={selectedId === index}
                        >
                          {head.map(({ key, label }, headIndex: number) =>
                            label === 'TABLE.HEAD.NAME' ? (
                              (isAccountPrivileged || isLeader) &&
                              withNameColumn && (
                                <TableCell
                                  align={
                                    head.length
                                      ? head[headIndex].align
                                      : undefined
                                  }
                                  key={`${entry}-${index}-${headIndex}-${currentLocale}`}
                                  component="th"
                                  scope="row"
                                >
                                  {renderCellData(entry, index, key)}
                                </TableCell>
                              )
                            ) : (
                              <TableCell
                                align={
                                  head.length
                                    ? head[headIndex].align
                                    : undefined
                                }
                                key={`${entry}-${index}-${headIndex}-${currentLocale}`}
                                component="th"
                                scope="row"
                              >
                                {renderCellData(entry, index, key)}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        {selectedId === index && (
                          <TableRow
                            key={`description - ${entry} - ${index}`}
                            $selected
                          >
                            <TableCell colSpan={head.length}>
                              <Grid
                                container
                                alignItems="center"
                                justify="center"
                              >
                                {showDescription && (
                                  <BusinessTripDetails
                                    data={entry}
                                    isAccountPrivileged={isAccountPrivileged}
                                    tripsChanged={tripsChanged}
                                    fetchTrips={fetchTrips}
                                    displayReminder={displayReminder}
                                    setDisplayReminder={setDisplayReminder}
                                    uploadedTripFiles={uploadedTripFiles}
                                    fetchTripFiles={fetchTripFiles}
                                    downloadFile={downloadFile}
                                  />
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))
                  : null}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${intl.formatMessage({
                id: 'TABLE.PAGINATION.OF_COUNT',
              })} ${count}`
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
      </TableContainer>
      {showConfirmationDialog && (
        <ConfirmationDialog
          addChecked={showConfirmationDialog}
          handleAddChanged={handleToggleConfirmationDialog}
          activateClicked
          handleSubmit={handleSubmitCancel}
          isButtonDisabled={isButtonDisabled}
          title={intl.formatMessage({
            id: `BUSINESS_TRIP.CANCEL_DIALOG_TITLE`,
          })}
          content={intl.formatMessage({
            id: `BUSINESS_TRIP.CANCEL_DIALOG_CONTENT`,
          })}
          withCancellingNote
        />
      )}
      {isEditOpen && (
        <BusinessTripDialog
          addChecked={isEditOpen}
          handleAddChanged={() => setIsEditOpen(false)}
          tripsChanged={tripsChanged}
          editClicked
          previousData={previousData}
        />
      )}
      <FilesUploadDialog
        fetchTrips={fetchTrips}
        files={[]}
        previouslyUploadedFiles={uploadedTripFiles}
        sendFile={sendFile}
        deleteFile={deleteFile}
        addChecked={showUploadDialog}
        handleAddChanged={handleToggleUploadDialog}
        filesDownloadable
        downloadFile={downloadFile}
        filesForBusinessTrip
        selectedTripData={selectedTripData}
        setDisplayReminder={setDisplayReminder}
      />
      {renderEditDialog()}
    </>
  );
};

export default BusinessTripsTable;
