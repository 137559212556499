import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import { UserContext } from 'globalContext/UserContext';
import ContainedButton from 'components/Button/ContainedButton';
import FilesUploadArea from 'components/FileUploadArea/FilesUploadArea';
import { Checkbox, TableIconButton } from 'components/Table/Table.css';
import { FetchedFile } from 'components/Table/types';
import { Paragraph } from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { TripStatuses } from 'modules/BusinessTrips/types';
import { getNameAndSurname } from 'utils/formatters';
import { groupFilesByUploader } from 'utils/groupFilesByUploader';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import ConfirmationDialog from '../ConfirmationDialog';
import { FilesUploadDialogProps, FileUploadOptions } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  DownloadFileIcon,
  GridItem,
  GridWrapper,
} from '../Dialog.css';
import {
  CheckBoxesFileUpload,
  DeleteFileIcon,
  FileTile,
  GridLoaderItem,
  PreviouslyUploadedSubheading,
} from './FilesUploadDialog.css';

const FilesUploadDialog = ({
  fetchTrips,
  addChecked,
  handleAddChanged,
  files,
  previouslyUploadedFiles,
  setFiles,
  sendFile,
  deleteFile,
  canDeleteOnSubmit,
  filesDownloadable = false,
  downloadFile,
  filesForBusinessTrip = false,
  selectedTripData,
  setAreFilesSend,
  setDisplayReminder,
}: FilesUploadDialogProps) => {
  const intl = useIntl();
  const { username, roles } = useContext(UserContext);
  const nameAndSurname = getNameAndSurname(username);
  const [uploadAreaFiles, setUploadAreaFiles] = useState<File[]>(files);
  const [deletedFileId, setDeletedFileId] = useState(-1);
  const [uploadPending, setUploadLoading] = useState(false);
  const isAdministration =
    roles.includes(RoleTypes.RoleAdministration) ||
    roles.includes(RoleTypes.RoleDEVHR);
  const [fileUploadOption, setFileUploadOption] = useState<FileUploadOptions>(
    isAdministration ? 'AdminForEmployee' : 'ForMe',
  );
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState<
    FetchedFile[]
  >(previouslyUploadedFiles || []);
  const isPrivilidged =
    roles.includes(RoleTypes.RoleAdministration) ||
    roles.includes(RoleTypes.RoleDEVHR) ||
    roles.includes(RoleTypes.RoleAccounting);

  const [showConfirmationDialog, handleToggleConfirmationDialog] =
    useToggleState(false);

  const onSubmit = async () => {
    setFiles && setFiles(uploadAreaFiles);

    if (sendFile) {
      setUploadLoading(true);
      Promise.all(
        uploadAreaFiles.map((file) => {
          return sendFile(file, fileUploadOption);
        }),
      )
        .then(async () => {
          onClose();
          if (setAreFilesSend) {
            setAreFilesSend(true);
          }
          if (fetchTrips) {
            await fetchTrips();
          }
        })
        .finally(() => {
          setUploadLoading(false);
        });
    } else {
      onClose();
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (canDeleteOnSubmit) {
      if (JSON.stringify(files) === JSON.stringify(uploadAreaFiles)) {
        return true;
      }
    } else if (!uploadAreaFiles.length) {
      return true;
    }

    return false;
  }, [canDeleteOnSubmit, files, uploadAreaFiles]);

  const isDisabled =
    (selectedTripData &&
      roles.includes(RoleTypes.RoleAccounting) &&
      selectedTripData?.username !== username &&
      !roles.includes(RoleTypes.RoleAdministration) &&
      !roles.includes(RoleTypes.RoleDEVHR)) ||
    isSubmitDisabled ||
    selectedTripData?.status === TripStatuses.SETTLED ||
    uploadPending;

  const handleFilesChange = (files: File[]) => {
    setUploadAreaFiles(files);
  };

  const handleDeleteAttempt = (fileId: number) => {
    setDeletedFileId(fileId);
    handleToggleConfirmationDialog();
  };

  const handleDeleteSubmit = async () => {
    if (!deleteFile) {
      return;
    }

    try {
      const ok = await deleteFile(deletedFileId);
      if (ok) {
        setAlreadyUploadedFiles((uploadedFiles) =>
          uploadedFiles.filter((file) => file.id !== deletedFileId),
        );
      }
      if (fetchTrips) {
        await fetchTrips();
      }
    } catch (e) {
      handleToggleConfirmationDialog();
      return;
    }

    handleToggleConfirmationDialog();
  };

  const handleDownloadClick = (uploadedFile: FetchedFile) => {
    if (downloadFile) {
      downloadFile(uploadedFile.id, String(uploadedFile.originalFileName));
    }
  };

  const displayPreviousFile = (uploadedFile: FetchedFile) => {
    return (
      <FileTile key={`${uploadedFile.id}`}>
        {uploadedFile.originalFileName}
        <TableIconButton
          id="deleteFileButton"
          onClick={() => handleDeleteAttempt(uploadedFile.id)}
          disabled={selectedTripData?.status === TripStatuses.SETTLED}
        >
          <DeleteFileIcon />
        </TableIconButton>
        {filesDownloadable && (
          <DownloadFileIcon onClick={() => handleDownloadClick(uploadedFile)} />
        )}
      </FileTile>
    );
  };

  const renderAlreadyUploadedFiles = () => {
    return alreadyUploadedFiles?.length ? (
      <>
        <PreviouslyUploadedSubheading>
          {intl.formatMessage({ id: 'DROPZONE.PREVIOUSLY_UPLOADED' })}
        </PreviouslyUploadedSubheading>
        {alreadyUploadedFiles.map((uploadedFile: FetchedFile) =>
          displayPreviousFile(uploadedFile),
        )}
      </>
    ) : null;
  };

  const renderAlreadyUploadedFilesForBusinessTrip = () => {
    const groups = groupFilesByUploader(alreadyUploadedFiles, nameAndSurname);

    return alreadyUploadedFiles?.length ? (
      <>
        {groups.uploadedByMe?.length > 0 && (
          <>
            <PreviouslyUploadedSubheading>
              {intl.formatMessage({ id: 'DROPZONE.PREVIOUSLY_UPLOADED_BY_ME' })}
              :
            </PreviouslyUploadedSubheading>
            {groups.uploadedByMe.map((uploadedFile) =>
              displayPreviousFile(uploadedFile),
            )}
          </>
        )}
        {groups.uploadedByEmployee?.length > 0 && (
          <>
            <PreviouslyUploadedSubheading>
              {intl.formatMessage({
                id: 'DROPZONE.PREVIOUSLY_UPLOADED_BY_EMPLOYEE',
              })}
              :
            </PreviouslyUploadedSubheading>
            {groups.uploadedByEmployee.map((uploadedFile) =>
              displayPreviousFile(uploadedFile),
            )}
          </>
        )}
        {isPrivilidged && groups.uploadedByAdministration?.length > 0 && (
          <>
            <PreviouslyUploadedSubheading>
              {intl.formatMessage({
                id: 'DROPZONE.PREVIOUSLY_UPLOADED_BY_ADMINISTRATION',
              })}
              :
            </PreviouslyUploadedSubheading>
            {groups.uploadedByAdministration.map((uploadedFile) =>
              displayPreviousFile(uploadedFile),
            )}
          </>
        )}
        {groups.uploadedByAdministrationForEmployee?.length > 0 && (
          <>
            <PreviouslyUploadedSubheading>
              {intl.formatMessage({
                id: 'DROPZONE.PREVIOUSLY_UPLOADED_BY_ADMINISTRATION',
              })}
              :
            </PreviouslyUploadedSubheading>
            {groups.uploadedByAdministrationForEmployee.map(
              displayPreviousFile,
            )}
          </>
        )}
      </>
    ) : null;
  };

  useEffect(() => {
    previouslyUploadedFiles && setAlreadyUploadedFiles(previouslyUploadedFiles);
  }, [previouslyUploadedFiles]);

  const onClose = () => {
    if (setDisplayReminder) {
      setDisplayReminder(true);
    }
    handleAddChanged();
  };

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'sm'}
      open={addChecked}
      onClose={onClose}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {intl.formatMessage({ id: 'DROPZONE.TITLE' })}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton id="closeButton" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      {filesForBusinessTrip && isAdministration && (
        <CheckBoxesFileUpload>
          {selectedTripData?.username === username && (
            <>
              <Checkbox
                id="filesForMe"
                onChange={() => setFileUploadOption('ForMe')}
                checked={fileUploadOption === 'ForMe'}
              />
              <Paragraph>
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP_FILES.UPLOAD_FOR_YOURSELF',
                })}
              </Paragraph>
            </>
          )}
          <PreviouslyUploadedSubheading style={{ paddingLeft: '15px' }}>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP_FILES.UPLOAD_AS_ADMINISTRATION',
            })}
          </PreviouslyUploadedSubheading>
          <Checkbox
            id="filesAsAdministrationForEmployee"
            onChange={() => setFileUploadOption('AdminForEmployee')}
            checked={fileUploadOption === 'AdminForEmployee'}
          />
          <Paragraph>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP_FILES.UPLOAD_AS_ADMINISTRATION_FOR_EMPLOYEE',
            })}
          </Paragraph>
          <Checkbox
            id="filesAsAdministrationForAccountancy"
            onChange={() => setFileUploadOption('AdminForAccountancy')}
            checked={fileUploadOption === 'AdminForAccountancy'}
          />
          <Paragraph>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP_FILES.UPLOAD_AS_ADMINISTRATION_FOR_ACCOUNTANCY',
            })}
          </Paragraph>
        </CheckBoxesFileUpload>
      )}
      <DialogContentWrapper>
        <form
          encType="multipart/form-data"
          className="form"
          onSubmit={onSubmit}
        >
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            {selectedTripData?.status !== TripStatuses.SETTLED && (
              <GridItem xs={12} item>
                <FilesUploadArea
                  files={files}
                  handleFileChange={handleFilesChange}
                />
              </GridItem>
            )}
            {uploadPending && (
              <GridLoaderItem
                xs={12}
                justifyContent="center"
                alignItems="center"
                item
              >
                <CircularProgress size={100} />
              </GridLoaderItem>
            )}
            {filesForBusinessTrip
              ? renderAlreadyUploadedFilesForBusinessTrip()
              : renderAlreadyUploadedFiles()}
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={onClose}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="saveButton"
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
            disabled={isDisabled}
          >
            {intl.formatMessage({ id: 'DROPZONE.SUBMIT.LABEL' })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>

      <ConfirmationDialog
        addChecked={showConfirmationDialog}
        handleAddChanged={handleToggleConfirmationDialog}
        handleSubmit={handleDeleteSubmit}
        title={intl.formatMessage({
          id: 'FILES_DIALOG.DELETE_TITLE',
        })}
        content={intl.formatMessage({
          id: 'FILES_DIALOG.DELETE_CONTENT',
        })}
      />
    </DialogWrapper>
  );
};

export default FilesUploadDialog;
