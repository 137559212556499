import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledCheckbox from 'components/ControlledCheckbox';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import FilesUploadDialog from 'components/Dialog/FilesUploadDialog/FilesUploadDialog';
import {
  HeaderTooltipText,
  InformationIcon,
  TooltipWithMargin,
} from 'components/Table/Table.css';
import { BusinessTripsContext } from 'modules/BusinessTrips/BusinessTripManagerContext/Context';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { useToggleState } from 'utils/hooks/useToggleState';
import { HELPER_TEXT_MARGIN, INSURANCE_PROVIDERS } from '../constants';
import { InsuranceProviderTypes, TripSectionProps } from '../types';
import {
  renderErrorMessageForRequiredFields,
  renderSelectOptions,
} from '../utils';
import {
  GridForInsurance,
  GridLeftAlign,
  TripSectionContainer,
} from '../BusinessTripDialog.css';
import { RequiredFilesParagraph } from './../BusinessTripDialog.css';

const Other = ({
  control,
  errors,
  editClicked,
  files,
  setFiles,
  setNeedsClientsAcceptance,
  isAbroad,
}: TripSectionProps) => {
  const {
    businessTripsManagerState: {
      formState: {
        insuranceProvidedBy,
        neededAdvance,
        clientAcceptance,
        advanceDescription,
        otherInformation,
      },
    },
  } = useContext(BusinessTripsContext);

  const intl = useIntl();

  const [openFileUploadArea, handleFileUploadClick] = useToggleState(false);

  const currentIsAdvanceNeeded = useWatch({
    control,
    name: 'neededAdvance',
    defaultValue: neededAdvance,
  });

  const needsClientAcceptance = useWatch({
    control,
    name: 'clientAcceptance',
    defaultValue: clientAcceptance,
  });

  const getDefaultInsuranceValue = (
    editClicked?: boolean,
    isAbroad?: boolean,
  ) => {
    if (editClicked) {
      return insuranceProvidedBy;
    }
    return {
      value: isAbroad
        ? InsuranceProviderTypes.JIT_INSURANCE
        : InsuranceProviderTypes.NO_INSURANCE,
      name: intl.formatMessage({
        id: isAbroad
          ? 'BUSINESS_TRIP.JIT_INSURANCE'
          : 'BUSINESS_TRIP.NO_INSURANCE',
      }),
    };
  };

  useEffect(() => {
    if (setNeedsClientsAcceptance && needsClientAcceptance !== undefined) {
      setNeedsClientsAcceptance(needsClientAcceptance);

      if (!needsClientAcceptance) {
        setFiles && setFiles([]);
      }
    }
  }, [needsClientAcceptance, setFiles, setNeedsClientsAcceptance]);

  return (
    <TripSectionContainer container spacing={3}>
      <GridForInsurance xs={12} container>
        {!isAbroad && (
          <TooltipWithMargin
            title={
              <HeaderTooltipText>
                {intl.formatMessage({
                  id: 'INSURANCE.NO_INSURANCE.TOOLTIP',
                })}
              </HeaderTooltipText>
            }
            placement="top"
          >
            <InformationIcon $bigger />
          </TooltipWithMargin>
        )}
        <Grid xs={isAbroad ? 12 : 11} item>
          <ControllerSelect
            label="BUSINESS_TRIP.FORM.INSURANCE"
            name="insuranceProvidedBy"
            control={control}
            options={renderSelectOptions<InsuranceProviderTypes>(
              isAbroad
                ? INSURANCE_PROVIDERS.filter(
                    (el) => el.value !== 'NO_INSURANCE',
                  )
                : INSURANCE_PROVIDERS,
              intl,
            )}
            isDisabled={!isAbroad}
            helperText={editClicked}
            required
            isPadding={false}
            defaultValue={getDefaultInsuranceValue(editClicked, isAbroad)}
            errorMessage={
              errors?.insuranceProvidedBy &&
              renderErrorMessageForRequiredFields(intl)
            }
            errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
          />
        </Grid>
      </GridForInsurance>
      <Grid xs={12} item>
        <ControlledCheckbox
          name="neededAdvance"
          label="BUSINESS_TRIP.IS_ADVANCE_NEEDED"
          control={control}
          placement="end"
          defaultChecked={currentIsAdvanceNeeded}
        />
      </Grid>
      <Grid xs={12} item>
        {currentIsAdvanceNeeded ? (
          <>
            {'' /**don't remove this */}
            <ControlledTextField
              label="BUSINESS_TRIP.FORM.ADVANCE"
              name="advanceDescription"
              control={control}
              variant="outlined"
              isMulti
              required
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={advanceDescription}
              validate={!!errors?.advanceDescription}
              helperText={
                errors?.advanceDescription &&
                renderErrorMessageForRequiredFields(intl)
              }
            />
          </>
        ) : (
          <ControlledTextField
            label="BUSINESS_TRIP.FORM.ADVANCE"
            name="advanceDescription"
            control={control}
            variant="outlined"
            isMulti
            isDisabled
          />
        )}
      </Grid>
      <Grid xs={12} item>
        <ControlledCheckbox
          name="clientAcceptance"
          label="BUSINESS_TRIP.NEEDS_CLIENT_ACCEPTANCE"
          control={control}
          placement="end"
          disabled={editClicked}
          defaultChecked={needsClientAcceptance}
        />
      </Grid>
      {needsClientAcceptance && (
        <GridLeftAlign xs={5} item>
          {!!files?.length && (
            <>
              {intl.formatMessage({ id: 'DROPZONE.SELECTED_FILES' })}
              {files.map((entry, index) => (
                <p key={`filename-${index}`}>{`${index + 1}. ${entry.name}`}</p>
              ))}
            </>
          )}
          <ContainedButton
            id="manageUploadsButtonBusinessTrips"
            size="medium"
            onClick={handleFileUploadClick}
          >
            {intl.formatMessage({ id: 'DROPZONE.DIALOG_BUTTON' })}
          </ContainedButton>
          {files?.length === 0 && (
            <RequiredFilesParagraph>
              {intl.formatMessage({ id: 'DROPZONE.SELECTED_FILES_REQUIRED' })}
            </RequiredFilesParagraph>
          )}
        </GridLeftAlign>
      )}
      <Grid xs={12} item>
        <ControlledTextField
          label="BUSINESS_TRIP.OTHER"
          name="otherInformation"
          control={control}
          variant="outlined"
          rows={8}
          isMulti={true}
          maxLength={MAX_INPUT_LENGTH}
          defaultValue={otherInformation}
        />
      </Grid>
      {files && (
        <FilesUploadDialog
          files={files}
          setFiles={setFiles}
          addChecked={openFileUploadArea}
          handleAddChanged={handleFileUploadClick}
          canDeleteOnSubmit
        />
      )}
    </TripSectionContainer>
  );
};

export default Other;
