import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { ContainedButton } from 'components/Button';
import { DeleteIcon } from 'components/Table/Table.css';
import {
  DetailsDialogProps,
  GridElementTypes,
  HolidayStatus,
  SelectedHolidayGridContent,
} from '../types';
import { getDetailsDataGrid } from '../utils';
import {
  ButtonWrapper,
  CloseIconButton,
  CommentGrid,
  HeaderWrapper,
  InfoGrid,
  RejectContainedButton,
  StatusGrid,
  StyledDialog,
} from '../EmployeesHolidaysComponent.css';

const DetailsDialog = ({
  open,
  selectedHoliday,
  handleClose,
}: DetailsDialogProps) => {
  const intl = useIntl();
  const detailsDataGrid = getDetailsDataGrid(selectedHoliday);

  const renderGridComponent = (
    content: string | number | SelectedHolidayGridContent[],
    type: GridElementTypes,
  ): JSX.Element => {
    switch (type) {
      case GridElementTypes.Info:
        return <InfoGrid item>{content}</InfoGrid>;
      case GridElementTypes.Name:
        return (
          <InfoGrid item>
            {`${selectedHoliday.account.name} ${selectedHoliday.account.surname}`}
          </InfoGrid>
        );
      case GridElementTypes.Status:
        return (
          <StatusGrid item color={content as HolidayStatus}>
            {intl.formatMessage({
              id: `HOLIDAY_REQUEST_STATUS.${content}`,
            })}
          </StatusGrid>
        );
      case GridElementTypes.Type:
        return (
          <InfoGrid item>
            {intl.formatMessage({
              id: `HOLIDAY_REQUEST_TYPE.${content}`,
            })}
          </InfoGrid>
        );
      case GridElementTypes.Comment:
        return <CommentGrid item>{content}</CommentGrid>;
    }
  };

  return (
    <StyledDialog disableScrollLock={true} open={open} onClose={handleClose}>
      <HeaderWrapper>
        {intl.formatMessage({ id: 'EMPLOYEES_HOLIDAY.EMPLOYEES_REQUEST' })}
        <CloseIconButton onClick={handleClose}>
          <DeleteIcon />
        </CloseIconButton>
      </HeaderWrapper>
      <Grid container>
        {detailsDataGrid.map(({ label, content, type }) => (
          <Grid container item justify="space-between" key={label}>
            <Grid item>{intl.formatMessage({ id: label })}:</Grid>
            {content ? renderGridComponent(content, type) : <></>}
          </Grid>
        ))}
        <ButtonWrapper>
          <ContainedButton type="submit">
            {intl.formatMessage({ id: 'EMPLOYEES_HOLIDAY.APPROVE' })}
          </ContainedButton>
          <RejectContainedButton>
            {intl.formatMessage({ id: 'EMPLOYEES_HOLIDAY.REJECT' })}
          </RejectContainedButton>
        </ButtonWrapper>
      </Grid>
    </StyledDialog>
  );
};

export default DetailsDialog;
