import { IntlShape } from 'react-intl';

interface RowsProps {
  from: number;
  to: number;
  count: number;
  intl: IntlShape;
}

export const displayLabelRows = ({ from, to, count, intl }: RowsProps) => {
  return `${from} - ${to} ${intl.formatMessage({
    id: 'TABLE.PAGINATION.OF_COUNT',
  })} ${count}`;
};
