import { useCallback, useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Chip, Grid } from '@material-ui/core';
import DefaultButton from 'components/Button/DefaultButton';
import ControlledDateTimePicker from 'components/ControlledDateTimePicker';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { AddIconNoHover as AddIcon } from 'components/Table/Table.css';
import { BusinessTripsContext } from 'modules/BusinessTrips/BusinessTripManagerContext/Context';
import { ActionKind } from 'modules/BusinessTrips/BusinessTripManagerContext/types';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { validate } from 'utils/formValidation';
import { HELPER_TEXT_MARGIN } from '../constants';
import { DescriptionProps } from '../types';
import {
  checkNumberOfNights,
  renderErrorMessageForRequiredFields,
} from '../utils';
import { TripSectionContainer } from '../BusinessTripDialog.css';

const GeneralInfo = ({
  control,
  errors,
  projectsData,
  clientsData,
  employeesData,
  countriesDietsData,
  addedEmployees,
  handleAddEmployee,
  deleteAdditionalEmployee,
  editClicked,
  handleClientChange,
}: DescriptionProps) => {
  const {
    businessTripsManagerState: {
      formState: {
        tripReason,
        city,
        client,
        project,
        startTime,
        endTime,
        country,
      },
    },
    dispatch,
  } = useContext(BusinessTripsContext);

  const intl = useIntl();

  const start = useWatch({
    control,
    name: 'startTime',
    defaultValue: startTime,
  });

  const end = useWatch({
    control,
    name: 'endTime',
    defaultValue: endTime,
  });

  const setNumberOfNights = useCallback(() => {
    if (start && end && (startTime !== start || endTime !== end)) {
      const calculatedNumberOfNights = checkNumberOfNights(start, end);
      dispatch({
        type: ActionKind.SetNumberOfNights,
        payload: calculatedNumberOfNights,
      });
    }
  }, [dispatch, end, endTime, start, startTime]);

  useEffect(() => setNumberOfNights(), [setNumberOfNights]);

  const { additionalEmployees } = useWatch({ control });

  return (
    <TripSectionContainer container spacing={4}>
      <Grid xs={12} item>
        <ControlledTextField
          label="BUSINESS_TRIP.TITLE"
          name="tripReason"
          control={control}
          variant="outlined"
          maxLength={MAX_INPUT_LENGTH}
          defaultValue={tripReason}
          placeholder={intl.formatMessage({
            id: 'BUSINESS_TRIP.TRIP_REASON_PLACEHOLDER',
          })}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid xs={12} item>
          <ControllerSelect
            label="BUSINESS_TRIP.COUNTRY*"
            name="country"
            control={control}
            options={countriesDietsData}
            isPadding={false}
            defaultValue={country}
            required
            isDisabled={editClicked}
            errorMessage={
              errors?.country && renderErrorMessageForRequiredFields(intl)
            }
            errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
          />
        </Grid>
        <Grid xs={12} item>
          <ControlledTextField
            label="BUSINESS_TRIP.FORM.CITY"
            name="city"
            control={control}
            required
            variant="outlined"
            maxLength={MAX_INPUT_LENGTH}
            defaultValue={city}
            isDisabled={editClicked}
            validate={!!errors?.city}
            helperText={
              errors?.city && renderErrorMessageForRequiredFields(intl)
            }
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid xs={12} item>
          <ControllerSelect
            label="BUSINESS_TRIP.FORM.CLIENT"
            name="client"
            control={control}
            options={clientsData}
            required
            helperText={editClicked}
            isPadding={false}
            defaultValue={client}
            errorMessage={
              errors?.client && renderErrorMessageForRequiredFields(intl)
            }
            errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
            handleChange={handleClientChange}
          />
        </Grid>
        <Grid xs={12} item>
          <ControllerSelect
            label="BUSINESS_TRIP.PROJECT"
            name="project"
            control={control}
            options={projectsData}
            helperText={editClicked}
            isPadding={false}
            defaultValue={project}
            noOptionMessage={intl.formatMessage({
              id: 'WORK_TIME.SELECT_CLIENT',
            })}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid xs={12} item>
          <ControlledDateTimePicker
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.FORM.TRIP_START',
            })}
            name="startTime"
            defaultValue={startTime}
            maxDate={end || undefined}
            rules={validate(intl).date}
            control={control}
            view="date"
            errorMessage={errors?.startTime?.message}
            placeholder={intl.formatMessage({
              id: 'BUSINESS_TRIP.DATE_PLACEHOLDER',
            })}
          />
        </Grid>
        <Grid xs={12} item>
          <ControlledDateTimePicker
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.FORM.TRIP_END',
            })}
            name="endTime"
            minDate={start || undefined}
            defaultValue={endTime}
            rules={validate(intl).date}
            control={control}
            view="date"
            errorMessage={errors?.endTime?.message}
            placeholder={intl.formatMessage({
              id: 'BUSINESS_TRIP.DATE_PLACEHOLDER',
            })}
          />
        </Grid>
      </Grid>

      {!editClicked && (
        <>
          <Grid xs={12} item container justify="space-between" spacing={1}>
            <Grid xs={8} item>
              <ControllerSelect
                label="BUSINESS_TRIP.ADDITIONAL_EMPLOYEES"
                name="additionalEmployees"
                control={control}
                options={employeesData}
                helperText={editClicked}
                isDisabled={editClicked}
                isPadding={false}
              />
            </Grid>
            <Grid xs={4} item>
              <DefaultButton
                style={{
                  margin: '0 -10px 0 10px',
                  padding: '3px 15px',
                  backgroundColor: additionalEmployees
                    ? '#FFCC46'
                    : 'rgba(1,1,1,0.1)',
                  boxShadow: '1px 1px 2px rgba(0,0,0,0.4)',
                }}
                color="default"
                startIcon={<AddIcon />}
                onClick={handleAddEmployee}
                disabled={!additionalEmployees}
              >
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.ADD_EMPLOYEE',
                })}
              </DefaultButton>
            </Grid>
          </Grid>
          {!!addedEmployees.length ? (
            addedEmployees.map((employee) => (
              <Chip
                key={employee.value}
                style={{ margin: '0.5rem 0 0 1rem' }}
                label={employee.name}
                onDelete={deleteAdditionalEmployee(employee.value)}
              />
            ))
          ) : (
            <p style={{ marginLeft: '17px', marginBottom: '-5px' }}>
              {intl.formatMessage({ id: 'NO_ADDITIONAL_EMPLOYEES_ADDED' })}
            </p>
          )}
        </>
      )}
    </TripSectionContainer>
  );
};

export default GeneralInfo;
