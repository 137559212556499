import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { benefitsUrl } from 'router/url';
import {
  BENEFIT_MEMBER_TYPES,
  BenefitAccountFormValues,
  BenefitManagerFormValues,
} from 'components/BenefitInputs/types';
import ControlledStepper from 'components/ControlledStepper';
import { StepTitlesData } from 'components/ControlledStepper/types';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import SwitchContainer from 'components/SwitchContainer';
import { RenderForm } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import { postFetch } from 'utils/fetchFunctions';
import { ActionKind } from './BenefitContext/types';
import {
  getBenefitsOptions,
  getUserBenefits,
  resignFromBenefit,
} from './components/api';
import BenefitInputs from './components/BenefitInputs';
import BenefitSelect from './components/BenefitSelect';
import BenefitsTable from './components/BenefitsTable';
import Summary from './components/Summary/Summary';
import { BenefitContext } from './BenefitContext';
import { BENEFIT_PARTNER_TYPES } from './staticData';
import { BenefitsDataProps } from './types';
import { getFormattedBody, getPartnerMemberType } from './utils';

const { adult } = BENEFIT_PARTNER_TYPES;

const BenefitsTab = () => {
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    formState: { isValid },
  } = useForm<BenefitAccountFormValues>({ mode: 'all' });

  const [benefitsInfo, setBenefitsInfo] = useState<BenefitManagerFormValues[]>(
    [],
  );
  const [loadingBenefitsInfo, setLoadingBenefitsInfo] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [benefitsFetchedData, setBenefitsFetchedData] = useState<
    BenefitsDataProps[]
  >([]);

  const { package: benefitPackage } = useWatch({ control });

  const { state, dispatch } = useContext(BenefitContext);
  const intl = useIntl();

  const isAdultSelected = getValues()?.partners?.some(
    (el) => el.partnerType?.type === adult,
  );

  const isPartnerTypeAdult = useCallback(
    (index?: number) =>
      getValues().partners?.[index ?? 0]?.partnerType?.type === adult,
    [getValues],
  );

  const fetchBenefits = useCallback(async () => {
    const data = await getUserBenefits(setLoadingTable);
    setBenefitsFetchedData(data);
  }, []);

  const handleClose = useCallback(() => {
    dispatch({ type: ActionKind.ResetState });
  }, [dispatch]);

  const onSubmit = useCallback(async () => {
    if (state.renderForm === RenderForm.Benefit) {
      const { ok } = await postFetch<BenefitManagerFormValues>({
        url: `${benefitsUrl}/forms`,
        body: getFormattedBody(state),
        intl,
        label: `BENEFITS.ADDITION`,
      });
      if (ok) {
        handleClose();
        fetchBenefits();
      }
      return ok;
    } else {
      return false;
    }
  }, [fetchBenefits, handleClose, intl, state]);

  const onNextStep: SubmitHandler<BenefitAccountFormValues> = useCallback(
    (data) => {
      if (Object.keys(data).length) {
        const newData = {
          ...data,
          partners: data?.partners?.map((partner) => ({
            ...partner,
            memberType: getPartnerMemberType(partner),
          })),
        };
        dispatch({
          type: ActionKind.SetBenefitData,
          payload: { ...newData },
        });
      }
    },
    [dispatch],
  );

  const onPrevStep = useCallback(() => {
    dispatch({
      type: ActionKind.SetBenefitData,
      payload: getValues(),
    });
  }, [dispatch, getValues]);

  const resetNumberOfFields = useCallback(() => {
    if (state.selectedBenefit || benefitPackage) {
      dispatch({ type: ActionKind.ResetNumberOfMembers });
    }
  }, [benefitPackage, dispatch, state.selectedBenefit]);

  const resetForm = useCallback(() => {
    state.toggleFormDialog && reset();
  }, [reset, state.toggleFormDialog]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    resetNumberOfFields();
  }, [resetNumberOfFields]);

  const renderFormDialogTitle = () => {
    const getMainTitle = (benefitName?: string) =>
      `${benefitName} - ${intl.formatMessage({
        id: 'USER_PROFILE.BENEFITS.FORM',
      })}`;

    const getPartnerTitle = (benefitName?: string) =>
      `${benefitName} - ${intl.formatMessage({
        id: 'USER_PROFILE.BENEFITS.ADD_PARTNER_FORM',
      })}`;

    const getEditTitle = (benefitName?: string) => {
      let id = 'USER_PROFILE.BENEFITS.EDIT_BENEFIT_FORM';
      if (
        state.benefitsData?.employee.memberType !==
        BENEFIT_MEMBER_TYPES.employee
      ) {
        id = 'USER_PROFILE.BENEFITS.EDIT_PARTNER_BENEFIT_FORM';
      }

      return `${benefitName} - ${intl.formatMessage({ id })}`;
    };

    switch (state.renderForm) {
      case RenderForm.Benefit:
        return getMainTitle(state.selectedBenefit?.benefitName);
      case RenderForm.Partner:
        return getPartnerTitle(state.benefitsData?.benefitName);
      case RenderForm.EditData:
        return getEditTitle(state.benefitsData?.benefitName);
      default:
        return '';
    }
  };

  const dialogTitle = (name: 'BENEFIT' | 'PARTNER') =>
    `${state.selectedBenefitToRemove?.benefitName} - ${intl.formatMessage({
      id: `USER_PROFILE.BENEFITS.FORM.REMOVE_${name}`,
    })}`;

  const stepsContent = [
    <BenefitInputs
      key="benefitInputs"
      isPartnerTypeAdult={isPartnerTypeAdult}
      isAdultSelected={isAdultSelected}
      control={control}
      errors={errors}
      getValues={getValues}
      setValue={setValue}
      benefitsInfo={benefitsInfo}
    />,
    <Summary key="summary" />,
  ];

  const stepTitles: StepTitlesData[] = [
    {
      label: intl.formatMessage({ id: 'BENEFITS.GENERAL' }),
      id: 'generalStep',
    },
    {
      label: intl.formatMessage({ id: 'BENEFITS.SUMMARY' }),
      id: 'summaryStep',
    },
  ];

  const fetchBenefitsOptions = useCallback(async () => {
    const data = await getBenefitsOptions(setLoadingBenefitsInfo);
    setBenefitsInfo(data);
  }, []);

  const handleResignFromBenefit = useCallback(async () => {
    if (state.selectedBenefitToRemove) {
      const { ok } = await resignFromBenefit(
        setLoadingTable,
        state.selectedBenefitToRemove.id,
        intl,
        'BENEFIT_RESIGNATION',
      );
      if (ok) {
        dispatch({ type: ActionKind.ToggleRemoveConfirmation });
        fetchBenefits();
      }
    }
  }, [dispatch, fetchBenefits, intl, state.selectedBenefitToRemove]);

  useEffect(() => {
    fetchBenefits();
    fetchBenefitsOptions();
  }, [fetchBenefits, fetchBenefitsOptions]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SwitchContainer
          toggleSwitch={state.renderPartners}
          setToggleSwitch={() =>
            dispatch({
              type: ActionKind.RenderPartners,
            })
          }
          label="USER_PROFILE.BENEFITS.SWITCH"
          name="showBenefitPartners"
          style={{ marginBottom: '-1rem' }}
          disabled={
            !benefitsFetchedData.some(
              (value) => value.partner || value.children,
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <BenefitsTable
          loadingTable={loadingTable}
          benefitsFetchedData={benefitsFetchedData}
          benefitsInfo={benefitsInfo}
        />
      </Grid>
      <Grid item xs={12}>
        <BenefitSelect
          benefitsOptions={benefitsInfo}
          benefitsFromTable={benefitsFetchedData}
          loading={loadingBenefitsInfo}
        />
      </Grid>
      {state.toggleFormDialog && (
        <ControlledStepper
          open={state.toggleFormDialog}
          handleClose={handleClose}
          checkValidation={handleSubmit(onNextStep)}
          handlePrevStep={onPrevStep}
          handleSubmit={onSubmit}
          isValid={isValid}
          dialogTitle={renderFormDialogTitle()}
          stepTitles={stepTitles}
          stepsContent={stepsContent}
          resetStep={state.toggleFormDialog}
          isEditing={state.renderForm === RenderForm.EditData}
        />
      )}
      <ConfirmationDialog
        title={dialogTitle('BENEFIT')}
        handleSubmit={handleResignFromBenefit}
        content={intl.formatMessage({
          id: 'USER_PROFILE.BENEFITS.FORM.REMOVE_CONFIRMATION_INFO',
        })}
        addChecked={state.toggleRemoveBenefitDialog}
        handleAddChanged={() =>
          dispatch({ type: ActionKind.ToggleRemoveConfirmation })
        }
      />
      <ConfirmationDialog
        title={dialogTitle('PARTNER')}
        handleSubmit={() => dispatch({ type: ActionKind.RemovePartner })}
        content={intl.formatMessage({
          id: 'USER_PROFILE.BENEFITS.FORM.REMOVE_CONFIRMATION_INFO',
        })}
        addChecked={state.toggleRemovePartnerDialog}
        handleAddChanged={() => dispatch({ type: ActionKind.RemovePartner })}
      />
    </Grid>
  );
};

export default BenefitsTab;
