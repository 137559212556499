import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ControlledDatePicker from 'components/ControlledDatePicker';
import ControlledSelect from 'components/ControlledSelect';
import EmployeesHolidaysTable from './components/EmployeesHolidaysTable';
import { FilterWrapper } from './EmployeesHolidaysComponent.css';

const EmployeesHolidaysComponent = () => {
  const { control } = useForm();
  const intl = useIntl();

  return (
    <form>
      <FilterWrapper>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={4}>
            <ControlledSelect
              name="employee"
              label={intl.formatMessage({ id: 'EMPLOYEES_HOLIDAY.LABEL.NAME' })}
              control={control}
              options={[
                { name: 'standard vacation' },
                { name: 'occasional leave' },
                { name: 'unpaid leave' },
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledSelect
              name="status"
              label={intl.formatMessage({
                id: 'EMPLOYEES_HOLIDAY.LABEL.STATUS',
              })}
              control={control}
              options={[
                { name: 'pending approval' },
                { name: 'approved' },
                { name: 'rejected' },
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledSelect
              name="type"
              label={intl.formatMessage({ id: 'EMPLOYEES_HOLIDAY.LABEL.TYPE' })}
              control={control}
              options={[
                { name: 'pending approval' },
                { name: 'approved' },
                { name: 'rejected' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ControlledDatePicker
              name="Date from"
              label={intl.formatMessage({
                id: 'LEAVE_REQUEST.LABEL.START_DATE',
              })}
              control={control}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledDatePicker
              name="Date to"
              label={intl.formatMessage({ id: 'LEAVE_REQUEST.LABEL.END_DATE' })}
              control={control}
            />
          </Grid>
        </Grid>
      </FilterWrapper>
      <EmployeesHolidaysTable />
    </form>
  );
};

export default EmployeesHolidaysComponent;
