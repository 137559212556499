import { IsFormInvalidProps } from './types';

export const isFormInvalidToSubmit = (args: IsFormInvalidProps) => {
  const {
    form,
    affiliationNames,
    freeDayType,
    areAllAffiliationsEmpty,
    excludedInputValue,
  } = args;
  const { title, client, account } = form;
  if (!title) {
    return true;
  }
  if (!affiliationNames.includes(freeDayType)) {
    return true;
  }
  if (freeDayType === 'all') {
    return excludedInputValue && areAllAffiliationsEmpty();
  }
  if (freeDayType === 'client') {
    if (!client) {
      return true;
    }
    if (excludedInputValue && !!account) {
      return false;
    }
    return !(!excludedInputValue && !!client);
  }
  if (freeDayType === 'account') {
    return !account;
  }
  return true;
};
