import { Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';
import ControlledRadioInput from 'components/ControlledRadioInput/ControlledRadioInput';
import { ControlledRadioInputProps } from 'components/ControlledRadioInput/types';

export const CustomTypography = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    text-align: left;
    margin-top: 1rem;
  }
`;

export const CustomControlledRadioInput = styled(
  ControlledRadioInput,
)<ControlledRadioInputProps>`
  &.MuiFormControl-root {
    width: 100%;
    padding: 1rem 0;
  }
`;
