import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { UserContext } from 'globalContext/UserContext';
import moment, { Moment } from 'moment';
import { BenefitManagerFormValues } from 'components/BenefitInputs/types';
import { ContainedButton } from 'components/Button';
import ControlledStepper from 'components/ControlledStepper';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import {
  SmallUploadFileIcon,
} from 'modules/BusinessTrips/BusinessTrips.css';
import { fetchBenefitsUploadHistory } from 'utils/commonFetches';
import { returnRoleInclusion } from 'utils/helpers';
import { RoleTypes } from 'utils/rolesTypes';
import { BenefitManagerContext } from './BenefitManagerContext/Context';
import { ActionKind } from './BenefitManagerContext/types';
import FirstStep from './components/FormSteps/FirstStep';
import FourthStep from './components/FormSteps/FourthStep';
import SecondStep from './components/FormSteps/SecondStep';
import Summary from './components/FormSteps/Summary';
import ThirdStep from './components/FormSteps/ThirdStep';
import { UploadCostsSummaryDialog } from './components/UploadCostsSummaryDialog';
import { UploadHistoryTable } from './components/UploadHistoryTable';
import {
  deleteBenefit,
  getBenefits,
  patchBenefitStatus,
  postBenefit,
  updateBenefit,
} from './api';
import { UploadHistoryEntry } from './types';
import {
  getConfirmationDialogData,
  getFormattedData,
  getFormattedPostData,
  getStepTitles,
} from './utils';
import { ButtonWithMargin, GridWithMargin } from './BenefitsManager.css';

const BenefitsManager = () => {
  const {
    control,
    errors,
    getValues,
    handleSubmit,
    setValue,
    formState: { isValid },
    reset,
  } = useForm<BenefitManagerFormValues>({
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [isCostsSummaryDialogOpen, setIsCostsSummaryDialogOpen] =
    useState(false);
  const [uploadHistory, setUploadHistory] = useState<UploadHistoryEntry[]>([]);
  const [monthAndYear, setMonthAndYear] = useState<Moment | null>(moment());
  const { roles } = useContext(UserContext);

  const {
    benefitManagerState: {
      formState,
      openedForm,
      openedConfirmationDialog,
      selectedBenefit,
      tableData,
    },
    dispatch,
  } = useContext(BenefitManagerContext);

  const { id, status, name: selectedBenefitName } = selectedBenefit ?? {};

  const stepsContent = [
    <FirstStep
      key="firstStep"
      control={control}
      errors={errors}
      getValues={getValues}
      setValue={setValue}
    />,
    <SecondStep
      key="secondStep"
      control={control}
      errors={errors}
      getValues={getValues}
      setValue={setValue}
      reset={reset}
    />,
    <ThirdStep key="thirdStep" control={control} errors={errors} />,
    <FourthStep
      key="fourthStep"
      control={control}
      errors={errors}
      getValues={getValues}
      setValue={setValue}
    />,
    <Summary key="summary" />,
  ];

  const confirmationDialogData = getConfirmationDialogData(
    intl,
    openedConfirmationDialog,
    selectedBenefitName,
    formState.status,
  );

  const isFormOpen = openedForm !== 'none';

  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]),
    [roles],
  );

  const getDialogTitle = useCallback(() => {
    switch (openedForm) {
      case 'addNew':
        return intl.formatMessage({ id: 'BENEFITS.ADDING_NEW_BENEFIT' });
      case 'edit':
        return `${intl.formatMessage({ id: 'BENEFITS.EDITING' })} ${
          formState.benefitName
        }`;
      default:
        return '';
    }
  }, [formState.benefitName, intl, openedForm]);

  const onNextStep: SubmitHandler<BenefitManagerFormValues> = useCallback(
    (data) =>
      dispatch({
        type: ActionKind.SetFormState,
        payload: data,
      }),
    [dispatch],
  );

  const onPrevStep = useCallback(
    () =>
      dispatch({
        type: ActionKind.SetFormState,
        payload: getValues(),
      }),
    [dispatch, getValues],
  );

  const onSubmit = useCallback(async () => {
    let isRequestOk = false;
    const body = getFormattedPostData(formState);
    switch (openedForm) {
      case 'addNew':
        {
          const { data, ok } = await postBenefit(setLoading, intl, body);
          isRequestOk = ok;
          if (ok && data) {
            dispatch({
              type: ActionKind.AddNewBenefit,
              payload: getFormattedData(data),
            });
          }
        }
        break;
      case 'edit':
        if (id) {
          const { data, ok } = await updateBenefit(setLoading, intl, body, id);
          isRequestOk = ok;
          if (ok && data) {
            dispatch({
              type: ActionKind.UpdateBenefit,
              payload: getFormattedData(data),
            });
          }
        }
        break;
    }
    return isRequestOk;
  }, [dispatch, formState, id, intl, openedForm]);

  const handleClose = useCallback(() => {
    dispatch({ type: ActionKind.ResetFormState });
    reset();
  }, [dispatch, reset]);

  const handleConfirmationDialogClose = useCallback(
    () =>
      dispatch({
        type: ActionKind.ToggleConfirmationDialog,
        payload: { type: 'none' },
      }),
    [dispatch],
  );

  const handleConfirmationDialogSubmit = useCallback(async () => {
    const index = tableData.findIndex(
      (benefit) => benefit.benefitName === selectedBenefitName,
    );

    if (id) {
      switch (openedConfirmationDialog) {
        case 'changingStatus':
          {
            const { ok } = await patchBenefitStatus(
              setLoading,
              intl,
              id,
              status,
            );
            if (ok) {
              dispatch({ type: ActionKind.ChangeStatus });
            }
          }
          break;
        case 'removingBenefit': {
          const { ok } = await deleteBenefit(setLoading, intl, id);
          if (ok) {
            dispatch({ type: ActionKind.RemoveBenefit, payload: index });
          }
        }
      }
    }
    handleConfirmationDialogClose();
  }, [
    dispatch,
    handleConfirmationDialogClose,
    id,
    intl,
    openedConfirmationDialog,
    selectedBenefitName,
    status,
    tableData,
  ]);

  const fetchBenefits = useCallback(async () => {
    const data = await getBenefits(setLoading);
    dispatch({ type: ActionKind.SetTableData, payload: data });
  }, [dispatch, setLoading]);

  useEffect(() => {
    fetchBenefits();
  }, [fetchBenefits]);

  useEffect(() => {
    fetchBenefitsUploadHistory(
      moment(monthAndYear).format('YYYY-MM'),
      setUploadHistory,
    );
  }, [monthAndYear]);

  return (
    <>
      <Grid container>
        <GridWithMargin container item justify="space-between" spacing={2}>
          {isAccountPrivileged && (
            <ButtonWithMargin item>
              <ContainedButton
                endIcon={<SmallUploadFileIcon />}
                onClick={() => setIsCostsSummaryDialogOpen(true)}
                size="large"
                id="uploadCostsSummary"
              >
                {intl.formatMessage({
                  id: 'BENEFITS.UPLOAD_COSTS_SUMMARY',
                })}
              </ContainedButton>
            </ButtonWithMargin>
          )}
          <DatePicker
            value={monthAndYear}
            onChange={setMonthAndYear}
            inputVariant="outlined"
            views={['month', 'year']}
            format="MM-YYYY"
            label={intl.formatMessage({
              id: 'BENEFITS.UPLOAD_HISTORY.DATE',
            })}
          />
        </GridWithMargin>
        <Grid item xs={12}>
          <UploadHistoryTable loading={loading} data={uploadHistory} />
        </Grid>
      </Grid>
      {isFormOpen && (
        <ControlledStepper
          handleClose={handleClose}
          checkValidation={handleSubmit(onNextStep)}
          handlePrevStep={onPrevStep}
          handleSubmit={onSubmit}
          isValid={isValid}
          dialogTitle={getDialogTitle()}
          stepTitles={getStepTitles(intl)}
          stepsContent={stepsContent}
          resetStep={!isFormOpen}
          isEditing={openedForm === 'edit'}
        />
      )}
      <ConfirmationDialog
        title={confirmationDialogData.title}
        handleSubmit={handleConfirmationDialogSubmit}
        content={confirmationDialogData.content}
        addChecked={openedConfirmationDialog !== 'none'}
        handleAddChanged={handleConfirmationDialogClose}
        isSubmitDisabled={loading}
      />
      <UploadCostsSummaryDialog
        isCostsSummaryDialogOpen={isCostsSummaryDialogOpen}
        setIsCostsSummaryDialogOpen={setIsCostsSummaryDialogOpen}
      />
    </>
  );
};

export default BenefitsManager;
