import { useCallback } from 'react';
import { TimesheetFilterProps } from './types';
import {
  MultipleFilterComponent,
  TextFieldComponent,
} from './TimesheetFilter.css';

const TimesheetChipsFilter = ({
  onChange,
  options,
  label,
  value = {},
  type,
  disabled,
}: TimesheetFilterProps) => {
  const handleChange = useCallback(
    (event, value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <MultipleFilterComponent
      id={type}
      value={value}
      disableClearable={
        (type === 'client' &&
          (options.length === 1 || value?.name === ('' || undefined))) ||
        (type === 'project' && value?.name === ('' || undefined)) ||
        (type === 'account' && value?.name === ('' || undefined))
          ? true
          : undefined
      }
      options={options as string[]}
      onChange={handleChange}
      getOptionLabel={(option: any) => option.name ?? ''}
      getOptionSelected={(option: any, value: any) =>
        option?.name === value?.name
      }
      renderInput={(params) => (
        <TextFieldComponent {...params} variant="outlined" label={label} />
      )}
      disabled={disabled}
    />
  );
};

export default TimesheetChipsFilter;
