import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormControlLabel } from '@material-ui/core';
import { ControlledSwitchProps } from './types';
import { SwitchComponent } from './ControlledSwitch.css';

const ControlledSwitch = ({
  control,
  label,
  name,
  required,
  size,
  defaultValue,
  isDisabled,
  edge,
  labelPlacement,
}: ControlledSwitchProps) => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      type={name}
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: !!required,
        'aria-label': 'switch',
      }}
      defaultValue={defaultValue || false}
      render={({ ...props }) => (
        <FormControlLabel
          labelPlacement={labelPlacement}
          label={label && intl.formatMessage({ id: label })}
          control={
            <SwitchComponent
              id={`${name}-switch`}
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
              edge={edge}
              size={size}
              color="primary"
              disabled={isDisabled}
            />
          }
          {...props}
        />
      )}
    />
  );
};

export default ControlledSwitch;
