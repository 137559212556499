import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { UserContext } from 'globalContext/UserContext';
import { businessTripsUrl } from 'router/url';
import ControlledStepper from 'components/ControlledStepper';
import { Data } from 'components/Dialog/types';
import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import {
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { postFetch, putFetch } from 'utils/fetchFunctions';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { BusinessTripsContext } from '../BusinessTripManagerContext/Context';
import { ActionKind } from '../BusinessTripManagerContext/types';
import { BusinessTrip, CountryDiet } from '../types';
import Accommodation from './TripSections/Accommodation';
import GeneralInfo from './TripSections/GeneralInfo';
import Other from './TripSections/Other';
import Summary from './TripSections/Summary';
import Travel from './TripSections/Travel';
import {
  fetchCountriesDietsList,
  fetchCurrencies,
  fetchPersonalData,
} from './api';
import { DEFAULT_CURRENCY } from './constants';
import {
  BusinessTripDialogProps,
  BusinessTripFormValues,
  CurrenciesData,
  InsuranceProviderTypes,
  ReservationProviderTypes,
  UserData,
} from './types';
import { getFormattedPostData, getStepTitles } from './utils';

const BusinessTripDialog = ({
  addChecked,
  handleAddChanged,
  editClicked = false,
  tripsChanged,
  previousData,
}: BusinessTripDialogProps) => {
  const intl = useIntl();
  const { username } = useContext(UserContext);
  const [isAbroad, setIsAbroad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const [addedEmployees, setAddedEmployees] = useState<Data[]>([]);
  const [personalData, setPersonalData] = useState<UserData>();
  const [files, setFiles] = useState<File[]>([]);
  const [needsClientAcceptance, setNeedsClientAcceptance] = useState(false);
  const [currenciesData, setCurrenciesData] =
    useState<CurrenciesData[]>(DEFAULT_CURRENCY);

  const [countriesDiets, setCountriesDiets] = useState<CountryDiet[]>([]);
  const getCountriesDietsOptions = () => {
    return countriesDiets
      .map((el) => ({
        ...el,
        value: el.name,
        engName: el.name,
        name: intl.formatMessage({
          id: `COUNTRY_DIET.${getFormattedName(el.name)}`,
        }),
      }))
      .sort((a, b) => {
        if (getFormattedName(a.value) === 'OTHER') {
          return 1;
        }
        if (getFormattedName(b.value) === 'OTHER') {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
  };

  useEffect(() => {
    const retrieveData = () => {
      Promise.all([
        fetchEmployeeList(setEmployeesData),
        fetchClientList(setClientsData),
        fetchProjectList(setProjectsData),
        fetchCurrencies(setCurrenciesData),
        fetchCountriesDietsList(setCountriesDiets),
        fetchPersonalData(username, setPersonalData),
      ]);
    };
    retrieveData();
  }, [username]);

  const deleteAdditionalEmployee =
    (deletedEmployeeId: number) => (_e: MouseEvent) => {
      setAddedEmployees((addedEmployees) =>
        addedEmployees.filter(
          (employee) => employee.value !== deletedEmployeeId,
        ),
      );
    };
  const handleAddEmployee = () => {
    const employeeCandidateName: Data = getValues('additionalEmployees');
    if (!employeeCandidateName) {
      return;
    }

    const updatedEmployees = new Set([
      ...addedEmployees,
      employeeCandidateName,
    ]);
    setValue('additionalEmployees', '');
    setAddedEmployees([...updatedEmployees]);
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { isValid },
    reset,
    errors,
  } = useForm<BusinessTripFormValues>({
    mode: 'onChange',
  });

  const sendFile = useCallback(
    async (businessTripId: number, file: File) => {
      const formFile = new FormData();
      formFile.append('file', file);
      await postFetch({
        url: `${businessTripsUrl}/${businessTripId}/file-with-acceptance`,
        body: formFile,
        contentType: 'formdata',
        intl,
        label: 'CLIENT_ACCEPTANCE_FILE',
      });
    },
    [intl],
  );

  const {
    businessTripsManagerState: { formState },
    dispatch,
  } = useContext(BusinessTripsContext);

  const { client, country } = useWatch({ control });

  const onNextStep: SubmitHandler<BusinessTripFormValues> = useCallback(
    (data) =>
      dispatch({
        type: ActionKind.SetFormState,
        payload: data,
      }),
    [dispatch],
  );

  const onPrevStep = useCallback(
    () =>
      dispatch({
        type: ActionKind.SetFormState,
        payload: getValues(),
      }),
    [dispatch, getValues],
  );

  const handleClose = useCallback(() => {
    handleAddChanged();
    reset();
    dispatch({ type: ActionKind.ResetFormState });
    setAddedEmployees([]);
  }, [dispatch, handleAddChanged, reset]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    dispatch({
      type: ActionKind.SetFormState,
      payload: getValues(),
    });

    const body = getFormattedPostData(
      formState,
      personalData,
      addedEmployees,
      needsClientAcceptance,
    );
    if (editClicked && previousData) {
      const { ok } = await putFetch<BusinessTrip>({
        url: `${businessTripsUrl}/${previousData.id}`,
        body: body,
        intl,
        label: 'BUSINESS_TRIP_EDIT',
      });
      if (tripsChanged) {
        tripsChanged();
      }
      if (ok) {
        handleClose();
        setNeedsClientAcceptance(false);
      }
      setIsLoading(false);
      return ok;
    } else {
      const { data, ok } = await postFetch<BusinessTrip>({
        url: `${businessTripsUrl}`,
        body: body,
        intl,
        label: 'BUSINESS_TRIP_ADD',
      });
      if (tripsChanged) {
        tripsChanged();
      }
      if (ok && needsClientAcceptance) {
        try {
          await Promise.all(
            files.map(async (file) => {
              if (data) {
                return sendFile(data.id, file);
              }
            }),
          );
        } catch (e) {
          console.error(e);
        } finally {
          handleClose();
          setFiles([]);
        }
      } else if (ok) {
        handleClose();
        setNeedsClientAcceptance(false);
      }
      setIsLoading(false);
      return ok;
    }
  }, [
    addedEmployees,
    dispatch,
    editClicked,
    files,
    formState,
    getValues,
    handleClose,
    intl,
    needsClientAcceptance,
    personalData,
    previousData,
    sendFile,
    tripsChanged,
  ]);
  const getDialogTitle = useCallback(() => {
    return intl.formatMessage({
      id: editClicked ? 'BUSINESS_TRIP.EDIT' : 'BUSINESS_TRIP.ADD',
    });
  }, [editClicked, intl]);

  const projectsFilter = useMemo(() => {
    if (client) {
      return projectsData.filter(
        (project) => project.client.id === client.value,
      );
    }
    return [];
  }, [client, projectsData]);

  const handleClientChange = useCallback(() => {
    setValue('project', undefined);
  }, [setValue]);

  useEffect(() => {
    setValue('insuranceProvidedBy', {
      value: isAbroad
        ? InsuranceProviderTypes.JIT_INSURANCE
        : InsuranceProviderTypes.NO_INSURANCE,
      name: isAbroad
        ? intl.formatMessage({
            id: 'BUSINESS_TRIP.JIT_INSURANCE',
          })
        : intl.formatMessage({
            id: 'BUSINESS_TRIP.NO_INSURANCE',
          }),
    });
  }, [setValue, isAbroad, intl]);

  const defaultReservationBy = {
    value: ReservationProviderTypes.WORK_TRIPS,
    name: intl.formatMessage({
      id: 'BUSINESS_TRIP.WORK_TRIPS',
    }),
  };

  const stepsContent = [
    <GeneralInfo
      key="description"
      control={control}
      projectsData={projectsFilter}
      clientsData={clientsData}
      employeesData={employeesData}
      countriesDietsData={getCountriesDietsOptions()}
      addedEmployees={addedEmployees}
      handleAddEmployee={handleAddEmployee}
      deleteAdditionalEmployee={deleteAdditionalEmployee}
      editClicked={editClicked}
      errors={errors}
      handleClientChange={handleClientChange}
    />,
    <Travel
      key="travel"
      control={control}
      editClicked={editClicked}
      errors={errors}
      defaultReservationBy={defaultReservationBy}
    />,
    <Accommodation
      key="accommodation"
      control={control}
      currenciesData={currenciesData}
      editClicked={editClicked}
      errors={errors}
      defaultReservationBy={defaultReservationBy}
    />,
    <Other
      key="other"
      control={control}
      editClicked={editClicked}
      errors={errors}
      files={files}
      setFiles={setFiles}
      setNeedsClientsAcceptance={setNeedsClientAcceptance}
      isAbroad={isAbroad}
    />,
    <Summary key="summary" additionalAccounts={addedEmployees} />,
  ];

  useEffect(() => {
    if (country !== undefined || previousData !== undefined) {
      country?.engName === 'Poland' ||
      (previousData && previousData?.country?.name === 'Poland')
        ? setIsAbroad(false)
        : setIsAbroad(true);
    }
  }, [country, previousData?.country, previousData]);

  return (
    <>
      {addChecked && (
        <ControlledStepper
          handleClose={handleClose}
          checkValidation={handleSubmit(onNextStep)}
          handlePrevStep={onPrevStep}
          handleSubmit={onSubmit}
          isValid={
            needsClientAcceptance ? files.length > 0 && isValid : isValid
          }
          dialogTitle={getDialogTitle()}
          stepTitles={getStepTitles(intl)}
          stepsContent={[...new Set(stepsContent)]}
          resetStep={!addChecked}
          isEditing={editClicked}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default BusinessTripDialog;
