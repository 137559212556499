import { useContext } from 'react';
import { GridWithMargin } from 'components/Table/Table.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext';
import { RenderForm } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import Address from './Address';
import General from './General';
import Partners from './Partners';
import PersonalData from './PersonalData';

const Summary = () => {
  const { state } = useContext(BenefitContext);
  return (
    <GridWithMargin container>
      {state.renderForm !== RenderForm.Partner && (
        <>
          <General />
          <PersonalData />
          <Address />
        </>
      )}
      {state.benefitsData?.partners && <Partners />}
    </GridWithMargin>
  );
};

export default Summary;
