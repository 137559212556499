import { useEffect, useState } from 'react';
import { getFetch } from 'utils/fetchFunctions';
import COMPANY_LOGO from './../../assets/images/companyLogo.png';
import {
  AboutBox,
  AboutContent,
  AboutLine,
  AboutSubTitle,
  CompanyLogo,
} from './About.css';

const authorsList = [
  'Błażej Karnecki',
  'Wiktor Morawski',
  'Tomasz Piasek',
  'Maksymilian Operlejn',
  'Matylda Badowska',
  'Miłosz Gustawski',
  'Kacper Olszewski',
  'Adam Mielewczyk',
  'Aleksander Wojas',
  'Julia Moroz',
  'Martyna Drabińska',
  'Anthony Nwachukwu',
  'Jędrzej Dolata',
  'Aleksander Kwiatkowski',
  'Magdalena Thomas',
  'Rafał Trzciński',
  'Jakub Czapiewski',
  'Maciej Dziamałek',
  'Daniel Żukowski',
  'Kamil Kiewisz',
  'Rafał Drezner',
  'Ryszard Jakielski',
  'Paweł Puzio',
  'Kacper Łęgowski',
  'Piotr Jaworski',
  'Maciej Kankowski',
  'Mateusz Gajewski-Tuttle',
  'Radosław Żerek',
  'Michał Bulanda',
  'Kamil Cieślar',
  'Grzegorz Nowakowski',
  'Michał Troka',
  'Aleksandra Jakubik',
  'Michał Gailitis',
  'Monika Zyznowska',
  'Roman Musijowski',
  'Patryk Siewruk',
  'Anna Woźniak',
  'Magdalena Szyszkowska',
  'Mateusz Bessman',
  'Jakub Pobłocki',
  'Paweł Milewczyk',
  'Kamil Światły',
  'Szymon Szymonowicz',
  'Oskar Baranowski',
  'Natalia Wojtania',
  'Mikołaj Stempin',
  'Julia Bessman',
  'Wiktoria Wolnik',
  'Maciej Szczęsny',
  'Wojciech Szałachowski',
].sort((a, b) => a.localeCompare(b));

const About = () => {
  const [backendVersion, setBackendVersion] = useState('');

  useEffect(() => {
    async function fetchVersion() {
      const response = await getFetch({
        url: 'api/actuator/info',
      });
      setBackendVersion(response.build.version);
    }
    fetchVersion();
  }, []);

  return (
    <AboutContent>
      <AboutBox>
        <AboutSubTitle>Timeports</AboutSubTitle>
        <AboutLine>Frontend v{process.env.REACT_APP_VERSION}</AboutLine>
        <AboutLine>Backend {backendVersion}</AboutLine>
      </AboutBox>
      <AboutBox>
        <AboutSubTitle>Authors</AboutSubTitle>
        {authorsList.map((author, index) => (
          <AboutLine key={index}>{author}</AboutLine>
        ))}
      </AboutBox>
      <AboutBox>
        <CompanyLogo src={COMPANY_LOGO} />
      </AboutBox>
    </AboutContent>
  );
};

export default About;
