import { Grid, Toolbar } from '@material-ui/core';
import BoxComponent, { BoxProps } from '@material-ui/core/Box';
import CheckboxComponent, { CheckboxProps } from '@material-ui/core/Checkbox';
import TypographyComponent, {
  TypographyProps,
} from '@material-ui/core/Typography';
import palette from 'assets/palette';
import styled from 'styled-components';
import { ContainedButton } from 'components/Button';

export const Typography = styled(TypographyComponent)<TypographyProps>``;

export const TopBar = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0.5rem;
  }
`;

export const DatePickerWrapper = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Checkbox = styled(CheckboxComponent)<CheckboxProps>`
  &.MuiCheckbox-root {
    color: rgb(0 0 0);
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${palette.ACTION};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const CheckboxBox = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    margin-left: 4vw;
    @media (max-width: 1280px) {
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
  }
`;

export const TimesheetsFilterToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const TimesheetsFilterDrawerGrid = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
  }
`;

export const TimesheetsFilterTypography = styled(TypographyComponent)`
  &.MuiTypography-root {
    font-size: 1.5rem;
  }
`;

export const TimesheetsFilterButton = styled(ContainedButton)`
  &.MuiButtonBase-root {
    background-color: rgb(208, 208, 208);
  }
`;

export const FilterButtonGrid = styled(Grid)`
  &.MuiGrid-root {
    margin: 10px 0;
  }
`;
