import { FreeDayType } from 'components/Dialog/types';

export const checkDisable = (
  exclude: boolean,
  freeDayType: FreeDayType,
  type: 'client' | 'account',
) => {
  switch (type) {
    case 'client': {
      if (freeDayType === 'client') {
        return false;
      }
      if (exclude && freeDayType === 'all') {
        return false;
      }
      return true;
    }
    case 'account': {
      if (freeDayType === 'account') {
        return false;
      }
      if (exclude && freeDayType === 'all') {
        return false;
      }
      if (exclude && freeDayType === 'client') {
        return false;
      }
      return true;
    }
  }
};

export const checkHiddenSelect = (
  exclude: boolean,
  typeOfDay: string,
  freeDayType: FreeDayType,
) => {
  if (typeOfDay === freeDayType) {
    return false;
  }
  if (exclude) {
    return false;
  }
  return true;
};
