import { Dispatch, SetStateAction } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Data, DialogProps } from 'components/Dialog/types';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { BusinessTrip, CountryDiet } from './../types';

export interface BusinessTripDialogProps extends DialogProps {
  tripsChanged: () => void;
  previousData?: BusinessTripFormValues;
}

export interface BusinessTripFormValues {
  tripReason?: string;
  country?: CountryDiet;
  city: string;
  client?: Data;
  project?: Data;
  startTime: Date | null;
  endTime: Date | null;
  transportation?: TransportationData | { value: string; name: string };
  travelFrom: string;
  travelTo: string;
  travelReservationBy?:
    | ReservationProviderData
    | { value: string; name: string };
  travelReservationLink?: string;
  reservationInformation?: string;
  numberOfNights: number | null;
  placeToStay: string | null;
  estimateCostsOfStay?: number;
  currency?: Data;
  neededAdvance: boolean;
  accommodationReservationBy?:
    | ReservationProviderData
    | { value: string; name: string };
  accommodationReservationLink?: string;
  accommodationInformation?: string;
  insuranceProvidedBy?: InsuranceProviderData | { value: string; name: string };
  advanceDescription?: string;
  otherInformation?: string;
  clientAcceptance?: boolean;
  id?: number;
}
export interface SummaryProps {
  additionalAccounts: Data[];
}

export interface TripSectionProps {
  control: Control<BusinessTripFormValues>;
  editClicked?: boolean;
  errors?: DeepMap<BusinessTripFormValues, FieldError>;
  getValues?: () => BusinessTripFormValues;
  setValue?: (
    name: keyof BusinessTripFormValues,
    value: BusinessTripFormValues[keyof BusinessTripFormValues],
    config?: { shouldValidate?: boolean },
  ) => void;
  files?: File[];
  setFiles?: Dispatch<SetStateAction<File[]>>;
  setNeedsClientsAcceptance?: Dispatch<SetStateAction<boolean>>;
  previousData?: BusinessTrip;
  isAbroad?: boolean;
  defaultReservationBy?: {
    value: ReservationProviderTypes;
    name: string;
  };
}

export interface AccommodationProps extends TripSectionProps {
  currenciesData: CurrenciesData[];
}

export interface DescriptionProps extends TripSectionProps {
  projectsData: ProjectsData[];
  clientsData: ClientsData[];
  employeesData: FilterOption[];
  countriesDietsData: CountryDiet[];
  handleAddEmployee: () => void;
  addedEmployees: Data[];
  deleteAdditionalEmployee: (
    deletedEmployeeId: number,
  ) => (_e: MouseEvent) => void;
  handleClientChange: () => void;
}

export interface UserData {
  name: string;
  surname: string;
  personalId: string;
  contractType?: Data;
}

export enum TransportationTypes {
  PLANE = 'PLANE',
  TRAIN = 'TRAIN',
  SHIP = 'SHIP',
  BUS = 'BUS',
  PERSONAL_CAR = 'PERSONAL_CAR',
  COMPANY_CAR = 'COMPANY_CAR',
}

export enum ReservationProviderTypes {
  JIT_RESERVATION = 'JIT_RESERVATION',
  CUSTOMER_RESERVATION = 'CUSTOMER_RESERVATION',
  CONSULTANT = 'CONSULTANT',
  WECO_TRAVEL = 'WECO_TRAVEL',
  WORK_TRIPS = 'WORK_TRIPS',
}

export enum InsuranceProviderTypes {
  JIT_INSURANCE = 'JIT_INSURANCE',
  CUSTOMER_INSURANCE = 'CUSTOMER_INSURANCE',
  NO_INSURANCE = 'NO_INSURANCE',
}

export enum ContractTypes {
  B2B = 'B2B',
  CIVIL_CONTRACT = 'CIVIL_CONTRACT',
  EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
}

export interface SelectDataForm<T> {
  value: T;
  name: string;
}

export interface CurrenciesData extends SelectDataForm<number> {}

export interface TransportationData
  extends SelectDataForm<TransportationTypes> {}

export interface ReservationProviderData
  extends SelectDataForm<ReservationProviderTypes> {}

export interface InsuranceProviderData
  extends SelectDataForm<InsuranceProviderTypes> {}

export interface SummarySectionProps {
  businessTripSectionData: {
    name: string;
    content: string | number | null | undefined | CountryDiet;
  }[];
  title: string;
}
export interface AdditionalAccountsSectionProps {
  additionalAccounts: Data[];
  title: string;
}
