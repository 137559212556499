import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid } from '@material-ui/core';
import { BENEFIT_MEMBER_TYPES } from 'components/BenefitInputs/types';
import {
  AddIcon,
  EditIcon,
  RemoveIcon,
  TableCell,
  TableRow,
  TrashIcon,
} from 'components/Table/Table.css';
import IconWithTooltip from 'modules/AdminPanel/Screens/BenefitsManager/components/BenefitsTable/IconWithTooltip';
import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext/BenefitContext';
import { ActionKind } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import { BenefitsDataProps } from 'modules/UserProfile/Screens/Benefits/types';
import {
  displayFormattedDate,
  getActionLabel,
  getBenefitTypeInfoByName,
  getSinglePersonBenefitCost,
} from 'modules/UserProfile/Screens/Benefits/utils';
import { BenefitsTableRowProps } from './types';

const BenefitsTableRow = ({
  entry,
  index,
  benefitCost,
  benefitsInfo,
}: BenefitsTableRowProps) => {
  const intl = useIntl();
  const { state, dispatch } = useContext(BenefitContext);
  const { employee } = BENEFIT_MEMBER_TYPES;

  const setBenefitData = useCallback(() => {
    const { generalInfo, typeInfo } = getBenefitTypeInfoByName(
      benefitsInfo,
      entry.benefitName,
      entry.benefitType?.name,
    );
    if (generalInfo && typeInfo) {
      dispatch({
        type: ActionKind.SetBenefitData,
        payload: entry,
      });
      dispatch({
        type: ActionKind.SetSelectedBenefitData,
        payload: {
          setSelectedBenefit: generalInfo,
          setSelectedBenefitType: typeInfo,
        },
      });
    } else {
      dispatch({
        type: ActionKind.SetBenefitData,
        payload: entry,
      });
    }
  }, [benefitsInfo, dispatch, entry]);

  const handleAddIcon = useCallback(() => {
    dispatch({ type: ActionKind.AddNewPartner });
  }, [dispatch]);

  const handleTrashIcon = useCallback(async () => {
    dispatch({
      type: ActionKind.RemoveBenefit,
      payload: { setSelectedBenefit: entry },
    });
  }, [dispatch, entry]);

  const handleRemoveIcon = useCallback(() => {
    dispatch({ type: ActionKind.RemovePartner });
    setBenefitData();
  }, [dispatch, setBenefitData]);

  const handleEditIcon = useCallback(() => {
    dispatch({ type: ActionKind.EditData });
    setBenefitData();
  }, [dispatch, setBenefitData]);

  const renderActionIcon = (iconName: string) => {
    let label, content, action;

    const isDisabled = iconName !== TrashIcon; //  && entry.benefitPackage?.type !== Packages.family;
    // commented until next steps of connecting with backend

    switch (iconName) {
      case EditIcon:
        label = getActionLabel('editIcon', {
          disabled: isDisabled,
          memberType: entry.employee.memberType,
          editable: true,
        });
        content = EditIcon;
        action = handleEditIcon;
        break;
      case TrashIcon:
        label = getActionLabel('trashIcon', { disabled: false });
        content = TrashIcon;
        action = handleTrashIcon;
        break;
      case RemoveIcon:
        label = getActionLabel('removeIcon', { disabled: isDisabled });
        content = RemoveIcon;
        action = handleRemoveIcon;
        break;
      default:
        label = getActionLabel('addIcon', { disabled: isDisabled });
        content = AddIcon;
        action = handleAddIcon;
        break;
    }

    return (
      <Grid item xs={12}>
        <IconWithTooltip
          icon={content}
          handleClick={action}
          disabled={isDisabled}
          title={intl.formatMessage({
            id: `TABLE.BODY.BENEFIT.${label}`,
          })}
        />
      </Grid>
    );
  };

  const renderActionCell = (memberType?: BENEFIT_MEMBER_TYPES) => {
    if (memberType !== employee) {
      return (
        <Box display="flex" alignItems="center">
          {renderActionIcon(RemoveIcon)}
          {renderActionIcon(EditIcon)}
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        {renderActionIcon(AddIcon)}
        {renderActionIcon(EditIcon)}
        {renderActionIcon(TrashIcon)}
      </Box>
    );
  };

  const renderEmployeeData = () => (
    <>
      <TableCell align="center">{`${entry.employee.firstName} ${entry.employee.surname}`}</TableCell>
      <TableCell align="center">{entry.benefitType?.name || '-'}</TableCell>
      <TableCell align="center">
        {entry.benefitPackage
          ? intl.formatMessage({
              id: `TABLE.BODY.BENEFIT.${getFormattedName(
                entry.benefitPackage.type,
              )}`,
            })
          : '-'}
      </TableCell>
      <TableCell align="center">{benefitCost.cost}</TableCell>
      <TableCell align="center">
        {displayFormattedDate(entry.benefitDates.dateWhenBenefitStarts)}
      </TableCell>
      <TableCell align="center">
        {displayFormattedDate(entry.benefitDates.dateWhenBenefitEnds)}
      </TableCell>
      <TableCell align="center">
        {intl.formatMessage({
          id: `TABLE.BODY.BENEFIT.${getFormattedName(
            entry?.benefitStatus.type,
          )}`,
        })}
      </TableCell>
    </>
  );

  const renderPartnerData = () => {
    return (
      entry.partner && (
        <TableRow key={`${entry.benefitName}_PARTNER - ${index}`}>
          <TableCell></TableCell>
          <TableCell align="center">{`${entry.partner.firstName} ${entry.partner.surname}`}</TableCell>
          <TableCell align="center">{entry.benefitType?.name || '-'}</TableCell>
          <TableCell align="center">
            {entry.benefitPackage
              ? intl.formatMessage({
                  id: `TABLE.BODY.BENEFIT.${getFormattedName(
                    entry.benefitPackage.type,
                  )}`,
                })
              : '-'}
          </TableCell>
          <TableCell align="center">
            {getSinglePersonBenefitCost({
              benefitId: entry.benefitId,
              benefitTypeId: entry.benefitTypeId,
              benefitsInfo,
              person: 'partner',
              billableBy: benefitCost.billableBy,
            })}
          </TableCell>
          <TableCell align="center">
            {displayFormattedDate(entry.benefitDates.dateWhenBenefitStarts)}
          </TableCell>
          <TableCell align="center">
            {displayFormattedDate(entry.benefitDates.dateWhenBenefitEnds)}
          </TableCell>
          <TableCell align="center">
            {intl.formatMessage({
              id: `TABLE.BODY.BENEFIT.${getFormattedName(
                entry?.benefitStatus.type,
              )}`,
            })}
          </TableCell>
          <TableCell align="center">
            {renderActionCell(entry.partner.memberType)}
          </TableCell>
        </TableRow>
      )
    );
  };

  const renderChildrenData = () => {
    return entry.children
      ? entry.children.map((child) => (
          <TableRow key={`${entry.benefitName}_PARTNER - ${index}`}>
            <TableCell></TableCell>
            <TableCell align="center">{`${child.firstName} ${child.surname}`}</TableCell>
            <TableCell align="center">
              {entry.benefitType?.name || '-'}
            </TableCell>
            <TableCell align="center">
              {entry.benefitPackage
                ? intl.formatMessage({
                    id: `TABLE.BODY.BENEFIT.${getFormattedName(
                      entry.benefitPackage.type,
                    )}`,
                  })
                : '-'}
            </TableCell>
            <TableCell align="center">
              {getSinglePersonBenefitCost({
                benefitId: entry.benefitId,
                benefitTypeId: entry.benefitTypeId,
                benefitsInfo,
                person: 'partner',
                billableBy: benefitCost.billableBy,
              })}
            </TableCell>
            <TableCell align="center">
              {displayFormattedDate(entry.benefitDates.dateWhenBenefitStarts)}
            </TableCell>
            <TableCell align="center">
              {displayFormattedDate(entry.benefitDates.dateWhenBenefitEnds)}
            </TableCell>
            <TableCell align="center">
              {intl.formatMessage({
                id: `TABLE.BODY.BENEFIT.${getFormattedName(
                  entry?.benefitStatus.type,
                )}`,
              })}
            </TableCell>
            <TableCell align="center">
              {renderActionCell(child?.memberType)}
            </TableCell>
          </TableRow>
        ))
      : null;
  };

  const renderMainRows = (entry: BenefitsDataProps, index: number) => (
    <TableRow key={`${entry.benefitName} - ${index}`}>
      <TableCell>{entry.benefitName}</TableCell>
      {renderEmployeeData()}
      <TableCell align="center">
        {renderActionCell(entry.employee.memberType)}
      </TableCell>
    </TableRow>
  );

  const renderPartnersRows = (entry: BenefitsDataProps, index: number) => (
    <>
      {renderMainRows(entry, index)}
      {renderPartnerData()}
      {renderChildrenData()}
    </>
  );

  const renderRows = () => {
    return state.renderPartners
      ? renderPartnersRows(entry, index)
      : renderMainRows(entry, index);
  };

  return <>{renderRows()}</>;
};

export default BenefitsTableRow;
