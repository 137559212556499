import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { DateProvider } from 'globalContext/DateContext';
import { GlobalProvider } from './globalContext/GlobalContext';
import { UserProvider } from './globalContext/UserContext';
import App from './App';
import { msalConfig } from './authConfig';
import * as serviceWorker from './serviceWorker';
import theme from './themes';
import './index.css';

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any;
    _env_: any;
  }
}

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    const account = msalInstance.getActiveAccount();
    if (!account && !localStorage.getItem('tpToken')) {
      localStorage.removeItem('tpToken');
      msalInstance.loginRedirect();
    }
  })
  .catch((err) => {
    console.error(err);
  });

ReactDOM.render(
  <StrictMode>
    <GlobalProvider>
      <DateProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <MsalProvider instance={msalInstance}>
              <Router>
                <App />
              </Router>
            </MsalProvider>
          </ThemeProvider>
        </UserProvider>
      </DateProvider>
    </GlobalProvider>
  </StrictMode>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
