import { Dispatch, SetStateAction } from 'react';
import {
  benefitsCostsSummaryUrl,
  benefitsUrl,
  bugReportIssueCollectorScript,
  clientsDetailsUrl,
  employeesUrl,
  projectsOrderedByNameUrl,
  timesheetUserReminderUrl,
} from 'router/url';
import { Data } from 'components/Dialog/types';
import { UploadHistoryEntry } from 'modules/AdminPanel/Screens/BenefitsManager/types';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { ClientDataProps, EmployeeDataProps } from 'modules/ManagerForm/types';
import { joinNames } from './helpers/joinNames';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
  renameClientKeys,
  renameProjectKeys,
} from './helpers/renameKeys';
import { getFetch } from './fetchFunctions';

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any;
  }
}

export const fetchProjectList = (
  setProjectsData: Dispatch<SetStateAction<ProjectsData[]>>,
  showInactive?: boolean,
): void => {
  getFetch({
    url:
      `${projectsOrderedByNameUrl}` +
      `${showInactive ? '?showInactive=true' : ''}`,
  }).then((projectsResponse) => {
    if (projectsResponse) {
      setProjectsData(
        renameProjectKeys({
          filterOptionsArray: projectsResponse.map(
            ({ id, ...obj }: { id: number; obj: Object }) => ({
              value: id,
              ...obj,
            }),
          ),
          oldKeyName: 'projectName',
          newKeyName: 'name',
        }),
      );
    }
  });
};
export const fetchClientList = (
  setClientsData: Dispatch<SetStateAction<ClientsData[]>>,
): void => {
  getFetch({ url: clientsDetailsUrl }).then((clientsResponse) => {
    if (clientsResponse) {
      setClientsData(
        renameClientKeys({
          filterOptionsArray: clientsResponse.map(
            ({ id, ...obj }: { id: number; obj: Object }) => ({
              value: id,
              ...obj,
            }),
          ),
          oldKeyName: 'clientName',
          newKeyName: 'name',
        }),
      );
    }
  });
};
export const fetchEmployeeList = (
  setEmployeesData: Dispatch<SetStateAction<FilterOption[]>>,
  contractType?: ContractTypes,
): void => {
  getFetch({
    url: contractType ? `${employeesUrl}/${contractType}` : employeesUrl,
  }).then((employeesResponse) => {
    // TODO: Unhandled Rejection (TypeError) map of undefined - quick fix for mapping through responses
    setEmployeesData(employeesResponse.map(joinNames));
  });
};

// This function fetch script from backend for Jira Issue Collector
//Link to docs: https://confluence.jit.team/display/TIM2/JIRA+Issue+Collector
export const fetchJiraIssueCollectorScripts = async () => {
  const findScript = document.querySelector('.jiraScripts');

  if (!findScript) {
    const jiraScripts = document.createElement('script');

    //This is a triggering function from Jira Issue Collector docs
    //for custom trigger
    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog: () => void) {
        document
          .querySelector('.bugReportButton')!
          .addEventListener('click', () => {
            showCollectorDialog();
          });
      },
    };

    jiraScripts.type = 'text/javascript';
    jiraScripts.classList.add('jiraScripts');
    //Response body from backend is text not json so we have to use content type text/plain
    jiraScripts.innerHTML = await getFetch({
      url: bugReportIssueCollectorScript,
      contentType: 'text',
    });

    document.body.prepend(jiraScripts);
  }
};

export const fetchEmplayeeListDialog = async (): Promise<Data[]> => {
  const employeeResponse = await getFetch({
    url: employeesUrl,
  });

  return employeeResponse.map(({ name, surname, id }: EmployeeDataProps) => ({
    value: id,
    name: `${name} ${surname}`,
  }));
};

export const fetchClientListDialog = async (): Promise<Data[]> => {
  const clientResponse = await getFetch({
    url: clientsDetailsUrl,
  });

  return clientResponse.map(({ clientName: name, id }: ClientDataProps) => ({
    value: id,
    name,
  }));
};

const DAY_IN_MILLISECONDS = 60 * 60 * 24 * 1000;

export const checkIfRemiderSent = async (
  urlEndpoint: string,
  date: Date | null,
  setState: Dispatch<SetStateAction<boolean>>,
) => {
  const response = await getFetch({
    url: `${timesheetUserReminderUrl}?month=${
      date!.getMonth() + 1
    }&year=${date!.getFullYear()}&endpointName=${urlEndpoint}`,
  });
  if (!response.content.length) {
    setState(false);
  } else {
    const lastReminderSent =
      response.content[response.content.length - 1].lastUsage;
    const dayAfterSent = new Date(
      new Date(lastReminderSent).getTime() + DAY_IN_MILLISECONDS,
    );
    setState(dayAfterSent > new Date());
  }
};

export const fetchBenefitsUploadHistory = async (yearMonth: string, setState: Dispatch<SetStateAction<UploadHistoryEntry[]>>) => {
  const response = await getFetch({
    url: `${benefitsUrl}/${benefitsCostsSummaryUrl}?yearMonth=${yearMonth}`
  })
  setState(response)
  return response
}