import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { benefitsCostsSummaryUrl, benefitsUrl } from 'router/url';
import ControlledCheckbox from 'components/ControlledCheckbox';
import ControlledDatePicker from 'components/ControlledDatePicker';
import { ContentDialog } from 'components/Dialog/ContentDialog';
import FilesUploadArea from 'components/FileUploadArea/FilesUploadArea';
import { Tooltip } from 'components/Tooltip';
import { postFetch } from 'utils/fetchFunctions';
import {
  UploadCostsSummaryDialogProps,
  UploadCostsSummaryForm,
} from './UploadCostsSummaryDialog.types';
import {
  CheckboxWrapper,
  ContentWrapper,
  SummaryInformationWrapper,
} from './UploadCostsSummaryDialog.css';

const yearMonthFormat = 'yyyy-MM';

export const UploadCostsSummaryDialog = ({
  isCostsSummaryDialogOpen,
  setIsCostsSummaryDialogOpen,
}: UploadCostsSummaryDialogProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const intl = useIntl();

  const { control, getValues } = useForm<UploadCostsSummaryForm>({
    defaultValues: {
      yearMonth: format(new Date(), yearMonthFormat),
      informOnUpdate: true,
    },
  });

  const handleSetSelectedFiles = (costsSummaryFiles: File[]) => {
    setSelectedFiles(costsSummaryFiles);
  };

  const handleSubmitForm = async () => {
    const { yearMonth, informOnUpdate } = getValues();

    selectedFiles.map(async (file) => {
      const requestBodyData = {
        file,
        yearMonth: format(new Date(yearMonth), yearMonthFormat),
        informOnUpdate: informOnUpdate.toString(),
      };

      const formData = new FormData();

      for (const [key, value] of Object.entries(requestBodyData)) {
        formData.append(key, value);
      }

      try {
        const response = await postFetch({
          url: `${benefitsUrl}/${benefitsCostsSummaryUrl}`,
          body: formData,
          contentType: 'formdata',
          label: 'BENEFITS_COSTS_SUMMARY',
        });

        if (response.ok) {
          toast.success(
            intl.formatMessage(
              { id: 'BENEFITS_COSTS_SUMMARY.TOAST_ACCEPT' },
              { fileName: file.name },
            ),
          );
        }
      } catch (e) {
        toast.error(
          intl.formatMessage(
            { id: 'BENEFITS_COSTS_SUMMARY.TOAST_DENIED' },
            { fileName: file.name },
          ),
        );
      }
    });

    setIsCostsSummaryDialogOpen(false);
  };

  return (
    <ContentDialog
      isOpen={isCostsSummaryDialogOpen}
      setIsOpen={setIsCostsSummaryDialogOpen}
      headerTitle="BENEFITS.UPLOAD_COSTS_SUMMARY"
      confirmButtonContent="DIALOG.SEND"
      handleConfirm={handleSubmitForm}
      disableConfirmButton={!selectedFiles.length}
    >
      <ContentWrapper>
        <form onSubmit={handleSubmitForm}>
          <FilesUploadArea handleFileChange={handleSetSelectedFiles} />
          <SummaryInformationWrapper>
            <ControlledDatePicker
              name="yearMonth"
              label={intl.formatMessage({
                id: 'BENEFITS_COSTS_SUMMARY.DATE',
              })}
              views={['year', 'month']}
              format={yearMonthFormat}
              control={control}
            />
            <CheckboxWrapper>
              <ControlledCheckbox
                name="informOnUpdate"
                control={control}
                label="BENEFITS_COSTS_SUMMARY.INFORM_ON_UPDATE"
                placement="end"
              />
              <Tooltip
                id="info-icon"
                icon={<InfoOutlinedIcon />}
                hoverText={intl.formatMessage({
                  id: 'BENEFITS_COSTS_SUMMARY.INFORM_ON_UPDATE_ICON',
                })}
              />
            </CheckboxWrapper>
          </SummaryInformationWrapper>
        </form>
      </ContentWrapper>
    </ContentDialog>
  );
};
