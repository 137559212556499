import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Tooltip,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { UserContext } from 'globalContext/UserContext';
import ControlledCheckbox from 'components/ControlledCheckbox';
import { HeaderTooltipText, InformationIcon } from 'components/Table/Table.css';
import { RoleTypes } from 'utils/rolesTypes';
import { TimesheetFiltersProps } from './types';
import {
  TimesheetsFilterDrawerGrid,
  TimesheetsFilterToolbar,
  TimesheetsFilterTypography,
} from './Timesheet.css';

const TimesheetFilters = ({
  control,
  loading,
  setOpen,
  open,
  client,
  project,
}: TimesheetFiltersProps) => {
  const intl = useIntl();
  const [isAuthorisedToSeeAllTimesheets, setIsAuthorisedToSeeAllTimesheets] =
    useState(false);
  const { roles } = useContext(UserContext);

  useEffect(() => {
    setIsAuthorisedToSeeAllTimesheets(
      roles.includes(RoleTypes.RoleAccounting) ||
        roles.includes(RoleTypes.RoleDEVHR),
    );
  }, [roles]);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={setOpen}
      onOpen={setOpen}
    >
      <Box role="presentation">
        <TimesheetsFilterToolbar>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton size="medium" edge="start" onClick={setOpen}>
                <ArrowForwardIos />
              </IconButton>
            </Grid>
            <TimesheetsFilterDrawerGrid item xs={11}>
              <TimesheetsFilterTypography>
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.FILTERS',
                })}
              </TimesheetsFilterTypography>
            </TimesheetsFilterDrawerGrid>
          </Grid>
        </TimesheetsFilterToolbar>
        <List>
          <ListItem>
            <ControlledCheckbox
              label="TABLE_FILTER.SHOW_NOT_READY_TIMESHEETS"
              name="readyForApprovalChecked"
              control={control}
              placement="end"
              disabled={loading}
            />
          </ListItem>
          <ListItem>
            {isAuthorisedToSeeAllTimesheets ? (
              <ControlledCheckbox
                label="TABLE_FILTER.SHOW_OWN_TIMESHEETS"
                name="myTimesheetsChecked"
                control={control}
                placement="end"
                disabled={loading}
              />
            ) : (
              <>
                <ControlledCheckbox
                  label="TABLE_FILTER.SHOW_OWN_TIMESHEETS"
                  name="myTimesheetsChecked"
                  control={control}
                  placement="end"
                  disabled
                  defaultChecked
                />
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: 'TABLE_FILTER.NOT_AUTHORIZED',
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <InformationIcon />
                </Tooltip>
              </>
            )}
          </ListItem>
          <ListItem>
            <ControlledCheckbox
              label="TABLE_FILTER.SHOW_EMPTY_TIMESHEETS"
              name="emptyTimesheetsChecked"
              control={control}
              placement="end"
              disabled={loading}
            />
          </ListItem>
          <ListItem>
            <ControlledCheckbox
              label="TABLE_FILTER.LABEL.APPROVED"
              name="approvedTimesheetsChecked"
              control={control}
              placement="end"
              disabled={loading}
            />
          </ListItem>
          {(client?.name || project?.name) && (
            <ListItem>
              <ControlledCheckbox
                label="TABLE_FILTER.LABEL.SHOW_FULL_TIMESHEET"
                name="showFullTimesheet"
                control={control}
                placement="end"
                disabled={loading}
              />
            </ListItem>
          )}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default TimesheetFilters;
