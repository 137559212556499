import palette from 'assets/palette';
import styled from 'styled-components';

export const InformationParagraph = styled.div`
  text-align: justify;
  width: 85%;
  padding: 10px 10px;
  margin: 10px auto;
  border: 1px solid ${palette.ACTION};
  border-radius: 1%;
  font-size: 12px;
`;
