import { timesheetsUrl } from 'router/url';
import { TimesheetUrlProps } from './types';

export const getTimesheetUrlWithParams = ({
  paginationNumber,
  paginationSize,
  month,
  year,
  accountValue,
  clientValue,
  projectValue,
  approvedTimesheetsChecked,
  emptyTimesheetsChecked,
  showFullTimesheet,
  myTimesheetsChecked,
  readyForApprovalChecked,
}: TimesheetUrlProps) =>
  `${timesheetsUrl}/summary` +
  `?size=${paginationSize}&page=${paginationNumber}` +
  `${typeof year === 'number' ? `&year=${year}` : ''}` +
  `${typeof month === 'number' ? `&month=${month + 1}` : ''}` +
  `${accountValue ? `&accountId=${accountValue}` : ''}` +
  `${projectValue ? `&projectId=${projectValue}` : ''}` +
  `${clientValue ? `&clientId=${clientValue}` : ''}` +
  `${!approvedTimesheetsChecked ? '&hideApprovedTimesheets=true' : ''}` +
  `${emptyTimesheetsChecked ? '&showEmpty=true' : ''}` +
  `${showFullTimesheet ? '&showFullTimesheet=true' : ''}` +
  `${myTimesheetsChecked ? '&showOnlyCurrentUserToAccept=true' : ''}` +
  `${readyForApprovalChecked ? '&readyForApproval=true' : ''}`;
