import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { businessTripsUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import ControlledTextField from 'components/ControlledTextField';
import { TripBillabilityStatus } from 'modules/BusinessTrips/types';
import { patchFetch } from 'utils/fetchFunctions';
import { getBillingStatusOptions } from '../utils/getBillingStatusOptions';
import { TripBillingDialogProps, TripBillingForm } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';
import {
  CustomControlledRadioInput,
  CustomTypography,
} from './TripBillingDialog.css';

const TripBillingDialog = ({
  id,
  open,
  handleClose,
  onSubmit,
  billableToEdit,
  billableDetailsToEdit,
}: TripBillingDialogProps) => {
  const intl = useIntl();
  const { control, watch, getValues, handleSubmit } = useForm<TripBillingForm>({
    mode: 'onChange',
    defaultValues: {
      billable: billableToEdit,
      billableDetails: billableDetailsToEdit,
    },
  });

  const billable = watch('billable');
  const billableDetails = watch('billableDetails');

  const isPartiallyBillable = useMemo(
    () => billable === TripBillabilityStatus.PARTLY,
    [billable],
  );

  const isFormValid = useMemo(
    () => (isPartiallyBillable ? !!billableDetails : billable),
    [billable, isPartiallyBillable, billableDetails],
  );

  const patchBillingStatus = useCallback(async () => {
    const billingForm = getValues();

    await patchFetch({
      url: `${businessTripsUrl}/${id}/billable`,
      intl,
      body: {
        billable: billingForm?.billable,
        billableDetails: billingForm?.billableDetails,
      },
      label: 'BILLABILITY',
    });
  }, [intl, id, getValues]);

  const handleSubmitForm = handleSubmit(async () => {
    await patchBillingStatus();
    if (onSubmit) {
      onSubmit(true);
    }
    handleClose();
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={10} item container justify="flex-start">
            {intl.formatMessage({ id: 'BUSINESS_TRIP.BILLABLITY_TITLE' })}
          </Grid>
          <Grid xs={2} item container justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <form onSubmit={handleSubmitForm}>
        <GridWrapper xs={12} container alignItems="center" justify="center">
          <Grid xs={11} item>
            <CustomTypography>
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.BILLABLITY_FORM_TITLE',
              })}
            </CustomTypography>
          </Grid>
          <Grid xs={11} item>
            <CustomControlledRadioInput
              control={control}
              name="billable"
              data={getBillingStatusOptions(intl)}
              defaultValue={billableToEdit}
            />
          </Grid>
          {isPartiallyBillable && (
            <Grid xs={11} item>
              <ControlledTextField
                name="billableDetails"
                variant="outlined"
                control={control}
                label="BUSINESS_TRIP.COMMENT_TITLE"
                rows={4}
                defaultValue={billableDetailsToEdit}
                isMulti
              />
            </Grid>
          )}
        </GridWrapper>
      </form>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={handleClose}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={handleSubmitForm}
            size="large"
            color="primary"
            type="submit"
            disabled={!isFormValid}
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.SUBMIT' })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};
export default TripBillingDialog;
