import { Collapse, Grid, ListItem } from '@material-ui/core';
import styled from 'styled-components';
import { SummaryGridProps } from 'modules/UserProfile/Screens/Benefits/types';

export const SummaryCollapse = styled(Collapse)`
  &.MuiCollapse-root {
    width: 100%;
  }
`;

export const SummaryListItem = styled(ListItem)`
  &.MuiButtonBase-root {
    border-bottom: 1px solid #ccc;
  }
`;

export const SummaryGrid = styled(Grid)<SummaryGridProps>`
  &.MuiGrid-root {
    ${({ $address }) =>
      $address
        ? `padding-left: 1rem; justify-content: center`
        : `padding: 1rem 0; justify-content: start`};
  }
`;
