import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from './types';
import { FormControl, Label, Select as SelectComponent } from './Select.css';

function Select({ label, options, value, onChange, $isSmall }: SelectProps) {
  return (
    <FormControl>
      <Label>{label}</Label>
      <SelectComponent
        id="languageButton"
        onChange={(event) => onChange(event?.target?.value)}
        value={value}
        autoWidth
        MenuProps={{
          disableScrollLock: true,
        }}
        $isSmall={$isSmall}
      >
        {options.map(({ label, value }, index) => (
          <MenuItem
            id={label}
            value={value}
            key={`${label} - ${value} - ${index}`}
          >
            {label}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  );
}

export default Select;
