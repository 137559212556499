import { QueryParameters } from 'utils/encoding/encodeQueryParamsString';
import { FilterOption } from 'utils/helpers/renameKeys';

export enum Filtering {
  dateFrom = 'dateFrom',
  default = 'default',
  project = 'project',
  client = 'client',
  dateTo = 'dateTo',
  name = 'name',
}

export enum FilterComponent {
  datepicker = 'datepicker',
  selectFilter = 'selectFilter',
}

export interface ReportsFilter {
  options?: Array<FilterOption>;
  component: FilterComponent;
  type: Filtering;
  label: string;
  handleChange?: () => void;
}

export interface FetchingUrlArgument {
  queryParams?: QueryParameters;
  typeOfFile?: 'csv' | 'xlsx';
}

type StringValue = string | null | undefined;
type DateValue = string | Date | null;

export interface FormDataProps {
  project: {
    projectName?: string;
    client?: {
      clientName: string;
      id: number;
    };
  };
  client: {
    clientName?: string;
  };
  name: {
    value: StringValue;
    name: StringValue;
  };
  dateFrom: DateValue;
  dateTo: DateValue;
}
