import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { getActionsValues } from '../inputHelpers';
import InfoGroup from './InfoGroup';
import { InfoContainer, InputTitle } from '../../../BenefitsManager.css';

const Actions = () => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  return (
    <InfoGroup
      title={intl.formatMessage({ id: 'BENEFITS.ACTIONS' })}
      justifyStart
    >
      {getActionsValues(intl, formState)?.map((value) =>
        value.content ? (
          <InfoContainer key={value.title.replaceAll(' ', '_')} item xs={12}>
            <InputTitle>{value.title}:</InputTitle>
            <span>{value.content}</span>
          </InfoContainer>
        ) : null,
      )}
    </InfoGroup>
  );
};

export default Actions;
