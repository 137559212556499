import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';
import { TripStatuses, TripStatusesOption } from './types';

export const MAX_INPUT_LENGTH = 255;

export const TRIPS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.TITLE',
    key: 'tripReason',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.NAME',
    key: 'name',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleAccounting,
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.STATUS',
    key: 'status',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.DATES',
    key: 'dates',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.DESCRIPTION',
    key: 'details',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.ACTIONS',
    key: 'actions',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
];

export const STATUS_LIST = [
  { name: 'DRAFT' },
  { name: 'SENT' },
  { name: 'FINISHED' },
  { name: 'SETTLED' },
  { name: 'CANCELED' },
];

export const BUSINESS_TRIPS_STATUSES: TripStatusesOption[] = [
  {
    value: TripStatuses.APPROVED,
    name: 'BUSINESS_TRIP.STATUS.APPROVED',
  },
  {
    value: TripStatuses.CANCELED,
    name: 'BUSINESS_TRIP.STATUS.CANCELED',
  },
  {
    value: TripStatuses.DOCS_TO_BE_VERIFIED,
    name: 'BUSINESS_TRIP.STATUS.DOCS_TO_BE_VERIFIED',
  },
  {
    value: TripStatuses.DOCS_TO_UPDATE,
    name: 'BUSINESS_TRIP.STATUS.DOCS_TO_UPDATE',
  },
  {
    value: TripStatuses.FINISHED,
    name: 'BUSINESS_TRIP.STATUS.FINISHED',
  },
  {
    value: TripStatuses.REJECTED_BY_LEADER,
    name: 'BUSINESS_TRIP.STATUS.REJECTED_BY_LEADER',
  },
  {
    value: TripStatuses.RESERVATION_DONE,
    name: 'BUSINESS_TRIP.STATUS.RESERVATION_DONE',
  },
  {
    value: TripStatuses.RESERVATION_TO_BE_DONE,
    name: 'BUSINESS_TRIP.STATUS.RESERVATION_TO_BE_DONE',
  },
  {
    value: TripStatuses.SETTLED,
    name: 'BUSINESS_TRIP.STATUS.SETTLED',
  },
  {
    value: TripStatuses.TO_BE_APPROVED_BY_LEADER,
    name: 'BUSINESS_TRIP.STATUS.TO_BE_APPROVED_BY_LEADER',
  },
  {
    value: TripStatuses.TO_BE_FINALLY_APPROVED_BY_LEADER,
    name: 'BUSINESS_TRIP.STATUS.TO_BE_FINALLY_APPROVED_BY_LEADER',
  },
  {
    value: TripStatuses.UPDATED,
    name: 'BUSINESS_TRIP.STATUS.UPDATED',
  },
  {
    value: TripStatuses.CREATED,
    name: 'BUSINESS_TRIP.STATUS.CREATED',
  },
  {
    value: TripStatuses.DELETED,
    name: 'BUSINESS_TRIP.STATUS.DELETED',
  },
  {
    value: TripStatuses.DRAFT,
    name: 'BUSINESS_TRIP.STATUS.DRAFT',
  },
];
