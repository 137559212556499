import { IntlShape } from 'react-intl';

const MAX_COUNT_OF_NUMBERS = 6;
const MAX_MULTIPLICATOR_VALUE = 99;

export const getHelperText = (intl: IntlShape) => {
  return {
    maxLength: intl.formatMessage({
      id: 'PROJECT_DIALOG.MULTIPLICATOR_VALIDATION.MAX_LENGTH',
    }),
    maxValue: intl.formatMessage({
      id: 'PROJECT_DIALOG.MULTIPLICATOR_VALIDATION.MAX_VALUE',
    }),
  };
};

const maxValue = (intl: IntlShape) => ({
  value: MAX_MULTIPLICATOR_VALUE,
  message: getHelperText(intl).maxValue,
});

const maxLength = (intl: IntlShape) => ({
  value: MAX_COUNT_OF_NUMBERS,
  message: getHelperText(intl).maxLength,
});

export const validateProjects = (intl: IntlShape) => {
  return {
    maxValue: {
      maxLength: maxLength(intl),
      max: maxValue(intl),
    },
  };
};

export const getMultiplicatorValue = (multiplicator?: number | null) => {
  if (multiplicator === 0) {
    return '0';
  }
  if (multiplicator) {
    return multiplicator;
  }
  return 1;
};
