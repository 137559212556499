import { useCallback, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import ContainedButton from 'components/Button/ContainedButton';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext/index';
import { ActionKind } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import { ButtonWrapper } from 'modules/UserProfile/Screens/Benefits/BenefitsTab.css';
import { BenefitSelectProps } from 'modules/UserProfile/Screens/Benefits/types';
import { getFilteredBenefitNames } from 'modules/UserProfile/Screens/Benefits/utils';

const BenefitSelect = ({
  benefitsFromTable,
  benefitsOptions,
  loading,
}: BenefitSelectProps) => {
  const { dispatch } = useContext(BenefitContext);
  const { control, setValue, reset } = useForm<FormData>();
  const { benefit, benefitType } = useWatch({ control });
  const intl = useIntl();

  const isBenefitTypeDisabled = !benefit?.types;
  const isButtonDisabled = !benefit || (benefit?.types ? !benefitType : false);

  const onSubmit = useCallback(() => {
    dispatch({
      type: ActionKind.AddNewBenefit,
      payload: { setBenefit: benefit, setBenefitType: benefitType },
    });
    reset();
  }, [dispatch, benefit, benefitType, reset]);

  const clearBenefitType = useCallback(() => {
    if (benefit) {
      setValue('benefitType', '');
    }
  }, [benefit, setValue]);

  useEffect(() => {
    clearBenefitType();
  }, [clearBenefitType]);

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid xs={12} sm={6} md={3} item>
          <ControllerSelect
            label="USER_PROFILE.BENEFITS"
            name="benefit"
            control={control}
            options={getFilteredBenefitNames(
              benefitsOptions,
              benefitsFromTable,
            )}
            isClearable={false}
            isDisabled={loading}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <ControllerSelect
            label="USER_PROFILE.BENEFITS.FORM.BENEFIT_TYPE"
            name="benefitType"
            control={control}
            options={benefit?.types}
            isDisabled={isBenefitTypeDisabled}
          />
        </Grid>
        <Grid sm={12} md={2} item>
          <ButtonWrapper>
            <ContainedButton
              endIcon={<Add />}
              size="large"
              onClick={onSubmit}
              disabled={isButtonDisabled}
            >
              {intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.ADD' })}
            </ContainedButton>
          </ButtonWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default BenefitSelect;
