import { Dispatch } from 'react';
import {
  BenefitManagerFormValues,
  BenefitTypesProps,
  SelectedBenefitData,
} from 'components/BenefitInputs/types';

export interface State {
  formState: BenefitManagerFormValues;
  tableData: BenefitManagerFormValues[];
  showInactiveBenefits: boolean;
  openedForm: OpenedDialogTypes;
  openedConfirmationDialog: ConfirmationDialogTypes;
  selectedBenefit?: SelectedBenefitData;
}

export type ConfirmationDialogTypes =
  | 'changingStatus'
  | 'removingBenefit'
  | 'none';

export type OpenedDialogTypes = 'addNew' | 'edit' | 'none';

export enum ActionKind {
  SetFormState = 'UPDATE_FORM_STATE',
  SetTableData = 'SET_TABLE_DATA',
  AddNewBenefit = 'ADD_NEW_BENEFIT',
  UpdateBenefit = 'UPDATE_BENEFIT',
  RemoveBenefit = 'REMOVE_BENEFIT',
  ResetFormState = 'RESET_FORM_STATE',
  ToggleInactiveBenefits = 'TOGGLE_INACTIVE_BENEFITS',
  OpenEditingForm = 'OPEN_EDITING_FORM',
  OpenAddingForm = 'OPEN_ADDING_FORM',
  ToggleConfirmationDialog = 'OPEN_CONFIRMATION_DIALOG',
  ChangeStatus = 'CHANGE_STATUS',
  AddNewType = 'ADD_NEW_TYPE',
  RemoveType = 'REMOVE_TYPE',
  ClearType = 'CLEAR_TYPE',
}

export interface BenefitContextProps {
  dispatch: Dispatch<Action>;
  benefitManagerState: State;
}

interface SetFormStateAction {
  type: ActionKind.SetFormState;
  payload: BenefitManagerFormValues;
}

interface SetTableDataAction {
  type: ActionKind.SetTableData;
  payload: BenefitManagerFormValues[];
}

interface AddNewBenefitAction {
  type: ActionKind.AddNewBenefit;
  payload: BenefitManagerFormValues;
}

export interface UpdateBenefitAction {
  type: ActionKind.UpdateBenefit;
  payload: BenefitManagerFormValues;
}

export interface RemoveBenefitAction {
  type: ActionKind.RemoveBenefit;
  payload: number;
}

interface ToggleInactiveBenefitsAction {
  type: ActionKind.ToggleInactiveBenefits;
}

interface OpenAddingFormAction {
  type: ActionKind.OpenAddingForm;
}

export interface OpenEditingFormAction {
  type: ActionKind.OpenEditingForm;
  payload: {
    data: BenefitManagerFormValues;
    selectedBenefitData?: SelectedBenefitData;
  };
}

interface ResetFormStateAction {
  type: ActionKind.ResetFormState;
}

export interface ToggleConfirmationDialogAction {
  type: ActionKind.ToggleConfirmationDialog;
  payload: {
    selectedBenefitData?: SelectedBenefitData;
    type: ConfirmationDialogTypes;
  };
}

interface ChangeStatusAction {
  type: ActionKind.ChangeStatus;
}

export interface AddNewTypeAction {
  type: ActionKind.AddNewType;
  payload: {
    data: BenefitTypesProps[];
    newEntry: BenefitTypesProps;
  };
}

export interface RemoveTypeAction {
  type: ActionKind.RemoveType;
  payload: number;
}

interface ClearTypeAction {
  type: ActionKind.ClearType;
}

export type Action =
  | SetFormStateAction
  | ResetFormStateAction
  | SetTableDataAction
  | AddNewBenefitAction
  | UpdateBenefitAction
  | RemoveBenefitAction
  | ChangeStatusAction
  | OpenAddingFormAction
  | OpenEditingFormAction
  | ToggleInactiveBenefitsAction
  | ToggleConfirmationDialogAction
  | AddNewTypeAction
  | RemoveTypeAction
  | ClearTypeAction;
