import { useCallback, useContext, useEffect, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { GlobalContext } from 'globalContext/GlobalContext';
import { bankHolidaysUrl } from 'router/url';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FreeDayDialog from 'components/Dialog/FreeDayDialog';
import {
  DeleteIcon,
  EditIcon,
  TableIconButton,
} from 'components/Table/Table.css';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { ActionsContainer } from 'modules/BusinessTrips/Screens/BusinessTripDetails.css';
import {
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { formatDate, yearMonthFormatBackend } from 'utils/dateFormats';
import { deleteFetch, getFetch } from 'utils/fetchFunctions';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { useToggleState } from 'utils/hooks/useToggleState';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import CalendarDay from './CalendarDay';
import { DISABLED_DAYS } from './mock_data';
import { CalendarFilters, FetchedFreeDay, FreeDay } from './types';
import 'react-day-picker/lib/style.css';
import {
  CalendarWrapper,
  FreeDaysTitle,
  FreeDaysWrapper,
  ListWrapper,
  PaperWrapper,
} from './CalendarComponent.css';

const filterNames = ['project', 'client', 'account'];

const CalendarComponent = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [, setProjectsData] = useState<ProjectsData[]>([]);
  const [accountsData, setAccountsData] = useState<FilterOption[]>([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dayToEdit, setDayToEdit] = useState<FetchedFreeDay | undefined>(
    undefined,
  );
  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDayClicked, handleSelectedDayClicked] = useToggleState(false);

  const [freeDays, setFreeDays] = useState<FetchedFreeDay[]>([]);
  const [filteredFreeDays, setFilteredFreeDays] = useState<FetchedFreeDay[]>(
    [],
  );
  const [areDaysModified, setAreDaysModified] = useToggleState(false);

  const [deletedDayId, setDeletedDayId] = useState<number | null>(null);

  const [showConfirmationDialog, handleToggleConfirmationDialog] =
    useToggleState(false);

  const fetchBankHolidays = useCallback(async () => {
    setLoading(true);
    const bankHolidays = await getFetch({
      url: `${bankHolidaysUrl}/v2/?yearMonth=${formatDate(
        currentDate,
        yearMonthFormatBackend,
      )}`,
      showErrorToast: false,
    });

    setFreeDays(bankHolidays);
    setFilteredFreeDays(bankHolidays);
    setLoading(false);
  }, [currentDate]);

  const handleSelectDay = (
    day: Date,
    modifiers: DayModifiers,
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!modifiers?.disabled) {
      setSelectedDay(day);
      handleSelectedDayClicked();
    }
  };

  const handleDeleteDayAttempt = (freeDay: FetchedFreeDay) => {
    if (freeDay?.id) {
      setDeletedDayId(freeDay?.id);
      handleToggleConfirmationDialog();
    }
  };

  const deleteFreeDay = useCallback(async () => {
    await deleteFetch({
      url: `${bankHolidaysUrl}/${deletedDayId}`,
      intl,
      label: 'ADMIN_PANEL.CALENDAR.DELETE',
    });
    handleToggleConfirmationDialog();
    fetchBankHolidays();
  }, [deletedDayId, handleToggleConfirmationDialog, intl, fetchBankHolidays]);

  const { control, getValues, setValue } = useForm<CalendarFilters>({
    defaultValues: {
      project: undefined,
      client: undefined,
      account: undefined,
    },
  });

  const filterOptionsFields = useWatch({
    control,
    name: filterNames,
  });

  const getDayAffiliation = (freeDay: FreeDay) => {
    if (freeDay?.account) {
      return `${freeDay.account.name} ${freeDay.account.surname}`;
    }
    return freeDay?.client?.name || freeDay.project?.name || '';
  };

  const sortByDates = useCallback(
    (dates: FetchedFreeDay[]) =>
      dates.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      ),
    [],
  );

  const resetFiltersApartFrom = useCallback(
    (currentFilter?: keyof CalendarFilters) => {
      filterNames.forEach(
        (filter) => filter !== currentFilter && setValue(filter, undefined),
      );
    },
    [setValue],
  );

  const isHolidayDisabled = useCallback(
    (freeDay: FetchedFreeDay) => !freeDay?.editable || loading,
    [loading],
  );

  useEffect(() => {
    resetFiltersApartFrom();
    fetchBankHolidays();
  }, [areDaysModified, resetFiltersApartFrom, fetchBankHolidays]);

  useEffect(() => {
    const client = getValues('client');
    const account = getValues('account');
    if (client) {
      setFilteredFreeDays(
        freeDays.filter(
          (day) =>
            (day?.type === 'client' && day?.client?.name === client?.name) ||
            (day?.type === 'all' &&
              day?.exclusions?.excludedClients.filter((client) => client?.name)
                .length === 0),
        ),
      );
    } else if (account) {
      setFilteredFreeDays(
        freeDays.filter(
          (day) =>
            day?.type === 'account' && day?.account?.id === account?.value,
        ),
      );
    } else {
      setFilteredFreeDays(freeDays);
    }
  }, [filterOptionsFields, freeDays, getValues, setValue]);

  useEffect(() => {
    fetchClientList(setClientsData);
    fetchProjectList(setProjectsData);
    fetchEmployeeList(setAccountsData, ContractTypes.EMPLOYMENT_CONTRACT);
  }, []);

  return (
    <div>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} justify="flex-start">
            <Grid xs={12} sm={5} item>
              <ControllerSelect
                label="TABLE.HEAD.CLIENT"
                name="client"
                control={control}
                options={clientsData}
                handleChange={() => resetFiltersApartFrom('client')}
              />
            </Grid>
            <Grid xs={12} sm={5} item>
              <ControllerSelect
                label="MANAGER_FORM.EMPLOYEE"
                name="account"
                control={control}
                options={accountsData}
                handleChange={() => resetFiltersApartFrom('account')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PaperWrapper>
        <CalendarWrapper>
          <Typography style={{ margin: '1rem 0' }} variant="h6">
            {intl.formatMessage({ id: 'ADMIN_PANEL.CALENDAR.ADD_HEADER' })}
          </Typography>
          <DayPicker
            numberOfMonths={1}
            month={currentDate}
            onMonthChange={setCurrentDate}
            localeUtils={MomentLocaleUtils}
            locale={currentLocale}
            disabledDays={DISABLED_DAYS}
            renderDay={(date) => (
              <CalendarDay day={date} freeDays={filteredFreeDays} />
            )}
            onDayClick={handleSelectDay}
          />
        </CalendarWrapper>

        <FreeDaysWrapper>
          <FreeDaysTitle variant="h3" align="center">
            {intl.formatMessage({ id: 'FREE_DAY.LIST_TITLE' })}
          </FreeDaysTitle>
          <ListWrapper>
            {sortByDates(filteredFreeDays).map((freeDay) => (
              <ListItem key={`list-item-${freeDay.title}-${freeDay?.id ?? ''}`}>
                <ListItemText
                  style={{ wordWrap: 'break-word' }}
                  primary={
                    <>
                      <Typography variant="h6">
                        {freeDay?.title || ''}
                      </Typography>
                      <Box display="flex">
                        <Typography style={{ marginRight: '1rem' }}>
                          {new Date(freeDay?.date).toLocaleDateString()}
                        </Typography>
                        {getDayAffiliation(freeDay)}
                      </Box>
                    </>
                  }
                  secondary={freeDay?.description || ''}
                />
                <ActionsContainer container xs={4}>
                  <TableIconButton
                    id="editButton"
                    disabled={isHolidayDisabled(freeDay)}
                    onClick={() => {
                      if (freeDay?.editable) {
                        setIsEditOpen(true);
                        setDayToEdit(freeDay);
                      }
                    }}
                  >
                    <EditIcon />
                  </TableIconButton>
                  <DeleteIcon
                    color={isHolidayDisabled(freeDay) ? 'disabled' : undefined}
                    $disabled={isHolidayDisabled(freeDay)}
                    onClick={() =>
                      freeDay?.editable &&
                      !loading &&
                      handleDeleteDayAttempt(freeDay)
                    }
                  />
                </ActionsContainer>
              </ListItem>
            ))}
          </ListWrapper>
        </FreeDaysWrapper>
      </PaperWrapper>
      {selectedDayClicked && (
        <FreeDayDialog
          addChecked={selectedDayClicked}
          handleAddChanged={handleSelectedDayClicked}
          data={selectedDay}
          setFreeDays={setFreeDays}
          setAreDaysModified={setAreDaysModified}
        />
      )}
      {isEditOpen && dayToEdit && (
        <FreeDayDialog
          addChecked={isEditOpen}
          handleAddChanged={() => setIsEditOpen(false)}
          data={undefined}
          setFreeDays={setFreeDays}
          setAreDaysModified={setAreDaysModified}
          isEdit
          dataToEdit={dayToEdit}
        />
      )}
      {
        <ConfirmationDialog
          addChecked={showConfirmationDialog}
          handleAddChanged={handleToggleConfirmationDialog}
          handleSubmit={deleteFreeDay}
          title={intl.formatMessage({
            id: 'ADMIN_PANEL.CALENDAR.DELETE_TITLE',
          })}
          content={intl.formatMessage({
            id: 'ADMIN_PANEL.CALENDAR.DELETE_CONTENT',
          })}
        />
      }
    </div>
  );
};

export default CalendarComponent;
