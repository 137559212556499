export { getEncodedQueryParamsString };
export type { QueryParameters };

interface QueryParameters {
  [index: string]: string | boolean | number | Array<string | boolean | number>;
}

/**
 * Encodes query parameters,
 * thus their values can contain special reserved smybols such as '&' etc.
 *
 * NOTE: Multiple values for the same parameter not yet supported
 *
 * @param queryParams object of type QueryParameters with parameters and values
 * @returns encoded query parameters string prefixed by '?'
 */
const getEncodedQueryParamsString = (queryParams?: QueryParameters): string => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return '';
  }

  const encodedQueryParamsString = Object.keys(queryParams)
    .filter(
      (key) =>
        typeof queryParams[key] !== 'undefined' && queryParams[key] !== null,
    )
    .map((param) => {
      const paramValue = queryParams[param];
      if (Array.isArray(paramValue)) {
        if (!paramValue.length) {
          return '';
        }

        return paramValue
          .map(
            (arrVal) =>
              fixedEncodeURIComponent(param) +
              '=' +
              fixedEncodeURIComponent(arrVal),
          )
          .join('&');
      }

      return (
        fixedEncodeURIComponent(param) +
        '=' +
        fixedEncodeURIComponent(paramValue)
      );
    })
    .join('&');

  return encodedQueryParamsString === '' ? '' : '?' + encodedQueryParamsString;
};

/**
 * Extends {@link encodeURIComponent} by encoding also
 * rest of character reserved according to RFC 3986 [reserves !, ', (, ), and *]
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent Source}
 */
const fixedEncodeURIComponent = (str: string | number | boolean): string => {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );
};
