import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from 'globalContext/UserContext';
import {
  activeRequestsUrl,
  leaveRequestUrl,
  requestsHistoryUrl,
} from 'router/url';
import { deleteFetch, getFetch } from 'utils/fetchFunctions';
import { usePagination } from 'utils/hooks/usePagination';
import { ExpandedOption, HolidayInformationList } from '../types';
import LeaveRequestsPagination from './LeaveRequestsPagination';
import LeaveRequestsTable from './LeaveRequestsTable';
import {
  HeaderWrapper,
  LeaveRequestsAccordion,
  LeaveRequestsTableContainer,
} from '../HolidaysTab.css';

const AccordionRequestsHistory = () => {
  const [expandedSection, setExpandedSection] = useState<string | null>(
    ExpandedOption.ActiveRequests,
  );
  const [activeRequestsList, setActiveRequestsList] = useState<
    HolidayInformationList[]
  >([]);
  const [requestsHistoryList, setRequestsHistoryList] = useState<
    HolidayInformationList[]
  >([]);
  const { username } = useContext(UserContext);
  const intl = useIntl();

  const { debouncedPage, pagination, setCurrentPage, setElementsCount } =
    usePagination<HolidayInformationList[]>({
      initialPage: 0,
      initialRowsPerPage: 5,
      initialElementsCount: 0,
      debounceTime: 200,
    });

  const withPagination = pagination.totalElements > pagination.pageSize;

  const handleChangeAccordionPanel =
    (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpandedSection(newExpanded ? panel : null);
    };

  const getLeaveRequestsHistoryUrl = useCallback(
    (pageNumber: number, pageSize: number) =>
      `${leaveRequestUrl}/${username}/${requestsHistoryUrl}` +
      `&page=${pageNumber}` +
      `&size=${pageSize}`,
    [username],
  );

  const fetchActiveRequests = useCallback(async () => {
    const activeRequests = await getFetch({
      url: `${leaveRequestUrl}/${username}/${activeRequestsUrl}`,
    });
    setActiveRequestsList(activeRequests?.content);
  }, [username]);

  const fetchRequestsHistory = useCallback(async () => {
    const requestsHistory = await getFetch({
      url: getLeaveRequestsHistoryUrl(debouncedPage, pagination.pageSize),
    });
    setElementsCount(requestsHistory?.totalElements);
    setRequestsHistoryList(requestsHistory?.content);
  }, [
    debouncedPage,
    pagination.pageSize,
    getLeaveRequestsHistoryUrl,
    setElementsCount,
  ]);

  useEffect(() => {
    fetchActiveRequests();
  }, [fetchActiveRequests]);

  useEffect(() => {
    fetchRequestsHistory();
  }, [fetchRequestsHistory]);

  const deleteRequest = useCallback(
    async (requestId: number) => {
      const { ok } = await deleteFetch({
        url: `${leaveRequestUrl}/${requestId}/users/${username}`,
        intl,
        label: 'LEAVE_REQUEST.DELETE',
      });
      if (ok) {
        fetchActiveRequests();
      }
    },
    [intl, username, fetchActiveRequests],
  );

  return (
    <>
      <Accordion
        expanded={expandedSection === ExpandedOption.ActiveRequests}
        onChange={handleChangeAccordionPanel(ExpandedOption.ActiveRequests)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HeaderWrapper>
            {intl.formatMessage({ id: 'LEAVE_REQUEST.ACTIVE_REQUESTS' })}
          </HeaderWrapper>
        </AccordionSummary>
        <LeaveRequestsAccordion>
          <LeaveRequestsTable
            data={activeRequestsList}
            isEditable
            deleteRequest={deleteRequest}
          />
        </LeaveRequestsAccordion>
      </Accordion>
      <Accordion
        expanded={expandedSection === ExpandedOption.AbsenceHistory}
        onChange={handleChangeAccordionPanel(ExpandedOption.AbsenceHistory)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HeaderWrapper>
            {intl.formatMessage({ id: 'LEAVE_REQUEST.HISTORY_TITLE' })}
          </HeaderWrapper>
        </AccordionSummary>
        <LeaveRequestsAccordion>
          <LeaveRequestsTableContainer>
            <LeaveRequestsTable data={requestsHistoryList} isEditable={false} />
            {withPagination && (
              <LeaveRequestsPagination
                elementsCount={pagination.totalElements}
                currentNumber={pagination.pageNumber}
                setCurrentPage={setCurrentPage}
                rowsPerPage={pagination.pageSize}
              />
            )}
          </LeaveRequestsTableContainer>
        </LeaveRequestsAccordion>
      </Accordion>
    </>
  );
};

export default AccordionRequestsHistory;
