import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TIMEREPORTS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.NAME',
    key: 'name',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.MULTIPLICATOR',
    key: 'multiplier',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.REPORTED_UNITS',
    key: 'reportedUnits',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.UNIT_TYPE',
    key: 'unitType',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.REPORTED_HOURS',
    key: 'reportedHours',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
];

export const BUSINESS_TRIPS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.NAME',
    key: 'name',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'left',
    sortable: true,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.NUMBER_OF_NIGHTS',
    key: 'numberOfNights',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.TOTAL_TRIPS',
    key: 'totalTrips',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.TRAVEL_BY',
    key: 'travelBy',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
];

export const TAB_LABELS = [
  { label: 'REPORTS_TAB.TIMEREPORTS', key: 0 },
  { label: 'REPORTS_TAB.BUSINESS_TRIPS', key: 1 },
];
