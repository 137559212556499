import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TABLE_HEAD_MOCK_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.NAME',
    key: 'name',
    sortable: false,
    align: 'left',
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    sortable: false,
    align: 'left',
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    sortable: false,
    align: 'left',
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.REPORTED_HOURS',
    key: 'hours',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.PARTIALLY_APPROVED_BY_LEADER',
    key: 'partiallyApprovedByLeader',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.TEAMLEADERS',
    key: 'teamleaders',
    sortable: false,
    align: 'left',
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.DETAILS',
    key: 'description',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.REPORTED_OVERHOURS_SUM',
    key: 'overhoursSum',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.VACATION',
    key: 'vacation',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.MEDICAL_LEAVE',
    key: 'medicalLeave',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.DAYS_OFF',
    key: 'dayOff',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.NOT_REPORTED_DAYS',
    key: 'unreportedDays',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.REMOTE_WORK',
    key: 'remoteWork',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: { padding: '16px 8px' },
  },
  {
    label: 'TABLE.HEAD.ACTIONS',
    key: 'actions',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.APPROVED',
    key: 'approved',
    sortable: false,
    align: 'center',
    role: RoleTypes.RoleEmployee,
    style: {
      padding: '16px 8px',
    },
  },
];
