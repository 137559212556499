import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ModalContextInterface, ModalStateType, PayloadType } from './types';

export const initialContextState = {
  onApprove: () => {
    // intentional empty function
  },
  openModal: () => {
    // intentional empty function
  },
  closeModal: () => {
    // intentional empty function
  },
  modalData: {
    visible: false,
    loading: false,
    payload: {
      url: '',
      body: undefined,
      content: <></>,
    },
    submit: () => {
      // intentional empty function
    },
    callback: () => {
      // intentional empty function
    },
  },
};

const ModalContext = createContext<ModalContextInterface>(initialContextState);
const ModalContextProvider = ModalContext.Provider;

interface Props {
  children: ReactNode;
}

function ModalProvider({ children }: Props) {
  const [modalData, setModalData] = useState<ModalStateType>({
    visible: false,
    loading: false,
    payload: {
      url: '',
      body: undefined,
      content: <></>,
    },
    submit: () => {
      // intentional empty function
    },
    callback: () => {
      // intentional empty function
    },
  });

  const openModal = useCallback(
    (payload: PayloadType, submit: Function, callback: Function) => {
      setModalData({
        ...modalData,
        visible: true,
        payload: payload,
        submit: submit,
        callback: callback,
      });
    },
    [modalData],
  );

  const closeModal = useCallback(() => {
    setModalData({
      loading: false,
      visible: false,
      payload: {
        url: '',
        body: undefined,
        content: <></>,
      },
      submit: () => () => {
        // intentional empty function
      },
      callback: () => () => {
        // intentional empty function
      },
    });
  }, []);

  const onApprove = useCallback(async () => {
    const {
      submit,
      callback,
      payload: { url, body },
    } = modalData;
    setModalData({ ...modalData, loading: true });
    await submit({
      url: url,
      body: JSON.stringify(body),
    });
    setModalData({ ...modalData, loading: false, visible: false });
    if (callback) {
      callback();
    }
  }, [modalData]);

  const value = useMemo(
    () => ({
      modalData,
      openModal,
      closeModal,
      onApprove,
    }),
    [modalData, openModal, closeModal, onApprove],
  );
  return <ModalContextProvider value={value}>{children}</ModalContextProvider>;
}

ModalProvider.displayName = 'ModalProvider';

export { ModalProvider, ModalContext };
