import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { UserContext } from 'globalContext/UserContext';
import { projectActiveTypes, projectsUrl, projectUpdateUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledCreatableSelect from 'components/ControlledCreatableSelect/ControlledCreatableSelect';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledSwitch from 'components/ControlledSwitch';
import ControlledTextField from 'components/ControlledTextField';
import {
  getMultiplicatorValue,
  validateProjects,
} from 'modules/Projects/utils';
import {
  fetchClientListDialog,
  fetchEmplayeeListDialog,
} from 'utils/commonFetches';
import { getFetch, postFetch, putFetch } from 'utils/fetchFunctions';
import { validate } from 'utils/formValidation';
import { returnRoleInclusion } from 'utils/helpers';
import { RoleTypes } from 'utils/rolesTypes';
import { Data, ProjectCategories } from '../types';
import { ProjectDialogProps, ProjectProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';
import { InformationParagraph } from './ProjectDialog.css';

const ProjectDialog = ({
  fetchProjects,
  addChecked,
  handleAddChanged,
  editClicked,
  data,
}: ProjectDialogProps) => {
  const intl = useIntl();
  const [projectCategories, setProjectCategories] = useState<
    ProjectCategories[]
  >([]);
  const [workUnitsOptions, setWorkUnitsOptions] = useState<
    Array<{ name: string; value: string }>
  >([]);
  const projectTypesOptions = projectCategories.map((el) => ({
    value: el,
    name: intl.formatMessage({
      id: `CATEGORY.${el}`,
    }),
  }));

  const defaultType = useMemo(
    () => ({ value: 'STANDARD', name: 'Standard' }),
    [],
  );

  const { control, getValues, handleSubmit, formState, reset, errors } =
    useForm<ProjectProps>({
      mode: 'onChange',
      defaultValues: {
        client: undefined,
        contactPerson: undefined,
        description: '',
        projectName: '',
        billable: true,
        projectCategory: defaultType,
        multiplicator: 1,
        reportingCustomDataType: undefined,
      },
    });
  const [employeeData, setEmployeeData] = useState<Data[]>([
    { value: 0, name: '' },
  ]);
  const [clientData, setClientData] = useState<Data[]>([
    { value: 0, name: '' },
  ]);

  const getProjectCategories = async () => {
    const categories = await getFetch({
      url: projectActiveTypes,
    });
    if (categories) {
      setProjectCategories(categories);
    }
  };
  const { projectCategory } = useWatch({ control });

  const { teamLeader: isTeamLeader, roles } = useContext(UserContext);

  const handleClose = useCallback(() => {
    handleAddChanged();
    reset({
      client: undefined,
      contactPerson: undefined,
      description: '',
      projectName: '',
      billable: true,
      projectCategory: defaultType,
      multiplicator: 1,
      reportingCustomDataType: undefined,
    });
  }, [handleAddChanged, reset, defaultType]);

  const fetchWorkUnits = useCallback(async () => {
    const workUnits = await getFetch({
      url: '/api/projects/work-units',
    });
    const unitOptions = workUnits.workUnits.map((unit: string) => {
      return {
        name: intl.formatMessage({
          id: `PROJECTS.UNIT_TYPE_OPTIONS_${unit}`,
        }),
        value: unit,
      };
    });
    setWorkUnitsOptions(unitOptions);
  }, [setWorkUnitsOptions, intl]);

  useEffect(() => {
    const retrieveData = async () => {
      const employeeData = await fetchEmplayeeListDialog();
      const clientData = await fetchClientListDialog();
      getProjectCategories();
      fetchWorkUnits();
      setEmployeeData(employeeData);
      setClientData(clientData);
    };
    retrieveData();
  }, [fetchWorkUnits]);

  const mapClientNameToId = useCallback(
    (name: string) => {
      const mappedClient = clientData.find((client) => {
        return client.name === name;
      });
      return mappedClient ? mappedClient.value : 0;
    },
    [clientData],
  );

  useEffect(() => {
    if (editClicked && data) {
      reset({
        client: {
          value: mapClientNameToId(data?.client),
          name: data?.client,
        },
        contactPerson: {
          value: data?.contactPerson,
          name: data?.contactPerson,
        },
        description: data?.description || '',
        projectName: data?.project || '',
        billable: data?.billable || false,
        projectCategory: {
          value: data?.projectCategory,
          name: intl.formatMessage({
            id: `CATEGORY.${data?.projectCategory}`,
          }),
        },
        multiplicator: getMultiplicatorValue(data?.multiplicator),
        reportingCustomData: data?.projectCategory === 'CUSTOM' ? true : false,
        reportingCustomDataType:
          data?.reportingCustomDataType !== null
            ? {
                value: data?.reportingCustomDataType,
                name: data?.reportingCustomDataType,
              }
            : undefined,
      });
    }
  }, [
    clientData,
    data,
    editClicked,
    employeeData,
    intl,
    mapClientNameToId,
    reset,
  ]);

  const onSubmit = handleSubmit(async (formData) => {
    const {
      client,
      contactPerson,
      description,
      projectName,
      billable,
      projectCategory,
      multiplicator,
      reportingCustomDataType,
    } = formData;

    const body = {
      clientId: client?.value,
      contactPerson: contactPerson?.name ?? contactPerson?.label,
      description,
      projectName,
      status: 'ACTIVE',
      billable,
      projectCategory: projectCategory?.value || defaultType,
      multiplicator: multiplicator || 1,
      reportingCustomData: isProjectCustom ? true : false,
      reportingCustomDataType: reportingCustomDataType?.value,
    };

    if (editClicked) {
      await putFetch({
        url: `${projectUpdateUrl}/${data?.id}`,
        body,
        intl,
        label: 'PROJECTS.EDIT',
      });
    } else {
      await postFetch({
        url: projectsUrl,
        body,
        intl,
        label: 'PROJECTS.ADD',
      });
    }
    fetchProjects();
    handleClose();
  });

  const isEditDisabled = () => {
    if (!editClicked) {
      return false;
    }
    return !data?.isDeletable;
  };

  const isProjectCustom: boolean =
    getValues().projectCategory?.value === ProjectCategories.custom;

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: `PROJECT_DIALOG.${editClicked ? 'EDIT' : 'ADD_NEW'}`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label="TABLE.HEAD.PROJECT"
                name="projectName"
                control={control}
                required
                maxLength={255}
                validate={!!errors.projectName}
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                label="TABLE.HEAD.CLIENT"
                name="client"
                control={control}
                options={clientData}
                isDisabled={isEditDisabled()}
                required
                helperText
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledCreatableSelect
                label="TABLE.HEAD.CONTACT_PERSON"
                name="contactPerson"
                control={control}
                options={employeeData}
                required
                helperText
                isTooltipEnabled
                tooltipInfo="PROJECT_DIALOG.CREATABLE_SELECT"
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                label="PROJECTS.PROJECT_TYPES_DIALOG.PROJECT_TYPE"
                name="projectCategory"
                control={control}
                options={projectTypesOptions}
                isDisabled={isEditDisabled()}
                helperText
                required
              />
            </GridItem>
            {isProjectCustom && (
              <InformationParagraph>
                {intl.formatMessage({ id: 'PROJECTS.CUSTOM_INFORMATION_P1' })}
                <br />
                {intl.formatMessage({ id: 'PROJECTS.CUSTOM_INFORMATION_P2' })}
                <br />
                {intl.formatMessage({ id: 'PROJECTS.CUSTOM_INFORMATION_P3' })}
                <br />
                {intl.formatMessage({ id: 'PROJECTS.CUSTOM_INFORMATION_P4' })}
              </InformationParagraph>
            )}
            <GridItem xs={12} item>
              <ControlledTextField
                label="TABLE.HEAD.MULTIPLICATOR"
                name="multiplicator"
                placeholder={intl.formatMessage({
                  id: 'PROJECT_DIALOG.MULTIPLICATOR_PLACEHOLDER',
                })}
                control={control}
                variant="outlined"
                required
                isNonNegative
                rules={
                  validateProjects(intl).maxValue &&
                  validate(intl).positiveNumber
                }
                validate={!!errors.multiplicator}
                helperText={errors?.multiplicator?.message}
                maxLength={6}
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                label="TABLE.HEAD.DESCRIPTION"
                name="description"
                control={control}
                variant="outlined"
                isMulti={true}
              />
            </GridItem>

            {projectCategory ? (
              projectCategory?.value === ProjectCategories.custom && (
                <>
                  <GridItem xs={12} item>
                    <ControlledSelect
                      label="PROJECTS.UNIT_TYPE"
                      name="reportingCustomDataType"
                      control={control}
                      required
                      maxMenuHeight={120}
                      options={workUnitsOptions}
                    />
                  </GridItem>
                </>
              )
            ) : (
              <></>
            )}
            {(returnRoleInclusion(roles, [
              RoleTypes.RoleAccounting,
              RoleTypes.RoleSales,
              RoleTypes.RoleHR,
            ]) ||
              isTeamLeader) && (
              <GridItem xs={12} item>
                <ControlledSwitch
                  label="PROJECTS.BILLABLE_TITLE"
                  name="billable"
                  control={control}
                  labelPlacement="start"
                />
              </GridItem>
            )}
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="addProjectButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `PROJECTS.${editClicked ? 'EDIT' : 'SUBMIT'}_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default ProjectDialog;
