import { Action, ActionKind, State } from './types';

const emptyState = {
  tripReason: '',
  country: undefined,
  city: '',
  client: undefined,
  project: undefined,
  startTime: null,
  endTime: null,
  transportation: undefined,
  travelFrom: '',
  travelTo: '',
  travelReservationBy: undefined,
  travelReservationLink: '',
  reservationInformation: '',
  numberOfNights: null,
  placeToStay: null,
  estimateCostsOfStay: 0,
  currency: undefined,
  accommodationReservationBy: undefined,
  accommodationReservationLink: '',
  accommodationInformation: '',
  insuranceProvidedBy: undefined,
  neededAdvance: false,
  advanceDescription: '',
  otherInformation: '',
};

export const initialState: State = {
  formState: emptyState,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.SetFormState:
      return { ...state, formState: { ...state.formState, ...action.payload } };
    case ActionKind.ResetFormState:
      return { ...state, formState: emptyState };
    case ActionKind.SetNumberOfNights:
      return {
        ...state,
        formState: { ...state.formState, numberOfNights: action.payload },
      };
    default:
      return state;
  }
};
