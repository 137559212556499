import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  timesheetCreationUrl,
  timesheetsUrl,
  workTimeFullReportUrl,
} from 'router/url';
import { formatMonthsToTwoDigits } from 'utils/dateFormats';
import { mapISODurationToString } from 'utils/durationFunctions';
import { getFetch, postFetch } from 'utils/fetchFunctions';
import { returnHourDate } from 'utils/formatters';
import { UserContext } from '../../globalContext/UserContext';
import FullReportTable from './FullReportTable';
import { addLabelsForTimeOffProjects, getInitialProjects } from './tableUtils';
import {
  FullReport as FullReportData,
  FullReportDay,
  FullReportProps,
  ProjectOptions,
} from './types';

const FullReport = ({
  headCustoms,
  projectsData,
  clientsData,
  fetchClientsAndProjects,
  renderEmptyDataInfo,
  timesheetUsername,
  timesheetData,
  date,
  setDate,
  setTimesheetUsername,
  selectedUser,
  setSelectedUser,
  setIsFormedChanged,
  myTimesheetLoaded,
  isUserAuthorized,
  setIsUsersFetched,
  userContract,
}: FullReportProps) => {
  const [loading, setLoading] = useState(false);
  const [reportAvailable, setReportAvailable] = useState(false);
  const [defaultProject, setDefaultProject] = useState({
    id: null,
    name: null,
    multiplicator: null,
    multiplicatorValue: null,
    projectCategory: null,
  });
  const [defaultClient, setDefaultClient] = useState({ id: null, name: null });
  const rowRef = useRef<null | HTMLTableRowElement>(null);
  const [sheetId, setSheetId] = useState(-1);
  const [fullReportData, setFullReportData] = useState<FullReportData>();
  const [projectsOptions, setProjectsOptions] = useState<ProjectOptions[]>();
  const labeledProjectsData = useMemo(
    () => addLabelsForTimeOffProjects(projectsData),
    [projectsData],
  );
  const { username } = useContext(UserContext);

  const createTimesheet = useCallback(async () => {
    const body = {
      month: date!.getMonth() + 1,
      year: date!.getFullYear(),
    };
    await postFetch({
      url: timesheetCreationUrl,
      body,
      showErrorToast: false,
    });
  }, [date]);

  const fetchFullReport = useCallback(
    async (reset?: (values?: FullReportData) => void) => {
      if (date?.getFullYear()) {
        try {
          setLoading(true);
          const data = await getFetch({
            url: `${timesheetsUrl}/${
              timesheetUsername || username
            }/${workTimeFullReportUrl}?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
              date!.getMonth() + 1,
            )}`,
          });
          if (timesheetUsername && !data) {
            setIsUsersFetched(false);
          } else {
            setIsUsersFetched(true);
          }
          if (!data.days) {
            throw new Error();
          }
          setSheetId(data.timesheetId);

          const newData = {
            ...data,
            days: data?.days.map((entry: FullReportDay) => ({
              ...entry,
              dayId: String(entry.id),
              startTime: entry.startTime && returnHourDate(entry.startTime),
              endTime: entry.endTime && returnHourDate(entry.endTime),
              projectBasicInfo: entry.projectBasicInfo,
              manHours:
                entry.manHours && mapISODurationToString(entry.manHours),
              breakTime:
                entry.breakTime &&
                returnHourDate(mapISODurationToString(entry.breakTime)),
              multiplicator: entry.multiplicator,
            })),
          };
          setFullReportData(newData);
          setProjectsOptions(
            getInitialProjects(
              newData.days,
              labeledProjectsData,
              newData.defaultClientName,
            ),
          );
          setDefaultClient({
            id: newData.defaultClientId,
            name: newData.defaultClientName,
          });
          if (newData.defaultProject) {
            setDefaultProject({
              id: newData.defaultProject.id,
              name: newData.defaultProject.name,
              multiplicator: newData.defaultProject.multiplicator,
              multiplicatorValue: newData.defaultProject.multiplicatorValue,
              projectCategory: newData.defaultProject.projectCategory,
            });
          }
          setReportAvailable(true);
          setLoading(false);
          reset?.(newData);
        } catch (e) {
          if (!timesheetUsername) {
            await createTimesheet();
            await fetchFullReport(reset);
          }
        }
      }
    },
    [
      date,
      username,
      setIsUsersFetched,
      timesheetUsername,
      createTimesheet,
      labeledProjectsData,
    ],
  );

  useEffect(() => {
    setFullReportData(undefined);
    fetchFullReport();
  }, [fetchFullReport, setFullReportData]);

  return fullReportData && !loading ? (
    <FullReportTable
      headCustoms={headCustoms}
      projectsData={labeledProjectsData}
      clientsData={clientsData}
      fetchClientsAndProjects={fetchClientsAndProjects}
      timesheetData={timesheetData}
      date={date}
      setDate={setDate}
      setTimesheetUsername={setTimesheetUsername}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      setIsFormedChanged={setIsFormedChanged}
      myTimesheetLoaded={myTimesheetLoaded}
      isUserAuthorized={isUserAuthorized}
      loading={loading}
      reportAvailable={reportAvailable}
      defaultProject={defaultProject}
      rowRef={rowRef}
      defaultClient={defaultClient}
      sheetId={sheetId}
      fullReportData={fullReportData}
      projectsOptions={projectsOptions}
      setProjectsOptions={setProjectsOptions}
      fetchFullReport={fetchFullReport}
      userContract={userContract}
    />
  ) : (
    renderEmptyDataInfo
  );
};
export default memo(FullReport);
