import { Dispatch, SetStateAction } from 'react';

export interface MissingUserProfileInfo {
  contractTypeDefined: boolean;
  defaultClientDefined: boolean;
  defaultProjectDefined: boolean;
  defaultTimeDefined: boolean;
  employerDefined: boolean;
  missingFieldsList: string[];
  personalIdDefined: boolean;
  prettyMessage: string;
}

export enum MissingInfoType {
  businessTrip = 'BUSINESS_TRIP',
  quickReport = 'QUICK_REPORT',
  summary = 'SUMMARY',
  companyInfo = 'COMPANY_INFO',
  notInvoicedBT = 'NOT_INVOICED_BT',
  newBenefitCost = 'NEW_BENEFIT_COST',
}

export interface MissingUserProfileProps {
  onClose: Dispatch<SetStateAction<boolean>>;
  handleAction?: () => Promise<void>;
  missingInfo?: MissingUserProfileInfo;
  cost?: number | null;
  type: MissingInfoType;
}
