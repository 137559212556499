import { Grid, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';
import { Tooltip } from 'components/Table/Table.css';

interface TextFieldTooltipProps extends TooltipProps {
  $isMulti?: boolean;
}

export const GridItem = styled(Grid)`
  &.MuiGrid-item {
    padding: 0.5rem;
    align-self: flex-start;
    position: relative;
  }
`;

export const TextFieldTooltip = styled(Tooltip)<TextFieldTooltipProps>`
  &.MuiIconButton-root {
    position: absolute;
    right: 1.2rem;
    top: ${({ $isMulti }) => ($isMulti ? '50%' : '1.8rem')};
    transform: ${({ $isMulti }) => ($isMulti ? 'translateY(-50%)' : '')};
  }
`;

export const CheckboxTooltip = styled(Tooltip)<TooltipProps>`
  &.MuiIconButton-root {
    margin-top: 0.85rem;
    margin-left: -0.5rem;
  }
`;

export const CheckboxContainer = styled(Grid)`
  &.MuiGrid-item {
    padding: 1rem;
    text-align: left;
  }
`;
