import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { manualAcceptanceUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledCheckbox from 'components/ControlledCheckbox';
import ControlledSelect from 'components/ControlledSelect';
import { InformationIcon, RulesInstruction } from 'components/Table/Table.css';
import { RuleProps } from 'components/Table/types';
import { postFetch, putFetch } from 'utils/fetchFunctions';
import {
  filterDropdownProjectData,
  getRenamedProjectsData,
  setClientsValue,
} from 'utils/helpers/filterDropdownData';
import { ProjectsData } from 'utils/helpers/renameKeys';
import { ManualAcceptanceDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const ManualAcceptanceDialog = ({
  addChecked,
  handleAddChanged,
  editClicked,
  data,
  fetchRules,
  clientsData,
  projectsData,
  employeesData,
}: ManualAcceptanceDialogProps) => {
  const intl = useIntl();

  const { control, setValue, getValues, handleSubmit, formState, reset } =
    useForm<RuleProps>({
      mode: 'onChange',
      defaultValues: {
        client: undefined,
        project: undefined,
        employee: undefined,
        leaders: undefined,
        alwaysManualApproval: false,
        ifOvertime: false,
        ifWorktimeOnHoliday: false,
        ifWorktimeOnWeekend: false,
        ifECMinimalBreakViolated: false,
        id: 0,
      },
    });
  const alwaysManualApproval = useWatch({
    control,
    name: 'alwaysManualApproval',
    defaultValue: getValues('alwaysManualApproval'),
  });

  const fillCheckboxes = useCallback(() => {
    [
      'ifOvertime',
      'ifWorktimeOnHoliday',
      'ifWorktimeOnWeekend',
      'ifECMinimalBreakViolated',
    ].forEach((propertyName) => setValue(propertyName, !alwaysManualApproval));
  }, [alwaysManualApproval, setValue]);

  const [filteredProjectsData, setFilteredProjectsData] = useState<
    ProjectsData[]
  >([]);
  const { client, project } = useWatch({ control });

  const setFilterDropdownData = useCallback(() => {
    setFilteredProjectsData(
      filterDropdownProjectData({
        projectsData,
        clientNameFromClient: client?.clientName,
      }),
    );
    setClientsValue({
      setValue,
      projectNameFromProject: project?.projectName,
      clientNameFromClient: client?.clientName,
      clientNameFromProject: project?.client?.clientName,
      clientIdFromProject: project?.client?.id,
    });
  }, [client, project, projectsData, setValue]);

  const handleClose = useCallback(() => {
    handleAddChanged();
    reset();
  }, [handleAddChanged, reset]);

  const onSubmit = handleSubmit(async (formData) => {
    handleAddChanged();
    const body = {
      ...formData,
      leaders: formData.leaders?.map((leader) => ({
        id: leader.value,
        username: leader.username,
      })),
      id: data?.id,
    };
    formData.client &&
      Object.assign(body, {
        client: {
          id: formData.client.value || formData.client.id,
        },
      });
    formData.project &&
      Object.assign(body, {
        project: {
          id: formData.project.value || formData.project.id,
        },
      });
    formData.employee &&
      Object.assign(body, {
        employee: {
          id: formData.employee.value || formData.employee.id,
        },
      });
    if (editClicked) {
      await putFetch({
        url: `${manualAcceptanceUrl}/${data?.id}`,
        body,
        intl,
        label: 'ACCEPTANCE_RULE.EDIT',
      });
    } else {
      await postFetch({
        url: manualAcceptanceUrl,
        body,
        intl,
        label: 'ACCEPTANCE_RULE.ADD',
      });
    }

    fetchRules();
    reset();
  });

  useEffect(() => {
    if (editClicked && data) {
      const editData = {
        alwaysManualApproval: data.alwaysManualApproval,
        ifOvertime: data.ifOvertime,
        ifWorktimeOnHoliday: data.ifWorktimeOnHoliday,
        ifWorktimeOnWeekend: data.ifWorktimeOnWeekend,
        ifECMinimalBreakViolated: data.ifECMinimalBreakViolated,
        id: data.id,
      };
      data.client &&
        Object.assign(editData, {
          client: {
            id: data.client?.id,
            name: data.client?.name,
          },
        });
      data.project &&
        Object.assign(editData, {
          project: {
            id: data.project?.id,
            name: data.project?.name,
          },
        });
      data.employee &&
        Object.assign(editData, {
          employee: {
            id: data.employee?.id,
            name: `${data.employee?.name} ${data.employee?.surname}`,
          },
        });
      data.leaders &&
        Object.assign(editData, {
          leaders: data.leaders?.map((leader) => ({
            value: leader?.id,
            name: `${leader?.name} ${leader?.surname}`,
            username: leader?.username,
          })),
        });
      reset(editData);
    }
  }, [data, editClicked, reset]);

  const clearProjectAndNameField = useCallback(() => {
    setValue('project', undefined);
    setValue('employee', undefined);
  }, [setValue]);

  useEffect(() => {
    setFilterDropdownData();
  }, [setFilterDropdownData]);

  const projectOptions = useMemo(
    () => getRenamedProjectsData(filteredProjectsData),
    [filteredProjectsData],
  );

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'sm'}
      open={addChecked}
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {intl.formatMessage({
              id: editClicked
                ? 'ACCEPTANCE_RULE.EDIT_DIALOG_TITLE'
                : 'ACCEPTANCE_RULE.ADD_DIALOG_TITLE',
            })}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <GridWrapper
          container
          direction="column"
          alignItems="flex-start"
          justify="flex-start"
        >
          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            container
            alignItems="center"
          >
            <InformationIcon />
            <RulesInstruction>
              {intl.formatMessage({
                id: 'ACCEPTANCE_RULE.DIALOG_ENTITIES',
              })}
            </RulesInstruction>
          </GridItem>
          <GridItem lg={12} sm={12} xl={12} xs={12} item>
            <ControlledSelect
              label="ADMIN_PANEL.HEADER.CLIENT"
              name="client"
              control={control}
              options={clientsData}
              handleChange={clearProjectAndNameField}
            />
          </GridItem>
          <GridItem lg={12} sm={12} xl={12} xs={12} item>
            <ControlledSelect
              label="ADMIN_PANEL.HEADER.PROJECT"
              name="project"
              control={control}
              options={projectOptions}
            />
          </GridItem>
          <GridItem lg={12} sm={12} xl={12} xs={12} item>
            <ControlledSelect
              label="ADMIN_PANEL.HEADER.EMPLOYEE"
              name="employee"
              control={control}
              options={employeesData}
            />
          </GridItem>
          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            container
            alignItems="center"
          >
            <InformationIcon />
            <RulesInstruction>
              {intl.formatMessage({
                id: 'ACCEPTANCE_RULE.DIALOG_REQUIREMENTS',
              })}
            </RulesInstruction>
          </GridItem>
          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            alignItems="flex-start"
          >
            <ControlledCheckbox
              label="ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_ALWAYS_MANUAL_TOOLTIP"
              name="alwaysManualApproval"
              control={control}
              placement="end"
              changeSideEffect={fillCheckboxes}
            />
          </GridItem>

          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            alignItems="flex-start"
          >
            <ControlledCheckbox
              label="ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_OVERTIME_TOOLTIP"
              name="ifOvertime"
              control={control}
              placement="end"
              disabled={alwaysManualApproval}
            />
          </GridItem>
          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            container
            alignItems="flex-start"
          >
            <ControlledCheckbox
              label="ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_WEEKENDS_TOOLTIP"
              name="ifWorktimeOnWeekend"
              control={control}
              placement="end"
              disabled={alwaysManualApproval}
            />
          </GridItem>
          <GridItem lg={12} sm={12} xl={12} xs={12} item>
            <ControlledCheckbox
              label="ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_HOLIDAYS_TOOLTIP"
              name="ifWorktimeOnHoliday"
              control={control}
              placement="end"
              disabled={alwaysManualApproval}
            />
          </GridItem>
          <GridItem lg={12} sm={12} xl={12} xs={12} item>
            <ControlledCheckbox
              label="ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_UOP_VIOLATED_TOOLTIP"
              name="ifECMinimalBreakViolated"
              control={control}
              placement="end"
              disabled={alwaysManualApproval}
            />
          </GridItem>
          <GridItem
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            item
            container
            alignItems="center"
          >
            <InformationIcon />
            <RulesInstruction>
              {intl.formatMessage({
                id: 'ACCEPTANCE_RULE.DIALOG_LEADERS',
              })}
            </RulesInstruction>
          </GridItem>
          <GridItem xs={12} item>
            <ControlledSelect
              label="ADMIN_PANEL.HEADER.LEADER"
              name="leaders"
              control={control}
              options={employeesData}
              required
              menuPlacement="top"
              isMulti
            />
          </GridItem>
        </GridWrapper>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="saveButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `MODAL.BUTTON.SUBMIT`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default ManualAcceptanceDialog;
