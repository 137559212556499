import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';
import {
  SummaryLabels,
  WorkTimeDefaultProjectInfo,
  WorkTimeTooltipData,
} from './types';

export const WORK_TIME_CUSTOM_PROJECTS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE_FILTER.LABEL.PROJECT_CUSTOM',
    key: 'project',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '35%',
  },
  {
    label: 'PROJECTS.PROJECT_TYPES.MULTIPLICATOR',
    key: 'multiplicator',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'PROJECTS.WORK_UNIT_AMOUNT',
    key: 'unitType',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'PROJECTS.WORK_UNIT_TYPE',
    key: 'amount',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '25%',
  },
];

export const WORK_TIME_QUICK_REPORT_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TABLE.HEAD.REPORTED_TIME',
    key: 'reportedDays',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TABLE.HEAD.REPORT_HOURS',
    key: 'reportedHours',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TABLE.HEAD.MULTIPLICATOR',
    key: 'multiplier',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
];

export const WORK_TIME_SUMMARY_HEAD_DATA: TableHead[] = [
  {
    label: 'WORK_TIME_TAB.SUMMARY',
    key: 'summary',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '40%',
  },
  {
    label: 'WORK_TIME_TAB.APPROVE_AND_SEND',
    key: 'submit',
    align: 'right',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '60%',
  },
];

export const WORK_TIME_HOLIDAYS_HEAD_DATA: TableHead[] = [];

export const SUMMARY_LABELS: SummaryLabels = {
  totalReportedDays: 'TABLE.HEAD.REPORTED_DAYS',
  totalReportedHours: 'SUMMARY.REPORTED_HOURS',
  monthWorkdays: 'SUMMARY.WORK_DAYS',
  unreportedDays: 'TABLE.HEAD.NOT_REPORTED_DAYS',
  medicalLeave: 'SUMMARY.MEDICAL_LEAVE',
  vacations: 'SUMMARY.VACATIONS',
  bankHolidays: 'SUMMARY.BANKHOLIDAYS',
  reportedWeekendsHolidays: 'SUMMARY.REPORTED_WEEKENDS',
  benefitsCostSummary: 'SUMMARY.BENEFITS_COST_SUMMARY',
  status: 'SUMMARY.STATUS',
  attachedFiles: 'SUMMARY.ATTACHED_FILES',
  additionalInformation: 'SUMMARY.ADDITIONAL_INFORMATION',
  mailStatus: 'SUMMARY_MAIL_LABEL',
  customDailyTimes: 'SUMMARY.CUSTOM_DAILY_TIMES',
  daysOff: 'SUMMARY.DAYS_OFF',
  remoteWork: 'SUMMARY.REMOTE_WORK',
};

export const TAB_LABELS = [
  { label: 'WORK_TIME_TAB.FULL_REPORT', key: 0, disabled: false },
  { label: 'WORK_TIME_TAB.QUICK_REPORT', key: 1, disabled: false },
  { label: 'WORK_TIME_TAB.SUMMARY', key: 2, disabled: false },
  //{ label: 'WORK_TIME_TAB.HOLIDAYS', key: 3, disabled: false },
];

export const TOOLTIP_DATA: WorkTimeTooltipData = {
  label: `QUICK_REPORT_TABLE.TOOLTIP_DATA`,
};

export const DEFAULT_PROJECT_LABEL: WorkTimeDefaultProjectInfo = {
  label: 'QUICK_REPORT_TABLE.DEFAULT_PROJECT_LABEL',
};

export const SUBMIT_BUTTON_LABEL = {
  label: 'WORK_TIME.SUBMIT_BUTTON',
};
