import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Box,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FilterList } from '@material-ui/icons';
import ContainedButton from 'components/Button/ContainedButton';
import Datepicker from 'components/Datepicker';
import { HeaderTooltipText } from 'components/Table/Table.css';
import TimesheetFilter from 'components/TimesheetFilter/TimesheetChipsFilter';
import { useToggleState } from 'utils/hooks/useToggleState';
import TimesheetFilters from './TimesheetFilters';
import { TimesheetBoxProps } from './types';
import { FilterButtonGrid } from './Timesheet.css';

const TimesheetsBox = ({
  anchorEl,
  handleRemindClick,
  handleRemindClose,
  timesheetsFilterData,
  control,
  getValues,
  client,
  project,
  date,
  onDateChange,
  onLeaderReminderClick,
  onEmployeeReminderClick,
  loading,
  handleDatepickerClearButtonClick,
}: TimesheetBoxProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [openFilters, setOpenFilters] = useToggleState(false);

  const betweenSmAndLgBreakpoint = useMediaQuery(
    theme.breakpoints.between('sm', 'lg'),
  );
  const aboveSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

  const filterSelected =
    getValues().approvedTimesheetsChecked ||
    getValues().readyForApprovalChecked ||
    getValues().myTimesheetsChecked ||
    getValues().emptyTimesheetsChecked;

  return (
    <Box padding="1rem 0 1rem 0">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid container item xs={12} sm={12} lg={12} xl={8}>
          <Grid item xs={12}>
            <Grid spacing={1} container justifyContent="space-between">
              {timesheetsFilterData.map(
                (entry, index): JSX.Element => (
                  <Grid item xs={12} sm={4} md={3} key={index}>
                    <Controller
                      name={entry.type}
                      control={control}
                      type={entry.type}
                      render={({ value, name, onChange }) => {
                        const handleChange = (
                          e: ChangeEvent<HTMLInputElement>,
                        ) => {
                          onChange(e);
                          if (entry.handleChange) {
                            entry.handleChange();
                          }
                        };
                        return (
                          <TimesheetFilter
                            label={intl.formatMessage({ id: entry.label })}
                            value={value}
                            type={name}
                            onChange={handleChange}
                            options={entry.options ?? []}
                            disabled={loading}
                          />
                        );
                      }}
                    />
                  </Grid>
                ),
              )}
              <Grid item xs={12} sm={12} md={3}>
                <Datepicker
                  label={intl.formatMessage({
                    id: 'TABLE_FILTER.LABEL.DATE',
                  })}
                  monthJumpButtons
                  value={date}
                  onChange={onDateChange}
                  onClearButtonClick={handleDatepickerClearButtonClick}
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          xl={4}
        >
          <Grid item xs={12} sm={4} lg={2} xl={8} alignItems="center">
            <FilterButtonGrid
              container
              justifyContent={
                betweenSmAndLgBreakpoint ? 'flex-start' : 'center'
              }
            >
              <ContainedButton
                startIcon={<FilterList />}
                size="large"
                color={filterSelected ? 'primary' : 'default'}
                onClick={setOpenFilters}
              >
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.FILTERS',
                })}
              </ContainedButton>
            </FilterButtonGrid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            md={6}
            lg={4}
            xl={4}
            justifyContent={aboveSmBreakpoint ? 'flex-end' : 'center'}
          >
            <Tooltip
              title={
                <HeaderTooltipText>
                  {intl.formatMessage({
                    id: 'REMINDER.TOOLTIP',
                  })}
                </HeaderTooltipText>
              }
              placement="bottom"
            >
              <ContainedButton
                id="reminderButton"
                size="large"
                onClick={handleRemindClick}
                disabled={loading}
              >
                {intl.formatMessage({ id: 'TIMESHEETS.REMINDER_BUTTON' })}
              </ContainedButton>
            </Tooltip>

            <Menu
              id="remind-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleRemindClose}
            >
              <MenuItem id="remindLeaders" onClick={onLeaderReminderClick}>
                {intl.formatMessage({ id: 'REMIND_LEADERS.BUTTON' })}
              </MenuItem>
              <MenuItem id="remindEmployees" onClick={onEmployeeReminderClick}>
                {intl.formatMessage({ id: 'REMIND_EMPLOYEES.BUTTON' })}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
      <TimesheetFilters
        control={control}
        loading={loading}
        open={openFilters}
        setOpen={setOpenFilters}
        client={client}
        project={project}
      />
    </Box>
  );
};

export default TimesheetsBox;
