import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const StyledFormControl = styled('form')`
  display: block;
  padding-top: 2rem;
  padding-bottom: 1rem;
  max-width: 90%;
  margin: 0 auto;
`;

export const GridGroup = styled(Grid)`
  &.MuiGrid-container {
    position: relative;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const GridItem = styled(Grid)`
  &.MuiGrid-item {
    padding: 0.5rem;
    width: 100vw;
    text-align: start;
  }
`;

export const GroupTitle = styled('span')`
  display: inline-block;
  padding: 0 10px;
  background-color: white;
  color: #000;
  position: absolute;
  top: -13px;
  left: 7.5%;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const GridInfo = styled(Grid)`
  &.MuiGrid-container {
    margin-top: -1rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }
`;
