import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledDatePicker from 'components/ControlledDatePicker';
import { DatePickerProps, ErrorProperties } from './types';
import {
  checkEmployeeErrors,
  checkPartnerErrors,
  checkTimeRangeErrors,
  getPropertyError,
} from './utils';
import { GridItem } from './BenefitInputs.css';

const BenefitDatePicker = ({
  label,
  name,
  rules,
  index,
  control,
  errors,
  disabled,
  nameOfErrorProperty = ErrorProperties.Employee,
  views,
  view,
  disablePast,
  disableFuture,
  clearable = true,
  format,
  xs = 12,
  sm = 12,
  md = 6,
  defaultValue = null,
  handleChange,
}: DatePickerProps) => {
  const intl = useIntl();
  const partnersErrors = checkPartnerErrors(errors, name, index) as FieldError;
  const timeRangeErrors = checkTimeRangeErrors(errors, name) as FieldError;
  const employeeErrors = checkEmployeeErrors(errors, name);
  const errorsName = errors[name] as FieldError;

  const errorProperty = useMemo(
    () =>
      getPropertyError({
        nameOfErrorProperty,
        partnersErrors,
        timeRangeErrors,
        employeeErrors,
      }),
    [employeeErrors, nameOfErrorProperty, partnersErrors, timeRangeErrors],
  );
  return (
    <GridItem item xs={xs} sm={sm} md={md}>
      <ControlledDatePicker
        control={control}
        label={intl.formatMessage({
          id: `BENEFITS.FORM.${label}`,
        })}
        name={name}
        rules={rules}
        errorMessage={errorsName?.message || errorProperty?.message}
        disabled={disabled}
        defaultValue={defaultValue}
        format={format}
        view={view}
        views={views}
        disablePast={disablePast}
        disableFuture={disableFuture}
        clearable={clearable}
        onAccept={handleChange}
      />
    </GridItem>
  );
};

export default BenefitDatePicker;
