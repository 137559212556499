import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { ContainedButton } from 'components/Button';
import ControlledSelect from 'components/ControlledSelect';
import { AddIcon } from 'components/Table/Table.css';
import { returnRoleInclusion } from 'utils/helpers/';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import BusinessTripCalculator from './Screens/BusinessTripCalculator';
import { TripCalculatorContext } from './Screens/BusinessTripCalculator/TripCalculatorContext';
import { ActionKind } from './Screens/BusinessTripCalculator/TripCalculatorContext/types';
import BusinessTripCheckboxes from './Screens/BusinessTripCheckboxes';
import { BusinessTripBoxProps } from './types';
import { BusinessTripsButton, BusinessTripsGrid } from './BusinessTrips.css';

const BusinessTripBox = ({
  isAccountPrivileged,
  loading,
  control,
  employees,
  clients,
  projects,
  statusesOptions,
  setValue,
  onAddBusinessTrip,
  isLeader,
  roles,
  checkboxesState,
  setCheckboxesState,
}: BusinessTripBoxProps) => {
  const intl = useIntl();
  const [openFilters, setOpenFilters] = useToggleState(false);
  const [showCalculator, handleToggleCalculator] = useToggleState(false);
  const { dispatch } = useContext(TripCalculatorContext);
  const shouldBeVisible = isAccountPrivileged || isLeader;
  const smallSizeSelectGrid = shouldBeVisible ? 3 : 4;

  const isAccounting = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]),
    [roles],
  );

  const handleEmptyCalculatorClick = () => {
    dispatch({
      type: ActionKind.SetIsEmptyCalculation,
      payload: { isEmptyCalculation: true },
    });
    handleToggleCalculator();
  };

  const filterSelected =
    checkboxesState.showAccepted ||
    checkboxesState.showSettled ||
    checkboxesState.showToAccept ||
    checkboxesState.showOnlyMine ||
    checkboxesState.showToSettle;

  return (
    <>
      <BusinessTripsGrid
        container
        direction="row"
        alignItems="center"
        justify="center"
        spacing={1}
      >
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid spacing={1} container xs={12} md={10} justify="space-between">
              <Grid item xs={12} sm={smallSizeSelectGrid}>
                <ControlledSelect
                  label="TABLE.HEAD.CLIENT"
                  name="client"
                  control={control}
                  options={clients}
                  isDisabled={loading}
                  handleChange={() => setValue('project', '')}
                />
              </Grid>
              <Grid item xs={12} sm={smallSizeSelectGrid}>
                <ControlledSelect
                  label="TABLE.HEAD.PROJECT"
                  name="project"
                  control={control}
                  options={projects}
                  isDisabled={loading}
                />
              </Grid>
              {shouldBeVisible && (
                <Grid item xs={12} sm={smallSizeSelectGrid}>
                  <ControlledSelect
                    label="TABLE.HEAD.NAME"
                    name="employee"
                    control={control}
                    options={employees}
                    isDisabled={loading}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={smallSizeSelectGrid}>
                <ControlledSelect
                  label="TABLE.HEAD.STATUS"
                  name="status"
                  control={control}
                  options={statusesOptions}
                  isDisabled={loading}
                />
              </Grid>
            </Grid>
            {shouldBeVisible && (
              <Grid container xs={12} md={2} justify="flex-end">
                <BusinessTripsButton
                  startIcon={<FilterList />}
                  size="large"
                  color={filterSelected ? 'primary' : 'default'}
                  onClick={setOpenFilters}
                >
                  {intl.formatMessage({
                    id: 'BUSINESS_TRIP.FILTERS',
                  })}
                </BusinessTripsButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container xs={12} justify="space-between">
          <Grid item>
            {isAccounting && (
              <ContainedButton
                size="large"
                onClick={handleEmptyCalculatorClick}
                style={{ marginTop: '1rem' }}
              >
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.EMPTY_CALCULATOR_BUTTON',
                })}
              </ContainedButton>
            )}
          </Grid>
          <Grid item>
            <ContainedButton
              endIcon={<AddIcon />}
              size="large"
              onClick={onAddBusinessTrip}
              style={{ marginTop: '1rem' }}
            >
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.ADD',
              })}
            </ContainedButton>
          </Grid>
        </Grid>
      </BusinessTripsGrid>
      <BusinessTripCheckboxes
        shouldBeVisible={shouldBeVisible}
        control={control}
        loading={loading}
        isLeader={isLeader}
        open={openFilters}
        roles={roles}
        setOpen={setOpenFilters}
        checkboxesState={checkboxesState}
        setCheckboxesState={setCheckboxesState}
      />
      {showCalculator && (
        <BusinessTripCalculator
          showCalculator={showCalculator}
          handleToggleCalculator={handleToggleCalculator}
        />
      )}
    </>
  );
};

export default BusinessTripBox;
