import CheckboxComponent, { CheckboxProps } from '@material-ui/core/Checkbox';
import palette from 'assets/palette';
import styled from 'styled-components';

export const Checkbox = styled(CheckboxComponent)<CheckboxProps>`
  &.MuiCheckbox-root {
    color: rgb(0 0 0);

    ${({ disabled }) => disabled && 'background: white !important;'}
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${palette.ACTION};
  }

  &.MuiIconButton-colorSecondary:hover {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;
