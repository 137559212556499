import { createContext, ReactNode, useReducer } from 'react';
import { reducer, tripCalculatorInitialState } from './reducer';
import { TripCalculatorContextProps } from './types';

interface Props {
  children: ReactNode;
}

const initialContextState = {
  dispatch: () => {
    // intentional empty function
  },
  state: tripCalculatorInitialState,
};

const TripCalculatorContext =
  createContext<TripCalculatorContextProps>(initialContextState);
const TripCalculatorContextProvider = TripCalculatorContext.Provider;

const TripCalculatorProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, tripCalculatorInitialState);
  return (
    <TripCalculatorContextProvider value={{ state, dispatch }}>
      {children}
    </TripCalculatorContextProvider>
  );
};

export { TripCalculatorContext, TripCalculatorProvider };
