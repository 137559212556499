import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Block } from '@material-ui/icons';
import { GlobalContext } from 'globalContext/GlobalContext';
import { UserContext } from 'globalContext/UserContext';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import { displayLabelRows } from 'utils/displayLabelRows';
import { useToggleState } from 'utils/hooks/useToggleState';
import { HoursCell } from './HoursCell';
import { Report, TableHead, TableProps } from './types';
import {
  Paper,
  SortAsc,
  SortDesc,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TablePagination,
  TableRow,
  Unsorted,
} from './Table.css';

const Table = ({
  sorting = { column: 'name', direction: 'asc' },
  setRowsPerPage = () => {
    // intentional empty function
  },
  setCurrentPage = () => {
    // intentional empty function
  },
  setSorting = () => {
    // intentional empty function
  },
  withPagination = false,
  loading = false,
  currentPage = 0,
  pageCount = 1,
  limit,
  head,
  data,
  toggleSorting,
  toggleWorktimeDisplay,
}: TableProps) => {
  const intl = useIntl();
  const [hoursChecked, handleChange] = useToggleState(false);
  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);
  const { roles } = useContext(UserContext);
  const renderHoursCellData = (entry: Report) =>
    hoursChecked
      ? entry.workInHours
      : entry.workInDays

  const changeTimeDisplay = () => {
    handleChange();
    toggleWorktimeDisplay &&
      toggleWorktimeDisplay((displayDays) => !displayDays);
  };

  const renderLabel = (headData: TableHead) => {
    if (headData.key === 'reportedHours') {
      return HoursCell(hoursChecked, intl, changeTimeDisplay);
    }

    return intl.formatMessage({ id: headData.label });
  };

  const renderCellData = (key: keyof Report, entry: Report) => {
    if (key === 'reportedHours' && entry.workInHours > 0 && entry.workInDays > 0) {
      return renderHoursCellData(entry);
    }
    if (key === 'multiplier' && entry.multiplier !== undefined) {
      return `${entry.multiplicator * 100}%`;
    }
    if (key === 'unitType') {
      return entry.workUnitTypes;
    }
    if (key === 'reportedUnits' && entry.workUnits > 0) {
      return entry.workUnits;
    }
    if (key === 'travelBy') {
      return entry.travelBy
        ? intl.formatMessage({
            id: `BUSINESS_TRIP.${entry.travelBy}`,
          })
        : '-';
    }
    return entry[key];
  };

  const renderSortingIcon = (
    column: string,
    sortable: boolean,
    sorting: { column: string; direction: 'asc' | 'desc' },
  ) => {
    if (!sortable) {
      return null;
    }
    if (sortable && column === sorting.column && sorting.direction === 'asc') {
      return (
        <SortAsc
          onClick={() => toggleSorting(column, sorting, setSorting)}
          active={column === sorting.column ? 1 : 0}
          id={`${column}_sortAsc`}
        />
      );
    }
    if (sortable && column === sorting.column && sorting.direction === 'desc') {
      return (
        <SortDesc
          onClick={() => toggleSorting(column, sorting, setSorting)}
          active={column === sorting.column ? 1 : 0}
          id={`${column}_sortDesc`}
        />
      );
    }
    return (
      <Unsorted
        onClick={() => toggleSorting(column, sorting, setSorting)}
        id={`${column}_unsorted`}
      />
    );
  };

  return (
    <TableContainer component={Paper} $loading={loading}>
      {withPagination && !!limit && (
        <TablePagination
          onChangeRowsPerPage={setRowsPerPage}
          onPageChange={setCurrentPage}
          labelRowsPerPage={intl.formatMessage({
            id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
          })}
          labelDisplayedRows={({ from, to, count }) =>
            displayLabelRows({ from, to, count, intl })
          }
          rowsPerPageOptions={[]}
          rowsPerPage={limit}
          page={currentPage}
          count={pageCount}
          //@ts-ignore
          component={'div'}
        />
      )}
      <TableComponent>
        <TableLoadingWrapper loading={loading}>
          <>
            <TableHeadComponent>
              <TableRow $main>
                {head.map(
                  (headData, index) =>
                    roles.includes(headData.role) && (
                      <TableCell
                        align={headData.align}
                        key={`${index} - ${headData.key}`}
                      >
                        {renderLabel(headData)}
                        {renderSortingIcon(
                          headData.key,
                          headData.sortable,
                          sorting,
                        )}
                      </TableCell>
                    ),
                )}
              </TableRow>
            </TableHeadComponent>

            <TableBody>
              {data.length ? (
                data.map((entry: Report, index: number) => (
                  <TableRow key={`${entry.name}-${index}`} index={index}>
                    {head.map(
                      ({ key, role }, headIndex: number) =>
                        roles.includes(role) && (
                          <TableCell
                            align={
                              head.length ? head[headIndex].align : undefined
                            }
                            key={`${entry.name.concat(
                              key,
                            )}-${index}-${headIndex}-${currentLocale}`}
                            component="th"
                            scope="row"
                          >
                            {renderCellData(key, entry)}
                          </TableCell>
                        ),
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align={'center'}>
                    <Block />
                    <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </>
        </TableLoadingWrapper>
      </TableComponent>
      {withPagination && !!limit && (
        <TablePagination
          onChangeRowsPerPage={setRowsPerPage}
          onPageChange={setCurrentPage}
          labelRowsPerPage={intl.formatMessage({
            id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
          })}
          labelDisplayedRows={({ from, to, count }) =>
            displayLabelRows({ from, to, count, intl })
          }
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowsPerPage={limit}
          page={currentPage}
          count={pageCount}
          //@ts-ignore
          component={'div'}
        />
      )}
    </TableContainer>
  );
};

export default Table;
