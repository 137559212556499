import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const BENEFITS_TABLE_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.BENEFITS',
    key: 'benefit',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '8%',
  },
  {
    label: 'TABLE.HEAD.BENEFICIARY',
    key: 'benefit',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.BENEFIT_TYPE',
    key: 'benefit',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '16%',
  },
  {
    label: 'TABLE.HEAD.BENEFIT_PACKAGE',
    key: 'benefit',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '16%',
  },

  {
    label: 'TABLE.HEAD.MONTHLY_COST',
    key: 'cost',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.SUBSCRIPTION_START_DATE',
    key: 'deadline',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.SUBSCRIPTION_END_DATE',
    key: 'deadline',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },

  {
    label: 'TABLE.HEAD.STATUS',
    key: 'cost',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
];

export enum BENEFIT_PARTNER_TYPES {
  adult = 'ADULT',
  child = 'CHILD',
}
