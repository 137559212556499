import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { GlobalContext } from 'globalContext/GlobalContext';
import { UserContext } from 'globalContext/UserContext';
import { clientCustomFieldUrl, projectCustomFieldUrl } from 'router/url';
import AccountingNotesComponent from 'components/AccountingNotesList';
import CustomFieldsList from 'components/CustomFieldsList';
import AccountingNoteDialog from 'components/Dialog/AccountingNoteDialog/AccountingNoteDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import CustomFieldDialog from 'components/Dialog/CustomFieldDialog';
import ProjectDialog from 'components/Dialog/ProjectDialog';
import { ProjectData } from 'components/Dialog/types';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import { displayLabelRows } from 'utils/displayLabelRows';
import { returnRoleInclusion } from 'utils/helpers';
import { useEditIcon } from 'utils/hooks/useEditIcon';
import { useIndexIcon } from 'utils/hooks/useIndexIcon';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import EmptyDataRow from './EmptyDataRow';
import {
  CustomFieldsRow,
  renderActionIcons,
  renderTableCellData,
} from './tableUtils';
import { CustomField, Project, TableProjectProps as TableProps } from './types';
import {
  BoldedCellText,
  DescriptionText,
  DetailsTitle,
  Paper,
  ProjectDetailsBox,
  RowDescriptionWrapper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TablePagination,
  TableRow,
  TextValue,
} from './Table.css';

const ProjectsTable = ({
  fetchProjects,
  setRowsPerPage,
  setCurrentPage,
  withPagination = false,
  loading = false,
  currentPage = 0,
  pageCount = 1,
  limit = 10,
  head,
  data,
  addChecked,
  handleAddChanged,
  formData,
  deleteCustomField,
  changeProjectStatus,
  isAllowedForActions,
}: TableProps) => {
  const [showDescription, selectedId, renderIcon, resetIndex] =
    useIndexIcon(formData);
  const [addCustomField, handleAddCustomField] = useToggleState(false);
  const [showConfimationDialog, handleShowConfimationDialog] =
    useToggleState(false);
  const [dialogData, setDialogData] = useState<ProjectData>();
  const [customFieldData, setCustomFieldData] = useState<CustomField>();
  const [activateClicked, handleActivateClicked] = useToggleState(false);
  const [addAccountingNote, handleAddAccountingNote] = useToggleState(false);
  const [editClicked, renderEditIcon] = useEditIcon<ProjectData[]>(
    addChecked,
    handleAddChanged,
    setDialogData,
  );
  const [editCustomFieldClicked, renderCustomFieldEditIcon] = useEditIcon<
    CustomField[]
  >(addCustomField, handleAddCustomField, setCustomFieldData);
  const intl = useIntl();
  const { teamLeader: isTeamLeader, roles } = useContext(UserContext);
  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);

  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]) || !!isTeamLeader,
    [isTeamLeader, roles],
  );

  const renderCellData = useCallback(
    (entry: Project, index: number, key: string) => {
      switch (key) {
        case 'description':
          return renderIcon(index);
        case 'project':
          return index === selectedId && showDescription ? (
            <BoldedCellText>{entry[key]}</BoldedCellText>
          ) : (
            renderTableCellData(entry[key])
          );
        case 'action':
          return renderActionIcons<ProjectData[]>({
            renderEditIcon,
            setDialogData,
            index,
            data,
            intl,
            handleShowConfimationDialog,
            isArchivable: true,
            isDeletable: false,
            elementToRemove: 'project',
            entry,
            handleActivateClicked,
            isAllowedForActions,
          });
        default:
          return renderTableCellData(entry[key]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      handleActivateClicked,
      handleShowConfimationDialog,
      intl,
      renderEditIcon,
      renderIcon,
      selectedId,
      showDescription,
    ],
  );

  const handleSubmitActivate = useCallback(() => {
    handleShowConfimationDialog();
    handleActivateClicked();
    !!dialogData && changeProjectStatus(dialogData.id);
    resetIndex();
  }, [
    changeProjectStatus,
    dialogData,
    handleActivateClicked,
    handleShowConfimationDialog,
    resetIndex,
  ]);

  const handleActiveStatusChanged = () =>
    activateClicked && handleActivateClicked();

  const onAddCustomFieldClicked = useCallback(
    (index: number) => {
      handleAddCustomField();
      setDialogData(data[index]);
    },
    [data, handleAddCustomField],
  );

  const onAddAccountingNoteClicked = useCallback(
    (index: number) => {
      handleAddAccountingNote();
      setDialogData(data[index]);
    },
    [data, handleAddAccountingNote],
  );

  return (
    <>
      <TableContainer component={Paper} $loading={loading}>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
        <TableComponent>
          <TableLoadingWrapper loading={loading}>
            <>
              <TableHeadComponent>
                <TableRow $main>
                  {head.map((headData, index) => (
                    <TableCell
                      align={headData.align}
                      key={`cell - ${index} - ${headData.key}`}
                    >
                      {intl.formatMessage({ id: headData.label })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeadComponent>

              <TableBody>
                {!data?.length && <EmptyDataRow colSpan={head.length} />}
                {data?.length > 0 &&
                  data.map((entry: Project, index: number) => (
                    <>
                      <TableRow
                        key={`row - ${entry} - ${index}`}
                        index={index}
                        $selected={selectedId === index}
                      >
                        {head.map(({ key }, headIndex: number) => (
                          <TableCell
                            align={
                              head.length ? head[headIndex].align : undefined
                            }
                            key={`${entry}-${index}-${headIndex}-${currentLocale}`}
                            component="th"
                            scope="row"
                          >
                            {renderCellData(entry, index, key)}
                          </TableCell>
                        ))}
                      </TableRow>
                      {selectedId === index && (
                        <TableRow
                          key={`description - ${entry} - ${index}`}
                          $selected
                        >
                          <TableCell colSpan={6}>
                            <Grid
                              container
                              alignItems="center"
                              justify="center"
                            >
                              {showDescription && (
                                <Grid xs={12} item>
                                  <ProjectDetailsBox>
                                    <DetailsTitle>
                                      {intl.formatMessage({
                                        id: 'ACCORDION.DESCRIPTION',
                                      })}
                                    </DetailsTitle>
                                    <DescriptionText>
                                      {entry.description ||
                                        intl.formatMessage({
                                          id: 'SUMMARY.NONE',
                                        })}
                                    </DescriptionText>
                                  </ProjectDetailsBox>
                                  <ProjectDetailsBox>
                                    <CustomFieldsRow
                                      intl={intl}
                                      onAddCustomFieldClicked={
                                        onAddCustomFieldClicked
                                      }
                                      index={index}
                                      isAccountPrivileged={isAccountPrivileged}
                                    />
                                    <>
                                      {entry.clientCustomFields?.length > 0 && (
                                        <>
                                          <RowDescriptionWrapper>
                                            {`${intl.formatMessage({
                                              id: 'TABLE.HEAD.CLIENT',
                                            })}`}
                                          </RowDescriptionWrapper>
                                          <CustomFieldsList
                                            data={entry.clientCustomFields}
                                            deleteCustomField={
                                              deleteCustomField
                                            }
                                            url={clientCustomFieldUrl}
                                            isAccountPrivileged={
                                              isAccountPrivileged
                                            }
                                            renderCustomFieldEditIcon={
                                              renderCustomFieldEditIcon
                                            }
                                          />
                                        </>
                                      )}
                                      {entry.projectCustomFields?.length >
                                        0 && (
                                        <>
                                          <RowDescriptionWrapper>
                                            {`${intl.formatMessage({
                                              id: 'TABLE.HEAD.PROJECT',
                                            })}`}
                                          </RowDescriptionWrapper>
                                          <CustomFieldsList
                                            data={entry.projectCustomFields}
                                            deleteCustomField={
                                              deleteCustomField
                                            }
                                            url={projectCustomFieldUrl}
                                            isAccountPrivileged={
                                              isAccountPrivileged
                                            }
                                            renderCustomFieldEditIcon={
                                              renderCustomFieldEditIcon
                                            }
                                          />
                                        </>
                                      )}
                                      {!entry.projectCustomFields?.length &&
                                        !entry.clientCustomFields?.length && (
                                          <DescriptionText>
                                            {intl.formatMessage({
                                              id: 'SUMMARY.NONE',
                                            })}
                                          </DescriptionText>
                                        )}
                                    </>
                                  </ProjectDetailsBox>
                                  {isAccountPrivileged && (
                                    <>
                                      <ProjectDetailsBox>
                                        <AccountingNotesComponent
                                          onAddAccountingNoteClicked={
                                            onAddAccountingNoteClicked
                                          }
                                          index={index}
                                          notes={entry.accountingNotes}
                                          fetchData={fetchProjects}
                                          data={data[index]}
                                        />
                                      </ProjectDetailsBox>
                                      <ProjectDetailsBox
                                        style={{
                                          marginTop: '10px',
                                          border: '0',
                                        }}
                                      >
                                        <DetailsTitle>
                                          {intl.formatMessage({
                                            id: 'PROJECTS.BILLABLE_TITLE',
                                          })}
                                        </DetailsTitle>
                                        <TextValue>
                                          {intl.formatMessage({
                                            id: `PROJECTS.BILLABLE_${
                                              entry?.billable
                                                ? 'CONFIRM'
                                                : 'DENY'
                                            }`,
                                          })}
                                        </TextValue>
                                      </ProjectDetailsBox>
                                    </>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
      </TableContainer>
      {showConfimationDialog && (
        <ConfirmationDialog
          addChecked={showConfimationDialog}
          handleAddChanged={handleShowConfimationDialog}
          activateClicked={activateClicked}
          handleSubmit={handleSubmitActivate}
          handleActivateClicked={handleActiveStatusChanged}
          title={intl.formatMessage({
            id: `PROJECTS.${
              activateClicked ? 'DEACTIVATE' : 'DELETE'
            }_DIALOG_TITLE`,
          })}
          content={intl.formatMessage({
            id: `PROJECTS.${
              activateClicked ? 'DEACTIVATE' : 'DELETE'
            }_DIALOG_CONTENT`,
          })}
          data={dialogData}
        />
      )}
      {addCustomField && (
        <CustomFieldDialog
          addChecked={addCustomField}
          handleAddChanged={handleAddCustomField}
          fetchData={fetchProjects}
          data={editCustomFieldClicked ? customFieldData : dialogData}
          editClicked={editCustomFieldClicked}
        />
      )}
      {addChecked && (
        <ProjectDialog
          fetchProjects={fetchProjects}
          addChecked={addChecked}
          handleAddChanged={handleAddChanged}
          editClicked={editClicked}
          data={dialogData}
        />
      )}
      {addAccountingNote && (
        <AccountingNoteDialog
          addChecked={addAccountingNote}
          handleAddChanged={handleAddAccountingNote}
          data={dialogData}
          fetchData={fetchProjects}
        />
      )}
    </>
  );
};

export default ProjectsTable;
