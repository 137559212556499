import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import BenefitDatePicker from 'components/BenefitInputs/BenefitDatePicker';
import BenefitSelectField from 'components/BenefitInputs/BenefitSelectField';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import { ErrorProperties } from 'components/BenefitInputs/types';
import {
  BorderedGrid,
  GridTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { getHelperText, validate } from 'utils/formValidation';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import {
  getTimeRangeData,
  getTimeRangeDefaultSelectValue,
  getTimeRangeName,
  getUpdatedDaysMonthsYearsOption,
} from '../inputHelpers';
import { TimeRangeSelectProps } from '../types';

const TimeRangeSelect = ({
  control,
  errors,
  getValues,
  setValue,
  type,
}: TimeRangeSelectProps) => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  const getInputsData = useCallback(
    () => getTimeRangeData(intl, formState, getValues, type),
    [formState, getValues, intl, type],
  );

  const clearDatePicker = useCallback(() => {
    if (getValues()[type]?.fixedDate) {
      setValue(getTimeRangeName(type, 'fixedDate'), null);
    }
  }, [getValues, setValue, type]);

  const handleTextFieldChange = useCallback(() => {
    const selectOption = getUpdatedDaysMonthsYearsOption(
      intl,
      getValues()[type]?.numberOfDaysMonthsYears ?? 0,
      getValues()[type]?.dayMonthYear?.value ?? null,
    );

    if (selectOption.value) {
      setValue(getTimeRangeName(type, 'dayMonthYear'), selectOption, {
        shouldValidate: true,
      });
    } else if (
      !getValues()[type]?.dayMonthYear?.value &&
      getValues()[type]?.numberOfDaysMonthsYears
    ) {
      setValue(
        getTimeRangeName(type, 'dayMonthYear'),
        getTimeRangeDefaultSelectValue(
          intl,
          getValues()[type]?.numberOfDaysMonthsYears,
        ),
        { shouldValidate: true },
      );
    }
    clearDatePicker();
  }, [clearDatePicker, getValues, intl, setValue, type]);

  const handleDatePickerChange = useCallback(() => {
    if (getValues()[type]?.fixedDate) {
      setValue(getTimeRangeName(type, 'dayMonthYear'), null);
      setValue(getTimeRangeName(type, 'numberOfDaysMonthsYears'), '');
    } else {
      setValue(
        getTimeRangeName(type, 'dayMonthYear'),
        getTimeRangeDefaultSelectValue(intl),
        { shouldValidate: true },
      );
      setValue(getTimeRangeName(type, 'numberOfDaysMonthsYears'), '0', {
        shouldValidate: true,
      });
    }
  }, [getValues, intl, setValue, type]);

  const handleSelectFieldChange = () => {
    clearDatePicker();
    if (
      String(getValues()[type].numberOfDaysMonthsYears) === '' &&
      getValues()[type]?.dayMonthYear?.value
    ) {
      setValue(getTimeRangeName(type, 'numberOfDaysMonthsYears'), '0', {
        shouldValidate: true,
      });
    }
  };

  const getValidation = useCallback(() => {
    if (
      (getValues()[type]?.fixedDate || formState[type]?.fixedDate) &&
      !getValues()[type]?.dayMonthYear?.value &&
      !getValues()[type]?.numberOfDaysMonthsYears
    ) {
      return { required: false };
    } else {
      return validate(intl).required;
    }
  }, [formState, getValues, intl, type]);

  return (
    <BorderedGrid container justify="center">
      <GridTitle item xs={12}>
        {getInputsData().label}
      </GridTitle>
      <BenefitTextField
        label="NUMBER_OF_DAY_MONTH_YEAR"
        name={getInputsData().textFieldName}
        defaultValue={getInputsData().textFieldDefaultValue}
        rules={getValidation()}
        nameOfErrorProperty={ErrorProperties.TimeRange}
        handleChange={handleTextFieldChange}
        control={control}
        errors={errors}
        isOnlyInteger
      />
      <BenefitSelectField
        label="DAY_MONTH_YEAR"
        name={getInputsData().selectFieldName}
        options={getInputsData().selectOptions}
        defaultValue={getInputsData().selectFieldDefaultValue}
        rules={getValidation()}
        nameOfErrorProperty={ErrorProperties.TimeRange}
        errorMessage={getHelperText(intl).required}
        control={control}
        errors={errors}
        handleChange={handleSelectFieldChange}
      />
      <BenefitDatePicker
        label="DATE"
        name={getInputsData().datePickerName}
        defaultValue={getInputsData().datePickerDefaultValue}
        rules={validate(intl).unrequiredDate}
        nameOfErrorProperty={ErrorProperties.TimeRange}
        control={control}
        errors={errors}
        format="Do MMMM"
        view="month"
        views={['month', 'date']}
        handleChange={handleDatePickerChange}
      />
    </BorderedGrid>
  );
};

export default TimeRangeSelect;
