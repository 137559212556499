import { useIntl } from 'react-intl';
import { Card, CardContent } from '@material-ui/core';
import {
  BenefitTypesProps,
  ChildProperties,
  Packages,
  PartnerProperties,
  PersonCosts,
} from 'components/BenefitInputs/types';
import {
  getCost,
  getFormattedName,
  stringEnumIncludesProperty,
} from '../../../utils';
import { Paragraph, SecondaryTitle } from '../../../BenefitsManager.css';

interface Props {
  type: BenefitTypesProps;
  property: Packages | PersonCosts;
}

const PackageOrPersonCard = ({ type, property }: Props) => {
  const intl = useIntl();

  const isActive =
    type?.[property]?.active ||
    property === Packages.individual ||
    property === PersonCosts.employee;

  const checkIfChildPropertyExists = (childProperty: ChildProperties) => {
    if (
      (property === Packages.family || property === PersonCosts.child) &&
      type[property]?.[childProperty]
    ) {
      const content = `${intl.formatMessage({
        id: `BENEFITS.FORM.${getFormattedName(childProperty)}`,
      })}: ${type[property]?.[childProperty]}`;
      return <Paragraph>{content}</Paragraph>;
    }

    return null;
  };

  const checkIfPartnerPropertyExists = (partnerProperty: PartnerProperties) => {
    if (property === Packages.partner && type[property]?.[partnerProperty]) {
      const content = `${intl.formatMessage({
        id: `BENEFITS.FORM.${getFormattedName(partnerProperty)}`,
      })}: ${type[property]?.[partnerProperty]}`;
      return <Paragraph>{content}</Paragraph>;
    }
    return null;
  };

  const getTitle = () => {
    if (stringEnumIncludesProperty(PersonCosts, property)) {
      return getFormattedName(property, 0);
    } else {
      return getFormattedName(property);
    }
  };

  return isActive ? (
    <Card style={{ alignSelf: 'flex-start' }}>
      <CardContent>
        <SecondaryTitle>
          {intl.formatMessage({
            id: `BENEFITS.FORM.${getTitle()}`,
          })}
        </SecondaryTitle>
        <Paragraph>{getCost(intl, type, property)}</Paragraph>
        {checkIfPartnerPropertyExists(PartnerProperties.minAge)}
        {checkIfPartnerPropertyExists(PartnerProperties.maxAge)}
        {checkIfChildPropertyExists(ChildProperties.minAge)}
        {checkIfChildPropertyExists(ChildProperties.maxAge)}
        {checkIfChildPropertyExists(ChildProperties.amount)}
      </CardContent>
    </Card>
  ) : null;
};

export default PackageOrPersonCard;
