import { UserState } from './types';

export interface ADD_GLOBAL_DATA {
  payload: {
    roles: string[];
    teamLeader: boolean;
    username: string;
  };
  type: 'ADD_GLOBAL_DATA';
}
export type Action = ADD_GLOBAL_DATA;

export const userReducer = (state: UserState, action: Action) => {
  return action.type === 'ADD_GLOBAL_DATA'
    ? { ...state, ...action.payload }
    : state;
};
