import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  BillabilityTypes,
  Packages,
  PersonCosts,
} from 'components/BenefitInputs/types';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import InfoGroup from './InfoGroup';
import PackageOrPersonCard from './PackageOrPersonCard';
import { InfoContainer, InputTitle } from '../../../BenefitsManager.css';

const CostContainer = () => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  return (
    <>
      {formState.benefitTypes?.map((type) => (
        <InfoGroup
          key={type.benefitTypeName}
          title={`${intl.formatMessage({ id: 'BENEFITS.PACKAGES' })} - ${
            type.benefitTypeName
          }`}
        >
          {!!type.companyCost && (
            <InfoContainer md={12} $centered $bottomSpace>
              <InputTitle>
                {intl.formatMessage({
                  id: 'BENEFITS.FORM.COMPANY_COST',
                })}
                :
              </InputTitle>
              <span>{`${Number(type.companyCost).toFixed(2)} zł`}</span>
            </InfoContainer>
          )}
          {formState.billableBy === BillabilityTypes.package && (
            <>
              <PackageOrPersonCard type={type} property={Packages.individual} />
              <PackageOrPersonCard type={type} property={Packages.partner} />
              <PackageOrPersonCard type={type} property={Packages.family} />
            </>
          )}
          {formState.billableBy === BillabilityTypes.person && (
            <>
              <PackageOrPersonCard
                type={type}
                property={PersonCosts.employee}
              />
              <PackageOrPersonCard type={type} property={PersonCosts.partner} />
              <PackageOrPersonCard type={type} property={PersonCosts.child} />
            </>
          )}
        </InfoGroup>
      ))}
    </>
  );
};

export default CostContainer;
