import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import {
  deleteEmployeeFromExcludedEmployees,
  fetchEmployeeListWithUsername,
  fetchExcludedEmployees,
  postEmployeeToExclude,
} from './api';
import ExcludeTimeSheetTable from './ExcludeTimesheetTable';
import { EmployeeData, ExcludedEmployeeData, FormValues } from './types';

const defaultValues = {
  value: 0,
  name: '',
  username: '',
};

const joinSurname = (obj: {
  id: number;
  name: string;
  surname: string;
  username: string;
}) => ({
  value: obj.id,
  name: `${obj.name} ${obj.surname}`,
  username: obj.username,
});

const ExcludeTimesheetComponent = () => {
  const intl = useIntl();
  const [employeesData, setEmployeesData] = useState<EmployeeData[]>([]);
  const [excludedEmployees, setExcludedEmployees] = useState<
    ExcludedEmployeeData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchEmployees = useCallback(async () => {
    const data = await fetchEmployeeListWithUsername();
    setEmployeesData(data.map(joinSurname));
  }, []);

  const fetchExcluded = useCallback(async () => {
    setLoading(true);
    const data = await fetchExcludedEmployees();
    setExcludedEmployees(data.reverse());
    setLoading(false);
  }, []);

  const postExclude = useCallback(
    async (username: string) => {
      const { ok } = await postEmployeeToExclude({ username, intl });
      if (ok) {
        fetchExcluded();
      }
    },
    [fetchExcluded, intl],
  );

  const handleDelete = useCallback(
    async (username: string) => {
      await deleteEmployeeFromExcludedEmployees({
        username,
        intl,
      });
      fetchExcluded();
    },
    [fetchExcluded, intl],
  );

  const filterNotExcludedEmployees = useMemo(() => {
    return employeesData.filter((employee) =>
      excludedEmployees.every(
        (excluded) => excluded.account.username !== employee.username,
      ),
    );
  }, [employeesData, excludedEmployees]);

  useEffect(() => {
    fetchEmployees();
    fetchExcluded();
  }, [fetchEmployees, fetchExcluded]);

  const { control, reset, handleSubmit } = useForm<EmployeeData>({
    defaultValues,
  });

  const { employee } = useWatch({ control });
  const isButtonDisabled = !employee;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    postExclude(data.employee.username);
    reset(defaultValues);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} sm={8}>
                <ControlledSelect
                  label="ADMIN_PANEL.EXCLUDE_SELECT"
                  name="employee"
                  control={control}
                  options={filterNotExcludedEmployees}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ContainedButton
                  size="large"
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  {intl.formatMessage({ id: 'ADMIN_PANEL.EXCLUDE_BUTTON' })}
                </ContainedButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ExcludeTimeSheetTable
              excludedEmployees={excludedEmployees}
              loading={loading}
              handleDelete={handleDelete}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ExcludeTimesheetComponent;
