import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import AssignIcon from '@material-ui/icons/AssignmentInd';
import { teamLeaderAssignmentsUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledCheckbox from 'components/ControlledCheckbox/ControlledCheckbox';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import {
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { postFetch } from 'utils/fetchFunctions';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { FormValues } from './types';
import { GridItem, GridWrapper } from './ManagerForm.css';

export default function ManagerForm() {
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const { control, handleSubmit, watch, formState, reset } =
    useForm<FormValues>({ mode: 'onChange' });
  const data = watch();
  const intl = useIntl();

  const optionsData: { name: string; value: string }[] = [
    {
      name: intl.formatMessage({ id: 'MANAGER_FORM.CLIENT' }),
      value: 'CLIENT',
    },
    {
      name: intl.formatMessage({ id: 'MANAGER_FORM.EMPLOYEE' }),
      value: 'EMPLOYEE',
    },
    {
      name: intl.formatMessage({ id: 'MANAGER_FORM.PROJECT' }),
      value: 'PROJECT',
    },
  ];

  const returnData = useCallback(
    (type: string) => {
      switch (type) {
        case 'CLIENT':
          return clientsData;
        case 'PROJECT':
          return projectsData;
        case 'EMPLOYEE':
          return employeesData;
        default:
          return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientsData, employeesData, projectsData],
  );

  useEffect(() => {
    fetchEmployeeList(setEmployeesData);
    fetchClientList(setClientsData);
    fetchProjectList(setProjectsData);
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    let { type, overtime, manager, target } = data;

    let body = {
      assignmentType: type.value,
      idToAssign: target.value,
      overtimeHours: !!overtime,
      teamLeaderId: manager.value,
    };
    await postFetch({
      url: teamLeaderAssignmentsUrl,
      body,
      intl,
      label: 'MANAGER_FORM',
    });

    reset();
  });

  return (
    <Box overflow="auto" minHeight="calc(100vh - 200px)">
      <form className="form" onSubmit={onSubmit}>
        <GridWrapper
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <GridItem lg={12} sm={12} key="type" xl={12} xs={12} item>
            <ControlledSelect
              label="MANAGER_FORM.MANAGER_TYPE"
              name="type"
              control={control}
              options={optionsData}
              required
            />
          </GridItem>
          <GridItem lg={12} sm={12} key="type" xl={12} xs={12} item>
            <ControlledSelect
              label="MANAGER_FORM.TARGET"
              name="target"
              control={control}
              options={data.type ? returnData(data.type.value) : []}
              isDisabled={!data.type}
              required
            />
          </GridItem>
          <GridItem lg={12} sm={12} key="type" xl={12} xs={12} item>
            <ControlledCheckbox
              label="MANAGER_FORM.OVERTIME_LABEL"
              name="overtime"
              control={control}
              placement="top"
            />
          </GridItem>
          <GridItem xs={12} item>
            <ControlledSelect
              label="MANAGER_FORM.MANAGER"
              name="manager"
              control={control}
              options={employeesData}
              required
            />
          </GridItem>
          <GridItem lg={12} sm={12} xl={true} xs={12} item>
            <ContainedButton
              disabled={!formState.isValid}
              fullWidth
              size="large"
              endIcon={<AssignIcon />}
              type="submit"
            >
              {intl.formatMessage({ id: 'MANAGER_FORM.SAVE_BUTTON' })}
            </ContainedButton>
          </GridItem>
        </GridWrapper>
      </form>
    </Box>
  );
}
