import { useIntl } from 'react-intl';
import ControlledSelect from 'components/ControlledSelect';
import { LeaveRequestSelectProps } from '../../types';
import { FormSectionTitle } from '../../HolidaysTab.css';

function LeaveRequestSelect<T>({
  title,
  label,
  name,
  control,
  options,
  handleChange,
}: LeaveRequestSelectProps<T>) {
  const intl = useIntl();
  return (
    <>
      <FormSectionTitle>{intl.formatMessage({ id: title })}</FormSectionTitle>
      <ControlledSelect
        label={label}
        name={name}
        control={control}
        options={options}
        required
        handleChange={handleChange}
      />
    </>
  );
}

export default LeaveRequestSelect;
