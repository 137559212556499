import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PaginationHookObject, PaginationObject } from './types';
import useDebounce from './useDebounce';

interface PaginationProps<T> {
  initialPage?: number;
  initialRowsPerPage?: number;
  initialElementsCount?: number;
  debounceTime: number;
  data?: T;
}

export const usePagination = <T>({
  initialPage,
  initialRowsPerPage,
  initialElementsCount,
  debounceTime = 500,
  data,
}: PaginationProps<T>): PaginationHookObject => {
  const [pagination, setPagination] = useState<PaginationObject>({
    pageNumber: initialPage || 0,
    pageSize: initialRowsPerPage || 10,
    totalElements: initialElementsCount || 10,
    paged: true,
  });

  const setRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPagination((pagination) => ({
      ...pagination,
      pageSize: Number(event.target.value),
      pageNumber: 0,
    }));
  }, []);

  const debouncedPage = useDebounce(pagination.pageNumber, debounceTime);

  const setCurrentPage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      value: number,
    ): void => {
      setPagination((pagination) => ({ ...pagination, pageNumber: value }));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [],
  );

  const resetPage = useCallback((): void => {
    setPagination((pagination) => ({ ...pagination, pageNumber: 0 }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    resetPage();
  }, [data, resetPage]);

  const setPageNumber = useCallback((value: number): void => {
    setPagination((pagination) => ({ ...pagination, pageNumber: value }));
  }, []);

  const setPageSize = useCallback((value: number): void => {
    setPagination((pagination) => ({ ...pagination, pageSize: value }));
  }, []);

  const setElementsCount = useCallback((value: number): void => {
    setPagination((pagination) => ({ ...pagination, totalElements: value }));
  }, []);

  return {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setPageNumber,
    setPageSize,
    setElementsCount,
  };
};
