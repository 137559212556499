import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { nanoid } from 'nanoid';
import BenefitRadioInput from 'components/BenefitInputs/BenefitRadioInput';
import {
  BenefitTypesProps,
  BillabilityTypes,
} from 'components/BenefitInputs/types';
import { ContainedButton } from 'components/Button';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { emptyBenefitTypeData } from '../../../BenefitManagerContext/reducer';
import { ActionKind } from '../../../BenefitManagerContext/types';
import { getBillabilityOptions } from '../inputHelpers';
import { StepProps } from '../types';
import BenefitTypeField from './BenefitTypeField';
import { GridWithMargin } from '../FormSteps.css';

const SecondStep = ({
  control,
  errors,
  getValues,
  setValue,
  reset,
}: StepProps) => {
  const {
    benefitManagerState: { formState },
    dispatch,
  } = useContext(BenefitManagerContext);

  const intl = useIntl();

  const handleAdd = useCallback(() => {
    const newEntry: BenefitTypesProps = {
      ...emptyBenefitTypeData,
      id: nanoid(),
    };
    dispatch({
      type: ActionKind.AddNewType,
      payload: { newEntry, data: getValues().benefitTypes },
    });
  }, [dispatch, getValues]);

  const handleRemove = useCallback(
    (index: number) => {
      dispatch({ type: ActionKind.RemoveType, payload: index });
    },
    [dispatch],
  );

  const handleClear = useCallback(() => {
    if (reset) {
      reset();
    }
    dispatch({ type: ActionKind.ClearType });
  }, [dispatch, reset]);

  return (
    <GridWithMargin container>
      <BenefitRadioInput
        control={control}
        data={getBillabilityOptions(intl)}
        name="billableBy"
        row
        defaultValue={formState.billableBy || BillabilityTypes.package}
      />
      {formState.benefitTypes.map((type, index) => {
        return (
          <BenefitTypeField
            key={type.id}
            index={index}
            handleRemove={handleRemove}
            handleClear={handleClear}
            getValues={getValues}
            setValue={setValue}
            control={control}
            errors={errors}
          />
        );
      })}
      <Grid item xs={12}>
        <ContainedButton
          size="small"
          onClick={handleAdd}
          id="addAnotherTypeButton"
        >
          {intl.formatMessage({ id: 'BENEFITS.FORM.ADD_ANOTHER_TYPE' })}
        </ContainedButton>
      </Grid>
    </GridWithMargin>
  );
};

export default SecondStep;
