import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  LockedIcon,
  TableIconButton,
  Tooltip,
  UnlockedIcon,
} from 'components/Table/Table.css';

interface Props {
  onClick: () => void;
  isSettled: boolean;
  id: string;
  disabled?: boolean;
}

const BlockTimesheet = ({ onClick, isSettled, id, disabled }: Props) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={
        disabled ? (
          ''
        ) : (
          <Typography variant="caption">
            {intl.formatMessage({
              id: isSettled
                ? 'TABLE.BODY.UNBLOCK_USER_TIMESHEET'
                : 'TABLE.BODY.BLOCK_USER_TIMESHEET',
            })}
          </Typography>
        )
      }
      placement="top"
    >
      <TableIconButton onClick={onClick} id={id} disabled={disabled}>
        {isSettled ? <LockedIcon /> : <UnlockedIcon />}
      </TableIconButton>
    </Tooltip>
  );
};

export default BlockTimesheet;
