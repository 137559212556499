import { Grid, GridProps } from '@material-ui/core';
import BoxComponent, { BoxProps } from '@material-ui/core/Box';
import styled from 'styled-components';
import { ContainedButton } from 'components/Button';

export const BoxWrapper = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow: auto;
    min-height: calc(100vh - 200px);
  }
`;

export const GridWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    margin-bottom: 20px;
  }
`;

export const GridWithPointer = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    cursor: pointer;
  }
`;

export const BoxItem = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    padding-bottom: 1rem;
  }
`;

export const AssignButton = styled(ContainedButton)`
  && {
    float: right;
  }
`;

export const SpinnerWrapper = styled.div`
  padding-top: 30vh;
`;
