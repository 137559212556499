import { useIntl } from 'react-intl';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import {
  ACTIVE_REQUESTS_TABLE_HEAD,
  LEAVE_REQUESTS_TABLE_HEAD,
} from '../const';
import { RequestsTableProps } from '../types';
import ActiveRequestsTableCells from './ActiveRequestsTableCells';
import LeaveRequestsTableCells from './LeaveRequestsTableCells';
import LeaveRequestsTableHead from './LeaveRequestsTableHead';

const LeaveRequestsTable = ({
  data,
  isEditable,
  deleteRequest,
}: RequestsTableProps) => {
  const intl = useIntl();
  return (
    <>
      {data.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <LeaveRequestsTableHead titles={LEAVE_REQUESTS_TABLE_HEAD} />
              {isEditable && (
                <LeaveRequestsTableHead titles={ACTIVE_REQUESTS_TABLE_HEAD} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              ({ id, creationDateTime, dateFrom, dateTo, type, status }) => (
                <TableRow key={id}>
                  <LeaveRequestsTableCells
                    creationDateTime={creationDateTime}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    type={type}
                    status={status}
                  />
                  {isEditable && (
                    <ActiveRequestsTableCells
                      requestId={id}
                      deleteRequest={deleteRequest}
                    />
                  )}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        intl.formatMessage({ id: 'LEAVE_REQUEST.NO_REQUEST' })
      )}
    </>
  );
};

export default LeaveRequestsTable;
