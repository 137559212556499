import { useIntl } from 'react-intl';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import {
  HeaderTooltipText,
  TableIconButton,
  Tooltip,
} from 'components/Table/Table.css';

interface Props {
  intlTitle: string;
  id: string;
  disabled?: boolean;
  isActiveWhileDisabled?: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick?: () => void;
}

const TooltipIcon = ({
  intlTitle,
  id,
  disabled,
  isActiveWhileDisabled,
  Icon,
  onClick,
}: Props) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        <HeaderTooltipText>
          {intl.formatMessage({
            id: intlTitle,
          })}
        </HeaderTooltipText>
      }
      placement="top"
    >
      <span>
        <TableIconButton
          id="deleteButton"
          disabled={disabled}
          onClick={onClick}
          $active={isActiveWhileDisabled}
        >
          <Icon id={id} />
        </TableIconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipIcon;
