import { Link } from 'react-router-dom';
import { IconButton, Tabs } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Apps, BugReport, Menu as SimpleMenu } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import palette from 'assets/palette';
import styled from 'styled-components';
import NavLink from 'components/Shared/NavLink';

interface StyledIconButtonProps {
  $token?: boolean;
}

export const HeaderWrapper = styled(Box)<BoxProps>`
  z-index: 1300;
  width: 100%;
  background-color: #000;
  position: fixed;
`;

export const HeaderBox = styled(Box)<BoxProps>`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-end;

  @media (min-width: 1100px) {
    padding-right: 1.5rem;
  }
  @media (max-width: 650px) {
    justify-content: flex-start;
  }

  color: ${palette.ACCENT_WHITE};
`;

export const LinkContainer = styled(Link)`
  padding: 0 1.5rem;
  display: flex;
  text-decoration: none;
  color: ${palette.ACCENT_WHITE};
  height: 100%;
`;
export const UsernameContainer = styled(LinkContainer)`
  order: 6;
`;

export const Header = styled.div`
  padding-left: 2.3rem;
`;
export const Username = styled.div`
  align-self: center;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  &:hover {
    color: ${palette.ACTION};
  }
`;

export const SelectBox = styled(Box)<BoxProps>`
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
`;
export const LogoContainer = styled(LinkContainer)`
  margin-right: auto;
  align-items: center;
  order: 2;
  @media (min-width: 1230px) {
    margin-left: 3rem;
  }
  @media (max-width: 650px) {
    margin: auto;
    order: 3;
  }
  @media (max-width: 350px) {
    padding: 0;
  }
`;

export const LogoImage = styled.img`
  width: 70px;
`;
export const AppTitle = styled.h6`
  font-size: 13px;
  font-weight: 300;
`;

export const TransparentButton = styled.button`
  background: transparent;
  border: none;
  width: 75px;
  height: 75px;
  outline: none;
  cursor: pointer;
`;
export const SelectWrapper = styled.div`
  width: 80px;
  order: 4;
`;

export const ButtonWrapper = styled.div`
  margin: 0 2rem;
  min-width: 150px;
  order: 5;
`;

export const StyledMenu = styled(Menu)<MenuProps>`
  .MuiPopover-paper {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const AccountIcon = styled(AccountCircleIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    width: 36px;
    height: 36px;
    padding: 5px;
    color: ${palette.ACCENT_WHITE};
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARKEST};
    }
  }
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const TextWrapper = styled(ListItemText)`
  &.MuiListItemText-root {
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const MenuIcon = styled(SimpleMenu)<SvgIconProps>`
  &.MuiSvgIcon-root {
    width: 22.5px;
    height: 22.5px;
    color: ${palette.ACCENT_WHITE};
    cursor: pointer;
  }
`;

export const AppsIcon = styled(Apps)<SvgIconProps>`
  &.MuiSvgIcon-root {
    width: 22.5px;
    height: 22.5px;
    color: ${palette.ACCENT_WHITE};
    cursor: pointer;
  }
`;

export const StyledBugReportIcon = styled(BugReport)<SvgIconProps>`
  &.MuiSvgIcon-root {
    width: 22.5px;
    height: 22.5px;
    color: ${palette.ACCENT_WHITE};
    cursor: pointer;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    border-radius: 0;
    height: 45px;
    width: 45px;
    &:hover {
      background-color: ${palette.ACTION};
    }
  }
`;

export const StyledAppsButton = styled(StyledIconButton)<StyledIconButtonProps>`
  visibility: ${({ $token }) => ($token ? 'visible' : 'hidden')};
  order: 1;
`;

export const StyledMenuButton = styled(StyledIconButton)`
  order: 8;
`;

export const StyledBugButton = styled(StyledIconButton)`
  order: 4;
`;

export const StyledAccountButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0;
  }
  order: 7;
  @media (max-width: 650px) {
    order: 2;
  }
`;
export const StyledLink = styled.a`
  text-decoration: none;
`;

export const StyledNavLink = styled(NavLink)`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  margin: 0 0.25rem;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  margin-right: 3.5rem;
`;

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    align-items: center;
    order: 3;
    margin-right: 2rem;
  }
`;
