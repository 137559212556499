import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import moment from 'moment';
import { bankHolidaysUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledDatePicker from 'components/ControlledDatePicker';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledSwitch from 'components/ControlledSwitch';
import ControlledTextField from 'components/ControlledTextField';
import {
  checkDisable,
  checkHiddenSelect,
} from 'components/Dialog/utils/freeDayDialogCheckDisable';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { fetchClientList, fetchEmployeeList } from 'utils/commonFetches';
import { formatDate } from 'utils/dateFormats';
import { postFetch, putFetch } from 'utils/fetchFunctions';
import { ClientsData, FilterOption } from 'utils/helpers/renameKeys';
import {
  CalendarFormProps,
  FetchedAccountProps,
  FetchedClientProps,
  FreeDayType,
} from '../types';
import { isFormInvalidToSubmit } from '../utils/freeDayDialogValidation';
import { FreeDayDialogProps, FreeDayProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const affiliationNames: FreeDayType[] = ['all', 'client', 'account'];

const FreeDayDialog = ({
  addChecked,
  handleAddChanged,
  data,
  setAreDaysModified,
  isEdit = false,
  dataToEdit,
}: FreeDayDialogProps) => {
  const areExclusions = dataToEdit
    ? dataToEdit?.exclusions.excludedClients.length > 0 ||
      dataToEdit?.exclusions.excludedAccounts.length > 0
    : false;

  const getClient = () => {
    switch (true) {
      case Boolean(dataToEdit?.client?.name) && Boolean(dataToEdit?.client?.id):
        return [
          {
            name: dataToEdit?.client?.name,
            value: dataToEdit?.client?.id,
          },
        ];
      case dataToEdit?.type === 'all' && areExclusions:
        return dataToEdit?.exclusions?.excludedClients.map((c) => {
          return { value: c.id, name: c.name };
        });
      default:
        return undefined;
    }
  };

  const getAccount = () => {
    switch (true) {
      case Boolean(
        dataToEdit?.account?.id &&
          dataToEdit?.account?.name &&
          dataToEdit?.account?.surname,
      ):
        return [
          {
            value: dataToEdit?.account?.id,
            name: `${dataToEdit?.account?.name} ${dataToEdit?.account?.surname}`,
            surname: dataToEdit?.account?.surname,
            username: `${dataToEdit?.account?.name.toLowerCase()}.${dataToEdit?.account?.surname?.toLowerCase()}`,
          },
        ];
      case (dataToEdit?.type === 'all' || dataToEdit?.type === 'client') &&
        areExclusions:
        return dataToEdit?.exclusions?.excludedAccounts.map((a) => {
          if (a.id && a.name && a.surname) {
            return {
              value: a.id,
              name: `${a.name} ${a.surname}`,
              surname: a.surname,
              username: `${a.name.toLowerCase()}.${a.surname.toLowerCase()}`,
            };
          }
          return {
            value: 0,
            name: '',
            surname: '',
            username: '',
          };
        });
      default:
        return undefined;
    }
  };

  const { control, getValues, setValue, handleSubmit, reset, watch } =
    useForm<CalendarFormProps>({
      mode: 'onChange',
      defaultValues: {
        title: dataToEdit?.title || '',
        description: dataToEdit?.description || '',
        client: getClient(),
        account: getAccount(),
        exclude: areExclusions,
      },
    });

  const form = watch();

  useEffect(() => {
    if (isEdit) {
      if (dataToEdit?.applyToAllEmployees) {
        setFreeDayType('all');
      }
      if (dataToEdit?.type === 'account') {
        setFreeDayType('account');
      }
    }
  }, [dataToEdit?.applyToAllEmployees, dataToEdit?.type, isEdit]);

  const intl = useIntl();
  const [employeeData, setEmployeeData] = useState<FilterOption[]>([
    { value: 0, name: '' },
  ]);
  const [clientData, setClientData] = useState<ClientsData[]>([
    { value: 0, clientName: '', decription: '' },
  ]);
  const [freeDayType, setFreeDayType] = useState<FreeDayType>('client');

  const areAllAffiliationsEmpty = () =>
    affiliationNames.every((name) => !getValues(name));

  const handleDayTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFreeDayType(e.target.value as FreeDayType);
    setValue('exclude', false);
    affiliationNames.forEach((name) => setValue(name, undefined));
  };

  useEffect(() => {
    const retrieveData = async () => {
      Promise.all([
        fetchEmployeeList(setEmployeeData, ContractTypes.EMPLOYMENT_CONTRACT),
        fetchClientList(setClientData),
      ]);
    };
    retrieveData();
  }, []);
  const excludedInputValue = useWatch({
    control,
    name: 'exclude',
    defaultValue: false,
  });

  const setFormValuesToUndefined = () =>
    affiliationNames.forEach((name) => {
      if (name !== freeDayType) {
        setValue(name, undefined);
      }
    });

  const { holidayDate } = useWatch({ control });

  const renderExcludeButton = (typeOfDay: FreeDayType) => {
    if (typeOfDay === freeDayType) {
      return (
        <GridItem
          xs={12}
          item
          style={{ width: '20vw' }}
          onChange={setFormValuesToUndefined}
        >
          <ControlledSwitch
            control={control}
            label={intl.formatMessage({
              id: `ADMIN_PANEL.HEADER.EXCLUDE`,
            })}
            name="exclude"
            size="medium"
            labelPlacement="start"
          />
        </GridItem>
      );
    } else {
      return <></>;
    }
  };

  const onSubmit = handleSubmit(async (formData: CalendarFormProps) => {
    const { title, description } = formData;

    const formattedDate = data
      ? formatDate(data, 'YYYY-MM-DD')
      : formatDate(holidayDate, 'YYYY-MM-DD');

    const getFormattedClientData = (data: FetchedClientProps[]) =>
      data.map((prop) => ({ id: prop.value, name: prop.name }));

    const getFormattedAccountData = (data: FetchedAccountProps[]) =>
      data.map((prop) => ({ id: prop.value, username: prop.username }));

    const body: FreeDayProps = {
      title,
      description,
      date: `${formattedDate}`,
      type: freeDayType,
      applyToAllEmployees: false,
      exclusions: {
        excludedClients: [],
        excludedAccounts: [],
      },
    };
    if (!excludedInputValue) {
      if (freeDayType === 'all') {
        body.applyToAllEmployees = true;
      }
      if (formData.client) {
        body.clients = getFormattedClientData(formData.client);
      }
      if (formData.account) {
        body.accounts = getFormattedAccountData(formData.account);
      }
    }
    if (excludedInputValue) {
      if (freeDayType === 'all') {
        body.applyToAllEmployees = true;
        if (formData.client) {
          body.exclusions.excludedClients = getFormattedClientData(
            formData.client,
          );
        }
        if (formData.account) {
          body.exclusions.excludedAccounts = getFormattedAccountData(
            formData.account,
          );
        }
      }
      if (freeDayType === 'client') {
        if (formData.client) {
          body.clients = getFormattedClientData(formData.client);
        }
        if (formData.account) {
          body.exclusions.excludedAccounts = getFormattedAccountData(
            formData.account,
          );
        }
      }
    }

    handleAddChanged();
    const { ok } = isEdit
      ? await putFetch({
          url: `${bankHolidaysUrl}/${dataToEdit?.id}`,
          body,
          intl,
          label: 'ADMIN_PANEL.CALENDAR.EDIT',
        })
      : await postFetch({
          url: bankHolidaysUrl,
          body,
          intl,
          label: 'ADMIN_PANEL.CALENDAR.ADD',
        });
    if (ok) {
      setAreDaysModified((state) => !state);
    }

    reset({
      title: '',
      description: '',
      exclude: false,
    });
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleAddChanged}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: isEdit
                ? `FREE_DAY.EDIT_DIALOG_TITLE`
                : `FREE_DAY.DIALOG_TITLE`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton aria-label="close" onClick={handleAddChanged}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label={'FREE_DAY.TITLE'}
                name="title"
                control={control}
                required
                variant="outlined"
              />
            </GridItem>

            <GridItem xs={12} item>
              <ControlledTextField
                label="FREE_DAY.DESCRIPTION"
                name="description"
                control={control}
                isMulti
                variant="outlined"
              />
            </GridItem>
            {isEdit && (
              <GridItem xs={12} item>
                <ControlledDatePicker
                  name="holidayDate"
                  control={control}
                  view="date"
                  label={intl.formatMessage({
                    id: 'BANK_HOLIDAY.HOLIDAY_DATE',
                  })}
                  defaultValue={
                    dataToEdit?.date
                      ? moment(dataToEdit?.date).toDate()
                      : undefined
                  }
                />
              </GridItem>
            )}

            <GridItem xs={12} item>
              <FormControl style={{ marginLeft: '.8rem' }} component="fieldset">
                <FormLabel component="legend">
                  {intl.formatMessage({
                    id: `FREE_DAY.TYPE`,
                  })}
                </FormLabel>
                <RadioGroup
                  value={freeDayType}
                  onChange={handleDayTypeChange}
                  aria-label="free day type"
                  name="daytype"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: 'ADMIN_PANEL.HEADER.HOLIDAY_FOR_ALL',
                    })}
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: `ADMIN_PANEL.HEADER.CLIENT`,
                    })}
                  />
                  <FormControlLabel
                    value="account"
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: `ADMIN_PANEL.HEADER.EMPLOYEE`,
                    })}
                  />
                  {renderExcludeButton('all')}
                </RadioGroup>
              </FormControl>
            </GridItem>

            {!checkHiddenSelect(form.exclude, 'client', freeDayType) && (
              <>
                <GridItem xs={12} item>
                  <ControllerSelect
                    label="TABLE.HEAD.CLIENT"
                    name="client"
                    control={control}
                    options={clientData}
                    isMulti
                    isHidden={checkHiddenSelect(
                      form.exclude,
                      'client',
                      freeDayType,
                    )}
                    isDisabled={checkDisable(
                      form.exclude,
                      freeDayType,
                      'client',
                    )}
                  />
                  {renderExcludeButton('client')}
                </GridItem>
              </>
            )}
            {!checkHiddenSelect(form.exclude, 'account', freeDayType) && (
              <>
                <GridItem xs={12} item>
                  <ControllerSelect
                    label="MANAGER_FORM.EMPLOYEE"
                    name="account"
                    control={control}
                    options={employeeData}
                    isMulti
                    isHidden={checkHiddenSelect(
                      form.exclude,
                      'account',
                      freeDayType,
                    )}
                    isDisabled={checkDisable(
                      form.exclude,
                      freeDayType,
                      'account',
                    )}
                  />
                </GridItem>
              </>
            )}
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            disabled={isFormInvalidToSubmit({
              form,
              affiliationNames,
              freeDayType,
              areAllAffiliationsEmpty,
              excludedInputValue,
            })}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `CUSTOM_FIELDS.DIALOG_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default FreeDayDialog;
