import { IntlShape } from 'react-intl';
import moment from 'moment';
import {
  BusinessTrip,
  CalculatorTransportationDataType,
  SelectDataForm,
  SummaryObject,
  TripCalculatorFormValues,
  TripCalculatorSummary,
} from 'modules/BusinessTrips/types';
import { dateFormatFrontend, dateTimeFormatFrontend } from 'utils/dateFormats';
import { mapISODurationToDaysHoursMinutes } from 'utils/durationFunctions';
import { CALCULATOR_TRANSPORTATION_OPTIONS } from './constants';

export const renderSelectOptions = <T>(
  options: SelectDataForm<T>[],
  intl: IntlShape,
) =>
  options.map(({ value, name }) => ({
    value,
    name: intl.formatMessage({ id: name }),
  }));

export const getTransportationTypesInputName = (
  name: keyof CalculatorTransportationDataType,
  index?: number,
) => {
  return `transportation[${index}].${name}` as keyof TripCalculatorFormValues;
};

export const renderErrorMessageForRequiredFields = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'VALIDATION.REQUIRED',
  });

export const getFormattedPostData = (
  formState?: TripCalculatorFormValues,
  calculatorInitData?: TripCalculatorFormValues,
) => {
  if (calculatorInitData === undefined) {
    calculatorInitData = formState;
  }

  return {
    foreignBusinessTrip:
      formState?.foreignBusinessTrip ??
      calculatorInitData?.foreignBusinessTrip ??
      formState?.countryDietSelection?.currency !== 'PLN',
    actualStartTime: formState?.actualStartTime,
    actualEndTime: formState?.actualEndTime,
    countryDietId: calculatorInitData?.countryDietPresent
      ? calculatorInitData?.countryDietId
      : formState?.countryDietSelection?.value,
    accommodation: formState?.accomodationCosts
      ? [
          {
            price: formState?.accomodationCosts,
            currency: formState?.accomodationCurrency?.name,
            id: calculatorInitData?.accomodationId || undefined,
          },
        ]
      : undefined,
    transportation: formState?.transportation
      ?.filter((transport) => transport.transportationType)
      .map((transport) => ({
        id: transport?.transportationId || undefined,
        price: transport?.transportationCosts,
        currency: transport?.transportationCurrency?.name,
        type: transport?.transportationType?.value,
      })),
    otherExpenses: formState?.otherExpensesCosts
      ? [
          {
            id: calculatorInitData?.otherExpensesId || undefined,
            price: formState?.otherExpensesCosts,
            currency: formState?.otherExpensesCurrency?.name,
            message: formState?.otherExpensesComment,
          },
        ]
      : undefined,
    numberOfBreakfasts: formState?.numberOfBreakfasts ?? 0,
    numberOfDinners: formState?.numberOfDinners ?? 0,
    numberOfSuppers: formState?.numberOfSuppers ?? 0,
    exchangeRateDate: formState?.exchangeRateDate,
  };
};

const getCurrencySelection = (
  currencies: SelectDataForm<number>[],
  targetCurrency?: string,
) => currencies.find((currency) => currency.name === targetCurrency);

const getTransportationSelection = (
  intl: IntlShape,
  targetTransportation?: string,
) => {
  const targetTransportationOption = CALCULATOR_TRANSPORTATION_OPTIONS.filter(
    (option) => option.value === targetTransportation,
  );

  return renderSelectOptions(targetTransportationOption, intl)[0];
};

const getTransportationFields = (
  currencies: SelectDataForm<number>[],
  intl: IntlShape,
  transportations?: SummaryObject[],
) =>
  transportations?.map((transportation) => ({
    transportationId: transportation?.id,
    transportationType:
      getTransportationSelection(intl, transportation?.type) ?? null,
    transportationCosts: transportation?.price,
    transportationCurrency:
      getCurrencySelection(currencies, transportation?.currency) ?? null,
  }));

export const getSummaryFormattedToFormData = (
  summary: TripCalculatorSummary,
  data: BusinessTrip,
  currencies: SelectDataForm<number>[],
  intl: IntlShape,
) => {
  const otherExpenses = summary?.otherExpensesSummaries?.[0];
  const accomodation = summary?.accommodationSummaries?.[0];
  const transportations = getTransportationFields(
    currencies,
    intl,
    summary?.transportationSummaries,
  );

  return {
    id: data?.id,
    foreignBusinessTrip: data?.foreignBusinessTrip,
    country: data?.country,
    city: data?.city,
    currency: data?.currency,
    countryDietId: data?.countryDiet?.id,
    countryDietValue: data?.countryDiet?.diet,
    countryDietCurrency: data?.countryDiet?.currency,
    actualStartTime: summary?.durationStart,
    actualEndTime: summary?.durationFinish,
    numberOfBreakfasts: summary?.numberOfBreakfasts,
    numberOfSuppers: summary?.numberOfSuppers,
    numberOfDinners: summary?.numberOfDinners,
    transportation: transportations ?? [
      {
        transportationType: null,
        transportationCosts: null,
        transportationCurrency: null,
      },
    ],
    accomodationId: accomodation?.id,
    accomodationCosts: accomodation?.price,
    accomodationCurrency: getCurrencySelection(
      currencies,
      accomodation?.currency,
    ),
    otherExpensesId: otherExpenses?.id,
    otherExpensesCosts: otherExpenses?.price,
    otherExpensesCurrency: getCurrencySelection(
      currencies,
      otherExpenses?.currency,
    ),
    otherExpensesComment: otherExpenses?.message,
  };
};

export const renderTripCalculatorDescription = (
  summary?: TripCalculatorSummary,
  calculatorFormValues?: TripCalculatorFormValues,
) => [
  {
    name: 'BUSINESS_TRIP.ACCORDION.DESTINATION',
    content: calculatorFormValues?.country,
  },
  {
    name: 'QUICK_REPORT.START_DATE',
    content: moment(summary?.durationStart).format(dateTimeFormatFrontend),
  },
  {
    name: 'QUICK_REPORT.END_DATE',
    content: moment(summary?.durationFinish).format(dateTimeFormatFrontend),
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_DURATION',
    content: mapISODurationToDaysHoursMinutes(summary?.tripDuration),
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_CURRENCY_EXCHANGE_DATE',
    content: summary?.rate
      ? moment(summary?.rate?.date).format(dateFormatFrontend)
      : undefined,
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_CURRENCY_EXCHANGE_RATE',
    content: summary?.rate
      ? `1 ${summary?.rate?.sourceCurrency} = ${summary?.rate?.rate} ${summary?.rate?.targetCurrency}`
      : undefined,
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_BREAKFASTS',
    content: summary?.numberOfBreakfasts,
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_DINNERS',
    content: summary?.numberOfDinners,
  },
  {
    name: 'BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_SUPPERS',
    content: summary?.numberOfSuppers,
  },
];

const renderTransportation = (
  transportationSummaries?: SummaryObject[],
  currency?: string,
) =>
  transportationSummaries
    ? transportationSummaries
        .filter(
          (transportationSummary) =>
            transportationSummary.currency === currency,
        )
        .map((transportationSummary) => ({
          name: `BUSINESS_TRIP.CALCULATOR_TRANSPORTATION_${transportationSummary?.type}`,
          content: `${transportationSummary?.price} ${currency}`,
        }))
    : [];

const getSummaryByCurrency = (
  summaries?: SummaryObject[],
  currency?: string,
) => {
  return summaries?.find((summary) => summary.currency === currency);
};

export const renderTripCalculatorCosts = (summary?: TripCalculatorSummary) => {
  const targetCurrency = summary?.rate?.targetCurrency ?? 'PLN';

  const dietSummary = getSummaryByCurrency(
    summary?.dietSummaries,
    targetCurrency,
  );

  const accomodationSummary = getSummaryByCurrency(
    summary?.accommodationSummaries,
    targetCurrency,
  );

  const otherExpensesSummary = getSummaryByCurrency(
    summary?.otherExpensesSummaries,
    targetCurrency,
  );

  return [
    {
      name: 'BUSINESS_TRIP.DIET',
      content: summary?.dietSummaries.length
        ? `${dietSummary?.price} ${dietSummary?.currency}`
        : undefined,
    },
    {
      name: 'BUSINESS_TRIP.CALCULATOR_ACCOMODATION',
      content: summary?.accommodationSummaries.length
        ? `${accomodationSummary?.price} ${accomodationSummary?.currency}`
        : undefined,
    },
    ...renderTransportation(summary?.transportationSummaries, targetCurrency),
    {
      name: 'BUSINESS_TRIP.CALCULATOR_OTHER_EXPENSES',
      content: summary?.otherExpensesSummaries.length
        ? `${otherExpensesSummary?.price} ${otherExpensesSummary?.currency}`
        : undefined,
    },
    {
      name: 'BUSINESS_TRIP.CALCULATOR_OTHER_EXPENSES_COMMENT',
      content: summary?.otherExpensesSummaries.length
        ? `${otherExpensesSummary?.message}`
        : undefined,
    },
  ];
};

export const renderTripCalculatorCostsForeignCurrency = (
  summary?: TripCalculatorSummary,
) => {
  const sourceCurrency = summary?.rate?.sourceCurrency;

  const dietSummary = getSummaryByCurrency(
    summary?.dietSummaries,
    sourceCurrency,
  );

  const accomodationSummary = getSummaryByCurrency(
    summary?.accommodationSummaries,
    sourceCurrency,
  );

  const otherExpensesSummary = getSummaryByCurrency(
    summary?.otherExpensesSummaries,
    sourceCurrency,
  );
  return [
    {
      name: 'BUSINESS_TRIP.DIET',
      content: dietSummary
        ? `${dietSummary?.price} ${dietSummary?.currency}`
        : undefined,
    },
    {
      name: 'BUSINESS_TRIP.CALCULATOR_ACCOMODATION',
      content: accomodationSummary
        ? `${accomodationSummary?.price} ${accomodationSummary?.currency}`
        : undefined,
    },
    ...renderTransportation(summary?.transportationSummaries, sourceCurrency),
    {
      name: 'BUSINESS_TRIP.CALCULATOR_OTHER_EXPENSES',
      content: otherExpensesSummary
        ? `${otherExpensesSummary?.price} ${otherExpensesSummary?.currency}`
        : undefined,
    },
  ];
};

export const renderTripCalculatorTotal = (summary?: TripCalculatorSummary) => [
  {
    name: 'BUSINESS_TRIP.CALCULATOR_TOTAL',
    content: `${summary?.plnSummary} PLN`,
  },
];

export const getCalculatorInitData = (data: BusinessTrip) => ({
  id: data?.id,
  foreignBusinessTrip: data?.foreignBusinessTrip,
  country: data?.country,
  city: data?.city,
  currency: data?.currency,
  actualStartTime: data?.startTime,
  actualEndTime: data?.endTime,
  countryDietId: data?.countryDiet?.id,
  countryDietValue: data?.countryDiet?.diet,
  countryDietCurrency: data?.countryDiet?.currency,
  accomodationCosts: undefined,
  accomodationCurrency: undefined,
  otherExpensesCosts: undefined,
  otherExpensesCurrency: undefined,
  otherExpensesComment: undefined,
  transportation: [
    {
      transportationType: null,
      transportationCosts: null,
      transportationCurrency: null,
    },
  ],
  numberOfBreakfasts: 0,
  numberOfDinners: 0,
  numberOfSuppers: 0,
  countryDietPresent: !!data?.countryDiet,
});
