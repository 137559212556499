import { useIntl } from 'react-intl';
import { validate } from 'utils/formValidation';
import { ControlledProps } from '../types';
import SelectField from './SelectField';
import { GridWithMargin } from '../../../BenefitsManager.css';

const ThirdStep = ({ control, errors }: ControlledProps) => {
  const intl = useIntl();

  return (
    <GridWithMargin container>
      <SelectField
        name="employeeInputs"
        control={control}
        errors={errors}
        rules={validate(intl).multiSelect}
      />
      <SelectField name="partnerInputs" control={control} errors={errors} />
      <SelectField name="childInputs" control={control} errors={errors} />
      <SelectField
        name="editableInputs"
        control={control}
        errors={errors}
        rules={validate(intl).multiSelect}
      />
    </GridWithMargin>
  );
};

export default ThirdStep;
