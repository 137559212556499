import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { SendIcon, TableIconButton, Tooltip } from 'components/Table/Table.css';

interface Props {
  isDisabled?: boolean;
  id: string;
  onClick: () => void;
}

const Message = ({ isDisabled, id, onClick }: Props) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        isDisabled ? (
          ''
        ) : (
          <Typography variant="caption">
            {intl.formatMessage({
              id: isDisabled
                ? 'TIMESHEETS.MESSAGE_BUTTON_DISABLED'
                : 'TABLE.BODY.MESSAGE',
            })}
          </Typography>
        )
      }
      placement="top"
    >
      <span>
        <TableIconButton onClick={onClick} disabled={isDisabled} id={id}>
          <SendIcon />
        </TableIconButton>
      </span>
    </Tooltip>
  );
};

export default Message;
