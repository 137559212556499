import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { BusinessTripsContext } from 'modules/BusinessTrips/BusinessTripManagerContext/Context';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { validateBusinessTrips } from '../../utils';
import { HELPER_TEXT_MARGIN, RESERVATION_MADE_BY } from '../constants';
import { AccommodationProps, ReservationProviderTypes } from '../types';
import {
  checkNumberOfNights,
  renderErrorMessageForRequiredFields,
  renderSelectOptions,
} from '../utils';
import { TripSectionContainer } from '../BusinessTripDialog.css';

const Accommodation = ({
  control,
  errors,
  editClicked,
  currenciesData,
  defaultReservationBy,
}: AccommodationProps) => {
  const {
    businessTripsManagerState: {
      formState: {
        startTime,
        endTime,
        numberOfNights,
        placeToStay,
        estimateCostsOfStay,
        currency,
        accommodationReservationBy,
        accommodationReservationLink,
        accommodationInformation,
      },
    },
  } = useContext(BusinessTripsContext);

  const intl = useIntl();

  const calculatedNumberOfNights = checkNumberOfNights(
    startTime as Date,
    endTime as Date,
  );

  const currentEstimateCostsOfStay = useWatch({
    control,
    name: 'estimateCostsOfStay',
    defaultValue: estimateCostsOfStay,
  });

  const currentNumberOfNights = useWatch({
    control,
    name: 'numberOfNights',
    defaultValue: numberOfNights,
  });

  const isAccommodation = Number(currentNumberOfNights) > 0;

  return (
    <TripSectionContainer container spacing={3}>
      <Grid xs={12} item>
        <ControlledTextField
          labelTranslated={`${intl.formatMessage({
            id: 'BUSINESS_TRIP.NIGHTS_COUNT_MAX',
          })} (max. ${calculatedNumberOfNights})`}
          name="numberOfNights"
          control={control}
          variant="outlined"
          rules={validateBusinessTrips(intl, calculatedNumberOfNights).maxValue}
          isOnlyInteger
          maxLength={3}
          placeholder={`${intl.formatMessage({
            id: 'BUSINESS_TRIP.HINT',
          })}: ${String(calculatedNumberOfNights)}`}
          defaultValue={numberOfNights}
          validate={!!errors?.numberOfNights}
          helperText={errors?.numberOfNights?.message}
        />
      </Grid>
      {isAccommodation && (
        <>
          <Grid xs={12} item>
            <ControlledTextField
              label="BUSINESS_TRIP.FORM.PLACE_TO_STAY"
              name="placeToStay"
              control={control}
              variant="outlined"
              required
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={placeToStay}
              validate={!!errors?.placeToStay}
              helperText={
                errors?.placeToStay && renderErrorMessageForRequiredFields(intl)
              }
            />
          </Grid>
          <Grid
            container
            xs={12}
            justify="space-evenly"
            style={{ margin: '1rem 1rem 1rem 0' }}
            spacing={1}
          >
            <Grid xs={8}>
              <ControlledTextField
                label="BUSINESS_TRIP.ESTIMATED_COST"
                name="estimateCostsOfStay"
                control={control}
                variant="outlined"
                isOnlyInteger
                isDisabled={editClicked}
                maxLength={5}
                defaultValue={estimateCostsOfStay}
              />
            </Grid>
            {Number(currentEstimateCostsOfStay) > 0 && (
              <Grid xs={3}>
                <ControllerSelect
                  label="BUSINESS_TRIP.FORM.CURRENCIES"
                  name="currency"
                  control={control}
                  options={currenciesData}
                  required
                  isPadding={false}
                  defaultValue={currency}
                  isDisabled={editClicked}
                  errorMessage={
                    errors?.currency &&
                    renderErrorMessageForRequiredFields(intl)
                  }
                  errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={2}>
            <Grid xs={12} item>
              <ControllerSelect
                label="BUSINESS_TRIP.FORM.RESERVATION_BY"
                name="accommodationReservationBy"
                control={control}
                options={renderSelectOptions<ReservationProviderTypes>(
                  RESERVATION_MADE_BY,
                  intl,
                )}
                helperText={editClicked}
                required
                isPadding={false}
                defaultValue={
                  accommodationReservationBy || defaultReservationBy
                }
                errorMessage={
                  errors?.accommodationReservationBy &&
                  renderErrorMessageForRequiredFields(intl)
                }
                errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
              />
            </Grid>
            <Grid xs={12} item>
              <ControlledTextField
                label="BUSINESS_TRIP.RESERVATION_LINK"
                name="accommodationReservationLink"
                control={control}
                variant="outlined"
                type="url"
                maxLength={MAX_INPUT_LENGTH}
                defaultValue={accommodationReservationLink}
              />
            </Grid>
            <Grid xs={12} item>
              <ControlledTextField
                label="BUSINESS_TRIP.ACCOMMODATION_RESERVATION_INFORMATION"
                name="accommodationInformation"
                control={control}
                variant="outlined"
                rows={8}
                isMulti
                maxLength={MAX_INPUT_LENGTH}
                defaultValue={accommodationInformation}
              />
            </Grid>
          </Grid>
        </>
      )}
    </TripSectionContainer>
  );
};

export default Accommodation;
