import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import palette from 'assets/palette';
import styled from 'styled-components';

export const FormControlComponent = styled(FormControl)<FormControlProps>`
  &.MuiFormControl-root {
    width: 100%;
  }
  margin: 8px;
  min-width: 120;
  max-width: 300;
`;

export const TextFieldComponent = styled(TextField)<TextFieldProps>`
  &.MuiInputBase-root {
  }
  &.MuiChip-root {
    margin: 2px;
    background-color: ${palette.ACTION};
  }
`;

export const MultipleFilterComponent = styled(Autocomplete)<
  AutocompleteProps<string, true, true, true>
>`
  padding-top: 5px;
  &.MuiAutocomplete-root
    > .MuiFormControl-root
    > .MuiInputBase-root
    > .MuiChip-root {
    margin: 2px;
    background-color: ${palette.ACTION};
  }
  &.MuiAutocomplete-root
    > .MuiFormControl-root
    > .MuiFormLabel-root.Mui-focused {
    color: #000000;
  }
`;
