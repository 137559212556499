import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { OnChangeValue } from 'react-select';
import { TextField, Typography } from '@material-ui/core';
import { Block } from '@material-ui/icons';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment';
import {
  accountFilledInfoUrl,
  benefitCostAcceptanceUrl,
  timesheetCreationUrl,
  timesheetCustomFieldSufixUrl,
  timesheetsUrl,
  userProfileUrl,
  workTimeApproveUrl,
  workTimeSummaryUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import { GridItem, GridWrapper } from 'components/Dialog/Dialog.css';
import FilesUploadDialog from 'components/Dialog/FilesUploadDialog/FilesUploadDialog';
import MissingUserProfileInfoDialog from 'components/Dialog/MissingInfoDialog';
import {
  MissingInfoType,
  MissingUserProfileInfo,
} from 'components/Dialog/MissingInfoDialog/types';
import { TableHead } from 'components/Table/types';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import ToolTipCell from 'components/ToolTipCell/ToolTipCell';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { AccountResponse } from 'modules/UserProfile/types';
import { SUMMARY_LABELS } from 'modules/WorkTime/static_data';
import { TimesheetData } from 'modules/WorkTime/types';
import { getFetch, patchFetch, postFetch } from 'utils/fetchFunctions';
import { returnLeaveDays } from 'utils/helpers';
import { returnBankHolidays } from 'utils/helpers/';
import { returnReportedWeekendsHolidays } from 'utils/helpers/returnReportedWeekendsHolidays';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import { UserContext } from '../../globalContext/UserContext';
import { formatMonthsToTwoDigits } from '../../utils/dateFormats';
import Datepicker from '../Datepicker';
import ReactSelect from '../ReactSelect/ReactSelect';
import CustomFieldsTable from './CustomFieldsTable';
import { getSendReportToastMessage } from './tableUtils';
import {
  ClientCustomFields,
  CustomDailyTime,
  CustomField,
  Summary,
  SummaryStatus,
  TableSummaryProps as TableProps,
  ValueOf,
} from './types';
import {
  BoldedTableCell,
  DatePickerWrapper,
  Paper,
  SelectContainer,
  SendButtonWrapper,
  SendIcon,
  StyledContainedButton,
  StyledErrorBox,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
  Tooltip,
} from './Table.css';

const WORK_TIME_SUMMARY_HEAD_DATA: TableHead[] = [
  {
    label: 'WORK_TIME_TAB.SUMMARY',
    key: 'summary',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '40%',
  },
  {
    label: 'WORK_TIME_TAB.APPROVE_AND_SEND',
    key: 'submit',
    align: 'right',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '60%',
  },
];

const SummaryTable = ({
  loading = false,
  timesheetUsername,
  timesheetData,
  date,
  setDate,
  setTimesheetUsername,
  selectedUser,
  setSelectedUser,
  setIsFormedChanged,
  myTimesheetLoaded,
  isUserAuthorized,
  setApprovedAndSendLoading,
}: TableProps) => {
  const intl = useIntl();
  const [openFileUploadArea, handleFileUploadClick] = useToggleState(false);
  const [customFieldsData, setCustomFieldsData] =
    useState<ClientCustomFields[]>();
  const [missingInfo, setMissingInfo] = useState<MissingUserProfileInfo>();
  const [isMissingContract, setIsMissingContract] = useState(false);
  const [summaryTableData, setSummaryTableData] = useState<Summary>(Object);
  const [isCustomFormValid, setIsCustomFormValid] = useState(false);
  const [didFileChanged, setDidFileChanged] = useState(false);
  const [didAdditionalInfoChanged, setDidAdditionalInfoChanged] =
    useState(false);
  const [userContract, setUserContract] = useState('');
  const [ownTimesheedId, setOwnTimesheetId] = useState<number>();
  const { username } = useContext(UserContext);
  const [canFetchOrCreate, setCanFetchOrCreate] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [reportSubmitting, setReportSubmitting] = useState(false);
  const [disabledApproveClicked, setDisabledApproveClicked] = useState(false);
  const [summaryStatus, setSummaryStatus] = useState<SummaryStatus>();
  const [isOpenNewBenefitCostDialog, setIsOpenNewBenefitCostDialog] =
    useState(false);

  const [leaders, setLeaders] = useState<string[]>([]);

  const getAccountFilledInfo = useCallback(async () => {
    const response: MissingUserProfileInfo = await getFetch({
      url: accountFilledInfoUrl,
    });
    setMissingInfo(response);
    if (response && !response.contractTypeDefined) {
      setIsMissingContract(true);
    }
  }, []);

  useEffect(() => {
    getAccountFilledInfo();
  }, [getAccountFilledInfo]);

  const isUserSettled = !!summaryTableData?.settled;

  useEffect(() => {
    setIsFormedChanged(false);
  }, [setIsFormedChanged]);

  useEffect(() => {
    setApprovedAndSendLoading(reportSubmitting);
  }, [reportSubmitting, setApprovedAndSendLoading]);

  const onAdditionalInfoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSummaryTableData({
        ...summaryTableData,
        additionalInformation: event.target.value,
      });
    },
    [setSummaryTableData, summaryTableData],
  );
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    summaryTableData?.additionalInformation
      ? setDidAdditionalInfoChanged(true)
      : setDidAdditionalInfoChanged(false);
  }, [summaryTableData?.additionalInformation]);

  const createTimesheet = useCallback(async () => {
    const body = {
      month: date!.getMonth() + 1,
      year: date!.getFullYear(),
    };
    await postFetch({
      url: timesheetCreationUrl,
      body,
      showErrorToast: false,
    });
  }, [date]);

  const fetchReport = useCallback(
    async (first = false) => {
      try {
        setSummaryLoading(true);
        const summaryResponse = await getFetch({
          showErrorToast: false,
          url:
            `${workTimeSummaryUrl}/${selectedUser?.username || username}` +
            `?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
              date!.getMonth() + 1,
            )}`,
        });
        const summaryRenamedData = {
          totalReportedDays: summaryResponse.workDurationInManDays,
          totalReportedHours: summaryResponse.workDurationInHours,
          monthWorkdays: summaryResponse.workDaysInMonth,
          remoteWork: summaryResponse.remoteWorkDaysCount,
          unreportedDays: summaryResponse.unreportedDays,
          medicalLeave: returnLeaveDays(summaryResponse.medicalLeaves),
          vacations: returnLeaveDays(summaryResponse.vacations),
          daysOff: returnLeaveDays(summaryResponse.daysOff),
          bankHolidays: returnBankHolidays(summaryResponse.bankHolidayDTOS),
          reportedWeekendsHolidays: returnReportedWeekendsHolidays(
            summaryResponse.workTimeInHolidaysOrWeekends,
          ),
          customDailyTimes: summaryResponse.customDailyTimes,
          benefitsCostSummary: summaryResponse.benefitsCostSummary,
          status: summaryResponse.status,
          mailStatus: summaryResponse.mailStatus,
          attachedFiles: [],
          additionalInformation: summaryResponse.addInfo,
          settled: summaryResponse.settled,
          wasNewBenefitCostAccepted:
            summaryResponse.userAcceptedNewBenefitsCostSummary,
        };
        setSummaryStatus(summaryResponse.status);
        if (!summaryRenamedData.wasNewBenefitCostAccepted) {
          setIsOpenNewBenefitCostDialog(true);
        }
        setLeaders(
          summaryResponse.leaders.map(({ fullName }: any) => fullName),
        );
        setFiles([]);
        setOwnTimesheetId(summaryResponse.timesheetId);
        setSummaryTableData(summaryRenamedData);
        setCanFetchOrCreate(true);
      } catch (e) {
        console.error(e);
        if (first && !timesheetUsername) {
          await createTimesheet();
          await fetchReport();
        } else {
          setCanFetchOrCreate(false);
        }
      } finally {
        setSummaryLoading(false);
      }
    },
    [
      selectedUser?.username,
      username,
      date,
      timesheetUsername,
      createTimesheet,
    ],
  );

  useEffect(() => {
    fetchReport(true);
  }, [date, fetchReport, customFieldsData]);

  const fetchCustomFields = useCallback(async () => {
    const customFieldsResponse = await getFetch({
      showErrorToast: false,
      url:
        `${timesheetsUrl}/${
          selectedUser?.username || username
        }/${timesheetCustomFieldSufixUrl}` +
        `?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
          date!.getMonth() + 1,
        )}`,
    });
    setCustomFieldsData(customFieldsResponse);
  }, [selectedUser, username, date]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  const validateCustomFields = useCallback(() => {
    const validateSingle = (obj: CustomField) => obj.value || obj.defaultValue;
    const isValid =
      customFieldsData?.every(
        (client) =>
          client.fields.every(validateSingle) &&
          client.projectsWithFields.every((project) =>
            project.fields.every(validateSingle),
          ),
      ) ?? true;

    setIsCustomFormValid(isValid || false);
  }, [customFieldsData]);

  useEffect(() => {
    validateCustomFields();
  }, [validateCustomFields]);

  const onApproveAndSend = useCallback(async () => {
    let formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('month', (date!.getMonth() + 1).toString());
    formData.append('year', date!.getFullYear().toString());
    formData.append('addInfo', summaryTableData.additionalInformation);

    await postFetch({
      setLoading: setReportSubmitting,
      url: `${workTimeApproveUrl}/v2/${username}`,
      body: formData,
      intl,
      label: getSendReportToastMessage(summaryStatus),
      contentType: 'formdata',
    });

    setSummaryTableData({
      ...summaryTableData,
      additionalInformation: '',
    });
    setDidFileChanged(false);
    setDidAdditionalInfoChanged(false);
    await fetchReport();
  }, [
    username,
    date,
    files,
    intl,
    setSummaryTableData,
    summaryTableData,
    fetchReport,
    summaryStatus,
  ]);

  const handleAccept = useCallback(async () => {
    await patchFetch({
      url: benefitCostAcceptanceUrl,
      intl,
      label: 'ACCEPT_NEW_BENEFIT_COST',
    });
  }, [intl]);

  const renderCell = (key: string, value: ValueOf<Summary>) => {
    switch (key) {
      case 'additionalInformation':
        return (
          <TextField
            id="additionalInformation"
            disabled={!myTimesheetLoaded}
            multiline
            fullWidth
            value={summaryTableData.additionalInformation}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onAdditionalInfoChange(event)
            }
          />
        );
      case 'customDailyTimes':
        if (summaryTableData.customDailyTimes.length > 0) {
          return summaryTableData.customDailyTimes.map(
            (cdt: CustomDailyTime) =>
              `${cdt.projectName}: ${
                cdt.workUnit
              } [${cdt.reportingCustomDataType[0].toUpperCase()}${cdt.reportingCustomDataType
                .substring(1)
                .toLowerCase()}], `,
          );
        } else {
          return '-';
        }
      case 'status':
        return value
          ? intl.formatMessage({ id: `SUMMARY_STATUS.${value}` })
          : intl.formatMessage({ id: 'SUMMARY_STATUS.UNKNOWN' });
      case 'attachedFiles':
        return (
          <>
            {!!files.length && (
              <>
                {intl.formatMessage({ id: 'DROPZONE.SELECTED_FILES' })}
                {files.map((entry: File, index: number) => (
                  <p key={`filename-${index}`}>
                    {`${index + 1}. ${entry.name}`}
                  </p>
                ))}
              </>
            )}
            <Tooltip
              title={
                displayAttachmentTooltip ? (
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id:
                        summaryStatus ===
                        SummaryStatus.WAITING_FOR_LEADER_APPROVAL
                          ? 'SUMMARY.TOOLTIP.DISABLED_ATTACHMENTS_BUTTON_WAIT_FOR_LEADER_APPROVAL'
                          : 'SUMMARY.TOOLTIP.DISABLED_ATTACHMENTS_BUTTON_NEED_LEADER_APPROVAL',
                    })}
                  </Typography>
                ) : (
                  ''
                )
              }
              placement="top"
            >
              <div style={{ width: 'fit-content' }}>
                <ContainedButton
                  id="manageUploadsButton"
                  disabled={disabledUploadButton}
                  size="medium"
                  onClick={handleFileUploadClick}
                >
                  {intl.formatMessage({ id: 'DROPZONE.DIALOG_BUTTON' })}
                </ContainedButton>
              </div>
            </Tooltip>
          </>
        );
      case 'mailStatus':
        return summaryTableData.mailStatus !== null
          ? intl.formatMessage({
              id: `SUMMARY_MAIL_STATUS.${summaryTableData.mailStatus}`,
            })
          : intl.formatMessage({
              id: `SUMMARY_MAIL_STATUS.NOT_SENT`,
            });

      case 'benefitsCostSummary':
        return value !== null ? `${value} PLN` : 0;
      default:
        return value ? value.toString() : '';
    }
  };

  const onDateChange = useCallback(
    (date: SetStateAction<Date | null>) => {
      setDate(date);
    },
    [setDate],
  );

  const handleClearButton = useCallback(() => {
    setDate(moment().toDate());
  }, [setDate]);

  useEffect(() => {
    setDidFileChanged(files.length > 0);
  }, [files.length]);

  const handleSelectUser = useCallback(
    (e: OnChangeValue<TimesheetData, boolean>) => {
      if (e) {
        e = e as TimesheetData;
        setSelectedUser(e);
        if (e.username) {
          setTimesheetUsername(e.username);
        }
      } else {
        setTimesheetUsername('');
        setSelectedUser({ name: '', value: 0, username: '' });
      }
    },
    [setSelectedUser, setTimesheetUsername],
  );
  const getUserData = useCallback(async () => {
    const profile: AccountResponse = await getFetch({
      url: `${userProfileUrl}/${username}/profile`,
    });
    setUserContract(profile?.contractType ?? '');
  }, [username]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const [summaryHead, sendHead] = WORK_TIME_SUMMARY_HEAD_DATA;

  const disabledSending = !isCustomFormValid || reportSubmitting;

  const disabledSendingWithoutChangesInFullReport =
    summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL ||
    summaryStatus === SummaryStatus.SENT_OUT;

  const disabledSendingWithoutAttachedFiles =
    userContract === ContractTypes.B2B &&
    files.length === 0 &&
    summaryStatus !== SummaryStatus.NEEDS_LEADER_APPROVAL &&
    !disabledSendingWithoutChangesInFullReport;

  const disabledButton =
    !myTimesheetLoaded ||
    disabledSending ||
    summaryTableData.unreportedDays > 0 ||
    (disabledSendingWithoutChangesInFullReport &&
      !didFileChanged &&
      !didAdditionalInfoChanged);

  const disabledUploadButton =
    disabledButton ||
    isUserSettled ||
    (userContract === 'B2B' &&
      summaryStatus === SummaryStatus.NEEDS_LEADER_APPROVAL);

  const displayAttachmentTooltip =
    userContract === ContractTypes.B2B &&
    (summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL ||
      summaryStatus === SummaryStatus.NEEDS_LEADER_APPROVAL);

  const displayLeadersTooltip =
    summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL &&
    leaders.length > 0;

  const isDisabledSending = () => {
    const hasMissingInfo = !missingInfo?.contractTypeDefined;
    return disabledButton || hasMissingInfo;
  };

  const userData = useMemo(
    () => timesheetData?.find((data) => data?.username === username),
    [timesheetData, username],
  );

  const getButtonTooltipId = () => {
    if (summaryStatus === SummaryStatus.SENT_OUT) {
      return 'SUMMARY.SENDING.TOAST_ACCEPT';
    }
    if (summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL) {
      return 'SUMMARY.TOOLTIP.DISABLED_SEND_BUTTON_WITHOUT_APPROVAL';
    }
    return disabledSendingWithoutAttachedFiles
      ? 'SUMMARY.TOOLTIP.DISABLED_APPROVE_AND_SEND_BUTTON_WITHOUT_ATTACHED_FILES'
      : 'SUMMARY.TOOLTIP.DISABLED_APPROVE_AND_SEND_BUTTON';
  };

  const showRequestForApprovalTooltip =
    disabledSendingWithoutChangesInFullReport &&
    summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL;

  const showApproveTooltip =
    ((summaryStatus === SummaryStatus.READY_TO_BE_SENT_OUT ||
      summaryStatus === SummaryStatus.SENT_OUT) &&
      disabledSendingWithoutAttachedFiles) ||
    summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL ||
    summaryStatus === SummaryStatus.SENT_OUT;

  const generateButton = () => {
    return (
      <>
        <Tooltip
          title={
            showRequestForApprovalTooltip ? (
              <Typography variant="body2">
                <div>
                  {intl.formatMessage({
                    id: 'SUMMARY.TOOLTIP.DISABLED_APPROVE_AND_SEND_BUTTON_REQUEST_FOR_APPROVAL',
                  })}
                  {'\n'}
                  {displayLeadersTooltip &&
                    `${intl.formatMessage({
                      id: 'SUMMARY.TOOLTIP.LEADERS',
                    })} ${leaders.join(', ')}`}
                </div>
              </Typography>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <div>
            <StyledContainedButton
              disabled={
                disabledSendingWithoutChangesInFullReport ||
                disabledButton ||
                summaryStatus !== SummaryStatus.NEEDS_LEADER_APPROVAL
              }
              endIcon={
                isDisabledSending() ||
                disabledSendingWithoutChangesInFullReport ||
                summaryStatus !== SummaryStatus.NEEDS_LEADER_APPROVAL ? (
                  <BlockIcon />
                ) : (
                  <SendIcon style={{ color: 'black' }} />
                )
              }
              onClick={handleOnClick}
              style={{ marginRight: '1rem' }}
            >
              {intl.formatMessage({
                id: 'WORK_TIME_TAB.REQUEST_FOR_APPROVAL',
              })}
            </StyledContainedButton>
          </div>
        </Tooltip>
        <Tooltip
          title={
            showApproveTooltip ? (
              <Typography variant="body2">
                <div>{intl.formatMessage({ id: getButtonTooltipId() })}</div>
              </Typography>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <div>
            <StyledContainedButton
              disabled={
                disabledSendingWithoutAttachedFiles ||
                disabledButton ||
                summaryStatus !== SummaryStatus.READY_TO_BE_SENT_OUT
              }
              endIcon={
                isDisabledSending() ||
                disabledSendingWithoutAttachedFiles ||
                summaryStatus !== SummaryStatus.READY_TO_BE_SENT_OUT ? (
                  <BlockIcon />
                ) : (
                  <SendIcon style={{ color: 'black' }} />
                )
              }
              onClick={handleOnClick}
            >
              {intl.formatMessage({
                id: 'WORK_TIME_TAB.APPROVE_AND_SEND',
              })}
            </StyledContainedButton>
          </div>
        </Tooltip>
      </>
    );
  };

  const handleOnClick = () => {
    if (isDisabledSending()) {
      setDisabledApproveClicked(true);
    } else {
      onApproveAndSend();
    }
  };

  return (
    <GridWrapper container>
      <GridWrapper container justify="flex-start">
        <GridItem
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '-5px',
            maxWidth: '300px',
          }}
        >
          <DatePickerWrapper>
            <Datepicker
              label={intl.formatMessage({
                id: 'TABLE_FILTER.LABEL.DATE',
              })}
              monthJumpButtons
              value={date}
              onChange={onDateChange}
              onClearButtonClick={handleClearButton}
              fullWidth
            />
          </DatePickerWrapper>
        </GridItem>
        <GridItem item xs={12} sm={7} md={5} lg={3}>
          {isUserAuthorized && (
            <SelectContainer>
              <ReactSelect
                name="users"
                label={intl.formatMessage({
                  id: 'WORK_TIME.SELECT_USER',
                })}
                options={timesheetData ?? []}
                defaultValue={
                  selectedUser?.name.length ? selectedUser : userData
                }
                handleChange={handleSelectUser}
              />
            </SelectContainer>
          )}
        </GridItem>
      </GridWrapper>
      <GridItem
        item
        xs={customFieldsData && customFieldsData?.length > 0 ? 7 : 12}
      >
        {!canFetchOrCreate && (
          <StyledErrorBox>
            <TableCell align={'center'}>
              <Block />
              <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
            </TableCell>
          </StyledErrorBox>
        )}
        {canFetchOrCreate && (
          <TableContainer component={Paper} $loading={loading}>
            <TableComponent>
              <TableLoadingWrapper loading={summaryLoading}>
                <>
                  <TableHeadComponent>
                    <TableRow $main>
                      <TableCell
                        key={'summary-header'}
                        width={summaryHead.width}
                        align={summaryHead.align}
                      >
                        {intl.formatMessage({ id: summaryHead.label })}
                      </TableCell>
                      <TableCell
                        key={'send-header'}
                        width={sendHead.width}
                        align={sendHead.align}
                      >
                        <SendButtonWrapper>
                          {summaryTableData.unreportedDays > 0 &&
                            !reportSubmitting && (
                              <ToolTipCell
                                tooltip={intl.formatMessage({
                                  id: 'WORK_TIME_UNREPORTED_DAYS',
                                })}
                                bigger
                                isWarning
                              />
                            )}
                          {disabledSending && !reportSubmitting && (
                            <ToolTipCell
                              id="customFieldsTooltip"
                              tooltip={intl.formatMessage({
                                id: 'CUSTOM_FIELDS.SAVE_CHANGES',
                              })}
                              bigger
                              isWarning
                            />
                          )}
                          {isUserSettled && !reportSubmitting ? (
                            <ToolTipCell
                              id="settledTooltip"
                              styles={{
                                wrapperStyles: {
                                  marginTop: '6px',
                                  marginBottom: '6px',
                                },
                              }}
                              bigger
                              text={intl.formatMessage({
                                id: 'WORK_TIME_SETTLED_TEXT',
                              })}
                              tooltip={intl.formatMessage({
                                id: 'WORK_TIME_SETTLED_TOOLTIP',
                              })}
                              isWarning
                            />
                          ) : (
                            generateButton()
                          )}
                        </SendButtonWrapper>
                      </TableCell>
                    </TableRow>
                  </TableHeadComponent>
                  <TableBody>
                    {Object.entries(summaryTableData).map(([k, v]) =>
                      k !== 'settled' && k !== 'wasNewBenefitCostAccepted' ? (
                        <TableRow key={`table-row-${k}`}>
                          <BoldedTableCell
                            width="40%"
                            align="left"
                            key={`summary-label-${k}`}
                          >
                            {intl.formatMessage({ id: SUMMARY_LABELS[k] })}
                          </BoldedTableCell>
                          <TableCell key={`summary-value-${k}`}>
                            {renderCell(k, v)}
                          </TableCell>
                        </TableRow>
                      ) : null,
                    )}
                  </TableBody>
                </>
              </TableLoadingWrapper>
            </TableComponent>
          </TableContainer>
        )}
        <FilesUploadDialog
          files={files}
          setFiles={setFiles}
          addChecked={openFileUploadArea}
          handleAddChanged={handleFileUploadClick}
          canDeleteOnSubmit
        />
      </GridItem>
      {customFieldsData && customFieldsData?.length > 0 && (
        <GridItem item xs={5}>
          <CustomFieldsTable
            summarySettled={summaryTableData.settled}
            customFieldsData={customFieldsData}
            fetchCustomFields={fetchCustomFields}
            timesheetId={ownTimesheedId}
            isDisabled={!myTimesheetLoaded}
          />
        </GridItem>
      )}
      {isOpenNewBenefitCostDialog &&
        disabledApproveClicked &&
        (selectedUser?.username === username || !selectedUser?.name) && (
          <MissingUserProfileInfoDialog
            onClose={() => {
              setIsOpenNewBenefitCostDialog(false);
              setDisabledApproveClicked(false);
            }}
            handleAction={handleAccept}
            type={MissingInfoType.newBenefitCost}
            cost={summaryTableData.benefitsCostSummary}
          />
        )}
      {isMissingContract &&
        disabledApproveClicked &&
        (selectedUser?.username === username || !selectedUser?.name) && (
          <MissingUserProfileInfoDialog
            onClose={setDisabledApproveClicked}
            missingInfo={missingInfo}
            type={MissingInfoType.summary}
          />
        )}
    </GridWrapper>
  );
};

export default SummaryTable;
