import moment from 'moment';
import { roundNumber } from './formatters/roundNumber';

const WORK_DAY_LENGTH = 8;
const DECIMAL_PLACES = 3;

export const mapStringToISODuration = (duration: string) => {
  const formattedDuration = moment(duration).format('HH:mm');
  return moment.duration(formattedDuration).toISOString();
};

export const mapISODurationToString = (duration: string) => {
  return `${String(moment.duration(duration).hours()).padStart(
    2,
    '0',
  )}:${String(moment.duration(duration).minutes()).padStart(2, '0')}`;
};

export const mapISODurationToDaysHoursMinutes = (duration?: string) => {
  return `${String(moment.duration(duration).days())}d ${String(
    moment.duration(duration).hours(),
  ).padStart(2, '0')}:${String(moment.duration(duration).minutes()).padStart(
    2,
    '0',
  )}`;
};

export const mapISODurationToDays = (duration: string) => {
  return roundNumber(
    moment.duration(duration).asHours() / WORK_DAY_LENGTH,
    DECIMAL_PLACES,
  );
};

export const mapISODurationToHours = (duration: string) => {
  return roundNumber(moment.duration(duration).asHours(), DECIMAL_PLACES);
};

export const mapStringToDuration = (date: string) => {
  return moment.duration(moment(date).format('HH:mm'));
};
