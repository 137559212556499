import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { LinearProgress } from '@material-ui/core';
import { Block } from '@material-ui/icons';
import {
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
} from 'components/Table/Table.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext/index';
import { BENEFITS_TABLE_HEAD_DATA as head } from 'modules/UserProfile/Screens/Benefits/staticData';
import { getBenefitCost } from 'modules/UserProfile/Screens/Benefits/utils';
import BenefitsTableRow from './BenefitsTableRow';
import ToolTipBenefitCell from './ToolTipBenefitCell';
import { BenefitsTableProps } from './types';

const BenefitsTable = ({
  loadingTable,
  benefitsFetchedData,
  benefitsInfo,
}: BenefitsTableProps) => {
  const intl = useIntl();

  const { state } = useContext(BenefitContext);

  const renderTableHeadRow = () =>
    head.map((headData, index) => (
      <TableCell
        align={headData.align}
        key={`${index} - ${headData.key}`}
        width={headData.width}
      >
        {headData.label !== 'TABLE.HEAD.SUBSCRIPTION_END_DATE' ? (
          intl.formatMessage({ id: headData.label })
        ) : (
          <ToolTipBenefitCell
            id={`${headData.key}_tooltip`}
            text={headData.label}
            tooltip="TABLE.BODY.BENEFIT.DEADLINE_INFO"
          />
        )}
      </TableCell>
    ));

  const renderTableRow = () => {
    return benefitsFetchedData
      .sort((a, b) => a.benefitName.localeCompare(b.benefitName))
      .map((entry, index) => {
        const benefitCost = getBenefitCost({
          entry,
          renderPartners: state.renderPartners,
          benefitsInfo,
        });
        return (
          <BenefitsTableRow
            key={entry.id}
            entry={entry}
            index={index}
            benefitCost={benefitCost}
            benefitsInfo={benefitsInfo}
          />
        );
      });
  };

  const renderTableRows = () => {
    return benefitsFetchedData?.length ? (
      <>{renderTableRow()}</>
    ) : (
      <TableRow>
        <TableCell align={'center'} colSpan={head.length}>
          <Block />
          <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper} $loading={loadingTable}>
      {loadingTable && <LinearProgress />}
      <TableComponent>
        <TableHeadComponent>
          <TableRow $main>{renderTableHeadRow()}</TableRow>
        </TableHeadComponent>
        <TableBody>{renderTableRows()}</TableBody>
      </TableComponent>
    </TableContainer>
  );
};

export default BenefitsTable;
