import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import BenefitCheckbox from 'components/BenefitInputs/BenefitCheckbox';
import BenefitSelectField from 'components/BenefitInputs/BenefitSelectField';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import { getHelperText, validate } from 'utils/formValidation';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { getDefaultSelectValue, getStringValue } from '../../../utils';
import { getCategoryOptions } from '../inputHelpers';
import { StepProps } from '../types';
import { GridWithMargin } from '../../../BenefitsManager.css';

const FirstStep = ({ control, errors, setValue, getValues }: StepProps) => {
  const {
    benefitManagerState: { formState, tableData, openedForm },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  const setCheckboxFormState = useCallback(() => {
    if (getValues().usingOwnForm === undefined) {
      setValue('usingOwnForm', formState.usingOwnForm);
    }
  }, [formState.usingOwnForm, getValues, setValue]);

  const activeBenefitNames = useMemo(
    () => tableData.map((benefit) => benefit.benefitName),
    [tableData],
  );

  const getValidation = useCallback(() => {
    if (openedForm === 'addNew') {
      return validate(intl, { forbiddenStrings: activeBenefitNames })
        .theSameName;
    } else {
      return validate(intl).required;
    }
  }, [activeBenefitNames, intl, openedForm]);

  useEffect(() => {
    setCheckboxFormState();
  }, [setCheckboxFormState]);

  return (
    <GridWithMargin container>
      <BenefitTextField
        label="BENEFIT_NAME"
        name="benefitName"
        rules={getValidation()}
        defaultValue={formState.benefitName}
        control={control}
        errors={errors}
      />
      <BenefitTextField
        label="EMAIL"
        name="benefitEmail"
        defaultValue={formState.benefitEmail}
        rules={validate(intl).unrequiredEmail}
        control={control}
        errors={errors}
        tooltipTitle={intl.formatMessage({
          id: 'BENEFITS.FORM.EMAIL_TOOLTIP',
        })}
      />
      <BenefitSelectField
        label="BENEFIT_CATEGORY"
        name="benefitCategory"
        options={getCategoryOptions(intl)}
        errorMessage={getHelperText(intl).required}
        defaultValue={getDefaultSelectValue(intl, formState.benefitCategory)}
        control={control}
        errors={errors}
        rules={validate(intl).required}
      />
      <BenefitTextField
        label="DAY_OF_DEADLINE"
        name="lastDayToSendChanges"
        rules={validate(intl).positiveMonthIntegers}
        defaultValue={getStringValue(formState.lastDayToSendChanges)}
        control={control}
        errors={errors}
        isOnlyInteger
        tooltipTitle={intl.formatMessage({
          id: 'BENEFITS.FORM.DAY_OF_DEADLINE_TOOLTIP',
        })}
      />
      <BenefitTextField
        label="COMMENTS"
        name="comments"
        defaultValue={formState.comments}
        control={control}
        errors={errors}
        isMulti
        placeholder={intl.formatMessage({
          id: 'BENEFITS.FORM.ADDITIONAL_INFO_PLACEHOLDER',
        })}
        md={12}
      />
      <BenefitCheckbox
        label="IS_USING_FORM"
        name="usingOwnForm"
        control={control}
        defaultChecked={formState.usingOwnForm}
      />
    </GridWithMargin>
  );
};

export default FirstStep;
