import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MomentUtils from '@date-io/moment';
import { Box, LinearProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { loginRequest } from 'authConfig';
import { GlobalContext } from 'globalContext/GlobalContext';
import { ModalProvider } from 'globalContext/ModalContext';
import { UserContext } from 'globalContext/UserContext';
import moment from 'moment';
import routes from 'router/routes';
import { ToastProvider } from 'components';
import Drawer from 'components/Drawer/Drawer';
import { BodyWrapper } from 'components/Drawer/Drawer.css';
import Header from 'components/Header/Header';
import Modal from 'components/Modal/Modal';
import { removeScript } from 'utils/removeScript';
import { StatusType } from 'utils/statusTypes';
import 'moment/locale/en-gb';
import { RoleTypes } from './utils/rolesTypes';
import './App.css';

const drawerData = Object.freeze([
  { label: 'DRAWER.TIMESHEETS', to: '/timesheets', id: 'menu_timesheets' },
  { label: 'DRAWER.WORKTIME', to: '/worktime', id: 'menu_worktime' },
  { label: 'DRAWER.REPORTS', to: '/reports', id: 'menu_reports' },
  { label: 'DRAWER.PROJECTS', to: '/projects', id: 'menu_projects' },
  { label: 'DRAWER.CLIENTS', to: '/clients', id: 'menu_clients' },
  { label: 'DRAWER.ADMIN_PANEL', to: '/adminpanel', id: 'menu_adminPanel' },
  //Comment it for now, to hide this view from sidebar for everyone
  {
    label: 'DRAWER.BUSINESS_TRIPS',
    to: '/businesstrips',
    id: 'menu_businessTrips',
  },
  // { label: 'DRAWER.MANAGER_FORM', to: '/form' },
]);

function App() {
  const { dispatch, teamLeader, roles } = useContext(UserContext);
  const [openMenu, setOpenMenu] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const fetchUser = useCallback(
    async (accessToken?: string) => {
      if (!accessToken && !localStorage.getItem('tpToken')) {
        return;
      }
      setLoading(true);
      const res = await fetch(`api/accounts/user`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            accessToken ?? localStorage.getItem('tpToken')
          }`,
        },
      });

      if (res.ok) {
        const response = await res.json();
        if (response && response.username && response.roles) {
          dispatch({
            type: 'ADD_GLOBAL_DATA',
            payload: {
              username: response?.username,
              teamLeader: response?.teamLeader,
              roles: response.roles,
            },
          });
        }
      } else if (res.status === StatusType.Unauthorized) {
        localStorage.removeItem('tpToken');
        window.location.reload();
      }
      setLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (localStorage.getItem('tpToken')) {
      return;
    }
    if (accounts[0] && isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(({ accessToken }) => {
          localStorage.setItem('tpToken', accessToken);
          fetchUser(accessToken);
        })
        .catch((err) => {
          if (err.name === 'InteractionRequiredAuthError') {
            return instance
              .acquireTokenPopup({
                ...loginRequest,
                account: accounts[0],
              })
              .then((response) => {
                localStorage.setItem('tpToken', response.accessToken);
                fetchUser(response.accessToken);
              })
              .catch((error) => {
                console.error(error);
              });
          }
          console.error(err);
        });
    } else if (inProgress === InteractionStatus.None && !isAuthenticating) {
      setIsAuthenticating(true);
      localStorage.removeItem('tpToken');
      instance.loginRedirect(loginRequest).catch((e) => console.error(e));
    } else if (inProgress === InteractionStatus.None && isAuthenticating) {
      setIsAuthenticating(false);
    }
  }, [
    accounts,
    instance,
    isAuthenticated,
    inProgress,
    isAuthenticating,
    fetchUser,
  ]);

  const toggleDrawer = () => setOpenMenu((prevState) => !prevState);

  const getDrawerData = () => {
    if (hideTabsForPrivilegedUsers && roles.length) {
      return drawerData.filter(
        (route) =>
          route.label !== 'DRAWER.TIMESHEETS' &&
          route.label !== 'DRAWER.REPORTS' &&
          route.label !== 'DRAWER.ADMIN_PANEL',
      );
    } else {
      return drawerData;
    }
  };

  const {
    localization: { locales, currentLocale },
    setlogoutURL,
  } = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    if (location.pathname !== '' && !localStorage.getItem('tpToken')) {
      setlogoutURL(location.pathname + location.search);
    }
  }, [history, location, setlogoutURL]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const logOut = () => {
    const logoutRequest = {
      account: accounts[0],
    };
    localStorage.removeItem('tpToken');
    instance
      .logoutRedirect(logoutRequest)
      .then(() => {
        removeScript();
        setlogoutURL('');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  //If you want to change authorized users, change it also in the Login form!
  const hideTabsForPrivilegedUsers = useMemo(() => {
    return !(
      roles.includes(RoleTypes.RoleAccounting) ||
      roles.includes(RoleTypes.RoleDEVHR) ||
      teamLeader
    );
  }, [roles, teamLeader]);

  useEffect(() => {
    if (localStorage.getItem('tpToken') && roles.length) {
      if (
        hideTabsForPrivilegedUsers &&
        ['/timesheets', '/reports', '/adminpanel'].includes(location.pathname)
      ) {
        history.push('/worktime');
      }
    }
  }, [hideTabsForPrivilegedUsers, history, roles, location.pathname]);

  if (!localStorage.getItem('tpToken') || loading) {
    return <LinearProgress />;
  }

  return (
    <IntlProvider
      messages={locales[currentLocale].messages}
      locale={locales[currentLocale].locale}
      defaultLocale={'en-gb'}
    >
      <Header
        onLogOut={logOut}
        token={localStorage.getItem('tpToken')}
        setOpenMenu={setOpenMenu}
        data={getDrawerData()}
      />
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={'en-gb'}
      >
        <ModalProvider>
          <BodyWrapper>
            <Modal />
            <Box
              minHeight="calc(100vh - 100px)"
              justifyContent="top"
              flexDirection="column"
              position="relative"
              display="flex"
            >
              {roles.length && localStorage.getItem('tpToken') && (
                <Switch>
                  {routes
                    .filter((route) => {
                      if (route.path === '/timesheets') {
                        return !hideTabsForPrivilegedUsers;
                      } else if (route.path === '/reports') {
                        return !hideTabsForPrivilegedUsers;
                      } else if (route.path === '/adminpanel') {
                        return !hideTabsForPrivilegedUsers;
                      } else {
                        return true;
                      }
                    })
                    .map((route, index) => (
                      <Route
                        key={`${index} - ${route.path}`}
                        component={route.component}
                        exact
                        path={route.path}
                      />
                    ))}
                </Switch>
              )}
            </Box>
          </BodyWrapper>
        </ModalProvider>
        <ToastProvider />
        {localStorage.getItem('tpToken') && (
          <Drawer
            variant="temporary"
            open={openMenu}
            onClose={toggleDrawer}
            anchor="right"
            onClick={toggleDrawer}
            data={getDrawerData()}
          />
        )}
      </MuiPickersUtilsProvider>
    </IntlProvider>
  );
}

export default App;
