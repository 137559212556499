import React from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { AdditionalAccountsSectionProps } from '../../types';
import {
  SummarySectionContainer,
  SummarySectionTitle,
} from '../../BusinessTripDialog.css';

const AdditionalAccountsSection = ({
  additionalAccounts,
  title,
}: AdditionalAccountsSectionProps) => {
  const intl = useIntl();
  return (
    <>
      {additionalAccounts.length > 0 ? (
        <SummarySectionContainer>
          {additionalAccounts.map((el, key) => (
            <Grid
              container
              direction="row"
              key={`${key}-${el.name}`}
              justify="center"
            >
              <Grid item>{el.name}</Grid>
            </Grid>
          ))}
          <SummarySectionTitle>
            {intl.formatMessage({
              id: title,
            })}
          </SummarySectionTitle>
        </SummarySectionContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdditionalAccountsSection;
