import React, { Dispatch, SetStateAction } from 'react';
import { Control } from 'react-hook-form';

type DateValue = Date | null;

export interface FormDataProps {
  startDate: DateValue;
  endDate: DateValue;
  leaveType: HolidayTypesOptions<MainHolidayTypes | LeaveTypes> | null;
  leaveTypeCategory: string | null;
  comment: string | null;
}

export enum FormTypes {
  StartDate = 'startDate',
  EndDate = 'endDate',
  LeaveType = 'leaveType',
  LeaveTypeCategory = 'leaveTypeCategory',
  Comment = 'comment',
}

export interface RequestHolidaysDialogProps {
  open: boolean;
  handleClose: () => void;
}

export enum ExpandedOption {
  ActiveRequests = 'activeRequests',
  AbsenceHistory = 'absenceHIstory',
}

export interface HolidayInformationList {
  id: number;
  creationDateTime: string;
  modificationDateTime: Date | null;
  dateFrom: string;
  dateTo: string;
  requestedDays: number;
  status: RequestStatusTypes;
  teamLeaderApproved: boolean;
  type: LeaveTypes;
  editable: boolean;
  attachments: [];
  comments: string[];
  enovaUpdates: number[] | null;
  account: { id: number; name: string; surname: string; username: string };
}

export interface RequestTableCellsProps {
  type: LeaveTypes;
  status: RequestStatusTypes;
  dateFrom: string;
  dateTo: string;
  creationDateTime: string;
}

export interface ActiveRequestTableCellsProps {
  requestId: number;
  leaderAccepted?: boolean;
  deleteRequest?: (requestId: number) => Promise<void>;
}

export interface StatusStyleProps {
  color: RequestStatusTypes;
}

export interface RequestsTableProps {
  data: HolidayInformationList[];
  isEditable: boolean;
  deleteRequest?: (requestId: number) => Promise<void>;
}

export interface RequestsTableHeadProps {
  titles: { name: string; isFirstColumn: boolean }[];
}

export interface LeaveRequestsPaginationProps {
  elementsCount: number;
  currentNumber: number;
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  rowsPerPage: number;
}

export enum MainHolidayTypes {
  StandardLeave,
  MedicalLeave,
  OtherLeave,
}

export enum StandardLeaveTypes {
  ANNUAL_PAID_LEAVE = 'ANNUAL_PAID_LEAVE',
  EMERGENCY_PAID_LEAVE = 'EMERGENCY_PAID_LEAVE',
}

export enum MedicalLeaveTypes {
  SICK_PAID_LEAVE = 'SICK_PAID_LEAVE',
  CARE_PAID_LEAVE = 'CARE_PAID_LEAVE',
}
export enum OtherLeaveTypes {
  SPECIAL_PAID_LEAVE = 'SPECIAL_PAID_LEAVE',
  CHILD_CARE_PAID_LEAVE = 'CHILD_CARE_PAID_LEAVE',
  UNPAID_LEAVE = 'UNPAID_LEAVE',
  MATERNITY_PAID_LEAVE = 'MATERNITY_PAID_LEAVE',
  FATHERLY_PAID_LEAVE = 'FATHERLY_PAID_LEAVE',
  PARENTAL_PAID_LEAVE = 'PARENTAL_PAID_LEAVE',
  CHILD_EDUCATION_PAID_LEAVE = 'CHILD_EDUCATION_PAID_LEAVE',
}

export type LeaveTypes =
  | StandardLeaveTypes
  | MedicalLeaveTypes
  | OtherLeaveTypes;

export interface HolidayTypesOptions<T> {
  value: T;
  name: string;
}

export interface LeaveRequestSelectProps<T> {
  title: string;
  label: string;
  name: FormTypes;
  control: Control;
  options: HolidayTypesOptions<T>[];
  handleChange: (e: HolidayTypesOptions<T>) => void;
}

export interface LeaveRequestFormDataSectionProps {
  control: Control;
  setValue: (name: string, value: null | Date) => void;
  endDate: DateValue;
  startDate: DateValue;
  leaveType: LeaveTypes;
  isAttachmentRequired: boolean;
  attachmentsArray: File[];
  setAttachmentsArray: Dispatch<SetStateAction<File[]>>;
}

export interface DatepickerFormControlTypes {
  name: string;
  control: Control;
  endDate: DateValue;
  startDate: DateValue;
  leaveType: LeaveTypes;
  setValue: (name: string, value: null | Date) => void;
}

export interface LeaveRequestFormProps {
  onClose: () => void;
}

export interface InfoNotesLineProps {
  isImportant?: boolean;
}

export enum InfoTypes {
  text,
  link,
}

export interface LeaveTypesInformationNotesProps {
  type: LeaveTypes;
  informationNotes: {
    type: InfoTypes;
    content: string;
    href?: string;
    isImportant?: boolean;
  }[];
}

export enum RequestStatusTypes {
  ToBeReviewed = 'TO_BE_REVIEWED',
  InProgress = 'IN_PROGRESS',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export interface RequestDataTypes {
  account: { id: number; name: string; surname: string; username: string };
  attachments: string | null;
  comments: string | null;
  creationDateTime: string;
  dateFrom: Date;
  dateTo: Date;
  enovaUpdates: number[] | null;
  id: number;
  modificationDateTime: Date | null;
  requestedDays: number;
  status: RequestStatusTypes;
  teamLeaderApproved: boolean;
  type: LeaveTypes;
}
