import { IntlShape } from 'react-intl';
import moment from 'moment';
import {
  BenefitManagerFormValues,
  BillabilityTypes,
  Packages,
  PersonCosts,
  TimeRangeData,
  TimeRangeTypes,
} from 'components/BenefitInputs/types';
import { RadioData } from 'components/ControlledRadioInput/types';
import { AVAILABLE_INPUTS } from '../../mock_data';
import { getFormattedName, getStringValue } from '../../utils';

export const getCategoryOptions = (intl: IntlShape) => [
  {
    name: intl.formatMessage({ id: 'BENEFITS.MEDICAL' }),
    type: 'Medical',
    value: 1,
  },
  {
    name: intl.formatMessage({ id: 'BENEFITS.SPORT' }),
    type: 'Sport',
    value: 2,
  },
  {
    name: intl.formatMessage({ id: 'BENEFITS.INSURANCE' }),
    type: 'Insurance',
    value: 3,
  },
];

export const getBillabilityOptions = (intl: IntlShape): RadioData[] => [
  {
    id: 'billableByPackage',
    value: BillabilityTypes.package,
    label: intl.formatMessage({ id: 'BENEFITS.FORM.BILLABILITY_BY_PACKAGE' }),
  },
  {
    id: 'billableByPerson',
    value: BillabilityTypes.person,
    label: intl.formatMessage({ id: 'BENEFITS.FORM.BILLABILITY_BY_PERSON' }),
  },
];

export const getCheckboxLabel = (property: Packages | PersonCosts) =>
  property.split(/(?=[A-Z])/)[0].toUpperCase();

export const getInputOptions = (intl: IntlShape) =>
  AVAILABLE_INPUTS.map((input) => ({
    name: intl.formatMessage({
      id: `BENEFITS.FORM.${getFormattedName(input.name)}`,
    }),
    type: input.type,
    value: input.value,
  }));

export const getGeneralValues = (
  intl: IntlShape,
  formState: BenefitManagerFormValues,
) => [
  {
    title: intl.formatMessage({ id: 'BENEFITS.FORM.BENEFIT_NAME' }),
    content: formState?.benefitName,
  },
  {
    title: intl.formatMessage({ id: 'BENEFITS.FORM.EMAIL' }),
    content: formState.benefitEmail,
  },
  {
    title: intl.formatMessage({ id: 'BENEFITS.FORM.BENEFIT_CATEGORY' }),
    content: formState.benefitCategory?.name,
  },
  {
    title: intl.formatMessage({ id: 'BENEFITS.FORM.DAY_OF_DEADLINE' }),
    content: formState.lastDayToSendChanges,
  },
  {
    title: intl.formatMessage({ id: 'BENEFITS.FORM.ADDITIONAL_INFO' }),
    content: formState.comments,
    isFullWidth: true,
  },
];

export const getDayMonthYearIntl = ({
  dayMonthYear,
  numberOfDaysMonthsYears,
}: TimeRangeData) => {
  const dayMonthYearType = dayMonthYear?.type?.toUpperCase();
  if (numberOfDaysMonthsYears === 1) {
    return `BENEFITS.${dayMonthYearType}`;
  } else {
    return `BENEFITS.${dayMonthYearType}S`;
  }
};

export const getActionsValues = (
  intl: IntlShape,
  formState: BenefitManagerFormValues,
) => {
  const getContent = (type: TimeRangeTypes) => {
    if (
      formState[type].dayMonthYear?.name &&
      (formState[type].numberOfDaysMonthsYears ||
        formState[type].numberOfDaysMonthsYears === 0)
    ) {
      return `${formState[type].numberOfDaysMonthsYears} ${intl.formatMessage({
        id: getDayMonthYearIntl(formState[type]),
      })}`;
    } else if (formState[type].fixedDate) {
      return moment(formState[type].fixedDate).format('Do MMMM');
    }
  };

  return Object.values(TimeRangeTypes).map((type) => ({
    title: intl.formatMessage({
      id: `BENEFITS.FORM.${getFormattedName(type)}`,
    }),
    content: getContent(type),
  }));
};

export const getDaysMonthsYearsOptions = (
  intl: IntlShape,
  numberOfDaysMonthsYears: number,
) => {
  const isEqualOne = Number(numberOfDaysMonthsYears) === 1;

  return [
    {
      name: intl.formatMessage({
        id: `BENEFITS.${isEqualOne ? 'DAY' : 'DAYS'}`,
      }),
      type: 'Day',
      value: 1,
    },
    {
      name: intl.formatMessage({
        id: `BENEFITS.${isEqualOne ? 'MONTH' : 'MONTHS'}`,
      }),
      type: 'Month',
      value: 2,
    },
    {
      name: intl.formatMessage({
        id: `BENEFITS.${isEqualOne ? 'YEAR' : 'YEARS'}`,
      }),
      type: 'Year',
      value: 3,
    },
  ];
};

export const getUpdatedDaysMonthsYearsOption = (
  intl: IntlShape,
  numberOfDaysMonthsYears: number,
  selectFieldValue: number | null,
) => {
  const { name, type, value } =
    getDaysMonthsYearsOptions(intl, numberOfDaysMonthsYears)[
      (selectFieldValue ?? 0) - 1
    ] ?? {};

  return { name, type, value };
};

export const getTimeRangeName = (
  type: TimeRangeTypes,
  propertyName: keyof TimeRangeData,
) => {
  return `${type}.${propertyName}` as keyof BenefitManagerFormValues;
};

export const getTimeRangeDefaultSelectValue = (
  intl: IntlShape,
  numberOfDaysMonthsYears?: number | null,
) => {
  const id =
    Number(numberOfDaysMonthsYears) === 1 ? 'BENEFITS.DAY' : 'BENEFITS.DAYS';

  return {
    name: intl.formatMessage({ id: id }),
    type: 'Day',
    value: 1,
  };
};

export const getTimeRangeData = (
  intl: IntlShape,
  formState: BenefitManagerFormValues,
  getValues: () => BenefitManagerFormValues,
  type: TimeRangeTypes,
) => {
  const isDate = getValues()[type]?.fixedDate || formState[type]?.fixedDate;

  const getSelectFieldDefaultValue = () => {
    if (formState[type]?.dayMonthYear?.value) {
      return getUpdatedDaysMonthsYearsOption(
        intl,
        formState[type]?.numberOfDaysMonthsYears ?? 0,
        formState[type]?.dayMonthYear?.value ?? null,
      );
    } else if (isDate) {
      return undefined;
    } else {
      return getTimeRangeDefaultSelectValue(intl);
    }
  };

  const getTextFieldDefaultValue = () => {
    if (isDate) {
      return '';
    } else {
      return getStringValue(formState[type]?.numberOfDaysMonthsYears) || '0';
    }
  };

  return {
    label: intl.formatMessage({
      id: `BENEFITS.FORM.${getFormattedName(type)}`,
    }),
    textFieldName: getTimeRangeName(type, 'numberOfDaysMonthsYears'),
    textFieldDefaultValue: getTextFieldDefaultValue(),
    selectFieldName: getTimeRangeName(type, 'dayMonthYear'),
    selectFieldDefaultValue: getSelectFieldDefaultValue(),
    selectOptions: getDaysMonthsYearsOptions(
      intl,
      getValues()[type]?.numberOfDaysMonthsYears ?? 0,
    ),
    datePickerName: getTimeRangeName(type, 'fixedDate'),
    datePickerDefaultValue: formState[type]?.fixedDate,
  };
};
