import { RoleTypes } from '../../utils/rolesTypes';
import { TableHead } from './types';

export const WORK_TIME_FULL_REPORT_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE_FILTER.LABEL.DATE',
    key: 'date',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '9%',
  },
  {
    label: 'TABLE.HEAD.STARTING_TIME',
    key: 'startTime',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '9%',
  },
  {
    label: 'TABLE.HEAD.ENDING_TIME',
    key: 'endTime',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '9%',
  },
  {
    label: 'TABLE.HEAD.BREAK_TIME',
    key: 'breakTime',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '6%',
  },
  {
    label: 'TABLE.HEAD.TOTAL_TIME',
    key: 'totalTime',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '8%',
  },
  {
    label: 'TABLE.HEAD.MULTIPLICATOR',
    key: 'multiplicator',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '14%',
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'clientName',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '14%',
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'projectName',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '14%',
  },
  {
    label: 'TABLE.HEAD.COMMENT',
    key: 'projectName',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '18%',
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '7%',
  },
];
