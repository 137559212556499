import TextField from '@material-ui/core/TextField';
import palette from 'assets/palette';
import styled from 'styled-components';
import { TextFieldComponentProps } from './types';

export const TextFieldComponent = styled(TextField)<TextFieldComponentProps>`
  &.MuiTextField-root {
    padding-right: 0.5rem;
  }
  && .MuiInput-root:hover::before {
    border-color: ${palette.ACTION};
  }
  & .MuiOutlinedInput-root:hover fieldset {
    border: 2px solid ${palette.ACTION};
  }

  .MuiInputBase-input.Mui-disabled {
    color: ${({ disabledStyle }) => {
      if (disabledStyle) {
        return '#000';
      }
    }};
    text-align: ${({ disabledStyle }) => {
      if (disabledStyle) {
        return 'center';
      }
    }};
  }
`;

export const FilledTextFieldComponent = styled(
  TextField,
)<TextFieldComponentProps>`
  &.MuiTextField-root {
    padding-right: 0.5rem;
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
`;
