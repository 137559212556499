import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Add, Close } from '@material-ui/icons';
import { ContainedButton } from 'components/Button';
import { TableIconButton, Tooltip } from 'components/Table/Table.css';
import { PartnerFieldProps } from './types';
import { ButtonWrapper, GridItem } from '../../BenefitsTab.css';

const PartnerField = ({
  children,
  handleAdd,
  isLast,
  index,
  handleRemove,
}: PartnerFieldProps) => {
  const intl = useIntl();
  const [removeField, setRemoveField] = useState(false);

  const handleCloseButton = () => {
    setRemoveField(true);
    if (handleRemove) {
      handleRemove();
    }
  };

  const renderCloseButton = () => {
    if (isLast && !!index) {
      return (
        <GridItem xs={12} style={{ textAlign: 'end', margin: '0 1rem' }}>
          <Tooltip
            title={intl.formatMessage({
              id: 'USER_PROFILE.BENEFITS.FORM_INFO.REMOVE_MEMBER',
            })}
            placement="top"
          >
            {
              <TableIconButton onClick={handleCloseButton}>
                <Close />
              </TableIconButton>
            }
          </Tooltip>
        </GridItem>
      );
    }
    return null;
  };

  const renderAddPartnerButton = () => {
    if (isLast) {
      return (
        <GridItem xs={12}>
          <ButtonWrapper
            style={{ justifyContent: 'flex-start', padding: '16px' }}
          >
            <ContainedButton
              size="small"
              disabled={false}
              endIcon={<Add />}
              type="button"
              onClick={handleAdd}
            >
              {intl.formatMessage({
                id: 'USER_PROFILE.BENEFITS.FORM_INFO.ADD_ANOTHER_MEMBER',
              })}
            </ContainedButton>
          </ButtonWrapper>
        </GridItem>
      );
    }
    return null;
  };

  const content = (
    <>
      {renderCloseButton()}
      {children}
      {renderAddPartnerButton()}
    </>
  );

  return !removeField ? content : null;
};

export default PartnerField;
