import styled from 'styled-components';
import { Paper } from 'components/Table/Table.css';

export const CustomFieldsListWrapper = styled(Paper)`
  width: 50vw;
  margin-top: 10px;
`;

export const ListItemWrapper = styled.div`
  width: 50vw;
  :not(:last-child) {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
`;
