import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';

export const GridWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    margin-bottom: 20px;
  }
`;

export const SpinnerWrapper = styled.div`
  padding-top: 30vh;
`;
