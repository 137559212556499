import { FC } from 'react';
import { useIntl } from 'react-intl';
import { DropzoneArea } from 'material-ui-dropzone';

//Max file size 10mb
const MAX_FILE_SIZE = 10 * 1024 * 1024;
const FILES_LIMIT = 10;

const FilesUploadArea: FC<{
  handleFileChange: (files: File[]) => void;
  files?: File[];
}> = ({ handleFileChange, files }) => {
  const intl = useIntl();

  return (
    <>
      <DropzoneArea
        initialFiles={files}
        dropzoneText={intl.formatMessage({ id: 'DROPZONE.INSTRUCTION' })}
        showPreviews={true}
        filesLimit={FILES_LIMIT}
        maxFileSize={MAX_FILE_SIZE}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewText={intl.formatMessage({ id: 'DROPZONE.SELECTED_FILES' })}
        onChange={handleFileChange}
      />
    </>
  );
};

export default FilesUploadArea;
