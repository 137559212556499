import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const PaperWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 30px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
