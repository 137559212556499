import {
  BenefitAccountFormValues,
  BenefitManagerFormValues,
  BenefitsSelectData,
  BenefitTypesProps,
  Partner,
  PersonalData,
  TimeRangeData,
} from 'components/BenefitInputs/types';

export type BenefitStatus = {
  name: string;
  type: string;
  value: number;
};

export type BenefitDates = {
  dateWhenAddPartnerPossible?: string;
  dateWhenBenefitEnds?: string;
  dateWhenBenefitStarts?: string;
  dateWhenChangeToHigherPackagePossible?: string;
  dateWhenChangeToHigherTypePossible?: string;
  dateWhenChangeToLowerPackagePossible?: string;
  dateWhenChangeToLowerTypePossible?: string;
  dateWhenRemovePartnerPossible?: string;
  lastChangeToHigherPackage?: string;
  lastChangeToHigherType?: string;
  lastChangeToLowerPackage?: string;
  lastChangeToLowerType?: string;
  lastDateWhenFamilyMemberAdded?: string;
  lastDateWhenFamilyMemberRemoved?: string;
};

export interface BenefitsFetchedData {
  hiringCompany?: string;
  benefitId: number;
  comments?: string;
  benefitName: string;
  status: BenefitStatus;
  id: number;
  benefitDates: BenefitDates;
  benefitType?: string;
  benefitPackage?: string;
  benefitTypeId: number;
  employee: PersonalData;
  children?: Partner[];
  partner?: Partner;
}

export type FetchedPackages =
  | 'INDIVIDUAL'
  | 'PARTNER'
  | 'FAMILY'
  | 'NOT_APPLICABLE';

export enum Packages {
  individual = 'individualPackage',
  family = 'familyPackage',
  partner = 'partnerPackage',
  notApplicable = 'notApplicable',
}

export type BenefitPackageProps = {
  name: string;
  value: number | null;
  type: Packages;
};

export interface BenefitsDataProps extends BenefitAccountFormValues {
  benefitDates: BenefitDates;
  benefitId: number;
  benefitPackage?: BenefitPackageProps;
  benefitType?: { name: string; value: number | null };
  benefitTypeId: number;
  id: number | null;
  benefitName: string;
  benefitStatus: { name: string; type: string; value: number | null };
  hiringCompany?: string;
  partner?: Partner;
  children?: Partner[];
}

export interface OptionsProps extends BenefitsSelectData {
  type?: string;
}

export type Cost = number | null;

export type Input =
  | 'firstName'
  | 'surname'
  | 'pesel'
  | 'idNumber'
  | 'phoneNumber'
  | 'email'
  | 'address'
  | 'addressForCorrespondence'
  | 'dateOfBirth'
  | 'gender'
  | 'placeOfBirth';

export interface FilteredBenefitNamesProps
  extends BenefitManagerFormValues,
    BenefitsSelectData {}

export interface FilteredBenefitTypesProps
  extends BenefitTypesProps,
    BenefitsSelectData {}

export interface SummaryDataTypes {
  title: string;
  content?: string;
  isAddress?: boolean;
}

export interface PartnerDataProps {
  isAddress: boolean;
  summaryValues?: SummaryDataTypes[];
}

export interface AddressProps {
  index?: number;
}

export interface SummaryGridProps {
  $address?: boolean;
}

export interface BenefitSelectProps {
  benefitsOptions: BenefitManagerFormValues<TimeRangeData>[];
  benefitsFromTable: BenefitsDataProps[];
  loading: boolean;
}
