import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import ControlledSelect from 'components/ControlledSelect';
import { ErrorProperties, SelectFieldProps } from './types';
import {
  checkEmployeeErrors,
  checkPartnerErrors,
  checkTimeRangeErrors,
  getPropertyError,
} from './utils';
import { GridItem } from './BenefitInputs.css';

const BenefitSelectField = ({
  label,
  name,
  options,
  errorMessage,
  index,
  defaultValue,
  multiDefaultValue,
  control,
  errors,
  isMulti,
  nameOfErrorProperty = ErrorProperties.Employee,
  rules,
  disabled,
  handleChange,
  xs = 12,
  sm = 12,
  md = 6,
}: SelectFieldProps) => {
  const partnersErrors = checkPartnerErrors(errors, name, index) as FieldError;
  const timeRangeErrors = checkTimeRangeErrors(errors, name) as FieldError;
  const employeeErrors = checkEmployeeErrors(errors, name);
  const errorsName = errors[name];

  const errorProperty = useMemo(
    () =>
      getPropertyError({
        nameOfErrorProperty,
        partnersErrors,
        timeRangeErrors,
        employeeErrors,
      }),
    [employeeErrors, nameOfErrorProperty, partnersErrors, timeRangeErrors],
  );

  const getDefaultValue = () => {
    if (typeof defaultValue === 'string') {
      return { name: defaultValue, value: 0 };
    }
    return defaultValue?.value ? defaultValue : undefined;
  };

  return (
    <GridItem item xs={xs} sm={sm} md={md}>
      <ControlledSelect
        label={`BENEFITS.FORM.${label}`}
        name={name}
        control={control}
        options={options}
        rules={rules}
        required={!!rules?.required}
        errorMessage={(errorsName || errorProperty) && errorMessage}
        defaultValue={getDefaultValue()}
        multiDefaultValue={multiDefaultValue}
        errorMessageStyle={{ margin: '-2px 14px 0 14px' }}
        isMulti={isMulti}
        isDisabled={disabled}
        handleChange={handleChange}
      />
    </GridItem>
  );
};

export default BenefitSelectField;
