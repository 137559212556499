import { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';
import { apiUrl } from 'router/url';
import {
  getEncodedQueryParamsString,
  QueryParameters,
} from 'utils/encoding/encodeQueryParamsString';
import { bugReportIssueCollectorScript } from './../router/url';
import { StatusType } from './statusTypes';

interface FetchTypes {
  setLoading?: Dispatch<SetStateAction<boolean>>;
  url: string;
  body?: any;
  intl?: IntlShape;
  /** INTL id. It returns string: `${label}.TOAST_ACCEPT` / `${label}.TOAST_DENIED` */
  label?: string;
  contentType?: 'json' | 'text' | 'formdata';
  signal?: AbortSignal;
  showErrorToast?: boolean;
  queryParams?: QueryParameters;
}

export interface FetchResponse<T = {}> {
  status: number;
  /** It contains boolean state whether the response was successful (status in the range 200-299) or not. */
  ok: boolean;
  data?: T;
}

interface FetchFunctionTypes extends FetchTypes {
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: HeadersInit;
}

const getDataType = (
  data: string,
  contentType?: 'json' | 'text' | 'formdata',
) => {
  if (contentType === 'text') {
    return data;
  }
  return data ? JSON.parse(data) : {};
};

export const getFetch = async ({
  setLoading = () => {
    //intentional empty function
  },
  url = '',
  contentType = 'json',
  signal,
  showErrorToast = true,
  queryParams,
}: FetchTypes) => {
  const token = localStorage.getItem('tpToken');
  if (token) {
    setLoading(true);
    try {
      url += getEncodedQueryParamsString(queryParams);

      const response = await fetch(apiUrl + url, {
        signal: signal,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': `${
            contentType === 'json'
              ? 'text/plain;charset=UTF-8'
              : 'charset=UTF-8'
          }`,
        },
      });
      setLoading(false);
      if (response.status === StatusType.OK) {
        if (contentType === 'text') {
          return await response.text();
        }
        return await response.json();
      } else if (
        response.status === StatusType.Unauthorized &&
        url !== bugReportIssueCollectorScript
      ) {
        localStorage.removeItem('tpToken');
      } else {
        if (
          response.status !== StatusType.Forbidden &&
          response.status !== StatusType.Unauthorized
        ) {
          showErrorToast &&
            response
              .json()
              .then((res) =>
                toast.error(
                  res.errorMessage ||
                    `${response.status} ${response.statusText}`,
                ),
              );
        }
      }
    } catch (e) {
      setLoading(false);
    }
  } else {
    window.location.reload();
  }
};

const fetchFunction = async ({
  setLoading = () => {
    // intentional empty function
  },
  url,
  method,
  body,
  headers,
  intl,
  label,
  contentType,
}: FetchFunctionTypes) => {
  const token = localStorage.getItem('tpToken');
  if (token) {
    setLoading(true);
    try {
      const response = await fetch(apiUrl + url, {
        headers: { ...headers, Authorization: `Bearer ${token}` },
        method,
        body,
      });
      const data = getDataType(await response.text(), contentType);
      const ok = response.status >= 200 && response.status <= 299;

      if (ok) {
        toast.success(intl?.formatMessage({ id: `${label}.TOAST_ACCEPT` }));
      } else {
        toast.error(
          data.errorMessage ||
            intl?.formatMessage({ id: `${label}.TOAST_DENIED` }),
        );
      }
      setLoading(false);
      return {
        status: response.status,
        ok,
        data,
      };
    } catch (e) {
      setLoading(false);
      toast.error(intl?.formatMessage({ id: `TOAST_UNDEFINED_ERROR` }));
      return { status: (e as any).response?.status || 0, ok: false };
    }
  } else {
    toast.error(intl?.formatMessage({ id: 'FETCH.NO_AUTHORIZATION' }));
    return { status: StatusType.Unauthorized, ok: false };
  }
};

export const postFetch = async <T = {}>({
  setLoading = () => {
    // intentional empty function
  },
  url = '',
  body,
  intl,
  label,
  contentType,
}: FetchTypes): Promise<FetchResponse<T>> =>
  fetchFunction({
    setLoading,
    intl,
    label,
    url,
    method: 'POST',
    body: contentType === 'formdata' ? body : JSON.stringify(body),
    headers: {
      ...(contentType !== 'formdata' && {
        'content-type': 'application/json; charset=utf-8',
      }),
    },
  });

export const putFetch = async <T = {}>({
  setLoading = () => {
    // intentional empty function
  },
  url = '',
  body,
  intl,
  label,
}: FetchTypes): Promise<FetchResponse<T>> =>
  fetchFunction({
    setLoading,
    intl,
    label,
    url,
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'content-type': 'application/json; charset=utf-8' },
  });

export const patchFetch = async <T = {}>({
  setLoading = () => {
    // intentional empty function
  },
  url = '',
  body,
  intl,
  label,
}: FetchTypes): Promise<FetchResponse<T>> =>
  fetchFunction({
    setLoading,
    intl,
    label,
    url,
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'content-type': 'application/json' },
  });

export const deleteFetch = async <T = {}>({
  setLoading = () => {
    // intentional empty function
  },
  url = '',
  intl,
  label,
}: FetchTypes): Promise<FetchResponse<T>> =>
  fetchFunction({
    setLoading,
    intl,
    label,
    url,
    method: 'DELETE',
  });
