import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { clientAccountingNoteUrl, projectAccountingNoteUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledTextField from 'components/ControlledTextField';
import { patchFetch, postFetch } from 'utils/fetchFunctions';
import { AccountingNoteDialogProps, AccountingNoteProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const AccountingNoteDialog = ({
  note,
  addChecked,
  handleAddChanged,
  data,
  fetchData: fetchNotes,
  editClicked,
}: AccountingNoteDialogProps) => {
  const { control, handleSubmit, formState, reset } =
    useForm<AccountingNoteProps>({
      mode: 'onChange',
      defaultValues: {
        title: note?.title || '',
        noteContent: note?.noteContent || '',
      },
    });
  const intl = useIntl();

  const onSubmit = handleSubmit(async (formData) => {
    if (data) {
      if (editClicked) {
        await patchFetch({
          url: `${clientAccountingNoteUrl}/${note?.id}`,
          body: formData,
          intl,
          label: 'ACCOUNTING_NOTES_EDIT',
        });
      } else {
        await postFetch({
          url: `${
            'project' in data
              ? projectAccountingNoteUrl
              : clientAccountingNoteUrl
          }/${data?.id}`,
          body: formData,
          intl,
          label: 'ACCOUNTING_NOTES',
        });
      }
      if (!fetchNotes) {
        return;
      }
      fetchNotes();
    }
    handleAddChanged();
    reset({
      title: '',
      noteContent: '',
    });
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleAddChanged}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: editClicked
                ? `ACCOUNTING_NOTES.EDIT_DIALOG_TITLE`
                : `ACCOUNTING_NOTES.DIALOG_TITLE`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label="ACCOUNTING_NOTES.TITLE"
                name="title"
                control={control}
                required
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                label="ACCOUNTING_NOTES.NOTE_CONTENT"
                name="noteContent"
                control={control}
                variant="outlined"
                required
                isMulti
              />
            </GridItem>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="saveButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `ACCOUNTING_NOTES.DIALOG_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default AccountingNoteDialog;
