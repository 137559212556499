import React from 'react';
import Menu from './Menu';
import MenuLink from './MenuLink';

interface Props {
  onClick: () => void;
  anchorEl?: Element | ((element: Element) => Element) | null;
}

const AppsMenu = ({ onClick, anchorEl }: Props) => (
  <Menu anchorEl={anchorEl} onClose={onClick}>
    <MenuLink
      label="WeJIT"
      href="https://we.jit.team/"
      id="weJitButton"
      onClick={onClick}
    />
    <MenuLink
      label="Confluence"
      href="https://jit-internal.atlassian.net/wiki/home"
      id="confluenceButton"
      onClick={onClick}
    />
    <MenuLink
      label="Jira"
      href="https://jit-internal.atlassian.net/jira/your-work"
      id="jiraButton"
      onClick={onClick}
    />
    <MenuLink
      label="Atlas"
      href="https://atlas.jit.team/login"
      id="atlasButton"
      onClick={onClick}
    />
    <MenuLink
      label="GitLab"
      href="https://gitlab.jit.team/"
      id="gitLabButton"
      onClick={onClick}
    />
    <MenuLink
      label="Sphinx"
      href="https://sphinx.jit.team"
      id="sphinxButton"
      onClick={onClick}
    />
  </Menu>
);

export default AppsMenu;
