import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Block } from '@material-ui/icons';
import { manualAcceptanceUrl } from 'router/url';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import ManualAcceptanceDialog from 'components/Dialog/ManualAcceptanceDialog';
import {
  AddIcon,
  Checkbox,
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TablePagination,
  TableRow,
} from 'components/Table/Table.css';
import { renderActionIcons } from 'components/Table/tableUtils';
import { RuleProps, TableAdminPanelProps } from 'components/Table/types';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import ToolTipCell from 'components/ToolTipCell';
import { SpinnerWrapper } from 'modules/AdminPanel/AdminPanel.css';
import { MANUAL_ACCEPTANCE_HEAD_DATA } from 'modules/AdminPanel/static_data';
import { AssignButton, GridWrapper } from 'modules/WorkTime/WorkTime.css';
import {
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { deleteFetch, getFetch, putFetch } from 'utils/fetchFunctions';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { useEditIcon } from 'utils/hooks/useEditIcon';
import { usePagination } from 'utils/hooks/usePagination';
import { useToggleState } from 'utils/hooks/useToggleState';

const ManualAcceptanceTable = ({ head }: TableAdminPanelProps) => {
  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
  } = usePagination({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 500,
  });

  const intl = useIntl();

  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);

  useEffect(() => {
    fetchEmployeeList(setEmployeesData);
    fetchClientList(setClientsData);
    fetchProjectList(setProjectsData);
  }, []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RuleProps[]>([]);
  const [dialogData, setDialogData] = useState<RuleProps>();
  const [showConfimationDialog, handleShowConfimationDialog] =
    useToggleState(false);
  const [addChecked, handleAddChanged] = useToggleState(false);
  const [editClicked, renderEditIcon] = useEditIcon(
    addChecked,
    handleAddChanged,
    setDialogData,
  );

  const returnQueryString = useCallback(
    (pageNumber: number, pageSize: number) =>
      `${manualAcceptanceUrl}?page=${pageNumber}&size=${pageSize || ''}`,
    [],
  );

  const fetchManualAcceptanceData = useCallback(async () => {
    setLoading(true);

    const data = await getFetch({
      url: returnQueryString(debouncedPage, pagination.pageSize),
    });
    setElementsCount(data?.totalElements);
    setData(data?.content);

    setLoading(false);
  }, [debouncedPage, pagination.pageSize, returnQueryString, setElementsCount]);

  useEffect(() => {
    fetchManualAcceptanceData();
  }, [fetchManualAcceptanceData]);

  const deleteRule = useCallback(
    async (ruleId: number) => {
      await deleteFetch({
        url: `${manualAcceptanceUrl}/${ruleId}`,
        intl,
        label: 'ACCEPTANCE_RULE.DELETE',
      });
      await fetchManualAcceptanceData();
    },
    [fetchManualAcceptanceData, intl],
  );

  const handleSubmitDelete = useCallback(() => {
    handleShowConfimationDialog();
    !!dialogData && deleteRule(dialogData.id);
  }, [deleteRule, dialogData, handleShowConfimationDialog]);

  const onCheckboxCheck = useCallback(
    async (event, entry: RuleProps, type: string) => {
      switch (type) {
        case 'ifOvertime':
          entry.ifOvertime = event.target.checked;
          break;
        case 'ifWorktimeOnHoliday':
          entry.ifWorktimeOnHoliday = event.target.checked;
          break;
        case 'ifWorktimeOnWeekend':
          entry.ifWorktimeOnWeekend = event.target.checked;
          break;
        case 'ifECMinimalBreakViolated':
          entry.ifECMinimalBreakViolated = event.target.checked;
          break;
        case 'alwaysManualApproval':
          entry.alwaysManualApproval = event.target.checked;
          entry.ifOvertime = event.target.checked;
          entry.ifWorktimeOnHoliday = event.target.checked;
          entry.ifWorktimeOnWeekend = event.target.checked;
          entry.ifECMinimalBreakViolated = event.target.checked;
          break;
      }
      await putFetch({
        url: `${manualAcceptanceUrl}/${entry.id}`,
        body: entry,
        intl,
        label: 'ACCEPTANCE_RULE.EDIT',
      });
      fetchManualAcceptanceData();
    },
    [fetchManualAcceptanceData, intl],
  );

  const getLeaderEntry = (entry: RuleProps) => {
    if (entry.leaders) {
      return entry.leaders
        ?.map((leader) => `${leader.name} ${leader.surname}`)
        .join(', ');
    }
    return '-';
  };

  const renderEmptyDataInfo = useMemo(() => {
    return (
      <Grid container spacing={0} direction="column" alignItems="center">
        {!data ? (
          <>
            <Grid item xs={6}>
              <Block />
            </Grid>
            <Grid item xs={5}>
              <p>
                {intl.formatMessage({
                  id: 'ADMIN_PANEL.DATA_UNAVAILABLE',
                })}
              </p>
            </Grid>
          </>
        ) : (
          <SpinnerWrapper>
            <CircularProgress size={100} />
          </SpinnerWrapper>
        )}
      </Grid>
    );
  }, [data, intl]);

  if (!data) {
    return renderEmptyDataInfo;
  }
  return (
    <>
      <GridWrapper container spacing={1} justify="flex-end" alignItems="center">
        <Grid item xs={12} sm={10} />
        <Grid item xs={12} sm={2}>
          <AssignButton
            id="addRuleButton"
            disabled={loading}
            endIcon={<AddIcon />}
            size="large"
            onClick={handleAddChanged}
          >
            {intl.formatMessage({
              id: 'ACCEPTANCE_RULE.ADD',
            })}
          </AssignButton>
        </Grid>
      </GridWrapper>

      <TableContainer component={Paper} $loading={loading}>
        {!!pagination.pageSize && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${intl.formatMessage({
                id: 'TABLE.PAGINATION.OF_COUNT',
              })} ${count}`
            }
            rowsPerPageOptions={[]}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNumber}
            count={pagination.totalElements}
            //@ts-ignore
            component={'div'}
          />
        )}
        <TableComponent>
          <TableLoadingWrapper loading={loading}>
            <>
              <TableHeadComponent>
                <TableRow $main>
                  {!loading &&
                    MANUAL_ACCEPTANCE_HEAD_DATA.map((headData, index) => (
                      <TableCell
                        align={headData.align}
                        width={headData.width}
                        key={`${index} - ${headData.key}`}
                      >
                        {headData.tooltip ? (
                          <ToolTipCell
                            id={`${headData.key}_tooltip`}
                            tooltip={intl.formatMessage({
                              id: headData.tooltip,
                            })}
                            text={intl.formatMessage({ id: headData.label })}
                          />
                        ) : (
                          intl.formatMessage({ id: headData.label })
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHeadComponent>

              <TableBody>
                {data ? (
                  data.map((entry: RuleProps, index: number) => (
                    <TableRow key={` - ${index}`} index={index}>
                      <TableCell>{entry.client?.name || '-'}</TableCell>
                      <TableCell>{entry.project?.name || '-'}</TableCell>
                      <TableCell>
                        {entry.employee
                          ? `${entry.employee.name} ${entry.employee.surname}`
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          id="alwaysManualApprovalCheckbox"
                          onChange={(event) =>
                            onCheckboxCheck(
                              event,
                              entry,
                              'alwaysManualApproval',
                            )
                          }
                          checked={entry.alwaysManualApproval}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          id="overTimeCheckbox"
                          onChange={(event) =>
                            onCheckboxCheck(event, entry, 'ifOvertime')
                          }
                          checked={entry.ifOvertime}
                          disabled={entry.alwaysManualApproval}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          id="worktimeOnWeekendsCheckbox"
                          onChange={(event) =>
                            onCheckboxCheck(event, entry, 'ifWorktimeOnWeekend')
                          }
                          checked={entry.ifWorktimeOnWeekend}
                          disabled={entry.alwaysManualApproval}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          id="holidaysCheckbox"
                          onChange={(event) =>
                            onCheckboxCheck(event, entry, 'ifWorktimeOnHoliday')
                          }
                          checked={entry.ifWorktimeOnHoliday}
                          disabled={entry.alwaysManualApproval}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          id="ifECMinimalBreakViolatedCheckbox"
                          onChange={(event) =>
                            onCheckboxCheck(
                              event,
                              entry,
                              'ifECMinimalBreakViolated',
                            )
                          }
                          checked={entry.ifECMinimalBreakViolated}
                          disabled={entry.alwaysManualApproval}
                        />
                      </TableCell>
                      <TableCell>{getLeaderEntry(entry)}</TableCell>
                      <TableCell align="center">
                        {renderActionIcons({
                          renderEditIcon,
                          isAllowedForActions: true,
                          setDialogData,
                          index,
                          data,
                          intl,
                          handleShowConfimationDialog,
                          entry,
                          isDeletable: true,
                        })}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align={'center'} colSpan={head.length}>
                      <Block />
                      <p>
                        {intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
        {!!pagination.pageSize && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${intl.formatMessage({
                id: 'TABLE.PAGINATION.OF_COUNT',
              })} ${count}`
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNumber}
            count={pagination.totalElements}
            //@ts-ignore
            component="div"
          />
        )}
      </TableContainer>
      <ConfirmationDialog
        confirmMessage="MODAL.BUTTON.DELETE"
        addChecked={showConfimationDialog}
        handleAddChanged={handleShowConfimationDialog}
        handleSubmit={handleSubmitDelete}
        title={intl.formatMessage({
          id: 'ACCEPTANCE_RULE.DELETE_DIALOG_TITLE',
        })}
        content={intl.formatMessage({
          id: 'ACCEPTANCE_RULE.DELETE_DIALOG_CONTENT',
        })}
      />
      {addChecked && (
        <ManualAcceptanceDialog
          addChecked={addChecked}
          handleAddChanged={handleAddChanged}
          editClicked={editClicked}
          data={dialogData}
          fetchRules={fetchManualAcceptanceData}
          employeesData={employeesData}
          clientsData={clientsData}
          projectsData={projectsData}
        />
      )}
    </>
  );
};

export default ManualAcceptanceTable;
