import { IntlShape } from 'react-intl';
import { RadioData } from 'components/ControlledRadioInput/types';
import { TripBillabilityStatus } from 'modules/BusinessTrips/types';

export const getBillingStatusOptions = (intl: IntlShape): RadioData[] => [
  {
    id: 'fullyBillable',
    value: TripBillabilityStatus.YES,
    label: intl.formatMessage({ id: 'BUSINESS_TRIP.BILLABILITY.YES' }),
  },
  {
    id: 'notBillable',
    value: TripBillabilityStatus.NO,
    label: intl.formatMessage({ id: 'BUSINESS_TRIP.BILLABILITY.NO' }),
  },
  {
    id: 'partlyBillable',
    value: TripBillabilityStatus.PARTLY,
    label: intl.formatMessage({ id: 'BUSINESS_TRIP.BILLABILITY.PARTLY' }),
  },
];
