import {
  CalculatorTransportationDataType,
  CalculatorTransportationOptions,
} from 'modules/BusinessTrips/types';

export const CALCULATOR_TRANSPORTATION_OPTIONS = [
  {
    value: CalculatorTransportationOptions.PLANE,
    name: 'BUSINESS_TRIP.PLANE',
  },
  {
    value: CalculatorTransportationOptions.TRAIN,
    name: 'BUSINESS_TRIP.TRAIN',
  },
  {
    value: CalculatorTransportationOptions.PERSONAL_CAR,
    name: 'BUSINESS_TRIP.PERSONAL_CAR',
  },
  {
    value: CalculatorTransportationOptions.COMPANY_CAR,
    name: 'BUSINESS_TRIP.COMPANY_CAR',
  },
  {
    value: CalculatorTransportationOptions.SHIP,
    name: 'BUSINESS_TRIP.SHIP',
  },
  {
    value: CalculatorTransportationOptions.BUS,
    name: 'BUSINESS_TRIP.BUS',
  },
];

export const DEFAULT_CURRENCY = [
  {
    value: 0,
    name: 'PLN',
  },
];

export const EMPTY_TRANSPORTATION_FIELD: CalculatorTransportationDataType = {
  transportationType: null,
  transportationCosts: null,
  transportationCurrency: null,
};
