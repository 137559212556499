import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { MissingInfoType } from '../MissingInfoDialog/types';

export const getMissingInfoToShow = (
  type: MissingInfoType,
  missingInfo?: string[],
) => {
  switch (type) {
    case MissingInfoType.businessTrip:
    case MissingInfoType.summary:
      return missingInfo?.map(
        (el) => `MISSING_INFO_IN_USER_PROFILE.TITLE.${getFormattedName(el)}`,
      );
    case MissingInfoType.companyInfo:
      return ['MISSING_INFO_IN_COMPANY_INFO.TITLE_MESSAGE'];
    case MissingInfoType.notInvoicedBT:
      return ['MISSING_INVOICE_FOR_BUSINESS_TRIP.MESSAGE'];
    case MissingInfoType.quickReport:
      return ['MISSING_INFO_IN_USER_PROFILE.TITLE.QUICK_REPORT'];
    case MissingInfoType.newBenefitCost:
      return ['MISSING_NEW_BENEFIT_COST_ACCEPTANCE'];
  }
};
