import { useIntl } from 'react-intl';
import { RequestsTableHeadProps } from '../types';
import { StyledTableCell } from '../HolidaysTab.css';

const LeaveRequestsTableHead = ({ titles }: RequestsTableHeadProps) => {
  const intl = useIntl();
  return (
    <>
      {titles.map(({ name, isFirstColumn }, index) => (
        <StyledTableCell
          align={isFirstColumn ? 'left' : 'center'}
          key={`${index}-${name}`}
        >
          {intl.formatMessage({ id: name })}
        </StyledTableCell>
      ))}
    </>
  );
};

export default LeaveRequestsTableHead;
