export const apiUrl = '';

// AccountController
export const employeesUrl = '/api/accounts/names';
export const userProfileUrl = '/api/accounts';
export const userInfoUrl = '/api/accounts/user';
export const employersUrl = '/api/employers';
export const accountFilledInfoUrl = '/api/accounts/user/accountInfo';
export const companyFilledInfoUrl =
  '/api/accounts/user/profile/company/is-complete-and-vat-payer';
export const invoiceUrl = '/api/invoices';
export const userProfileCompanyUrl = '/api/accounts/user/profile/company';
export const benefitCostAcceptanceUrl =
  '/api/accounts/user/profile/accept-benefits-cost-summary';
export const accountLeaderStatusUrl = '/api/accounts/leader';
// AuthTokenController
export const loginUrl = '/api/authentication/login';

//BankHolidayController
export const bankHolidaysUrl = '/api/bank-holidays';

//BusinessTripReportsController
export const businessTripReportsUrl =
  '/api/business-trip-reports/overall-report';
export const businessTripByUserUrl =
  '/api/business-trip-reports/overall-report/accounts/';
export const businessTripByClientUrl =
  '/api/business-trip-reports/overall-report/clients/';
export const businessTripByProjectUrl =
  '/api/business-trip-reports/overall-report/projects/';
export const notInvoicedBusinessTripUrl = '/api/business-trips/not-invoiced';

//BusinessTripController
export const businessTripCurrenciesUrl = '/api/business-trips/currencies';
export const businessTripsUrl = '/api/business-trips';

//CountryDietController
export const countryDietsUrl = '/api/country-diets';

// ClientController
export const clientsUrl = '/api/clients';
export const clientsDetailsUrl = '/api/clients/details';
export const clientsPagedUrl = '/api/clients/paged';
export const clientCustomFieldUrl = '/api/clients/custom-fields';
export const clientAccountingNoteUrl = '/api/clients/accounting-notes';
export const clientPermissionsUrl = '/api/clients/permission';

// ConfigController
export const businessMonthYearUrl = '/api/configuration/business-month-year';

// IssueCollectorController
export const bugReportIssueCollectorScript =
  '/api/issue-collectors/fetch-scripts';

// ProjectController
export const projectsUrl = '/api/projects';
export const projectsPagedUrl = '/api/projects/paged';
export const projectsByNamesUrl = '/api/projects/details';
export const projectsOrderedByNameUrl = '/api/projects/ordered';
export const workTimeAssignProjectUrl = '/api/projects/assign-accounts';
export const workTimeRemoveProjectAssignmentUrl = '/api/projects/assignments';
export const projectCustomFieldUrl = '/api/projects/custom-fields';
export const projectAccountingNoteUrl = '/api/projects/accounting-notes';
export const projectActiveTypes = '/api/projects/categories';
export const projectUpdateUrl = '/api/projects';
export const projectPermissionsUrl = '/api/projects/permission';

// SummaryController
export const workTimeSummaryUrl = '/api/summaries';
export const workTimeApproveUrl = '/api/summaries';

// TeamLeaderAssignmentController
export const findTeamLiderList = '/api/teamleaders/accounts';
export const teamLeaderAssignmentsUrl = '/api/teamleaders/assign';

// TimesheetController
export const timesheetsUrl = '/api/timesheets';
export const timesheetCreationUrl = '/api/timesheets';
export const timesheetsApproveUrl = '/api/timesheets/approve';
export const timesheetsUndoApprovalUrl = '/api/timesheets/cancel-approval';
export const timesheetsUndoPartialApprovalUrl =
  '/api/timesheets/cancel-partial-approval';
export const timesheetsApproveReminder = '/api/timesheets/approval-reminders';
export const timesheetsBatchApproveReminder =
  '/api/timesheets/reminders/teamleaders';
export const remindEmployeesAboutTimesheets =
  '/api/timesheets/reminders/employees';
export const workTimeQuickReportUrl = 'quick-reports';
export const workTimeFullReportUrl = 'full-reports';
export const workTimeMultiplicatorUrl = '/api/timesheets/multiplicators';
export const timesheetCustomFieldSufixUrl = 'custom-fields';
export const timesheetCustomFieldUrl = '/api/timesheets/custom-fields';
export const timesheetUserReminderUrl = '/api/timesheets/endpoints-history';

// WorkTimeController
export const reportsUrl = '/api/work-time';
export const workTimeByUserUrl = '/api/work-time/report/accounts/';
export const workTimeByClientUrl = '/api/work-time/report/clients/';
export const workTimeByProjectUrl = '/api/work-time/report/projects/';
export const manualAcceptanceUrl = '/api/manual-approval-rules';

// TimesheetExcludedAccountController
export const exlusionsUrl = '/api/exclusions/';

// HolidayRequestsController
export const allEmployeesHolidayRequestsUrl = '/api/vacations/history';
export const leaveRequestUrl = '/api/vacations';
export const activeRequestsUrl = 'history?editable=true';
export const requestsHistoryUrl = 'history?editable=false';

// BenefitController
export const benefitsUrl = '/api/v2/benefits';
export const benefitsCostsSummaryUrl = 'excel-cost-info';
export const userBenefitsUrl = '/api/v2/benefits/forms/user';
export const userBenefitResignUrl = '/api/v2/benefits/forms';

// AdminController
export const permissionsUrl = '/api/admin/permissions';
