export interface SelectedHolidayType {
  name: string;
  balance: number;
  dateOfRequest: string;
  requestedDates: string[];
  requestedDaysCount: number;
  type: HolidayTypes;
  status: HolidayStatus;
  comment: string;
}
export interface HolidayRequestProps {
  account: { id: number; name: string; surname: string; username: string };
  attachments?: {
    contentType: string;
    id: number;
    originalFileName: string;
    uploadDate: string;
  };
  comments?: Array<{
    id: number;
    title: string;
    message: string;
    creationDateTime: string;
  }>;
  creationDateTime: string;
  dateFrom: string;
  dateTo: string;
  editable: boolean;
  enovaUpdates?: { id: number; creationDateTime: string };
  id: number;
  modificationDateTime?: number;
  requestedDays: number;
  status: HolidayStatus;
  teamLeaderApproved: boolean;
  type: HolidayTypes;
}
export interface DetailsDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedHoliday: HolidayRequestProps;
}
export interface SelectedHolidayGridContent {
  id: number;
  title: string;
  message: string;
  creationDateTime: string;
}
export enum HolidayTypes {
  ANNUAL_PAID_LEAVE = 'annual paid leave',
  EMERGENCY_PAID_LEAVE = 'emergency paid leave',
  FATHERLY_PAID_LEAVE = 'fatherly paid leave',
  MATERNITY_PAID_LEAVE = 'maternity paid leave',
  PARENTAL_PAID_LEAVE = 'parental paid leave',
  CHILD_EDUCATION_PAID_LEAVE = 'child education paid leave',
  SPECIAL_PAID_LEAVE = 'special paid leave',
  CHILD_CARE_PAID_LEAVE = 'child care paid leave',
  SICK_PAID_LEAVE = 'sick paid leave',
  CARE_PAID_LEAVE = 'care paid paid leave',
  UNPAID_LEAVE = 'unpaid paid leave',
}

export enum GridElementTypes {
  Info = 'info',
  Status = 'status',
  Comment = 'comment',
  Type = 'type',
  Name = 'name',
}

export enum HolidayStatus {
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  IN_PROGRESS = 'IN_PROGRESS',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface StatusStyleProps {
  color: HolidayStatus;
}
