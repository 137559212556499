import { useContext } from 'react';
import { BusinessTripsContext } from 'modules/BusinessTrips/BusinessTripManagerContext/Context';
import { SummaryProps } from '../types';
import {
  renderBusinessTripAccommodationData,
  renderBusinessTripGeneralInfoData,
  renderBusinessTripOtherData,
  renderBusinessTripTravelData,
} from '../utils';
import AdditionalAccountsSection from './components/AdditionalAccountsSection';
import SummarySection from './components/SummarySection';
import { SummaryContainer } from '../BusinessTripDialog.css';

const Summary = ({ additionalAccounts }: SummaryProps) => {
  const {
    businessTripsManagerState: { formState },
  } = useContext(BusinessTripsContext);

  const isAccommodation = Number(formState.numberOfNights) > 0;

  const BusinessTripGeneralInfoData =
    renderBusinessTripGeneralInfoData(formState);
  const BusinessTripTravelData = renderBusinessTripTravelData(formState);
  const BusinessTripAccommodationData =
    renderBusinessTripAccommodationData(formState);
  const BusinessTripOtherData = renderBusinessTripOtherData(formState);

  return (
    <SummaryContainer>
      <SummarySection
        businessTripSectionData={BusinessTripGeneralInfoData}
        title="BUSINESS_TRIP.DIALOG_SECTION.GENERAL_INFO"
      />
      <SummarySection
        businessTripSectionData={BusinessTripTravelData}
        title="BUSINESS_TRIP.DIALOG_SECTION.TRAVEL"
      />
      {isAccommodation && (
        <SummarySection
          businessTripSectionData={BusinessTripAccommodationData}
          title="BUSINESS_TRIP.DIALOG_SECTION.ACCOMMODATION"
        />
      )}
      <SummarySection
        businessTripSectionData={BusinessTripOtherData}
        title="BUSINESS_TRIP.DIALOG_SECTION.OTHER"
      />
      <AdditionalAccountsSection
        additionalAccounts={additionalAccounts}
        title="BUSINESS_TRIP.DIALOG_SECTION.ADDITIONAL_ACCOUNTS"
      />
    </SummaryContainer>
  );
};

export default Summary;
