import { Tooltip, Typography, TypographyProps } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions, {
  DialogActionsProps,
} from '@material-ui/core/DialogActions';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import { Close } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import palette from 'assets/palette';
import styled from 'styled-components';
import ControlledRadioInput from 'components/ControlledRadioInput/ControlledRadioInput';
import { ControlledRadioInputProps } from 'components/ControlledRadioInput/types';

export const GridWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    padding-top: 0.5rem;
  }
`;

export const GridWrapperGapped = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    gap: 1rem;
  }
`;

export const GridItem = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    padding: 0.5rem;
    width: 100vw;
    text-align: start;
  }
`;

export const GridItemCentered = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    padding: 0.5rem;
    width: 100vw;
  }
`;

export const GridItemFlex = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    padding: 0.5rem;
    width: 100vw;
    text-align: start;
    display: flex;
  }
`;

export const DialogWrapper = styled(Dialog)<DialogProps>`
  & .MuiPaper-root {
    text-align: center;
    overflow-y: hidden;
  }
`;

export const DialogContentWrapper = styled(DialogContent)<DialogContentProps>`
  &.MuiDialogContent-root {
    padding: 0.5rem;
  }
`;

export const DialogContentWrapperFlex = styled(
  DialogContent,
)<DialogContentProps>`
  &.MuiDialogContent-root {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    > :first-child {
      padding: 15px;
    }
  }
`;

export const DialogActionWrapper = styled(DialogActions)<DialogActionsProps>`
  &.MuiDialogActions-root {
    padding: 0.5rem;
  }
`;

export const DialogTitleWrapper = styled(DialogTitle)<DialogTitleProps>`
  &.MuiDialogTitle-root {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const CloseIcon = styled(Close)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
  }
`;

export const DialogParagraph = styled.p`
  white-space: pre-wrap;
  margin-right: 0.8em;
  margin-left: 0.8em;
  font-size: 1.15rem;
  white-space: pre-wrap;
`;

export const DownloadFileIcon = styled(GetAppIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    margin-left: 0.2rem;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Note = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
`;

export const PreviouslyUploadedSubheading = styled.h5``;

export const GridWithTooltip = styled(GridItem)`
  &.MuiGrid-root {
    position: relative;
  }
`;

export const CustomProjectTooltip = styled(Tooltip)`
  &.MuiSvgIcon-root {
    position: absolute;
    left: 0;
    top: 33%;
  }
`;

export const CustomControlledRadioInput = styled(
  ControlledRadioInput,
)<ControlledRadioInputProps>`
  &.MuiFormControl-root {
    width: 100%;
    padding: 1rem 0;
  }
`;

export const CustomTypography = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    text-align: left;
    margin-top: 1rem;
  }
`;

export const GridLoaderItem = styled(Grid)<GridProps>`
  &.MuiGrid-root {
    padding: 1rem;
  }
`;
