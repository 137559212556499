import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Block } from '@material-ui/icons';
import { GlobalContext } from 'globalContext/GlobalContext';
import { UserContext } from 'globalContext/UserContext';
import { clientCustomFieldUrl } from 'router/url';
import AccountingNotesComponent from 'components/AccountingNotesList';
import CustomFieldsList from 'components/CustomFieldsList';
import AccountingNoteDialog from 'components/Dialog/AccountingNoteDialog/AccountingNoteDialog';
import ClientDialog from 'components/Dialog/ClientDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import CustomFieldDialog from 'components/Dialog/CustomFieldDialog';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import { displayLabelRows } from 'utils/displayLabelRows';
import { returnRoleInclusion } from 'utils/helpers';
import { useEditIcon } from 'utils/hooks/useEditIcon';
import { useIndexIcon } from 'utils/hooks/useIndexIcon';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import {
  CustomFieldsRow,
  renderActionIcons,
  renderTableCellData,
} from './tableUtils';
import {
  ClientProps,
  CustomField,
  TableClientProps as TableProps,
} from './types';
import {
  BoldedCellText,
  DescriptionText,
  DetailsTitle,
  Paper,
  ProjectDetailsBox,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TablePagination,
  TableRow,
} from './Table.css';

const ClientsTable = ({
  fetchClients,
  setRowsPerPage,
  setCurrentPage,
  withPagination = false,
  loading = false,
  currentPage = 0,
  pageCount = 1,
  limit = 10,
  head,
  data,
  client,
  addChecked,
  handleAddChanged,
  deleteCustomField,
  changeClientStatus,
  isAllowedForActions,
}: TableProps) => {
  const [showDescription, selectedId, renderIcon, resetIndex] =
    useIndexIcon(client);
  const [dialogData, setDialogData] = useState<ClientProps>();
  const [customFieldData, setCustomFieldData] = useState<CustomField>();
  const [addCustomField, handleAddCustomField] = useToggleState(false);
  const [addAccountingNote, handleAddAccountingNote] = useToggleState(false);
  const [activateClicked, handleActivateClicked] = useToggleState(false);
  const [showConfimationDialog, handleShowConfimationDialog] =
    useToggleState(false);
  const [editClicked, renderEditIcon] = useEditIcon<ClientProps[]>(
    addChecked,
    handleAddChanged,
    setDialogData,
  );

  const [editCustomFieldClicked, renderCustomFieldEditIcon] = useEditIcon<
    CustomField[]
  >(addCustomField, handleAddCustomField, setCustomFieldData);

  const intl = useIntl();
  const { teamLeader: isTeamLeader, roles } = useContext(UserContext);
  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);

  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]) || !!isTeamLeader,
    [isTeamLeader, roles],
  );

  const renderCellData = useCallback(
    (entry: ClientProps, index: number, key: string) => {
      switch (key) {
        case 'description':
          return renderIcon(index);
        case 'clientName':
          return index === selectedId && showDescription ? (
            <BoldedCellText>{entry[key]}</BoldedCellText>
          ) : (
            renderTableCellData(entry[key])
          );
        case 'action':
          return renderActionIcons<ClientProps[]>({
            isJitOther: entry.clientName === 'JIT Other',
            renderEditIcon,
            setDialogData,
            index,
            data,
            intl,
            handleShowConfimationDialog,
            isArchivable: true,
            isDeletable: false,
            elementToRemove: 'client',
            entry,
            handleActivateClicked,
            isAllowedForActions,
          });
        default:
          return renderTableCellData(entry[key]);
      }
    },
    [
      data,
      handleActivateClicked,
      handleShowConfimationDialog,
      intl,
      isAllowedForActions,
      renderEditIcon,
      renderIcon,
      selectedId,
      showDescription,
    ],
  );

  const handleSubmitActivate = useCallback(() => {
    handleShowConfimationDialog();
    handleActivateClicked();
    !!dialogData && changeClientStatus(dialogData.id);
    resetIndex();
  }, [
    changeClientStatus,
    dialogData,
    handleActivateClicked,
    handleShowConfimationDialog,
    resetIndex,
  ]);

  const handleActiveStatusChanged = () =>
    activateClicked && handleActivateClicked();

  const onAddCustomFieldClicked = useCallback(
    (index: number) => {
      handleAddCustomField();
      setDialogData(data[index]);
    },
    [data, handleAddCustomField],
  );

  const onAddAccountingNoteClicked = useCallback(
    (index: number) => {
      handleAddAccountingNote();
      setDialogData(data[index]);
    },
    [data, handleAddAccountingNote],
  );

  return (
    <>
      <TableContainer component={Paper} $loading={loading}>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
        <TableComponent>
          <TableLoadingWrapper loading={loading}>
            <>
              <TableHeadComponent>
                <TableRow $main>
                  {head.map((headData, index) => (
                    <TableCell
                      align={headData.align}
                      key={`${index} - ${headData.key}`}
                    >
                      {intl.formatMessage({ id: headData.label })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeadComponent>

              <TableBody>
                {data.length ? (
                  data.map((entry, index) => (
                    <>
                      <TableRow
                        key={`row - ${entry} - ${index}`}
                        index={index}
                        $selected={selectedId === index}
                      >
                        {head.map(({ key }, headIndex) => (
                          <TableCell
                            align={
                              head.length ? head[headIndex].align : undefined
                            }
                            key={`${entry}-${index}-${headIndex}-${currentLocale}`}
                            component="th"
                            scope="row"
                          >
                            {renderCellData(entry, index, key)}
                          </TableCell>
                        ))}
                      </TableRow>
                      {selectedId === index && (
                        <TableRow
                          key={`description - ${entry} - ${index}`}
                          $selected
                        >
                          <TableCell colSpan={6}>
                            <Grid
                              container
                              alignItems="flex-start"
                              justify="center"
                              spacing={2}
                            >
                              {showDescription && (
                                <Grid xs={12} item>
                                  <ProjectDetailsBox>
                                    <DetailsTitle>
                                      {intl.formatMessage({
                                        id: 'ACCORDION.DESCRIPTION',
                                      })}
                                    </DetailsTitle>
                                    <DescriptionText>
                                      {entry.description ||
                                        intl.formatMessage({
                                          id: 'SUMMARY.NONE',
                                        })}
                                    </DescriptionText>
                                  </ProjectDetailsBox>
                                  <ProjectDetailsBox>
                                    <CustomFieldsRow
                                      intl={intl}
                                      onAddCustomFieldClicked={
                                        onAddCustomFieldClicked
                                      }
                                      index={index}
                                      isAccountPrivileged={isAccountPrivileged}
                                    />
                                    {entry.clientCustomFields?.length > 0 ? (
                                      <CustomFieldsList
                                        data={entry.clientCustomFields}
                                        deleteCustomField={deleteCustomField}
                                        url={clientCustomFieldUrl}
                                        isAccountPrivileged={
                                          isAccountPrivileged
                                        }
                                        renderCustomFieldEditIcon={
                                          renderCustomFieldEditIcon
                                        }
                                      />
                                    ) : (
                                      <DescriptionText>
                                        {intl.formatMessage({
                                          id: 'SUMMARY.NONE',
                                        })}
                                      </DescriptionText>
                                    )}
                                  </ProjectDetailsBox>
                                  {isAccountPrivileged && (
                                    <AccountingNotesComponent
                                      notes={entry.accountingNotes}
                                      onAddAccountingNoteClicked={
                                        onAddAccountingNoteClicked
                                      }
                                      index={index}
                                      fetchData={fetchClients}
                                      data={data[index]}
                                    />
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center">
                      <Block />
                      <p>
                        {intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component="div"
          />
        )}
      </TableContainer>
      {showConfimationDialog && (
        <ConfirmationDialog
          addChecked={showConfimationDialog}
          handleAddChanged={handleShowConfimationDialog}
          activateClicked={activateClicked}
          handleSubmit={handleSubmitActivate}
          handleActivateClicked={handleActiveStatusChanged}
          title={intl.formatMessage({
            id: `CLIENTS.${
              activateClicked ? 'DEACTIVATE' : 'DELETE'
            }_DIALOG_TITLE`,
          })}
          content={intl.formatMessage({
            id: `CLIENTS.${
              activateClicked ? 'DEACTIVATE' : 'DELETE'
            }_DIALOG_CONTENT`,
          })}
          data={dialogData}
        />
      )}
      {addCustomField && (
        <CustomFieldDialog
          addChecked={addCustomField}
          handleAddChanged={handleAddCustomField}
          fetchData={fetchClients}
          data={editCustomFieldClicked ? customFieldData : dialogData}
          editClicked={editCustomFieldClicked}
        />
      )}
      {addChecked && (
        <ClientDialog
          fetchClients={fetchClients}
          addChecked={addChecked}
          handleAddChanged={handleAddChanged}
          editClicked={editClicked}
          data={dialogData}
        />
      )}
      {addAccountingNote && (
        <AccountingNoteDialog
          addChecked={addAccountingNote}
          handleAddChanged={handleAddAccountingNote}
          data={dialogData}
          fetchData={fetchClients}
        />
      )}
    </>
  );
};

export default ClientsTable;
