import BoxComponent, { BoxProps } from '@material-ui/core/Box';
import styled from 'styled-components';

export const BoxWrapper = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow: auto;
    min-height: calc(100vh - 200px);
  }
`;

export const BoxItem = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    padding-bottom: 1rem;
  }
`;

export const FieldWrapper = styled.div`
  padding-top: 5px;
`;
