import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import SaveIcon from '@material-ui/icons/Save';
import { timesheetCustomFieldUrl, userProfileUrl } from 'router/url';
import ControlledTextField from 'components/ControlledTextField';
import { postFetch } from 'utils/fetchFunctions';
import {
  ClientCustomFields,
  CustomFieldsTableProps,
  ProjectCustomFields,
} from './types';
import {
  BoldedClientTitleCell,
  BoldedProjectTitleCell,
  BoldedTableCell,
  Paper,
  StyledContainedButton,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
} from './Table.css';

const CustomFieldsTable = ({
  customFieldsData,
  fetchCustomFields,
  timesheetId,
  isDisabled,
  username,
  summarySettled,
}: CustomFieldsTableProps) => {
  const intl = useIntl();
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
  });
  const [fieldsSaved, setFieldsSaved] = useState(false);

  const onSubmit = handleSubmit(async (formData) => {
    if (username || timesheetId) {
      const data = Object.entries(formData).map(([k, v]) => ({
        id: k,
        value: v,
      }));
      const { ok } = await postFetch({
        url: username
          ? `${userProfileUrl}/${username}/profile/custom-fields`
          : `${timesheetCustomFieldUrl}/${timesheetId}`,
        body: data,
      });
      if (ok) {
        toast.success(
          intl?.formatMessage({ id: `CUSTOM_FIELDS_FORM.TOAST_ACCEPT` }),
        );
        fetchCustomFields();
      } else {
        toast.error(
          intl?.formatMessage({ id: `CUSTOM_FIELDS_FORM.TOAST_ACCEPT` }),
        );
      }
    }
  });

  useEffect(() => {
    reset();
  }, [reset, customFieldsData]);

  const saveCustomFields = useCallback(() => {
    if (!summarySettled && !fieldsSaved) {
      setFieldsSaved(true);
    }
  }, [summarySettled, fieldsSaved]);

  useEffect(() => {
    saveCustomFields();
  }, [saveCustomFields, timesheetId]);

  useEffect(() => {
    setFieldsSaved(false);
  }, [timesheetId]);

  return (
    <>
      {customFieldsData && (
        <form className="form" onSubmit={onSubmit}>
          <TableContainer component={Paper}>
            <TableComponent>
              <TableHeadComponent>
                <TableRow $main>
                  <TableCell key="custom-field-tile" width="60%" align="left">
                    {intl.formatMessage({
                      id: username
                        ? 'CUSTOM_FIELDS.TITLE_DEFAULT'
                        : 'CUSTOM_FIELDS.TITLE',
                    })}
                  </TableCell>
                  <TableCell
                    key="custom-field-button"
                    width="40%"
                    align="right"
                  >
                    <StyledContainedButton
                      id="saveDataButton"
                      disabled={
                        isDisabled || summarySettled || !formState.isValid
                      }
                      endIcon={<SaveIcon />}
                      onClick={onSubmit}
                      type="submit"
                    >
                      {intl.formatMessage({
                        id: 'CUSTOM_FIELDS.SAVE_BUTTON',
                      })}
                    </StyledContainedButton>
                  </TableCell>
                </TableRow>
              </TableHeadComponent>
              <TableBody>
                {customFieldsData?.map((client: ClientCustomFields) => (
                  <>
                    <TableRow
                      key={`custom-field-row-client-${client.clientName}`}
                    >
                      <BoldedClientTitleCell colSpan={2}>
                        {client?.clientName}
                      </BoldedClientTitleCell>
                    </TableRow>
                    {client?.fields?.map((field) => (
                      <TableRow key={`custom-field-row-value-${field.label}`}>
                        <BoldedTableCell
                          width="40%"
                          align="left"
                          key={`client-field-label-${field.label}-${field.id}`}
                        >
                          {field?.label}
                        </BoldedTableCell>
                        <TableCell
                          key={`client-field-value-${field.label}-${field.id}`}
                        >
                          <ControlledTextField
                            name={`${field.id}`}
                            control={control}
                            size="small"
                            isDisabled={isDisabled}
                            defaultValue={field.value ?? field.defaultValue}
                            validate
                            required
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {client?.projectsWithFields?.map(
                      (project: ProjectCustomFields) => (
                        <>
                          <TableRow
                            key={`custom-field-row-project-${project.projectName}`}
                          >
                            <BoldedProjectTitleCell colSpan={2}>
                              {`${project?.projectName}`}
                            </BoldedProjectTitleCell>
                          </TableRow>
                          {project?.fields?.map((projectField) => (
                            <TableRow
                              key={`project-field-row-value-${projectField.label}`}
                            >
                              <BoldedTableCell
                                width="40%"
                                align="left"
                                key={`project-field-label-${projectField.label}-${projectField.id}`}
                              >
                                {projectField?.label}
                              </BoldedTableCell>
                              <TableCell
                                key={`project-field-value-${projectField.label}-${projectField.id}`}
                              >
                                <ControlledTextField
                                  name={`${projectField.id}`}
                                  control={control}
                                  size="small"
                                  defaultValue={
                                    projectField.value ??
                                    projectField.defaultValue
                                  }
                                  isDisabled={isDisabled}
                                  validate
                                  required
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ),
                    )}
                  </>
                ))}
              </TableBody>
            </TableComponent>
          </TableContainer>
        </form>
      )}
    </>
  );
};

export default CustomFieldsTable;
