import { createTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import palette from 'assets/palette';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export default createTheme({
  palette: {
    primary: {
      main: palette.ACTION,
      light: palette.ACTION_LIGHTER,
      dark: palette.ACTION_DARK,
    },
    secondary: {
      main: palette.ACCENT_DARKER,
      light: palette.ACCENT_LIGHT,
      dark: palette.ACCENT_DARKEST,
    },
    action: {
      disabledBackground: palette.ACCENT_DARKEST,
      disabled: palette.ACCENT_DARKER,
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&$disabled:before': {
          borderBottomStyle: 'none',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        '&$disabled:before': {
          border: 'none',
        },
      },
    },
    MuiTableCell: {
      paddingNone: {
        padding: '0!important', //for material-table action column
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'white',
      },
    },
  },
});
