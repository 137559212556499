import { Grid, StepLabel, StepLabelProps, Stepper } from '@material-ui/core';
import styled from 'styled-components';

interface StyledStepLabelProps extends StepLabelProps {
  $isActiveStep?: boolean;
}

export const StyledStepper = styled(Stepper)`
  &.MuiStepper-root {
    padding: 0;
  }
`;

export const GridWithPadding = styled(Grid)`
  &.MuiGrid-container {
    padding: 16px;
  }
`;

export const StyledStepLabel = styled(StepLabel)<StyledStepLabelProps>`
  &.MuiStepLabel-root,
  &.MuiStepLabel-root.Mui-disabled {
    cursor: ${({ $isActiveStep }) => (!$isActiveStep ? 'pointer' : 'default')};
  }
`;
