import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
  &.MuiIconButton-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`;
