import { returnLeaveDays } from '.';

export const returnBankHolidays = (
  bankHolidaysArray: [{ date: string; title: string }],
) => {
  return bankHolidaysArray?.length > 0
    ? bankHolidaysArray
        .map((v) => `${returnLeaveDays([v.date])} - ${v.title}`)
        .sort()
        .join(', ')
    : '-';
};
