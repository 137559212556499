import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import { Telegram, Update } from '@material-ui/icons';
import { ContainedButton } from 'components/Button';
import {
  CloseIcon,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
} from 'components/Dialog/Dialog.css';
import { Props } from './types';
import {
  GridWithPadding,
  StyledStepLabel,
  StyledStepper,
} from './ControlledStepper.css';

const ControlledStepper = ({
  open = true,
  handleClose,
  checkValidation,
  handlePrevStep,
  handleSubmit,
  isValid,
  dialogTitle,
  stepTitles,
  stepsContent,
  maxWidth = 'sm',
  isSubmitDisabled = false,
  nextStep,
  previousStep,
  resetStep,
  isEditing,
  isLoading,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);

  const intl = useIntl();
  const isLast = activeStep === stepTitles.length - 1;

  const getStepContent = () => stepsContent[activeStep];

  const handleStepLabelClick = async (index: number) => {
    await checkValidation();
    if (isValid && index !== activeStep) {
      setActiveStep(index);
    }
  };

  const handleNext = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      await checkValidation();
      if (!isLast) {
        if (isValid) {
          if (nextStep) {
            setActiveStep(nextStep);
          } else {
            setActiveStep(activeStep + 1);
          }
        }
      } else {
        const ok = await handleSubmit();
        if (ok) {
          handleClose(e);
          setActiveStep(0);
        }
      }
    },
    [
      checkValidation,
      isLast,
      isValid,
      nextStep,
      activeStep,
      handleSubmit,
      handleClose,
    ],
  );

  const handlePrevious = useCallback(async () => {
    if (handlePrevStep) {
      handlePrevStep();
    }
    if (previousStep || previousStep === 0) {
      setActiveStep(previousStep);
    }
    if (isEditing) {
      await checkValidation();
      if (isValid) {
        setActiveStep(activeStep - 1);
      }
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [
    activeStep,
    checkValidation,
    handlePrevStep,
    isEditing,
    isValid,
    previousStep,
  ]);

  const handleReset = useCallback(() => {
    if (resetStep) {
      setActiveStep(0);
    }
  }, [resetStep]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const nextStepData = useMemo(() => {
    if (isLast && isEditing) {
      return { intl: 'UPDATE_DATA', icon: <Update />, id: 'updateButton' };
    } else if (isLast && !isEditing) {
      return {
        intl: 'APPROVE_AND_SEND',
        icon: <Telegram />,
        id: 'approveButton',
      };
    } else {
      return { intl: 'STEPPER.NEXT_STEP', icon: null, id: 'nextStepButton' };
    }
  }, [isEditing, isLast]);

  return (
    <DialogWrapper fullScreen={false} fullWidth maxWidth={maxWidth} open={open}>
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={10} item container justifyContent="flex-start">
            {dialogTitle}
          </Grid>
          <Grid xs={2} item container justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <GridWithPadding container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <StyledStepper activeStep={activeStep} alternativeLabel>
              {stepTitles.map(({ label, id }, index) => (
                <Step
                  key={id}
                  id={id}
                  onClick={() => handleStepLabelClick(index)}
                >
                  <StyledStepLabel $isActiveStep={index === activeStep}>
                    {label}
                  </StyledStepLabel>
                </Step>
              ))}
            </StyledStepper>
          </Grid>
          {getStepContent()}
          <Grid container item justifyContent="space-between" xs={12}>
            <Grid item>
              <ContainedButton
                color="secondary"
                disabled={!activeStep}
                onClick={handlePrevious}
                type="button"
                id="prevStepButton"
              >
                {intl.formatMessage({
                  id: 'STEPPER.PREVIOUS_STEP',
                })}
              </ContainedButton>
            </Grid>
            <Grid item>
              <ContainedButton
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleNext}
                disabled={isSubmitDisabled || isLoading}
                endIcon={nextStepData.icon}
                id={nextStepData.id}
              >
                {intl.formatMessage({ id: nextStepData.intl })}
              </ContainedButton>
            </Grid>
          </Grid>
        </GridWithPadding>
      </DialogContentWrapper>
    </DialogWrapper>
  );
};

export default ControlledStepper;
