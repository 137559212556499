import { useIntl } from 'react-intl';
import { TableCell } from '@material-ui/core';
import { changeDateFormatWithYear, showVacationDates } from 'utils/dateFormats';
import {
  MEDICAL_LEAVE_REQUESTS_OPTIONS,
  OTHER_LEAVE_REQUESTS_OPTIONS,
  STANDARD_LEAVE_REQUESTS_OPTIONS,
} from '../const';
import {
  LeaveTypes,
  MedicalLeaveTypes,
  OtherLeaveTypes,
  RequestStatusTypes,
  RequestTableCellsProps,
  StandardLeaveTypes,
} from '../types';
import { StatusTableCell } from '../HolidaysTab.css';

const LeaveRequestsTableCells = ({
  dateFrom,
  dateTo,
  creationDateTime,
  type,
  status,
}: RequestTableCellsProps) => {
  const intl = useIntl();

  const renderStatusName = (status: RequestStatusTypes) => {
    switch (status) {
      case RequestStatusTypes.Accepted:
        return 'LEAVE_REQUEST.STATUS.ACCEPTED';
      case RequestStatusTypes.Rejected:
        return 'LEAVE_REQUEST.STATUS.REJECTED';
      case RequestStatusTypes.InProgress:
        return 'LEAVE_REQUEST.STATUS.IN_PROGRESS';
      case RequestStatusTypes.ToBeReviewed:
        return 'LEAVE_REQUEST.STATUS.TO_BE_REVIEWED';
    }
  };

  const renderTypeName = (type: LeaveTypes) => {
    let requestOption;
    if (type in StandardLeaveTypes) {
      requestOption = STANDARD_LEAVE_REQUESTS_OPTIONS.find(
        ({ value }) => value === type,
      );
    } else if (type in MedicalLeaveTypes) {
      requestOption = MEDICAL_LEAVE_REQUESTS_OPTIONS.find(
        ({ value }) => value === type,
      );
    } else if (type in OtherLeaveTypes) {
      requestOption = OTHER_LEAVE_REQUESTS_OPTIONS.find(
        ({ value }) => value === type,
      );
    }
    return requestOption?.name;
  };

  return (
    <>
      <TableCell align="left">
        {changeDateFormatWithYear(creationDateTime)}
      </TableCell>
      <TableCell align="center">
        {intl.formatMessage({ id: renderTypeName(type) })}
      </TableCell>
      <TableCell align="center">
        {showVacationDates(dateFrom, dateTo)}
      </TableCell>
      <StatusTableCell align="center" color={status}>
        {intl.formatMessage({ id: renderStatusName(status) })}
      </StatusTableCell>
    </>
  );
};

export default LeaveRequestsTableCells;
