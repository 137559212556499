import { DEFAULT_CURRENCY } from '../constants';
import { Action, ActionKind, RenderPage, State } from './types';

export const tripCalculatorInitialState: State = {
  renderPage: RenderPage.Calculator,
  calculatorFormValues: undefined,
  isAccounting: false,
  isEditing: false,
  isEmptyCalculation: false,
  tripCalculatorSummary: undefined,
  currencies: DEFAULT_CURRENCY,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.RenderCalculator:
      return {
        ...state,
        renderPage: RenderPage.Calculator,
      };
    case ActionKind.RenderSummary:
      return {
        ...state,
        renderPage: RenderPage.Summary,
      };
    case ActionKind.SetCalculatorFormValues:
      return {
        ...state,
        calculatorFormValues: action.payload.calculatorFormValues,
      };
    case ActionKind.UpdateCalculatorFormValues:
      return {
        ...state,
        calculatorFormValues: {
          ...state.calculatorFormValues,
          ...action.payload.calculatorFormValues,
          transportation: action.payload.calculatorFormValues.transportation,
        },
      };
    case ActionKind.SetIsAccounting:
      return {
        ...state,
        isAccounting: action.payload.isAccounting,
      };
    case ActionKind.SetIsEditing:
      return {
        ...state,
        isEditing: action.payload.isEditing,
      };
    case ActionKind.SetIsEmptyCalculation:
      return {
        ...state,
        isEmptyCalculation: action.payload.isEmptyCalculation,
      };
    case ActionKind.SetTripCalculatorSummary:
      return {
        ...state,
        tripCalculatorSummary: action.payload.tripCalculatorSummary,
      };
    case ActionKind.AddCurrency:
      return {
        ...state,
        currencies: [...DEFAULT_CURRENCY, action.payload.currency],
      };
    case ActionKind.ClearCurrency:
      return {
        ...state,
        currencies: DEFAULT_CURRENCY,
      };
    default:
      return state;
  }
};
