import { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { benefitsUrl, userBenefitResignUrl, userBenefitsUrl } from 'router/url';
import {
  BenefitFetchedData,
  BenefitManagerFormValues,
} from 'components/BenefitInputs/types';
import { deleteFetch, getFetch } from 'utils/fetchFunctions';
import { BenefitsDataProps, BenefitsFetchedData } from '../types';
import { getFormattedData } from '../utils';
import { getFormattedBenefitInfoData } from './../utils';

export const getBenefitsOptions = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
): Promise<BenefitManagerFormValues[]> => {
  const response: BenefitFetchedData[] = await getFetch({
    url: benefitsUrl,
    setLoading,
  });
  return response?.map(getFormattedBenefitInfoData);
};

export const getUserBenefits = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
): Promise<BenefitsDataProps[]> => {
  const response: BenefitsFetchedData[] = await getFetch({
    url: userBenefitsUrl,
    setLoading,
  });

  return response?.map(getFormattedData);
};

export const resignFromBenefit = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  benefitId: number | null,
  intl: IntlShape,
  label: string,
) => {
  return deleteFetch({
    url: `${userBenefitResignUrl}/${benefitId}`,
    setLoading,
    intl,
    label,
  });
};
