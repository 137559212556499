import DrawerComponent, { DrawerProps } from '@material-ui/core/Drawer';
import palette from 'assets/palette';
import styled from 'styled-components';

export interface BodyWrapperProps {
  token: boolean;
}

export const DrawerContainer = styled(DrawerComponent)<DrawerProps>`
  .MuiDrawer-paperAnchorRight {
    background-color: #000;
    padding: 1rem 1rem;
    color: ${palette.ACCENT_WHITE};
    width: 100%;
    box-sizing: border-box;
    margin-top: 45px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.85rem;
    height: max-content;

    & > a {
      &:nth-of-type(1) {
        margin: 0 0 0.35rem;
      }
      padding: 0 1rem;
      font-weight: 600;
      color: #bababa;
      margin: 0.5rem;
    }
  }
`;

export const VersionContainer = styled.p`
  font-size: 10px;
  padding: 0 1rem 0 1rem;
  font-weight: 600;
  color: #bababa;
  margin-top: auto;
`;

export const BodyWrapper = styled.div`
  padding-top: 45px;
`;

export const LanguageContainer = styled.div`
  margin-top: 1rem;
  font-size: 0.7rem;
`;

interface LanguageNameProps {
  $isActive: boolean;
}

export const LanguageName = styled.span<LanguageNameProps>`
  color: ${({ $isActive }) =>
    $isActive ? palette.ACTION : palette.ACCENT_WHITE};
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    color: ${palette.ACTION};
  }
`;
