import { nanoid } from 'nanoid';
import {
  BenefitManagerFormValues,
  BenefitTypesProps,
  CostInfo,
  SelectDataWithType,
  Status,
  TimeRangeData,
} from 'components/BenefitInputs/types';
import {
  Action,
  ActionKind,
  AddNewTypeAction,
  OpenedDialogTypes,
  OpenEditingFormAction,
  RemoveBenefitAction,
  RemoveTypeAction,
  State,
  ToggleConfirmationDialogAction,
  UpdateBenefitAction,
} from './types';

const selectDataState: SelectDataWithType = {
  name: '',
  type: '',
  value: null,
};

const timeRangeState: TimeRangeData = {
  numberOfDaysMonthsYears: null,
  dayMonthYear: selectDataState,
  fixedDate: null,
};

const emptyCostData: CostInfo = {
  active: false,
  cost: null,
};

export const emptyBenefitTypeData: BenefitTypesProps = {
  id: nanoid(),
  benefitTypeName: '',
  companyCost: null,
  individualPackage: {
    ...emptyCostData,
    active: true,
  },
  partnerPackage: { ...emptyCostData, minChildAge: null, maxChildAge: null },
  familyPackage: { ...emptyCostData, numberOfChildren: null },
  employeeCost: emptyCostData,
  partnerCost: { ...emptyCostData, minChildAge: null, maxChildAge: null },
  childCost: { ...emptyCostData, minChildAge: null, maxChildAge: null },
};

const emptyState: BenefitManagerFormValues = {
  id: null,
  benefitName: '',
  benefitCategory: selectDataState,
  benefitEmail: '',
  lastDayToSendChanges: null,
  comments: '',
  timeToChangeToLowerType: timeRangeState,
  timeToChangeToHigherType: timeRangeState,
  timeToChangeToLowerPackage: timeRangeState,
  timeToChangeToHigherPackage: timeRangeState,
  timeToRemovePartner: timeRangeState,
  timeToAddPartner: timeRangeState,
  usingOwnForm: false,
  benefitTypes: [emptyBenefitTypeData],
  employeeInputs: [],
  partnerInputs: [],
  childInputs: [],
  editableInputs: [],
};

export const initialState: State = {
  formState: emptyState,
  tableData: [],
  showInactiveBenefits: false,
  openedForm: 'none',
  openedConfirmationDialog: 'none',
  selectedBenefit: { name: '', status: undefined, id: null },
};

const handleToggleConfirmationDialog = (
  state: State,
  { payload: { selectedBenefitData, type } }: ToggleConfirmationDialogAction,
) => ({
  ...state,
  openedConfirmationDialog: type,
  selectedBenefit: selectedBenefitData,
  formState: {
    ...state.formState,
    status: selectedBenefitData?.status,
  },
});

const handleChangeStatus = (state: State) => {
  const tableData = state.tableData.map((data) => {
    const getStatus = () => {
      switch (data.status) {
        case 'ACTIVE':
          return 'INACTIVE' as Status;
        case 'INACTIVE':
          return 'ACTIVE' as Status;
        default:
          return undefined;
      }
    };
    if (data.benefitName === state.selectedBenefit?.name) {
      return {
        ...data,
        status: getStatus(),
      };
    } else {
      return data;
    }
  });
  return {
    ...state,
    tableData,
  };
};

const handleRemoveBenefit = (
  state: State,
  { payload }: RemoveBenefitAction,
) => ({
  ...state,
  tableData: [
    ...state.tableData.slice(0, payload),
    ...state.tableData.slice(payload + 1),
  ],
});

const handleOpenEditingForm = (
  state: State,
  { payload: { data, selectedBenefitData } }: OpenEditingFormAction,
) => ({
  ...state,
  openedForm: 'edit' as OpenedDialogTypes,
  formState: data,
  selectedBenefit: selectedBenefitData,
});

const handleUpdateBenefit = (
  state: State,
  { payload }: UpdateBenefitAction,
) => {
  const tableData = state.tableData.map((data) => {
    if (data.id === payload.id) {
      return payload;
    } else {
      return data;
    }
  });
  return { ...state, tableData };
};

const handleAddNewType = (
  state: State,
  { payload: { data, newEntry } }: AddNewTypeAction,
) => ({
  ...state,
  formState: {
    ...state.formState,
    benefitTypes: [...data, newEntry],
  },
});

const handleRemoveType = (state: State, { payload }: RemoveTypeAction) => ({
  ...state,
  formState: {
    ...state.formState,
    benefitTypes: [
      ...state.formState.benefitTypes.slice(0, payload),
      ...state.formState.benefitTypes.slice(payload + 1),
    ],
  },
});

const handleClearType = (state: State) => ({
  ...state,
  formState: {
    ...state.formState,
    benefitTypes: [emptyBenefitTypeData],
  },
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.SetFormState:
      return { ...state, formState: { ...state.formState, ...action.payload } };
    case ActionKind.ResetFormState:
      return { ...state, openedForm: 'none', formState: emptyState };
    case ActionKind.SetTableData:
      return { ...state, tableData: action.payload };
    case ActionKind.AddNewBenefit:
      return { ...state, tableData: [...state.tableData, action.payload] };
    case ActionKind.UpdateBenefit:
      return handleUpdateBenefit(state, action);
    case ActionKind.RemoveBenefit:
      return handleRemoveBenefit(state, action);
    case ActionKind.OpenAddingForm:
      return { ...state, openedForm: 'addNew' };
    case ActionKind.OpenEditingForm:
      return handleOpenEditingForm(state, action);
    case ActionKind.ToggleConfirmationDialog:
      return handleToggleConfirmationDialog(state, action);
    case ActionKind.ChangeStatus:
      return handleChangeStatus(state);
    case ActionKind.ToggleInactiveBenefits:
      return { ...state, showInactiveBenefits: !state.showInactiveBenefits };
    case ActionKind.AddNewType:
      return handleAddNewType(state, action);
    case ActionKind.RemoveType:
      return handleRemoveType(state, action);
    case ActionKind.ClearType:
      return handleClearType(state);
    default:
      return state;
  }
};
