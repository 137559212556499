export const renameKeys = ({
  filterOptionsArray,
  oldKeyName,
  newKeyName,
}: {
  filterOptionsArray: FilterOption[];
  oldKeyName: string;
  newKeyName: string;
}) =>
  filterOptionsArray.map((element) => ({
    ...element,
    [newKeyName]: element[oldKeyName],
  }));

export const renameProjectKeys = ({
  filterOptionsArray,
  oldKeyName,
  newKeyName,
}: {
  filterOptionsArray: ProjectsData[];
  oldKeyName: string;
  newKeyName: string;
}) =>
  filterOptionsArray.map((element) => ({
    ...element,
    [newKeyName]: element[oldKeyName],
  }));

export const renameClientKeys = ({
  filterOptionsArray,
  oldKeyName,
  newKeyName,
}: {
  filterOptionsArray: ClientsData[];
  oldKeyName: string;
  newKeyName: string;
}) =>
  filterOptionsArray.map((element) => ({
    ...element,
    [newKeyName]: element[oldKeyName],
  }));

export type FilterOption = {
  value: number;
  [key: string]: string | number | object | boolean;
};

export interface ProjectsData extends FilterOption {
  client: { clientName: string; id: number; description: string };
  billable: boolean;
  contactPerson: string;
  projectName: string;
}

export interface ClientsData extends FilterOption {
  clientName: string;
  decription: string;
}
