import { memo, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { StyledTableIconButton } from 'components/AccountingNotesList/AccountingNotes.css';
import ControlledSelect from 'components/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import ControlledTimePicker from 'components/ControlledTimePicker';
import { ProjectCategories } from 'components/Dialog/types';
import ToolTipCell from 'components/ToolTipCell';
import { getBankHolidayTooltip } from './tableUtils';
import { DayProjectProps, FullReportRowProps } from './types';
import {
  AddIcon,
  ClearButton,
  DateWrapper,
  FileCounter,
  FlexDivFullReport,
  HeaderTooltipText,
  HiddenInput,
  InfoTooltipIcon,
  OverlappingHoursTooltip,
  RemoveIcon,
  ReportTypography,
  TableActionCell,
  TableCell,
  TableCellWithIcon,
  TableRow,
  Tooltip,
  UploadFileIcon,
  WarningIcon,
  WarningTooltip,
} from './Table.css';

const FullReportRow = ({
  isFreeDay,
  isToday,
  index,
  entry,
  rowRef,
  control,
  register,
  isLeaveDay,
  clientsData,
  projectOptions,
  invalidWorktimeTitle,
  isInvalidWorktime,
  onInvalidWorktime,
  checkWhatToRender,
  defaultProject,
  handleClientChange,
  handleProjectChange,
  isTimesheetDisabled,
  addEntryToDailytime,
  handleFileUploadClick,
  deleteEntryFromDailytime,
  handleRefreshAfterTimeChange,
  setValue,
}: FullReportRowProps) => {
  const intl = useIntl();

  const [isEndingTimeBeforeStarting, setIsEndingTimeBeforeStarting] =
    useState(false);

  const project = useWatch({
    control,
    name: `days[${index}].project`,
  }) as DayProjectProps;

  const isDayFree = useMemo(() => {
    return (
      project?.projectCategory === ProjectCategories.medicalLeave ||
      project?.projectCategory === ProjectCategories.vacations ||
      project?.projectCategory === ProjectCategories.dayOff
    );
  }, [project?.projectCategory]);

  const isDateBeforeToday = useMemo(() => {
    return new Date(entry.date) <= new Date();
  }, [entry.date]);

  const startTime = useWatch({
    control,
    name: `days[${index}].startTime`,
  }) as DayProjectProps;

  const endTime = useWatch({
    control,
    name: `days[${index}].endTime`,
  }) as DayProjectProps;

  useEffect(() => {
    if (startTime !== null && endTime !== null) {
      setIsEndingTimeBeforeStarting(startTime > endTime);
    }
  }, [startTime, endTime]);

  const isEndingTimeBeforeStartingTitle = intl.formatMessage({
    id: 'FULL_REPORT.WARNING_TOOLTIP.WRONG_ENDING_TIME',
  });

  const deleteInsertedHours = () => {
    setValue(`days[${index}].startTime`, null, { shouldDirty: true });
    setValue(`days[${index}].endTime`, null, { shouldDirty: true });
    setValue(`days[${index}].breakTime`, null, { shouldDirty: true });
  };

  return (
    <TableRow
      key={`fullReportDay-${entry.id}`}
      ref={moment(entry.date).isSame(Date.now(), 'date') ? rowRef : undefined}
      index={index}
      $weekend={isFreeDay || isDayFree}
      $today={isToday}
    >
      <TableCell>
        <HiddenInput //this hidden input allows me to store id in form, thus obtaining it via getValues
          type="hidden"
          name={`days[${index}].id`}
          defaultValue={entry.id}
          ref={register()}
        />
        <HiddenInput
          type="hidden"
          name={`days[${index}].dayId`}
          defaultValue={entry.dayId}
          ref={register()}
        />
        <HiddenInput
          type="hidden"
          name={`days[${index}].parentId`}
          defaultValue={entry.parentId}
          ref={register()}
        />
        <HiddenInput
          type="hidden"
          name={`days[${index}].date`}
          defaultValue={entry.date}
          ref={register()}
        />
        <DateWrapper>
          {entry.parentId ? (
            <ArrowRight />
          ) : (
            entry.dayOfWeek !== 'SATURDAY' &&
            entry.dayOfWeek !== 'SUNDAY' &&
            !entry.holiday &&
            !entry.startTime &&
            !entry.endTime &&
            !entry.parentId &&
            !isLeaveDay &&
            !entry.bankHoliday &&
            isDateBeforeToday && (
              <WarningTooltip
                id="overdueTooltip"
                title={
                  <HeaderTooltipText>
                    {intl.formatMessage({
                      id: 'WORK_TIME_TAB.OVERDUE.TOOLTIP',
                    })}
                  </HeaderTooltipText>
                }
                placement="top"
                style={{
                  marginLeft: 0,
                }}
              >
                <WarningIcon />
              </WarningTooltip>
            )
          )}
          {!entry.parentId && entry.bankHoliday && (
            <WarningTooltip
              id="bankHolidayTooltip"
              title={
                <HeaderTooltipText>
                  {getBankHolidayTooltip(entry)}
                </HeaderTooltipText>
              }
              placement="top"
              style={{
                marginLeft: 0,
              }}
            >
              <InfoTooltipIcon />
            </WarningTooltip>
          )}
          <ReportTypography>{`${entry.dayOfMonth} ${
            entry.dayOfWeek?.charAt(0) +
            entry.dayOfWeek?.slice(1, 3).toLowerCase()
          }`}</ReportTypography>
        </DateWrapper>
      </TableCell>
      <TableCell style={{ position: 'relative' }}>
        {(isInvalidWorktime || isEndingTimeBeforeStarting) && (
          <OverlappingHoursTooltip
            id="overlappingHoursTooltip"
            title={
              <HeaderTooltipText>
                {isInvalidWorktime
                  ? invalidWorktimeTitle
                  : isEndingTimeBeforeStartingTitle}
              </HeaderTooltipText>
            }
            placement="top"
          >
            <WarningIcon />
          </OverlappingHoursTooltip>
        )}
        <ControlledTimePicker
          id="startTime"
          name={`days[${index}].startTime`}
          control={control}
          required
          defaultValue={entry.startTime}
          isDisabled={isLeaveDay || isTimesheetDisabled}
          showMidnight
          onInvalidWorktime={onInvalidWorktime}
          isError={isInvalidWorktime || isEndingTimeBeforeStarting}
          handleRefreshAfterTimeChange={handleRefreshAfterTimeChange(index)}
        />
      </TableCell>
      <TableCell>
        <ControlledTimePicker
          id="endTime"
          name={`days[${index}].endTime`}
          control={control}
          required
          defaultValue={entry.endTime}
          isDisabled={isLeaveDay || isTimesheetDisabled}
          showMidnight
          onInvalidWorktime={onInvalidWorktime}
          isError={isInvalidWorktime || isEndingTimeBeforeStarting}
          handleRefreshAfterTimeChange={handleRefreshAfterTimeChange(index)}
        />
      </TableCell>
      <TableCellWithIcon $smaller={!startTime && !endTime}>
        <ControlledTimePicker
          id="breakTime"
          name={`days[${index}].breakTime`}
          control={control}
          required
          defaultValue={entry.breakTime}
          isDisabled={isLeaveDay || isTimesheetDisabled}
          handleRefreshAfterTimeChange={handleRefreshAfterTimeChange(index)}
        />
        {(startTime || endTime) && (
          <ClearButton>
            <Tooltip
              title={
                <HeaderTooltipText>
                  {intl.formatMessage({
                    id: 'FULL_REPORT.DELETE_INSERTED_HOURS',
                  })}
                </HeaderTooltipText>
              }
              placement="top"
            >
              <ClearIcon fontSize="small" onClick={deleteInsertedHours} />
            </Tooltip>
          </ClearButton>
        )}
      </TableCellWithIcon>
      <TableCell align="center">
        <ControlledTextField
          id="manHours"
          name={`days[${index}].manHours`}
          control={control}
          defaultValue={!isLeaveDay && entry.manHours}
          isDisabled
          isDisabledTotalTime
        />
      </TableCell>
      <TableCell align="center">
        <ControlledTextField
          id="multiplicator"
          name={`days[${index}].multiplicator`}
          control={control}
          defaultValue={
            entry?.projectBasicInfo?.multiplicator ??
            defaultProject?.multiplicator
          }
          isDisabled
          isDisabledTotalTime
        />
      </TableCell>
      <TableCell size="small">
        <ControlledSelect
          id="client"
          label="TABLE.HEAD.CLIENT"
          name={`days[${index}].client`}
          control={control}
          options={clientsData ?? []}
          required
          isSmall
          defaultValue={checkWhatToRender(entry, 'client')}
          handleChange={handleClientChange(entry.dayId, index)}
          isDisabled={isTimesheetDisabled}
          isOptionDisabled={(option) => option.status === 'INACTIVE'}
        />
      </TableCell>
      <TableCell>
        {isTimesheetDisabled ? (
          <FlexDivFullReport>
            <ControlledSelect
              id="project"
              label="TABLE.HEAD.PROJECT"
              name={`days[${index}].project`}
              control={control}
              options={projectOptions ?? []}
              required
              isSmall
              isWider
              defaultValue={checkWhatToRender(entry, 'project')}
              isDisabled={isTimesheetDisabled}
              noOptionMessage={intl.formatMessage({
                id: 'WORK_TIME.SELECT_CLIENT',
              })}
              isOptionDisabled={(option) =>
                option.status === 'INACTIVE' ||
                option.reportingCustomData === true
              }
              handleChange={handleProjectChange(entry.dayId, index)}
            />
            <ToolTipCell
              id={`project-${index}`}
              tooltip={entry.projectBasicInfo?.name || defaultProject?.name}
            />
          </FlexDivFullReport>
        ) : (
          <ControlledSelect
            id="project"
            label="TABLE.HEAD.PROJECT"
            name={`days[${index}].project`}
            control={control}
            options={projectOptions ?? []}
            required
            isSmall
            isWider
            defaultValue={checkWhatToRender(entry, 'project')}
            isDisabled={isTimesheetDisabled}
            noOptionMessage={intl.formatMessage({
              id: 'WORK_TIME.SELECT_CLIENT',
            })}
            isOptionDisabled={(option) =>
              option.status === 'INACTIVE' ||
              option.reportingCustomData === true
            }
            handleChange={handleProjectChange(entry.dayId, index)}
            isTooltipEnabled
          />
        )}
      </TableCell>
      <TableCell>
        <ControlledTextField
          id="comment"
          name={`days[${index}].comment`}
          control={control}
          required
          maxLength={255}
          size="small"
          defaultValue={entry.comment}
          isDisabled={isTimesheetDisabled}
        />
      </TableCell>
      <TableActionCell align="center">
        {entry.parentId ? (
          <Tooltip
            title={
              <HeaderTooltipText>
                {intl.formatMessage({
                  id: 'WORK_TIME_TAB.REMOVE_DAILY_PROJECT.TOOLTIP',
                })}
              </HeaderTooltipText>
            }
            placement="top"
          >
            <StyledTableIconButton
              id="removeRow"
              disabled={isTimesheetDisabled}
              onClick={deleteEntryFromDailytime(entry.dayId, index)}
            >
              <RemoveIcon />
            </StyledTableIconButton>
          </Tooltip>
        ) : (
          <Box display="flex">
            <Tooltip
              title={
                <HeaderTooltipText>
                  {intl.formatMessage({
                    id: 'WORK_TIME_TAB.ADD_DAILY_PROJECT.TOOLTIP',
                  })}
                </HeaderTooltipText>
              }
              placement="top"
            >
              <StyledTableIconButton
                id="addRow"
                disabled={isLeaveDay || isTimesheetDisabled}
                onClick={addEntryToDailytime(entry, index)}
              >
                <AddIcon />
              </StyledTableIconButton>
            </Tooltip>
            <Tooltip
              title={
                <HeaderTooltipText>
                  {intl.formatMessage({
                    id: 'WORK_TIME_TAB.MANAGE_UPLOADED_FILES.TOOLTIP',
                  })}
                </HeaderTooltipText>
              }
              placement="top"
            >
              <StyledTableIconButton
                id="attachments"
                disabled={isTimesheetDisabled}
                onClick={handleFileUploadClick(entry)}
              >
                <UploadFileIcon />
                {entry.attachments.length !== 0 && (
                  <FileCounter>{entry.attachments.length}</FileCounter>
                )}
              </StyledTableIconButton>
            </Tooltip>
          </Box>
        )}
      </TableActionCell>
    </TableRow>
  );
};

export default memo(FullReportRow);
