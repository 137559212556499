import { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import BenefitCheckbox from 'components/BenefitInputs/BenefitCheckbox';
import BenefitDatePicker from 'components/BenefitInputs/BenefitDatePicker';
import BenefitSelectField from 'components/BenefitInputs/BenefitSelectField';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import {
  BillabilityTypes,
  ErrorProperties,
  InputNames,
  Packages,
  PersonalData,
} from 'components/BenefitInputs/types';
import { getErrorProperty } from 'components/BenefitInputs/utils';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext';
import {
  ActionKind,
  RenderForm,
} from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import {
  GridGroup,
  GroupTitle,
} from 'modules/UserProfile/Screens/Benefits/BenefitsTab.css';
import { getHelperText, validate } from 'utils/formValidation';
import { BENEFIT_PARTNER_TYPES } from '../../staticData';
import {
  checkIfBenefitIncludesInput,
  getAddressData,
  getBenefitTypeOptions,
  getChildOption,
  getFormGroupTitles,
  getGenderOptions,
  getInputName,
  getPackageOptions,
  getPartnerOptions,
} from './InputHelpers';
import PartnerField from './PartnerField';
import {
  AddressData,
  InputProps as RenderInputProps,
  PartnerType,
  Props,
  StaticInputNames,
} from './types';

const { individual, family } = Packages;

const BenefitInputs = ({
  isAdultSelected,
  control,
  errors,
  getValues,
  setValue,
  benefitsInfo,
}: Props) => {
  const { state, dispatch } = useContext(BenefitContext);
  const intl = useIntl();

  const setCheckboxFormState = useCallback(() => {
    if (getValues()?.employee?.areAddressesDifferent === undefined) {
      setValue(
        'areAddressesDifferent',
        state.benefitsData?.employee?.areAddressesDifferent,
      );
    }
  }, [getValues, setValue, state]);

  const areAddressesDifferent = (index?: number) => {
    if (index !== undefined) {
      return (
        getValues().partners?.[index]?.areAddressesDifferent ??
        state.benefitsData?.partners?.[index]?.areAddressesDifferent
      );
    }
    return (
      getValues().employee.areAddressesDifferent ??
      state.benefitsData?.employee?.areAddressesDifferent
    );
  };

  useEffect(() => {
    setCheckboxFormState();
  }, [setCheckboxFormState]);

  const isInput = (inputName: InputNames, type?: PartnerType) =>
    checkIfBenefitIncludesInput(inputName, state, benefitsInfo, type);

  const getDefaultValue = (value: keyof PersonalData, index?: number) => {
    if (index || index === 0) {
      return state.benefitsData?.partners?.[index]?.[value] ?? '';
    }
    return state.benefitsData?.employee?.[value] ?? '';
  };

  const renderFirstNameInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.firstName, type)) {
      return (
        <BenefitTextField
          key={`firstName - ${type} - ${index}`}
          label="FIRST_NAME"
          name={getInputName(InputNames.firstName, index)}
          rules={validate(intl).text}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          defaultValue={getDefaultValue('firstName', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderSurname = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.surname, type)) {
      return (
        <BenefitTextField
          key={`surname - ${index}`}
          label="SURNAME"
          name={getInputName(InputNames.surname, index)}
          rules={validate(intl).text}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          defaultValue={getDefaultValue('surname', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
  };

  const renderPeselInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.pesel, type)) {
      return (
        <BenefitTextField
          key={`pesel - ${index}`}
          label="PESEL"
          name={getInputName(InputNames.pesel, index)}
          rules={validate(intl).pesel}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          defaultValue={getDefaultValue('pesel', index) as number | null}
          placeholder="0123456789123"
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderEmailInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.email, type)) {
      return (
        <BenefitTextField
          key={`email - ${index}`}
          label="EMAIL"
          name={getInputName(InputNames.email, index)}
          rules={validate(intl).email}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          defaultValue={getDefaultValue('email', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderDateOfBirthInput = ({ index, type }: RenderInputProps) => {
    const defaultValue = getDefaultValue('dateOfBirth', index) as Date;
    if (isInput(InputNames.dateOfBirth, type)) {
      return (
        <BenefitDatePicker
          key={`dateOfBirth - ${index}`}
          label="DATE_OF_BIRTH"
          name={getInputName(InputNames.dateOfBirth, index)}
          rules={validate(intl).date}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          defaultValue={!!defaultValue ? defaultValue : undefined}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderIdNumberInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.idNumber, type)) {
      return (
        <BenefitTextField
          key={`idNumber - ${index}`}
          label="ID_NUMBER"
          name={getInputName(InputNames.idNumber, index)}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          rules={validate(intl).required}
          placeholder="AB1234567"
          defaultValue={getDefaultValue('idNumber', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderPlaceOfBirthInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.placeOfBirth, type)) {
      return (
        <BenefitTextField
          key={`placeOfBirth - ${index}`}
          label="PLACE_OF_BIRTH"
          name={getInputName(InputNames.placeOfBirth, index)}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          rules={validate(intl).text}
          defaultValue={getDefaultValue('placeOfBirth', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderPhoneNumberInput = ({ index, type }: RenderInputProps) => {
    if (isInput(InputNames.phoneNumber, type)) {
      return (
        <BenefitTextField
          key={`phoneNumber - ${index}`}
          label="PHONE_NUMBER"
          name={getInputName(InputNames.phoneNumber, index)}
          rules={validate(intl).number}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          placeholder="123123123"
          defaultValue={getDefaultValue('phoneNumber', index) as string}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderPartnerTypeInput = ({ index }: RenderInputProps) => {
    const getDefaultPartnerTypeValue = () => {
      if (isAdultSelected) {
        return getChildOption(intl)[0];
      }
      const defaultValue = getDefaultValue('partnerType', index) as string;
      return !!defaultValue ? defaultValue : undefined;
    };
    return (
      <BenefitSelectField
        key={`partnerType - ${index}`}
        label="PARTNER_TYPE"
        name={getInputName(StaticInputNames.partnerType, index)}
        options={getPartnerOptions(
          state.renderForm,
          intl,
          state.benefitsData,
          isAdultSelected,
        )}
        rules={validate(intl).required}
        errorMessage={getHelperText(intl).required}
        index={index}
        nameOfErrorProperty={getErrorProperty(index)}
        control={control}
        errors={errors}
        defaultValue={getDefaultPartnerTypeValue()}
      />
    );
  };

  const renderAddressCheckbox = ({
    index,
    addressType,
    type,
  }: RenderInputProps) => {
    switch (addressType) {
      case 'address':
        if (isInput(InputNames.crspAddress, type)) {
          return (
            <BenefitCheckbox
              key={`areAddressesDifferent - ${index}`}
              label="DIFFERENT_ADDRESSES"
              name={getInputName(StaticInputNames.areAddressesDifferent, index)}
              defaultChecked={
                getDefaultValue('areAddressesDifferent', index) as boolean
              }
              control={control}
              placement="end"
            />
          );
        } else {
          return null;
        }
      case 'crspAddress':
        return null;
    }
  };

  const renderAddress = ({ addressType, index }: RenderInputProps) => {
    switch (addressType) {
      case 'address':
        return true;
      case 'crspAddress':
        return areAddressesDifferent(index);
    }
  };

  const renderAddressGroup = ({ index, addressType }: RenderInputProps) => {
    const isCrsp = addressType === 'crspAddress';
    const city = getAddressData(AddressData.city, isCrsp, state, index);
    const zipCode = getAddressData(AddressData.zipCode, isCrsp, state, index);
    const street = getAddressData(AddressData.street, isCrsp, state, index);
    const houseNumber = getAddressData(
      AddressData.houseNumber,
      isCrsp,
      state,
      index,
    );
    const apartmentNumber = getAddressData(
      AddressData.apartmentNumber,
      isCrsp,
      state,
      index,
    );

    const fieldName = isCrsp ? 'crspAddress' : 'address';

    return (
      <>
        {renderAddress({ addressType, index }) && (
          <GridGroup container spacing={4}>
            <GroupTitle>
              {isCrsp
                ? getFormGroupTitles(intl).crspAddress
                : getFormGroupTitles(intl).address}
            </GroupTitle>
            <BenefitTextField
              key={isCrsp ? `crspCity - ${index}` : `city - ${index}`}
              label="CITY"
              name={getInputName(
                `${fieldName}.city` as keyof PersonalData,
                index,
              )}
              rules={validate(intl).text}
              defaultValue={city}
              control={control}
              errors={errors}
              addressType={fieldName}
              nameOfErrorProperty={ErrorProperties.Address}
              index={index}
            />
            <BenefitTextField
              key={isCrsp ? `crspZipCode - ${index}` : `zipCode - ${index}`}
              label="ZIP_CODE"
              name={getInputName(
                `${fieldName}.zipCode` as keyof PersonalData,
                index,
              )}
              rules={validate(intl).required}
              placeholder="12-123"
              defaultValue={zipCode}
              control={control}
              errors={errors}
              addressType={fieldName}
              nameOfErrorProperty={ErrorProperties.Address}
              index={index}
            />
            <BenefitTextField
              key={isCrsp ? `crspStreet - ${index}` : `street - ${index}`}
              label="STREET"
              name={getInputName(
                `${fieldName}.street` as keyof PersonalData,
                index,
              )}
              rules={validate(intl).text}
              defaultValue={street}
              control={control}
              errors={errors}
              addressType={fieldName}
              nameOfErrorProperty={ErrorProperties.Address}
              index={index}
            />
            <BenefitTextField
              key={
                isCrsp ? `crspHouseNumber - ${index}` : `houseNumber - ${index}`
              }
              label="HOUSE_NUMBER"
              name={getInputName(
                `${fieldName}.houseNumber` as keyof PersonalData,
                index,
              )}
              rules={validate(intl).required}
              defaultValue={houseNumber}
              control={control}
              errors={errors}
              addressType={fieldName}
              nameOfErrorProperty={ErrorProperties.Address}
              index={index}
            />
            <BenefitTextField
              key={
                isCrsp ? `crspApartNumber - ${index}` : `apartNumber - ${index}`
              }
              label="APARTMENT_NUMBER"
              name={getInputName(
                `${fieldName}.apartmentNumber` as keyof PersonalData,
                index,
              )}
              defaultValue={apartmentNumber}
              control={control}
              errors={errors}
              addressType={fieldName}
              nameOfErrorProperty={ErrorProperties.Address}
              index={index}
            />
            {renderAddressCheckbox({ index, addressType })}
          </GridGroup>
        )}
      </>
    );
  };

  const renderPackageOptionsGroup = () => {
    if (state.selectedBenefit?.billableBy !== BillabilityTypes.person) {
      return (
        <GridGroup container spacing={4}>
          <GroupTitle>{getFormGroupTitles(intl).benefitPackage}</GroupTitle>
          <BenefitSelectField
            key="package"
            label="PACKAGE"
            name="benefitPackage"
            options={getPackageOptions(
              intl,
              benefitsInfo,
              state.selectedBenefit,
              state.selectedBenefitType,
            )}
            rules={validate(intl).required}
            errorMessage={getHelperText(intl).required}
            defaultValue={state.benefitsData?.benefitPackage}
            control={control}
            errors={errors}
          />
        </GridGroup>
      );
    }
  };

  const renderBenefitTypeOptionsGroup = () => {
    return (
      <GridGroup container spacing={4}>
        <GroupTitle>{getFormGroupTitles(intl).benefitType}</GroupTitle>
        <BenefitSelectField
          key="benefitType"
          label="BENEFIT_TYPE"
          name="benefitType"
          options={getBenefitTypeOptions(state.benefitsData)}
          defaultValue={state.benefitsData?.benefitType?.name}
          rules={validate(intl).required}
          errorMessage={getHelperText(intl).required}
          control={control}
          errors={errors}
        />
      </GridGroup>
    );
  };

  const renderGenderOptions = ({ index, type }: RenderInputProps) => {
    const defaultValue = getDefaultValue('gender', index) as string;
    if (isInput(InputNames.gender, type)) {
      return (
        <BenefitSelectField
          key={`gender - ${index}`}
          label="GENDER"
          name={getInputName(InputNames.gender, index)}
          index={index}
          nameOfErrorProperty={getErrorProperty(index)}
          options={getGenderOptions(intl)}
          rules={validate(intl).required}
          errorMessage={getHelperText(intl).required}
          defaultValue={!!defaultValue ? defaultValue : undefined}
          control={control}
          errors={errors}
        />
      );
    }
    return null;
  };

  const renderFormInputs = ({ index, type }: RenderInputProps) => {
    return (
      <>
        <GridGroup container spacing={4}>
          <GroupTitle>
            {type
              ? getFormGroupTitles(intl, index).partnersData
              : getFormGroupTitles(intl, index).personalData}
          </GroupTitle>
          {renderFirstNameInput({ index, type })}
          {renderSurname({ index, type })}
          {renderGenderOptions({ index, type })}
          {renderPeselInput({ index, type })}
          {renderIdNumberInput({ index, type })}
          {renderDateOfBirthInput({ index, type })}
          {renderPlaceOfBirthInput({ index, type })}
          {renderPhoneNumberInput({ index, type })}
          {renderEmailInput({ index, type })}
        </GridGroup>
        {isInput(InputNames.address, type) &&
          renderAddressGroup({ addressType: 'address', index, type })}
        {isInput(InputNames.crspAddress, type) &&
          areAddressesDifferent(index) &&
          renderAddressGroup({ addressType: 'crspAddress', index, type })}
      </>
    );
  };

  const renderPartnerField = () => {
    const addPartnerField = ({ isAddable = false }) => (
      <>
        {[
          [...Array(state.numberOfMembers + 1)].fill(null).map((_, index) => {
            const partnerType = getValues()?.partners?.[index]?.partnerType;
            return (
              <>
                <PartnerField
                  key={index}
                  isLast={index === state.numberOfMembers && isAddable}
                  index={index}
                  handleAdd={() =>
                    dispatch({ type: ActionKind.IncreaceNumberOfMembers })
                  }
                  handleRemove={() =>
                    dispatch({ type: ActionKind.DecreaseNumberOfMembers })
                  }
                >
                  <GridGroup container spacing={4}>
                    {renderPartnerTypeInput({ index: index })}
                  </GridGroup>
                  {partnerType?.type === BENEFIT_PARTNER_TYPES.adult
                    ? renderFormInputs({ index: index, type: 'partner' })
                    : renderFormInputs({ index: index, type: 'child' })}
                </PartnerField>
              </>
            );
          }),
        ]}
      </>
    );

    const benefitType =
      getValues().benefitPackage?.type ??
      state.benefitsData?.benefitPackage?.type;

    const isBillableByPerson =
      state.selectedBenefit?.billableBy === BillabilityTypes.person;

    if (
      (benefitType === family && state.renderForm === RenderForm.Partner) ||
      benefitType === family ||
      isBillableByPerson
    ) {
      return addPartnerField({ isAddable: true });
    }
    if (
      benefitType === individual ||
      (!benefitType &&
        (state.renderForm === RenderForm.Benefit ||
          state.renderForm === RenderForm.EditData))
    ) {
      return null;
    }

    return addPartnerField({});
  };

  const renderContent = () => {
    switch (state.renderForm) {
      case RenderForm.Benefit:
        return (
          <>
            {renderPackageOptionsGroup()}
            {renderFormInputs({})}
            {renderPartnerField()}
          </>
        );

      case RenderForm.Partner:
        return renderPartnerField();

      case RenderForm.EditData:
        return (
          <>
            {renderBenefitTypeOptionsGroup()}
            {renderFormInputs({})}
          </>
        );
      default:
        return null;
    }
  };

  return renderContent();
};

export default BenefitInputs;
