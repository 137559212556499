import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { ControllerCheckboxProps } from './types';
import { Checkbox } from './ControlledCheckbox.css';

const ControlledCheckbox = ({
  control,
  label,
  name,
  placement,
  disabled,
  changeSideEffect,
  defaultChecked,
  isFullWidth = true,
  isProvidedLabelTranslated = false,
}: ControllerCheckboxProps) => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      control={control}
      type={name}
      defaultValue={defaultChecked}
      as={(props) => (
        <FormControl
          fullWidth={isFullWidth}
          component="fieldset"
          style={{ pointerEvents: 'none' }}
        >
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  if (changeSideEffect) {
                    changeSideEffect();
                  }
                }}
                checked={props.value}
                disabled={disabled}
                style={{ pointerEvents: 'auto' }}
                inputProps={{
                  'aria-label': 'overtime checkbox',
                }}
                defaultChecked={defaultChecked}
                id={name}
              />
            }
            label={
              !isProvidedLabelTranslated
                ? intl.formatMessage({
                    id: label,
                  })
                : label
            }
            labelPlacement={placement}
          />
        </FormControl>
      )}
    />
  );
};

export default ControlledCheckbox;
