import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { UserContext } from 'globalContext/UserContext';
import moment from 'moment';
import {
  clientsUrl,
  employersUrl,
  projectsUrl,
  userProfileUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledSelect from 'components/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import ControlledTimePicker from 'components/ControlledTimePicker';
import { ClientObject, ProjectObject } from 'modules/WorkTime/types';
import { getFetch, patchFetch } from 'utils/fetchFunctions';
import { returnHourString } from 'utils/formatters/returnHourString';
import {
  filterDropdownProjectData,
  getRenamedProjectsData,
  setClientsValue,
} from 'utils/helpers/filterDropdownData';
import {
  ClientsData,
  ProjectsData,
  renameClientKeys,
  renameProjectKeys,
} from 'utils/helpers/renameKeys';
import { options } from '../mock_data';
import { AccountData, EditDialogProps, EmployerData } from '../types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
  TimePickerWrapper,
} from './Dialog.css';

const EditDialog = ({
  isDialogOpen,
  handleShowClicked,
  data,
  fetchData,
}: EditDialogProps) => {
  const { control, handleSubmit, reset, setValue, formState } =
    useForm<AccountData>({
      mode: 'onChange',
      defaultValues: {
        contractType: {
          value: '',
          name: '',
        },
        project: {
          value: 0,
          name: '',
        },
        client: {
          value: 0,
          name: '',
        },
        employer: {
          value: 0,
          name: '',
        },
        startTime: new Date(),
        personalId: '',
      },
    });
  const { username } = useContext(UserContext);
  const [employersData, setEmployersData] = useState<EmployerData[]>([]);
  const [projectData, setProjectData] = useState<ProjectsData[]>([]);
  const [clientData, setClientData] = useState<ClientsData[]>([]);
  const [timeStart, setTimeStart] = useState<MaterialUiPickersDate>(null);
  const [filteredProjectsData, setFilteredProjectsData] = useState<
    ProjectsData[]
  >([]);
  const { client, project } = useWatch({ control });

  const intl = useIntl();

  const setFilterDropdownData = useCallback(() => {
    const clientNameFromProject = projectData.filter(
      (el) => el.name === project?.name,
    )[0]?.client?.clientName;

    const clientIdFromProject = projectData.filter(
      (el) => el.name === project?.name,
    )[0]?.client?.id;

    const clientName = project?.client
      ? project.client.clientName
      : clientNameFromProject;

    const clientId = project?.client ? project.client.id : clientIdFromProject;

    setFilteredProjectsData(
      filterDropdownProjectData({
        projectsData: projectData,
        clientNameFromClient: client?.name,
      }),
    );
    setClientsValue({
      setValue,
      projectNameFromProject: project?.name,
      clientNameFromClient: client?.name,
      clientNameFromProject: clientName,
      clientIdFromProject: clientId,
    });
  }, [client, project, projectData, setValue]);

  useEffect(() => {
    const retrieveData = async () => {
      const clientsResponse = await getFetch({
        url: `${clientsUrl}?assignedToMe=true&active=true`,
      });
      if (clientsResponse) {
        setClientData(
          renameClientKeys({
            filterOptionsArray: clientsResponse.map(
              ({ id, ...obj }: { id: number; obj: ClientObject }) => ({
                value: id,
                ...obj,
              }),
            ),
            oldKeyName: 'clientName',
            newKeyName: 'name',
          }),
        );
      }

      const projectsResponse = await getFetch({
        url: `${projectsUrl}?assignedToMe=true&active=true`,
      });
      if (projectsResponse) {
        setProjectData(
          renameProjectKeys({
            filterOptionsArray: projectsResponse.map(
              ({ id, ...obj }: { id: number; obj: ProjectObject }) => ({
                value: id,
                ...obj,
              }),
            ),
            oldKeyName: 'projectName',
            newKeyName: 'name',
          }),
        );
      }

      const employersResponse = await getFetch({ url: employersUrl });
      if (employersResponse) {
        const employers = employersResponse.map(
          ({ id, name }: EmployerData) => ({
            value: id,
            name,
          }),
        );
        setEmployersData(employers);
      }
    };
    retrieveData();
  }, []);

  useEffect(() => {
    setFilterDropdownData();
  }, [setFilterDropdownData]);

  const projectOptions = useMemo(
    () => getRenamedProjectsData(filteredProjectsData),
    [filteredProjectsData],
  );

  useEffect(() => {
    if (data) {
      reset({
        contractType: data?.contractType?.name
          ? {
              name: intl.formatMessage({
                id: `BUSINESS_TRIP.${data?.contractType?.name
                  .split(' ')
                  .join('_')}`,
              }),
              value: data?.contractType?.value ?? '',
            }
          : { name: '', value: '' },
        project: data?.project,
        client: data?.client,
        employer: data?.employer,
        startTime: data?.startTime || '',
        personalId: data?.personalId,
      });
      setTimeStart(moment(data.startTime));
    }
  }, [clientData, data, projectData, reset, isDialogOpen, intl]);

  const startTimeValidation = (e: MaterialUiPickersDate) => {
    const startTime = moment(e);
    const minutes = startTime.minutes();
    const hours = startTime.hours();
    const maxHour = { hour: 15, minute: 30 };

    if (hours >= 16) {
      return startTime.set(maxHour);
    }

    if (minutes < 15) {
      return startTime.startOf('hour');
    } else if (minutes !== 30 && minutes >= 15 && minutes < 45) {
      return startTime.subtract(minutes - 30, 'minutes');
    } else if (minutes >= 45) {
      if (hours === 15) {
        return startTime.set(maxHour);
      }
      return startTime.endOf('hour').add(1, 'minute');
    } else {
      return startTime;
    }
  };
  const handleStartTimeChange = (e: MaterialUiPickersDate) => {
    const validatedTime = startTimeValidation(e);
    setValue('startTime', validatedTime.toDate());
    setTimeStart(validatedTime);
  };

  const onSubmit = handleSubmit(async (formData) => {
    const { client, project, contractType, personalId, employer } = formData;

    const body = {
      client: client ? { name: client.name, id: client.value } : null,
      project: project ? { name: project.name, id: project.value } : null,
      contractType: contractType?.value ?? null,
      startTime: returnHourString(timeStart?.toString()) || '08:00',
      employer: employer ? { name: employer.name, id: employer.value } : null,
      personalId: personalId || null,
    };

    try {
      await patchFetch({
        url: `${userProfileUrl}/${username}/profile`,
        intl,
        body,
        label: 'USER_PROFILE',
      });
    } catch (e) {
      handleShowClicked();
      return;
    }
    handleShowClicked();
    fetchData();
  });

  const clearProjectField = useCallback(() => {
    setValue('project', null);
  }, [setValue]);

  const getDataDefaultValue = () => {
    if (typeof data?.startTime === 'string') {
      return '';
    }
    return data?.startTime.toDateString();
  };

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isDialogOpen}
      onClose={handleShowClicked}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: `USER_PROFILE.DATA_DIALOG_HEADER`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton aria-label="close" onClick={handleShowClicked}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledSelect
                label="TABLE.HEAD.EMPLOYER"
                name="employer"
                control={control}
                helperText
                options={employersData}
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                label="TABLE.HEAD.CLIENT"
                name="client"
                control={control}
                helperText
                options={clientData}
                handleChange={clearProjectField}
                required
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                label="TABLE.HEAD.PROJECT"
                name="project"
                control={control}
                helperText
                options={projectOptions}
                required
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                options={options.map((option) => {
                  return {
                    value: option.value,
                    name: intl.formatMessage({
                      id: `BUSINESS_TRIP.${option.name.split(' ').join('_')}`,
                    }),
                  };
                })}
                name="contractType"
                label={'USER_PROFILE.CONTRACT_TYPE'}
                helperText
                control={control}
              />
            </GridItem>
            <GridItem xs={12} item>
              <TimePickerWrapper>
                <ControlledTimePicker
                  id="startTime"
                  name="startTime"
                  control={control}
                  required
                  label={'USER_PROFILE.DEFAULT_START_TIME'}
                  defaultValue={getDataDefaultValue()}
                  onChange={handleStartTimeChange}
                  tooltipMessage={'USER_PROFILE.GENERAL.STARTTIME_TOOLTIP'}
                />
              </TimePickerWrapper>
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                name="personalId"
                label={'USER_PROFILE.PERSONAL_ID'}
                control={control}
                defaultValue={data?.personalId}
              />
            </GridItem>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={handleShowClicked}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
            disabled={!formState.isValid || project === null}
          >
            {intl.formatMessage({
              id: `USER_PROFILE.DIALOG_SUBMIT`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default EditDialog;
