import { Dispatch, SetStateAction } from 'react';
import { Control } from 'react-hook-form';
import { Timesheet } from 'components/Table/types';
import { FilterOption } from 'utils/helpers/renameKeys';

export type paginationKeyTypes = 'pageSize' | 'pageNumber';

export type TableDataSetter = Dispatch<Timesheet[]>;
export type LoadingSetter = Dispatch<SetStateAction<boolean>>;
export type TotalWorkingTimeSetter = Dispatch<SetStateAction<string>>;

export interface QueryData {
  month?: number;
  year?: number;
  client: { value?: number };
  project: { value?: number };
  account: { value?: number };
  paginationNumber: number;
  paginationSize: number;
}

export type Details = { [id: number]: boolean };

export enum Filtering {
  project = 'project',
  client = 'client',
  name = 'account',
}

export interface TimsheetsFilter {
  options?: FilterOption[];
  type: Filtering;
  label: string;
  handleChange?: () => void;
}

type StringValue = string | null | undefined;
type NumberValue = number | undefined;

interface ObjectValues {
  name: StringValue;
  value: NumberValue;
}

interface ProjectObjectValues extends ObjectValues {
  client?: {
    clientName: StringValue;
    id: NumberValue;
  };
}
interface ClientObjectValues extends ObjectValues {
  clientName: StringValue;
}

export interface FormDataProps {
  account: ObjectValues;
  project: ProjectObjectValues;
  client: ClientObjectValues;
  showFullTimesheet: boolean;
  myTimesheetsChecked: boolean;
  emptyTimesheetsChecked: boolean;
  approvedTimesheetsChecked: boolean;
  readyForApprovalChecked: boolean;
}

export type FormValues = {
  client: Filtering.client;
  project: Filtering.project;
  name: Filtering.name;
};

export type TimesheetBoxProps = {
  anchorEl: HTMLElement | null;
  handleRemindClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleRemindClose: () => void;
  timesheetsFilterData: TimsheetsFilter[];
  control: Control<FormDataProps>;
  getValues: () => FormDataProps;
  client: ClientObjectValues;
  project: ProjectObjectValues;
  date: Date | null;
  onDateChange: (date: SetStateAction<Date | null>) => void;
  onLeaderReminderClick: () => void;
  onEmployeeReminderClick: () => void;
  loading: boolean;
  handleDatepickerClearButtonClick: () => void;
};

export interface TimesheetUrlProps {
  paginationNumber: number;
  paginationSize: number;
  month?: number;
  year?: number;
  accountValue?: number;
  clientValue?: number;
  projectValue?: number;
  approvedTimesheetsChecked: boolean;
  emptyTimesheetsChecked: boolean;
  showFullTimesheet: boolean;
  myTimesheetsChecked: boolean;
  readyForApprovalChecked?: boolean;
}

export interface TimesheetFiltersProps {
  control: Control<FormDataProps>;
  loading: boolean;
  setOpen: () => void;
  open: boolean;
  client: ClientObjectValues;
  project: ProjectObjectValues;
}

export type StatusToConfirm = 'APPROVE' | 'UNAPPROVE';
