import { Tooltip } from '@material-ui/core';
import { HeaderTooltipText } from 'components/Table/Table.css';
import { CalendarDayProps } from './types';
import {
  DateTextWrapper,
  DayWrapper,
  FreeDayAnnotation,
} from './CalendarComponent.css';

const CalendarDay = ({ day, freeDays }: CalendarDayProps) => {
  const date = day.getDate();
  const foundIndex = freeDays.findIndex(
    (freeDay) =>
      new Date(freeDay.date).getDate() === date &&
      new Date(freeDay.date).getMonth() === day.getMonth(),
  );

  const isFreeDay = foundIndex !== -1;

  return (
    <DayWrapper id={`${date}`}>
      {isFreeDay ? (
        <>
          <FreeDayAnnotation
            $isCalendarHoliday={!freeDays[foundIndex]?.editable}
          />
          <Tooltip
            title={
              <HeaderTooltipText>
                {freeDays[foundIndex].title || ''}
              </HeaderTooltipText>
            }
            placement="top"
          >
            <DateTextWrapper $freeDay={isFreeDay}>{date}</DateTextWrapper>
          </Tooltip>
        </>
      ) : (
        <DateTextWrapper $freeDay={isFreeDay}>{date}</DateTextWrapper>
      )}
    </DayWrapper>
  );
};

export default CalendarDay;
