import React, { ReactNode } from 'react';
import * as Styles from './Header.css';

interface Props {
  children: ReactNode;
  onClose: () => void;
  anchorEl?: Element | ((element: Element) => Element) | null;
}

const Menu = ({ children, onClose, anchorEl }: Props) => (
  <Styles.StyledMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    id="customized-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    {children}
  </Styles.StyledMenu>
);

export default Menu;
