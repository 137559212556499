import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const PROJECTS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.CONTACT_PERSON',
    key: 'contactPerson',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.DESCRIPTION',
    key: 'description',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
];

export const TAB_LABELS = [{ label: 'PROJECTS.PROJECTS_TAB', key: 0 }];
