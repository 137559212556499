import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import Datepicker from 'components/Datepicker';
import { DatepickerFormControlTypes } from '../../types';
import { valiadateMaxDate, valiadateMinDate } from './utils';

const LeaveRequestFormDataPicker = ({
  control,
  name,
  setValue,
  endDate,
  startDate,
  leaveType,
}: DatepickerFormControlTypes) => {
  const filterWeekends = (date: MaterialUiPickersDate) => {
    return moment(date).isoWeekday() > 5;
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        type={name}
        control={control}
        render={({ onChange, value, name }) => (
          <Datepicker
            type={name}
            value={value}
            onChange={onChange}
            required
            autoOk
            format={'dd-MM-yyyy'}
            shouldDisableDate={filterWeekends}
            maxDate={valiadateMaxDate(leaveType, name, startDate, endDate)}
            minDate={valiadateMinDate(leaveType, name, startDate, endDate)}
            onClearButtonClick={() => {
              setValue(name, null);
            }}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default LeaveRequestFormDataPicker;
