import { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { benefitsUrl } from 'router/url';
import {
  BenefitFetchedData,
  BenefitManagerFormValues,
  Status,
} from 'components/BenefitInputs/types';
import {
  deleteFetch,
  getFetch,
  patchFetch,
  postFetch,
  putFetch,
} from 'utils/fetchFunctions';
import { getFormattedData } from './utils';

export const postBenefit = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  intl: IntlShape,
  body: BenefitFetchedData,
) => {
  const newBody = {
    ...body,
    benefitTypes: body.benefitTypes.map((el) => ({
      ...el,
      id: undefined,
    })),
  };
  return postFetch<BenefitFetchedData>({
    url: benefitsUrl,
    body: newBody,
    intl,
    label: `BENEFITS.ADDITION`,
    setLoading,
  });
};

export const updateBenefit = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  intl: IntlShape,
  body: BenefitFetchedData,
  id: number,
) => {
  const newBody = {
    ...body,
    benefitTypes: body.benefitTypes.map((el) => ({
      ...el,
      id: undefined,
    })),
  };
  return putFetch<BenefitFetchedData>({
    url: `${benefitsUrl}/${id}`,
    body: newBody,
    intl,
    label: 'BENEFITS.UPDATE',
    setLoading,
  });
};

export const getBenefits = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
): Promise<BenefitManagerFormValues[]> => {
  const response: BenefitFetchedData[] = await getFetch({
    url: benefitsUrl,
    setLoading,
  });
  return response?.map(getFormattedData);
};

export const deleteBenefit = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  intl: IntlShape,
  id: number,
) =>
  deleteFetch({
    url: `${benefitsUrl}/${id}`,
    intl,
    label: `BENEFITS.REMOVAL`,
    setLoading,
  });

export const patchBenefitStatus = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  intl: IntlShape,
  id: number,
  status?: Status,
) => {
  const isActive = status === Status.active;
  return patchFetch({
    url: `${benefitsUrl}/${id}`,
    intl,
    body: !isActive,
    label: `BENEFITS.${isActive ? 'DEACTIVATION' : 'ACTIVATION'}`,
    setLoading,
  });
};
