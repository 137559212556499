import { ReactNode } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import {
  BenefitAccountFormValues,
  BenefitManagerFormValues,
  FormValues,
  TimeRangeData,
} from 'components/BenefitInputs/types';

export interface Props {
  isPartnerTypeAdult: (index?: number) => boolean;
  isAdultSelected?: boolean;
  getValues: () => BenefitAccountFormValues;
  setValue: (name: string, value: unknown) => void;
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[];
  control: Control<BenefitAccountFormValues>;
  errors: DeepMap<FormValues, FieldError>;
}

export type PartnerType = 'partner' | 'child';

export interface InputProps {
  index?: number;
  isEditing?: boolean;
  isCrsp?: boolean;
  addressType?: 'address' | 'crspAddress';
  type?: PartnerType;
}

export interface PartnerFieldProps {
  children: ReactNode;
  isLast?: boolean;
  handleAdd: () => void;
  handleRemove?: () => void;
  index?: number;
}

export enum AddressData {
  city = 'city',
  zipCode = 'zipCode',
  street = 'street',
  houseNumber = 'houseNumber',
  apartmentNumber = 'apartmentNumber',
}

export enum StaticInputNames {
  areAddressesDifferent = 'areAddressesDifferent',
  partnerType = 'partnerType',
}
