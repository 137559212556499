import { IntlShape } from 'react-intl';
import moment from 'moment';
import {
  BenefitFetchedData,
  BenefitManagerFormValues,
  BenefitTypesProps,
  ChildProperties,
  FetchedTimeRangeData,
  Packages,
  PartnerProperties,
  PersonCosts,
  SelectDataWithType,
  Status,
  TimeRangeData,
} from 'components/BenefitInputs/types';
import { StepTitlesData } from 'components/ControlledStepper/types';
import { ConfirmationDialogTypes } from './BenefitManagerContext/types';

export const getFormattedName = (name?: string | Packages, index?: number) => {
  let separator: string | RegExp;
  if (name === 'crspAddress') {
    name = 'addressForCorrespondence';
  }
  if (name?.toString()?.includes(' ')) {
    separator = ' ';
  } else {
    separator = /(?=[A-Z])/;
  }

  if (index || index === 0) {
    return name?.split(separator).map((word) => word.toUpperCase())[index];
  }

  return name
    ?.toString()
    ?.split(separator)
    .map((word) => word.toUpperCase())
    .join('_');
};

export const getFirstCellStyle = (index: number) => {
  if (!index) {
    return { paddingLeft: '3rem' };
  }
};

export const getStepTitles = (intl: IntlShape): StepTitlesData[] => [
  {
    label: intl.formatMessage({ id: 'BENEFITS.GENERAL' }),
    id: 'generalLabel',
  },
  {
    label: intl.formatMessage({ id: 'BENEFITS.COSTS' }),
    id: 'costsLabel',
  },
  {
    label: intl.formatMessage({ id: 'BENEFITS.REQUIRED_INPUTS' }),
    id: 'requiredInputsLabel',
  },
  {
    label: intl.formatMessage({ id: 'BENEFITS.ACTIONS' }),
    id: 'actionsLabel',
  },
  {
    label: intl.formatMessage({ id: 'BENEFITS.SUMMARY' }),
    id: 'summaryLabel',
  },
];

export const getFormStep = (
  intl: IntlShape,
  getValues: () => BenefitManagerFormValues,
  formState: BenefitManagerFormValues,
  step: number | 'last' | 'first',
) => {
  const checkStep = () => {
    if (typeof step === 'number') {
      return step;
    } else {
      if (step === 'first') {
        return 0;
      }
      if (step === 'last') {
        return getStepTitles(intl).length - 1;
      }
    }
  };

  if (getValues().usingOwnForm ?? formState.usingOwnForm) {
    return checkStep();
  } else {
    return undefined;
  }
};

export const getCost = (
  intl: IntlShape,
  type: BenefitTypesProps,
  packageName: Packages | PersonCosts,
) => {
  return `${intl.formatMessage({
    id: `BENEFITS.FORM.COST`,
  })}: ${Number(type[packageName]?.cost).toFixed(2)} zł`;
};

export const getStringValue = (textValue: string | number | null) =>
  textValue ? String(textValue) : '';

export const getDefaultCostValue = (
  benefitTypes: BenefitTypesProps[],
  index: number,
  property?: Packages | PersonCosts,
) => {
  const formStateCost = property
    ? benefitTypes?.[index]?.[property]?.cost
    : benefitTypes?.[index]?.companyCost;

  return formStateCost || formStateCost === 0
    ? getStringValue(Number(formStateCost)?.toFixed(2))
    : '';
};

export const getDefaultSelectValue = (
  intl: IntlShape,
  data: SelectDataWithType | null,
) => ({
  name: intl.formatMessage({
    id: `BENEFITS.${data?.type?.toUpperCase()}`,
  }),
  type: data?.type,
  value: data?.value ?? null,
});

export const getChildPropertyValue = (
  benefitTypes: BenefitTypesProps[],
  index: number,
  property: Packages.family | PersonCosts.child,
  childProperty: ChildProperties,
) => {
  const defaultValue = benefitTypes.find(
    (type) => !!type[property]?.[childProperty],
  )?.[property]?.[childProperty];

  const formStateValue = benefitTypes?.[index]?.[property]?.[childProperty];

  return (
    getStringValue(formStateValue ?? '') || getStringValue(defaultValue ?? '')
  );
};

export const getPartnerPropertyValue = (
  benefitTypes: BenefitTypesProps[],
  index: number,
  property: Packages.partner,
  partnerProperty: PartnerProperties,
) => {
  const defaultValue = benefitTypes.find(
    (type) => !!type[property]?.[partnerProperty],
  )?.[property]?.[partnerProperty];

  const formStateValue = benefitTypes?.[index]?.[property]?.[partnerProperty];

  return (
    getStringValue(formStateValue ?? '') || getStringValue(defaultValue ?? '')
  );
};

const getFormattedTypeProperty = ({
  type,
  property,
}: {
  type: BenefitTypesProps;
  property: Packages | PersonCosts;
}) => {
  const data = {
    active: type[property]?.active ?? false,
    cost: Number(type[property]?.cost) ?? null,
  };

  const getChildPropertyValue = (childProperty: ChildProperties) => {
    if (type[property]?.[childProperty]) {
      return Number(type[property]?.[childProperty]);
    }
    return null;
  };

  const childData = (property === Packages.family ||
    property === Packages.partner ||
    property === PersonCosts.child) && {
    maxChildAge: getChildPropertyValue(ChildProperties.maxAge),
    minChildAge: getChildPropertyValue(ChildProperties.minAge),
    numberOfChildren: getChildPropertyValue(ChildProperties.amount),
  };

  if (
    property === Packages.family ||
    property === Packages.partner ||
    property === PersonCosts.child
  ) {
    return {
      ...data,
      ...childData,
    };
  }

  if (property === Packages.individual || property === PersonCosts.employee) {
    return {
      ...data,
      active: true,
    };
  }

  return data;
};

const getTimeRangeFormattedPostData = (type: TimeRangeData) => {
  let date = null;
  if (moment(type.fixedDate).isValid()) {
    date = moment(type.fixedDate).format('MM-DD');
  }
  return {
    ...type,
    fixedDate: date,
    numberOfDaysMonthsYears: Number(type.numberOfDaysMonthsYears),
  };
};

export const getFormattedPostData = (
  formState: BenefitManagerFormValues,
): BenefitFetchedData => {
  const {
    childInputs,
    employeeInputs,
    partnerInputs,
    comments,
    timeToChangeToLowerType,
    timeToChangeToHigherType,
    timeToChangeToLowerPackage,
    timeToChangeToHigherPackage,
    timeToAddPartner,
    timeToRemovePartner,
    ...data
  } = formState;

  return {
    ...data,
    mandatoryEmployeeInputs: employeeInputs,
    mandatoryPartnerInputs: partnerInputs,
    mandatoryChildInputs: childInputs,
    comments: comments,
    lastDayToSendChanges: Number(data.lastDayToSendChanges),
    timeToChangeToLowerType: getTimeRangeFormattedPostData(
      timeToChangeToLowerType,
    ),
    timeToChangeToHigherType: getTimeRangeFormattedPostData(
      timeToChangeToHigherType,
    ),
    timeToChangeToLowerPackage: getTimeRangeFormattedPostData(
      timeToChangeToLowerPackage,
    ),
    timeToChangeToHigherPackage: getTimeRangeFormattedPostData(
      timeToChangeToHigherPackage,
    ),
    timeToAddPartner: getTimeRangeFormattedPostData(timeToAddPartner),
    timeToRemovePartner: getTimeRangeFormattedPostData(timeToRemovePartner),
    benefitTypes: data.benefitTypes.map((type) => ({
      ...type,
      companyCost: Number(type.companyCost),
      individualPackage: getFormattedTypeProperty({
        type,
        property: Packages.individual,
      }),
      partnerPackage: getFormattedTypeProperty({
        type,
        property: Packages.partner,
      }),
      familyPackage: getFormattedTypeProperty({
        type,
        property: Packages.family,
      }),
      employeeCost: getFormattedTypeProperty({
        type,
        property: PersonCosts.employee,
      }),
      partnerCost: getFormattedTypeProperty({
        type,
        property: PersonCosts.partner,
      }),
      childCost: getFormattedTypeProperty({
        type,
        property: PersonCosts.child,
      }),
    })),
  };
};

export const getTimeRangeFormattedData = (type: FetchedTimeRangeData) => {
  let date = null;
  if (moment(type.fixedDate).isValid()) {
    date = moment(type.fixedDate).toDate();
  }
  return {
    ...type,
    fixedDate: date,
  };
};

export const getFormattedData = (
  data: BenefitFetchedData,
): BenefitManagerFormValues => ({
  ...data,
  employeeInputs: data?.mandatoryEmployeeInputs,
  partnerInputs: data?.mandatoryPartnerInputs,
  childInputs: data?.mandatoryChildInputs,
  timeToChangeToLowerType: getTimeRangeFormattedData(
    data.timeToChangeToLowerType,
  ),
  timeToChangeToHigherType: getTimeRangeFormattedData(
    data.timeToChangeToHigherType,
  ),
  timeToChangeToLowerPackage: getTimeRangeFormattedData(
    data.timeToChangeToLowerPackage,
  ),
  timeToChangeToHigherPackage: getTimeRangeFormattedData(
    data.timeToChangeToHigherPackage,
  ),
  timeToAddPartner: getTimeRangeFormattedData(data.timeToAddPartner),
  timeToRemovePartner: getTimeRangeFormattedData(data.timeToRemovePartner),
});

export const getConfirmationDialogData = (
  intl: IntlShape,
  openedConfirmationDialog: ConfirmationDialogTypes,
  selectedBenefitName?: string,
  status?: Status,
) => {
  switch (openedConfirmationDialog) {
    case 'changingStatus': {
      switch (status) {
        case Status.active:
          return {
            title: `${intl.formatMessage({
              id: 'BENEFITS.DEACTIVATE_DIALOG_TITLE',
            })} ${selectedBenefitName}`,
            content: `${intl.formatMessage({
              id: 'BENEFITS.DEACTIVATE_DIALOG_CONTENT',
            })} ${selectedBenefitName}?`,
          };
        case Status.inactive:
          return {
            title: `${intl.formatMessage({
              id: 'BENEFITS.ACTIVATE_DIALOG_TITLE',
            })} ${selectedBenefitName}`,
            content: `${intl.formatMessage({
              id: 'BENEFITS.ACTIVATE_DIALOG_CONTENT',
            })} ${selectedBenefitName}?`,
          };
        default:
          return { title: '', content: '' };
      }
    }
    case 'removingBenefit':
      return {
        title: `${intl.formatMessage({
          id: 'BENEFITS.REMOVING_DIALOG_TITLE',
        })} ${selectedBenefitName}`,
        content: `${intl.formatMessage({
          id: 'BENEFITS.REMOVING_DIALOG_CONTENT',
        })} ${selectedBenefitName}?`,
      };
    default:
      return { title: '', content: '' };
  }
};

export const stringEnumIncludesProperty = (
  stringEnum: Record<string, string>,
  property: string,
) => Object.values(stringEnum).includes(property);
