import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  InfoContainer,
  InputTitle,
  StepGroup,
  StepTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext/index';
import { getGeneralValues } from './utils';

const General = () => {
  const { state } = useContext(BenefitContext);
  const intl = useIntl();

  return (
    <StepGroup container spacing={3} justifyStart>
      <StepTitle>{intl.formatMessage({ id: 'BENEFITS.GENERAL' })}</StepTitle>
      {getGeneralValues(intl, state)?.map(({ content, title }) =>
        content ? (
          <InfoContainer item xs={6} key={title}>
            <InputTitle>{title}</InputTitle>
            <span>{content}</span>
          </InfoContainer>
        ) : null,
      )}
    </StepGroup>
  );
};

export default General;
