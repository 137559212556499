import { Link } from 'react-router-dom';
import palette from 'assets/palette';
import styled from 'styled-components';

interface LinkProps {
  color?: string;
}

const NavLink = styled(Link)<LinkProps>`
  color: ${({ color }) => (color ? color : 'white')};
  text-decoration: none;
  padding: 0 0.4rem;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${palette.ACTION_LIGHTER};
    transition: hover 0.5s ease-in-out;
  }
`;

export default NavLink;
