import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { getFormattedName } from '../../../utils';
import { InputNames } from '../types';
import InfoGroup from './InfoGroup';
import { BorderedName } from '../../../BenefitsManager.css';

interface Props {
  inputType: InputNames;
}

const InputsContainer = ({ inputType }: Props) => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  return formState[inputType]?.length ? (
    <InfoGroup
      title={intl.formatMessage({
        id: `BENEFITS.FORM.${getFormattedName(inputType)}`,
      })}
      justifyStart
    >
      {formState[inputType]?.map(({ type }) => (
        <BorderedName key={`${type}_${inputType}`}>
          {intl.formatMessage({
            id: `BENEFITS.FORM.${getFormattedName(type)}`,
          })}
        </BorderedName>
      ))}
    </InfoGroup>
  ) : null;
};

export default InputsContainer;
