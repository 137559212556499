import { IntlShape } from 'react-intl';
import { deleteFetch, getFetch, postFetch } from 'utils/fetchFunctions';
import { employeesUrl, exlusionsUrl } from '../../../../router/url';

interface FetchTypes {
  username?: string;
  intl?: IntlShape;
}

export const fetchEmployeeListWithUsername = async () => {
  return getFetch({ url: employeesUrl });
};

export const fetchExcludedEmployees = async () => {
  return getFetch({ url: exlusionsUrl });
};

export const postEmployeeToExclude = async ({ username, intl }: FetchTypes) => {
  return postFetch({
    label: 'ADMIN_PANEL.EXCLUDE_POST',
    intl,
    url: `${exlusionsUrl}/${username}`,
  });
};

export const deleteEmployeeFromExcludedEmployees = async ({
  username,
  intl,
}: FetchTypes) => {
  return deleteFetch({
    label: 'ADMIN_PANEL.EXCLUDE_DELETE',
    intl,
    url: `${exlusionsUrl}/${username}`,
  });
};
