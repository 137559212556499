import {
  BenefitManagerFormValues,
  InputNames,
  InputProps,
  Status,
} from 'components/BenefitInputs/types';
import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TABLE_HEAD_DATA: TableHead[] = [
  {
    label: 'BENEFITS.TABLE_HEAD.NAME',
    key: 'benefitName',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'BENEFITS.FORM.BENEFIT_CATEGORY',
    key: 'benefitCategory',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
  {
    label: 'BENEFITS.TABLE_HEAD.EMAIL',
    key: 'benefitEmail',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'BENEFITS.TABLE_HEAD.DEADLINE',
    key: 'benefitDeadline',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
    tooltip: 'BENEFITS.FORM.DAY_OF_DEADLINE_TOOLTIP',
  },
  {
    label: 'TABLE.HEAD.STATUS',
    key: 'benefitStatus',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
  {
    label: 'TABLE.HEAD.ACTIONS',
    key: 'benefitActions',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
];

export const AVAILABLE_INPUTS: InputProps[] = Object.values(InputNames).map(
  (input, index) => ({
    name: input,
    type: input,
    value: index + 1,
  }),
);

export const BENEFITS_DATA: BenefitManagerFormValues[] = [
  {
    id: 1,
    benefitName: 'Medicover',
    benefitTypes: [
      {
        benefitTypeName: 'Zdrowie Ekstra',
        companyCost: 100,
        individualPackage: {
          active: true,
          cost: 100,
        },
        partnerPackage: {
          active: true,
          cost: 120,
        },
        familyPackage: {
          active: true,
          cost: 140,
        },
      },
      {
        benefitTypeName: 'Zdrowie Premium',
        companyCost: 100,
        individualPackage: {
          active: true,
          cost: 150,
        },
        partnerPackage: {
          active: true,
          cost: 170,
        },
        familyPackage: {
          active: true,
          cost: 190,
        },
      },
      {
        benefitTypeName: 'Zdrowie Premium Plus',
        companyCost: 100,
        individualPackage: {
          active: true,
          cost: 200,
        },
        partnerPackage: {
          active: true,
          cost: 220,
        },
        familyPackage: {
          active: true,
          cost: 240,
        },
      },
    ],
    employeeInputs: [],
    partnerInputs: [],
    editableInputs: [],
    usingOwnForm: false,
    lastDayToSendChanges: 14,
    benefitCategory: { name: 'Medical', type: 'Medical', value: 1 },
    benefitEmail: 'medicover@email.com',
    status: Status.active,
    comments: 'Additional info',
    timeToChangeToLowerType: {
      dayMonthYear: { name: 'Year', type: 'Year', value: 3 },
      numberOfDaysMonthsYears: 1,
      fixedDate: null,
    },
    timeToChangeToHigherType: {
      dayMonthYear: { name: 'Days', type: 'Day', value: 1 },
      numberOfDaysMonthsYears: 22,
      fixedDate: null,
    },
    timeToChangeToLowerPackage: {
      dayMonthYear: { name: 'Months', type: 'Month', value: 2 },
      numberOfDaysMonthsYears: 6,
      fixedDate: null,
    },
    timeToChangeToHigherPackage: {
      dayMonthYear: { name: '', type: '', value: null },
      numberOfDaysMonthsYears: null,
      fixedDate: null,
    },
    timeToRemovePartner: {
      dayMonthYear: { name: 'Days', type: 'Day', value: 1 },
      numberOfDaysMonthsYears: 20,
      fixedDate: null,
    },
    timeToAddPartner: {
      dayMonthYear: { name: '', type: '', value: null },
      numberOfDaysMonthsYears: null,
      fixedDate: null,
    },
  },
];
