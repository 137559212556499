import { CircularProgress } from '@material-ui/core';
import { TableBody, TableCell, TableRow } from 'components/Table/Table.css';
import { ProgressWrapper } from './TableLoadingWrapper.css';

interface TableLoadingWrapperProps {
  loading: boolean;
  children: JSX.Element;
}

const TableLoadingWrapper = ({ loading, children }: TableLoadingWrapperProps) =>
  loading ? (
    <TableBody>
      <TableRow>
        <TableCell align={'center'}>
          <ProgressWrapper>
            <CircularProgress size={100} />
          </ProgressWrapper>
        </TableCell>
      </TableRow>
    </TableBody>
  ) : (
    children
  );

export default TableLoadingWrapper;
