import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { DateContext } from 'globalContext/DateContext';
import { GlobalContext } from 'globalContext/GlobalContext';
import { UserContext } from 'globalContext/UserContext';
import {
  businessMonthYearUrl,
  remindEmployeesAboutTimesheets,
  timesheetsApproveUrl,
  timesheetsBatchApproveReminder,
  timesheetsUndoApprovalUrl,
  timesheetsUndoPartialApprovalUrl,
  timesheetsUrl,
} from 'router/url';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import NoteDialog from 'components/Dialog/NoteDialog';
import Table from 'components/Table/TableTimesheets';
import { TeamLeader, Timesheet } from 'components/Table/types';
import {
  checkIfRemiderSent,
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { getFetch, patchFetch, postFetch } from 'utils/fetchFunctions';
import {
  filterDropdownProjectData,
  getRenamedProjectsData,
  setClientsValue,
} from 'utils/helpers/filterDropdownData';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import useDebounce from 'utils/hooks/useDebounce';
import { usePagination } from 'utils/hooks/usePagination';
import useQuery from 'utils/hooks/useQuery';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import { TABLE_HEAD_MOCK_DATA } from './mock_data';
import TimesheetsBox from './TimesheetsBox';
import {
  Details,
  Filtering,
  FormDataProps,
  LoadingSetter,
  QueryData,
  StatusToConfirm,
  TableDataSetter,
  TotalWorkingTimeSetter,
} from './types';
import { getTimesheetUrlWithParams } from './utils';

const fetchAction = async (
  queryData: QueryData,
  setLoading: LoadingSetter,
  setTableData: TableDataSetter,
  setTotalWorkingTimeDuration: TotalWorkingTimeSetter,
  setElementsCount: (value: number) => void,
  setPageSize: (value: number) => void,
  setPageNumber: (value: number) => void,
  setIsTimesheetEmpty: Dispatch<SetStateAction<boolean>>,
  myTimesheetsChecked: boolean,
  emptyTimesheetsChecked: boolean,
  approvedTimesheetsChecked: boolean,
  showFullTimesheet: boolean,
  readyForApprovalChecked: boolean,
) => {
  const {
    paginationNumber,
    paginationSize,
    account,
    project,
    client,
    year,
    month,
  } = queryData;

  setLoading(true);

  try {
    const timesheetsResponse = await getFetch({
      url: getTimesheetUrlWithParams({
        paginationNumber,
        paginationSize,
        month,
        year,
        accountValue: account?.value,
        clientValue: client?.value,
        projectValue: project?.value,
        approvedTimesheetsChecked,
        emptyTimesheetsChecked,
        showFullTimesheet,
        myTimesheetsChecked,
        readyForApprovalChecked,
      }),
    });
    setLoading(false);

    if (timesheetsResponse?.pageable) {
      const {
        totalElements,
        pageable: { pageNumber, pageSize },
      } = timesheetsResponse;
      if (
        timesheetsResponse.pageable.pageNumber !== paginationNumber ||
        timesheetsResponse.pageable.pageSize !== paginationSize
      ) {
        setPageSize(pageSize);
        setPageNumber(pageNumber);
      }
      setElementsCount(totalElements);
    }
    if (timesheetsResponse?.totalWorkingTimeDuration) {
      setTotalWorkingTimeDuration(timesheetsResponse.totalWorkingTimeDuration);
    }

    if (timesheetsResponse?.content) {
      setTableData(timesheetsResponse.content);
    }
    if (timesheetsResponse?.empty) {
      setIsTimesheetEmpty(!!timesheetsResponse.empty);
    }
    if (!timesheetsResponse) {
      setIsTimesheetEmpty(true);
    }
  } catch (e) {
    setLoading(false);
  }
};

const generateParams = (
  stringName: string,
  paramName: { name?: string; value?: number },
): string =>
  paramName?.name && paramName?.value
    ? `&${stringName}Id=${paramName.value}&${stringName}Name=${paramName.name}`
    : '';

const setQuery = (
  queryData: QueryData,
  history: any,
  myTimesheetsChecked: boolean,
  emptyTimesheetsChecked: boolean,
) => {
  const { account, project, client, year, month } = queryData;
  history.push({
    pathname: '/timesheets',
    search:
      `?` +
      `${year && month ? `year=${year}&month=${month}` : ''}` +
      `${generateParams('account', account)}` +
      `${generateParams('project', project)}` +
      `${generateParams('client', client)}` +
      `${myTimesheetsChecked ? '&mytimesheets=true' : ''}` +
      `${emptyTimesheetsChecked ? '&showAll=true' : ''}`,
  });
};

const Timesheets = () => {
  const [isTimesheetEmpty, setIsTimesheetEmpty] = useState(false);
  const [tableData, setTableData] = useState<Timesheet[]>([]);
  const [totalWorkingTime, setTotalWorkingTimeDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [detailsVisibility, setDetailsVisibility] = useState<Details>({});
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [tableDataMaxItemsCount, setTableDataMaxItemsCount] = useState(0);
  const [filteredProjectsData, setFilteredProjectsData] = useState<
    ProjectsData[]
  >([]);
  const [timesheetId, setTimesheetId] = useState<number>(0);
  const [statusToConfirm, setStatusToConfirm] = useState<StatusToConfirm>();
  const [leaderReminderSent, setLeaderReminderSent] = useState(false);

  const [employeeReminderSent, setEmployeeReminderSent] = useState(false);
  const [openNotLeaderExplanation, handleNotLeaderExplanationClick] =
    useToggleState(false);
  const [openStatusConfirmation, handleStatusConfirmation] =
    useToggleState(false);

  const history = useHistory();
  const query = useQuery();
  const { username } = useContext(UserContext);
  const { setDateGlobal } = useContext(GlobalContext);

  const yearFromQuery = useMemo(() => query.get('year'), [query]);
  const monthFromQuery = useMemo(() => query.get('month'), [query]);
  const projectNameFromQuery = useMemo(() => query.get('projectName'), [query]);
  const projectIdFromQuery = useMemo(() => query.get('projectId'), [query]);
  const accountNameFromQuery = useMemo(() => query.get('accountName'), [query]);
  const accountIdFromQuery = useMemo(() => query.get('accountId'), [query]);
  const clientNameFromQuery = useMemo(() => query.get('clientName'), [query]);
  const clientIdFromQuery = useMemo(() => query.get('clientId'), [query]);

  const { roles } = useContext(UserContext);
  const isAccounting = roles.includes(RoleTypes.RoleAccounting);
  const { date, setDate } = useContext(DateContext);

  const debouncedDateQuery = useDebounce(date, 200);

  const getBusinessMonthYear = async () => {
    try {
      setLoading(true);
      const businessMonthYear = await getFetch({ url: businessMonthYearUrl });

      if (businessMonthYear) {
        const { year, month } = businessMonthYear;
        if (year && month) {
          setDate(new Date(year, month - 1));
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setDate(null);
    }
  };

  useEffect(() => {
    if (!date) {
      if (yearFromQuery && monthFromQuery) {
        setDate(new Date(Number(yearFromQuery), Number(monthFromQuery) - 1));
      } else {
        getBusinessMonthYear();
      }
    }
    // run once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDateGlobal(date);
  }, [date, setDateGlobal]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleRemindClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleRemindClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDatepickerClearButtonClick = useCallback(() => {
    setDate(new Date());
    setDateGlobal(new Date());
  }, [setDateGlobal]);

  const [openLeaderReminder, handleLeaderReminderClick] = useToggleState(false);
  const [openEmployeeReminder, handleEmployeeReminderClick] =
    useToggleState(false);

  const { control, getValues, setValue } = useForm<FormDataProps>({
    shouldUnregister: false,
    defaultValues: {
      account: {
        name: accountNameFromQuery || undefined,
        value: accountIdFromQuery ? Number(accountIdFromQuery) : undefined,
      },
      project: {
        name: projectNameFromQuery || undefined,
        value: projectIdFromQuery ? Number(projectIdFromQuery) : undefined,
        client: {
          clientName: clientNameFromQuery || undefined,
          id: clientIdFromQuery ? Number(clientIdFromQuery) : undefined,
        },
      },
      client: {
        name: clientNameFromQuery || undefined,
        clientName: clientNameFromQuery || undefined,
        value: clientIdFromQuery ? Number(clientIdFromQuery) : undefined,
      },
      myTimesheetsChecked: isAccounting ? false : true,
      emptyTimesheetsChecked: isAccounting ? true : false,
      approvedTimesheetsChecked: false,
      showFullTimesheet: true,
      readyForApprovalChecked: isAccounting ? false : true,
    },
  });

  const formData: FormDataProps = useWatch({ control }) as FormDataProps;
  const {
    client,
    account,
    project,
    myTimesheetsChecked,
    emptyTimesheetsChecked,
    approvedTimesheetsChecked,
    showFullTimesheet,
    readyForApprovalChecked,
  } = formData;

  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
    setPageNumber,
    setPageSize,
  } = usePagination<FormDataProps>({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 500,
    data: formData,
  });

  useEffect(() => {
    if (pagination.totalElements > tableDataMaxItemsCount) {
      setTableDataMaxItemsCount(pagination.totalElements);
    }
  }, [pagination.totalElements, tableDataMaxItemsCount]);

  const clearProjectAndNameField = useCallback(() => {
    setValue('project', {
      name: undefined,
      value: undefined,
      client: {
        clientName: undefined,
        id: undefined,
      },
    });
    setValue('account', { value: undefined, name: undefined });
  }, [setValue]);

  const projectOptions = useMemo(
    () => getRenamedProjectsData(filteredProjectsData),
    [filteredProjectsData],
  );

  const TIMESHEETS_FILTER_DATA = [
    {
      label: 'TABLE_FILTER.LABEL.CLIENT',
      type: Filtering.client,
      options: clientsData,
      handleChange: clearProjectAndNameField,
    },
    {
      label: 'TABLE_FILTER.LABEL.PROJECT',
      type: Filtering.project,
      options: projectOptions,
    },
    {
      label: 'TABLE_FILTER.LABEL.EMPLOYEE',
      type: Filtering.name,
      options: employeesData,
    },
  ];

  const isTimesheetLoading =
    loading || (!tableData.length && !isTimesheetEmpty);

  const intl = useIntl();

  const toggleRowDetails = useCallback((id: number) => {
    setDetailsVisibility((prevDetailsVisibility) => ({
      ...prevDetailsVisibility,
      [id]: !prevDetailsVisibility[id],
    }));
  }, []);

  const onLeaderReminderClick = useCallback(() => {
    handleRemindClose();
    handleLeaderReminderClick();
  }, [handleLeaderReminderClick, handleRemindClose]);

  const onEmployeeReminderClick = useCallback(() => {
    handleRemindClose();
    handleEmployeeReminderClick();
  }, [handleEmployeeReminderClick, handleRemindClose]);

  const fetchTimesheets = useCallback(() => {
    const {
      client,
      project,
      account,
      myTimesheetsChecked,
      emptyTimesheetsChecked,
      approvedTimesheetsChecked,
      showFullTimesheet,
      readyForApprovalChecked,
    } = getValues();

    fetchAction(
      {
        client,
        project,
        account,
        year: debouncedDateQuery?.getFullYear(),
        month: debouncedDateQuery?.getMonth(),
        paginationNumber: debouncedPage,
        paginationSize: pagination.pageSize,
      },
      setLoading,
      setTableData,
      setTotalWorkingTimeDuration,
      setElementsCount,
      setPageNumber,
      setPageSize,
      setIsTimesheetEmpty,
      myTimesheetsChecked,
      emptyTimesheetsChecked,
      approvedTimesheetsChecked,
      showFullTimesheet,
      readyForApprovalChecked,
    );
  }, [
    getValues,
    debouncedDateQuery,
    debouncedPage,
    pagination.pageSize,
    setElementsCount,
    setPageNumber,
    setPageSize,
  ]);

  const onDateChange = useCallback(
    (date: SetStateAction<Date | null>) => {
      setDate(date);
      setDateGlobal(date);
      setCurrentPage(null, 0);
    },
    [setCurrentPage, setDateGlobal],
  );

  const updateTableData = useCallback(async () => {
    const response = await getFetch({
      url: getTimesheetUrlWithParams({
        paginationNumber: pagination.pageNumber,
        paginationSize: pagination.pageSize,
        month: date?.getMonth(),
        year: date?.getFullYear(),
        accountValue: account?.value,
        clientValue: client?.value,
        projectValue: project?.value,
        approvedTimesheetsChecked,
        emptyTimesheetsChecked,
        showFullTimesheet,
        myTimesheetsChecked,
      }),
    });
    if (response.content) {
      setTableData(response.content);
    }
  }, [
    account?.value,
    approvedTimesheetsChecked,
    client?.value,
    date,
    emptyTimesheetsChecked,
    myTimesheetsChecked,
    pagination.pageNumber,
    pagination.pageSize,
    project?.value,
    showFullTimesheet,
  ]);

  const patchApprovalStatus = useCallback(async () => {
    handleStatusConfirmation();
    setLoading(true);
    const isApproval = statusToConfirm === 'APPROVE';
    await patchFetch({
      url: isApproval ? timesheetsApproveUrl : timesheetsUndoApprovalUrl,
      body: isApproval ? { timesheetsIds: [timesheetId] } : [timesheetId],
      intl,
      label: `TIMESHEET.${statusToConfirm}`,
    });
    await updateTableData();
    setLoading(false);
  }, [
    intl,
    updateTableData,
    timesheetId,
    handleStatusConfirmation,
    statusToConfirm,
  ]);

  const onApproveTimesheet = useCallback(
    async (id: number, teamLeaders: TeamLeader[]) => {
      setTimesheetId(id);
      if (
        username &&
        teamLeaders.some((teamLeader) => teamLeader.userName === username)
      ) {
        setStatusToConfirm('APPROVE');
        handleStatusConfirmation();
      } else {
        handleNotLeaderExplanationClick();
      }
    },
    [handleNotLeaderExplanationClick, username, handleStatusConfirmation],
  );

  const onUndoApprove = useCallback(
    (id: number) => {
      setStatusToConfirm('UNAPPROVE');
      setTimesheetId(id);
      handleStatusConfirmation();
    },
    [handleStatusConfirmation],
  );

  const onPartialApproveTimesheet = useCallback(
    async (timesheetId: number, projectId: number, index: number) => {
      setTimesheetId(timesheetId);
      setLoading(true);
      await patchFetch({
        url: timesheetsApproveUrl,
        body: {
          timesheetsIds: [timesheetId],
          projectId: projectId,
        },
        intl,
        label: 'TIMESHEET.PARTIAL_APPROVE',
      });
      await updateTableData();
      toggleRowDetails(index);
      setLoading(false);
    },
    [intl, updateTableData, toggleRowDetails],
  );

  const onPartialUndoApproveTimesheet = useCallback(
    async (timesheetId: number, projectId: number, index: number) => {
      setTimesheetId(timesheetId);
      setLoading(true);
      await patchFetch({
        url: timesheetsUndoPartialApprovalUrl,
        body: {
          timesheetId: timesheetId,
          projectId: projectId,
        },
        intl,
        label: 'TIMESHEET.PARTIAL_UNAPPROVE',
      });
      await updateTableData();
      toggleRowDetails(index);
      setLoading(false);
    },
    [intl, updateTableData, toggleRowDetails],
  );

  const onBatchReminderClick = useCallback(
    async (note: string) => {
      setLoading(true);
      await postFetch({
        url: `${timesheetsBatchApproveReminder}`,
        body: { explanationNote: note },
        intl,
        label: 'TIMESHEET',
      });
      checkIfRemiderSent(
        'REMIND_ALL_LEADERS_ABOUT_UNACCEPTED_TIMESHEETS',
        date,
        setLeaderReminderSent,
      );
      setLoading(false);
    },
    [date, intl],
  );

  const handleEmployeeReminderSubmit = useCallback(
    async (note) => {
      setLoading(true);
      const body = {
        month: date!.getMonth() + 1,
        year: date!.getFullYear(),
        explanationNote: note,
      };
      await postFetch({
        url: remindEmployeesAboutTimesheets,
        body,
        intl,
        label: 'REMIND_EMPLOYEES',
      });
      checkIfRemiderSent(
        'REMIND_ALL_EMPLOYEES_TO_APPROVE_AND_SEND_THEIR_TIMESHEET',
        date,
        setEmployeeReminderSent,
      );
      setLoading(false);
    },
    [date, intl],
  );

  const onNotLeaderExplanationSubmitClick = useCallback(
    async (id: number, note: string) => {
      setLoading(true);
      await patchFetch({
        url: timesheetsApproveUrl,
        body: { note: note, timesheetsIds: [id] },
        intl,
        label: 'TIMESHEET.APPROVE',
      });
      await updateTableData();
      setLoading(false);
    },
    [intl, updateTableData],
  );

  const onExplanationClick = useCallback(
    (id: number, note: string, actionType: 'reminder' | 'explanation') => {
      let url = `${timesheetsUrl}/${id}/requests-to-clarification`;

      if (actionType === 'reminder') {
        url = `${timesheetsUrl}/${id}/reminders/employees`;
      }

      return postFetch({
        url: url,
        body: { explanationNote: note },
        intl,
        label: 'TIMESHEET',
      });
    },
    [intl],
  );

  const onLockIconClick = useCallback(
    async (username: string, settled: boolean) => {
      const monthWithTwoCharacters = (date!.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      await patchFetch({
        url:
          `${timesheetsUrl}` +
          `/${username}/settlement` +
          `?yearMonth=${date!.getFullYear()}-${monthWithTwoCharacters}` +
          `&settled=${settled}`,
        intl,
        label: settled
          ? 'TIMESHEET_EDITION_BLOCKED'
          : 'TIMESHEET_EDITION_UNBLOCKED',
      });

      await updateTableData();
    },
    [date, intl, updateTableData],
  );

  const setFilterDropdownData = useCallback(() => {
    setFilteredProjectsData(
      filterDropdownProjectData({
        projectsData: projectsData,
        clientNameFromClient: client?.name,
      }),
    );
    setClientsValue({
      setValue,
      projectNameFromProject: project?.name,
      clientNameFromClient: client?.name,
      clientNameFromProject: project?.client?.clientName,
      clientIdFromProject: project?.client?.id,
    });
  }, [client, project, projectsData, setValue]);

  useEffect(() => {
    if (debouncedDateQuery) {
      fetchTimesheets();
    }
  }, [
    fetchTimesheets,
    account,
    client,
    project,
    approvedTimesheetsChecked,
    myTimesheetsChecked,
    emptyTimesheetsChecked,
    debouncedDateQuery,
    showFullTimesheet,
    readyForApprovalChecked,
  ]);

  useEffect(() => {
    setQuery(
      {
        client,
        project,
        account,
        year: date?.getFullYear(),
        month: date ? date.getMonth() + 1 : undefined,
        paginationNumber: pagination?.pageNumber,
        paginationSize: pagination?.pageSize,
      },
      history,
      myTimesheetsChecked,
      emptyTimesheetsChecked,
    );
  }, [
    client,
    date,
    history,
    account,
    project,
    myTimesheetsChecked,
    emptyTimesheetsChecked,
    pagination?.pageNumber,
    pagination?.pageSize,
  ]);

  useEffect(() => {
    setDetailsVisibility(
      tableData.reduce((prev, curr, index) => {
        return { ...prev, [index]: false };
      }, {}),
    );
  }, [tableData]);

  useEffect(() => {
    fetchEmployeeList(setEmployeesData);
    fetchClientList(setClientsData);
    fetchProjectList(setProjectsData);
  }, []);

  useEffect(() => {
    setFilterDropdownData();
  }, [setFilterDropdownData]);

  useEffect(() => {
    if (date) {
      checkIfRemiderSent(
        'REMIND_ALL_LEADERS_ABOUT_UNACCEPTED_TIMESHEETS',
        date,
        setLeaderReminderSent,
      );
    }
  }, [date]);

  useEffect(() => {
    if (date) {
      checkIfRemiderSent(
        'REMIND_ALL_EMPLOYEES_TO_APPROVE_AND_SEND_THEIR_TIMESHEET',
        date,
        setEmployeeReminderSent,
      );
    }
  }, [date]);

  return !date ? null : (
    <Box overflow="auto" padding="1rem" minHeight={'100%'}>
      <form>
        <TimesheetsBox
          anchorEl={anchorEl}
          handleRemindClick={handleRemindClick}
          handleRemindClose={handleRemindClose}
          timesheetsFilterData={TIMESHEETS_FILTER_DATA}
          control={control}
          client={client}
          getValues={control.getValues}
          project={project}
          date={date}
          onDateChange={onDateChange}
          onLeaderReminderClick={onLeaderReminderClick}
          onEmployeeReminderClick={onEmployeeReminderClick}
          loading={isTimesheetLoading}
          handleDatepickerClearButtonClick={handleDatepickerClearButtonClick}
        />
        <Table
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          pageCount={pagination.totalElements}
          currentPage={pagination.pageNumber}
          withPagination={true}
          onMasterApprovalClick={onApproveTimesheet}
          onLeaderPartialApprovalClick={onPartialApproveTimesheet}
          onLeaderPartialUndoApprovalClick={onPartialUndoApproveTimesheet}
          onMasterUndoApprovalClick={onUndoApprove}
          onExplanationClick={onExplanationClick}
          onLockIconClick={onLockIconClick}
          limit={pagination.pageSize}
          loading={isTimesheetLoading}
          data={tableData}
          emptyDataMessage={
            tableDataMaxItemsCount
              ? intl.formatMessage(
                  { id: 'TABLE.BODY.EMPTY_DATA_COUNT' },
                  { count: tableDataMaxItemsCount },
                )
              : undefined
          }
          head={TABLE_HEAD_MOCK_DATA}
          detailsVisibility={detailsVisibility}
          setDetailsVisibility={toggleRowDetails}
          totalWorkingTime={totalWorkingTime}
        />
      </form>
      <NoteDialog
        addChecked={openLeaderReminder}
        handleAddChanged={handleLeaderReminderClick}
        onBatchReminderClick={onBatchReminderClick}
        headerLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.REMINDER.SUMBIT',
        })}
        submitButtonLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.REMINDER.SUMBIT',
        })}
        noteTitle={intl.formatMessage({
          id: leaderReminderSent
            ? 'TIMESHEET_LEADERS_REMINDER_ALREADY_SENT'
            : 'NOTE_DIALOG.REMINDER.NEW',
        })}
      />
      <NoteDialog
        addChecked={openNotLeaderExplanation}
        handleAddChanged={handleNotLeaderExplanationClick}
        onExplanationClick={onNotLeaderExplanationSubmitClick}
        timesheetsId={timesheetId}
        headerLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.NOT_LEADER_EXPLANATION.TITLE',
        })}
        submitButtonLabel={intl.formatMessage({
          id: 'MODAL.APPROVE',
        })}
        content={intl.formatMessage({
          id: 'NOTE_DIALOG.NOT_LEADER_EXPLANATION',
        })}
      />
      <ConfirmationDialog
        confirmMessage="MODAL.APPROVE"
        addChecked={openStatusConfirmation}
        handleAddChanged={handleStatusConfirmation}
        handleSubmit={patchApprovalStatus}
        title={intl.formatMessage({
          id:
            statusToConfirm === 'APPROVE'
              ? 'TIMESHEET_STATUS.APPROVE_TITLE'
              : 'TIMESHEET_STATUS.UNAPPROVE_TITLE',
        })}
        content={intl.formatMessage({
          id:
            statusToConfirm === 'APPROVE'
              ? 'TIMESHEET_STATUS.APPROVE_BODY'
              : 'TIMESHEET_STATUS.UNAPPROVE_BODY',
        })}
      />
      <NoteDialog
        addChecked={openEmployeeReminder}
        handleAddChanged={handleEmployeeReminderClick}
        onBatchReminderClick={handleEmployeeReminderSubmit}
        headerLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.REMINDER.SUMBIT',
        })}
        submitButtonLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.REMINDER.SUMBIT',
        })}
        noteTitle={
          employeeReminderSent
            ? intl.formatMessage(
                {
                  id: 'TIMESHEET_EMPLOYEES_REMINDER_ALREADY_SENT',
                },
                { month: date.getMonth() + 1, year: date.getFullYear() },
              )
            : intl.formatMessage(
                { id: 'REMIND_EMPLOYEES.TITLE' },
                { month: date.getMonth() + 1, year: date.getFullYear() },
              )
        }
      />
    </Box>
  );
};

export default Timesheets;
