import { ChangeEvent } from 'react';
import { QuickReportCustomProject, QuickReportProject } from './types';

export const getStatusTooltipTitle = (entry: QuickReportProject) => {
  if (entry.clientStatus === 'INACTIVE' && entry.projectStatus === 'INACTIVE') {
    return 'INACTIVE_CLIENT_PROJECT';
  }
  if (entry.clientStatus === 'INACTIVE') {
    return 'INACTIVE_CLIENT';
  }
  if (entry.projectStatus === 'INACTIVE') {
    return 'INACTIVE_PROJECT';
  }
};

export const checkIfStatusInactive = (entry: QuickReportProject) =>
  entry.projectStatus === 'INACTIVE' || entry.clientStatus === 'INACTIVE';

export const isInputDirty = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  initialProjectsData: QuickReportProject[],
) => {
  const inputNodes = event.target
    .closest('#quickReportTableBody')
    ?.getElementsByTagName('input');

  return initialProjectsData.some(
    (project, index) =>
      inputNodes &&
      Number(inputNodes[index].value) !== project.reportedTimeHours,
  );
};
export const isCustomInputDirty = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  initialProjectsData: QuickReportCustomProject[],
) => {
  const inputNodes = event.target
    .closest('#quickReportCustomTableBody')
    ?.getElementsByTagName('input');

  return initialProjectsData.some(
    (project, index) =>
      inputNodes &&
      Number(inputNodes[index].value) !== project.reportedWorkUnits,
  );
};
