import {
  Control,
  Controller,
  Validate,
  ValidationValueMessage,
} from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import {
  DatePickerView,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

interface Props extends Omit<KeyboardDatePickerProps, 'onChange' | 'value'> {
  control: Control;
  name: string;
  rules?: {
    required?: string | boolean | ValidationValueMessage<boolean>;
    validate?: Validate | Record<string, Validate>;
  };
  defaultValue?: Date | null;
  disabled?: boolean;
  errorMessage?: string;
  view?: DatePickerView;
}

const ControlledDatePicker = ({
  name,
  label,
  control,
  disabled = false,
  format = 'DD/MM/yyyy',
  rules,
  errorMessage,
  view = 'year',
  views = ['year', 'month', 'date'],
  disablePast = false,
  disableFuture = false,
  clearable = false,
  minDate,
  maxDate,
  defaultValue,
  id,
  onAccept,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, ...props }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            {...props}
            id={name || id}
            label={label}
            onChange={onChange}
            value={value}
            disabled={disabled}
            format={format}
            inputVariant="outlined"
            openTo={view}
            views={views}
            helperText={errorMessage}
            error={!!errorMessage}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoOk
            disableFuture={disableFuture}
            disablePast={disablePast}
            fullWidth
            minDate={minDate}
            maxDate={maxDate}
            clearable={clearable}
            onAccept={onAccept}
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

export default ControlledDatePicker;
