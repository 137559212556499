import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import BenefitHiddenInput from 'components/BenefitInputs/BenefitHiddenInput';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import {
  BillabilityTypes,
  CostProperties,
  ErrorProperties,
  Packages,
  PersonCosts,
} from 'components/BenefitInputs/types';
import { getBenefitTypePropertyInput } from 'components/BenefitInputs/utils';
import {
  ClearIcon,
  HeaderTooltipText,
  TableIconButton,
  Tooltip,
  TrashIcon,
  WarningIcon,
} from 'components/Table/Table.css';
import { validate } from 'utils/formValidation';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { getDefaultCostValue } from '../../../utils';
import { BenefitTypeFieldProps } from '../types';
import PackageInputs from './PackageInputs';
import PersonInputs from './PersonInputs';
import {
  BenefitTypeContainer,
  GridAlignLeft,
  GridAlignRight,
} from '../FormSteps.css';

const BenefitTypeField = ({
  index,
  control,
  errors,
  getValues,
  setValue,
  handleRemove,
  handleClear,
}: BenefitTypeFieldProps) => {
  const {
    benefitManagerState: {
      formState: { benefitTypes },
    },
  } = useContext(BenefitManagerContext);

  const [expanded, setExpanded] = useState(false);

  const intl = useIntl();

  const isOnlyOneType = benefitTypes.length === 1;
  const isLastType = benefitTypes.length === index + 1;

  const checkIfActive = (property: Packages | PersonCosts) =>
    getValues().benefitTypes?.[index]?.[property]?.active ??
    !!benefitTypes?.[index]?.[property]?.active;

  const setCheckboxFormState = useCallback(
    (index: number, property: Packages | PersonCosts) => {
      if (getValues().benefitTypes?.[index]?.[property]?.active === undefined) {
        setValue(
          getBenefitTypePropertyInput(CostProperties.active, index, property),
          benefitTypes?.[index]?.[property]?.active,
        );
      }
    },
    [benefitTypes, getValues, setValue],
  );

  useEffect(() => {
    setCheckboxFormState(index, Packages.partner);
    setCheckboxFormState(index, Packages.family);
    setCheckboxFormState(index, PersonCosts.partner);
    setCheckboxFormState(index, PersonCosts.child);
  }, [index, setCheckboxFormState]);

  const stopPropagation = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => e.stopPropagation(),
    [],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      stopPropagation(e);
      if (isOnlyOneType) {
        handleClear();
      } else {
        handleRemove(index);
      }
    },
    [handleClear, handleRemove, index, isOnlyOneType, stopPropagation],
  );

  const handleAccordionChange = () => setExpanded((prevState) => !prevState);

  const getBenefitTypeName = useCallback(() => {
    if (expanded) {
      return '';
    } else {
      return (
        getValues().benefitTypes?.[index]?.benefitTypeName ||
        benefitTypes?.[index]?.benefitTypeName ||
        intl.formatMessage({
          id: 'BENEFITS.FORM.BENEFIT_TYPE_NAME',
        })
      );
    }
  }, [benefitTypes, expanded, getValues, index, intl]);

  return (
    <BenefitTypeContainer container item xs={12} isLast={isLastType}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        id={`collapsableRow_${index}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id={`rowSummary_${index}`}
        >
          <Grid container justify="space-between" alignItems="flex-start">
            <GridAlignLeft item xs={6}>
              {getBenefitTypeName()}
            </GridAlignLeft>
            <GridAlignRight item xs={6}>
              {!!errors.benefitTypes?.[index] && (
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: `BENEFITS.FORM_INFO.BENEFIT_TYPE_ERROR`,
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <TableIconButton
                    onClick={stopPropagation}
                    id={`errorTooltip_${index}`}
                  >
                    <WarningIcon />
                  </TableIconButton>
                </Tooltip>
              )}
              {
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: `BENEFITS.FORM_INFO.${
                          isOnlyOneType ? 'CLEAR' : 'REMOVE'
                        }_BENEFIT_TYPE`,
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <TableIconButton
                    onClick={handleClick}
                    id={
                      isOnlyOneType
                        ? `clearButton_${index}`
                        : `removeButton_${index}`
                    }
                  >
                    {isOnlyOneType ? <ClearIcon /> : <TrashIcon />}
                  </TableIconButton>
                </Tooltip>
              }
            </GridAlignRight>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <BenefitHiddenInput
              name={getBenefitTypePropertyInput('id', index)}
              control={control}
              defaultValue={benefitTypes?.[index]?.id}
            />
            <BenefitTextField
              label="BENEFIT_TYPE_NAME"
              name={getBenefitTypePropertyInput('benefitTypeName', index)}
              nameOfErrorProperty={ErrorProperties.BenefitTypes}
              index={index}
              rules={validate(intl).required}
              defaultValue={benefitTypes?.[index]?.benefitTypeName}
              control={control}
              errors={errors}
            />
            <BenefitTextField
              label="COMPANY_COST_WITH_CURRENCY"
              name={getBenefitTypePropertyInput('companyCost', index)}
              rules={validate(intl).unrequiredPositiveNumber}
              control={control}
              errors={errors}
              defaultValue={getDefaultCostValue(benefitTypes, index)}
              index={index}
              nameOfErrorProperty={ErrorProperties.BenefitTypes}
            />
            {getValues().billableBy === BillabilityTypes.package && (
              <>
                <PackageInputs
                  packageProperty={Packages.individual}
                  index={index}
                  isActive={checkIfActive(Packages.individual)}
                  control={control}
                  errors={errors}
                />
                <PackageInputs
                  packageProperty={Packages.partner}
                  index={index}
                  isActive={checkIfActive(Packages.partner)}
                  control={control}
                  errors={errors}
                />
                <PackageInputs
                  packageProperty={Packages.family}
                  index={index}
                  isActive={checkIfActive(Packages.family)}
                  control={control}
                  errors={errors}
                />
              </>
            )}
            {getValues().billableBy === BillabilityTypes.person && (
              <>
                <PersonInputs
                  personProperty={PersonCosts.employee}
                  index={index}
                  isActive={checkIfActive(PersonCosts.employee)}
                  control={control}
                  errors={errors}
                />
                <PersonInputs
                  personProperty={PersonCosts.partner}
                  index={index}
                  isActive={checkIfActive(PersonCosts.partner)}
                  control={control}
                  errors={errors}
                />
                <PersonInputs
                  personProperty={PersonCosts.child}
                  index={index}
                  isActive={checkIfActive(PersonCosts.child)}
                  control={control}
                  errors={errors}
                />
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </BenefitTypeContainer>
  );
};

export default BenefitTypeField;
