import { useContext, useMemo } from 'react';
import * as CSS from 'csstype';
import { UserContext } from 'globalContext/UserContext';
import { RoleTypes } from 'utils/rolesTypes';
import {
  BillableIcon,
  HeaderTooltipText,
  InformationIcon,
  NotBillableIcon,
  TableCellWithTooltip,
  TextWrapper,
  Tooltip,
  WarningIcon,
} from '../Table/Table.css';

interface Props {
  tooltip: string;
  text?: string;
  isWarning?: boolean;
  isChecked?: null | boolean;
  checkTooltip?: string;
  bigger?: boolean;
  styles?: {
    wrapperStyles?: CSS.Properties;
    cellTooltipStyles?: CSS.Properties;
  };
  id?: string;
  checkedId?: string;
  alignIconsToEnd?: boolean;
  shouldCoverSelectBox?: boolean;
}

const ToolTipCell = ({
  tooltip,
  text = '',
  isWarning = false,
  isChecked = null,
  checkTooltip = '',
  bigger = false,
  styles = { wrapperStyles: {}, cellTooltipStyles: {} },
  id,
  checkedId,
  alignIconsToEnd,
  shouldCoverSelectBox,
}: Props) => {
  const { teamLeader, roles } = useContext(UserContext);

  const isUserAuthorized = useMemo(() => {
    return (
      roles.includes(RoleTypes.RoleAccounting) ||
      roles.includes(RoleTypes.RoleDEVHR) ||
      teamLeader
    );
  }, [roles, teamLeader]);

  const defaultTooltip = (
    <Tooltip
      title={<HeaderTooltipText variant="body2">{tooltip}</HeaderTooltipText>}
      placement="top"
      PopperProps={
        shouldCoverSelectBox ? { style: { zIndex: 10000 } } : undefined
      }
    >
      {isWarning ? (
        <WarningIcon id={`warningTooltip_${id}`} />
      ) : (
        <InformationIcon
          $bigger={bigger}
          id={id}
          $alignToEnd={alignIconsToEnd}
        />
      )}
    </Tooltip>
  );

  const isCheckedTooltip = (
    <Tooltip
      id={checkedId}
      title={
        <HeaderTooltipText variant="body2">{checkTooltip}</HeaderTooltipText>
      }
      placement="top"
      PopperProps={
        shouldCoverSelectBox ? { style: { zIndex: 10000 } } : undefined
      }
    >
      {isChecked ? (
        <BillableIcon id={id} $alignToEnd={alignIconsToEnd} />
      ) : (
        <NotBillableIcon id={id} $alignToEnd={alignIconsToEnd} />
      )}
    </Tooltip>
  );

  return (
    <TableCellWithTooltip $styles={styles.cellTooltipStyles}>
      {text && <TextWrapper $styles={styles.wrapperStyles}>{text}</TextWrapper>}
      {tooltip && defaultTooltip}
      {text && isChecked !== null && isUserAuthorized && isCheckedTooltip}
    </TableCellWithTooltip>
  );
};

export default ToolTipCell;
