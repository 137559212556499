import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  InfoContainer,
  InputTitle,
  StepGroup,
  StepTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext/index';
import { AddressProps } from 'modules/UserProfile/Screens/Benefits/types';
import { getAddress } from './utils';

const Address = ({ index }: AddressProps) => {
  const intl = useIntl();
  const { state } = useContext(BenefitContext);

  const addressesDifferent =
    index || index === 0
      ? state.benefitsData?.partners?.[index]?.areAddressesDifferent
      : state.benefitsData?.employee?.areAddressesDifferent;

  const address = getAddress(state, intl, 'address', index);
  const crspAddress = getAddress(state, intl, 'crspAddress', index);

  return (
    <>
      {address && (
        <StepGroup container item spacing={3} justifyStart>
          <StepTitle>
            {intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.ADDRESS' })}
          </StepTitle>
          {address.map(({ content, title }) =>
            content ? (
              <InfoContainer item xs={6} key={title}>
                <InputTitle>{title}</InputTitle>
                <span>{content}</span>
              </InfoContainer>
            ) : null,
          )}
        </StepGroup>
      )}
      {addressesDifferent && crspAddress && (
        <StepGroup container spacing={3} justifyStart>
          <StepTitle>
            {intl.formatMessage({
              id: 'USER_PROFILE.BENEFITS.FORM.CRSP_ADDRESS',
            })}
          </StepTitle>
          {crspAddress.map(({ content, title }) =>
            content ? (
              <InfoContainer item xs={6} key={title}>
                <InputTitle>{title}</InputTitle>
                <span>{content}</span>
              </InfoContainer>
            ) : null,
          )}
        </StepGroup>
      )}
    </>
  );
};

export default Address;
