import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { UserContext } from 'globalContext/UserContext';
import NoteDialog from 'components/Dialog/NoteDialog';
import BlockTimesheet from 'components/IconsWithTooltip/BlockTimesheet';
import LeaderReminder from 'components/IconsWithTooltip/LeaderReminder';
import Message from 'components/IconsWithTooltip/Message';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import { displayLabelRows } from 'utils/displayLabelRows';
import {
  mapISODurationToDays,
  mapISODurationToHours,
} from 'utils/durationFunctions';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import ToolTipCell from '../ToolTipCell';
import EmptyDataRow from './EmptyDataRow';
import { HoursCell } from './HoursCell';
import {
  ApprovalStatus,
  SingleClient,
  SingleProject,
  TableCellPropsExtended,
  TableHead,
  TeamLeader,
  Timesheet,
  TimesheetTableProps,
} from './types';
import {
  ActionCircularProgress,
  BoldSpan,
  Checkbox,
  CheckboxPartialApproval,
  DivFlex,
  FirstRowTableCell,
  FormControlWrapper,
  GridIconWrapper,
  HeaderTooltipText,
  InformationIcon,
  Paper,
  StyledLink,
  StyledTableCell,
  Table as TableComponent,
  TableActionCell,
  TableActionCellFlex,
  TableBody,
  TableCell,
  TableContainer,
  TableHeadCell,
  TableHeader,
  TableHideIconButton,
  TableIconButton,
  TableOvertimeCellText,
  TablePagination,
  TableRow,
  TableSummaryCell,
  Tooltip,
  Typography,
} from './Table.css';

const formatTooltipTitle = (
  approvalStatus: ApprovalStatus,
  approverFullName: string,
  intl: IntlShape,
  approvmentNote?: string,
  unapprovedBy?: string,
) => {
  let tooltipContent = '';
  if (approvalStatus === 'APPROVED') {
    if (approverFullName) {
      tooltipContent += `${intl.formatMessage({
        id: 'TIMESHEETS.APPROVED_BY',
      })} ${approverFullName}`;
    } else {
      tooltipContent += intl.formatMessage({
        id: 'TIMESHEETS.APPROVED',
      });
    }
    if (approvmentNote) {
      tooltipContent += `\n\n${intl.formatMessage({
        id: 'TIMESHEETS_APPROVEMENT_NOTE.TOOLTIP_DATA',
      })}: ${approvmentNote}`;
    }
  } else {
    if (unapprovedBy) {
      tooltipContent += `${intl.formatMessage({
        id: 'TIMESHEETS.UNAPPROVED_BY',
      })} ${unapprovedBy}`;
    } else {
      tooltipContent += intl.formatMessage({
        id: `APPROVAL_STATUS.${approvalStatus}`,
      });
    }
  }
  return tooltipContent;
};

const TableTimesheets = ({
  onLockIconClick,
  onExplanationClick,
  onMasterApprovalClick,
  onLeaderPartialApprovalClick,
  onLeaderPartialUndoApprovalClick,
  onMasterUndoApprovalClick,
  setRowsPerPage,
  setCurrentPage,
  withPagination = false,
  loading = false,
  currentPage = 0,
  pageCount = 1,
  limit,
  head,
  data,
  emptyDataMessage,
  detailsVisibility,
  setDetailsVisibility = () => {
    // init empty function
  },
  totalWorkingTime,
}: TimesheetTableProps) => {
  const headFilteredWithLabelsOnly: TableHead[] = head.filter(
    (element) => element.label,
  );
  const [openExplanationDialog, setExplanationDialogOpen] =
    useToggleState(false);
  const [timesheetId, setTimesheetId] = useState<number>(0);
  const [hoursChecked, handleChange] = useToggleState(false);
  const [timesheetNrActionLoading, setTimesheetNrActionLoading] =
    useState<number>();
  const { username, roles } = useContext(UserContext);
  const intl = useIntl();
  const isAuthorized =
    roles.includes(RoleTypes.RoleAccounting) ||
    roles.includes(RoleTypes.RoleDEVHR);

  const noteDialogSubmit = useCallback(
    async (
      id: number,
      note: string,
      actionType: 'reminder' | 'explanation',
    ) => {
      setTimesheetNrActionLoading(id);
      await onExplanationClick(id, note, actionType);
      setTimesheetNrActionLoading(undefined);
    },
    [onExplanationClick],
  );

  const changeUserTimesheetEdition = useCallback(
    async (username: string, settled: boolean, id: number) => {
      setTimesheetNrActionLoading(id);
      onLockIconClick(username, settled);
      setTimesheetNrActionLoading(undefined);
    },
    [onLockIconClick],
  );

  const onMessageIconClick = useCallback(
    (id: number) => {
      setExplanationDialogOpen();
      setTimesheetId(id);
    },
    [setExplanationDialogOpen],
  );

  const handlePartialApprovalClick = useCallback(
    (entry: Timesheet, client: SingleProject, index: number) => {
      client?.approved
        ? onLeaderPartialUndoApprovalClick(
            entry.timesheetReportId,
            client.projectId,
            index,
          )
        : onLeaderPartialApprovalClick(
            entry.timesheetReportId,
            client.projectId,
            index,
          );
    },
    [onLeaderPartialApprovalClick, onLeaderPartialUndoApprovalClick],
  );

  const renderTime = useCallback(
    (duration: string) => {
      return hoursChecked
        ? mapISODurationToHours(duration)
        : mapISODurationToDays(duration);
    },
    [hoursChecked],
  );

  const generateTooltipInfo = useCallback(
    (client: SingleProject, clientName: string) => {
      return client?.approved ? (
        <>
          {`${intl.formatMessage({
            id: 'TIMESHEETS.APPROVED_BY',
          })}`}
        </>
      ) : (
        <>
          {intl.formatMessage({
            id: 'TIMESHEET.PARTIAL.TOOLTIP_HEAD',
          })}
          <BoldSpan>
            {intl.formatMessage({
              id: 'TIMESHEET.PARTIAL.TOOLTIP_CLIENT',
            })}
            {clientName}
            {intl.formatMessage({
              id: 'TIMESHEET.PARTIAL.TOOLTIP_PROJECT',
            })}
            {client.project}
            {intl.formatMessage({
              id: 'TIMESHEET.PARTIAL.TOOLTIP_MANUAL_RULE',
            })}
            manualRuleBroken
          </BoldSpan>
        </>
      );
    },
    [intl],
  );

  const body = useMemo(
    () =>
      data.map((entry, index) => {
        const employeeProjectsCount = entry.workingTimeSummaryDTO.reduce(
          (prev, curr) => prev + curr.projectsList.length,
          0,
        );

        const FirstRow = (props: TableCellPropsExtended) => (
          <FirstRowTableCell
            {...props}
            rowSpan={detailsVisibility[index] ? employeeProjectsCount : 1}
          />
        );

        const userIsLeader = entry.teamLeaders?.some(
          (leader) => leader?.userName === username,
        );

        const LinkToWorkTime = (username: string, fullName: string) => (
          <StyledLink to={`/worktime/${username}`} id={`link_${index}`}>
            {fullName}
          </StyledLink>
        );

        const returnTeamLeaders = (leaders: TeamLeader[]) =>
          leaders.map(({ fullName }) => fullName).join(', ');

        const showRowData =
          detailsVisibility[index] || employeeProjectsCount === 1;
        const showDetails =
          detailsVisibility[index] && employeeProjectsCount !== 1;

        const isPartialApprovalCheckboxChecked = (entries: SingleClient[]) =>
          entries
            .flatMap(({ projectsList }) => projectsList)
            .every(({ approved }) => approved);

        const renderPartialApprovalCheckbox = (
          client: SingleProject,
          clientName: string,
        ) => {
          return showRowData && employeeProjectsCount !== 1 ? (
            <DivFlex>
              <CheckboxPartialApproval
                id="partialApproveTimesheet"
                checked={client?.approved}
                disabled={loading}
                onChange={() =>
                  handlePartialApprovalClick(entry, client, index)
                }
              />
              <Tooltip
                title={
                  <HeaderTooltipText>
                    {generateTooltipInfo(client, clientName)}
                  </HeaderTooltipText>
                }
                placement="top"
              >
                <InformationIcon />
              </Tooltip>
            </DivFlex>
          ) : (
            <CheckboxPartialApproval
              id="partialApproveTimesheet"
              checked={isPartialApprovalCheckboxChecked(
                entry.workingTimeSummaryDTO,
              )}
              disabled
            />
          );
        };

        const renderActions = () => {
          return timesheetNrActionLoading === entry.timesheetReportId ? (
            <Grid item xs={12}>
              <ActionCircularProgress size={20} />
            </Grid>
          ) : (
            actionsContent(entry.teamLeaders.length > 0)
          );
        };

        const actionsContent = (hasLeaders: boolean) => (
          <>
            {isAuthorized && (
              <Grid item xs={4}>
                <BlockTimesheet
                  onClick={() =>
                    changeUserTimesheetEdition(
                      entry.username,
                      !entry.settled,
                      entry.timesheetReportId,
                    )
                  }
                  isSettled={entry.settled}
                  id={entry.settled ? `locked_${index}` : `unlocked_${index}`}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <LeaderReminder
                id={`reminder_${index}`}
                timesheetId={entry.timesheetReportId}
                setTimesheetNrActionLoading={setTimesheetNrActionLoading}
                disabled={!hasLeaders}
              />
            </Grid>
            <Grid item xs={4}>
              <Message
                id={`messageIcon_${index}`}
                onClick={() => onMessageIconClick(entry.timesheetReportId)}
              />
            </Grid>
          </>
        );

        const renderLeaders = (entry: Timesheet) => {
          return entry.teamLeaders.length
            ? returnTeamLeaders(entry.teamLeaders)
            : '-';
        };

        if (entry.workingTimeSummaryDTO.length === 0) {
          return (
            <TableRow key={`empty-${index}`}>
              <TableCell>
                {entry.vacationDaysCount || entry.medicalLeaveDaysCount ? (
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        {intl.formatMessage({
                          id: 'TABLE.BODY.LINK_TO_WORKTIME',
                        })}
                      </Typography>
                    }
                    placement="top"
                  >
                    {LinkToWorkTime(entry.username, entry.fullName)}
                  </Tooltip>
                ) : (
                  entry.fullName
                )}
              </TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell align="center">{0}</TableCell>
              <TableCell align="center">
                <CheckboxPartialApproval disabled />
              </TableCell>
              <TableCell>{renderLeaders(entry)}</TableCell>
              <TableCell></TableCell>
              <TableCell align="center">
                {mapISODurationToHours(entry.overtimeDuration)}
              </TableCell>
              <TableCell align="center">{entry.vacationDaysCount}</TableCell>
              <TableCell align="center">
                {entry.medicalLeaveDaysCount}
              </TableCell>
              <TableCell align="center">{entry.daysOffCount}</TableCell>
              <TableCell align="center">{entry.unreportedDaysCount}</TableCell>
              <TableCell align="center">{entry.remoteWorkDaysCount}</TableCell>
              <StyledTableCell align="center">
                <Grid container spacing={0}>
                  {timesheetNrActionLoading === entry.timesheetReportId ? (
                    <>
                      <Grid item xs={12}>
                        <ActionCircularProgress size={20} />
                      </Grid>
                    </>
                  ) : (
                    <>{actionsContent(entry.teamLeaders.length > 0)}</>
                  )}
                </Grid>
              </StyledTableCell>
              <TableActionCell align="center">
                <TableActionCellFlex>
                  <FormControlWrapper
                    id={`approve_${index}`}
                    control={
                      <Checkbox
                        checked={entry.approved}
                        disabled={
                          !entry.teamLeaders.length ||
                          (entry.approved && !userIsLeader)
                        }
                        onChange={
                          entry.approved
                            ? () =>
                                onMasterUndoApprovalClick(
                                  entry.timesheetReportId,
                                )
                            : () =>
                                onMasterApprovalClick(
                                  entry.timesheetReportId,
                                  entry.teamLeaders,
                                )
                        }
                      />
                    }
                    label={
                      entry.approvalStatus && (
                        <ToolTipCell
                          id="approvalTooltip"
                          tooltip={formatTooltipTitle(
                            entry.approvalStatus as ApprovalStatus,
                            entry.approverFullName,
                            intl,
                            entry.explanationNotes,
                            entry.unapprovedBy,
                          )}
                          styles={{ cellTooltipStyles: { color: '#d50000' } }}
                        />
                      )
                    }
                  />
                  {!entry.teamLeaders.length && (
                    <ToolTipCell
                      tooltip={intl.formatMessage({
                        id: 'TIMESHEET.REPORT.NO_LEADER_APPROVAL',
                      })}
                    />
                  )}
                </TableActionCellFlex>
              </TableActionCell>
            </TableRow>
          );
        } else {
          return entry.workingTimeSummaryDTO.map((unit, ind) => {
            const moreProjects = unit.projectsList.map((el, i) => {
              return i && detailsVisibility[index] ? (
                <TableRow
                  key={`${el.project} - ${unit.client}`}
                  index={index}
                  $selected
                >
                  <TableCell $details={showDetails}>
                    <ToolTipCell
                      id="projectDescriptionTooltip"
                      checkedId="billabilityTooltip"
                      text={el.project}
                      tooltip={el.projectDescription}
                      isChecked={el.billable}
                      checkTooltip={intl.formatMessage({
                        id: el.billable
                          ? 'TIMESHEET.BILLABILITY_TRUE'
                          : 'TIMESHEET.BILLABILITY_FALSE',
                      })}
                    />
                  </TableCell>
                  <TableCell align="center" $details={showDetails}>
                    {renderTime(el.workTime)}
                  </TableCell>
                  <TableCell align="center" $details={showDetails}>
                    <CheckboxPartialApproval
                      id="partialApproveTimesheet"
                      checked={el?.approved}
                      disabled={loading || employeeProjectsCount === 1}
                      onChange={() =>
                        handlePartialApprovalClick(entry, el, index)
                      }
                    />
                  </TableCell>
                  <TableCell $details={showDetails} />
                </TableRow>
              ) : null;
            });

            const rowsSpan = detailsVisibility[index]
              ? unit.projectsList.length
              : 1;

            const returnRowsSpan = showRowData ? employeeProjectsCount : 1;

            const client = unit.projectsList[0];

            const returnTime = showRowData
              ? renderTime(client?.workTime)
              : renderTime(entry.totalWorkingTimeDuration);

            const handleApprovalChange = entry.approved
              ? () => onMasterUndoApprovalClick(entry.timesheetReportId)
              : () =>
                  onMasterApprovalClick(
                    entry.timesheetReportId,
                    entry.teamLeaders,
                  );

            return !ind || detailsVisibility[index] ? (
              <Fragment key={`${unit.client} - ${ind}`}>
                <TableRow index={index % 2}>
                  {!ind && (
                    <FirstRow $details={showDetails}>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            {intl.formatMessage({
                              id: 'TABLE.BODY.LINK_TO_WORKTIME',
                            })}
                          </Typography>
                        }
                        placement="top"
                      >
                        {LinkToWorkTime(entry.username, entry.fullName)}
                      </Tooltip>
                    </FirstRow>
                  )}
                  <TableCell
                    align="left"
                    rowSpan={rowsSpan}
                    $details={showDetails}
                  >
                    {(showRowData ||
                      entry.workingTimeSummaryDTO.length === 1) && (
                      <ToolTipCell
                        id="clientDescriptionTooltip"
                        text={unit.client}
                        tooltip={unit.clientDescription}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left" $details={showDetails} width="10%">
                    {showRowData && (
                      <ToolTipCell
                        id="projectDescriptionTooltip"
                        checkedId="billabilityTooltip"
                        text={client?.project}
                        tooltip={client?.projectDescription}
                        isChecked={client?.billable}
                        checkTooltip={intl.formatMessage({
                          id: client?.billable
                            ? 'TIMESHEET.BILLABILITY_TRUE'
                            : 'TIMESHEET.BILLABILITY_FALSE',
                        })}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" $details={showDetails}>
                    {returnTime}
                  </TableCell>
                  <TableCell align="center" $details={showDetails}>
                    {renderPartialApprovalCheckbox(client, unit.client)}
                  </TableCell>
                  <TableCell $details={showDetails}>
                    {renderLeaders(entry)}
                  </TableCell>
                  <TableCell align="center" $details={showDetails}>
                    {!showRowData && (
                      <TableIconButton
                        id={`projectDetails_${index}`}
                        onClick={() => setDetailsVisibility(index)}
                      >
                        <GridIconWrapper>
                          <Typography variant="subtitle2">
                            {intl.formatMessage({
                              id: 'TABLE.BODY.DETAILS_SHOW',
                            })}
                          </Typography>
                          <KeyboardArrowDown />
                        </GridIconWrapper>
                      </TableIconButton>
                    )}
                    {showRowData && employeeProjectsCount > 1 && ind === 0 && (
                      <TableHideIconButton
                        id={`hideDetails_${index}`}
                        onClick={() => setDetailsVisibility(index)}
                      >
                        <KeyboardArrowUp />
                        <Typography>
                          {intl.formatMessage({
                            id: 'TABLE.BODY.DETAILS_HIDE',
                          })}
                        </Typography>
                      </TableHideIconButton>
                    )}
                  </TableCell>
                  {!ind && (
                    <>
                      <FirstRow align="center" position="relative">
                        <TableOvertimeCellText>
                          {mapISODurationToHours(entry.overtimeDuration)}
                        </TableOvertimeCellText>
                      </FirstRow>
                      <FirstRow align="center">
                        {entry.vacationDaysCount}
                      </FirstRow>
                      <FirstRow align="center">
                        {entry.medicalLeaveDaysCount}
                      </FirstRow>
                      <FirstRow align="center">{entry.daysOffCount}</FirstRow>
                      <FirstRow align="center">
                        {entry.unreportedDaysCount}
                      </FirstRow>
                      <FirstRow align="center">
                        {entry.remoteWorkDaysCount}
                      </FirstRow>
                      <FirstRow
                        align="center"
                        style={{
                          minWidth: '100px',
                        }}
                      >
                        <Grid container spacing={0}>
                          {renderActions()}
                        </Grid>
                      </FirstRow>
                      <TableActionCell rowSpan={returnRowsSpan}>
                        <TableActionCellFlex align="center">
                          <FormControlWrapper
                            label=""
                            control={
                              <Checkbox
                                id="approveTimesheet"
                                checked={entry.approved}
                                disabled={
                                  !entry.approvedByUser ||
                                  loading ||
                                  (entry.approved && !userIsLeader)
                                }
                                onChange={handleApprovalChange}
                              />
                            }
                          />
                          {!entry.approvedByUser && (
                            <ToolTipCell
                              id="notSentReportTooltip"
                              tooltip={intl.formatMessage({
                                id: 'TIMESHEET.REPORT.NOT.SENT',
                              })}
                              isWarning
                            />
                          )}
                          {!entry.teamLeaders.length && (
                            <ToolTipCell
                              tooltip={intl.formatMessage({
                                id: 'TIMESHEET.REPORT.NO_LEADER_APPROVAL',
                              })}
                            />
                          )}
                          {entry.approvalStatus && (
                            <ToolTipCell
                              id="approvalTooltip"
                              tooltip={formatTooltipTitle(
                                entry.approvalStatus as ApprovalStatus,
                                entry.approverFullName,
                                intl,
                                entry.explanationNotes,
                                entry.unapprovedBy,
                              )}
                              styles={{
                                cellTooltipStyles: { color: '#d50000' },
                              }}
                            />
                          )}
                        </TableActionCellFlex>
                      </TableActionCell>
                    </>
                  )}
                </TableRow>
                {moreProjects}
              </Fragment>
            ) : null;
          });
        }
      }),
    [
      data,
      detailsVisibility,
      isAuthorized,
      intl,
      changeUserTimesheetEdition,
      onMessageIconClick,
      timesheetNrActionLoading,
      onMasterUndoApprovalClick,
      onMasterApprovalClick,
      handlePartialApprovalClick,
      renderTime,
      loading,
      setDetailsVisibility,
      username,
      generateTooltipInfo,
    ],
  );

  return (
    <>
      <TableContainer component={Paper} $loading={loading}>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component={'div'}
          />
        )}
        <TableComponent>
          <TableLoadingWrapper loading={loading}>
            <>
              <TableHeader>
                <TableRow $main>
                  {headFilteredWithLabelsOnly.map((data, index) => (
                    <TableHeadCell
                      align={data.align}
                      key={`${index} - ${data.key}`}
                      style={data.style}
                      $action={data.key === 'approved'}
                    >
                      {data.key === 'hours'
                        ? HoursCell(hoursChecked, intl, handleChange)
                        : intl.formatMessage({ id: data.label })}
                    </TableHeadCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.length ? (
                  <>
                    {body}
                    <TableRow>
                      <TableSummaryCell colSpan={2} />
                      <TableSummaryCell>
                        {intl.formatMessage({
                          id: 'TIMESHEET.TOTAL_WORKING_TIME',
                        })}
                      </TableSummaryCell>
                      <TableSummaryCell>
                        {totalWorkingTime && renderTime(totalWorkingTime)}
                      </TableSummaryCell>
                    </TableRow>
                  </>
                ) : (
                  <EmptyDataRow
                    colSpan={headFilteredWithLabelsOnly.length}
                    message={emptyDataMessage}
                  />
                )}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
        {withPagination && !!limit && (
          <TablePagination
            onChangeRowsPerPage={setRowsPerPage}
            onPageChange={setCurrentPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
            labelDisplayedRows={({ from, to, count }) =>
              displayLabelRows({ from, to, count, intl })
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={limit}
            page={currentPage}
            count={pageCount}
            //@ts-ignore
            component={'div'}
          />
        )}
      </TableContainer>
      <NoteDialog
        addChecked={openExplanationDialog}
        handleAddChanged={setExplanationDialogOpen}
        onExplanationClick={noteDialogSubmit}
        timesheetsId={timesheetId}
        submitButtonLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.SEND.MESSAGE',
        })}
        headerLabel={intl.formatMessage({
          id: 'NOTE_DIALOG.SEND.MESSAGE',
        })}
        noteTitle="EmployeeMessageFromActions"
      />
    </>
  );
};

export default TableTimesheets;
