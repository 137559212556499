import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { CountryDiet } from 'modules/BusinessTrips/types';
import { SummarySectionProps } from '../../types';
import {
  DataTypeTitle,
  SummarySectionContainer,
  SummarySectionTitle,
} from '../../BusinessTripDialog.css';

const SummarySection = ({
  businessTripSectionData,
  title,
}: SummarySectionProps) => {
  const intl = useIntl();
  const getContent = (content: string | number | CountryDiet) => {
    if (typeof content === 'string' || typeof content === 'number') {
      return content;
    }
    return content.name;
  };

  return (
    <SummarySectionContainer>
      {businessTripSectionData.map((el, key) => {
        if (!el.content) {
          return null;
        }
        return (
          <Grid
            container
            direction="row"
            key={`${key}-${el.name}`}
            justify="space-between"
          >
            <DataTypeTitle item>
              {intl.formatMessage({
                id: el.name,
              })}
              :
            </DataTypeTitle>
            <Grid item>
              {el.content
                ? getContent(el.content)
                : intl.formatMessage({ id: 'SUMMARY.NONE' })}
            </Grid>
          </Grid>
        );
      })}
      <SummarySectionTitle>
        {intl.formatMessage({
          id: title,
        })}
      </SummarySectionTitle>
    </SummarySectionContainer>
  );
};

export default SummarySection;
