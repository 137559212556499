import { IntlShape } from 'react-intl';
import { businessTripsUrl } from 'router/url';
import { ProjectsData } from 'utils/helpers/renameKeys';
import {
  BusinessTrip,
  CheckboxesState,
  QueryParams,
  TripStatuses,
} from './types';

const getStatuses = (checkboxes: CheckboxesState, status?: TripStatuses) => {
  const statuses: string[] = [];
  if (status) {
    statuses.push(status);
  }
  if (checkboxes.showAccepted) {
    statuses.push(TripStatuses.APPROVED);
  }
  if (checkboxes.showSettled) {
    statuses.push(TripStatuses.SETTLED);
  }
  if (checkboxes.showReservationToBeDone) {
    statuses.push(TripStatuses.RESERVATION_TO_BE_DONE);
  }
  if (checkboxes.showToSettle) {
    statuses.push(TripStatuses.DOCS_TO_BE_VERIFIED);
  }
  if (checkboxes.showToAccept) {
    statuses.push(TripStatuses.TO_BE_APPROVED_BY_LEADER);
  }
  return statuses;
};

export const getQueryString = (
  props: QueryParams,
  checkboxes: CheckboxesState,
) => {
  const {
    pageNumber,
    pageSize,
    isAccountPrivileged,
    username,
    employeeId,
    clientId,
    projectId,
    businessTripId,
    status,
    isLeader,
  } = props;

  const { showOnlyMine, showToAccept } = checkboxes;

  const statuses = getStatuses(checkboxes, status);

  const searchParams = new URLSearchParams({
    page: String(pageNumber),
    size: String(pageSize),
    clientId: clientId ? String(clientId) : '',
    projectId: projectId ? String(projectId) : '',
    businessTripId: businessTripId ?? '',
  });

  if (!!statuses.length) {
    searchParams.append('statuses', statuses.join(','));
  }

  if (isAccountPrivileged && !showOnlyMine && !showToAccept) {
    searchParams.append('accountId', employeeId ? String(employeeId) : '');
    return `${businessTripsUrl}?${searchParams.toString()}`;
  } else if (isLeader && !showOnlyMine) {
    searchParams.append('accountId', employeeId ? String(employeeId) : '');
    return `${businessTripsUrl}/leader?${searchParams.toString()}`;
  } else {
    return `${businessTripsUrl}/users/${username}?${searchParams.toString()}`;
  }
};

export const projectsFilter = (
  client: string,
  projectsData: ProjectsData[],
) => {
  return projectsData.filter((data) => data.client.clientName === client);
};

const MAX_COUNT_OF_NUMBERS = 4;

export const getHelperText = (intl: IntlShape) => {
  return {
    maxValue: intl.formatMessage({
      id: 'VALIDATION.MAX_VALUE',
    }),
    maxLength: intl.formatMessage({
      id: 'VALIDATION.MAX_LENGTH',
    }),
  };
};

const maxValue = (intl: IntlShape, days: number) => ({
  value: days,
  message: `${getHelperText(intl).maxValue} ${days}`,
});

const maxLength = (intl: IntlShape) => ({
  value: MAX_COUNT_OF_NUMBERS,
  message: getHelperText(intl).maxLength,
});

export const validateBusinessTrips = (intl: IntlShape, days: number) => {
  return {
    maxValue: {
      max: maxValue(intl, days),
    },

    maxLength: {
      maxLength: maxLength(intl),
    },
  };
};

export const preparePreviousData = (
  previousData: BusinessTrip,
  intl: IntlShape,
) => {
  return {
    id: previousData.id,
    tripReason: previousData.tripReason,
    country: { ...previousData.countryDiet },
    city: previousData.city,
    client: {
      clientName: previousData.client.clientName,
      name: previousData.client.clientName,
      status: previousData.client.status,
      value: 0,
      description: previousData.client.description,
    },
    project: previousData.project
      ? {
          ...previousData.project,
          name: previousData.project?.projectName,
          value: 0,
        }
      : undefined,
    startTime: new Date(previousData.startTime),
    endTime: new Date(previousData.endTime),
    transportation: {
      value: previousData.transportation[0].expenseType,
      name: intl.formatMessage({
        id: `BUSINESS_TRIP.${previousData.transportation[0].expenseType}`,
      }),
    },
    travelFrom: previousData.travelFrom,
    travelTo: previousData.travelTo,
    travelReservationBy: previousData.travelReservationBy
      ? {
          value: `${previousData.travelReservationBy}`,
          name: intl.formatMessage({
            id: `BUSINESS_TRIP.${previousData.travelReservationBy}`,
          }),
        }
      : undefined,
    travelReservationLink: previousData.travelReservationLink || '',
    reservationInformation: previousData.reservationInformation || '',
    numberOfNights: previousData.numberOfNights,
    placeToStay: previousData.placeToStay,
    estimateCostsOfStay: previousData.estimatedCostsOfStay || undefined,
    currency: { name: previousData.currency, value: 0 } || undefined,
    neededAdvance: previousData.advance,
    accommodationReservationBy: {
      value: `${previousData.accommodationReservationBy}`,
      name: intl.formatMessage({
        id: `BUSINESS_TRIP.${previousData.accommodationReservationBy}`,
      }),
    },
    accommodationReservationLink:
      previousData.accommodationReservationLink || '',
    accommodationInformation: previousData.accommodationInformation || '',
    insuranceProvidedBy: {
      value: `${previousData.insuranceProvidedBy}`,
      name: intl.formatMessage({
        id: `BUSINESS_TRIP.${previousData.insuranceProvidedBy}`,
      }),
    },
    advanceDescription: previousData.advanceDescription,
    otherInformation: previousData.otherInformation || '',
    clientAcceptance: false,
  };
};
