import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { getGeneralValues } from '../inputHelpers';
import InfoGroup from './InfoGroup';
import { InfoContainer, InputTitle } from '../../../BenefitsManager.css';

const General = () => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  return (
    <InfoGroup
      isFirst
      title={intl.formatMessage({ id: 'BENEFITS.GENERAL' })}
      justifyStart
    >
      {getGeneralValues(intl, formState)?.map((value) =>
        value.content ? (
          <InfoContainer
            key={value.title.replaceAll(' ', '_')}
            item
            md={value.isFullWidth ? 12 : 6}
          >
            <InputTitle>{value.title}:</InputTitle>
            <span>{value.content}</span>
          </InfoContainer>
        ) : null,
      )}
    </InfoGroup>
  );
};

export default General;
