import {
  Box,
  CircularProgress,
  Grid,
  SvgIconProps,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Backup, Edit } from '@material-ui/icons';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import UpdateIcon from '@material-ui/icons/Update';
import palette from 'assets/palette';
import styled from 'styled-components';
import { ContainedButton } from 'components/Button';

export const TripsBox = styled(Box)`
  overflow: auto;
  padding: 2rem;
  min-height: calc(100vh - 200px);
`;

export const UpdateStateIcon = styled(UpdateIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;

    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;
export const FlexDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const SmallUploadFileIcon = styled(Backup)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    font-size: 2rem;
    margin-bottom: 0.1rem;
    z-index: 100;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const SmallFileCounter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc703;
  z-index: 999;
  border-radius: 50%;
  padding: 0.2rem;
  margin: 0 -0.45rem 0.65rem;
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0.7rem;
`;
export const CheckBoxLabel = styled.p`
  line-height: 8px;
`;
export const SmallEditIcon = styled(Edit)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    font-size: 2rem;
    margin-left: 0.3rem;
    margin-bottom: 0.1rem;

    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const SendToVerifyIcon = styled(SendSharpIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    font-size: 2rem;
    margin-left: 0.7rem;
    margin-bottom: 0.1rem;

    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const BusinessTripsGrid = styled(Grid)`
  &.MuiGrid-root {
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
`;

export const BusinessTripsButton = styled(ContainedButton)`
  &.MuiButtonBase-root {
    margin: auto;
  }
`;

export const BusinessTripsToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const BusinessTripsDrawerGrid = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
  }
`;

export const BusinessTripTypograhpy = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5rem;
  }
`;
