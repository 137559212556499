import { Dispatch } from 'react';
import {
  SelectDataForm,
  TripCalculatorFormValues,
  TripCalculatorSummary,
} from 'modules/BusinessTrips/types';

export type State = {
  renderPage: RenderPage | null;
  calculatorFormValues: TripCalculatorFormValues | undefined;
  isAccounting: boolean;
  isEditing: boolean;
  tripCalculatorSummary: TripCalculatorSummary | undefined;
  currencies: SelectDataForm<number>[];
  isEmptyCalculation: boolean;
};

export enum ActionKind {
  RenderCalculator = 'RENDER_CALCULATOR',
  RenderSummary = 'RENDER_SUMMARY',
  SetCalculatorFormValues = 'SET_CALCULATOR_FORM_VALUES',
  UpdateCalculatorFormValues = 'UPDATE_CALCULATOR_FORM_VALUES',
  SetIsAccounting = 'SET_IS_ACCOUNTING',
  SetIsEditing = 'SET_IS_EDITING',
  AddTransportation = 'ADD_TRANSPORTATION_DATA',
  DeleteTransportation = 'DELETE_TRANSPORTATION_DATA',
  ClearTransportation = 'CLEAR_TRANSPORTATION_DATA',
  SetTripCalculatorSummary = 'SET_TRIP_CALCULATOR_SUMMARY',
  AddCurrency = 'ADD_CURRENCY',
  ClearCurrency = 'CLEAR_CURRENCY',
  SetIsEmptyCalculation = 'SET_EMPTY_CALCULATION',
}

export interface TripCalculatorContextProps {
  dispatch: Dispatch<Action>;
  state: State;
}

interface SetCalculatorFormValues {
  type: ActionKind.SetCalculatorFormValues;
  payload: { calculatorFormValues: TripCalculatorFormValues };
}
interface UpdateCalculatorFormValues {
  type: ActionKind.UpdateCalculatorFormValues;
  payload: { calculatorFormValues: TripCalculatorFormValues };
}
interface SetTripCalculatorSummary {
  type: ActionKind.SetTripCalculatorSummary;
  payload: { tripCalculatorSummary: TripCalculatorSummary };
}
interface SetIsAccounting {
  type: ActionKind.SetIsAccounting;
  payload: { isAccounting: boolean };
}
interface SetIsEditing {
  type: ActionKind.SetIsEditing;
  payload: { isEditing: boolean };
}
interface SetIsEmptyCalculation {
  type: ActionKind.SetIsEmptyCalculation;
  payload: { isEmptyCalculation: boolean };
}
interface AddCurrency {
  type: ActionKind.AddCurrency;
  payload: { currency: SelectDataForm<number> };
}
interface TypeOnlyAction {
  type:
    | ActionKind.RenderCalculator
    | ActionKind.RenderSummary
    | ActionKind.ClearTransportation
    | ActionKind.ClearCurrency;
}

export type Action =
  | SetCalculatorFormValues
  | UpdateCalculatorFormValues
  | SetIsAccounting
  | SetIsEditing
  | SetTripCalculatorSummary
  | AddCurrency
  | SetIsEmptyCalculation
  | TypeOnlyAction;

export enum RenderPage {
  Calculator = 'calculator',
  Summary = 'summary',
}
