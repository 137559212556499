import {
  ChangeEvent,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { OnChangeValue } from 'react-select';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { Block } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { UserContext } from 'globalContext/UserContext';
import moment from 'moment';
import {
  accountFilledInfoUrl,
  projectsUrl,
  timesheetCreationUrl,
  timesheetsUrl,
  workTimeFullReportUrl,
  workTimeQuickReportUrl,
  workTimeRemoveProjectAssignmentUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import Datepicker from 'components/Datepicker';
import AssignProjectDialog from 'components/Dialog/AssignProjectDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import DateRangeDialog from 'components/Dialog/DateRangeDialog';
import MissingUserProfileInfoDialog from 'components/Dialog/MissingInfoDialog';
import {
  MissingInfoType,
  MissingUserProfileInfo,
} from 'components/Dialog/MissingInfoDialog/types';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import {
  DefaultProject,
  DefaultProjectInfo,
  DefaultProjectName,
  GreyBorder,
  HeaderTooltipText,
  InformationIcon,
  Paper,
  StyledTextField,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader as TableHeadComponent,
  TableRow,
  Tooltip,
} from 'components/Table/Table.css';
import { TableHead } from 'components/Table/types';
import TableLoadingWrapper from 'components/TableLoadingWrapper';
import ToolTipCell from 'components/ToolTipCell';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { GridWrapperWithMargin } from 'modules/Reports/Reports.css';
import {
  DEFAULT_PROJECT_LABEL,
  SUBMIT_BUTTON_LABEL,
} from 'modules/WorkTime/static_data';
import { TimesheetData } from 'modules/WorkTime/types';
import { AssignButton, GridWrapper } from 'modules/WorkTime/WorkTime.css';
import { formatMonthsToTwoDigits } from 'utils/dateFormats';
import { deleteFetch, getFetch, postFetch } from 'utils/fetchFunctions';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import ActionIcons from './components/ActionIcons';
import {
  IconLoadingProps,
  QuickReport,
  QuickReportCustom,
  QuickReportCustomProject,
  QuickReportProject,
  TableQuickReportProps as TableProps,
} from './types';
import {
  checkIfStatusInactive,
  getStatusTooltipTitle,
  isCustomInputDirty,
  isInputDirty,
} from './utils';
import {
  CustomProjectsSaveButton,
  CustomProjectsTable,
  LoweredParagraphWithTooltip,
} from './QuickReport.css';

export const WORK_TIME_QUICK_REPORT_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'client',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
  {
    label: 'TABLE.HEAD.PROJECT',
    key: 'project',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TABLE_FILTER.LABEL.PROJECT_CUSTOM',
    key: 'projectCustom',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '20%',
  },
  {
    label: 'TABLE.HEAD.REPORTED_TIME',
    key: 'reportedDays',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '25%',
  },
  {
    label: 'TABLE.HEAD.REPORT_HOURS',
    key: 'reportedHours',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '25%',
  },
  {
    label: 'TABLE.HEAD.MULTIPLICATOR',
    key: 'multiplier',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
];

const QuickReportTable = ({
  loading = false,
  fetchClientsAndProjects,
  timesheetUsername,
  timesheetData,
  date,
  setDate,
  setTimesheetUsername,
  selectedUser,
  setSelectedUser,
  setIsFormedChanged,
  myTimesheetLoaded,
  isUserAuthorized,
  userContract,
}: TableProps) => {
  const intl = useIntl();
  const [readyToSend, setReadyToSend] = useState(false);
  const [readyToSendCustomData, setReadyToSendCustomData] = useState(false);
  const [quickTableData, setQuickTableData] = useState<QuickReport>(Object);
  const [addChecked, handleAddChecked] = useToggleState(false);
  const [reportAvailable, setReportAvailable] = useState(false);
  const [isFullReportEmpty, setIsFullReportEmpty] = useState(false);
  const [hasCustomProject, setHasCustomProject] = useState(false);
  const [iconLoading, setIconLoading] = useState<
    IconLoadingProps | undefined
  >();
  const [row, setRow] = useState({
    clientName: '',
    clientId: 0,
    projectName: '',
    projectId: 0,
  });
  const [initialProjectsData, setInitialProjectsData] = useState<
    QuickReportProject[]
  >([]);
  const [initialCustomProjectsData, setInitialCustomProjectsData] = useState<
    QuickReportCustomProject[]
  >([]);
  const [customTableData, setCustomTableData] = useState<QuickReportCustom>({
    month: new Date().getMonth().toString(),
    year: new Date().getFullYear().toString(),
    reportedDays: 0,
    reportedWorkUnits: 0,
    medicalLeaveDays: 0,
    vacationDays: 0,
    unreportedDays: 0,
    workingDaysInMonth: 0,
    projects: [],
    settled: false,
  });
  const [canFetchOrCreate, setCanFetchOrCreate] = useState(true);
  const [quickReportLoading, setQuickReportLoading] = useState(false);
  const [openMissingInfoDialog, setOpenMissingInfoDialog] = useState(false);
  const [isValidInput, setIsValidInput] = useState(true);
  const { username } = useContext(UserContext);
  const [toggleConfimationDialog, handleToggleConfimationDialog] =
    useToggleState(false);
  const isUserSettled = !!quickTableData?.settled;
  const isTimesheetDisabled = !myTimesheetLoaded || isUserSettled;
  const userData = timesheetData?.find((data) => data.username === username);
  const [isDateRangeDialogOpen, setDateRangeDialogOpen] = useToggleState(false);
  const [missingInfo, setMissingInfo] = useState<MissingUserProfileInfo>();
  const isEmploymentContract = Boolean(
    userContract === ContractTypes.EMPLOYMENT_CONTRACT,
  );
  const isSaveWorkUnitsDisabled = (entry: QuickReportCustomProject) => {
    return isTimesheetDisabled || checkIfStatusInactive(entry);
  };

  useEffect(() => {
    setIsFormedChanged(false);
  }, [setIsFormedChanged]);

  const onTextFieldBlur = () => {
    const reportedDays = quickTableData.projects.reduce(
      (acctualNumber, project) => {
        return acctualNumber + Number(project.reportedTimeHours);
      },
      0,
    );
    const reportedWorkUnits = quickTableData.projects.reduce(
      (actualNumber, project) => {
        return actualNumber + Number(project.reportedWorkUnits);
      },
      0,
    );
    const unreportedDays =
      Number(quickTableData.workingDaysInMonth) - reportedDays;
    if (unreportedDays < 0) {
      setReadyToSend(false);
      toast.error(
        intl.formatMessage({ id: `REPORTING_TIME.TOAST_OVER_HOURS` }),
      );
    }
    setQuickTableData({
      ...quickTableData,
      reportedDays,
      unreportedDays,
      reportedWorkUnits,
    });
  };

  const onKeyPress = (event: KeyboardEvent, index: number): void => {
    if (event.key === 'Backspace') {
      setQuickTableData((previousQuickTableData) => ({
        ...previousQuickTableData,
        projects: Object.assign([], quickTableData.projects, {
          [index]: {
            ...quickTableData.projects[index],
            reportedTimeHours: 0,
          },
        }),
      }));
    }
  };

  const onKeyPressWorkUnits = (event: KeyboardEvent, index: number): void => {
    if (event.key === 'Backspace') {
      setCustomTableData((previousCustomTableData) => ({
        ...previousCustomTableData,
        projects: Object.assign([], customTableData.projects, {
          [index]: {
            ...customTableData.projects[index],
            reportedWorkUnits: 0,
          },
        }),
      }));
    }
  };

  const createTimesheet = useCallback(async () => {
    const body = {
      month: date!.getMonth() + 1,
      year: date!.getFullYear(),
    };
    await postFetch({
      url: timesheetCreationUrl,
      body,
      showErrorToast: false,
    });
  }, [date]);

  const fetchReport = useCallback(
    async (first = false) => {
      try {
        setQuickReportLoading(true);
        const quickReportResponse = await getFetch({
          url:
            `${timesheetsUrl}/${
              selectedUser?.username || username
            }/${workTimeQuickReportUrl}` +
            `?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
              date!.getMonth() + 1,
            )}`,
          showErrorToast: false,
        });
        const quickReportData = {
          ...quickReportResponse,
          projects: quickReportResponse.projects.map(
            (project: QuickReportProject) => ({
              ...project,
              reportedTimeHours: project.reportedTimeHours / 8,
              reportedWorkUnits: project.reportedWorkUnits,
            }),
          ),
        };

        setInitialProjectsData(quickReportData.projects);
        setQuickTableData(quickReportData);
        setReportAvailable(true);
        setCanFetchOrCreate(true);
      } catch (e) {
        setReportAvailable(false);
        if (first && !timesheetUsername) {
          await createTimesheet();
          await fetchReport();
        } else {
          setCanFetchOrCreate(false);
        }
      } finally {
        setQuickReportLoading(false);
      }
    },
    [
      selectedUser?.username,
      username,
      timesheetUsername,
      date,
      createTimesheet,
    ],
  );

  const fetchFullReport = useCallback(async () => {
    const data = await getFetch({
      showErrorToast: false,
      url: `${timesheetsUrl}/${username}/${workTimeFullReportUrl}?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
        date!.getMonth() + 1,
      )}`,
    });
    setIsFullReportEmpty(data?.reportedDays === 0);
  }, [date, username]);

  const fetchCustomData = useCallback(async () => {
    const user = selectedUser?.username ? selectedUser.username : username;
    const customData = await getFetch({
      showErrorToast: false,
      url: `${timesheetsUrl}/${user}/${workTimeQuickReportUrl}/custom?yearMonth=${date!.getFullYear()}-${formatMonthsToTwoDigits(
        date!.getMonth() + 1,
      )}`,
    });
    if (customData) {
      setCustomTableData(customData);
      setHasCustomProject(customData?.length > 0);
      setInitialCustomProjectsData(customData.projects);
    }
  }, [date, username, selectedUser]);

  useEffect(() => {
    fetchFullReport();
    fetchCustomData();
  }, [
    fetchFullReport,
    fetchCustomData,
    quickTableData.reportedDays,
    customTableData.reportedWorkUnits,
  ]);

  useEffect(() => {
    fetchReport(true);
  }, [date, fetchReport, hasCustomProject, timesheetUsername]);

  const isNaturalNumber = (n: number) => {
    var pattern = /^(0|([1-9]\d*))$/;
    return pattern.test(n.toString());
  };

  const postQuickReport = useCallback(
    async (projectsRenamed, deletingProject?) => {
      const reportedDays = quickTableData.projects.reduce((acc, project) => {
        return acc + project.reportedTimeHours;
      }, 0);
      if (quickTableData.workingDaysInMonth - reportedDays < 0) {
        toast.error(
          intl.formatMessage({ id: `REPORTING_TIME.TOAST_OVER_HOURS` }),
        );
      } else if (!isNaturalNumber(reportedDays)) {
        toast.error(
          intl.formatMessage({ id: `REPORTING_TIME.NATURAL_NUMBERS_REQUIRED` }),
        );
      } else {
        const body = {
          month: date!.getMonth() + 1,
          year: date!.getFullYear(),
          projects: projectsRenamed,
        };
        setReadyToSend(false);
        const { ok } = await postFetch({
          url: `${timesheetsUrl}/${
            selectedUser?.username || username
          }/${workTimeQuickReportUrl}`,
          body,
          intl,
          label: deletingProject ? 'DELETING_TIME' : 'REPORTING_TIME',
        });
        if (ok) {
          fetchReport();
        } else {
          setReadyToSend(true);
        }
      }
    },
    [
      date,
      fetchReport,
      intl,
      quickTableData.projects,
      quickTableData.workingDaysInMonth,
      selectedUser?.username,
      username,
    ],
  );

  const onReportedDaysClick = useCallback(() => {
    !isFullReportEmpty && handleToggleConfimationDialog();
    const projectsRenamed = quickTableData.projects
      .filter(
        (project) =>
          !(project.reportedTimeHours === 0 && project.reportedWorkUnits === 0),
      )
      .map((project: any) => ({
        name: project.projectName,
        client: project.clientName,
        reportedDays: project.reportedTimeHours.toString(),
      }));
    if (date) {
      postQuickReport(projectsRenamed);
    }
  }, [
    isFullReportEmpty,
    handleToggleConfimationDialog,
    quickTableData.projects,
    date,
    postQuickReport,
  ]);

  const onCustomTableSubmit = useCallback(async () => {
    const customProjectsRenamed = customTableData.projects.map(
      (project: QuickReportCustomProject) => ({
        name: project.projectName,
        client: project.clientName,
        reportedWorkUnits: project.reportedWorkUnits,
      }),
    );
    const body = {
      month: date!.getMonth() + 1,
      year: date!.getFullYear(),
      projects: customProjectsRenamed,
    };
    setReadyToSendCustomData(false);
    const { ok } = await postFetch({
      url: `${timesheetsUrl}/${
        selectedUser?.username || username
      }/${workTimeQuickReportUrl}/custom`,
      body,
      intl,
      label: 'REPORTING_CUSTOM_TIME',
    });
    if (ok) {
      fetchCustomData();
    } else {
      setReadyToSendCustomData(true);
    }
  }, [
    customTableData.projects,
    date,
    selectedUser?.username,
    username,
    intl,
    fetchCustomData,
  ]);

  const handleDateRangeSelect = (entry: QuickReportProject) => {
    setRow(entry);
    setDateRangeDialogOpen();
  };

  const handleUnassignProject = useCallback(
    async (projectId: number) => {
      setIconLoading({ id: projectId, action: 'unassignProject' });
      const { ok } = await deleteFetch({
        url: `${workTimeRemoveProjectAssignmentUrl}?id=${projectId}`,
        intl,
        label: 'PROJECT.UNASSIGN',
      });
      if (ok) {
        await fetchReport();
      }
      fetchCustomData();
      setIconLoading(undefined);
    },
    [fetchReport, intl, fetchCustomData],
  );

  const onReportedDaysChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ): void => {
    setReadyToSend(false);
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    if (isInputDirty(event, initialProjectsData)) {
      setReadyToSend(true);
      fetchFullReport();
    }

    setQuickTableData({
      ...quickTableData,
      projects: Object.assign([], quickTableData.projects, {
        [index]: {
          ...quickTableData.projects[index],
          reportedTimeHours: Number(onlyNums),
        },
      }),
    });
  };
  const onReportedWorkUnitsChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ): void => {
    setReadyToSendCustomData(false);
    setIsValidInput(Boolean(event.target.value.match(/^\d+(\.\d\d)?$/)));
    const onlyNums = event.target.value;

    if (isCustomInputDirty(event, initialCustomProjectsData)) {
      setReadyToSendCustomData(true);
    }
    setCustomTableData({
      ...customTableData,
      projects: Object.assign([], customTableData.projects, {
        [index]: {
          ...customTableData.projects[index],
          reportedWorkUnits: onlyNums,
        },
      }),
    });
  };

  const onDateChange = useCallback(
    (date: SetStateAction<Date | null>) => {
      setDate(date);
    },
    [setDate],
  );

  const handleClearButton = useCallback(() => {
    setDate(moment().toDate());
  }, [setDate]);

  const handleSelectUser = useCallback(
    (e: OnChangeValue<TimesheetData, boolean>) => {
      if (e) {
        e = e as TimesheetData;
        setSelectedUser(e);
        if (e.username) {
          setTimesheetUsername(e.username);
        }
      } else {
        setTimesheetUsername('');
        setSelectedUser({ name: '', value: 0, username: '' });
      }
    },
    [setSelectedUser, setTimesheetUsername],
  );

  const handleChangeDefaultProject = useCallback(
    async (entry: QuickReportProject) => {
      setIconLoading({ id: entry.projectId, action: 'setDefaultProject' });
      const { ok } = await postFetch({
        url: `${projectsUrl}/assign-as-default/${entry.projectId}`,
        intl,
        label: 'WORK_TIME.SET_DEFAULT_PROJECT',
      });
      if (ok) {
        await fetchReport();
      }
      setIconLoading(undefined);
    },
    [fetchReport, intl],
  );

  const renderTableBody = (quickTableData: QuickReport) => {
    if (!canFetchOrCreate) {
      return (
        <TableRow>
          <TableCell
            align={'center'}
            colSpan={WORK_TIME_QUICK_REPORT_HEAD_DATA.length}
          >
            <Block />
            <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
          </TableCell>
        </TableRow>
      );
    } else if (!quickTableData?.projects?.length) {
      return (
        <TableRow>
          <TableCell
            align={'center'}
            colSpan={WORK_TIME_QUICK_REPORT_HEAD_DATA.length}
          ></TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {quickTableData.projects.map(
          (entry: QuickReportProject, index: number) => {
            return (
              <TableRow key={`${entry.clientName}-${index}`} index={index}>
                <TableCell>
                  <ToolTipCell
                    id="clientDescriptionTooltip"
                    tooltip={entry.clientDescription}
                    text={entry.clientName}
                  />
                </TableCell>
                <TableCell>
                  {entry.userDefaultProject ? (
                    <DefaultProject>
                      <DefaultProjectName>
                        <ToolTipCell
                          id="projectDescriptionTooltip"
                          text={entry.projectName}
                          tooltip={entry.projectDescription}
                          isChecked={entry.billable}
                          checkTooltip={intl.formatMessage({
                            id: entry.billable
                              ? 'TIMESHEET.BILLABILITY_TRUE'
                              : 'TIMESHEET.BILLABILITY_FALSE',
                          })}
                        />
                      </DefaultProjectName>
                      <DefaultProjectInfo>
                        {intl.formatMessage({
                          id: DEFAULT_PROJECT_LABEL.label,
                        })}
                      </DefaultProjectInfo>
                    </DefaultProject>
                  ) : (
                    <ToolTipCell
                      id="billabilityTooltip"
                      text={entry.projectName}
                      tooltip={entry.projectDescription}
                      isChecked={entry.billable}
                      checkTooltip={intl.formatMessage({
                        id: entry.billable
                          ? 'TIMESHEET.BILLABILITY_TRUE'
                          : 'TIMESHEET.BILLABILITY_FALSE',
                      })}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <StyledTextField
                    disabled={
                      isTimesheetDisabled || checkIfStatusInactive(entry)
                    }
                    onBlur={onTextFieldBlur}
                    value={
                      entry.reportedTimeHours || entry.reportedWorkUnits || 0
                    }
                    type="text"
                    onChange={(event) => onReportedDaysChange(event, index)}
                    onKeyDown={(event) => onKeyPress(event, index)}
                  />
                  {checkIfStatusInactive(entry) && (
                    <Tooltip
                      title={intl.formatMessage({
                        id: `WORK_TIME_TAB.${getStatusTooltipTitle(
                          entry,
                        )}.TOOLTIP`,
                      })}
                      placement="top"
                      style={{
                        marginTop: '0.5rem',
                      }}
                    >
                      <InformationIcon id="statusInactiveTooltip" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  {entry.project.multiplicator}
                </TableCell>
                <TableCell align="left">
                  <ActionIcons
                    entry={entry}
                    isTimesheetDisabled={isTimesheetDisabled}
                    iconLoading={iconLoading}
                    handleDateRange={handleDateRangeSelect}
                    handleUnassignProject={handleUnassignProject}
                    handleChangeDefaultProject={handleChangeDefaultProject}
                    hasCustomData={entry.project.projectCategory === 'CUSTOM'}
                    canUnassign={!readyToSend}
                  />
                </TableCell>
              </TableRow>
            );
          },
        )}
        <TableRow $main>
          {isEmploymentContract && (
            <>
              <TableCell $whiteText colSpan={1}>
                {`${intl.formatMessage({
                  id: 'SUMMARY.MEDICAL_LEAVE',
                })}: ${quickTableData.medicalLeaveDays}`}
              </TableCell>

              <TableCell $whiteText>
                {`${intl.formatMessage({
                  id: 'SUMMARY.VACATIONS',
                })}: ${quickTableData.vacationDays}`}
              </TableCell>
            </>
          )}
          {userContract !== ContractTypes.EMPLOYMENT_CONTRACT && (
            <TableCell $whiteText colSpan={2}>
              {`${intl.formatMessage({
                id: 'SUMMARY.DAYS_OFF',
              })}: ${quickTableData.daysOff}`}
            </TableCell>
          )}
          <TableCell $whiteText>
            {`${intl.formatMessage({
              id: 'TABLE.HEAD.REPORTED_DAYS',
            })}: ${quickTableData.reportedDays}`}
          </TableCell>
          <TableCell $whiteText colSpan={isEmploymentContract ? 1 : 2}>
            {`${intl.formatMessage({
              id: 'TABLE.HEAD.NOT_REPORTED_DAYS',
            })}: ${quickTableData.unreportedDays}`}
          </TableCell>
          {isEmploymentContract && (
            <TableCell $whiteText style={{ textAlign: 'center' }}>
              {`${intl.formatMessage({
                id: 'SUMMARY.DAYS_OFF',
              })}: ${quickTableData.daysOff}`}
            </TableCell>
          )}
        </TableRow>
      </>
    );
  };
  const renderCustomTableBody = (customTableData: QuickReportCustom) => {
    if (!customTableData?.projects?.length) {
      return (
        <TableRow>
          <TableCell
            align={'center'}
            colSpan={WORK_TIME_QUICK_REPORT_HEAD_DATA.length}
          ></TableCell>
        </TableRow>
      );
    } else if (!canFetchOrCreate) {
      return (
        <TableRow>
          <TableCell
            align={'center'}
            colSpan={WORK_TIME_QUICK_REPORT_HEAD_DATA.length}
          >
            <Block />
            <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {customTableData.projects.map(
          (entry: QuickReportCustomProject, index: number) => {
            return (
              <TableRow
                key={`${entry.clientName}-${entry.projectName} - ${index}`}
                index={index}
              >
                <TableCell>
                  <ToolTipCell
                    id="clientDescriptionTooltip"
                    tooltip={entry.clientDescription}
                    text={entry.clientName}
                  />
                </TableCell>
                <TableCell>
                  <ToolTipCell
                    id="billabilityTooltip"
                    text={entry.projectName}
                    tooltip={entry.projectDescription}
                    isChecked={entry.billable}
                    checkTooltip={intl.formatMessage({
                      id: entry.billable
                        ? 'TIMESHEET.BILLABILITY_TRUE'
                        : 'TIMESHEET.BILLABILITY_FALSE',
                    })}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    disabled={isSaveWorkUnitsDisabled(entry)}
                    onBlur={onTextFieldBlur}
                    value={entry.reportedWorkUnits}
                    type="text"
                    onChange={(event) =>
                      onReportedWorkUnitsChange(event, index)
                    }
                    onKeyDown={(event) => onKeyPressWorkUnits(event, index)}
                  />

                  {checkIfStatusInactive(entry) ? (
                    <>
                      <Tooltip
                        title={intl.formatMessage({
                          id: `WORK_TIME_TAB.${getStatusTooltipTitle(
                            entry,
                          )}.TOOLTIP`,
                        })}
                        placement="top"
                        style={{
                          marginTop: '0.5rem',
                        }}
                      >
                        <InformationIcon id="statusInactiveTooltip" />
                      </Tooltip>
                      <LoweredParagraphWithTooltip>
                        {entry.project.workUnit}
                      </LoweredParagraphWithTooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          <HeaderTooltipText>
                            {intl.formatMessage({
                              id: `WORK_TIME_TAB.DECIMALS.TOOLTIP`,
                            })}
                          </HeaderTooltipText>
                        }
                        placement="top"
                        style={{
                          marginTop: '0.5rem',
                        }}
                      >
                        <InformationIcon id="decimalsInfoTooltip" />
                      </Tooltip>
                      <LoweredParagraphWithTooltip>
                        {entry.project.workUnit}
                      </LoweredParagraphWithTooltip>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {entry.project.multiplicator}
                </TableCell>
                <TableCell align="left">
                  {entry.project.projectCategory !== 'REMOTE_WORK' && (
                    <ActionIcons
                      entry={entry}
                      isTimesheetDisabled={isTimesheetDisabled}
                      iconLoading={iconLoading}
                      handleDateRange={handleDateRangeSelect}
                      handleUnassignProject={handleUnassignProject}
                      handleChangeDefaultProject={handleChangeDefaultProject}
                      hasCustomData={
                        entry.project.projectCategory === 'CUSTOM' ||
                        entry.project.projectCategory === 'REMOTE_WORK'
                      }
                      canUnassign={
                        !readyToSendCustomData ||
                        entry.project.projectCategory === 'REMOTE_WORK'
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          },
        )}
      </>
    );
  };
  const getAccountMissingInfo = useCallback(async () => {
    if (myTimesheetLoaded) {
      const response: MissingUserProfileInfo = await getFetch({
        url: accountFilledInfoUrl,
      });
      setMissingInfo(response);
      if (response && !response.defaultTimeDefined) {
        setOpenMissingInfoDialog(true);
      }
    }
  }, [myTimesheetLoaded]);

  useEffect(() => {
    getAccountMissingInfo();
  }, [getAccountMissingInfo]);

  return (
    <>
      <GridWrapper
        container
        spacing={2}
        alignItems="center"
        style={{ width: '100%', position: 'relative' }}
      >
        <Grid
          item
          xs={5}
          sm={3}
          lg={2}
          style={{
            maxWidth: '250px',
            minWidth: '250px',
          }}
        >
          <GreyBorder>
            {reportAvailable &&
              `${intl.formatMessage({
                id: 'SUMMARY.WORK_DAYS',
              })}: ${quickTableData.workingDaysInMonth}`}
          </GreyBorder>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={5}
          lg={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '300px',
          }}
        >
          <Datepicker
            label={intl.formatMessage({
              id: 'TABLE_FILTER.LABEL.DATE',
            })}
            monthJumpButtons
            value={date}
            onChange={onDateChange}
            onClearButtonClick={handleClearButton}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={7} md={3} lg={2} style={{ marginTop: '7px' }}>
          {isUserAuthorized && (
            <ReactSelect
              name="users"
              label={intl.formatMessage({
                id: 'WORK_TIME.SELECT_USER',
              })}
              options={timesheetData || []}
              defaultValue={selectedUser?.name.length ? selectedUser : userData}
              handleChange={handleSelectUser}
            />
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={5}
          justify="flex-end"
          style={{
            position: 'absolute',
            right: '0',
          }}
        >
          <AssignButton
            id="assignButton"
            endIcon={<AddIcon />}
            disabled={!reportAvailable || isTimesheetDisabled}
            size="large"
            onClick={handleAddChecked}
          >
            {intl.formatMessage({
              id: 'WORK_TIME_TAB.ASSIGN_BUTTON',
            })}
          </AssignButton>
        </Grid>
      </GridWrapper>
      <TableContainer component={Paper} $loading={loading}>
        <TableComponent>
          <TableLoadingWrapper loading={quickReportLoading}>
            <>
              <TableHeadComponent>
                <TableRow $main>
                  {WORK_TIME_QUICK_REPORT_HEAD_DATA.filter(
                    (headData) =>
                      headData.key !== 'reportedHours' &&
                      headData.key !== 'projectCustom',
                  ).map((headData, index) => (
                    <TableCell
                      align={headData.align}
                      key={`${index} - ${headData.key}`}
                      width={headData.width}
                    >
                      {intl.formatMessage({ id: headData.label })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeadComponent>
              <TableBody id="quickReportTableBody">
                {renderTableBody(quickTableData)}
              </TableBody>
            </>
          </TableLoadingWrapper>
        </TableComponent>
      </TableContainer>
      {reportAvailable && myTimesheetLoaded && (
        <GridWrapperWithMargin container placement={'top'}>
          <Grid item xs={12} container justify="flex-end" alignItems="center">
            {isUserSettled ? (
              <ToolTipCell
                id="settledTooltip"
                styles={{
                  wrapperStyles: { marginTop: '0', marginBottom: '0' },
                }}
                bigger
                text={intl.formatMessage({
                  id: 'WORK_TIME_SETTLED_TEXT',
                })}
                tooltip={intl.formatMessage({
                  id: 'WORK_TIME_SETTLED_TOOLTIP',
                })}
              />
            ) : null}
            <ContainedButton
              id="saveButton"
              disabled={!readyToSend}
              onClick={
                isFullReportEmpty
                  ? onReportedDaysClick
                  : handleToggleConfimationDialog
              }
              size="large"
              style={isUserSettled ? { marginLeft: '1rem' } : undefined}
            >
              {intl.formatMessage({
                id: SUBMIT_BUTTON_LABEL.label,
              })}
            </ContainedButton>
          </Grid>
        </GridWrapperWithMargin>
      )}
      {customTableData?.projects?.length > 0 && (
        <>
          <CustomProjectsTable>
            <TableContainer component={Paper} $loading={loading}>
              <TableComponent>
                <TableHeadComponent>
                  <TableRow $main>
                    {WORK_TIME_QUICK_REPORT_HEAD_DATA.filter(
                      (headData) =>
                        headData.key !== 'reportedDays' &&
                        headData.key !== 'project',
                    ).map((headData, index) => (
                      <TableCell
                        align={headData.align}
                        key={`${index} - ${headData.key}`}
                        width={headData.width}
                      >
                        {intl.formatMessage({ id: headData.label })}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHeadComponent>
                <TableBody id="quickReportCustomTableBody">
                  {renderCustomTableBody(customTableData)}
                </TableBody>
              </TableComponent>
            </TableContainer>
          </CustomProjectsTable>
          <GridWrapperWithMargin container placement={'top'}>
            <Grid item xs={12} container justify="flex-end" alignItems="center">
              {!isValidInput ? (
                <Tooltip
                  title={
                    <HeaderTooltipText>
                      {intl.formatMessage({
                        id: 'TABLE.QUICK_REPORT.BLOCK_INVALID_INPUT',
                      })}
                    </HeaderTooltipText>
                  }
                  placement="top"
                >
                  <div>
                    <CustomProjectsSaveButton
                      id="saveCustomDataButton"
                      disabled
                      onClick={onCustomTableSubmit}
                      size="large"
                    >
                      {intl.formatMessage({
                        id: SUBMIT_BUTTON_LABEL.label,
                      })}
                    </CustomProjectsSaveButton>
                  </div>
                </Tooltip>
              ) : (
                <CustomProjectsSaveButton
                  id="saveCustomDataButton"
                  disabled={!readyToSendCustomData}
                  onClick={onCustomTableSubmit}
                  size="large"
                >
                  {intl.formatMessage({
                    id: SUBMIT_BUTTON_LABEL.label,
                  })}
                </CustomProjectsSaveButton>
              )}
            </Grid>
          </GridWrapperWithMargin>
        </>
      )}
      {toggleConfimationDialog && (
        <ConfirmationDialog
          addChecked={toggleConfimationDialog}
          handleAddChanged={handleToggleConfimationDialog}
          handleSubmit={onReportedDaysClick}
          title={intl.formatMessage({
            id: 'WORK_TIME.CONFIRMATION_DIALOG.TITLE',
          })}
          content={intl.formatMessage({
            id: 'WORK_TIME.CONFIRMATION_DIALOG.CONTENT',
          })}
        />
      )}
      {addChecked && (
        <AssignProjectDialog
          addChecked={addChecked}
          handleAddChanged={handleAddChecked}
          fetchReport={fetchReport}
          fetchCustomReport={fetchCustomData}
          fetchClientsAndProjects={fetchClientsAndProjects}
        />
      )}
      {isDateRangeDialogOpen && (
        <DateRangeDialog
          isDialogOpen={isDateRangeDialogOpen}
          handleShowClicked={setDateRangeDialogOpen}
          selectedUser={selectedUser}
          row={row}
          fetchData={fetchReport}
          date={date}
        />
      )}
      {openMissingInfoDialog && (
        <MissingUserProfileInfoDialog
          onClose={setOpenMissingInfoDialog}
          missingInfo={missingInfo}
          type={MissingInfoType.quickReport}
        />
      )}
    </>
  );
};

export default QuickReportTable;
