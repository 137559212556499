import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton, TextField } from '@material-ui/core';
import ContainedButton from 'components/Button/ContainedButton';
import { ConfirmationDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapperFlex,
  DialogParagraph,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
} from '../Dialog.css';
import { Note } from './ConfirmationDialog.css';

const ConfirmationDialog = ({
  addChecked,
  handleAddChanged,
  handleActivateClicked,
  handleSubmit,
  title,
  content,
  data,
  activateClicked,
  isButtonDisabled,
  isSubmitDisabled = false,
  withCancellingNote = false,
  confirmMessage = 'MODAL.BUTTON.SUBMIT',
  cancelMessage = 'MODAL.BUTTON.CANCEL',
}: ConfirmationDialogProps) => {
  const intl = useIntl();
  const [noteContent, setNoteContent] = useState('');
  const handleClose = useCallback(() => {
    handleAddChanged();
    handleActivateClicked && handleActivateClicked();
  }, [handleAddChanged, handleActivateClicked]);

  const isInactive = useMemo(
    () => data?.status === 'INACTIVE' && activateClicked,
    [activateClicked, data],
  );

  const getTitle = () => {
    switch (true) {
      case isInactive && data?.hasOwnProperty('clientName'):
        return intl.formatMessage({ id: 'CLIENTS.ACTIVATE_DIALOG_TITLE' });
      case isInactive:
        return intl.formatMessage({ id: 'PROJECTS.ACTIVATE_DIALOG_TITLE' });
      default:
        return title;
    }
  };

  const getContent = () => {
    switch (true) {
      case isInactive && data?.hasOwnProperty('clientName'):
        return intl.formatMessage({ id: 'CLIENTS.ACTIVATE_DIALOG_CONTENT' });
      case isInactive:
        return intl.formatMessage({ id: 'PROJECTS.ACTIVATE_DIALOG_CONTENT' });
      default:
        return content.replace('. ', '.\n');
    }
  };

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {getTitle()}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      {withCancellingNote && (
        <Note>
          <TextField
            id="outlined-multiline-static"
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.REASON',
            })}
            multiline
            rows={3}
            defaultValue=""
            variant="outlined"
            fullWidth
            onChange={(event) => setNoteContent(event.target.value)}
          />
        </Note>
      )}
      <DialogContentWrapperFlex>
        <DialogParagraph>{getContent()}</DialogParagraph>
      </DialogContentWrapperFlex>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleClose}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: cancelMessage })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="confirmationButton"
            fullWidth
            onClick={() => handleSubmit(noteContent)}
            size="large"
            type="submit"
            disabled={
              withCancellingNote
                ? noteContent.replaceAll(' ', '').length === 0 ||
                  isButtonDisabled
                : isSubmitDisabled
            }
          >
            {intl.formatMessage({ id: confirmMessage })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default ConfirmationDialog;
