import { IntlShape } from 'react-intl';
import {
  BenefitAccountFormValues,
  BillabilityTypes,
  Partner,
} from 'components/BenefitInputs/types';
import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { State } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import {
  Packages,
  SummaryDataTypes,
} from 'modules/UserProfile/Screens/Benefits/types';

const getChosenPackageCosts = (state: State) => {
  if (state.benefitsData?.benefitPackage?.type !== Packages.notApplicable) {
    const packageName: Packages | undefined =
      state.benefitsData?.benefitPackage?.type;

    const packageInfo = packageName && state.selectedBenefitType?.[packageName];

    const cost = Number(packageInfo?.cost);

    return cost ? `${cost.toFixed(2)} zł` : '-';
  }
};

const getBillableByPersonCosts = (state: State) => {
  return (
    state.selectedBenefitType &&
    Object.entries(state.selectedBenefitType)
      .reduce((acc, curr) => {
        const content = curr?.[1];
        const cost = content?.cost ?? 0;
        acc += cost;
        return acc;
      }, 0)
      .toFixed(0) + ' zł'
  );
};

export const getGeneralValues = (intl: IntlShape, state: State) => {
  const generalValues = [
    {
      title: `${intl.formatMessage({ id: 'BENEFITS.FORM.BENEFIT_NAME' })}:`,
      content: state.selectedBenefit?.benefitName,
    },
    {
      title: intl.formatMessage({
        id: 'USER_PROFILE.BENEFITS.FORM_INFO.BENEFIT_TYPE',
      }),
      content: state.selectedBenefitType?.benefitTypeName,
    },
  ];

  if (state.selectedBenefit?.billableBy === BillabilityTypes.package) {
    generalValues.push(
      {
        title: intl.formatMessage({
          id: 'USER_PROFILE.BENEFITS.FORM_INFO.BENEFIT_PACKAGE',
        }),
        content: intl.formatMessage({
          id: `BENEFITS.FORM.${getFormattedName(
            state.benefitsData?.benefitPackage?.type,
          )}`,
        }),
      },
      {
        title: intl.formatMessage({
          id: 'USER_PROFILE.BENEFITS.FORM_INFO.TOTAL_COST',
        }),
        content: getChosenPackageCosts(state),
      },
    );
  } else {
    generalValues.push({
      title: intl.formatMessage({
        id: 'USER_PROFILE.BENEFITS.FORM_INFO.BENEFIT_COST',
      }),
      content: getBillableByPersonCosts(state),
    });
  }

  return generalValues;
};

const inputPersonalDataShouldBeIncluded = (key: string) => {
  const inputsToInclude = [
    'dateOfBirth',
    'email',
    'firstName',
    'gender',
    'idNumber',
    'pesel',
    'phoneNumber',
    'placeOfBirth',
    'surname',
    'partnerType',
  ];
  return inputsToInclude.includes(key);
};

export const getPersonalData = (
  intl: IntlShape,
  data?: BenefitAccountFormValues | Partner,
) => {
  if (data) {
    return Object.entries(data).reduce((acc: SummaryDataTypes[], curr) => {
      let [key, content] = curr;
      if (inputPersonalDataShouldBeIncluded(key)) {
        if (key === 'dateOfBirth') {
          content = content.format('YYYY/MM/DD');
        }
        if (key === 'gender' || key === 'partnerType') {
          content = content.name;
        }
        acc.push({
          title: `${intl.formatMessage({
            id: `USER_PROFILE.BENEFITS.FORM.${getFormattedName(key)}`,
          })}:`,
          content: content,
        });
      }
      return acc;
    }, []);
  }
};

export const getAddress = (
  state: State,
  intl: IntlShape,
  addressType: 'address' | 'crspAddress',
  index?: number,
) => {
  const address =
    index || index === 0
      ? state.benefitsData?.partners?.[index]?.[addressType]
      : state.benefitsData?.employee?.[addressType];
  if (address) {
    return Object.entries(address).reduce((acc: SummaryDataTypes[], curr) => {
      const [key, content] = curr;
      acc.push({
        title: `${intl.formatMessage({
          id: `USER_PROFILE.BENEFITS.FORM.${getFormattedName(key)}`,
        })}:`,
        content: content,
      });
      return acc;
    }, []);
  }
};

export const getPartnersPersonalData = (
  intl: IntlShape,
  partners?: Partner[],
) => {
  return partners?.map((partner) => getPersonalData(intl, partner));
};

export const getPartnersTitle = (index: number, intl: IntlShape) => {
  const message = intl.formatMessage({
    id: 'USER_PROFILE.BENEFITS.FORM.PARTNERS_DATA',
  });
  return index ? `${message} ${`(${index})`}` : message;
};
