import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { ReminderIcon, Tooltip } from 'components/Table/Table.css';
import TableButtonIcon from 'components/TableIconButton/TableButtonIcon';

interface Props {
  id: string;
  timesheetId: number;
  setTimesheetNrActionLoading: (el: number | undefined) => void;
  disabled: boolean;
}

const LeaderReminder = ({
  setTimesheetNrActionLoading,
  id,
  timesheetId,
  disabled,
}: Props) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={
        disabled ? (
          ''
        ) : (
          <Typography variant="caption">
            {intl.formatMessage({ id: 'TABLE.BODY.LEADER_REMINDER' })}
          </Typography>
        )
      }
      placement="top"
    >
      <span>
        <TableButtonIcon
          timesheetID={timesheetId}
          setTimesheetNrActionLoading={setTimesheetNrActionLoading}
          id={id}
          disabled={disabled}
        >
          <ReminderIcon />
        </TableButtonIcon>
      </span>
    </Tooltip>
  );
};

export default LeaderReminder;
