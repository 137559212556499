import { useIntl } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { MenuBarProps } from './types';

const MenuBar = ({
  value,
  setValue,
  options,
  confirmCondition,
}: MenuBarProps) => {
  const intl = useIntl();

  return (
    <AppBar style={{ position: 'sticky', top: '45px' }} color="default">
      <Tabs
        onChange={(_evt, val) => {
          confirmCondition
            ? // eslint-disable-next-line no-restricted-globals
              confirm(intl.formatMessage({ id: 'PROMPT.LEAVE' })) &&
              setValue(val)
            : setValue(val);
        }}
        aria-label="scrollable auto tabs example"
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={value}
        centered
      >
        {options.map((option) => (
          <Tab
            id={option.label}
            label={intl.formatMessage({ id: option.label })}
            key={option.key}
            disabled={option.disabled}
            value={option.key}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default MenuBar;
