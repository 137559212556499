import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { GlobalContext } from 'globalContext/GlobalContext';
import { permissionsUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import ControlledCheckbox from 'components/ControlledCheckbox';
import TimesheetFilter from 'components/TimesheetFilter/TimesheetChipsFilter';
import { fetchEmployeeList } from 'utils/commonFetches';
import { getFetch, putFetch } from 'utils/fetchFunctions';
import { FilterOption } from 'utils/helpers/renameKeys';
import { Permission, PermissionForm } from './types';
import {
  formatAllowedPermissions,
  getCheckedFields,
  getFormattedPostData,
} from './utils';
import { PaperWrapper } from './PermissionManager.css';

const PermissionsManager = () => {
  const intl = useIntl();
  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [allowedPermissions, setAllowedPermissions] = useState<Permission[]>(
    [],
  );
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);

  const { control, setValue, getValues } = useForm<PermissionForm>({
    defaultValues: { employee: undefined, permissions: allowedPermissions },
  });
  const { fields } = useFieldArray({ control, name: 'permissions' });

  const { employee } = useWatch({ control });

  const fetchEmployeePermissions = useCallback(
    async (shouldReload = true) => {
      if (employee?.value) {
        if (shouldReload) {
          setLoading(true);
        }
        const response = await getFetch({
          url: `${permissionsUrl}/${employee?.value}`,
        });

        if (response) {
          const userPermissions = response?.permissions as Permission[];
          const checkedFields = getCheckedFields(
            allowedPermissions,
            userPermissions,
          );
          setValue('permissions', checkedFields);
        }
        setLoading(false);
      } else {
        setValue('permissions', allowedPermissions);
      }
    },
    [employee?.value, allowedPermissions, setValue],
  );

  const fetchAllowedPermissions = useCallback(async () => {
    const response: Permission[] = await getFetch({
      url: permissionsUrl,
      setLoading,
    });
    if (response?.length) {
      const permissions = formatAllowedPermissions(response);

      setAllowedPermissions(permissions);
      setValue('permissions', permissions);
    }
  }, [setValue]);

  const handleSubmit = useCallback(async () => {
    const formValues = getValues();
    const body = getFormattedPostData(formValues);

    const { ok } = await putFetch({
      url: `${permissionsUrl}/${employee?.value}`,
      label: 'PERMISSION_MANAGER',
      body,
      intl,
    });

    if (ok) {
      fetchEmployeePermissions(false);
    }
  }, [getValues, employee?.value, intl, fetchEmployeePermissions]);

  useEffect(() => {
    fetchEmployeeList(setEmployeesData);
    fetchAllowedPermissions();
  }, [fetchAllowedPermissions]);

  useEffect(() => {
    fetchEmployeePermissions();
  }, [fetchEmployeePermissions]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Grid container justify="center">
        <Grid item sm={7} md={3} xs={7}>
          <Controller
            name="employee"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <TimesheetFilter
                  onChange={onChange}
                  value={value}
                  label={intl.formatMessage({ id: 'TABLE_FILTER.LABEL.NAME' })}
                  type={name}
                  options={employeesData ?? []}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item sm={7} md={3} xs={7}>
          <PaperWrapper>
            {employee?.value && (
              <Typography
                align="center"
                style={{ margin: '1rem 0' }}
                variant="h6"
              >
                {intl.formatMessage({ id: 'ADMIN_PANEL.PERMISSION_MANAGER' })} -{' '}
                {employee?.name}
              </Typography>
            )}
            <Grid item xs={12}>
              {loading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                fields?.map((permission, index) => (
                  <Box
                    display="flex"
                    justifyContent="center"
                    key={permission?.id}
                  >
                    <ControlledCheckbox
                      name={
                        `permissions[${index}].${permission?.enumName}` as keyof PermissionForm
                      }
                      control={control}
                      disabled={!employee?.value}
                      label={
                        currentLocale !== 'en'
                          ? permission?.fieldNameTranslated
                          : permission?.fieldName
                      }
                      isProvidedLabelTranslated
                      placement="end"
                      isFullWidth={true}
                      defaultChecked={permission?.active}
                    />
                  </Box>
                ))
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <ContainedButton
                  onClick={handleSubmit}
                  disabled={!employee?.value}
                >
                  {intl.formatMessage({ id: 'MODAL.BUTTON.SUBMIT' })}
                </ContainedButton>
              </Box>
            </Grid>
          </PaperWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PermissionsManager;
