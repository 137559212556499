import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  projectPermissionsUrl,
  projectsPagedUrl,
  projectUpdateUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledCheckbox from 'components/ControlledCheckbox/ControlledCheckbox';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ProjectsTable from 'components/Table/ProjectsTable';
import { ButtonWrapper } from 'components/Table/Table.css';
import { Project } from 'components/Table/types';
import { fetchClientList, fetchProjectList } from 'utils/commonFetches';
import { deleteFetch, getFetch, patchFetch } from 'utils/fetchFunctions';
import {
  filterDropdownProjectData,
  getRenamedProjectsData,
  setClientsValue,
} from 'utils/helpers/filterDropdownData';
import { ClientsData, ProjectsData } from 'utils/helpers/renameKeys';
import { usePagination } from 'utils/hooks/usePagination';
import { useToggleState } from 'utils/hooks/useToggleState';
import { PROJECTS_HEAD_DATA } from './static_data';
import { FormData, ProjectData } from './types';
import { BoxItem, BoxWrapper, FieldWrapper } from './Projects.css';

const Projects = () => {
  const { control, setValue } = useForm<FormData>({
    defaultValues: {
      project: undefined,
      client: undefined,
      inactiveProjects: false,
    },
  });
  const [addChecked, handleAddChanged] = useToggleState(false);
  const formData: FormData = useWatch({ control }) as FormData;
  const { client, project, inactiveProjects } = formData;
  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
  } = usePagination<FormData>({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 300,
    data: formData,
  });
  const intl = useIntl();
  const [tableData, setTableData] = useState<Project[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const [filteredProjectsData, setFilteredProjectsData] = useState<
    ProjectsData[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [isAllowedForActions, setIsAllowedForActions] = useState(false);

  const projectOptions = useMemo(
    () => getRenamedProjectsData(filteredProjectsData),
    [filteredProjectsData],
  );

  const returnQueryString = useCallback(
    (pageNumber: number, pageSize: number) =>
      `${projectsPagedUrl}?${new URLSearchParams({
        page: pageNumber.toString(),
        size: pageSize.toString(),
        ...(client?.name && { clientsNames: client.name }),
        ...(project?.projectName && {
          projectName: project.projectName,
        }),
        ...(inactiveProjects && { showInactive: 'true' }),
      })}`,
    [client, inactiveProjects, project],
  );

  const fetchProjects = useCallback(async () => {
    setLoading(true);
    const projectsResponse = await getFetch({
      url: returnQueryString(debouncedPage, pagination.pageSize),
    });
    if (projectsResponse?.content) {
      setElementsCount(projectsResponse.totalElements);

      const projectRenamedData: Project[] = projectsResponse.content.map(
        ({
          id,
          projectName: project,
          client: { clientName: client, clientCustomFields },
          description,
          contactPerson,
          accountingNotes,
          addingUser,
          billable,
          projectCustomFields,
          hasTimeReported,
          status,
          projectCategory,
          multiplicator,
          reportingCustomData,
          reportingCustomDataType,
        }: ProjectData) => ({
          id,
          project,
          client,
          description,
          contactPerson,
          accountingNotes,
          addingUser,
          billable: !!billable,
          projectCustomFields,
          clientCustomFields,
          isDeletable: !hasTimeReported,
          status,
          projectCategory,
          multiplicator,
          reportingCustomData,
          reportingCustomDataType,
        }),
      );
      const isProjectDoubled =
        projectRenamedData.filter(
          (data) => data.project === project?.projectName,
        ).length > 1;

      if (!(project?.name && isProjectDoubled)) {
        setTableData(projectRenamedData);
      }
    }
    setLoading(false);
  }, [
    debouncedPage,
    pagination.pageSize,
    project,
    returnQueryString,
    setElementsCount,
  ]);

  const deleteCustomField = useCallback(
    async (id: number, url: string) => {
      setLoading(true);
      await deleteFetch({
        url: `${url}/${id}`,
        intl,
        label: 'CUSTOM_FIELDS_DELETE',
      });
      await fetchProjects();
      setLoading(false);
    },
    [fetchProjects, intl],
  );

  const fetchPermissions = useCallback(async () => {
    const hasPermissions = await getFetch({
      url: projectPermissionsUrl,
    });
    setIsAllowedForActions(hasPermissions);
  }, []);

  const changeProjectStatus = useCallback(
    async (id: number) => {
      setLoading(true);
      await patchFetch({
        url: `${projectUpdateUrl}/${id}`,
        intl,
        label: 'PROJECTS.STATUS_CHANGED',
      });
      await fetchProjects();
      setLoading(false);
    },
    [fetchProjects, intl],
  );

  const setFilterDropdownData = useCallback(() => {
    setFilteredProjectsData(
      filterDropdownProjectData({
        projectsData,
        clientNameFromClient: client?.name,
      }),
    );

    setClientsValue({
      setValue,
      projectNameFromProject: project?.projectName,
      clientNameFromClient: client?.name,
      clientNameFromProject: project?.client?.clientName,
      clientIdFromProject: project?.client?.id,
    });
  }, [client, project, projectsData, setValue]);

  useEffect(() => {
    fetchClientList(setClientsData);
  }, []);

  useEffect(() => {
    fetchProjectList(setProjectsData, inactiveProjects);
  }, [inactiveProjects]);

  useEffect(() => {
    setFilterDropdownData();
  }, [setFilterDropdownData]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <BoxWrapper overflow="auto" marginTop="1.5rem" minHeight={'100%'}>
      <BoxItem>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} justify="space-between">
              <Grid xs={12} sm={6} item>
                <ControllerSelect
                  label="TABLE.HEAD.CLIENT"
                  name="client"
                  control={control}
                  options={clientsData}
                  isDisabled={loading}
                  handleChange={() => setValue('project', undefined)}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <ControllerSelect
                  label="TABLE.HEAD.PROJECT"
                  name="project"
                  control={control}
                  options={projectOptions}
                  isDisabled={loading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldWrapper>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6}>
                  <ControlledCheckbox
                    label="TABLE_FILTER.INACTIVE_PROJECTS"
                    name="inactiveProjects"
                    control={control}
                    placement="end"
                    disabled={loading}
                  />
                </Grid>
                {isAllowedForActions && (
                  <Grid item xs={12} sm={6}>
                    <ButtonWrapper>
                      <ContainedButton
                        id="addNewProjectButton"
                        endIcon={<AddIcon />}
                        size="large"
                        onClick={handleAddChanged}
                        disabled={loading}
                      >
                        {intl.formatMessage({ id: 'PROJECT_DIALOG.ADD_NEW' })}
                      </ContainedButton>
                    </ButtonWrapper>
                  </Grid>
                )}
              </Grid>
            </FieldWrapper>
          </Grid>
        </Grid>
      </BoxItem>
      <ProjectsTable
        fetchProjects={fetchProjects}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        addChecked={addChecked}
        handleAddChanged={handleAddChanged}
        pageCount={pagination.totalElements}
        currentPage={pagination.pageNumber}
        withPagination
        limit={pagination.pageSize}
        loading={loading}
        head={PROJECTS_HEAD_DATA}
        data={tableData}
        formData={formData}
        deleteCustomField={deleteCustomField}
        changeProjectStatus={changeProjectStatus}
        isAllowedForActions={isAllowedForActions}
      />
    </BoxWrapper>
  );
};

export default Projects;
