import { FormControlLabel, FormControlLabelProps } from '@material-ui/core';
import styled from 'styled-components';

export interface StyledFormControlLabelProps extends FormControlLabelProps {
  $row?: boolean;
}

export const StyledFormControlLabel = styled(
  FormControlLabel,
)<StyledFormControlLabelProps>`
  &.MuiFormControlLabel-root {
    margin: ${({ $row }) => ($row ? '0 0.5rem' : 0)};
  }
`;
