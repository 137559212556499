import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';
import { Tooltip } from 'components/Table/Table.css';

interface ContainerProps extends GridProps {
  isLast?: boolean;
}

export const GridWithMargin = styled(Grid)`
  &.MuiGrid-container {
    margin: 2rem 0;
    min-height: 22rem;
    align-content: flex-start;
  }
`;

export const BenefitTypeContainer = styled(Grid)<ContainerProps>`
  &.MuiGrid-item {
    margin-bottom: ${({ isLast }) => (isLast ? '1.5rem' : '0.5rem')};
  }
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute !important;
  top: 4%;
  right: 4%;
`;

export const GridAlignLeft = styled(Grid)`
  text-align: left;
`;

export const GridAlignRight = styled(Grid)`
  text-align: right;
`;
