import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ToolTipCell from 'components/ToolTipCell';

interface Props {
  label: string;
  tooltip: string;
  id: string;
}

const FormTooltip = ({ label, tooltip, id }: Props) => {
  const intl = useIntl();

  return (
    <Grid item xs={12} style={{ padding: '0.5rem 1rem' }}>
      <ToolTipCell
        id={id}
        tooltip={intl.formatMessage({
          id: `BENEFITS.FORM.${tooltip}`,
        })}
        bigger
        text={intl.formatMessage({
          id: `BENEFITS.FORM.${label}`,
        })}
        styles={{
          cellTooltipStyles: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
          },
          wrapperStyles: {
            marginRight: 0,
            marginLeft: '10px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          },
        }}
      />
    </Grid>
  );
};

export default FormTooltip;
