import { Permission, PermissionForm } from './types';

export const getCheckedFields = (
  allowedPermissions: Permission[],
  employeePermissions: Permission[],
) =>
  allowedPermissions?.map((permission: Permission) => {
    return employeePermissions.find((employeePermissions: Permission) => {
      return permission?.enumName === employeePermissions?.enumName;
    })
      ? { ...permission, active: true }
      : permission;
  });

export const getFormattedPostData = (formValues: PermissionForm) =>
  formValues?.permissions
    ?.map((permission) => {
      const permissionName = Object.keys(permission)[0];
      return permission[permissionName] ? permissionName : undefined;
    })
    .filter((permissionName) => permissionName);

export const formatAllowedPermissions = (response: Permission[]) =>
  response.map((permission) => ({
    enumName: permission?.enumName,
    fieldName: permission?.fieldName,
    fieldNameTranslated: permission?.fieldNameTranslated,
    oridinalNum: permission?.ordinalNum,
    active: false,
  }));
