import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import palette from 'assets/palette';
import styled from 'styled-components';
import ContainedButton from 'components/Button/ContainedButton';

export const GridWrapper = styled(Grid)`
  &.MuiGrid-container {
    margin-bottom: 20px;
  }
  display: flex;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)';
`;

export const UserTitleWrapper = styled.p`
  color: ${palette.ACCENT_WHITE};
  margin-top: 22px;
  padding-left: 20px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

export const UpdateIcon = styled(EditIcon)`
  &.MuiSvgIcon-root {
    width: 25px;
    height: 25px;
    color: ${palette.ACCENT_WHITE};
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARKEST};
    }
  }
`;

export const DataWrapper = styled(Grid)`
  padding: 30px;
  && {
    margin-top: 10px;
  }
`;

interface HasValueProps {
  $hasValue?: boolean;
}

export const StyledFieldName = styled.h3<HasValueProps>`
  font-size: 16px;
  font-weight: 700;
  margin-left: 25%;
  color: ${({ $hasValue }) => ($hasValue ? 'black' : 'red')};
`;

export const StyledFieldValue = styled.p`
  margin-left: 20%;
  font-size: 16px;
`;

export const StyledContainedButton = styled(ContainedButton)`
  width: 100%;
  && {
    margin-top: 20px;
  }
`;
