import { IntlShape } from 'react-intl';
import moment from 'moment';
import { MultiProps } from 'components/ControlledSelect/types';

export const getHelperText = (intl: IntlShape) => {
  return {
    required: intl.formatMessage({
      id: 'VALIDATION.REQUIRED',
    }),
    onlyText: intl.formatMessage({
      id: 'VALIDATION.ONLY_TEXT',
    }),
    onlyNumber: intl.formatMessage({
      id: 'VALIDATION.ONLY_NUMBER',
    }),
    email: intl.formatMessage({
      id: 'VALIDATION.EMAIL',
    }),
    peselLength: intl.formatMessage({
      id: 'VALIDATION.PESEL',
    }),
    invalidDate: intl.formatMessage({
      id: 'VALIDATION.DATE_INVALID',
    }),
    futureDate: intl.formatMessage({
      id: 'VALIDATION.FUTURE_DATE',
    }),
    onlyPositive: intl.formatMessage({
      id: 'VALIDATION.ONLY_POSITIVE',
    }),
    theSameName: intl.formatMessage({
      id: 'VALIDATION.THE_SAME_NAME',
    }),
  };
};

const getNumberPattern = (intl: IntlShape) => ({
  value: /^-?\d+\.?\d*$/,
  message: getHelperText(intl).onlyNumber,
});

const getPositiveNumberValidation = (intl: IntlShape) => ({
  isPositive: (value: string) =>
    Number(value) >= 0 || getHelperText(intl).onlyPositive,
});

const getPositiveMonthIntegerValidation = (intl: IntlShape) => ({
  isPositive: (value: string) =>
    (Number.isInteger(Number(value)) &&
      Number(value) >= 1 &&
      Number(value) <= 28) ||
    getHelperText(intl).onlyPositive + ' <28',
});

const getPositiveIntegerValidation = (intl: IntlShape) => ({
  isPositive: (value: string) =>
    (Number.isInteger(Number(value)) && Number(value) > 0) ||
    value === '' ||
    getHelperText(intl).onlyPositive,
});

const getEmailPattern = (intl: IntlShape) => ({
  value: /^[^\s@]+@[^\s@]+[.]\S+$/,
  message: getHelperText(intl).email,
});

const getDateValidation = (intl: IntlShape) => ({
  isValid: (value: Date | null) =>
    value === null ||
    moment(value).isValid() ||
    getHelperText(intl).invalidDate,
});

export const validate = (
  intl: IntlShape,
  types?: { forbiddenStrings?: string[] },
) => {
  return {
    required: {
      required: getHelperText(intl).required,
    },

    text: {
      required: getHelperText(intl).required,
      pattern: {
        value: /^[^0-9]+$/,
        message: getHelperText(intl).onlyText,
      },
    },

    theSameName: {
      required: getHelperText(intl).required,
      validate: (value: string) =>
        !types?.forbiddenStrings?.some(
          (string) => string.toLowerCase() === value.toLowerCase(),
        ) || getHelperText(intl).theSameName,
    },

    number: {
      required: getHelperText(intl).required,
      pattern: getNumberPattern(intl),
    },

    unrequiredNumber: {
      pattern: getNumberPattern(intl),
    },

    positiveNumber: {
      required: getHelperText(intl).required,
      pattern: getNumberPattern(intl),
      validate: getPositiveNumberValidation(intl),
    },
    positiveMonthIntegers: {
      required: getHelperText(intl).required,
      validate: getPositiveMonthIntegerValidation(intl),
    },

    unrequiredPositiveNumber: {
      pattern: getNumberPattern(intl),
      validate: getPositiveNumberValidation(intl),
    },

    positiveIntegers: {
      required: getHelperText(intl).required,
      validate: getPositiveIntegerValidation(intl),
    },

    unrequiredPositiveIntegers: {
      validate: getPositiveIntegerValidation(intl),
    },

    email: {
      required: getHelperText(intl).required,
      pattern: getEmailPattern(intl),
    },

    unrequiredEmail: {
      pattern: getEmailPattern(intl),
    },

    pesel: {
      required: getHelperText(intl).required,
      pattern: getNumberPattern(intl),
      minLength: {
        value: 11,
        message: getHelperText(intl).peselLength,
      },
      maxLength: {
        value: 11,
        message: getHelperText(intl).peselLength,
      },
    },
    date: {
      required: getHelperText(intl).required,
      validate: getDateValidation(intl),
    },
    unrequiredDate: {
      validate: getDateValidation(intl),
    },
    multiSelect: {
      validate: {
        isEmpty: (value: MultiProps[]) => !!value?.length,
      },
    },
  };
};
