import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid } from '@material-ui/core';
import { UserContext } from 'globalContext/UserContext';
import moment from 'moment';
import { businessTripsUrl, userProfileUrl } from 'router/url';
import { DownloadFileIcon } from 'components/Dialog/Dialog.css';
import TripBillingDialog from 'components/Dialog/TripBillingDialog';
import {
  DescriptionText,
  DetailsTitle,
  EditIcon,
  TableIconButton,
} from 'components/Table/Table.css';
import { FetchedFile } from 'components/Table/types';
import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { getFetch } from 'utils/fetchFunctions';
import { getNameAndSurname } from 'utils/formatters';
import { groupFilesByUploader } from 'utils/groupFilesByUploader';
import { returnRoleInclusion } from 'utils/helpers';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import {
  AdditionalAccount,
  BusinessTripDetailsProps,
  TransportationElement,
  TripStatuses,
} from '../types';
import { TripCalculatorProvider } from './BusinessTripCalculator/TripCalculatorContext';
import BusinessTripCalculator from './BusinessTripCalculator';
import BusinessTripDetailsActions from './BusinessTripDetailsActions';
import {
  BoxDetails,
  Detail,
  DetailContent,
  DetailLabel,
  FileChip,
  GridWithLift,
  TripLink,
} from './BusinessTripDetails.css';

const BusinessTripDetails = ({
  data,
  tripsChanged,
  fetchTrips,
  downloadFile,
  displayReminder,
  setDisplayReminder,
  fetchTripFiles,
  uploadedTripFiles,
}: BusinessTripDetailsProps) => {
  const intl = useIntl();
  const [showCalculator, handleToggleCalculator] = useToggleState(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [personalId, setPersonalId] = useState();
  const [tripBillingOpen, toggleTripBillingOpen] = useToggleState(false);
  const [tripBillingEditOpen, toggleTripBillingEditOpen] =
    useToggleState(false);
  const { roles, username } = useContext(UserContext);
  const [isLeaderOfBusinessTrip, setIsLeaderOfBusinessTrip] = useState(false);
  const nameAndSurname = getNameAndSurname(username);

  const isAdministration = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAdministration,
      ]),
    [roles],
  );

  const isAccounting = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
      ]),
    [roles],
  );

  const forAdministration =
    isAdministration && data.status === TripStatuses.RESERVATION_TO_BE_DONE;

  const forAccountancy =
    isAccounting && data.status === TripStatuses.DOCS_TO_BE_VERIFIED;

  const visibleForUser =
    data.account.username === username &&
    (data.status === TripStatuses.FINISHED ||
      data.status === TripStatuses.DOCS_TO_UPDATE);

  const isSummaryVisible =
    (isAccounting || data.account.username === username) &&
    data.summarised &&
    data.status !== TripStatuses.CANCELED;

  const isApprover = data?.businessTripApproverUid === username;

  const getDetailContent = (
    value: any,
    isTransportation?: boolean,
    isCountry?: boolean,
  ) => {
    if (isTransportation) {
      if (value) {
        return value.map((el: TransportationElement) =>
          intl.formatMessage({
            id: `BUSINESS_TRIP.${el.expenseType}`,
          }),
        );
      }
      return intl.formatMessage({
        id: 'SUMMARY.NONE',
      });
    }
    if (isCountry) {
      if (value) {
        const translation = intl.formatMessage({
          id: `COUNTRY_DIET.${getFormattedName(value)}`,
        });
        return translation.includes('COUNTRY_DIET') ? value : translation;
      }
    }
    return (
      value ||
      intl.formatMessage({
        id: 'SUMMARY.NONE',
      })
    );
  };

  const renderDetailsCell = (
    labelId: string,
    value: any,
    isTransportation?: boolean,
    isCountry?: boolean,
  ) => {
    return (
      <Detail>
        <DetailLabel>
          {intl.formatMessage({
            id: labelId,
          }) + ': '}
        </DetailLabel>
        <DetailContent>
          {getDetailContent(value, isTransportation, isCountry)}
        </DetailContent>
      </Detail>
    );
  };

  const isPrivileged =
    roles.includes(RoleTypes.RoleAccounting) ||
    roles.includes(RoleTypes.RoleDEVHR);

  const canViewFilesFromAdministration =
    roles.includes(RoleTypes.RoleAdministration) ||
    roles.includes(RoleTypes.RoleDEVHR) ||
    roles.includes(RoleTypes.RoleAccounting);

  const fetchPesel = useCallback(async () => {
    const personalData = await getFetch({
      url: `${userProfileUrl}/${data?.account?.username}/info`,
    });

    setPersonalId(personalData?.personalId);
  }, [data?.account?.username]);

  const getCancellingReason = useCallback(async () => {
    const cancellingReason = await getFetch({
      url: `${businessTripsUrl}/${data.id}/cancel-reason`,
      contentType: 'text',
    });
    if (cancellingReason) {
      setCancelReason(cancellingReason);
    }
  }, [data.id]);

  useEffect(() => {
    if (data.status === TripStatuses.CANCELED) {
      getCancellingReason();
    }
  }, [data.status, getCancellingReason]);

  useEffect(() => {
    isPrivileged && fetchPesel();
  }, [fetchPesel, isPrivileged]);

  useEffect(() => {
    const result = data.businessTripLeadersUid?.find(
      (leader) => leader === username,
    );
    setIsLeaderOfBusinessTrip(Boolean(result));
  }, [data.businessTripLeadersUid, username]);

  useEffect(() => {
    fetchTripFiles(data?.id);
  }, [fetchTripFiles, data?.id]);

  const renderAdditionalAccounts = () => {
    if (!data?.additionalAccounts?.length) {
      return;
    }
    return data.additionalAccounts.map((account: AdditionalAccount) => (
      <Detail key={account?.id}>
        <DetailLabel>
          {intl.formatMessage({
            id: 'TABLE.HEAD.NAME',
          }) + ': '}
        </DetailLabel>
        <DetailContent>{`${account?.name} ${account?.surname}`}</DetailContent>
      </Detail>
    ));
  };

  const renderUploadedFile = (uploadedFile: FetchedFile) => {
    return (
      <FileChip
        label={uploadedFile.originalFileName}
        variant="outlined"
        onClick={() =>
          downloadFile(uploadedFile.id, uploadedFile.originalFileName, data.id)
        }
        avatar={<DownloadFileIcon />}
      />
    );
  };

  const renderFilesSection = (files: FetchedFile[]) => {
    const groups = groupFilesByUploader(files, nameAndSurname);

    return (
      <DescriptionText>
        {groups.uploadedByMe?.length > 0 &&
          renderDetailsCell(
            'DROPZONE.PREVIOUSLY_UPLOADED_BY_ME',
            groups.uploadedByMe.map(renderUploadedFile),
          )}
        {groups.uploadedByEmployee?.length > 0 &&
          renderDetailsCell(
            'DROPZONE.PREVIOUSLY_UPLOADED_BY_EMPLOYEE',
            groups.uploadedByEmployee.map(renderUploadedFile),
          )}
        {canViewFilesFromAdministration &&
          groups.uploadedByAdministration?.length > 0 &&
          renderDetailsCell(
            'DROPZONE.PREVIOUSLY_UPLOADED_BY_ADMINISTRATION',
            groups.uploadedByAdministration.map(renderUploadedFile),
          )}
        {!canViewFilesFromAdministration &&
          groups.uploadedByAdministrationForEmployee?.length > 0 &&
          renderDetailsCell(
            'DROPZONE.PREVIOUSLY_UPLOADED_BY_ADMINISTRATION',
            groups.uploadedByAdministrationForEmployee.map(renderUploadedFile),
          )}
      </DescriptionText>
    );
  };

  const renderCellWithEditButton = (labelId: string, onClick: () => void) => {
    return (
      <Grid xs={12} container>
        <Grid item>
          {intl.formatMessage({
            id: labelId,
          })}
        </Grid>
        {isLeaderOfBusinessTrip && (
          <GridWithLift item>
            <TableIconButton onClick={onClick}>
              <EditIcon />
            </TableIconButton>
          </GridWithLift>
        )}
      </Grid>
    );
  };

  return (
    <Grid xs={12} container direction="column">
      <BoxDetails>
        <DetailsTitle>
          {intl.formatMessage({
            id: 'ACCORDION.DESCRIPTION',
          })}
        </DetailsTitle>
        <DescriptionText $fullWidth>
          {renderDetailsCell(
            'BUSINESS_TRIP.CONTRACT_TYPE',
            intl.formatMessage({
              id: `BUSINESS_TRIP.${data.contractType}`,
            }),
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.COUNTRY',
            data.country,
            false,
            true,
          )}
          {renderDetailsCell('BUSINESS_TRIP.CITY', data.city)}
          {isPrivileged &&
            renderDetailsCell('BUSINESS_TRIP.USER_IDENTITY_NUMBER', personalId)}
          {data?.billable &&
            renderDetailsCell(
              'BUSINESS_TRIP.SUMMARY_BILLABLE',
              renderCellWithEditButton(
                `BUSINESS_TRIP.BILLABILITY.${data?.billable}`,
                toggleTripBillingEditOpen,
              ),
            )}
          {data?.billableDetails &&
            renderDetailsCell(
              'BUSINESS_TRIP.SUMMARY_BILLABLE_DESCRIPTION',
              data?.billableDetails,
            )}
        </DescriptionText>
      </BoxDetails>
      <BoxDetails>
        <DetailsTitle>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.DIALOG_SECTION.TRAVEL',
          })}
        </DetailsTitle>
        <DescriptionText>
          {renderDetailsCell(
            'BUSINESS_TRIP.TRIP_START',
            moment(data?.startTime).format('YYYY-MM-DD HH:mm'),
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.TRIP_END',
            moment(data?.endTime).format('YYYY-MM-DD HH:mm'),
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.TRANSPORTATION',
            data.transportation,
            true,
          )}
          {renderDetailsCell('BUSINESS_TRIP.TRAVEL_FROM', data.travelFrom)}
          {renderDetailsCell('BUSINESS_TRIP.TRAVEL_TO', data.travelTo)}
          {renderDetailsCell(
            'BUSINESS_TRIP.TRAVEL_RESERVATION',
            data.travelReservationBy
              ? intl.formatMessage({
                  id: `BUSINESS_TRIP.${data.travelReservationBy}`,
                })
              : '',
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.TRAVEL_RESERVATION_LINK',
            <TripLink href={data.travelReservationLink} target="_blank">
              {data.travelReservationLink}
            </TripLink>,
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.TRAVEL_RESERVATION_INFORMATION',
            data.reservationInformation,
          )}
        </DescriptionText>
      </BoxDetails>
      <BoxDetails>
        <DetailsTitle>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.DIALOG_SECTION.ACCOMMODATION',
          })}
        </DetailsTitle>
        <DescriptionText>
          {renderDetailsCell('BUSINESS_TRIP.PLACE_TO_STAY', data.placeToStay)}
          {renderDetailsCell('BUSINESS_TRIP.NIGHTS_COUNT', data.numberOfNights)}
          {renderDetailsCell(
            'BUSINESS_TRIP.ESTIMATED_COST',
            data.estimatedCostsOfStay
              ? `${data.estimatedCostsOfStay} ${data.currency}`
              : `0`,
          )}
          {data?.mealsProvided &&
            renderDetailsCell(
              'BUSINESS_TRIP.BREAKFASTS_COUNT',
              data.numberOfBreakfasts,
            )}
          {data?.mealsProvided &&
            renderDetailsCell(
              'BUSINESS_TRIP.DINNERS_COUNT',
              data.numberOfDinners,
            )}
          {data?.mealsProvided &&
            renderDetailsCell(
              'BUSINESS_TRIP.SUPPERS_COUNT',
              data.numberOfSuppers,
            )}
          {renderDetailsCell(
            'BUSINESS_TRIP.ACCOMMODATION_RESERVATION',
            data.accommodationReservationBy
              ? intl.formatMessage({
                  id: `BUSINESS_TRIP.${data.accommodationReservationBy}`,
                })
              : '',
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.ACCOMMODATION_RESERVATION_LINK',
            <TripLink href={data.accommodationReservationLink} target="_blank">
              {data.accommodationReservationLink}
            </TripLink>,
          )}
          {renderDetailsCell(
            'BUSINESS_TRIP.ACCOMMODATION_RESERVATION_INFORMATION',
            data.accommodationInformation,
          )}
        </DescriptionText>
      </BoxDetails>
      {data.insuranceProvidedBy !== 'NO_INSURANCE' && (
        <BoxDetails>
          <DetailsTitle>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.DIALOG_SECTION.OTHER',
            })}
          </DetailsTitle>
          <DescriptionText>
            {renderDetailsCell(
              'BUSINESS_TRIP.INSURANCE',
              intl.formatMessage({
                id: `BUSINESS_TRIP.${data.insuranceProvidedBy}`,
              }),
            )}
            {data?.advanceDescription &&
              renderDetailsCell(
                'BUSINESS_TRIP.ACCORDION.ADVANCE',
                data.advanceDescription,
              )}
            {renderDetailsCell('BUSINESS_TRIP.OTHER', data.otherInformation)}
          </DescriptionText>
        </BoxDetails>
      )}
      {data?.additionalAccounts?.length ? (
        <BoxDetails>
          <DetailsTitle>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.OTHER_EMPLOYEES',
            })}
          </DetailsTitle>
          <DescriptionText>{renderAdditionalAccounts()}</DescriptionText>
        </BoxDetails>
      ) : null}
      {!!cancelReason && (
        <BoxDetails>
          <Grid container direction="column">
            <DetailsTitle>
              {intl.formatMessage({
                id: 'TABLE.HEAD.CANCELLING_REASON',
              })}
            </DetailsTitle>
            {renderDetailsCell('BUSINESS_TRIP.CANCEL_REASON', cancelReason)}
          </Grid>
        </BoxDetails>
      )}
      {data?.rejectReason && data?.status === 'DOCS_TO_UPDATE' && (
        <BoxDetails>
          <DetailsTitle>
            {intl.formatMessage({
              id: 'TABLE.HEAD.REJECT_REASON',
            })}
          </DetailsTitle>
          <DescriptionText>
            {renderDetailsCell(
              'BUSINESS_TRIP.REJECT_REASON',
              data?.rejectReason,
            )}
          </DescriptionText>
        </BoxDetails>
      )}
      {!!uploadedTripFiles?.length && (
        <BoxDetails>
          <DetailsTitle>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.FILES',
            })}
          </DetailsTitle>
          {renderFilesSection(uploadedTripFiles)}
        </BoxDetails>
      )}
      {(isApprover || isLeaderOfBusinessTrip) && tripBillingEditOpen && (
        <TripBillingDialog
          id={data.id}
          open={tripBillingEditOpen}
          handleClose={toggleTripBillingEditOpen}
          onSubmit={fetchTrips}
          billableToEdit={data?.billable}
          billableDetailsToEdit={data?.billableDetails}
        />
      )}
      {(forAdministration ||
        visibleForUser ||
        forAccountancy ||
        (isSummaryVisible && !visibleForUser) ||
        (isLeaderOfBusinessTrip &&
          (data.status === TripStatuses.TO_BE_APPROVED_BY_LEADER ||
            data.status ===
              TripStatuses.TO_BE_FINALLY_APPROVED_BY_LEADER))) && (
        <TripCalculatorProvider>
          <Box>
            <Grid container direction="column">
              <DetailsTitle>
                {intl.formatMessage({
                  id: 'TABLE.HEAD.ACTION',
                })}
              </DetailsTitle>
              <BusinessTripDetailsActions
                id={data.id}
                name={data.name}
                status={data.status}
                attachmentAmount={data.attachmentAmount}
                tripsChanged={tripsChanged}
                handleToggleCalculator={handleToggleCalculator}
                tripSummarised={data.summarised}
                fetchTrips={fetchTrips}
                displayReminder={displayReminder}
                setDisplayReminder={setDisplayReminder}
                tripBillingOpen={tripBillingOpen}
                toggleTripBillingOpen={toggleTripBillingOpen}
                visibleForUser={visibleForUser}
                isSummaryVisible={isSummaryVisible}
                isLeaderOfBusinessTrip={isLeaderOfBusinessTrip}
                forAdministration={forAdministration}
                forAccountancy={forAccountancy}
              />
            </Grid>
          </Box>
          <BusinessTripCalculator
            data={data}
            showCalculator={showCalculator}
            handleToggleCalculator={handleToggleCalculator}
            fetchTrips={fetchTrips}
            status={data.status}
          />
        </TripCalculatorProvider>
      )}
    </Grid>
  );
};

export default BusinessTripDetails;
