import {
  AccordionDetails,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import { Backup, Close, Delete, Edit } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import palette from 'assets/palette';
import styled from 'styled-components';
import {
  InfoNotesLineProps,
  RequestStatusTypes,
  StatusStyleProps,
} from './types';

const handleColorType = (color?: RequestStatusTypes) => {
  switch (color) {
    case RequestStatusTypes.Accepted:
      return palette.STATUS_APPROVED;
    case RequestStatusTypes.Rejected:
      return palette.STATUS_REJECTED;
    case RequestStatusTypes.ToBeReviewed:
    case RequestStatusTypes.InProgress:
      return palette.ACCENT_LIGHT;
    default:
      return '#000';
  }
};

export const StatusTableCell = styled(TableCell)<StatusStyleProps>`
  && {
    color: ${({ color }) => handleColorType(color)};
  }
`;

export const BoldGrid = styled(Grid)`
  font-weight: 700;
`;

export const HeaderWrapper = styled.div`
  height: 30px;
  display: flex;
  background: ${palette.ACCENT_WHITE};
  font-weight: 700;
`;

export const FormWrapper = styled.div`
  padding: 2rem 3rem;
`;

export const FormSectionTitle = styled.p`
  margin-bottom: 5px;
`;

export const FormSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 500px;
    margin: 1rem;
  }
  & .MuiDialog-paper {
    overflow-y: hidden;
  }
`;

export const DialogTitleWrapper = styled(DialogTitle)`
  background-color: ${palette.ACTION};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const CloseIcon = styled(Close)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARKEST};
    }
  }
`;

export const EditIcon = styled(Edit)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const TrashIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    background-color: black;
    color: white;
  }
`;

export const AttachmentContainer = styled(Grid)`
  margin-top: 1rem;
`;

export const HolidayIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: #000;
  }
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

export const UploadFileIcon = styled(Backup)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    padding-left: 10px;
    font-size: 40px;
    z-index: 1;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const FileCounter = styled.span`
  background-color: ${palette.ACTION_LIGHTER};
  border-radius: 50%;
  padding: 0.3rem;
  margin-left: -0.65rem;
  margin-bottom: 0.65rem;
  display: block;
  width: 1rem;
  height: 1rem;
  font-size: 0.9rem;
`;

export const InfoNotesContainer = styled.div`
  background-color: #ffecb8;
  padding: 1rem;
  margin: 1rem -1rem 0;
`;

export const InfoNotesLine = styled.p<InfoNotesLineProps>`
  padding: 2px 5px;
  margin: 0;
  font-size: 13px;
  font-weight: ${({ isImportant }) => (isImportant ? 700 : 400)};
  text-decoration: ${({ isImportant }) => (isImportant ? 'underline' : 'none')};
`;

export const InfoLink = styled.a`
  text-decoration: none;
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin: 0 1.5rem;
  &:hover {
    border-bottom: 2px solid ${palette.ACTION_DARK};
  }
`;

export const CheckIcon = styled(DoneIcon)`
  &.MuiSvgIcon-root {
    font-size: 14px;
  }
  margin-bottom: -3px;
  margin-right: 5px;
`;

export const LeaveRequestsTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const LeaveRequestsAccordion = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;
