import { useIntl } from 'react-intl';
import { Grid, IconButton, TextField } from '@material-ui/core';
import ContainedButton from 'components/Button/ContainedButton';
import { DialogWithMessageProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapperFlex,
  DialogTitleWrapper,
  DialogWrapper,
} from '../Dialog.css';

const DialogWithMessage = ({
  open,
  title,
  handleClose,
  handleChange,
  onSubmit,
}: DialogWithMessageProps) => {
  const intl = useIntl();
  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {intl.formatMessage({ id: title })}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapperFlex>
        <Grid xs={11}>
          <TextField
            id="outlined-multiline-static"
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.REASON',
            })}
            multiline
            rows={3}
            defaultValue=""
            variant="outlined"
            fullWidth
            onChange={handleChange}
            inputProps={{
              maxLength: 1500,
            }}
          />
        </Grid>
      </DialogContentWrapperFlex>
      <DialogActionWrapper>
        <Grid xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleClose}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </Grid>
        <Grid xs={12} item>
          <ContainedButton
            id="confirmationButton"
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.CONFIRMATION' })}
          </ContainedButton>
        </Grid>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default DialogWithMessage;
