import Grid, { GridProps as DefaultProps } from '@material-ui/core/Grid';
import styled from 'styled-components';

interface GridProps extends DefaultProps {
  placement?: 'top' | 'bottom';
}

export const SwitchMultipliersLabel = styled.label`
  color: #222;
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 0.3rem;
`;

export const GridWrapperWithMargin = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    margin-${({ placement }) => placement || 'bottom'}: 2rem;
  }
`;

export const GridWrapperWithMargin2 = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    margin: 30px 0;
  }
`;
