import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import palette from 'assets/palette';
import { GlobalContext } from 'globalContext/GlobalContext';
import NavLink from 'components/Shared/NavLink';
import getLanguageOptions from 'utils/getLanguageOptions';
import { DrawerProps } from './types';
import {
  DrawerContainer,
  LanguageContainer,
  LanguageName,
  VersionContainer,
} from './Drawer.css';

const Drawer = ({
  open = true,
  variant = 'permanent',
  data,
  onClick,
  ...props
}: DrawerProps) => {
  const location = useLocation();
  const intl = useIntl();
  const {
    localization: { currentLocale },
    setCurrentLocale,
  } = useContext(GlobalContext);

  const minWidth650 = useMediaQuery(`(min-width: 650px)`);

  return (
    <DrawerContainer variant={variant} open={open} {...props}>
      {data.map(({ label, to, id }, index: number) => (
        <NavLink
          color={location.pathname === to ? palette.ACTION : ''}
          key={`${label} - ${to} - ${index}`}
          to={to}
          id={id}
          onClick={onClick}
        >
          {intl.formatMessage({ id: label })}
        </NavLink>
      ))}
      {!minWidth650 && (
        <LanguageContainer>
          {getLanguageOptions(intl).map(({ label, value }, index) => (
            <span key={label}>
              {index ? ' / ' : ''}
              <LanguageName
                $isActive={value === currentLocale}
                onClick={() => setCurrentLocale(value)}
              >
                {label}
              </LanguageName>
            </span>
          ))}
        </LanguageContainer>
      )}

      <VersionContainer>v.{process.env.REACT_APP_VERSION}</VersionContainer>
    </DrawerContainer>
  );
};

export default Drawer;
