import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { clientAccountingNoteUrl } from 'router/url';
import AccountingNoteDialog from 'components/Dialog/AccountingNoteDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import {
  AddIcon,
  BorderRow,
  EditIcon,
  HeaderTooltipText,
  LeftCellWrapper,
  RightCellWrapper,
  TableIconButton,
  Tooltip,
  TrashIcon,
} from 'components/Table/Table.css';
import { deleteFetch } from 'utils/fetchFunctions';
import { useToggleState } from 'utils/hooks/useToggleState';
import { AccordionProps } from './types';
import {
  DetailsWrapper,
  EmptyValueWrapper,
  NoteContentWrapper,
  NotesWrapper,
  StyledTableIconButton,
  TypographyDescription,
  TypographyPrimary,
  TypographySecondary,
  TypographyUser,
  UserIcon,
} from './AccountingNotes.css';

const AccountingNotesComponent = ({
  notes,
  index,
  onAddAccountingNoteClicked,
  fetchData: fetchNotes,
  data,
}: AccordionProps) => {
  const intl = useIntl();
  const [showConfirmationDialog, handleToggleConfirmationDialog] =
    useToggleState(false);
  const [accountingNoteId, setAccountingNoteId] = useState(0);
  const [editClicked, setEditClicked] = useToggleState(false);

  const handleDeleteButton = (noteId: number) => {
    setAccountingNoteId(noteId);
    handleToggleConfirmationDialog();
  };

  const handleNoteDeletion = useCallback(async () => {
    await deleteFetch({
      url: `${clientAccountingNoteUrl}/${accountingNoteId}`,
      intl,
      label: 'ACCOUNTING_NOTES_DELETION',
    });
    handleToggleConfirmationDialog();
    fetchNotes && fetchNotes();
  }, [fetchNotes, handleToggleConfirmationDialog, intl, accountingNoteId]);

  const handleNoteEdition = (noteId: number) => {
    setAccountingNoteId(noteId);
    setEditClicked();
  };

  return (
    <>
      {onAddAccountingNoteClicked && typeof index === 'number' && (
        <BorderRow>
          <LeftCellWrapper>
            {`${intl.formatMessage({
              id: 'TABLE.HEAD.ACCOUNTING_NOTES',
            })}`}
          </LeftCellWrapper>
          <RightCellWrapper>
            <TableIconButton
              id="addAccountingNotesButton"
              onClick={() => onAddAccountingNoteClicked(index)}
            >
              <Tooltip
                title={
                  <HeaderTooltipText>
                    {intl.formatMessage({
                      id: 'ACCOUNTING_NOTES.DIALOG_TITLE',
                    })}
                  </HeaderTooltipText>
                }
                placement="top"
              >
                <AddIcon />
              </Tooltip>
            </TableIconButton>
          </RightCellWrapper>
        </BorderRow>
      )}
      {notes?.length > 0 ? (
        <NotesWrapper elevation={0}>
          {notes.map((note) => (
            <Accordion key={`accordion-${note.id}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${note.id}-content`}
                id={`panel-${note.id}-header`}
              >
                <TypographyPrimary>{note.title}</TypographyPrimary>
                <TypographySecondary>
                  {new Date(note.modificationDateTime)
                    .toLocaleDateString('pl-PL')
                    .substring(0, 10)}
                </TypographySecondary>
              </AccordionSummary>
              <AccordionDetails>
                <DetailsWrapper container>
                  <TypographyUser>
                    <UserIcon />
                    {note.author}
                  </TypographyUser>
                  <NoteContentWrapper>
                    <TypographyDescription>
                      {note.noteContent}
                    </TypographyDescription>
                    <Tooltip
                      id="EditingAccountingNote"
                      title={
                        <HeaderTooltipText>
                          {intl.formatMessage({
                            id: `ACCOUNTING_NOTES.EDIT`,
                          })}
                        </HeaderTooltipText>
                      }
                      placement="top"
                    >
                      <StyledTableIconButton
                        isPaddingLeft
                        id="editButton"
                        onClick={() => {
                          handleNoteEdition(note.id);
                        }}
                      >
                        <EditIcon />
                      </StyledTableIconButton>
                    </Tooltip>
                    <Tooltip
                      id="DeleteAccountingNote"
                      title={
                        <HeaderTooltipText>
                          {intl.formatMessage({
                            id: `ACCOUNTING_NOTES.DELETE_NOTE`,
                          })}
                        </HeaderTooltipText>
                      }
                      placement="top"
                    >
                      <StyledTableIconButton
                        id="deleteButton"
                        onClick={() => {
                          handleDeleteButton(note.id);
                        }}
                      >
                        <TrashIcon />
                      </StyledTableIconButton>
                    </Tooltip>
                  </NoteContentWrapper>
                </DetailsWrapper>
              </AccordionDetails>
            </Accordion>
          ))}
          {handleToggleConfirmationDialog && (
            <ConfirmationDialog
              addChecked={showConfirmationDialog}
              handleAddChanged={handleToggleConfirmationDialog}
              handleSubmit={handleNoteDeletion}
              title={intl.formatMessage({
                id: 'ACCOUNTING_NOTES.DELETE_DIALOG_TITLE',
              })}
              content={intl.formatMessage({
                id: 'ACCOUNTING_NOTES.DELETE_DIALOG_CONTENT',
              })}
            />
          )}
          {editClicked && (
            <AccountingNoteDialog
              addChecked={editClicked}
              handleAddChanged={setEditClicked}
              note={notes.find((note) => note.id === accountingNoteId)}
              fetchData={fetchNotes}
              editClicked={editClicked}
              data={data}
            />
          )}
        </NotesWrapper>
      ) : (
        <EmptyValueWrapper>
          <TypographyPrimary>
            {intl.formatMessage({ id: 'HISTORY_DIALOG.NO_FOUND' })}
          </TypographyPrimary>
        </EmptyValueWrapper>
      )}
    </>
  );
};

export default AccountingNotesComponent;
