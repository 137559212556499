import { DialogContent, DialogContentProps } from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import palette from 'assets/palette';
import styled from 'styled-components';

export const CalculatorContainer = styled.div`
  padding: 0 3rem;
`;

export const FormSectionHeading = styled.h3`
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  font-weight: 400;
`;
export const GridOnFlexEnd = styled(Grid)<GridProps>`
  display: flex;
  justify-content: flex-end;
`;

export const DialogContentWrapperBottomMargin = styled(
  DialogContent,
)<DialogContentProps>`
  &.MuiDialogContent-root {
    padding: 0.5rem;
    margin-bottom: 15px;
  }
`;

export const GridButtonsWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    padding: 2rem 0;
  }
`;

export const GridItemRemoveAction = styled(Grid)`
  &.MuiGrid-item {
    padding: 0;
    align-self: center;
    position: relative;
    margin-right: -40px;
  }
`;

export const SummarySectionContainer = styled.div`
  margin: 2rem 0;
  padding: 1em;
  border: solid 1px ${palette.ACCENT_LIGHT};
  border-radius: 4px;
  position: relative;
`;

export const SummarySectionTitle = styled.p`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: -1.9rem;
  background: white;
  padding: 0 15px;
  color: ${palette.ACCENT_DARKEST};
`;

export const DataTypeTitle = styled(Grid)`
  font-weight: 500;
`;
