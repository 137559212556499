import { createContext, ReactNode, useReducer } from 'react';
import { benefitInitialState, reducer } from './reducer';
import { BenefitContextProps } from './types';

interface Props {
  children: ReactNode;
}

const initialContextState = {
  dispatch: () => {
    // intentional empty function
  },
  state: benefitInitialState,
};

const BenefitContext = createContext<BenefitContextProps>(initialContextState);
const BenefitContextProvider = BenefitContext.Provider;

const BenefitProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, benefitInitialState);
  return (
    <BenefitContextProvider value={{ state, dispatch }}>
      {children}
    </BenefitContextProvider>
  );
};

export { BenefitProvider, BenefitContext };
