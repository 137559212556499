import { useContext, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { GridItem, GridWrapperGapped } from 'components/Dialog/Dialog.css';
import { DeleteIcon, HiddenInput } from 'components/Table/Table.css';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import {
  CalculatorTransportationDataType,
  TransportationRowProps,
} from 'modules/BusinessTrips/types';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { ActionKind } from '../TripCalculatorContext/types';
import {
  getTransportationTypesInputName,
  renderErrorMessageForRequiredFields,
} from '../utils';
import { GridItemRemoveAction } from '../BusinessTripCalculator.css';

export const TransportationRow = ({
  transportationOptions,
  control,
  index,
  getValues,
  errors,
  clearErrors,
  remove,
  transportationFields,
  transportationField,
  register,
}: TransportationRowProps) => {
  const intl = useIntl();
  const {
    state: { currencies },
    dispatch,
  } = useContext(TripCalculatorContext);

  const transportationSelectName = getTransportationTypesInputName(
    'transportationType',
    index,
  );

  const transportationCostsName = getTransportationTypesInputName(
    'transportationCosts',
    index,
  );

  const transportationCurrencyName = getTransportationTypesInputName(
    'transportationCurrency',
    index,
  );

  const transportationIdName = getTransportationTypesInputName(
    'transportationId',
    index,
  );

  const transportationSelect = useWatch({
    control,
    name: transportationSelectName,
    defaultValue: transportationField.transportationType,
  });

  const transportationCosts = useWatch({
    control,
    name: transportationCostsName,
    defaultValue: transportationField.transportationCosts,
  });

  const transportationCurrency = useWatch({
    control,
    name: transportationCurrencyName,
    defaultValue: transportationField.transportationCurrency,
  });

  const handleChange = () => {
    const selectedTransportation = getValues(transportationSelectName);

    if (!selectedTransportation && transportationFields.length > 1) {
      remove(index);
    }
    clearErrors('transportation');
  };

  const transportationSelectionError = useMemo(
    () =>
      errors?.transportation?.filter(
        (transportationError) =>
          transportationError?.transportationType?.ref?.name ===
          transportationSelectName,
      ),
    [errors?.transportation, transportationSelectName],
  );

  const transportationCostError = useMemo(
    () =>
      errors?.transportation?.filter(
        (transportationError) =>
          transportationError?.transportationCosts?.ref?.name ===
          transportationCostsName,
      ),
    [errors?.transportation, transportationCostsName],
  );

  const transportationCurrencyError = useMemo(
    () =>
      errors?.transportation?.filter(
        (transportationError) =>
          transportationError?.transportationCurrency?.ref?.name ===
          transportationCurrencyName,
      ),
    [errors?.transportation, transportationCurrencyName],
  );

  const isTransportationRequired =
    !!transportationSelect || !!transportationCosts || !!transportationCurrency;

  useEffect(() => {
    if (!isTransportationRequired) {
      clearErrors([
        transportationSelectName,
        transportationCostsName,
        transportationCurrencyName,
      ]);
    }
  }, [
    isTransportationRequired,
    clearErrors,
    transportationSelectionError,
    transportationCurrencyError,
    transportationCostError,
    transportationSelectName,
    transportationCostsName,
    transportationCurrencyName,
  ]);

  useEffect(() => {
    dispatch({
      type: ActionKind.UpdateCalculatorFormValues,
      payload: {
        calculatorFormValues: {
          ...getValues(),
          transportation:
            transportationFields as CalculatorTransportationDataType[],
        },
      },
    });
  }, [transportationFields, getValues, dispatch]);

  return (
    <GridItem item xs={12}>
      <GridWrapperGapped container wrap="nowrap" justify="space-between">
        <HiddenInput // hidden input for keeping track of editing id
          type="hidden"
          name={transportationIdName}
          defaultValue={transportationField.transportationId}
          ref={register()}
          key={transportationField.id}
        />
        <GridItem xs={8}>
          <ControllerSelect
            label="BUSINESS_TRIP.CALCUALATOR_TRANSPORTATION_OPTIONS"
            name={transportationSelectName}
            control={control}
            options={transportationOptions}
            required={isTransportationRequired}
            handleChange={handleChange}
            defaultValue={transportationSelect}
            errorMessage={
              transportationSelectionError?.length
                ? renderErrorMessageForRequiredFields(intl)
                : undefined
            }
            key={transportationField.id}
          />
        </GridItem>
        <GridItem xs={4}>
          <GridWrapperGapped container wrap="nowrap" justify="space-between">
            <GridItem>
              <ControlledTextField
                label="BUSINESS_TRIP.CALCULATOR_TRANSPORTATION_COSTS"
                name={transportationCostsName}
                variant="outlined"
                control={control}
                required={isTransportationRequired}
                maxLength={MAX_INPUT_LENGTH}
                isOnlyDecimals
                validate={!!transportationCostError?.length}
                helperText={
                  transportationCostError?.length
                    ? renderErrorMessageForRequiredFields(intl)
                    : undefined
                }
                defaultValue={transportationCosts}
                key={transportationField.id}
              />
            </GridItem>
            <GridItem>
              <ControllerSelect
                label="BUSINESS_TRIP.CURRENCIES"
                name={transportationCurrencyName}
                control={control}
                options={currencies}
                required={isTransportationRequired}
                errorMessage={
                  transportationCurrencyError?.length
                    ? renderErrorMessageForRequiredFields(intl)
                    : undefined
                }
                defaultValue={transportationCurrency}
                key={transportationField.id}
              />
            </GridItem>
            {index !== 0 && (
              <GridItemRemoveAction onClick={() => remove(index)} item>
                <DeleteIcon />
              </GridItemRemoveAction>
            )}
          </GridWrapperGapped>
        </GridItem>
      </GridWrapperGapped>
    </GridItem>
  );
};
