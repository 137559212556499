import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { GridItem, GridWrapperGapped } from 'components/Dialog/Dialog.css';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { ClearableCalculatorSectionProps } from 'modules/BusinessTrips/types';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { renderErrorMessageForRequiredFields } from '../utils';
import { FormSectionHeading } from '../BusinessTripCalculator.css';

export const AccomodationSection = ({
  control,
  errors,
  clearErrors,
}: ClearableCalculatorSectionProps) => {
  const intl = useIntl();
  const {
    state: { calculatorFormValues, currencies },
  } = useContext(TripCalculatorContext);

  const otherExpensesCosts = useWatch({
    control,
    name: 'otherExpensesCosts',
    defaultValue: calculatorFormValues?.otherExpensesCosts,
  });

  const otherExpensesCurrency = useWatch({
    control,
    name: 'otherExpensesCurrency',
    defaultValue: calculatorFormValues?.otherExpensesCurrency,
  });

  const otherExpensesComment = useWatch({
    control,
    name: 'otherExpensesComment',
    defaultValue: calculatorFormValues?.otherExpensesComment,
  });

  const otherExpensesRequired =
    !!otherExpensesCosts || !!otherExpensesCurrency || !!otherExpensesComment;

  useEffect(() => {
    if (!otherExpensesRequired) {
      clearErrors(['otherExpensesCosts', 'otherExpensesCurrency']);
    }
  }, [
    otherExpensesRequired,
    clearErrors,
    errors?.otherExpensesCurrency,
    errors?.otherExpensesCosts,
  ]);

  return (
    <>
      <GridItem xs={12} item>
        <FormSectionHeading>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.CALCULATOR_OTHER_EXPENSES',
          })}
        </FormSectionHeading>
      </GridItem>
      <GridItem item xs={12}>
        <GridWrapperGapped container wrap="nowrap" justify="space-between">
          <GridItem xs={8}>
            <ControlledTextField
              name="otherExpensesCosts"
              variant="outlined"
              control={control}
              required={otherExpensesRequired}
              label="BUSINESS_TRIP.CALCULATOR_OTHER_EXPENSES_COSTS"
              maxLength={MAX_INPUT_LENGTH}
              isOnlyDecimals
              defaultValue={calculatorFormValues?.otherExpensesCosts}
              validate={!!errors?.otherExpensesCosts}
              helperText={
                errors?.otherExpensesCosts &&
                renderErrorMessageForRequiredFields(intl)
              }
            />
          </GridItem>
          <GridItem xs={4}>
            <ControlledSelect
              label="BUSINESS_TRIP.CURRENCIES"
              name="otherExpensesCurrency"
              control={control}
              options={currencies}
              defaultValue={calculatorFormValues?.otherExpensesCurrency}
              required={otherExpensesRequired}
              errorMessage={
                errors?.otherExpensesCurrency &&
                renderErrorMessageForRequiredFields(intl)
              }
            />
          </GridItem>
        </GridWrapperGapped>
      </GridItem>
      <GridItem item xs={12}>
        <ControlledTextField
          name="otherExpensesComment"
          variant="outlined"
          control={control}
          label="TABLE.HEAD.COMMENT"
          maxLength={MAX_INPUT_LENGTH}
          rows={3}
          isMulti
          defaultValue={calculatorFormValues?.otherExpensesComment}
        />
      </GridItem>
    </>
  );
};

export default AccomodationSection;
