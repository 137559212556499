import { createContext, ReactNode, useMemo, useReducer } from 'react';
import { userReducer } from './reducer';
import { UserContextInterface } from './types';

export const initialContextState = {
  username: '',
  teamLeader: false,
  dispatch: () => {
    // intentional empty function
  },
  roles: [],
};

const UserContext = createContext<UserContextInterface>(initialContextState);
const UserContextProvider = UserContext.Provider;

interface Props {
  children: ReactNode;
}

const initialUserInfoState = {
  username: '',
  teamLeader: false,
  roles: [],
};

function UserProvider({ children }: Props) {
  const [userInfo, dispatch] = useReducer(userReducer, initialUserInfoState);

  const value = useMemo(
    () => ({
      dispatch,
      ...userInfo,
    }),
    [userInfo, dispatch],
  );
  return <UserContextProvider value={value}>{children}</UserContextProvider>;
}

UserProvider.displayName = 'UserProvider';

export { UserProvider, UserContext };
