import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { workTimeAssignProjectUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import { fetchAllProjects } from 'utils/fetchAllProjects';
import { postFetch } from 'utils/fetchFunctions';
import { Data } from '../types';
import { AssignedProjectProps, AssignProjectDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const AssignProjectDialog = ({
  addChecked,
  handleAddChanged,
  editClicked,
  fetchReport,
  fetchCustomReport,
  fetchClientsAndProjects,
}: AssignProjectDialogProps) => {
  const { control, handleSubmit, formState, reset } =
    useForm<AssignedProjectProps>({
      defaultValues: {
        project: undefined,
      },
      mode: 'onChange',
    });
  const [projectData, setProjectData] = useState<Data[]>([]);
  const intl = useIntl();

  const handleClose = useCallback(() => {
    handleAddChanged();
    fetchClientsAndProjects();
    reset({
      project: undefined,
    });
  }, [handleAddChanged, reset, fetchClientsAndProjects]);

  useEffect(() => {
    fetchAllProjects(setProjectData);
  }, []);

  const assignProject = useCallback(
    async (project) => {
      return postFetch({
        url: `${workTimeAssignProjectUrl}?id=${project}`,
        intl,
        label: 'PROJECTS.ADD',
      });
    },
    [intl],
  );

  const onSubmit = handleSubmit(async (formData) => {
    const { project } = formData;

    if (project?.value) {
      try {
        await assignProject(project.value);
      } catch (e) {
        return;
      }
    }

    fetchReport();
    if (fetchCustomReport) {
      fetchCustomReport();
    }
    handleClose();
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'sm'}
      open={addChecked}
      onClose={handleClose}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: 'WORK_TIME_TAB.ASSIGN_BUTTON',
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <GridWrapper
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <GridItem xs={12} item>
            <ControllerSelect
              label="TABLE.HEAD.PROJECT"
              name="project"
              control={control}
              options={projectData}
              required
              helperText={editClicked}
            />
          </GridItem>
        </GridWrapper>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="addProjectButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `PROJECTS.SUBMIT_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default AssignProjectDialog;
