import { KeyboardTimePicker } from '@material-ui/pickers';
import palette from 'assets/palette';
import styled from 'styled-components';

export const TimePickerComponent = styled(KeyboardTimePicker)`
  input {
    text-align: center;
  }
  button {
    display: none;
  }

  &.MuiTextField-root {
    padding-right: 0.5rem;
  }
  && .MuiInput-root:hover::before {
    border-color: ${palette.ACTION};
  }

  & .MuiOutlinedInput-root:hover fieldset {
    border: 2px solid ${palette.ACTION};
  }
  & .MuiInputBase-root.Mui-disabled:hover fieldset {
    border: 1px solid #77787a;
  }
`;
