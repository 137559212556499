import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { allEmployeesHolidayRequestsUrl } from 'router/url';
import { TableIconButton, TablePagination } from 'components/Table/Table.css';
import { changeDateFormatWithYear, showVacationDates } from 'utils/dateFormats';
import { getFetch } from 'utils/fetchFunctions';
import { useToggleState } from 'utils/hooks/useToggleState';
import { HOLIDAYS_TABLE_HEAD as headTitles } from '../const';
import { HolidayRequestProps } from '../types';
import DetailsDialog from './DetailsDialog';
import {
  DetailsIcon,
  StatusTableCell,
  StyledTableCell,
  TabelWrapper,
} from '../EmployeesHolidaysComponent.css';

const rowsPerPage = 10;

const EmployeesHolidaysTable = () => {
  const [areDetailsOpen, setAreDetailsOpen] = useToggleState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [data, setData] = useState<HolidayRequestProps[]>();
  const intl = useIntl();
  const [page, setPage] = useState(0);
  const [count, setElementsCount] = useState(0);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleOpen = (index: number) => {
    setSelected(index);
    setAreDetailsOpen();
  };

  const handleClose = () => {
    setSelected(null);
    setAreDetailsOpen();
  };

  const fetchHolidayRequests = useCallback(async () => {
    const allEmployeesHolidayRequests = await getFetch({
      url: `${allEmployeesHolidayRequestsUrl}`,
    });
    setData(allEmployeesHolidayRequests.content);
    setElementsCount(allEmployeesHolidayRequests.content.length);
  }, []);

  useEffect(() => {
    fetchHolidayRequests();
  }, [fetchHolidayRequests]);

  if (!data) {
    return <></>;
  }
  return (
    <TabelWrapper>
      <Table>
        <TableHead>
          <TableRow>
            {headTitles.map((title, index) => (
              <StyledTableCell
                key={`${title}-${index}`}
                align={index === 0 ? 'left' : 'right'}
              >
                {intl.formatMessage({
                  id: title,
                })}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((request, index) => (
              <TableRow
                hover
                key={index + page * rowsPerPage}
                selected={selected === index + page * rowsPerPage}
              >
                <TableCell>{`${request.account.name} ${request.account.surname}`}</TableCell>
                <TableCell align="right">
                  {changeDateFormatWithYear(request.creationDateTime)}
                </TableCell>
                <StatusTableCell color={request.status} align="right">
                  {intl.formatMessage({
                    id: `HOLIDAY_REQUEST_STATUS.${request.status}`,
                  })}
                </StatusTableCell>
                <TableCell align="right">
                  {intl.formatMessage({
                    id: `HOLIDAY_REQUEST_TYPE.${request.type}`,
                  })}
                </TableCell>
                <TableCell align="right">
                  {showVacationDates(request.dateFrom, request.dateTo)}
                </TableCell>
                <TableCell align="right">
                  <TableIconButton
                    id={`index-DetailsIcon`}
                    onClick={() => handleOpen(index + page * rowsPerPage)}
                  >
                    <DetailsIcon />
                  </TableIconButton>
                </TableCell>
              </TableRow>
            ))}
          <TablePagination
            count={count}
            page={page}
            rowsPerPageOptions={[]}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={intl.formatMessage({
              id: 'TABLE.PAGINATION.ROWS_PER_PAGE',
            })}
          />
        </TableBody>
      </Table>
      {selected !== null && (
        <DetailsDialog
          open={areDetailsOpen}
          selectedHoliday={data[selected]}
          handleClose={handleClose}
        />
      )}
    </TabelWrapper>
  );
};

export default EmployeesHolidaysTable;
