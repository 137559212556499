import {
  InfoTypes,
  LeaveTypes,
  LeaveTypesInformationNotesProps,
  MainHolidayTypes,
  MedicalLeaveTypes,
  OtherLeaveTypes,
  StandardLeaveTypes,
} from './types';

export const ROWS_PER_PAGE = 5;

export const LEAVE_REQUESTS_TABLE_HEAD = [
  { name: 'EMPLOYEES_HOLIDAY.LABEL.DATE_OF_REQUEST', isFirstColumn: true },
  { name: 'EMPLOYEES_HOLIDAY.LABEL.TYPE', isFirstColumn: false },
  { name: 'EMPLOYEES_HOLIDAY.LABEL.DATE', isFirstColumn: false },
  { name: 'EMPLOYEES_HOLIDAY.LABEL.STATUS', isFirstColumn: false },
];

export const ACTIVE_REQUESTS_TABLE_HEAD = [
  { name: 'EMPLOYEES_HOLIDAY.LABEL.ACTION', isFirstColumn: false },
];

export const MAIN_LEAVE_REQUESTS_OPTIONS = [
  {
    value: MainHolidayTypes.StandardLeave,
    name: 'LEAVE_REQUEST.FORM_OPTION.STANDARD',
  },
  {
    value: MainHolidayTypes.MedicalLeave,
    name: 'LEAVE_REQUEST.FORM_OPTION.MEDICAL',
  },
  {
    value: MainHolidayTypes.OtherLeave,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER',
  },
];

export const STANDARD_LEAVE_REQUESTS_OPTIONS = [
  {
    value: StandardLeaveTypes.ANNUAL_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.STANDARD.ANNUAL',
  },
  {
    value: StandardLeaveTypes.EMERGENCY_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.STANDARD.EMERGENCY',
  },
];

export const MEDICAL_LEAVE_REQUESTS_OPTIONS = [
  {
    value: MedicalLeaveTypes.SICK_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.MEDICAL.SICK',
  },
  {
    value: MedicalLeaveTypes.CARE_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.MEDICAL.CARE',
  },
];

export const OTHER_LEAVE_REQUESTS_OPTIONS = [
  {
    value: OtherLeaveTypes.SPECIAL_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.OCCASIONAL',
  },
  {
    value: OtherLeaveTypes.UNPAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.UNPAID',
  },
  {
    value: OtherLeaveTypes.CHILD_CARE_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.CHILD_CARE',
  },
  {
    value: OtherLeaveTypes.MATERNITY_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.MATERNITY',
  },
  {
    value: OtherLeaveTypes.FATHERLY_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.FATHERLY',
  },
  {
    value: OtherLeaveTypes.PARENTAL_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.PARENTAL',
  },
  {
    value: OtherLeaveTypes.CHILD_EDUCATION_PAID_LEAVE,
    name: 'LEAVE_REQUEST.FORM_OPTION.OTHER.CHILD_EDUCATION',
  },
];

export const LEAVE_TYPES_WITH_ATTACHEMNT: LeaveTypes[] = [
  MedicalLeaveTypes.CARE_PAID_LEAVE,
  OtherLeaveTypes.MATERNITY_PAID_LEAVE,
  OtherLeaveTypes.FATHERLY_PAID_LEAVE,
  OtherLeaveTypes.PARENTAL_PAID_LEAVE,
  OtherLeaveTypes.CHILD_EDUCATION_PAID_LEAVE,
];

export const leaveTypesInformationNotes: LeaveTypesInformationNotesProps[] = [
  {
    type: StandardLeaveTypes.ANNUAL_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.ANNUAL.FIRST' },
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.ANNUAL.SECOND' },
    ],
  },
  {
    type: OtherLeaveTypes.UNPAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.ANNUAL.FIRST' },
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.ANNUAL.SECOND' },
    ],
  },
  {
    type: StandardLeaveTypes.EMERGENCY_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.EMERGENCY.FIRST' },
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.EMERGENCY.SECOND' },
    ],
  },
  {
    type: MedicalLeaveTypes.SICK_PAID_LEAVE,
    informationNotes: [
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.MEDICAL_SICK.FIRST',
      },
    ],
  },
  {
    type: MedicalLeaveTypes.CARE_PAID_LEAVE,
    informationNotes: [
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.MEDICAL_CARE.FIRST',
      },
      {
        type: InfoTypes.link,
        content: 'LEAVE_REQUEST.INFO.MEDICAL_CARE.SECOND',
        href: 'https://www.zus.pl/wzory-formularzy/zasilki/zasilek-opiekunczy/-/publisher/details/1/wniosek-z-15b/1111160',
      },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.MEDICAL_CARE.THIRD',
      },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.MEDICAL_CARE.FOURTH',
        isImportant: true,
      },
    ],
  },
  {
    type: OtherLeaveTypes.SPECIAL_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OCCASIONAL.FIRST' },
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OCCASIONAL.SECOND' },
    ],
  },
  {
    type: OtherLeaveTypes.MATERNITY_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OTHER.FIRST' },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.OTHER.SECOND',
        isImportant: true,
      },
    ],
  },
  {
    type: OtherLeaveTypes.FATHERLY_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OTHER.FIRST' },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.OTHER.SECOND',
        isImportant: true,
      },
    ],
  },
  {
    type: OtherLeaveTypes.PARENTAL_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OTHER.FIRST' },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.OTHER.SECOND',
        isImportant: true,
      },
    ],
  },
  {
    type: OtherLeaveTypes.CHILD_EDUCATION_PAID_LEAVE,
    informationNotes: [
      { type: InfoTypes.text, content: 'LEAVE_REQUEST.INFO.OTHER.FIRST' },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.OTHER.SECOND',
        isImportant: true,
      },
    ],
  },
  {
    type: OtherLeaveTypes.CHILD_CARE_PAID_LEAVE,
    informationNotes: [
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.CHILD_CARE.FIRST',
      },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.CHILD_CARE.SECOND',
      },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.CHILD_CARE.THIRD',
      },
      {
        type: InfoTypes.link,
        content: 'LEAVE_REQUEST.INFO.CHILD_CARE.FOURTH',
        href: 'https://www.zus.pl/wzory-formularzy/zasilki/zasilek-opiekunczy/-/publisher/details/1/wniosek-z-15a/ZUS_Z-15',
      },
      {
        type: InfoTypes.text,
        content: 'LEAVE_REQUEST.INFO.OTHER.SECOND',
        isImportant: true,
      },
    ],
  },
];
