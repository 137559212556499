import { ChangeEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Radio, RadioGroup } from '@material-ui/core';
import { ControlledRadioInputProps } from './types';
import { StyledFormControlLabel } from './ControlledRadioInput.css';

const ControlledRadioInput = ({
  control,
  name,
  defaultValue,
  data,
  row,
  className,
}: ControlledRadioInputProps) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      type={name}
      defaultValue={defaultValue}
      as={
        <FormControl component="fieldset" className={className}>
          <RadioGroup
            id={name}
            aria-label={name}
            name={name}
            value={value}
            onChange={handleChange}
            row={row}
          >
            {data.map(({ value, label, disabled, id }) => (
              <StyledFormControlLabel
                $row={row}
                key={id}
                value={value}
                control={<Radio color="primary" />}
                label={label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      }
    />
  );
};

export default ControlledRadioInput;
