import { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { businessTripsUrl } from 'router/url';
import {
  CloseIcon,
  DialogTitleWrapper,
  DialogWrapper,
} from 'components/Dialog/Dialog.css';
import {
  BusinessTripCalculatorProps,
  TripCalculatorSummary as TripCalculatorSummaryProps,
  TripStatuses,
} from 'modules/BusinessTrips/types';
import { getFetch } from 'utils/fetchFunctions';
import { ActionKind, RenderPage } from './TripCalculatorContext/types';
import TripCalculatorForm from './TripCalculatorSections/TripCalculatorForm';
import TripCalculatorSummary from './TripCalculatorSections/TripCalculatorSummary';
import { TripCalculatorContext } from './TripCalculatorContext';
import { getCalculatorInitData, getSummaryFormattedToFormData } from './utils';
import { DialogContentWrapperBottomMargin } from './BusinessTripCalculator.css';

const BusinessTripCalculator = ({
  data,
  showCalculator,
  handleToggleCalculator,
  fetchTrips = () => {
    // intentional empty function
  },
  status,
}: BusinessTripCalculatorProps) => {
  const {
    state: { isAccounting, renderPage, currencies },
    dispatch,
  } = useContext(TripCalculatorContext);
  const intl = useIntl();

  const setInitFormData = useCallback(() => {
    if (data) {
      const formattedData = getCalculatorInitData(data);
      dispatch({
        type: ActionKind.SetCalculatorFormValues,
        payload: { calculatorFormValues: formattedData },
      });
    }

    if (data?.foreignBusinessTrip) {
      dispatch({
        type: ActionKind.AddCurrency,
        payload: { currency: { value: 1, name: data?.countryDiet?.currency } },
      });
    } else {
      dispatch({ type: ActionKind.ClearCurrency });
    }
  }, [dispatch, data]);

  const fetchSummary = useCallback(async () => {
    if (data) {
      if (isAccounting) {
        const summary = (await getFetch({
          url: `${businessTripsUrl}/${data?.id}/summary`,
        })) as TripCalculatorSummaryProps;

        dispatch({
          type: ActionKind.SetTripCalculatorSummary,
          payload: { tripCalculatorSummary: summary },
        });
      } else {
        const summary = (await getFetch({
          url: `${businessTripsUrl}/${data?.id}/summary-to-edit`,
        })) as TripCalculatorSummaryProps;

        const formattedData = getSummaryFormattedToFormData(
          summary,
          data,
          currencies,
          intl,
        );

        dispatch({
          type: ActionKind.SetIsEditing,
          payload: { isEditing: true },
        });
        dispatch({
          type: ActionKind.UpdateCalculatorFormValues,
          payload: { calculatorFormValues: formattedData },
        });
      }
    }
  }, [dispatch, isAccounting, data, currencies, intl]);

  const handleClose = useCallback(() => {
    dispatch({ type: ActionKind.ClearTransportation });
    handleToggleCalculator();
  }, [dispatch, handleToggleCalculator]);

  useEffect(() => {
    setInitFormData();
  }, [setInitFormData]);

  useEffect(() => {
    if (data?.summarised) {
      fetchSummary();
    }
  }, [fetchSummary, data?.summarised]);

  useEffect(() => {
    if (data?.status === TripStatuses.DOCS_TO_UPDATE) {
      dispatch({
        type: ActionKind.SetIsAccounting,
        payload: { isAccounting: false },
      });
    }
  }, [data?.status, dispatch]);

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'md'}
      open={showCalculator}
      onClose={handleClose}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id:
                renderPage === RenderPage.Calculator
                  ? 'BUSINESS_TRIP.CALCULATOR_TITLE'
                  : 'BUSINESS_TRIP.SUMMARY_TITLE',
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleToggleCalculator}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapperBottomMargin>
        {renderPage === RenderPage.Calculator ? (
          <TripCalculatorForm />
        ) : (
          <TripCalculatorSummary
            handleToggleCalculator={handleToggleCalculator}
            fetchTrips={fetchTrips}
            isVisiblePDF={
              status === TripStatuses.SETTLED ||
              status === TripStatuses.DOCS_TO_BE_VERIFIED
            }
          />
        )}
      </DialogContentWrapperBottomMargin>
    </DialogWrapper>
  );
};

export default BusinessTripCalculator;
