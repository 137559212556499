import { Controller } from 'react-hook-form';
import { HiddenInput } from 'components/Table/Table.css';
import { HiddenInputProps } from './types';

const BenefitHiddenInput = ({
  control,
  name,
  defaultValue,
}: HiddenInputProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={() => <HiddenInput />}
  />
);

export default BenefitHiddenInput;
