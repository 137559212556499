import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import * as Styles from './Header.css';

interface Props {
  onClick: () => void;
  label: string;
  id: string;
  to?: string;
  href?: string;
  icon?: JSX.Element;
}

const MenuLink = ({ onClick, label, id, to, icon, href }: Props) => {
  const content = (
    <MenuItem onClick={onClick} id={id}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Styles.TextWrapper>{label}</Styles.TextWrapper>
    </MenuItem>
  );

  if (to) {
    return <Styles.LinkWrapper to={to}>{content}</Styles.LinkWrapper>;
  }
  return (
    <Styles.StyledLink href={href} target="_blank">
      {content}
    </Styles.StyledLink>
  );
};

export default MenuLink;
