import { ReactNode } from 'react';
import { GridSpacing } from '@material-ui/core';
import { StepGroup, StepTitle } from '../../../BenefitsManager.css';

interface Props {
  isFirst?: boolean;
  justifyStart?: boolean;
  title: string;
  children: ReactNode;
  spacing?: GridSpacing;
}

const InfoGroup = ({
  isFirst = false,
  title,
  children,
  justifyStart = false,
  spacing = 2,
}: Props) => {
  return (
    <StepGroup
      item
      container
      isFirst={isFirst}
      justifyStart={justifyStart}
      spacing={spacing}
    >
      <StepTitle>{title}</StepTitle>
      {children}
    </StepGroup>
  );
};

export default InfoGroup;
