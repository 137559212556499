import { Control, DeepMap, FieldError, ValidationRules } from 'react-hook-form';
import { GridSize } from '@material-ui/core';
import { DatePickerView } from '@material-ui/pickers';
import { ControlledRadioInputProps } from 'components/ControlledRadioInput/types';
import { EventType } from 'components/ControlledTextField/types';
import { BENEFIT_PARTNER_TYPES } from 'modules/UserProfile/Screens/Benefits/staticData';
import { BenefitPackageProps } from 'modules/UserProfile/Screens/Benefits/types';

export interface BenefitsSelectData {
  name: string;
  value: number | null;
}

export interface Partner extends PersonalData {}

export interface SelectDataWithType extends BenefitsSelectData {
  type: string;
}

export interface SelectPackageWithType extends BenefitsSelectData {
  type: Packages;
}

export interface FormValues
  extends BenefitManagerFormValues,
    BenefitAccountFormValues {}

export enum BENEFIT_MEMBER_TYPES {
  employee = 'EMPLOYEE',
  partner = 'PARTNER',
  child = 'CHILD',
  child_underage = 'CHILD_UNDERAGE',
  other_family_member = 'OTHER_FAMILY_MEMBER',
}

export type Address = {
  city: string;
  zipCode: string;
  street: string;
  houseNumber: string;
  apartmentNumber?: string;
};

export interface PersonalData {
  id: number | null;
  dateOfBirth?: Date | null;
  address?: Address;
  crspAddress?: Address;
  email?: string;
  firstName: string;
  gender?: string;
  idNumber?: string | null;
  pesel?: number | null;
  phoneNumber?: string | null;
  placeOfBirth?: string;
  surname: string;
  memberType?: BENEFIT_MEMBER_TYPES;
  hiringCompany?: string;
  nationality?: string;
  passportNumber?: string;
  personalId?: number;
  areAddressesDifferent: boolean;
  partnerType?: { name: string; value: number; type: BENEFIT_PARTNER_TYPES };
  comments?: string;
}

export interface BenefitAccountFormValues {
  id: number | null;
  benefitName: string;
  benefitType?: { name: string; value: number | null };
  benefitPackage?: BenefitPackageProps;
  employee: PersonalData;
  partners?: Partner[];
}

export interface BenefitManagerFormValues<TimeRangeDataType = TimeRangeData> {
  id: number | null;
  benefitName: string;
  benefitEmail: string;
  lastDayToSendChanges: number | null;
  benefitCategory: SelectDataWithType;
  usingOwnForm: boolean;
  benefitTypes: BenefitTypesProps[];
  employeeInputs: InputProps[];
  partnerInputs?: InputProps[];
  childInputs?: InputProps[];
  editableInputs: InputProps[];
  comments?: string;
  timeToChangeToLowerType: TimeRangeDataType;
  timeToChangeToHigherType: TimeRangeDataType;
  timeToChangeToLowerPackage: TimeRangeDataType;
  timeToChangeToHigherPackage: TimeRangeDataType;
  timeToRemovePartner: TimeRangeDataType;
  timeToAddPartner: TimeRangeDataType;
  status?: Status;
  billableBy?: BillabilityTypes;
  benefitPackage?: BenefitPackageProps;
}

export interface TimeRangeData {
  numberOfDaysMonthsYears: number | null;
  dayMonthYear: SelectDataWithType | null;
  fixedDate: Date | null;
}

export interface FetchedTimeRangeData extends Omit<TimeRangeData, 'fixedDate'> {
  fixedDate: string | null;
}
export interface BenefitTypesProps {
  id?: string;
  benefitTypeName: string;
  companyCost: number | null;
  individualPackage?: CostInfo;
  partnerPackage?: CostInfo;
  familyPackage?: CostInfo;
  employeeCost?: CostInfo;
  partnerCost?: CostInfo;
  childCost?: CostInfo;
  notApplicable?: string;
}

export interface CostInfo {
  active: boolean;
  cost: number | null;
  maxChildAge?: number | null;
  minChildAge?: number | null;
  numberOfChildren?: number | null;
}

export interface InputProps {
  name: InputNames;
  type: InputNames;
  value: number;
}

export interface OmittedBenefitManagerFormValues
  extends Omit<
    BenefitManagerFormValues<FetchedTimeRangeData>,
    'employeeInputs' | 'childInputs' | 'partnerInputs' | 'comments'
  > {}

export interface BenefitFetchedData extends OmittedBenefitManagerFormValues {
  mandatoryEmployeeInputs: InputProps[];
  mandatoryPartnerInputs?: InputProps[];
  mandatoryChildInputs?: InputProps[];
  comments?: string;
}

interface GridSizes {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

interface AllFieldProps<T = FormValues> extends GridSizes {
  label: string;
  name: keyof T;
  index?: number;
  nameOfErrorProperty?: ErrorProperties;
  rules?: ValidationRules;
  handleChange?: () => void;
  disabled?: boolean;
}

export interface HiddenInputProps {
  control: Control;
  name: keyof FormValues;
  defaultValue?: number | string | null;
}

export interface ControlledFieldProps {
  control: Control;
  errors: DeepMap<FormValues, FieldError>;
}

export interface TextFieldProps extends AllFieldProps, ControlledFieldProps {
  placeholder?: string;
  defaultValue?: number | string | null;
  isMulti?: boolean;
  isOnlyInteger?: boolean;
  addressType?: 'address' | 'crspAddress';
  tooltipTitle?: string;
  handleChange?: (e?: EventType) => void;
}
export interface SelectFieldProps extends AllFieldProps, ControlledFieldProps {
  options: BenefitsSelectData[];
  errorMessage?: string;
  defaultValue?: BenefitsSelectData | string;
  multiDefaultValue?: BenefitsSelectData[];
  isMulti?: boolean;
}

export interface DatePickerProps extends AllFieldProps, ControlledFieldProps {
  defaultValue?: Date | null;
  view?: DatePickerView;
  views?: DatePickerView[];
  disablePast?: boolean;
  disableFuture?: boolean;
  clearable?: boolean;
  format?: string;
}

export interface RadioInputProps
  extends Omit<ControlledRadioInputProps, 'name'>,
    GridSizes {
  name: keyof FormValues;
}

export enum InputNames {
  firstName = 'firstName',
  surname = 'surname',
  pesel = 'pesel',
  idNumber = 'idNumber',
  phoneNumber = 'phoneNumber',
  email = 'email',
  address = 'address',
  crspAddress = 'addressForCorrespondence',
  dateOfBirth = 'dateOfBirth',
  gender = 'gender',
  placeOfBirth = 'placeOfBirth',
}

export enum TimeRangeTypes {
  lowerType = 'timeToChangeToLowerType',
  higherType = 'timeToChangeToHigherType',
  lowerPackage = 'timeToChangeToLowerPackage',
  higherPackage = 'timeToChangeToHigherPackage',
  removePartner = 'timeToRemovePartner',
  addPartner = 'timeToAddPartner',
}

export enum InputTypes {
  employee = 'employeeInputs',
  partner = 'partnerInputs',
  child = 'childInputs',
  editable = 'editableInputs',
}

export enum Packages {
  individual = 'individualPackage',
  partner = 'partnerPackage',
  family = 'familyPackage',
}

export enum PersonCosts {
  employee = 'employeeCost',
  partner = 'partnerCost',
  child = 'childCost',
}

export enum CostProperties {
  cost = 'cost',
  active = 'active',
}

export enum ChildProperties {
  amount = 'numberOfChildren',
  maxAge = 'maxChildAge',
  minAge = 'minChildAge',
}

export enum PartnerProperties {
  maxAge = 'maxChildAge',
  minAge = 'minChildAge',
}

export enum Status {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export interface SelectedBenefitData {
  name: string;
  status?: Status;
  id: number | null;
}

export enum ErrorProperties {
  Partners = 'PARTNERS',
  BenefitTypes = 'BENEFIT_TYPES',
  TimeRange = 'TIME_RANGE',
  Address = 'ADDRESS',
  Employee = 'EMPLOYEE',
}

export enum BillabilityTypes {
  package = 'PACKAGE',
  person = 'PERSON',
}
