import { IntlShape } from 'react-intl';
import { SwitchIcon } from './Table.css';

export const HoursCell = (
  hoursChecked: boolean,
  intl: IntlShape,
  handleChange: () => void,
) => (
  <>
    {hoursChecked
      ? intl.formatMessage({ id: 'TABLE.HEAD.REPORTED_HOURS' })
      : intl.formatMessage({ id: 'TABLE.HEAD.REPORTED_DAYS' })}
    <SwitchIcon onClick={handleChange} id="switchIcon" />
  </>
);
