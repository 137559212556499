import { ArrayField } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  Clear,
  Done,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import moment from 'moment';
import { ProjectCategories } from 'components/Dialog/types';
import { ProjectData } from 'modules/WorkTime/types';
import { mapStringToDuration } from 'utils/durationFunctions';
import {
  CustomFieldsRowProps,
  FullReportDay,
  InvalidWorktimeProps,
  ParsedDaysProps,
  RenderActionIconsProps,
  SummaryStatus,
  TableCellData,
} from './types';
import {
  ActionIconWrapper,
  ActivateIcon,
  AddIcon,
  BoldedText,
  BorderRow,
  DeactivateIcon,
  HeaderTooltipText,
  LeftCellWrapper,
  RightCellWrapper,
  TableIconButton,
  Tooltip,
  TrashIcon,
} from './Table.css';

export const renderTableCellData = (entry: TableCellData) => {
  switch (typeof entry) {
    case 'string':
      return entry;
    case 'number':
      return entry.toString();
    case 'boolean':
      return entry ? <Done /> : <Clear />;
  }
};

export const returnHideIcon = (intl: IntlShape) => (
  <>
    <KeyboardArrowUp />
    <Typography>
      {intl.formatMessage({
        id: 'TABLE.BODY.DETAILS_HIDE',
      })}
    </Typography>
  </>
);

export const returnShowIcon = (intl: IntlShape) => (
  <>
    <KeyboardArrowDown />
    <Typography>
      {intl.formatMessage({
        id: 'TABLE.BODY.DETAILS_SHOW',
      })}
    </Typography>
  </>
);

export const CustomFieldsRow = ({
  intl,
  onAddCustomFieldClicked,
  index,
  isAccountPrivileged,
}: CustomFieldsRowProps) => (
  <BorderRow>
    <LeftCellWrapper>
      {`${intl.formatMessage({
        id: 'CUSTOM_FIELDS.TITLE',
      })}`}
    </LeftCellWrapper>
    {isAccountPrivileged && (
      <RightCellWrapper>
        <TableIconButton
          id="addCustomFieldsButton"
          onClick={() => onAddCustomFieldClicked(index)}
        >
          <Tooltip
            title={
              <HeaderTooltipText>
                {intl.formatMessage({
                  id: 'CUSTOM_FIELDS.DIALOG_TITLE',
                })}
              </HeaderTooltipText>
            }
            placement="top"
          >
            <AddIcon />
          </Tooltip>
        </TableIconButton>
      </RightCellWrapper>
    )}
  </BorderRow>
);

export function renderActionIcons<T extends Record<string, any>>({
  renderEditIcon,
  setDialogData,
  index,
  data,
  intl,
  entry,
  handleShowConfimationDialog,
  isArchivable,
  isDeletable,
  elementToRemove,
  handleActivateClicked,
  isAllowedForActions,
  isJitOther,
}: RenderActionIconsProps<T>) {
  const onActivateClicked = () => {
    handleShowConfimationDialog();
    setDialogData(data[index]);
    handleActivateClicked && handleActivateClicked();
  };

  const onDeleteClicked = () => {
    setDialogData(data[index]);
    handleShowConfimationDialog();
  };

  const trashIconLabel = elementToRemove && elementToRemove.toUpperCase();

  const getInfo = () => {
    switch (true) {
      case Boolean(entry?.status !== 'ACTIVE' && entry.project):
        return 'TABLE_ACTIONS.ACTIVATE_INFO_PROJECT';
      case Boolean(entry?.status !== 'ACTIVE' && !entry.project):
        return 'TABLE_ACTIONS.ACTIVATE_INFO_CLIENT';
      case Boolean(entry.project):
        return 'TABLE_ACTIONS.DEACTIVATE_INFO_PROJECT';
      default:
        return 'TABLE_ACTIONS.DEACTIVATE_INFO_CLIENT';
    }
  };

  const renderIcons = () => {
    if (isArchivable) {
      return (
        <Tooltip
          title={
            <HeaderTooltipText>
              {intl.formatMessage({
                id: getInfo(),
              })}
            </HeaderTooltipText>
          }
          placement="top"
        >
          <div>
            <TableIconButton
              id="activationButton"
              onClick={onActivateClicked}
              disabled={!isAllowedForActions || isJitOther}
            >
              {entry?.status === 'ACTIVE' ? (
                <DeactivateIcon />
              ) : (
                <ActivateIcon />
              )}
            </TableIconButton>
          </div>
        </Tooltip>
      );
    } else if (trashIconLabel) {
      return (
        <Tooltip
          title={
            <HeaderTooltipText>
              {intl.formatMessage({
                id: isDeletable
                  ? `TABLE_ACTIONS.${trashIconLabel}_DELETABLE`
                  : `TABLE_ACTIONS.${trashIconLabel}_NOT_DELETABLE`,
              })}
            </HeaderTooltipText>
          }
          placement="top"
        >
          <span>
            <TableIconButton
              id="deleteButton"
              onClick={onDeleteClicked}
              disabled={!isDeletable}
            >
              <TrashIcon />
            </TableIconButton>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <TableIconButton
          id="deleteButton"
          onClick={onDeleteClicked}
          disabled={!isDeletable}
        >
          <TrashIcon />
        </TableIconButton>
      );
    }
  };

  return (
    <ActionIconWrapper>
      {isAllowedForActions && renderEditIcon(data, index)}
      <>{renderIcons()}</>
    </ActionIconWrapper>
  );
}

export const getWorkTimeDuration = (
  startTime: string,
  endTime: string,
  breakTime: string,
) => {
  if (
    mapStringToDuration(breakTime) <
    mapStringToDuration(endTime).subtract(mapStringToDuration(startTime))
  ) {
    return mapStringToDuration(endTime)
      .subtract(mapStringToDuration(startTime))
      .subtract(mapStringToDuration(breakTime));
  }
  return '';
};

export const getWorkTimeMidnightDuration = (
  startTime: string,
  breakTime: string,
) => {
  const midnightDuration = moment
    .duration('23:59')
    .subtract(mapStringToDuration(startTime));
  if (mapStringToDuration(breakTime) < midnightDuration) {
    return midnightDuration
      .subtract(mapStringToDuration(breakTime))
      .add(1, 'minutes');
  }
  return '';
};

const checkIfMidnight = (hour: number) => {
  if (hour === 0) {
    return 24;
  } else {
    return hour;
  }
};

const areHoursOverlapping = (
  currentStartTime: number | null,
  currentEndTime: number | null,
  previousStartTime: number | null,
  previousEndTime: number | null,
) => {
  if (
    typeof currentStartTime !== 'number' ||
    typeof currentEndTime !== 'number' ||
    typeof previousStartTime !== 'number' ||
    typeof previousEndTime !== 'number'
  ) {
    return;
  }

  const validStartTime =
    (currentStartTime >= previousStartTime &&
      currentStartTime >= checkIfMidnight(previousEndTime)) ||
    (currentStartTime <= previousStartTime &&
      checkIfMidnight(currentEndTime) <= previousStartTime);

  const validEndTime =
    (checkIfMidnight(currentEndTime) > previousStartTime &&
      checkIfMidnight(currentEndTime) >= checkIfMidnight(previousEndTime)) ||
    checkIfMidnight(currentEndTime) <= previousStartTime;

  return !validStartTime || !validEndTime;
};

export const getInvalidWorktime = (days: ParsedDaysProps[]) => {
  const getWorktime = (time: string) => {
    if (time) {
      return moment.duration(time).asHours();
    }
    return null;
  };
  const invalidWorktime: InvalidWorktimeProps[] = [];

  const checkIfInvalidWorktime = () =>
    days?.map((entry, _, arrayOfDays) => {
      const entryStartTime = getWorktime(entry.startTime);
      const entryEndTime = getWorktime(entry.endTime);

      return arrayOfDays.map((nextEntry) => {
        if (entry.date === nextEntry.date && entry.id !== nextEntry.id) {
          const nextEntryStartTime = getWorktime(nextEntry.startTime);
          const nextEntryEndTime = getWorktime(nextEntry.endTime);

          if (
            areHoursOverlapping(
              nextEntryStartTime,
              nextEntryEndTime,
              entryStartTime,
              entryEndTime,
            )
          ) {
            invalidWorktime.push({ type: 'overlapped', id: Number(entry.id) });
          }

          if (entryStartTime === entryEndTime) {
            invalidWorktime.push({ type: 'equal', id: Number(entry.id) });
          }
        }
        return null;
      });
    });
  checkIfInvalidWorktime();
  return invalidWorktime;
};

export const getInvalidWorktimeTitle = (
  intl: IntlShape,
  invalidWorktime: InvalidWorktimeProps[],
  id: number,
) => {
  const isEqual =
    invalidWorktime.find((day) => day.type === 'equal')?.id === id;
  let type = 'OVERLAPPING_HOURS';
  if (isEqual) {
    type = 'EQUAL_HOURS';
  }

  return intl.formatMessage({
    id: `WORK_TIME_TAB.${type}.TOOLTIP`,
  });
};

export const addLabelsForTimeOffProjects = (projectsData: ProjectData[]) =>
  projectsData.map((project) => {
    switch (project.projectCategory) {
      case ProjectCategories.dayOff:
        return { ...project, tooltipMessage: 'PROJECT_TOOLTIP.LABEL.DAY_OFF' };
      case ProjectCategories.vacations:
        return {
          ...project,
          tooltipMessage: 'PROJECT_TOOLTIP.LABEL.VACATIONS',
        };
      default:
        return project;
    }
  });

export const projectsFilter = (
  projectsData: ProjectData[],
  client?: string | null,
) => {
  return projectsData.filter((data) => data.client.clientName === client);
};

const getProjectsForClient = (
  data: FullReportDay[],
  projectsData: ProjectData[],
  defaultClientName: string,
) => {
  return data.map((day) => {
    const getClient = () => {
      return day?.client?.name ?? defaultClientName;
    };
    return {
      dayId: String(day.id),
      projects: projectsFilter(projectsData, getClient()),
    };
  });
};

export const getInitialProjects = (
  data: FullReportDay[],
  projectsData: ProjectData[],
  defaultClientName: string,
) => {
  return getProjectsForClient(data, projectsData, defaultClientName);
};

export const getNewEntryProjects = (
  newEntry: FullReportDay,
  projectsData: ProjectData[],
  defaultClientName: string | null,
) => {
  return {
    dayId: newEntry.dayId,
    projects: projectsFilter(projectsData, defaultClientName),
  };
};

export const getFormattedFullReportData = (parsedDays: ParsedDaysProps[]) =>
  parsedDays.map((day) => {
    const { ...otherClientProperties } = day.client || {};
    const { ...otherProjectProperties } = day.project || {};

    return {
      ...day,
      client: {
        ...otherClientProperties,
        id: day.client?.value ?? day.client?.id,
      },
      project: {
        ...otherProjectProperties,
        id: day.project?.value ?? day.project?.id,
      },
    };
  });

export const getBankHolidayTooltip = (
  entry: Partial<ArrayField<Record<string, any>, 'id'>>,
) => {
  const title = entry.bankHolidayDTOS.map((el: { title: string }) => el.title);
  const description = entry.bankHolidayDTOS.map(
    (el: { description: string }) => el.description,
  );
  return (
    <Grid direction="row">
      <Grid>
        <BoldedText>{title}</BoldedText>
      </Grid>
      <Grid>
        <span>{description}</span>
      </Grid>
    </Grid>
  );
};

export const getSummaryButtonText = (
  approveAndSend: string,
  summaryStatus?: SummaryStatus,
) =>
  summaryStatus === SummaryStatus.NEEDS_LEADER_APPROVAL ||
  summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL
    ? 'WORK_TIME_TAB.REQUEST_FOR_APPROVAL'
    : approveAndSend;

export const getSendReportToastMessage = (summaryStatus?: SummaryStatus) =>
  summaryStatus === SummaryStatus.NEEDS_LEADER_APPROVAL ||
  summaryStatus === SummaryStatus.WAITING_FOR_LEADER_APPROVAL
    ? 'SUMMARY.REQUEST_APPROVAL'
    : 'SUMMARY.SENDING';
