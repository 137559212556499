import {
  CellFlex,
  DateRangeIcon,
  DeleteIcon,
  StarIcon,
} from 'components/Table/Table.css';
import { ActionIconsProps } from '../../types';
import TooltipIcon from './TooltipIcon';
import {
  checkIfDisabled,
  checkIfLoading,
  getDateRangeTooltipData,
  getDefaultProjectTooltipData,
  getUnassignTooltipData,
} from './utils';
import { StyledCircularProgress } from '../../QuickReport.css';

const ActionIcons = ({
  entry,
  isTimesheetDisabled,
  iconLoading,
  handleDateRange,
  handleUnassignProject,
  handleChangeDefaultProject,
  hasCustomData,
  canUnassign,
}: ActionIconsProps) => {
  const { userDefaultProject, projectId } = entry;

  return (
    <CellFlex>
      {!hasCustomData && (
        <TooltipIcon
          intlTitle={getDateRangeTooltipData(entry).intl}
          id={getDateRangeTooltipData(entry).id}
          disabled={
            checkIfDisabled('getDateRange', entry) || isTimesheetDisabled
          }
          onClick={() => handleDateRange(entry)}
          Icon={DateRangeIcon}
        />
      )}
      {!hasCustomData &&
        (checkIfLoading('setDefaultProject', projectId, iconLoading) ? (
          <StyledCircularProgress size={20} />
        ) : (
          <TooltipIcon
            intlTitle={getDefaultProjectTooltipData(entry).intl}
            id={getDefaultProjectTooltipData(entry).id}
            disabled={
              checkIfDisabled('setDefaultProject', entry) || isTimesheetDisabled
            }
            isActiveWhileDisabled={userDefaultProject}
            onClick={() => handleChangeDefaultProject(entry)}
            Icon={StarIcon}
          />
        ))}
      {checkIfLoading('unassignProject', projectId, iconLoading) ? (
        <StyledCircularProgress size={20} />
      ) : (
        <TooltipIcon
          intlTitle={getUnassignTooltipData(entry).intl}
          id={getUnassignTooltipData(entry).id}
          disabled={
            checkIfDisabled('unassignProject', entry, canUnassign) ||
            isTimesheetDisabled
          }
          onClick={() => handleUnassignProject(projectId)}
          Icon={DeleteIcon}
        />
      )}
    </CellFlex>
  );
};

export default ActionIcons;
