import { changeDateFormatWithYear, showVacationDates } from 'utils/dateFormats';
import { GridElementTypes, HolidayRequestProps } from './types';
//balance temporarly hidden - waiting for backend
export const getDetailsDataGrid = ({
  account,
  creationDateTime,
  dateFrom,
  dateTo,
  requestedDays,
  type,
  status,
  comments,
}: HolidayRequestProps) => {
  return [
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.NAME',
      content: account.username,
      type: GridElementTypes.Name,
    },
    // {
    //   label: 'EMPLOYEES_HOLIDAY.LABEL.BALANCE',
    //   content: balance,
    //   type: GridElementTypes.Info,
    // },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.DATE_OF_REQUEST',
      content: changeDateFormatWithYear(creationDateTime),
      type: GridElementTypes.Info,
    },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.DATE',
      content: showVacationDates(dateFrom, dateTo),
      type: GridElementTypes.Info,
    },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.NUMBER_OF_DAYS',
      content: requestedDays,
      type: GridElementTypes.Info,
    },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.TYPE',
      content: type,
      type: GridElementTypes.Type,
    },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.STATUS',
      content: status,
      type: GridElementTypes.Status,
    },
    {
      label: 'EMPLOYEES_HOLIDAY.LABEL.COMMENT',
      content: comments,
      type: GridElementTypes.Comment,
    },
  ];
};
