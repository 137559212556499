import { Dispatch } from 'react';
import { BusinessTripFormValues } from '../Dialog/types';

export interface State {
  formState: BusinessTripFormValues;
}

export enum ActionKind {
  SetFormState = 'UPDATE_FORM_STATE',
  ResetFormState = 'RESET_FORM_STATE',
  SetNumberOfNights = 'SET_NUMBER_OF_NIGHTS',
}

export interface BusinessTripsContextProps {
  dispatch: Dispatch<Action>;
  businessTripsManagerState: State;
}

interface SetFormStateAction {
  type: ActionKind.SetFormState;
  payload: BusinessTripFormValues;
}

interface ResetFormStateAction {
  type: ActionKind.ResetFormState;
}

interface SetNumberOfNights {
  type: ActionKind.SetNumberOfNights;
  payload: number;
}

export type Action =
  | SetFormStateAction
  | ResetFormStateAction
  | SetNumberOfNights;
