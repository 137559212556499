import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import CircularProgressComponent from '@material-ui/core/CircularProgress';
import { getDaysInMonth } from 'date-fns';
import { UserContext } from 'globalContext/UserContext';
import moment, { Moment } from 'moment';
import {
  timesheetsUrl,
  userProfileUrl,
  workTimeFullReportUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledDatePicker from 'components/ControlledDatePicker';
import ControlledSelect from 'components/ControlledSelect';
import { Checkbox } from 'components/Table/Table.css';
import { projectsFilter } from 'components/Table/tableUtils';
import { FullReport, FullReportDay } from 'components/Table/types';
import { AccountResponse } from 'modules/UserProfile/types';
import { ClientData, ProjectData } from 'modules/WorkTime/types';
import { dateFormatMoment, formatMonthsToTwoDigits } from 'utils/dateFormats';
import { mapISODurationToString } from 'utils/durationFunctions';
import { getFetch, patchFetch } from 'utils/fetchFunctions';
import { returnHourDate } from 'utils/formatters';
import { useToggleState } from 'utils/hooks/useToggleState';
import { FormData, ProjectDateRangeDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogContentWrapperFlex,
  DialogParagraph,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const ProjectDateRangeDialog = ({
  isDialogOpen,
  onClose,
  date,
  clientsData,
  projectsData,
  fetchReport,
}: ProjectDateRangeDialogProps) => {
  const [projectOptions, setProjectOptions] = useState<ProjectData[]>([]);
  const [shouldOverwrite, toggleShouldOverwrite] = useToggleState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm<FormData>({ mode: 'onChange' });
  const { username } = useContext(UserContext);
  const intl = useIntl();

  const getDates = (dateStart: Moment, dateEnd: Moment) => {
    let dateArray = [];
    while (dateStart.isSameOrBefore(dateEnd)) {
      dateArray.push(dateStart.format(dateFormatMoment));
      dateStart = dateStart.add(1, 'days');
    }
    return dateArray;
  };

  const getDays = (data: FullReport) => {
    return data?.days.map((entry: FullReportDay) => ({
      ...entry,
      dayId: entry.id,
      startTime: entry.startTime && returnHourDate(entry.startTime),
      endTime: entry.endTime && returnHourDate(entry.endTime),
      manHours: entry.manHours && mapISODurationToString(entry.manHours),
      breakTime:
        entry.breakTime &&
        returnHourDate(mapISODurationToString(entry.breakTime)),
      multiplicator: {
        label: entry.multiplicator,
        value: entry.multiplicator,
        name: intl.formatMessage({
          id: `MULTIPLICATOR.${entry.multiplicator}`,
        }),
      },
    }));
  };

  const convertToEndTime = (startTime: string) => {
    const entryTime = startTime.split(':');
    const hours = parseInt(entryTime[0]) + 8;
    const minutes = parseInt(entryTime[1]);
    if (minutes === 0) {
      return hours + ':00';
    }
    if (minutes.toString().length === 1) {
      return hours + ':0' + minutes;
    }
    return hours + ':' + minutes;
  };

  const mappingDays = (
    datesArrayFiltered: string[],
    days: FullReportDay[],
    profile: AccountResponse,
  ) => {
    return datesArrayFiltered.map((date) => {
      const day = days?.find((day: { date: string }) => day.date === date);
      if (!day) {
        return <></>;
      }
      const clientValue = getValues('client');
      const projectValue = getValues('project');
      return {
        breakTime: 'PT0S',
        client: {
          name: clientValue.clientName,
          id: clientValue.value,
        },
        comment: day.comment,
        date: day.date,
        startTime: profile.startTime,
        id: day.id,
        multiplicator: 'STANDARD_100',
        project: {
          name: projectValue.projectName,
          id: projectValue.value,
        },
        endTime: convertToEndTime(profile.startTime),
      };
    });
  };

  const filterAndPrepareDaysToSend = (
    datesArrayEnds: string[],
    days: any,
    profile: AccountResponse,
  ) => {
    const result = datesArrayEnds.filter((date) => {
      const day = days?.find((day: { date: string }) => day.date === date);
      if (!day) {
        return <></>;
      }
      return !(
        day.dayOfWeek === 'SATURDAY' ||
        day.dayOfWeek === 'SUNDAY' ||
        day.holiday ||
        day.bankHoliday
      );
    });
    return mappingDays(result, days, profile);
  };

  const filterAndPrepareDaysToSendWithoutOverwriting = (
    datesArrayEnds: string[],
    days: any,
    profile: AccountResponse,
  ) => {
    const result = datesArrayEnds.filter((date) => {
      const day = days?.find((day: { date: string }) => day.date === date);
      return !(
        day.dayOfWeek === 'SATURDAY' ||
        day.dayOfWeek === 'SUNDAY' ||
        day.holiday ||
        day.bankHoliday ||
        day.startTime !== null ||
        day.multiplicator.label === 'STANDARD_VACATION_0' ||
        day.multiplicator.label === 'OTHER_VACATION_0' ||
        day.multiplicator.label === 'MEDICAL_LEAVE_0'
      );
    });
    return mappingDays(result, days, profile);
  };

  const handleClientChange = useCallback(
    (_e: ClientData) => {
      setValue('project', undefined);
      const projectsForClient = projectsFilter(
        projectsData,
        getValues('client')?.name,
      );
      setProjectOptions(projectsForClient);
    },
    [getValues, setValue, projectsData],
  );

  if (!date) {
    return <></>;
  }

  const dateRangeStartDate = 1;
  const dateRangeEndDate = getDaysInMonth(new Date());

  const minDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const onSubmit = handleSubmit(async (formValues: FormData) => {
    setIsLoading(true);
    const reportData: FullReport = await getFetch({
      url: `${timesheetsUrl}/${username}/${workTimeFullReportUrl}?yearMonth=${date.getFullYear()}-${formatMonthsToTwoDigits(
        date.getMonth() + 1,
      )}`,
    });

    const profile: AccountResponse = await getFetch({
      url: `${userProfileUrl}/${username}/profile`,
    });

    const days = getDays(reportData);
    const datesArrayEnds = getDates(
      moment(formValues.dateStart),
      moment(formValues.dateEnd),
    );

    const body = {
      days: shouldOverwrite
        ? filterAndPrepareDaysToSend(datesArrayEnds, days, profile)
        : filterAndPrepareDaysToSendWithoutOverwriting(
            datesArrayEnds,
            days,
            profile,
          ),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    await patchFetch({
      url: `${timesheetsUrl}/${username}/${workTimeFullReportUrl}`,
      intl,
      body: body,
      label: 'QUICK_REPORT.DATE_RANGE',
    });
    fetchReport();

    onClose();
    setIsLoading(false);
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isDialogOpen}
      onClose={onClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={10} item container justify="flex-start">
            {intl.formatMessage({
              id: 'WORK_TIME_TAB.REPORT_RANGE_HEADER',
            })}
          </Grid>
          <Grid xs={2} item container justify="flex-end">
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledSelect
                id="client"
                name="client"
                label="TABLE.HEAD.CLIENT"
                control={control}
                options={clientsData ?? []}
                required
                handleChange={handleClientChange}
                isOptionDisabled={(option) => option.status === 'INACTIVE'}
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledSelect
                id="project"
                label="TABLE.HEAD.PROJECT"
                name="project"
                control={control}
                options={projectOptions ?? []}
                required
                noOptionMessage={intl.formatMessage({
                  id: 'WORK_TIME.SELECT_CLIENT',
                })}
                isOptionDisabled={(option) =>
                  option.status === 'INACTIVE' ||
                  option.reportingCustomData === true
                }
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledDatePicker
                name="dateStart"
                control={control}
                view="date"
                minDate={minDate}
                maxDate={maxDate}
                label={intl.formatMessage({
                  id: 'QUICK_REPORT.START_DATE',
                })}
                rules={{
                  validate: (v) =>
                    moment(v).isSameOrBefore(moment(getValues('dateEnd'))),
                }}
                defaultValue={
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    dateRangeStartDate,
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledDatePicker
                name="dateEnd"
                control={control}
                view="date"
                minDate={minDate}
                maxDate={maxDate}
                label={intl.formatMessage({
                  id: 'QUICK_REPORT.END_DATE',
                })}
                defaultValue={
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    dateRangeEndDate,
                  )
                }
              />
            </GridItem>
            <DialogContentWrapperFlex>
              <Checkbox
                id="overwrite"
                onChange={toggleShouldOverwrite}
                checked={shouldOverwrite}
              />
              <DialogParagraph>
                {intl.formatMessage({
                  id: 'WORK_TIME.CONFIRMATION_OVERWRITE_DIALOG.CONTENT',
                })}
              </DialogParagraph>
            </DialogContentWrapperFlex>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            onClick={onClose}
            size="large"
            color="secondary"
            type="submit"
            id="cancelButton"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            fullWidth
            id="submitButton"
            onClick={onSubmit}
            size="large"
            type="submit"
            disabled={!isValid || isLoading}
            endIcon={
              isLoading ? (
                <CircularProgressComponent color="secondary" size={29} />
              ) : undefined
            }
          >
            {intl.formatMessage({
              id: `MODAL.BUTTON.SUBMIT`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default ProjectDateRangeDialog;
