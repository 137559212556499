import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { businessTripsUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import DialogWithMessage from 'components/Dialog/DialogWithMessage';
import PopUpReminderDialog from 'components/Dialog/PopUpReminderDialog';
import TripBillingDialog from 'components/Dialog/TripBillingDialog';
import { patchFetch, putFetch } from 'utils/fetchFunctions';
import { useToggleState } from 'utils/hooks/useToggleState';
import { ActionsProps, TripStatuses } from '../types';
import { TripCalculatorContext } from './BusinessTripCalculator/TripCalculatorContext';
import { ActionKind } from './BusinessTripCalculator/TripCalculatorContext/types';
import { ActionsContainer, ButtonContainer } from './BusinessTripDetails.css';

const BusinessTripDetailsActions = ({
  id,
  status,
  fetchTrips,
  handleToggleCalculator,
  tripSummarised,
  tripsChanged,
  displayReminder,
  setDisplayReminder,
  tripBillingOpen,
  toggleTripBillingOpen,
  visibleForUser,
  isSummaryVisible,
  isLeaderOfBusinessTrip,
  forAdministration,
  forAccountancy,
}: ActionsProps) => {
  const intl = useIntl();

  const { dispatch } = useContext(TripCalculatorContext);
  const [open, toggleHandleOpen] = useToggleState(false);
  const [finalRejectOpen, toggleFinalRejectOpen] = useToggleState(false);
  const [reasonMessage, setReasonMessage] = useState('');
  const [rejectMessage, setRejectMessage] = useState('');

  const isReminderVisible =
    displayReminder &&
    visibleForUser &&
    status === TripStatuses.FINISHED &&
    forAdministration &&
    tripSummarised;

  const handleToggleSummary = () => {
    dispatch({
      type: ActionKind.SetIsAccounting,
      payload: { isAccounting: true },
    });
    dispatch({ type: ActionKind.RenderSummary });
    handleToggleCalculator();
  };

  const handleToggleCalculatorForm = () => {
    dispatch({
      type: ActionKind.SetIsAccounting,
      payload: { isAccounting: false },
    });
    dispatch({ type: ActionKind.RenderCalculator });
    handleToggleCalculator();
  };

  const putAcceptanceStatus = useCallback(
    async (isApproved: boolean) => {
      await putFetch({
        url: `${businessTripsUrl}/${id}/settle-by-leader/?is-accepted=${isApproved}`,
        intl,
        label: isApproved ? 'APPROVAL' : 'REJECTION',
      });
    },
    [id, intl],
  );

  const patchStatus = useCallback(
    async (url: string, label: string, body?: string) => {
      const { ok } = await patchFetch({
        url: url,
        intl,
        label: label,
        body: { rejectReason: body },
      });
      if (ok) {
        tripsChanged();
      }
    },
    [intl, tripsChanged],
  );

  const putStatus = async (url: string, label: string, body?: string) => {
    const { ok } = await putFetch({
      url: url,
      intl,
      label: label,
      body: { rejectReason: body },
    });
    if (ok) {
      tripsChanged();
    }
  };

  const renderButtonToChangeStatus = (businessTripId: number) => {
    if (forAdministration || visibleForUser) {
      return (
        <ButtonContainer item>
          <ContainedButton
            onClick={() =>
              patchStatus(
                `${businessTripsUrl}/${businessTripId}/${
                  forAdministration ? 'reservation-done' : 'verify'
                }`,
                'BUSINESS_TRIP_STATUS_UPDATE',
              )
            }
            disabled={!forAdministration && !tripSummarised}
          >
            {intl.formatMessage({
              id: forAdministration
                ? 'BUSINESS_TRIP.STATUS.RESERVATION_DONE'
                : 'BUSINESS_TRIP.STATUS.DOCS_TO_BE_VERIFIED',
            })}
          </ContainedButton>
        </ButtonContainer>
      );
    } else if (forAccountancy) {
      return (
        <ButtonContainer item>
          <ContainedButton
            style={{ marginRight: '32px' }}
            onClick={() =>
              patchStatus(
                `${businessTripsUrl}/${businessTripId}/ask-leader-final-approval`,
                'BUSINESS_TRIP_STATUS_UPDATE',
              )
            }
          >
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.LABEL.ASK_LEADER_FOR_FINAL_APPROVAL',
            })}
          </ContainedButton>
          <ContainedButton
            style={{ marginRight: '10px' }}
            onClick={toggleHandleOpen}
          >
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.STATUS.DOCS_TO_UPDATE',
            })}
          </ContainedButton>
          <ContainedButton
            onClick={() =>
              putStatus(
                `${businessTripsUrl}/${businessTripId}/settle`,
                'BUSINESS_TRIP_STATUS_UPDATE',
              )
            }
          >
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.STATUS.SETTLED',
            })}
          </ContainedButton>
        </ButtonContainer>
      );
    }
  };

  const renderLeaderApprovalButtons = (
    onApprove: () => Promise<void> | void,
    onReject: () => Promise<void> | void,
  ) => (
    <>
      <ButtonContainer item>
        <ContainedButton onClick={onApprove}>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.LEADER_APPROVE',
          })}
        </ContainedButton>
      </ButtonContainer>
      <ButtonContainer item>
        <ContainedButton onClick={onReject} color="secondary">
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.LEADER_REJECT',
          })}
        </ContainedButton>
      </ButtonContainer>
    </>
  );

  const changeStatusIntoDocsToUpdate = (businessTripId: number) => {
    putStatus(
      `${businessTripsUrl}/${businessTripId}/reject`,
      'BUSINESS_TRIP_STATUS_UPDATE',
      reasonMessage,
    );
    toggleHandleOpen();
  };

  const finalRejectTrip = async (businessTripId: number) => {
    await patchStatus(
      `${businessTripsUrl}/${businessTripId}/leader-reject`,
      'REJECTION',
      rejectMessage,
    );
    toggleFinalRejectOpen();
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    setState: (value: SetStateAction<string>) => void,
  ) => {
    setState(e.target.value);
  };

  const handleFinalApproval = useCallback(
    (approved: boolean) => () =>
      approved
        ? patchStatus(
            `${businessTripsUrl}/${id}/leader-final-approve`,
            'APPROVAL',
          )
        : toggleFinalRejectOpen(),
    [id, patchStatus, toggleFinalRejectOpen],
  );

  const handleApproval = useCallback(
    async (approval: boolean) => {
      await putAcceptanceStatus(approval);
      await fetchTrips();
    },
    [fetchTrips, putAcceptanceStatus],
  );

  return (
    <>
      <ActionsContainer container xs={12}>
        {visibleForUser && (
          <ButtonContainer item>
            <ContainedButton onClick={handleToggleCalculatorForm}>
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.CALCULATOR_BUTTON',
              })}
            </ContainedButton>
          </ButtonContainer>
        )}
        {renderButtonToChangeStatus(id)}
        {isSummaryVisible && !visibleForUser && (
          <ButtonContainer item>
            <ContainedButton onClick={handleToggleSummary}>
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.CALCULATOR_SUMMARY_BUTTON',
              })}
            </ContainedButton>
          </ButtonContainer>
        )}
        {isLeaderOfBusinessTrip &&
          status === TripStatuses.TO_BE_APPROVED_BY_LEADER &&
          renderLeaderApprovalButtons(toggleTripBillingOpen, () =>
            handleApproval(false),
          )}
        {isLeaderOfBusinessTrip &&
          status === TripStatuses.TO_BE_FINALLY_APPROVED_BY_LEADER &&
          renderLeaderApprovalButtons(
            handleFinalApproval(true),
            handleFinalApproval(false),
          )}
      </ActionsContainer>
      <DialogWithMessage
        open={open}
        title={intl.formatMessage({
          id: `BUSINESS_TRIP.DIALOG.DOCS_TO_UPDATE.TITLE`,
        })}
        handleClose={toggleHandleOpen}
        handleChange={(e) => handleChange(e, setReasonMessage)}
        onSubmit={() => changeStatusIntoDocsToUpdate(id)}
      />
      <DialogWithMessage
        open={finalRejectOpen}
        title={intl.formatMessage({
          id: `BUSINESS_TRIP.REJECT_REASON`,
        })}
        handleClose={toggleFinalRejectOpen}
        handleChange={(e) => handleChange(e, setRejectMessage)}
        onSubmit={() => finalRejectTrip(id)}
      />
      {tripBillingOpen && (
        <TripBillingDialog
          id={id}
          open={tripBillingOpen}
          handleClose={toggleTripBillingOpen}
          onSubmit={handleApproval}
        />
      )}
      {isReminderVisible && (
        <PopUpReminderDialog
          onClose={() => setDisplayReminder(false)}
          title={'BUSINESS_TRIP.DOCS_REMINDER_TITLE'}
          content={'BUSINESS_TRIP.DOCS_REMINDER_CONTENT'}
        />
      )}
    </>
  );
};

export default BusinessTripDetailsActions;
