import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import englishTranslations from 'translations/english.json';
import polishTranslations from 'translations/polish.json';
import { GlobalContextValue } from './types';

export type Props = {
  children: ReactNode;
};

const polishLocalization = {
  locale: 'pl',
  messages: polishTranslations,
};

const englishLocalization = {
  locale: 'en',
  messages: englishTranslations,
};

export const GlobalContext = createContext<GlobalContextValue>(undefined);

const localStorageLanguage = 'language';

const initLanguage = () => localStorage.getItem(localStorageLanguage) || 'en';

export function GlobalProvider({ children }: Props) {
  const [localization, setLocalization] = useState({
    locales: {
      pl: polishLocalization,
      en: englishLocalization,
    },
    currentLocale: initLanguage(),
  });
  const [dateGlobal, setDateGlobal] = useState<Date | null>(moment().toDate());
  const [logoutURL, setlogoutURL] = useState<string>('');
  const [isLoading] = useState(false);
  moment.locale(localization.currentLocale);

  const setCurrentLocale = useCallback(
    (payload: 'pl' | 'en') => {
      setLocalization({ ...localization, currentLocale: payload });
      moment.locale(payload);
    },
    [localization],
  );

  const value = useMemo(
    () => ({
      isLoading,
      localization,
      setCurrentLocale,
      dateGlobal,
      logoutURL,
      setlogoutURL,
      setDateGlobal,
    }),
    [
      logoutURL,
      setlogoutURL,
      isLoading,
      localization,
      setCurrentLocale,
      dateGlobal,
      setDateGlobal,
    ],
  );

  window.onbeforeunload = () => {
    localStorage.setItem(localStorageLanguage, localization.currentLocale);
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}
