import { IntlShape } from 'react-intl';
import {
  BENEFIT_MEMBER_TYPES,
  BenefitAccountFormValues,
  BenefitManagerFormValues,
  BenefitTypesProps,
  InputNames,
  PersonalData,
  SelectDataWithType,
  TimeRangeData,
} from 'components/BenefitInputs/types';
import { BENEFITS_DATA as BENEFIT_INFO } from 'modules/AdminPanel/Screens/BenefitsManager/mock_data';
import {
  RenderForm,
  State,
} from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import { BENEFIT_PARTNER_TYPES } from 'modules/UserProfile/Screens/Benefits/staticData';
import {
  getBenefitInfoByName,
  getNameToLowerCase,
} from 'modules/UserProfile/Screens/Benefits/utils';
import { AddressData, PartnerType } from './types';

export const checkIfBenefitIncludesInput = (
  inputName: InputNames,
  state: State,
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
  type?: PartnerType,
) => {
  const getBenefit = () => {
    if (state.benefitsData?.benefitName) {
      return getBenefitInfoByName(state.benefitsData.benefitName, benefitsInfo);
    }
  };

  const getInputsToCheck = () => {
    switch (type) {
      case 'partner':
        return 'partnerInputs';
      case 'child':
        return 'childInputs';
      default:
        return 'employeeInputs';
    }
  };

  const isIncludingInputs = () => {
    if (state.renderForm === RenderForm.Benefit) {
      return state.selectedBenefit?.[getInputsToCheck()]?.some(
        (input) => input.name === inputName,
      );
    }

    return false;
  };

  const isIncludingPartnerInputs = () => {
    if (state.renderForm === RenderForm.Partner) {
      return getBenefit()?.partnerInputs?.some(
        (input) => input.name === inputName,
      );
    }
    return false;
  };

  const isIncludingEditableInputs = () => {
    if (state.renderForm === RenderForm.EditData) {
      return getBenefit()?.editableInputs.some(
        (input) => input.name === inputName,
      );
    }
    return false;
  };

  return (
    isIncludingInputs() ||
    isIncludingPartnerInputs() ||
    isIncludingEditableInputs()
  );
};

export const getBenefitTypeOptions = (
  benefitsData?: BenefitAccountFormValues,
) => {
  const filteredBenefitInfo = BENEFIT_INFO.find(
    (benefit) =>
      getNameToLowerCase(benefit.benefitName) ===
      getNameToLowerCase(benefitsData?.benefitName),
  );

  return filteredBenefitInfo?.benefitTypes
    ? filteredBenefitInfo?.benefitTypes.map((data, index) => {
        return { name: data.benefitTypeName, value: index };
      })
    : [];
};

export const isAdultAssigned = (benefitsData?: BenefitAccountFormValues) =>
  benefitsData?.employee.memberType !==
  (BENEFIT_MEMBER_TYPES.child || BENEFIT_MEMBER_TYPES.child_underage);

export const getInputName = (name: keyof PersonalData, index?: number) => {
  if (index || index === 0) {
    return `partners[${index}].${name}` as keyof BenefitAccountFormValues;
  }
  return `employee.${name}` as keyof BenefitAccountFormValues;
};

export const getGenderOptions = (intl: IntlShape) => {
  return [
    {
      name: intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.MALE' }),
      value: 1,
    },
    {
      name: intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.FEMALE' }),
      value: 2,
    },
  ];
};

export const getChildOption = (intl: IntlShape) => {
  return [
    {
      name: intl.formatMessage({ id: 'TABLE.BODY.BENEFIT.CHILD' }),
      value: 2,
      type: BENEFIT_PARTNER_TYPES.child,
    },
  ];
};

export const getPartnerTypeOptions = (intl: IntlShape) => {
  return [
    ...getChildOption(intl),
    {
      name: intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.ADULT' }),
      value: 1,
      type: BENEFIT_PARTNER_TYPES.adult,
    },
  ];
};

export const getPackageOptions = (
  intl: IntlShape,
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
  selectedBenefit?: BenefitManagerFormValues,
  selectedBenefitType?: BenefitTypesProps,
) => {
  const filteredBenefitInfo = benefitsInfo.find(
    (data) => data.benefitName === selectedBenefit?.benefitName,
  );

  const filteredBenefitTypeInfo = filteredBenefitInfo?.benefitTypes.find(
    (benefit) =>
      benefit.benefitTypeName === selectedBenefitType?.benefitTypeName,
  );

  if (filteredBenefitTypeInfo) {
    return Object.entries(filteredBenefitTypeInfo).reduce(
      (acc: SelectDataWithType[], curr, index) => {
        const [key, content] = curr;
        if (content?.active) {
          acc.push({
            name: intl.formatMessage({
              id: `USER_PROFILE.BENEFITS.SELECTED_PACKAGE.${key.toUpperCase()}`,
            }),
            value: index,
            type: key,
          });
        }
        return acc;
      },
      [],
    );
  }
  return [];
};

export const getFormGroupTitles = (intl: IntlShape, index?: number) => {
  const memberId = !!index ? `(${index + 1})` : '';
  return {
    personalData: intl.formatMessage({
      id: 'USER_PROFILE.BENEFITS.FORM.PERSONAL_DATA',
    }),
    partnersData: `${intl.formatMessage({
      id: 'USER_PROFILE.BENEFITS.FORM.PARTNERS_DATA',
    })} ${memberId}`,
    address: intl.formatMessage({ id: 'USER_PROFILE.BENEFITS.FORM.ADDRESS' }),
    crspAddress: intl.formatMessage({
      id: 'USER_PROFILE.BENEFITS.FORM.CRSP_ADDRESS',
    }),
    benefitPackage: intl.formatMessage({
      id: 'TABLE.HEAD.BENEFIT_PACKAGE',
    }),
    benefitType: intl.formatMessage({
      id: 'TABLE.HEAD.BENEFIT_TYPE',
    }),
  };
};

export const getPartnerOptions = (
  renderForm: string | null,
  intl: IntlShape,
  benefitsData?: BenefitAccountFormValues,
  isAdultSelected?: boolean,
) => {
  switch (renderForm) {
    case 'benefit':
      if (isAdultSelected) {
        return getChildOption(intl);
      }
      return getPartnerTypeOptions(intl);
    default:
      if (isAdultSelected || isAdultAssigned(benefitsData)) {
        return getChildOption(intl);
      }
      return getPartnerTypeOptions(intl);
  }
};

export const getAddressData = (
  name: AddressData,
  isCrsp: boolean,
  state: State,
  index?: number,
) => {
  if (index || index === 0) {
    return state.benefitsData?.partners?.[index]?.[
      isCrsp ? 'crspAddress' : 'address'
    ]?.[name];
  } else {
    return state.benefitsData?.employee?.[isCrsp ? 'crspAddress' : 'address']?.[
      name
    ];
  }
};
