import { useIntl } from 'react-intl';
import { Block } from '@material-ui/icons';
import { TableCell, TableRow } from './Table.css';

interface Props {
  colSpan?: number;
  message?: string;
}

const EmptyDataRow = ({ colSpan, message }: Props) => {
  const intl = useIntl();

  return (
    <TableRow>
      <TableCell align={'center'} colSpan={colSpan}>
        <Block />
        <p>{message || intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
      </TableCell>
    </TableRow>
  );
};

export default EmptyDataRow;
