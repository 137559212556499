import { useContext } from 'react';
import { useIntl } from 'react-intl';
import BenefitCheckbox from 'components/BenefitInputs/BenefitCheckbox';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import {
  ChildProperties,
  CostProperties,
  ErrorProperties,
  Packages,
  PartnerProperties,
} from 'components/BenefitInputs/types';
import { getBenefitTypePropertyInput } from 'components/BenefitInputs/utils';
import { validate } from 'utils/formValidation';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import {
  getChildPropertyValue,
  getDefaultCostValue,
  getPartnerPropertyValue,
} from '../../../utils';
import FormTooltip from '../FormTooltip';
import { getCheckboxLabel } from '../inputHelpers';
import { PackageInputsProps } from '../types';

const PackageInputs = ({
  index,
  isActive,
  control,
  errors,
  packageProperty,
}: PackageInputsProps) => {
  const {
    benefitManagerState: {
      formState: { benefitTypes },
    },
  } = useContext(BenefitManagerContext);

  const intl = useIntl();
  const isIndividualPackage = packageProperty === Packages.individual;
  const isFamilyPackage = packageProperty === Packages.family;
  const isPartnerPackage = packageProperty === Packages.partner;

  const getPackageTooltip = () => {
    switch (packageProperty) {
      case Packages.partner:
        return intl.formatMessage({
          id: 'BENEFITS.FORM.PARTNER_PACKAGE_TOOLTIP',
        });
      case Packages.family:
        return intl.formatMessage({
          id: 'BENEFITS.FORM.FAMILY_PACKAGE_TOOLTIP',
        });
      default:
        return undefined;
    }
  };

  const getCostTooltip = () => {
    if (packageProperty === Packages.individual) {
      return undefined;
    }
    return intl.formatMessage({
      id: 'BENEFITS.FORM.PACKAGE_COST_TOOLTIP',
    });
  };

  const getDefaultValue = (childProperty: ChildProperties) => {
    if (isFamilyPackage) {
      return getChildPropertyValue(
        benefitTypes,
        index,
        packageProperty as Packages.family,
        childProperty,
      );
    }
  };

  const getPartnerDefaultValue = (partnerProperty: PartnerProperties) => {
    if (isPartnerPackage) {
      return getPartnerPropertyValue(
        benefitTypes,
        index,
        packageProperty as Packages.partner,
        partnerProperty,
      );
    }
  };

  return (
    <>
      {isIndividualPackage ? (
        <FormTooltip
          id="individualPackageTooltip"
          label="INDIVIDUAL_PACKAGE"
          tooltip="INDIVIDUAL_PACKAGE_TOOLTIP"
        />
      ) : (
        <BenefitCheckbox
          label={`${getCheckboxLabel(packageProperty)}_PACKAGE`}
          name={getBenefitTypePropertyInput(
            CostProperties.active,
            index,
            packageProperty,
          )}
          control={control}
          isFullWidth={false}
          defaultChecked={benefitTypes?.[index]?.[packageProperty]?.active}
          tooltipTitle={getPackageTooltip()}
        />
      )}

      {(isActive || isIndividualPackage) && (
        <>
          <BenefitTextField
            label="COST_WITH_CURRENCY"
            name={getBenefitTypePropertyInput(
              CostProperties.cost,
              index,
              packageProperty,
            )}
            rules={validate(intl).positiveNumber}
            control={control}
            errors={errors}
            defaultValue={getDefaultCostValue(
              benefitTypes,
              index,
              packageProperty,
            )}
            index={index}
            nameOfErrorProperty={ErrorProperties.BenefitTypes}
            tooltipTitle={getCostTooltip()}
          />
          {isFamilyPackage && (
            <>
              <BenefitTextField
                label="NUMBER_OF_CHILDREN"
                name={getBenefitTypePropertyInput(
                  ChildProperties.amount,
                  index,
                  packageProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.amount)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
              <BenefitTextField
                label="MIN_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  ChildProperties.minAge,
                  index,
                  packageProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.minAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
              <BenefitTextField
                label="MAX_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  ChildProperties.maxAge,
                  index,
                  packageProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.maxAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
            </>
          )}
          {isPartnerPackage && (
            <>
              <BenefitTextField
                label="MIN_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  PartnerProperties.minAge,
                  index,
                  packageProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getPartnerDefaultValue(PartnerProperties.minAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
              <BenefitTextField
                label="MAX_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  PartnerProperties.maxAge,
                  index,
                  packageProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getPartnerDefaultValue(PartnerProperties.maxAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PackageInputs;
