import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import ControlledTextField from 'components/ControlledTextField';
import {
  HeaderTooltipText,
  InformationIcon,
  TableIconButton,
} from 'components/Table/Table.css';
import { ErrorProperties, TextFieldProps } from './types';
import {
  checkAddressErrors,
  checkBenefitTypeErrors,
  checkEmployeeErrors,
  checkPartnerErrors,
  checkTimeRangeErrors,
  getPropertyError,
} from './utils';
import { GridItem, TextFieldTooltip } from './BenefitInputs.css';

const BenefitTextField = ({
  label,
  name,
  rules,
  placeholder,
  index,
  defaultValue,
  control,
  errors,
  disabled,
  isMulti,
  handleChange,
  nameOfErrorProperty = ErrorProperties.Employee,
  isOnlyInteger,
  tooltipTitle,
  xs = 12,
  sm = 12,
  md = 6,
  addressType,
}: TextFieldProps) => {
  const partnersErrors = checkPartnerErrors(errors, name, index) as FieldError;
  const benefitTypesErrors = checkBenefitTypeErrors(
    errors,
    name,
    index,
  ) as FieldError;
  const addressErrors = checkAddressErrors(
    errors,
    name,
    addressType,
    index,
  ) as FieldError;

  const timeRangeErrors = checkTimeRangeErrors(errors, name) as FieldError;

  const employeeErrors = checkEmployeeErrors(errors, name);
  const errorsName = errors[name] as FieldError;

  const errorProperty = useMemo(
    () =>
      getPropertyError({
        nameOfErrorProperty,
        partnersErrors,
        addressErrors,
        employeeErrors,
        benefitTypesErrors,
        timeRangeErrors,
      }),
    [
      nameOfErrorProperty,
      partnersErrors,
      addressErrors,
      employeeErrors,
      benefitTypesErrors,
      timeRangeErrors,
    ],
  );
  return (
    <GridItem item xs={xs} sm={sm} md={md}>
      <ControlledTextField
        label={`BENEFITS.FORM.${label}`}
        name={name}
        defaultValue={defaultValue}
        control={control}
        required={rules?.required}
        variant="outlined"
        placeholder={placeholder}
        rules={rules}
        validate={!!(errorsName || errorProperty)}
        helperText={errorsName?.message || errorProperty?.message}
        isDisabled={disabled}
        isMulti={isMulti}
        handleChange={handleChange}
        isOnlyInteger={isOnlyInteger}
      />
      {tooltipTitle && (
        <TextFieldTooltip
          $isMulti={isMulti}
          title={<HeaderTooltipText>{tooltipTitle}</HeaderTooltipText>}
          placement="top"
        >
          <TableIconButton id={`${name}_tooltip`}>
            <InformationIcon />
          </TableIconButton>
        </TextFieldTooltip>
      )}
    </GridItem>
  );
};

export default BenefitTextField;
