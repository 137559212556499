import { IntlShape } from 'react-intl';

const getLanguageOptions = (intl: IntlShape) => [
  {
    value: 'pl',
    label: intl.formatMessage({ id: 'NAVBAR.LANG_SWITCH.POLISH' }),
  },
  {
    value: 'en',
    label: intl.formatMessage({ id: 'NAVBAR.LANG_SWITCH.ENGLISH' }),
  },
];

export default getLanguageOptions;
