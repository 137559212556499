export const generateHeaders = (token: string | null) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', `Bearer ${token}`);
  requestHeaders.set('Accept', '*/*');
  requestHeaders.set(
    'Content-Disposition',
    'attachment; filename="picture.png"',
  );
  return requestHeaders;
};
