export const joinNames = (object: {
  id: number;
  name: string;
  surname: string;
  username: string;
}) => ({
  value: object.id,
  name: `${object.name} ${object.surname}`,
  username: object.username,
});
