import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ControlledTextField from 'components/ControlledTextField/ControlledTextField';
import FilesUploadDialog from 'components/Dialog/FilesUploadDialog/FilesUploadDialog';
import { useToggleState } from 'utils/hooks/useToggleState';
import { leaveTypesInformationNotes } from '../../const';
import {
  FormTypes,
  InfoTypes,
  LeaveRequestFormDataSectionProps,
  LeaveTypes,
} from '../../types';
import LeaveRequestFormDataPicker from './LeaveRequestFormDataPicker';
import {
  AttachmentContainer,
  CheckIcon,
  FileCounter,
  FormSectionTitle,
  HolidayIconButton,
  InfoLink,
  InfoNotesContainer,
  InfoNotesLine,
  UploadFileIcon,
} from '../../HolidaysTab.css';

const LeaveRequestFormDataSection = ({
  control,
  setValue,
  endDate,
  startDate,
  leaveType,
  isAttachmentRequired,
  attachmentsArray,
  setAttachmentsArray,
}: LeaveRequestFormDataSectionProps) => {
  const [isUploadAreaOpen, handleChangeUploadArea] = useToggleState(false);
  const intl = useIntl();

  const renderInformationNote = (leaveType: LeaveTypes) => {
    return leaveTypesInformationNotes.map(({ type, informationNotes }) =>
      leaveType === type
        ? informationNotes.map(({ type, content, isImportant, href }) => {
            switch (type) {
              case InfoTypes.text: {
                return (
                  <InfoNotesLine key={type} isImportant={isImportant}>
                    <CheckIcon />
                    {intl.formatMessage({ id: content })}
                  </InfoNotesLine>
                );
              }
              case InfoTypes.link: {
                return (
                  <InfoLink key={type} href={href}>
                    {intl.formatMessage({ id: content })}
                  </InfoLink>
                );
              }
              default:
                return null;
            }
          })
        : null,
    );
  };

  return (
    <>
      <InfoNotesContainer>
        {renderInformationNote(leaveType)}
      </InfoNotesContainer>
      <FormSectionTitle>
        {intl.formatMessage({ id: 'LEAVE_REQUEST.LABEL.START_DATE' })}
      </FormSectionTitle>
      <LeaveRequestFormDataPicker
        control={control}
        name={FormTypes.StartDate}
        setValue={setValue}
        endDate={endDate}
        startDate={startDate}
        leaveType={leaveType}
      />
      <FormSectionTitle>
        {intl.formatMessage({ id: 'LEAVE_REQUEST.LABEL.END_DATE' })}
      </FormSectionTitle>
      <LeaveRequestFormDataPicker
        control={control}
        name={FormTypes.EndDate}
        setValue={setValue}
        endDate={endDate}
        startDate={startDate}
        leaveType={leaveType}
      />
      <FormSectionTitle>
        {intl.formatMessage({ id: 'LEAVE_REQUEST.LABEL.COMMENT' })}
      </FormSectionTitle>
      <ControlledTextField
        isMulti
        name={FormTypes.Comment}
        control={control}
        variant="outlined"
      />
      {isAttachmentRequired && (
        <>
          <AttachmentContainer container alignItems="center">
            <Grid item xs={4}>
              <FormSectionTitle>
                {intl.formatMessage({
                  id: 'LEAVE_REQUEST.LABEL.ADD_ATTACHMENT',
                })}
              </FormSectionTitle>
            </Grid>
            <Grid item>
              <HolidayIconButton
                id="attachments"
                onClick={handleChangeUploadArea}
                title={intl.formatMessage({
                  id: 'WORK_TIME_TAB.MANAGE_UPLOADED_FILES.TOOLTIP',
                })}
              >
                <UploadFileIcon />
                <FileCounter>{attachmentsArray?.length}</FileCounter>
              </HolidayIconButton>
            </Grid>
          </AttachmentContainer>
          <FilesUploadDialog
            files={attachmentsArray}
            addChecked={isUploadAreaOpen}
            handleAddChanged={handleChangeUploadArea}
            setFiles={setAttachmentsArray}
            canDeleteOnSubmit
          />
        </>
      )}
    </>
  );
};

export default LeaveRequestFormDataSection;
