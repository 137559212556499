import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import palette from '../../../assets/palette';

export const TripSectionContainer = styled(Grid)`
  padding: 2rem 1rem 3rem;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  margin: 2rem 1rem;
`;

export const GridForInsurance = styled(Grid)`
  &.MuiGrid-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
`;

export const SummarySectionContainer = styled.div`
  margin: 2rem 0;
  padding: 1em;
  border: solid 1px ${palette.ACCENT_LIGHT};
  border-radius: 4px;
  position: relative;
  // color: ${palette.ACCENT_DARKEST};
`;

export const RequiredFilesParagraph = styled.p`
  color: ${palette.STATUS_REJECTED};
  font-size: 13px;
  text-align: left;
`;

export const GridLeftAlign = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export const SummarySectionTitle = styled.p`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: -1.9rem;
  background: white;
  padding: 0 15px;
  color: ${palette.ACCENT_LIGHT};
`;

export const DataTypeTitle = styled(Grid)`
  font-weight: 500;
`;
