import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TableArrowIcon } from 'components/Table/Table.css';
import { returnHideIcon, returnShowIcon } from 'components/Table/tableUtils';
import { UseIndexProps } from './types';
import { useToggleState } from './useToggleState';

export function useIndexIcon<T>(data: T): UseIndexProps {
  const [showIcon, handleShow] = useToggleState(false);
  const [iconIndex, setSelectedId] = useState(-1);
  const intl = useIntl();

  const changeIndex = useCallback(
    (id: number) => {
      if (!showIcon) {
        handleShow();
      }
      setSelectedId(showIcon && iconIndex === id ? -1 : id);
    },
    [handleShow, iconIndex, showIcon],
  );

  const resetIndex = useCallback(() => {
    setSelectedId(-1);
  }, []);

  const renderIcon = (index: number) => {
    return (
      <TableArrowIcon id="detailsButton" onClick={() => changeIndex(index)}>
        {showIcon && index === iconIndex
          ? returnHideIcon(intl)
          : returnShowIcon(intl)}
      </TableArrowIcon>
    );
  };

  useEffect(() => {
    resetIndex();
  }, [data, resetIndex]);

  return [showIcon, iconIndex, renderIcon, resetIndex];
}
