import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledTextField from 'components/ControlledTextField';
import { NoteDialogProps, NoteFields, RadioType } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';
import { ContentWrapper } from './NoteDialog.css';

const NoteDialog = ({
  addChecked,
  handleAddChanged,
  onExplanationClick,
  onBatchReminderClick,
  timesheetsId,
  submitButtonLabel,
  headerLabel,
  noteTitle,
  content,
}: NoteDialogProps) => {
  const intl = useIntl();

  const { control, handleSubmit, reset, setValue, trigger } =
    useForm<NoteFields>({
      mode: 'onChange',
    });

  const [actionType, setActionType] = useState<RadioType>('explanation');
  const { note } = useWatch({ control });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setActionType(event.target.value as RadioType);
    setValue(
      'note',
      event.target.value === 'reminder'
        ? intl.formatMessage({ id: 'NOTE_DIALOG.EMPLOYEE_REMINDER_TEMPLATE' })
        : '',
    );
    trigger('note');
  };

  const onSubmit = handleSubmit((formData) => {
    if (timesheetsId && onExplanationClick) {
      onExplanationClick(timesheetsId, formData.note, actionType);
    }
    onBatchReminderClick && onBatchReminderClick(formData.note);

    handleAddChanged();
    reset({
      note: '',
    });
  });

  const getCheckbox = (value: string, id: string) => {
    return (
      <FormControlLabel
        value={value}
        control={
          <Radio
            id={value}
            style={{
              color: 'black',
            }}
          />
        }
        label={intl.formatMessage({ id })}
      />
    );
  };

  useEffect(() => {
    trigger('note');
  }, [trigger]);

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleAddChanged}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary" style={{ padding: '5px 20px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div> {headerLabel} </div>
          <IconButton onClick={handleAddChanged}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {noteTitle !== 'EmployeeMessageFromActions' ? (
              noteTitle
            ) : (
              <RadioGroup
                value={actionType}
                onChange={handleChange}
                style={{ marginLeft: '10px' }}
              >
                {getCheckbox('explanation', 'NOTE_DIALOG.EXPLANATION')}
                {getCheckbox('reminder', 'NOTE_DIALOG.EMPLOYEE_REMINDER')}
              </RadioGroup>
            )}
          </Grid>
        </Grid>
        {content && (
          <ContentWrapper>{content.replace('. ', '.\n')}</ContentWrapper>
        )}
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label={
                  actionType === 'explanation'
                    ? 'NOTE_DIALOG.NOTE'
                    : 'NOTE_DIALOG.REMINDER'
                }
                name="note"
                control={control}
                variant="outlined"
                required={actionType === 'explanation'}
                defaultValue={
                  actionType === 'reminder'
                    ? intl.formatMessage({
                        id: 'NOTE_DIALOG.EMPLOYEE_REMINDER_TEMPLATE',
                      })
                    : ''
                }
                isMulti
              />
            </GridItem>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="submitButton"
            disabled={!note?.trim()}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {submitButtonLabel}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default NoteDialog;
