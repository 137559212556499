import { createContext, ReactNode, useReducer } from 'react';
import { initialState, reducer } from './reducer';
import { BusinessTripsContextProps } from './types';

interface Props {
  children: ReactNode;
}

const initialContextState = {
  dispatch: () => {
    // intentional empty function
  },
  businessTripsManagerState: initialState,
};

const BusinessTripsContext =
  createContext<BusinessTripsContextProps>(initialContextState);
const BusinessTripContextProvider = BusinessTripsContext.Provider;

const BusinessTripsManagerProvider = ({ children }: Props) => {
  const [businessTripsManagerState, dispatch] = useReducer(
    reducer,
    initialState,
  );
  return (
    <BusinessTripContextProvider
      value={{ businessTripsManagerState, dispatch }}
    >
      {children}
    </BusinessTripContextProvider>
  );
};

export { BusinessTripsManagerProvider, BusinessTripsContext };
