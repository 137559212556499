import { useEffect, useState } from 'react';
import {
  ArrowBack,
  ArrowForward,
  Clear as ClearIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { KeyboardDatePickerProps } from './types';
import {
  DatepickerWrapper,
  IconButton,
  KeyboardDatePicker,
  NextButton,
  PrevButton,
} from './Datepicker.css';

const Datepicker = ({
  onClearButtonClick,
  onChange,
  monthJumpButtons,
  fullWidth,
  ...props
}: KeyboardDatePickerProps) => {
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (moment(date).isValid()) {
      onChange(moment(date).toDate());
    }
    // missing dependency onChange crates infinite loop.
    //  eslint-disable-next-line
  }, [date]);

  const addMonth = () => {
    const newDate = moment(`${props.value ? props.value : new Date()}`)
      .add(1, 'month')
      .toDate();
    onChange(newDate);
  };

  const substractMonth = () => {
    const newDate = moment(`${props.value ? props.value : new Date()}`)
      .subtract(1, 'month')
      .toDate();
    onChange(newDate);
  };

  return (
    <DatepickerWrapper style={fullWidth ? { width: '100%' } : undefined}>
      {monthJumpButtons && (
        <>
          <PrevButton
            disabled={props.disabled}
            onClick={substractMonth}
            id="prevButton"
          >
            <ArrowBack />
          </PrevButton>
          <NextButton
            disabled={props.disabled}
            onClick={addMonth}
            id="nextButton"
          >
            <ArrowForward />
          </NextButton>
        </>
      )}
      <IconButton
        disabled={props.disabled}
        size="small"
        onClick={onClearButtonClick}
        id="clearButton"
      >
        <ClearIcon />
      </IconButton>
      <KeyboardDatePicker
        KeyboardButtonProps={{
          'aria-label': 'change date',
          id: 'calendar',
        }}
        views={monthJumpButtons ? ['month'] : undefined}
        inputVariant="outlined"
        format={monthJumpButtons ? 'MM.yyyy' : 'DD.MM.yyyy'}
        //@ts-ignore
        onChange={setDate}
        openTo="date"
        {...props}
      />
    </DatepickerWrapper>
  );
};

export default Datepicker;
