import { FocusEvent } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import TimeIcon from '@material-ui/icons/Schedule';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { returnHourDate } from 'utils/formatters';
import { ControlledTimePickerProps, TimePart } from './types';
import { TimePickerComponent } from './ControlledTimePicker.css';

const ControlledTimePicker = ({
  control,
  label,
  name,
  type,
  required,
  defaultValue,
  isDisabled,
  showMidnight,
  isError,
  id,
  onChange: handleChange,
  onInvalidWorktime,
  handleRefreshAfterTimeChange,
  tooltipMessage,
}: ControlledTimePickerProps) => {
  const intl = useIntl();

  const isValid = (date: Date) => moment(date).isValid();

  const validationRules = {
    required: required,
    validate: isValid,
  };

  const handleChangePicker =
    (onChange: (...event: any[]) => void) => (e: MaterialUiPickersDate) => {
      onChange(e);
      handleChange && handleChange(e);
    };

  const validateTime = (timePart: string, index: number) => {
    const limit = index === TimePart.Hours ? 23 : 59;

    if (Number(timePart) > limit) {
      return limit;
    }

    switch (index) {
      case TimePart.Hours:
        return timePart.padStart(2, '0');

      case TimePart.Minutes:
        return timePart.padEnd(2, '0');
    }
  };

  const handleOnBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void,
  ) => {
    const value = event.target.value;
    const validatedTime = value
      .split(':')
      .map((part: string, index: number) =>
        validateTime(part.replace(/_/g, ''), index),
      )
      .join(':');

    if (value) {
      onChange(returnHourDate(validatedTime));
    }
    if (handleRefreshAfterTimeChange) {
      handleRefreshAfterTimeChange();
    }
    if (onInvalidWorktime) {
      onInvalidWorktime();
    }
  };

  return (
    <Controller
      name={name}
      rules={validationRules}
      control={control}
      type={name}
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: !!required,
        'aria-label': 'time picker',
      }}
      defaultValue={
        moment(defaultValue).isValid() &&
        (showMidnight || moment(defaultValue).format('HH:mm') !== '00:00')
          ? defaultValue
          : null
      }
      render={({ onChange, ...props }) => (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePickerComponent
              {...props}
              ampm={false}
              onBlur={(event) => handleOnBlur(event, onChange)}
              fullWidth
              inputVariant="outlined"
              disabled={isDisabled}
              onChange={handleChangePicker(onChange)}
              type={type}
              helperText=""
              size="small"
              color="primary"
              placeholder={'--:--'}
              minutesStep={30}
              keyboardIcon={<TimeIcon />}
              label={label && intl.formatMessage({ id: label })}
              error={isError}
              id={id}
            />
            {tooltipMessage && (
              <Typography style={{ fontSize: '0.7rem' }}>
                {intl.formatMessage({
                  id: tooltipMessage,
                })}
              </Typography>
            )}
          </MuiPickersUtilsProvider>
        </>
      )}
    />
  );
};

export default ControlledTimePicker;
