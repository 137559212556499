import { ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { timesheetsApproveReminder } from 'router/url';
import { TableIconButton } from 'components/Table/Table.css';
import { postFetch } from 'utils/fetchFunctions';

interface TableButtonIconProps {
  children: ReactNode;
  timesheetID: number;
  setTimesheetNrActionLoading: (el: number | undefined) => void;
  id: string;
  disabled?: boolean;
}

const TableButtonIcon = ({
  children,
  timesheetID,
  setTimesheetNrActionLoading,
  id,
  disabled,
}: TableButtonIconProps) => {
  const intl = useIntl();
  const [isSent, setIsSent] = useState(false);
  const onReminderClick = useCallback(
    async (id: number) => {
      setIsSent(true);
      setTimesheetNrActionLoading(timesheetID);
      await postFetch({
        url: `${timesheetsApproveReminder}?id=${id}`,
        intl,
        label: 'TIMESHEET',
      });
      setTimesheetNrActionLoading(undefined);
      setIsSent(false);
    },
    [intl, setTimesheetNrActionLoading, timesheetID],
  );
  return (
    <TableIconButton
      id={id}
      onClick={() => onReminderClick(timesheetID)}
      disabled={isSent || disabled}
    >
      {children}
    </TableIconButton>
  );
};

export default TableButtonIcon;
