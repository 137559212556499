import { Grid, GridProps } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import palette from 'assets/palette';
import styled from 'styled-components';

export const FileTile = styled.div`
  border-radius: 2rem;
  border: 1px solid #cbcbcb;
  padding: 0.6rem 1.5rem;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: space-between;
  color: #323232;
  display: flex;
`;

export const CheckBoxesFileUpload = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

export const DeleteFileIcon = styled(DeleteIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    margin-left: 0.8rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const GridLoaderItem = styled(Grid)<GridProps>`
  &.MuiGrid-root {
    padding: 1rem;
  }
`;

export const PreviouslyUploadedSubheading = styled.h5``;
