import moment from 'moment';
import {
  BENEFIT_MEMBER_TYPES,
  BenefitAccountFormValues,
  BenefitFetchedData,
  BenefitManagerFormValues,
  BillabilityTypes,
  Partner,
  Status,
  TimeRangeData,
} from 'components/BenefitInputs/types';
import {
  getFormattedName,
  getTimeRangeFormattedData,
} from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { State } from './BenefitContext/types';
import { PartnerType } from './components/BenefitInputs/types';
import { BENEFIT_PARTNER_TYPES } from './staticData';
import {
  BenefitsDataProps,
  BenefitsFetchedData,
  Cost,
  FetchedPackages,
  Packages,
} from './types';

const getArrayElementsToLowerCase = (array: string[]) =>
  array.map((name) => name.toLowerCase().replaceAll('_', ' '));

export const getNameToLowerCase = (name?: string) =>
  name?.toLowerCase().replaceAll('_', ' ');

export const getFilteredBenefitNames = (
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
  benefitsData: BenefitsDataProps[],
) => {
  const benefitsFromTable = getArrayElementsToLowerCase(
    Array.from(new Set(benefitsData?.map((name) => name.benefitName))),
  );

  const activeBenefits = benefitsInfo
    .map((el) => {
      return {
        benefitName: el.benefitName,
        benefitKind: el.benefitCategory.name,
      };
    })
    .filter((name) =>
      benefitsFromTable?.includes(name.benefitName.toLowerCase()),
    );

  return benefitsInfo
    .filter(
      (name) =>
        !activeBenefits.find(
          (benefit) => benefit.benefitKind === name.benefitCategory.name,
        ),
    )
    .filter((benefit) => benefit.status !== Status.inactive)
    .map((name, index) => {
      return {
        ...name,
        name: name.benefitName,
        value: index,
        types: name?.benefitTypes?.map((entry, index) => {
          return {
            ...entry,
            name: entry.benefitTypeName,
            value: index,
          };
        }),
      };
    });
};

export const getBenefitInfoByName = (
  benefitName: string,
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
) => {
  return benefitsInfo.find(
    (benefit) =>
      benefit.benefitName.toUpperCase() === benefitName.toUpperCase(),
  );
};

export const getFirstDayOfMonth = (
  benefitName: string,
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
) => {
  const lastDay = getBenefitInfoByName(
    benefitName,
    benefitsInfo,
  )?.lastDayToSendChanges;
  const formatDate = (amount: number) =>
    moment().add(amount, 'month').startOf('month').format('DD/MM/YYYY');
  if (lastDay) {
    return moment().date() > lastDay ? formatDate(2) : formatDate(1);
  }
};

export const getTotalCost = (
  employeeCost?: Cost,
  partnerCost?: Cost,
  childCost?: Cost,
) => {
  const employee = Number(employeeCost);
  const partner = Number(partnerCost);
  const child = Number(childCost);
  return (employee + partner + child).toFixed(2);
};

export const getSinglePersonBenefitCost = ({
  benefitId,
  benefitTypeId,
  benefitsInfo,
  person,
  billableBy,
}: {
  benefitId: number;
  benefitTypeId: number;
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[];
  person: PartnerType;
  billableBy?: BillabilityTypes;
}) => {
  if (billableBy === BillabilityTypes.person) {
    const chosenBenefitInfo = benefitsInfo
      .find((benefit) => benefit.id === benefitId)
      ?.benefitTypes.find((type) => Number(type.id) === benefitTypeId);

    const personCost = person === 'partner' ? 'partnerCost' : 'childCost';

    const content =
      chosenBenefitInfo &&
      Object.entries(chosenBenefitInfo).reduce((acc, curr) => {
        const [key, value] = curr;
        if (key === personCost && value.cost) {
          acc += value.cost;
        }
        return acc;
      }, 0);

    return content ? `${content} zł` : '';
  }
  return '';
};

export const getBenefitCost = ({
  entry,
  renderPartners,
  benefitsInfo,
}: {
  entry: BenefitsDataProps;
  renderPartners: boolean;
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[];
}) => {
  const benefitInfo = benefitsInfo.find(
    (name) =>
      getNameToLowerCase(name.benefitName) ===
      getNameToLowerCase(entry.benefitName),
  );

  const billableBy = benefitInfo?.billableBy;

  const benefitTypeInfo = benefitInfo?.benefitTypes?.find(
    (type) => type.benefitTypeName === entry.benefitType?.name,
  );

  const getBillableByPackageCost = () => {
    if (
      entry?.benefitPackage &&
      entry.benefitPackage.type !== Packages.notApplicable
    ) {
      const benefitPackage = benefitTypeInfo?.[entry.benefitPackage.type];
      return benefitPackage?.cost?.toFixed(2);
    }
  };

  const getBillableByPersonCost = () => {
    const getBenefitCost = (
      type: 'employeeCost' | 'partnerCost' | 'childCost',
    ) => benefitTypeInfo?.[type]?.cost ?? 0;

    const employeeCost = getBenefitCost('employeeCost');
    const partnerCost = getBenefitCost('partnerCost');
    const childCost = getBenefitCost('childCost');
    const isPartnerAdded = !!entry.partner;
    const areChildrenAdded = !!entry.children;
    const getFinalCost = () => {
      if (isPartnerAdded && areChildrenAdded) {
        return employeeCost + partnerCost + childCost;
      } else if (isPartnerAdded) {
        return employeeCost + partnerCost;
      } else if (areChildrenAdded) {
        return employeeCost + childCost;
      }
      return employeeCost;
    };
    if (!renderPartners) {
      return getFinalCost().toFixed(2);
    }
    return employeeCost.toFixed(2);
  };

  const getContent = () => {
    if (billableBy === BillabilityTypes.package) {
      return getBillableByPackageCost();
    } else if (billableBy === BillabilityTypes.person) {
      return getBillableByPersonCost();
    }
  };

  const content = getContent();

  return { billableBy, cost: content ? `${content} zł` : '' };
};

export const getDataFromBenefitInfo = (
  entry: BenefitsDataProps,
  benefitsInfo: BenefitManagerFormValues<TimeRangeData>[],
) => {
  const filteredBenefitInfo = benefitsInfo.filter(
    (name) =>
      getNameToLowerCase(name.benefitName) ===
      getNameToLowerCase(entry.benefitName),
  )[0];

  const benefitName = filteredBenefitInfo.benefitName;
  const lastDayToSendChanges = filteredBenefitInfo.lastDayToSendChanges;
  const partnerInputs = filteredBenefitInfo.partnerInputs;
  const editableInputs = filteredBenefitInfo.editableInputs;

  return {
    benefitName: benefitName,
    lastDayToSendChanges: lastDayToSendChanges,
    partnerInputs: partnerInputs,
    editableInputs: editableInputs,
  };
};

export const getBenefitTypeInfoByName = (
  benefitsInfo: BenefitManagerFormValues[],
  benefitName: string,
  benefitTypeName?: string,
) => {
  const benefitInfo = benefitsInfo.find(
    (benefit) =>
      benefit.benefitName.toUpperCase() === benefitName.toUpperCase(),
  );
  const benefitTypeInfo = benefitInfo?.benefitTypes.find(
    (value) => value.benefitTypeName === benefitTypeName,
  );
  return { generalInfo: benefitInfo, typeInfo: benefitTypeInfo };
};

export const getActionLabel = (
  label: string,
  options: {
    disabled: boolean;
    memberType?: BENEFIT_MEMBER_TYPES;
    editable?: boolean;
  },
) => {
  if (options.editable) {
    if (options.memberType === BENEFIT_MEMBER_TYPES.employee) {
      return options.disabled ? 'DISABLED_EDIT_ICON' : 'EDIT_ICON';
    }
    return options.disabled
      ? 'DISABLED_PARTNER_EDIT_ICON'
      : 'PARTNER_EDIT_ICON';
  }
  return options.disabled
    ? `DISABLED_${getFormattedName(label)}`
    : getFormattedName(label);
};

export const getFormattedBody = (state: State) => {
  const getBenefitPackage = (benefitsData?: BenefitAccountFormValues) => {
    return state.selectedBenefit?.billableBy === BillabilityTypes.package
      ? benefitsData?.benefitPackage?.name.toUpperCase()
      : 'NOT_APPLICABLE';
  };

  const getPartnerOrChildren = (state: State, type: 'partner' | 'children') => {
    const partner = BENEFIT_MEMBER_TYPES.partner;
    const child = BENEFIT_MEMBER_TYPES.child;
    switch (type) {
      case 'partner':
        return state.benefitsData?.partners?.find(
          (el) => el.memberType === partner,
        );
      case 'children':
        return state.benefitsData?.partners?.filter(
          (el) => el.memberType === child,
        );
    }
  };
  return {
    employee: {
      ...state.benefitsData?.employee,
      memberType: BENEFIT_MEMBER_TYPES.employee,
    },
    benefitId: state.selectedBenefit?.id,
    benefitName: state.selectedBenefit?.benefitName,
    benefitTypeId: state.selectedBenefitType?.id,
    benefitPackage: getBenefitPackage(state.benefitsData),
    benefitType: state.selectedBenefitType?.benefitTypeName,
    partner: getPartnerOrChildren(state, 'partner'),
    children: getPartnerOrChildren(state, 'children'),
  };
};

const getBenefitPackage = (benefitPackage: FetchedPackages) => {
  switch (benefitPackage) {
    case 'INDIVIDUAL':
      return Packages.individual;
    case 'PARTNER':
      return Packages.partner;
    case 'FAMILY':
      return Packages.family;
    case 'NOT_APPLICABLE':
      return Packages.notApplicable;
  }
};

export const getFormattedData = (
  data: BenefitsFetchedData,
): BenefitsDataProps => {
  return {
    ...data,
    benefitPackage: data.benefitPackage
      ? {
          name: data.benefitPackage,
          value: null,
          type: getBenefitPackage(data.benefitPackage as FetchedPackages),
        }
      : undefined,
    benefitType: data.benefitType
      ? { name: data.benefitType, value: null }
      : undefined,
    benefitStatus: {
      name: data.status.name,
      type: data.status.name,
      value: null,
    },
  };
};

export const getFormattedBenefitInfoData = (
  data: BenefitFetchedData,
): BenefitManagerFormValues => ({
  ...data,
  employeeInputs: data?.mandatoryEmployeeInputs,
  partnerInputs: data?.mandatoryPartnerInputs,
  childInputs: data?.mandatoryChildInputs,
  timeToChangeToLowerType: getTimeRangeFormattedData(
    data.timeToChangeToLowerType,
  ),
  timeToChangeToHigherType: getTimeRangeFormattedData(
    data.timeToChangeToHigherType,
  ),
  timeToChangeToLowerPackage: getTimeRangeFormattedData(
    data.timeToChangeToLowerPackage,
  ),
  timeToChangeToHigherPackage: getTimeRangeFormattedData(
    data.timeToChangeToHigherPackage,
  ),
  timeToAddPartner: getTimeRangeFormattedData(data.timeToAddPartner),
  timeToRemovePartner: getTimeRangeFormattedData(data.timeToRemovePartner),
});

export const displayFormattedDate = (date?: string) =>
  date ? date.replaceAll('-', '/') : '-';

export const getPartnerMemberType = (partner: Partner) => {
  switch (partner.partnerType?.type) {
    case BENEFIT_PARTNER_TYPES.adult:
      return BENEFIT_MEMBER_TYPES.partner;
    case BENEFIT_PARTNER_TYPES.child:
      return BENEFIT_MEMBER_TYPES.child;
  }
};
