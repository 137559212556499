const palette = {
  ACCENT_LIGHTEST: '#F8F8FA',
  ACCENT_LIGHT: '#B0B1B5',
  ACCENT_DARKER: '#77787A',
  ACCENT_DARKEST: '#4D4D4F',
  ACCENT_RED: '#FEE7E7',
  ACTION: '#FFC703',
  ACTION_LIGHTER: '#FFCF4F',
  ACTION_DARK: '#FFC003',
  ACTION_DARKEST: '#DAA520',
  STATUS_APPROVED: '#22BE31',
  STATUS_REJECTED: '#FF3636',
  ACCENT_WHITE: '#fff',
  ACTION_RED: '#FF0F39',
};

export default palette;
