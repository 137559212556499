import ControlledRadioInput from 'components/ControlledRadioInput/ControlledRadioInput';
import { RadioInputProps } from './types';
import { GridItem } from './BenefitInputs.css';

const BenefitRadioInput = ({
  xs = 12,
  sm,
  md,
  name,
  control,
  defaultValue,
  row,
  data,
}: RadioInputProps) => {
  return (
    <GridItem item xs={xs} sm={sm} md={md}>
      <ControlledRadioInput
        name={name}
        control={control}
        defaultValue={defaultValue}
        row={row}
        data={data}
      />
    </GridItem>
  );
};

export default BenefitRadioInput;
