import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { ContainedButton } from 'components/Button';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
} from 'components/Dialog/Dialog.css';
import { ContentDialogProps } from './ContentDialog.types';

export const ContentDialog = ({
  isOpen,
  setIsOpen,
  headerTitle,
  children,
  confirmButtonContent = 'MODAL.CONFIRMATION',
  cancelButtonContent = 'MODAL.BUTTON.CANCEL',
  handleConfirm,
  disableConfirmButton,
}: ContentDialogProps) => {
  const intl = useIntl();

  const handleCloseDialog = () => setIsOpen(false);

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleCloseDialog}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justifyContent="flex-start">
            {intl.formatMessage({
              id: headerTitle,
            })}
          </Grid>
          <Grid xs={12} sm={2} item container justifyContent="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>{children}</DialogContentWrapper>
      <DialogActionWrapper>
        <Grid xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleCloseDialog}
            size="large"
            color="secondary"
          >
            {intl.formatMessage({ id: cancelButtonContent })}
          </ContainedButton>
        </Grid>
        <Grid xs={12} item>
          <ContainedButton
            id="confirmButton"
            disabled={disableConfirmButton}
            fullWidth
            onClick={handleConfirm}
            size="large"
          >
            {intl.formatMessage({
              id: confirmButtonContent,
            })}
          </ContainedButton>
        </Grid>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};
