import { Dispatch, SetStateAction } from 'react';
import { projectsOrderedByNameUrl } from 'router/url';
import { Data } from 'components/Dialog/types';
import { ProjectDataProps } from 'modules/ManagerForm/types';
import { getFetch } from './fetchFunctions';

export const fetchAllProjects = async (
  setProjectData: Dispatch<SetStateAction<Data[]>>,
) => {
  const projectsResponse = await getFetch({
    url: projectsOrderedByNameUrl,
  });
  if (projectsResponse) {
    const projectData = projectsResponse.map(
      ({
        id: value,
        projectName: name,
        client: { clientName },
      }: ProjectDataProps) => ({
        value,
        name: `${name} (${clientName})`,
      }),
    );
    setProjectData(projectData);
  }
};
