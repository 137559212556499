import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Modal, { ModalProps } from '@material-ui/core/Modal';
import TypographyComponent, {
  TypographyProps,
} from '@material-ui/core/Typography';
import palette from 'assets/palette';
import styled from 'styled-components';

export const ApprovalButton = styled(Button)<ButtonProps>`
  &.MuiButton-contained {
    background-color: ${palette.ACTION};
    width: 40%;
  }
  &.MuiButton-contained:hover {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const CancelButton = styled(Button)<ButtonProps>`
  &.MuiButton-contained {
    background-color: ${palette.ACCENT_WHITE};
    width: 40%;
  }
  &.MuiButton-contained:hover {
    background-color: #ffffff82;
  }
`;

export const ModalBox = styled.div`
  position: absolute;
  top: calc(50% - 10vh);
  left: calc(50% - 15vw);
  background-color: black;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  color: white;
  justify-content: space-around;
  align-items: center;
  width: 30vw;
  height: 20vh;
`;

export const ModalButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const ModalComponent = styled(Modal)<ModalProps>``;

export const Typography = styled(TypographyComponent)<TypographyProps>`
  &.MuiTypography-body1 {
    letter-spacing: 0.04em;
  }
`;

export const Loading = styled(CircularProgress)<CircularProgressProps>``;
