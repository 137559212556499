import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Cancel, Save } from '@material-ui/icons';
import { ModalContext } from 'globalContext/ModalContext';
import {
  ApprovalButton,
  CancelButton,
  Loading,
  ModalBox,
  ModalButtonBox,
  ModalComponent,
} from './Modal.css';

const Modal = () => {
  const {
    modalData: { visible, payload, loading },
    closeModal,
    onApprove,
  } = useContext(ModalContext);

  const intl = useIntl();

  const agree = () => {
    onApprove();
  };
  return (
    <ModalComponent open={visible} onClose={closeModal}>
      <ModalBox>
        {loading ? (
          <Loading />
        ) : (
          <>
            {payload.content}
            <ModalButtonBox>
              <CancelButton
                variant="contained"
                startIcon={<Cancel />}
                onClick={closeModal}
              >
                {' '}
                {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
              </CancelButton>
              <ApprovalButton
                variant="contained"
                startIcon={<Save />}
                onClick={agree}
              >
                {intl.formatMessage({ id: 'MODAL.BUTTON.SUBMIT' })}
              </ApprovalButton>
            </ModalButtonBox>
          </>
        )}
      </ModalBox>
    </ModalComponent>
  );
};
export default Modal;
