import { useIntl } from 'react-intl';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { PopUpProps } from './types';
import {
  CloseIcon,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
} from '../Dialog.css';

const PopUpReminderDialog = ({ onClose, title, content }: PopUpProps) => {
  const intl = useIntl();

  const handleClose = () => onClose(false);

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {intl.formatMessage({ id: title })}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <Grid style={{ margin: '2rem' }}>
          <Typography style={{ fontSize: '1.5rem' }}>
            {intl.formatMessage({ id: content })}
          </Typography>
        </Grid>
      </DialogContentWrapper>
    </DialogWrapper>
  );
};
export default PopUpReminderDialog;
