import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import moment from 'moment';
import ControlledDatePicker from 'components/ControlledDatePicker';
import ControlledDateTimePicker from 'components/ControlledDateTimePicker';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import { FilledTextFieldComponent } from 'components/ControlledTextField/ControlledTextField.css';
import { GridItem, GridWrapperGapped } from 'components/Dialog/Dialog.css';
import { getFormattedName } from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { fetchCountriesDietsList } from 'modules/BusinessTrips/Dialog/api';
import {
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
} from 'modules/BusinessTrips/Dialog/constants';
import {
  CountryDiet,
  TripCalculatorSectionProps,
} from 'modules/BusinessTrips/types';
import { validate } from 'utils/formValidation';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { ActionKind } from '../TripCalculatorContext/types';
import { renderErrorMessageForRequiredFields } from '../utils';
import { FormSectionHeading } from '../BusinessTripCalculator.css';

export const GeneralInfoSection = ({
  control,
  errors,
}: TripCalculatorSectionProps) => {
  const intl = useIntl();
  const {
    state: { calculatorFormValues, isEmptyCalculation },
    dispatch,
  } = useContext(TripCalculatorContext);

  const [countriesDiets, setCountriesDiets] = useState<CountryDiet[]>([]);
  const yesterdaysDate = moment().subtract(1, 'days').toDate();
  const countriesDietsOptions = countriesDiets.map((el) => ({
    value: el.id,
    engName: el.name,
    currency: el.currency,
    name: intl.formatMessage({
      id: `COUNTRY_DIET.${getFormattedName(el.name)}`,
    }),
  }));

  const defaultCountry = countriesDietsOptions.find(
    (country) => country.engName === DEFAULT_COUNTRY,
  );

  const start = useWatch({
    control,
    name: 'actualStartTime',
    defaultValue: calculatorFormValues?.actualStartTime,
  });

  const selectedCountryDiet = useWatch({
    control,
    name: 'countryDietSelection',
    defaultValue: defaultCountry,
  });

  const getDestination = () => {
    if (calculatorFormValues?.foreignBusinessTrip) {
      return calculatorFormValues?.country;
    }
    return `${calculatorFormValues?.country}, ${calculatorFormValues?.city}`;
  };

  useEffect(() => {
    if (!calculatorFormValues?.countryDietPresent) {
      fetchCountriesDietsList(setCountriesDiets);
    }
  }, [calculatorFormValues?.countryDietPresent]);

  useEffect(() => {
    if (!calculatorFormValues?.countryDietPresent) {
      dispatch({ type: ActionKind.ClearCurrency });
      if (
        selectedCountryDiet?.currency &&
        selectedCountryDiet?.currency !== DEFAULT_CURRENCY[0].name
      ) {
        dispatch({
          type: ActionKind.AddCurrency,
          payload: {
            currency: { value: 1, name: selectedCountryDiet?.currency },
          },
        });
      }
    }
  }, [
    selectedCountryDiet?.currency,
    calculatorFormValues?.countryDietPresent,
    dispatch,
  ]);

  return (
    <>
      <GridItem xs={12} item>
        <FormSectionHeading>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.CALCULATOR_DESCRIPTION',
          })}
        </FormSectionHeading>
      </GridItem>
      {!isEmptyCalculation && (
        <GridItem xs={12} item>
          <FilledTextFieldComponent
            fullWidth
            disabled
            variant="outlined"
            color="primary"
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.ACCORDION.DESTINATION',
            })}
            value={getDestination()}
          />
        </GridItem>
      )}
      <GridItem xs={12} item>
        <ControlledDateTimePicker
          name="actualStartTime"
          control={control}
          label={intl.formatMessage({
            id: 'QUICK_REPORT.START_DATE',
          })}
          defaultValue={calculatorFormValues?.actualStartTime ?? null}
          view="date"
          rules={validate(intl).date}
          errorMessage={errors?.actualStartTime?.message}
        />
      </GridItem>
      <GridItem xs={12} item>
        <ControlledDateTimePicker
          name="actualEndTime"
          control={control}
          label={intl.formatMessage({
            id: 'QUICK_REPORT.END_DATE',
          })}
          minDate={start}
          defaultValue={calculatorFormValues?.actualEndTime ?? null}
          view="date"
          rules={validate(intl).date}
          errorMessage={errors?.actualEndTime?.message}
        />
      </GridItem>
      {isEmptyCalculation && (
        <GridItem xs={12} item>
          <ControlledDatePicker
            name="exchangeRateDate"
            control={control}
            label={intl.formatMessage({
              id: 'BUSINESS_TRIP.EMPTY_CALCULATOR_EXCHANGE_DATE',
            })}
            minDate={start}
            maxDate={yesterdaysDate}
            defaultValue={yesterdaysDate}
            view="date"
            rules={validate(intl).date}
            errorMessage={errors?.exchangeRateDate?.message}
          />
        </GridItem>
      )}
      <GridItem item xs={12}>
        {calculatorFormValues?.countryDietPresent ? (
          <GridWrapperGapped container wrap="nowrap" justify="space-between">
            <GridItem xs={8}>
              <FilledTextFieldComponent
                fullWidth
                disabled
                variant="outlined"
                color="primary"
                label={intl.formatMessage({
                  id: 'BUSINESS_TRIP.DIET',
                })}
                value={calculatorFormValues?.countryDietValue}
              />
            </GridItem>
            <GridItem xs={4}>
              <FilledTextFieldComponent
                fullWidth
                disabled
                variant="outlined"
                color="primary"
                label={intl.formatMessage({
                  id: 'BUSINESS_TRIP.CURRENCIES',
                })}
                value={calculatorFormValues?.countryDietCurrency}
              />
            </GridItem>
          </GridWrapperGapped>
        ) : (
          <ControlledSelect
            label="BUSINESS_TRIP.COUNTRY*"
            name="countryDietSelection"
            control={control}
            options={countriesDietsOptions}
            errorMessage={
              errors?.countryDietSelection &&
              renderErrorMessageForRequiredFields(intl)
            }
            isClearable={true}
            required
          />
        )}
      </GridItem>
    </>
  );
};

export default GeneralInfoSection;
