import { IconButton } from '@material-ui/core';
import { TooltipProps } from './Tooltip.types';
import { StyledTooltip } from './Tooltip.css';

export const Tooltip = ({ hoverText, icon, id }: TooltipProps) => {
  return (
    <StyledTooltip className={id} title={hoverText} arrow placement="top">
      <IconButton>{icon}</IconButton>
    </StyledTooltip>
  );
};
