import { TooltipProps } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions, {
  DialogActionsProps,
} from '@material-ui/core/DialogActions';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import { Close } from '@material-ui/icons';
import palette from 'assets/palette';
import styled from 'styled-components';
import { Tooltip } from 'components/Table/Table.css';

export const GridWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    padding-top: 0.5rem;
  }
`;

export const GridItem = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    padding: 0.5rem;
    width: 100vw;
  }
`;

export const TimePickerWrapper = styled.div`
  margin-top: 15px;
  input {
    height: 25px;
  }
  button {
    display: block;
  }
`;

export const DialogWrapper = styled(Dialog)<DialogProps>`
  &.MuiPaper-root {
    text-align: center;
  }
`;

export const DialogContentWrapper = styled(DialogContent)<DialogContentProps>`
  &.MuiDialogContent-root {
    padding: 0.5rem;
  }
`;

export const DialogActionWrapper = styled(DialogActions)<DialogActionsProps>`
  &.MuiDialogActions-root {
    padding: 0.5rem;
  }
`;

export const DialogTitleWrapper = styled(DialogTitle)<DialogTitleProps>`
  &.MuiDialogTitle-root {
    color: #000;
    background-color: ${palette.ACTION_LIGHTER};
    text-align: left;
  }
`;

export const CloseIcon = styled(Close)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARKEST};
    }
  }
`;

export const DialogParagraph = styled('p')`
  white-space: pre-wrap;
  margin-right: 0.8em;
  margin-left: 0.8em;
`;

export const TooltipForCheckboxBox = styled(Grid)`
  &.MuiGrid-item {
    padding: 0.5rem;
    text-align: left;
    display: flex;
    width: 360px;
  }
`;
export const TooltipForCheckbox = styled(Tooltip)<TooltipProps>`
  &.MuiIconButton-root {
    margin-left: -0.5rem;
  }
`;

export const ParagraphReminder = styled.p`
  font-size: 13px;
  margin: 0.4rem 0;
  margin-top: 20px;
  margin-left: 15px;
  color: ${palette.ACTION_DARK};
`;

export const AlertParagraph = styled.p`
  color: ${palette.STATUS_REJECTED};
  font-size: 0.7rem;
`;
