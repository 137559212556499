import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { components, OptionProps, StylesConfig } from 'react-select';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core/';
import FormHelperText from '@material-ui/core/FormHelperText';
import palette from 'assets/palette';
import ToolTipCell from 'components/ToolTipCell';
import { ControllerSelectProps } from './types';

const generateCustomStyles = (
  mediumBreakpoint: boolean,
  isSmall = false,
  isHidden = false,
  isError = false,
  isNarrow = false,
  isPadding = true,
  isWider = false,
) => {
  const getControlBorder = (isFocused: boolean) => {
    if (isFocused) {
      return `1px solid ${palette.ACTION}`;
    }
    if (isError) {
      return '1px solid #f44336';
    }
    return '1px solid #ddd';
  };

  const getPlaceholderColor = (isDisabled: boolean) => {
    if (isDisabled) {
      return '#999';
    }
    if (isError) {
      return '#f44336';
    }
    return '#000';
  };

  const customStyles: StylesConfig = {
    container: () => ({
      paddingBottom: isPadding ? 5 : 0,
      visibility: isHidden ? 'hidden' : 'visible',
      width: isWider ? 250 : 'auto',
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: '150px',
      minHeight: isSmall ? undefined : 56,
      border: getControlBorder(state.isFocused),
      textAlign: 'start',
      boxShadow: state.isFocused ? `0 0 0 1px ${palette.ACTION}` : 'none',
      '&:hover': {
        border: state.isDisabled ? `1px solid #ddd` : `1px solid #000`,
      },
      width: isNarrow && !mediumBreakpoint ? '65%' : '100%',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      '&:hover': {
        color: state.isDisabled ? '#ccc' : 'none',
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: getPlaceholderColor(state.isDisabled),
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 11,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return customStyles;
};

const CustomSelectOption = (props: OptionProps<any>) => {
  const intl = useIntl();
  const { Option } = components;
  const { data } = props;

  return (
    <Option {...props}>
      <Grid container justifyContent="space-between">
        <Grid item>{data.name}</Grid>
        {data?.tooltipMessage && (
          <Grid item>
            <ToolTipCell
              tooltip={intl.formatMessage({ id: data.tooltipMessage })}
              styles={{
                cellTooltipStyles: {
                  height: '100%',
                },
              }}
              shouldCoverSelectBox
            />
          </Grid>
        )}
      </Grid>
    </Option>
  );
};

const ControlledSelect = <T extends object>({
  control,
  options,
  label,
  name,
  isMulti,
  isDisabled,
  required,
  rules,
  helperText,
  errorMessage,
  defaultValue,
  multiDefaultValue,
  isSmall,
  isHidden,
  isNarrow,
  isPadding,
  isWider,
  menuPlacement = 'auto',
  isClearable = true,
  handleChange,
  errorMessageStyle,
  noOptionMessage,
  isOptionDisabled,
  maxMenuHeight = 200,
  isTooltipEnabled,
  id,
}: ControllerSelectProps<T>) => {
  const intl = useIntl();

  const handleCustomSelectChange = useCallback(
    (e, onChange) => {
      onChange(e);
      handleChange && handleChange(e);
    },
    [handleChange],
  );
  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {helperText && (
        <FormHelperText>{intl.formatMessage({ id: label })}</FormHelperText>
      )}

      <Controller
        rules={{ ...rules, required }}
        name={name}
        control={control}
        type={name}
        defaultValue={defaultValue || multiDefaultValue || null}
        as={(props) => (
          <Select
            {...props}
            styles={generateCustomStyles(
              mediumBreakpoint,
              isSmall,
              isHidden,
              !!errorMessage?.length,
              isNarrow,
              isPadding,
              isWider,
            )}
            getOptionLabel={(option: any) => option.name}
            noOptionsMessage={() =>
              noOptionMessage ||
              intl.formatMessage({ id: 'TABLE_FILTER.NO_OPTION' })
            }
            placeholder={intl.formatMessage({ id: label })}
            isDisabled={isDisabled}
            isClearable={
              name === 'client' && options.length === 1 ? false : isClearable
            }
            isMulti={isMulti}
            maxMenuHeight={maxMenuHeight}
            menuPlacement={menuPlacement}
            menuPortalTarget={document.body}
            options={options}
            onChange={(e) => handleCustomSelectChange(e, props.onChange)}
            isOptionDisabled={isOptionDisabled}
            inputId={id || name}
            components={isTooltipEnabled && { Option: CustomSelectOption }}
            menuShouldBlockScroll
          />
        )}
      />
      {errorMessage && (
        <FormHelperText error style={errorMessageStyle}>
          {intl.formatMessage({ id: errorMessage })}
        </FormHelperText>
      )}
    </>
  );
};

export default ControlledSelect;
