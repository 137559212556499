import { Dispatch, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Typography, useMediaQuery } from '@material-ui/core';
import JIT_LOGO from 'assets/images/jit_logo.svg';
import palette from 'assets/palette';
import { GlobalContext } from 'globalContext/GlobalContext';
import { DrawerLinkType } from 'components/Drawer/types';
import Select from 'components/Select';
import { capitalizeFirstLetter } from 'utils/formatters';
import getLanguageOptions from 'utils/getLanguageOptions';
import { UserContext } from './../../globalContext/UserContext';
import AccountMenu from './AccountMenu';
import AppsMenu from './AppsMenu';
import * as Styles from './Header.css';

interface Props {
  onLogOut: () => void;
  token: string | null;
  setOpenMenu: Dispatch<React.SetStateAction<boolean>>;
  data: readonly DrawerLinkType[];
}

function Header({ onLogOut, token, setOpenMenu, data }: Props) {
  const {
    localization: { currentLocale },
    setCurrentLocale,
  } = useContext(GlobalContext);
  const { username } = useContext(UserContext);
  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);
  const [appsAnchor, setAppsAnchor] = useState<null | HTMLElement>(null);

  const intl = useIntl();
  const location = useLocation();

  const minWidth1000 = useMediaQuery(`(min-width: 1000px)`);
  const minWidth720 = useMediaQuery(`(min-width: 720px)`);
  const minWidth650 = useMediaQuery(`(min-width: 650px)`);

  const handleAccountClick = (event: React.MouseEvent<HTMLElement>) =>
    setAccountAnchor(event.currentTarget);

  const handleAccountClose = useCallback(() => setAccountAnchor(null), []);

  const handleAppsClick = (event: React.MouseEvent<HTMLElement>) =>
    setAppsAnchor(event.currentTarget);

  const handleAppsClose = useCallback(() => setAppsAnchor(null), []);

  const handleMenuClick = () => setOpenMenu((prevState) => !prevState);

  const handleLogOut = useCallback(() => {
    handleAccountClose();
    onLogOut();
  }, [handleAccountClose, onLogOut]);

  const { ENVIRONMENT_NAME } = window._env_;

  return (
    <Styles.HeaderWrapper>
      <Styles.HeaderBox>
        <Styles.StyledAccountButton
          id="userButton"
          aria-label="upload picture"
          onClick={handleAccountClick}
        >
          <Styles.AccountIcon />
        </Styles.StyledAccountButton>
        <Styles.StyledAppsButton
          id="appsButton"
          aria-label="apps"
          onClick={handleAppsClick}
          $token={!!token}
        >
          <Styles.AppsIcon />
        </Styles.StyledAppsButton>
        <Styles.LogoContainer to="/">
          <Styles.LogoImage src={JIT_LOGO} id="logoImage" />
          <Styles.AppTitle id="appTitle">
            {intl.formatMessage({ id: 'HEADER.APP_TITLE' })}
          </Styles.AppTitle>
          {ENVIRONMENT_NAME === 'test' && (
            <div
              style={{
                backgroundColor: 'red',
                marginLeft: '1rem',
                borderRadius: '50%',
                padding: '8px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <Typography variant="h5">TEST</Typography>
            </div>
          )}
        </Styles.LogoContainer>

        {!minWidth1000 && (
          <Styles.StyledMenuButton
            id="menuButton"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <Styles.MenuIcon />
          </Styles.StyledMenuButton>
        )}
        {token && (
          <>
            {minWidth1000 && (
              <Styles.StyledTabs
                variant="scrollable"
                scrollButtons="on"
                value={false}
              >
                {data.map(({ label, to, id }, index) => (
                  <Styles.StyledNavLink
                    color={location.pathname === to ? palette.ACTION : ''}
                    key={`${label} - ${to} - ${index}`}
                    to={to}
                    id={id}
                  >
                    {intl.formatMessage({ id: label })}
                  </Styles.StyledNavLink>
                ))}
              </Styles.StyledTabs>
            )}
            <>
              {minWidth650 && (
                <Styles.SelectWrapper>
                  <Select
                    onChange={(value: string) => setCurrentLocale(value)}
                    options={getLanguageOptions(intl)}
                    value={currentLocale}
                    id="languageSelect"
                    $isSmall
                  />
                </Styles.SelectWrapper>
              )}
              {/* {minWidth650 ? (
                <Styles.ButtonWrapper> */}
              {/* Hidden for time it will be fixed */}
              {/* <ContainedButton
                    id="reportBugButton"
                    endIcon={<BugReportIcon />}
                    className="bugReportButton"
                    size="small"
                  >
                    {intl.formatMessage({ id: 'REPORTING_BUGS_BUTTON' })}
                  </ContainedButton> */}
              {/* </Styles.ButtonWrapper>
              ) : (
                <Styles.StyledBugButton
                  id="reportBugButton"
                  aria-label="bugButton"
                  className="bugReportButton"
                >
                  <Styles.StyledBugReportIcon />
                </Styles.StyledBugButton>
              )} */}
            </>

            {minWidth720 && (
              <Styles.UsernameContainer to="/profile">
                <Styles.Username id="username">
                  {capitalizeFirstLetter(username)}
                </Styles.Username>
              </Styles.UsernameContainer>
            )}
            <AccountMenu
              onClick={handleAccountClose}
              handleLogOut={handleLogOut}
              anchorEl={accountAnchor}
            />
            <AppsMenu onClick={handleAppsClose} anchorEl={appsAnchor} />
          </>
        )}
      </Styles.HeaderBox>
    </Styles.HeaderWrapper>
  );
}

export default Header;
