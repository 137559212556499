import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Block } from '@material-ui/icons';
import { WORK_TIME_FULL_REPORT_HEAD_DATA } from './consts';
import { TableCell, TableRow } from './Table.css';

const EmptyRow = (): ReactElement => {
  const intl = useIntl();
  return (
    <TableRow>
      <TableCell
        align={'center'}
        colSpan={WORK_TIME_FULL_REPORT_HEAD_DATA.length}
      >
        <Block />
        <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
      </TableCell>
    </TableRow>
  );
};

export default EmptyRow;
