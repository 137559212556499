import { createContext, ReactNode, useReducer } from 'react';
import { initialState, reducer } from './reducer';
import { BenefitContextProps } from './types';

interface Props {
  children: ReactNode;
}

const initialContextState = {
  dispatch: () => {
    // intentional empty function
  },
  benefitManagerState: initialState,
};

const BenefitManagerContext =
  createContext<BenefitContextProps>(initialContextState);
const BenefitContextProvider = BenefitManagerContext.Provider;

const BenefitManagerProvider = ({ children }: Props) => {
  const [benefitManagerState, dispatch] = useReducer(reducer, initialState);
  return (
    <BenefitContextProvider value={{ benefitManagerState, dispatch }}>
      {children}
    </BenefitContextProvider>
  );
};

export { BenefitManagerProvider, BenefitManagerContext };
