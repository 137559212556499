import { IconButtonProps, Paper, SvgIconProps } from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import palette from 'assets/palette';
import styled from 'styled-components';
import { TableIconButton } from 'components/Table/Table.css';

export const NotesWrapper = styled(Paper)`
  &.MuiPaper-root {
    margin-top: 10px;
    width: 50vw;
  }
`;

export const DetailsWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    display: flex;
    flex-direction: column;
  }
`;

export const UserIcon = styled(AccountCircleIcon)<SvgIconProps>`
  &.MuiSvgIcon-root {
    transform: translateY(0.4rem);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    transition: 0.25s ease-in-out;
    color: ${palette.ACTION_DARKEST};
    padding-right: 0.5rem;
  }
`;

export const TypographyUser = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    color: ${palette.ACTION_DARKEST};
    padding: 0.5rem;
  }
`;

export const TypographyPrimary = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    color: rgb(0 0 0);
    max-width: 90%;
    @media (max-width: 1000px) {
      max-width: 85%;
    }
  }
`;

export const TypographyDescription = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    color: rgb(0 0 0);
    text-align: left;
    flex-basis: 100%;
  }
`;

export const TypographySecondary = styled(Typography)<TypographyProps>`
  &.MuiTypography-root {
    color: rgb(180, 180, 180);
    position: absolute;
    right: 50px;
  }
`;

export const EmptyValueWrapper = styled.div`
  margin-top: 10px;
`;

export const ActionIcons = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
`;

interface TooltipIconProps extends IconButtonProps {
  isPaddingLeft?: boolean;
}

export const StyledTableIconButton = styled(TableIconButton)<TooltipIconProps>`
  &.MuiIconButton-root {
    padding: 0.5rem 0 0 1rem;
    padding-left: ${({ isPaddingLeft }) => (isPaddingLeft ? '0.5rem' : 0)};
    align-self: flex-end;
  }
`;

export const NoteContentWrapper = styled.div`
  display: flex;
  padding-top: 0.5rem;
  justify-content: flex-end;
`;
