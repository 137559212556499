import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { leaveRequestUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import { postFetch } from 'utils/fetchFunctions';
import {
  LEAVE_TYPES_WITH_ATTACHEMNT,
  MAIN_LEAVE_REQUESTS_OPTIONS,
  MEDICAL_LEAVE_REQUESTS_OPTIONS,
  OTHER_LEAVE_REQUESTS_OPTIONS,
  STANDARD_LEAVE_REQUESTS_OPTIONS,
} from '../../const';
import {
  FormDataProps,
  FormTypes,
  HolidayTypesOptions,
  LeaveRequestFormProps,
  LeaveTypes,
  MainHolidayTypes,
  RequestDataTypes,
} from '../../types';
import LeaveRequestFormDataSection from './LeaveRequestFormDataSection';
import LeaveRequestSelect from './LeaveRequestSelect';
import { FormSubmit, FormWrapper } from '../../HolidaysTab.css';

const LeaveRequestForm = ({ onClose }: LeaveRequestFormProps) => {
  const [mainLeaveOption, setMainLeaveOption] = useState<MainHolidayTypes>();
  const [detailedLeaveOption, setDetailedLeaveOption] = useState<LeaveTypes>();
  const [attachementIsRequierd, setAttachementIsRequierd] = useState(false);
  const [attachmentsArray, setAttachmentsArray] = useState<File[]>([]);

  const intl = useIntl();

  const { control, reset, handleSubmit, getValues, setValue } =
    useForm<FormDataProps>({
      defaultValues: {
        startDate: null,
        endDate: null,
        leaveType: null,
        leaveTypeCategory: null,
        comment: null,
      },
    });
  const { startDate, endDate, leaveType, comment }: FormDataProps = useWatch({
    control,
  }) as FormDataProps;

  const onSubmit = handleSubmit(async () => {
    const newRequest = {
      dateFrom: moment(startDate),
      dateTo: moment(endDate),
      type: leaveType?.value,
      userComment: comment,
    };
    let formData = new FormData();
    for (const file of attachmentsArray) {
      formData.append('files', file, file.name);
    }
    const { data, ok } = await postFetch<RequestDataTypes>({
      url: leaveRequestUrl,
      body: newRequest,
      intl,
      label: 'LEAVE_REQUEST',
    });
    if (ok && attachmentsArray.length > 0) {
      await postFetch({
        url: `${leaveRequestUrl}/${data?.id}/files`,
        body: formData,
        intl,
        label: 'LEAVE_REQUEST.ADD_ATTACHMENT',
        contentType: 'formdata',
      });
    }
    reset();
    setMainLeaveOption(undefined);
    setDetailedLeaveOption(undefined);
    onClose();
  });

  const renderLeaveRequestsOptions = (
    requestOptionsData: HolidayTypesOptions<LeaveTypes>[],
  ) =>
    requestOptionsData.map(({ value, name }) => ({
      value,
      name: intl.formatMessage({ id: name }),
    }));

  const renderMainTypes = (
    requestOptionsData: HolidayTypesOptions<MainHolidayTypes>[],
  ) =>
    requestOptionsData.map(({ value, name }) => ({
      value,
      name: intl.formatMessage({ id: name }),
    }));

  const handleChangeMainLeaveOption = (
    e: HolidayTypesOptions<MainHolidayTypes>,
  ) => {
    if (e === null) {
      setMainLeaveOption(undefined);
      setDetailedLeaveOption(undefined);
      reset({
        startDate: null,
        endDate: null,
        leaveType: null,
        leaveTypeCategory: null,
        comment: null,
      });
    } else if (e.value !== mainLeaveOption) {
      setMainLeaveOption(e.value);
      setDetailedLeaveOption(undefined);
      reset({
        ...getValues(),
        startDate: null,
        endDate: null,
        leaveType: null,
        comment: null,
      });
    }
  };

  const handleChangeDetailedLeaveOption = (
    e: HolidayTypesOptions<LeaveTypes>,
  ) => {
    setDetailedLeaveOption(e?.value);
    setAttachementIsRequierd(
      e ? LEAVE_TYPES_WITH_ATTACHEMNT.includes(e.value) : false,
    );
    if (e?.value !== detailedLeaveOption) {
      reset({
        ...getValues(),
        startDate: null,
        endDate: null,
        comment: null,
      });
    }
  };

  const chooseDetailedLeaveOptions = (mainLeaveOption: MainHolidayTypes) => {
    switch (mainLeaveOption) {
      case MainHolidayTypes.StandardLeave:
        return STANDARD_LEAVE_REQUESTS_OPTIONS;
      case MainHolidayTypes.MedicalLeave:
        return MEDICAL_LEAVE_REQUESTS_OPTIONS;
      case MainHolidayTypes.OtherLeave:
        return OTHER_LEAVE_REQUESTS_OPTIONS;
    }
  };

  const renderDetailedOptions = (mainLeaveOption: MainHolidayTypes) => {
    const choosedOptions = chooseDetailedLeaveOptions(mainLeaveOption);
    return (
      <LeaveRequestSelect
        title="LEAVE_REQUEST.LABEL.LEAVE_TYPE"
        label="LEAVE_REQUEST.LABEL_OPTION.LEAVE_TYPE"
        name={FormTypes.LeaveType}
        control={control}
        options={renderLeaveRequestsOptions(choosedOptions)}
        handleChange={handleChangeDetailedLeaveOption}
      />
    );
  };

  return (
    <FormWrapper>
      <Grid container spacing={2} direction="column">
        <form onSubmit={onSubmit}>
          <Grid item>
            <LeaveRequestSelect
              title="LEAVE_REQUEST.LABEL.LEAVE_TYPE_CATEGORY"
              label="LEAVE_REQUEST.LABEL_OPTION.LEAVE_TYPE"
              name={FormTypes.LeaveTypeCategory}
              control={control}
              options={renderMainTypes(MAIN_LEAVE_REQUESTS_OPTIONS)}
              handleChange={handleChangeMainLeaveOption}
            />
          </Grid>
          {mainLeaveOption !== undefined &&
            renderDetailedOptions(mainLeaveOption)}
          {detailedLeaveOption !== undefined && (
            <>
              <Grid item>
                <LeaveRequestFormDataSection
                  control={control}
                  setValue={setValue}
                  endDate={endDate}
                  startDate={startDate}
                  leaveType={detailedLeaveOption}
                  isAttachmentRequired={attachementIsRequierd}
                  attachmentsArray={attachmentsArray}
                  setAttachmentsArray={setAttachmentsArray}
                />
              </Grid>
              <Grid item>
                <FormSubmit>
                  <ContainedButton type="submit">
                    {intl.formatMessage({ id: 'LEAVE_REQUEST.FORM_SUBMIT' })}
                  </ContainedButton>
                </FormSubmit>
              </Grid>
            </>
          )}
        </form>
      </Grid>
    </FormWrapper>
  );
};

export default LeaveRequestForm;
