import {
  Control,
  Controller,
  Validate,
  ValidationValueMessage,
} from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import {
  DateTimePickerView,
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { dateTimeFormatFrontend } from 'utils/dateFormats';

interface Props
  extends Omit<KeyboardDateTimePickerProps, 'onChange' | 'value'> {
  control: Control;
  name: string;
  rules?: {
    required?: string | boolean | ValidationValueMessage<boolean>;
    validate?: Validate | Record<string, Validate>;
  };
  defaultValue?: Date | string | null;
  disabled?: boolean;
  errorMessage?: string;
  view?: DateTimePickerView;
  placeholder?: string;
}

const ControlledDateTimePicker = ({
  name,
  label,
  control,
  disabled = false,
  format = dateTimeFormatFrontend,
  rules,
  errorMessage,
  view = 'year',
  views = ['year', 'month', 'date', 'hours', 'minutes'],
  disablePast = false,
  disableFuture = false,
  clearable = false,
  minDate,
  maxDate,
  defaultValue,
  id,
  onAccept,
  ampm = false,
  placeholder,
}: Props) => {
  const getPickerValue = (value: Date | string | null) => {
    if (typeof value === 'string') {
      return new Date(value);
    }
    return value;
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, ...props }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            {...props}
            id={name || id}
            label={label}
            onChange={onChange}
            value={getPickerValue(value)}
            disabled={disabled}
            format={format}
            inputVariant="outlined"
            openTo={view}
            views={views}
            helperText={errorMessage}
            error={!!errorMessage}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoOk
            disableFuture={disableFuture}
            disablePast={disablePast}
            fullWidth
            minDate={minDate}
            maxDate={maxDate}
            clearable={clearable}
            onAccept={onAccept}
            ampm={ampm}
            placeholder={placeholder}
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

export default ControlledDateTimePicker;
