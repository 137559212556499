import IconButtonComponent from '@material-ui/core/IconButton';
import { KeyboardDatePicker as DatePickerComponent } from '@material-ui/pickers';
import styled from 'styled-components';
import { KeyboardDatePickerProps } from './types';

export const IconButton = styled(IconButtonComponent)`
  &.MuiIconButton-root {
    transform: translateY(-50%);
    position: absolute;
    visibility: hidden;
    right: 60px;
    z-index: 2;
    top: 50%;
  }
`;
export const PrevButton = styled(IconButtonComponent)`
  &.MuiIconButton-root {
    padding: 3px;
    transform: translateY(-50%);
    position: absolute;
    right: 120px;
    z-index: 2;
    top: 50%;
  }
`;
export const NextButton = styled(IconButtonComponent)`
  &.MuiIconButton-root {
    padding: 3px;
    transform: translateY(-50%);
    position: absolute;
    right: 90px;
    z-index: 2;
    top: 50%;
  }
`;

export const DatepickerWrapper = styled.div`
  position: relative;
  &:hover > ${IconButton} {
    visibility: visible;
  }
`;

export const KeyboardDatePicker = styled(
  DatePickerComponent,
)<KeyboardDatePickerProps>`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 5px;
  }

  & > .MuiPickersToolbar-toolbar {
    background-color: red;
  }
`;
