import { useIntl } from 'react-intl';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import ControlledCheckbox from 'components/ControlledCheckbox';
import { returnRoleInclusion } from 'utils/helpers/returnRoleInclusion';
import { RoleTypes } from 'utils/rolesTypes';
import { BusinessTripCheckboxesProps, ChekboxesControllers } from '../types';
import {
  BusinessTripsDrawerGrid,
  BusinessTripsToolbar,
  BusinessTripTypograhpy,
} from '../BusinessTrips.css';

const BusinessTripCheckboxes = ({
  shouldBeVisible,
  control,
  loading,
  isLeader,
  roles,
  setOpen,
  open,
  checkboxesState,
  setCheckboxesState,
}: BusinessTripCheckboxesProps) => {
  const intl = useIntl();

  const onCheckboxChange = (controller: ChekboxesControllers) => {
    setCheckboxesState((prevState) => ({
      ...prevState,
      [controller]: !prevState[controller],
    }));
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={setOpen}
      onOpen={setOpen}
    >
      <Box role="presentation">
        <BusinessTripsToolbar>
          <Grid
            container
            justify="space-between"
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton size="medium" edge="start" onClick={setOpen}>
                <ArrowForwardIos />
              </IconButton>
            </Grid>
            <BusinessTripsDrawerGrid item xs={11}>
              <BusinessTripTypograhpy>
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.FILTERS',
                })}
              </BusinessTripTypograhpy>
            </BusinessTripsDrawerGrid>
          </Grid>
        </BusinessTripsToolbar>
        <List>
          {shouldBeVisible && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_ONLY_MINE"
                name="showOnlyMine"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showOnlyMine}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showOnlyMine)
                }
              />
            </ListItem>
          )}
          {shouldBeVisible && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_SETTLED"
                name="showSettled"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showSettled}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showSettled)
                }
              />
            </ListItem>
          )}
          {(isLeader || returnRoleInclusion(roles, [RoleTypes.RoleDEVHR])) && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_BT_TO_ACCEPT"
                name="showToAccept"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showToAccept}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showToAccept)
                }
              />
            </ListItem>
          )}
          {(isLeader || returnRoleInclusion(roles, [RoleTypes.RoleDEVHR])) && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_ACCEPTED"
                name="showAccepted"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showAccepted}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showAccepted)
                }
              />
            </ListItem>
          )}
          {returnRoleInclusion(roles, [
            RoleTypes.RoleAdministration,
            RoleTypes.RoleDEVHR,
          ]) && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_BT_RESERVATION_TO_BE_DONE"
                name="showReservationToBeDone"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showReservationToBeDone}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showReservationToBeDone)
                }
              />
            </ListItem>
          )}
          {returnRoleInclusion(roles, [
            RoleTypes.RoleAccounting,
            RoleTypes.RoleDEVHR,
          ]) && (
            <ListItem>
              <ControlledCheckbox
                label="BUSINESS_TRIP.SHOW_BT_TO_SETTLE"
                name="showToSettle"
                control={control}
                placement="end"
                disabled={loading}
                defaultChecked={checkboxesState.showToSettle}
                changeSideEffect={() =>
                  onCheckboxChange(ChekboxesControllers.showToSettle)
                }
              />
            </ListItem>
          )}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default BusinessTripCheckboxes;
