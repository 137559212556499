import {
  ContractTypes,
  InsuranceProviderTypes,
  ReservationProviderTypes,
  TransportationTypes,
} from './types';

export const HELPER_TEXT_MARGIN = '2px 14px 0';

export const CONTRACT_TYPES = [
  {
    value: ContractTypes.B2B,
    name: 'BUSINESS_TRIP.B2B',
  },
  {
    value: ContractTypes.CIVIL_CONTRACT,
    name: 'BUSINESS_TRIP.CIVIL_CONTRACT',
  },
  {
    value: ContractTypes.EMPLOYMENT_CONTRACT,
    name: 'BUSINESS_TRIP.EMPLOYMENT_CONTRACT',
  },
];

export const TRANSPORTATION_OPTIONS = [
  {
    value: TransportationTypes.PLANE,
    name: 'BUSINESS_TRIP.PLANE',
  },
  {
    value: TransportationTypes.TRAIN,
    name: 'BUSINESS_TRIP.TRAIN',
  },
  {
    value: TransportationTypes.SHIP,
    name: 'BUSINESS_TRIP.SHIP',
  },
  {
    value: TransportationTypes.BUS,
    name: 'BUSINESS_TRIP.BUS',
  },
  {
    value: TransportationTypes.PERSONAL_CAR,
    name: 'BUSINESS_TRIP.PERSONAL_CAR',
  },
  {
    value: TransportationTypes.COMPANY_CAR,
    name: 'BUSINESS_TRIP.COMPANY_CAR',
  },
];

export const RESERVATION_MADE_BY = [
  {
    value: ReservationProviderTypes.JIT_RESERVATION,
    name: 'BUSINESS_TRIP.JIT_RESERVATION',
  },
  {
    value: ReservationProviderTypes.CUSTOMER_RESERVATION,
    name: 'BUSINESS_TRIP.CUSTOMER_RESERVATION',
  },
  {
    value: ReservationProviderTypes.CONSULTANT,
    name: 'BUSINESS_TRIP.CONSULTANT',
  },
  {
    value: ReservationProviderTypes.WECO_TRAVEL,
    name: 'BUSINESS_TRIP.WECO_TRAVEL',
  },
  {
    value: ReservationProviderTypes.WORK_TRIPS,
    name: 'BUSINESS_TRIP.WORK_TRIPS',
  },
];

export const INSURANCE_PROVIDERS = [
  {
    value: InsuranceProviderTypes.JIT_INSURANCE,
    name: 'BUSINESS_TRIP.JIT_INSURANCE',
  },
  {
    value: InsuranceProviderTypes.CUSTOMER_INSURANCE,
    name: 'BUSINESS_TRIP.CUSTOMER_INSURANCE',
  },
  {
    value: InsuranceProviderTypes.NO_INSURANCE,
    name: 'BUSINESS_TRIP.NO_INSURANCE',
  },
];

export const DEFAULT_CURRENCY = [
  {
    value: 0,
    name: 'PLN',
  },
];

export const DEFAULT_COUNTRY = 'Poland';
