import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { BusinessTripsContext } from 'modules/BusinessTrips/BusinessTripManagerContext/Context';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import {
  HELPER_TEXT_MARGIN,
  RESERVATION_MADE_BY,
  TRANSPORTATION_OPTIONS,
} from '../constants';
import {
  ReservationProviderTypes,
  TransportationTypes,
  TripSectionProps,
} from '../types';
import {
  renderErrorMessageForRequiredFields,
  renderSelectOptions,
} from '../utils';
import { TripSectionContainer } from '../BusinessTripDialog.css';

const Travel = ({
  control,
  errors,
  editClicked,
  defaultReservationBy,
}: TripSectionProps) => {
  const {
    businessTripsManagerState: {
      formState: {
        transportation,
        travelFrom,
        travelTo,
        travelReservationBy,
        reservationInformation,
      },
    },
  } = useContext(BusinessTripsContext);

  const intl = useIntl();

  const currentTransportation = useWatch({
    control,
    name: 'transportation',
    defaultValue: transportation,
  });

  return (
    <TripSectionContainer container spacing={3}>
      <Grid xs={12} item>
        <ControllerSelect
          label="BUSINESS_TRIP.FORM.TRANSPORTATION"
          name="transportation"
          control={control}
          required
          options={renderSelectOptions<TransportationTypes>(
            TRANSPORTATION_OPTIONS,
            intl,
          )}
          helperText={editClicked}
          isPadding={false}
          defaultValue={transportation}
          errorMessage={
            errors?.transportation && renderErrorMessageForRequiredFields(intl)
          }
          errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid xs={12} item>
          <ControlledTextField
            label="BUSINESS_TRIP.FORM.TRAVEL_FROM"
            name="travelFrom"
            control={control}
            required
            variant="outlined"
            maxLength={MAX_INPUT_LENGTH}
            defaultValue={travelFrom}
            validate={!!errors?.travelFrom}
            helperText={
              errors?.travelFrom && renderErrorMessageForRequiredFields(intl)
            }
          />
        </Grid>
        <Grid xs={12} item>
          <ControlledTextField
            label="BUSINESS_TRIP.FORM.TRAVEL_TO"
            name="travelTo"
            control={control}
            required
            variant="outlined"
            maxLength={MAX_INPUT_LENGTH}
            defaultValue={travelTo}
            validate={!!errors?.travelTo}
            helperText={
              errors?.travelTo && renderErrorMessageForRequiredFields(intl)
            }
          />
        </Grid>
      </Grid>
      {(currentTransportation?.value === TransportationTypes.PLANE ||
        currentTransportation?.value === TransportationTypes.BUS ||
        currentTransportation?.value === TransportationTypes.COMPANY_CAR ||
        currentTransportation?.value === TransportationTypes.SHIP ||
        currentTransportation?.value === TransportationTypes.TRAIN) && (
        <Grid container item spacing={2}>
          <Grid xs={12} item>
            <ControllerSelect
              label="BUSINESS_TRIP.FORM.RESERVATION_BY"
              name="travelReservationBy"
              control={control}
              options={renderSelectOptions<ReservationProviderTypes>(
                RESERVATION_MADE_BY,
                intl,
              )}
              required
              helperText={editClicked}
              isPadding={false}
              defaultValue={travelReservationBy || defaultReservationBy}
              errorMessage={
                errors?.travelReservationBy &&
                renderErrorMessageForRequiredFields(intl)
              }
              errorMessageStyle={{ margin: HELPER_TEXT_MARGIN }}
            />
          </Grid>
          <Grid xs={12} item>
            <ControlledTextField
              label="BUSINESS_TRIP.TRAVEL_RESERVATION_INFORMATION"
              name="reservationInformation"
              control={control}
              variant="outlined"
              rows={8}
              isMulti
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={reservationInformation}
              placeholder={intl.formatMessage({
                id: 'BUSINESS_TRIP.ADDITIONAL_TRAVEL_INFO_PLACEHOLDER',
              })}
            />
          </Grid>
        </Grid>
      )}
    </TripSectionContainer>
  );
};

export default Travel;
