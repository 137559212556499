import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { UserContext } from 'globalContext/UserContext';
import { accountFilledInfoUrl } from 'router/url';
import MissingUserProfileInfoDialog from 'components/Dialog/MissingInfoDialog';
import {
  MissingInfoType,
  MissingUserProfileInfo,
} from 'components/Dialog/MissingInfoDialog/types';
import {
  fetchClientList,
  fetchEmployeeList,
  fetchProjectList,
} from 'utils/commonFetches';
import { getFetch } from 'utils/fetchFunctions';
import { returnRoleInclusion } from 'utils/helpers';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { useIndexIcon } from 'utils/hooks/useIndexIcon';
import { usePagination } from 'utils/hooks/usePagination';
import useQuery from 'utils/hooks/useQuery';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import { BusinessTripsManagerProvider } from './BusinessTripManagerContext/Context';
import { TripCalculatorProvider } from './Screens/BusinessTripCalculator/TripCalculatorContext';
import BusinessTripsTable from './Screens/BusinessTripsTable';
import BusinessTripBox from './BusinessTripBox';
import BusinessTripDialog from './Dialog';
import { BUSINESS_TRIPS_STATUSES, TRIPS_HEAD_DATA } from './static_data';
import {
  BusinessTrip,
  BusinessTripsFilters,
  CheckboxesState,
  TripStatuses,
} from './types';
import { getQueryString, projectsFilter } from './utils';
import { TripsBox } from './BusinessTrips.css';

const BusinessTrips = () => {
  const intl = useIntl();
  const query = useQuery();
  const [showAddTripDialog, toggleAddTripDialog] = useToggleState(false);
  const [tripsChanged, toggleTripsChanged] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<BusinessTrip[]>([]);
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const [openMissingInfoDialog, setOpenMissingInfoDialog] = useState(false);
  const [missingInfo, setMissingInfo] = useState<MissingUserProfileInfo>();
  const [, setPreviousParameters] = useState<string>();
  const [checkboxesState, setCheckboxesState] = useState<CheckboxesState>({
    showOnlyMine: false,
    showAccepted: false,
    showSettled: false,
    showReservationToBeDone: false,
    showToSettle: false,
    showToAccept: false,
  });
  const [showDescription, selectedId, renderIcon, resetIndex] =
    useIndexIcon(null);

  const instantlyChangeTripState = useCallback(
    (id: number, state: TripStatuses) => {
      const updatedTableData: BusinessTrip[] = tableData.map((businessTrip) => {
        if (businessTrip.id === id) {
          return { ...businessTrip, status: state };
        }
        return businessTrip;
      });

      setTableData(updatedTableData);
    },
    [tableData],
  );
  const { control, setValue } = useForm<BusinessTripsFilters>({
    defaultValues: {
      employee: undefined,
      project: undefined,
      client: undefined,
      status: undefined,
    },
  });

  const formData: BusinessTripsFilters = useWatch({
    control,
  }) as BusinessTripsFilters;
  const { employee, project, client, status } = formData;

  const { roles, username, teamLeader } = useContext(UserContext);
  const isAccountPrivileged = useMemo(
    () =>
      returnRoleInclusion(roles, [
        RoleTypes.RoleDEVHR,
        RoleTypes.RoleAccounting,
        RoleTypes.RoleAdministration,
      ]),
    [roles],
  );

  const isLeader = useMemo(() => {
    return teamLeader;
  }, [teamLeader]);

  const tripIdFromQuery = useMemo(() => query.get('id') ?? undefined, [query]);

  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
  } = usePagination<FormData>({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 300,
  });

  const fetchTrips = useCallback(async () => {
    setLoading(true);
    if (!username) {
      return null;
    }

    const params = {
      pageNumber: debouncedPage,
      pageSize: pagination.pageSize,
      isAccountPrivileged,
      username,
      employeeId: employee?.value,
      clientId: client?.value,
      projectId: project?.value,
      status: status?.value,
      isLeader,
      businessTripId: tripIdFromQuery,
    };

    const checkboxes = {
      showOnlyMine: checkboxesState.showOnlyMine,
      showAccepted: checkboxesState.showAccepted,
      showSettled: checkboxesState.showSettled,
      showReservationToBeDone: checkboxesState.showReservationToBeDone,
      showToSettle: checkboxesState.showToSettle,
      showToAccept: checkboxesState.showToAccept,
    };

    const queryString = getQueryString(params, checkboxes);

    setPreviousParameters((prevParams) => {
      if (prevParams !== queryString) {
        resetIndex();
      }
      return queryString;
    });

    const tripsResponse = await getFetch({
      url: queryString,
    });

    setTableData(tripsResponse?.content);

    setElementsCount(tripsResponse?.totalElements);

    setLoading(false);
  }, [
    username,
    debouncedPage,
    pagination.pageSize,
    isAccountPrivileged,
    employee?.value,
    client?.value,
    project?.value,
    status?.value,
    isLeader,
    checkboxesState.showOnlyMine,
    checkboxesState.showAccepted,
    checkboxesState.showSettled,
    checkboxesState.showReservationToBeDone,
    checkboxesState.showToSettle,
    checkboxesState.showToAccept,
    setElementsCount,
    resetIndex,
    setPreviousParameters,
    tripIdFromQuery,
  ]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips, tripsChanged]);

  useEffect(() => {
    fetchEmployeeList(setEmployeesData);
    fetchClientList(setClientsData);
    fetchProjectList(setProjectsData);
  }, []);

  const projectsOptions = () => {
    if (client) {
      return projectsFilter(client.name, projectsData);
    }
    return projectsData;
  };

  const statusesOptions = () =>
    BUSINESS_TRIPS_STATUSES.map(({ value, name }) => ({
      value,
      name: intl.formatMessage({ id: name }),
    }));
  const getAccountFilledInfo = useCallback(async () => {
    const response: MissingUserProfileInfo = await getFetch({
      url: accountFilledInfoUrl,
    });
    setMissingInfo(response);
    if (
      response &&
      (!response.contractTypeDefined || !response.personalIdDefined)
    ) {
      setOpenMissingInfoDialog(true);
    }
  }, [setOpenMissingInfoDialog]);

  useEffect(() => {
    getAccountFilledInfo();
  }, [getAccountFilledInfo]);

  const handleAddBusinessTrip = useCallback(async () => {
    await getAccountFilledInfo();
    if (missingInfo?.contractTypeDefined || missingInfo?.personalIdDefined) {
      toggleAddTripDialog();
    }
  }, [getAccountFilledInfo, missingInfo, toggleAddTripDialog]);

  useEffect(() => {
    setCurrentPage(null, 0);
  }, [formData, setCurrentPage]);

  return (
    <>
      <BusinessTripsManagerProvider>
        <TripsBox>
          <TripCalculatorProvider>
            <BusinessTripBox
              isAccountPrivileged={isAccountPrivileged}
              loading={loading}
              control={control}
              employees={employeesData}
              clients={clientsData}
              projects={projectsOptions()}
              statusesOptions={statusesOptions()}
              setValue={setValue}
              onAddBusinessTrip={handleAddBusinessTrip}
              isLeader={isLeader}
              roles={roles}
              formData={formData}
              checkboxesState={checkboxesState}
              setCheckboxesState={setCheckboxesState}
            />
          </TripCalculatorProvider>
          <BusinessTripsTable
            fetchTrips={fetchTrips}
            data={tableData}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            pageCount={pagination.totalElements}
            currentPage={pagination.pageNumber}
            withPagination
            limit={pagination.pageSize}
            head={TRIPS_HEAD_DATA}
            loading={loading}
            isAccountPrivileged={isAccountPrivileged}
            isLeader={isLeader}
            withNameColumn={!checkboxesState.showOnlyMine}
            instantlyChangeTripState={instantlyChangeTripState}
            tripsChanged={toggleTripsChanged}
            showDescription={showDescription}
            selectedId={selectedId}
            renderIcon={renderIcon}
          />
        </TripsBox>

        <BusinessTripDialog
          addChecked={showAddTripDialog}
          handleAddChanged={toggleAddTripDialog}
          tripsChanged={toggleTripsChanged}
        />

        {openMissingInfoDialog && (
          <MissingUserProfileInfoDialog
            onClose={setOpenMissingInfoDialog}
            missingInfo={missingInfo}
            type={MissingInfoType.businessTrip}
          />
        )}
      </BusinessTripsManagerProvider>
    </>
  );
};

export default BusinessTrips;
