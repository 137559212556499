import { ProjectCategories } from 'components/Dialog/types';
import { IconLoadingProps, IconTypes, QuickReportProject } from '../../types';
import { checkIfStatusInactive, getStatusTooltipTitle } from '../../utils';
import { QuickReportCustomProject } from './../../types';

export const getUnassignTooltipData = (entry: QuickReportProject) => {
  if (entry.userDefaultProject) {
    return {
      intl: 'QUICK_REPORT_TABLE.TOOLTIP_UNASSIGN_PROJECT_IF_DEFAULT',
      id: 'defaultProjectTooltip',
    };
  }
  if (entry.reportedTimeHours > 0) {
    return {
      intl: `QUICK_REPORT_TABLE.TOOLTIP_UNASSIGN_PROJECT_IF_REPORTED_DAYS`,
      id: 'reportedDaysTooltip',
    };
  }
  if (entry.reportedWorkUnits > 0) {
    return {
      intl: `QUICK_REPORT_TABLE.TOOLTIP_UNASSIGN_PROJECT_IF_REPORTED_WORK_UNITS`,
      id: 'reportedUnitsTooltip',
    };
  }
  if (checkIfMedicalOrVacation(entry)) {
    return {
      intl: `QUICK_REPORT_TABLE.TOOLTIP_UNASSIGN_PROJECT_IF_MEDICAL_OR_VACATION`,
      id: 'unassignButton',
    };
  }

  return {
    intl: 'QUICK_REPORT_TABLE.TOOLTIP_UNASSIGN_PROJECT',
    id: 'unassignButton',
  };
};

export const getDateRangeTooltipData = (entry: QuickReportProject) => {
  if (checkIfStatusInactive(entry)) {
    return {
      intl: `WORK_TIME_TAB.${getStatusTooltipTitle(entry)}.TOOLTIP`,
      id: 'disabledDateRangeButton',
    };
  }
  return { intl: 'TABLE.DATE_RANGE', id: 'dateRangeButton' };
};

export const getDefaultProjectTooltipData = (entry: QuickReportProject) => {
  if (entry.userDefaultProject) {
    return {
      intl: 'QUICK_REPORT_TABLE.TOOLTIP_ACTUAL_DEFAULT_PROJECT',
      id: 'defaultProjectButton',
    };
  }
  return {
    intl: 'QUICK_REPORT_TABLE.TOOLTIP_SET_DEFAULT_PROJECT',
    id: 'disabledDefaultProjectButton',
  };
};

export const checkIfMedicalOrVacation = (entry: QuickReportProject) =>
  entry.projectCategory === ProjectCategories.medicalLeave ||
  entry.projectCategory === ProjectCategories.vacations ||
  entry.projectCategory === ProjectCategories.dayOff;

export const checkIfDisabled = (
  type: IconTypes,
  entry: QuickReportProject | QuickReportCustomProject,
  canUnassign?: boolean,
) => {
  switch (type) {
    case 'getDateRange':
      return checkIfStatusInactive(entry);
    case 'setDefaultProject':
      return entry.userDefaultProject;
    case 'unassignProject':
      return (
        entry.reportedTimeHours > 0 ||
        entry.userDefaultProject ||
        entry.reportedWorkUnits > 0 ||
        checkIfMedicalOrVacation(entry) ||
        !canUnassign
      );
    default:
      return false;
  }
};

export const checkIfLoading = (
  action: IconTypes,
  id: number,
  iconLoading?: IconLoadingProps,
) => iconLoading?.action === action && iconLoading.id === id;
