import { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { TimeRangeTypes } from 'components/BenefitInputs/types';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { StepProps } from '../types';
import TimeRangeSelect from './TimeRangeSelect';
import { GridWithMargin, InputSelectTitle } from '../../../BenefitsManager.css';

const FourthStep = ({ control, errors, setValue, getValues }: StepProps) => {
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);
  const intl = useIntl();

  const setCheckboxFormState = useCallback(() => {
    if (getValues().usingOwnForm === undefined) {
      setValue('usingOwnForm', formState.usingOwnForm);
    }
  }, [formState.usingOwnForm, getValues, setValue]);

  useEffect(() => {
    setCheckboxFormState();
  }, [setCheckboxFormState]);

  return (
    <GridWithMargin container>
      <InputSelectTitle>
        {intl.formatMessage({ id: `BENEFITS.FORM.TIME_RANGE_INFO` })}
      </InputSelectTitle>
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.lowerType}
      />
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.higherType}
      />
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.lowerPackage}
      />
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.higherPackage}
      />
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.removePartner}
      />
      <TimeRangeSelect
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        type={TimeRangeTypes.addPartner}
      />
    </GridWithMargin>
  );
};

export default FourthStep;
