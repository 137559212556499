import { Dispatch } from 'react';
import {
  BenefitAccountFormValues,
  BenefitManagerFormValues,
  BenefitTypesProps,
} from 'components/BenefitInputs/types';
import { BenefitsDataProps } from 'modules/UserProfile/Screens/Benefits/types';

export type State = {
  renderForm: RenderForm | null;
  renderPartners: boolean;
  toggleFormDialog: boolean;
  toggleRemoveBenefitDialog: boolean;
  toggleRemovePartnerDialog: boolean;
  selectedBenefitToRemove?: BenefitsDataProps;
  selectedBenefit?: BenefitManagerFormValues;
  selectedBenefitType?: BenefitTypesProps;
  benefitsData?: BenefitAccountFormValues;
  numberOfMembers: number;
};

export enum ActionKind {
  AddNewBenefit = 'ADD_NEW_BENEFIT',
  AddNewPartner = 'ADD_NEW_PARTNER',
  EditData = 'EDIT_DATA',
  RenderPartners = 'RENDER_PARTNERS_ROWS',
  ToggleFormDialog = 'TOGGLE_FORM_DIALOG',
  RemoveBenefit = 'REMOVE_BENEFIT',
  RemovePartner = 'REMOVE_PARTNER',
  SetBenefitData = 'SET_BENEFIT_DATA',
  IncreaceNumberOfMembers = 'INCREASE_NUMBER_OF_MEMBERS',
  DecreaseNumberOfMembers = 'DECREASE_NUMBER_OF_MEMBERS',
  ResetNumberOfMembers = 'RESET_NUMBER_OF_MEMBERS',
  SetSelectedBenefitData = 'SET_SELECTED_BENEFIT_DATA',
  ToggleRemoveConfirmation = 'TOGGLE_REMOVE_CONFIRMATION',
  ResetState = 'RESET_STATE',
}

export interface BenefitContextProps {
  dispatch: Dispatch<Action>;
  state: State;
}

interface AddNewBenefitAction {
  type: ActionKind.AddNewBenefit;
  payload: {
    setBenefit: BenefitManagerFormValues;
    setBenefitType?: BenefitTypesProps;
  };
}

interface SetBenefitDataAction {
  type: ActionKind.SetBenefitData;
  payload: BenefitAccountFormValues;
}

interface SetSelectedBenefitDataAction {
  type: ActionKind.SetSelectedBenefitData;
  payload: {
    setSelectedBenefit: BenefitManagerFormValues;
    setSelectedBenefitType: BenefitTypesProps;
  };
}

export interface RemoveBenefitAction {
  type: ActionKind.RemoveBenefit;
  payload: {
    setSelectedBenefit: BenefitsDataProps;
  };
}

interface OnlyTypeAction {
  type:
    | ActionKind.AddNewPartner
    | ActionKind.EditData
    | ActionKind.RenderPartners
    | ActionKind.ToggleFormDialog
    | ActionKind.RemovePartner
    | ActionKind.IncreaceNumberOfMembers
    | ActionKind.DecreaseNumberOfMembers
    | ActionKind.ResetNumberOfMembers
    | ActionKind.ToggleRemoveConfirmation
    | ActionKind.ResetState;
}

export type Action =
  | AddNewBenefitAction
  | SetBenefitDataAction
  | OnlyTypeAction
  | SetSelectedBenefitDataAction
  | RemoveBenefitAction;

export enum RenderForm {
  Benefit = 'benefit',
  Partner = 'partner',
  EditData = 'editData',
}
