import { Control } from 'react-hook-form';
import { GridSize } from '@material-ui/core';
import ControlledCheckbox from 'components/ControlledCheckbox';
import {
  HeaderTooltipText,
  InformationIcon,
  TableIconButton,
} from 'components/Table/Table.css';
import { FormValues } from './types';
import { CheckboxContainer, CheckboxTooltip } from './BenefitInputs.css';

interface Props {
  control: Control;
  name: keyof FormValues;
  label: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  placement?: 'bottom' | 'end' | 'start' | 'top';
  isFullWidth?: boolean;
  tooltipTitle?: string;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

const BenefitCheckbox = ({
  name,
  label,
  control,
  disabled,
  placement = 'end',
  defaultChecked,
  isFullWidth,
  tooltipTitle,
  xs = 12,
  sm,
  md,
}: Props) => (
  <CheckboxContainer item xs={xs} sm={sm} md={md}>
    <ControlledCheckbox
      control={control}
      label={`BENEFITS.FORM.${label}`}
      name={name}
      placement={placement}
      disabled={disabled}
      defaultChecked={defaultChecked}
      isFullWidth={isFullWidth}
    />
    {tooltipTitle && (
      <CheckboxTooltip
        title={<HeaderTooltipText>{tooltipTitle}</HeaderTooltipText>}
        placement="top"
      >
        <TableIconButton id={`${name}_tooltip`}>
          <InformationIcon />
        </TableIconButton>
      </CheckboxTooltip>
    )}
  </CheckboxContainer>
);

export default BenefitCheckbox;
