import About from 'modules/About/About';
import AdminPanel from 'modules/AdminPanel/AdminPanel';
import BusinessTrips from 'modules/BusinessTrips';
import Clients from 'modules/Clients/Clients';
import ManagerForm from 'modules/ManagerForm/ManagerForm';
import Projects from 'modules/Projects/Projects';
import Reports from 'modules/Reports/Reports';
import Timesheets from 'modules/Timesheets/Timesheets';
import UserProfile from 'modules/UserProfile/UserProfile';
import WorkTime from 'modules/WorkTime/WorkTime';

export default [
  { component: Timesheets, path: '/timesheets' },
  { component: WorkTime, path: '/worktime/:username' },
  { component: WorkTime, path: '/worktime' },
  { component: Reports, path: '/reports' },
  { component: Projects, path: '/projects' },
  { component: Clients, path: '/clients' },
  { component: ManagerForm, path: '/form' },
  { component: UserProfile, path: '/profile' },
  { component: About, path: '/about' },
  { component: AdminPanel, path: '/adminpanel' },
  { component: BusinessTrips, path: '/businesstrips' },
  { component: WorkTime, route: '/' },
];
