import moment from 'moment';
import {
  FormTypes,
  LeaveTypes,
  MedicalLeaveTypes,
  OtherLeaveTypes,
  StandardLeaveTypes,
} from '../../types';

const getNumberOfDay = (date: Date | null) => moment(date).day();

const isFirstPartOfWorkWeek = (date: Date | null) => {
  const numberOfDay = getNumberOfDay(date);
  return numberOfDay === 1 || numberOfDay === 2 || numberOfDay === 3;
};

const isEndPartOfWorkWeek = (date: Date | null) => {
  const numberOfDay = getNumberOfDay(date);
  return numberOfDay === 3 || numberOfDay === 4 || numberOfDay === 5;
};

export const valiadateMinDate = (
  leaveType: LeaveTypes | null,
  name: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const defaultMinDate =
    name === FormTypes.EndDate
      ? startDate || moment().add(1, 'days')
      : moment().add(1, 'days');
  const dayInTwoWeeks = moment().add(14, 'days');

  switch (leaveType) {
    case StandardLeaveTypes.ANNUAL_PAID_LEAVE:
    case OtherLeaveTypes.UNPAID_LEAVE: {
      if (FormTypes.StartDate && moment(endDate) < dayInTwoWeeks) {
        return isFirstPartOfWorkWeek(endDate)
          ? moment(endDate).subtract(5, 'days')
          : moment(endDate).subtract(3, 'days');
      }
      return defaultMinDate;
    }
    case StandardLeaveTypes.EMERGENCY_PAID_LEAVE: {
      return moment();
    }
    case MedicalLeaveTypes.SICK_PAID_LEAVE:
    case MedicalLeaveTypes.CARE_PAID_LEAVE: {
      if (name === FormTypes.EndDate) {
        return startDate || moment().subtract(3, 'days');
      }
      return moment().subtract(3, 'days');
    }
    case OtherLeaveTypes.MATERNITY_PAID_LEAVE: {
      return undefined;
    }
    case OtherLeaveTypes.FATHERLY_PAID_LEAVE:
    case OtherLeaveTypes.CHILD_EDUCATION_PAID_LEAVE: {
      if (name === FormTypes.EndDate) {
        return startDate || moment().add(7, 'days');
      }
      return moment().add(7, 'days');
    }
    case OtherLeaveTypes.CHILD_CARE_PAID_LEAVE:
    case OtherLeaveTypes.SPECIAL_PAID_LEAVE: {
      if (
        name === FormTypes.StartDate &&
        endDate &&
        moment(endDate) > moment()
      ) {
        return moment(endDate).day() === 1
          ? moment(endDate).subtract(3, 'days')
          : moment(endDate).subtract(1, 'days');
      }
      return defaultMinDate;
    }
    default:
      return defaultMinDate;
  }
};

export const valiadateMaxDate = (
  leaveType: LeaveTypes | null,
  name: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const defaultMaxDate =
    name === FormTypes.StartDate ? endDate || undefined : undefined;
  const dayInTwoWeeks = moment().add(13, 'days');
  switch (leaveType) {
    case StandardLeaveTypes.ANNUAL_PAID_LEAVE:
    case OtherLeaveTypes.UNPAID_LEAVE: {
      if (
        name === FormTypes.EndDate &&
        startDate &&
        moment(startDate) < dayInTwoWeeks
      ) {
        return isEndPartOfWorkWeek(startDate)
          ? moment(startDate).add(5, 'days')
          : moment(startDate).add(3, 'days');
      }
      return defaultMaxDate;
    }
    case StandardLeaveTypes.EMERGENCY_PAID_LEAVE: {
      return moment();
    }
    case OtherLeaveTypes.CHILD_CARE_PAID_LEAVE:
    case OtherLeaveTypes.SPECIAL_PAID_LEAVE: {
      if (name === FormTypes.EndDate && startDate) {
        return moment(startDate).day() === 5
          ? moment(startDate).add(3, 'days')
          : moment(startDate).add(1, 'days');
      }
      return defaultMaxDate;
    }
    default:
      return defaultMaxDate;
  }
};
