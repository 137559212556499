import Grid, { GridProps } from '@material-ui/core/Grid';
import styled from 'styled-components';

export const GridWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    padding: 2rem;
  }
`;

export const GridItem = styled(Grid)<GridProps>`
  &.MuiGrid-item {
    margin: 0.5rem;
    width: 30vw;
  }
`;
