import {
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ArrayField, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { DateRange } from '@mui/icons-material';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { timesheetsUrl, workTimeFullReportUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import FilesUploadDialog from 'components/Dialog/FilesUploadDialog/FilesUploadDialog';
import ProjectDateRangeDialog from 'components/Dialog/ProjectDateRangeDialog';
import { ProjectCategories } from 'components/Dialog/types';
import DownloadFileButton from 'components/DownloadFileButton';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import ToolTipCell from 'components/ToolTipCell';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { ClientData, TimesheetData } from 'modules/WorkTime/types';
import { AssignButton, GridWrapper } from 'modules/WorkTime/WorkTime.css';
import {
  mapISODurationToString,
  mapStringToDuration,
  mapStringToISODuration,
} from 'utils/durationFunctions';
import { deleteFetch, postFetch, putFetch } from 'utils/fetchFunctions';
import { returnHourString } from 'utils/formatters/returnHourString';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import { UserContext } from '../../globalContext/UserContext';
import Datepicker from '../Datepicker';
import AssignProjectDialog from '../Dialog/AssignProjectDialog';
import { WORK_TIME_FULL_REPORT_HEAD_DATA } from './consts';
import EmptyRow from './EmptyRow';
import FullReportRow from './FullReportRow';
import {
  getFormattedFullReportData,
  getInvalidWorktime,
  getInvalidWorktimeTitle,
  getNewEntryProjects,
  getWorkTimeDuration,
  getWorkTimeMidnightDuration,
  projectsFilter,
} from './tableUtils';
import {
  DayProjectOptionValue,
  FetchedFile,
  FullReport,
  FullReportDay,
  InvalidWorktimeProps,
  ProjectBasicInfo,
  SubmitResponseData,
  TableFullReportProps as TableProps,
} from './types';
import {
  CustomProjectsContainer,
  FullReportTableContainer,
  GreyBorder,
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableCellCustom,
  TableFooter,
  TableFooterCell,
  TableFooterRow,
  TableHeadCell,
  TableHeader,
  TableRow,
} from './Table.css';

const FullReportTable = ({
  headCustoms,
  projectsData,
  clientsData,
  fetchClientsAndProjects,
  timesheetData,
  date,
  setDate,
  setTimesheetUsername,
  selectedUser,
  setSelectedUser,
  setIsFormedChanged,
  myTimesheetLoaded,
  isUserAuthorized,
  loading,
  reportAvailable,
  defaultProject,
  rowRef,
  defaultClient,
  sheetId,
  fullReportData,
  projectsOptions,
  setProjectsOptions,
  fetchFullReport,
  userContract,
}: TableProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [addChecked, handleAddChecked] = useToggleState(false);
  const [openFileUploadArea, setOpenFileUploadArea] = useToggleState(false);
  const [uploadedDayId, setUploadedDayId] = useState('-1');
  const [uploadedDayFiles, setUploadedDayFiles] = useState<FetchedFile[]>();
  const [isFileDeleted, setFileDeleted] = useState(false);
  const [areFilesSend, setAreFilesSend] = useState(false);
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] =
    useToggleState(false);
  const [loadingToDisableButton, setLoadingToDisableButton] = useState(false);
  const [invalidWorktime, setInvalidWorktime] = useState<
    InvalidWorktimeProps[]
  >([]);
  const { username, roles } = useContext(UserContext);

  const userData = useMemo(
    () => timesheetData?.find((data) => data?.username === username),
    [timesheetData, username],
  );

  const isEmploymentContract =
    userContract === ContractTypes.EMPLOYMENT_CONTRACT;

  const isAccountingOrSales = useMemo(() => {
    return (
      roles.includes(RoleTypes.RoleAccounting) ||
      roles.includes(RoleTypes.RoleSales) ||
      roles.includes(RoleTypes.RoleDEVHR)
    );
  }, [roles]);

  const upLg = useMediaQuery(theme.breakpoints.up('lg'));

  const downloadExcelUrl = `${timesheetsUrl}/${
    selectedUser?.username ? selectedUser.username : username
  }/${workTimeFullReportUrl}/export?yearMonth=${moment(date).format(
    'YYYY-MM',
  )}&exportFormat=XLSX`;

  const { control, formState, reset, getValues, register, setValue } =
    useForm<FullReport>({
      defaultValues: fullReportData,
    });

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'days',
  });

  const handleProjectChange = useCallback(
    (dayId: string, index: number) =>
      async (e: OnChangeValue<DayProjectOptionValue, boolean>) => {
        const newDays = getValues()?.days.map((el) =>
          el.dayId === dayId
            ? { ...el, projectBasicInfo: e as unknown as ProjectBasicInfo }
            : el,
        );
        const correctDay = newDays?.find((day) => day.dayId === dayId);
        if (newDays && correctDay?.projectBasicInfo) {
          setValue(
            `days[${index}].multiplicator`,
            correctDay?.projectBasicInfo.multiplier,
          );
        } else {
          setValue(`days[${index}].multiplicator`, '');
        }
      },
    [setValue, getValues],
  );

  const clearProjectField = useCallback(
    (dayId: string, index: number) => {
      handleProjectChange(dayId, index)(null);
      setValue(`days[${index}].project`, '');
      setValue(`days[${index}].multiplicator`, '');
    },
    [handleProjectChange, setValue],
  );

  const handleClientChange = useCallback(
    (dayId: string, index: number) => (_e: ClientData) => {
      clearProjectField(dayId, index);
      const projectsForClient = projectsFilter(
        projectsData,
        getValues().days?.find((day) => day.dayId === dayId)?.client?.name,
      ).map((project) => ({
        value: project.value,
        name: project.name,
        status: project.status,
        reportingCustomData: project.reportingCustomData,
        projectCategory: project.projectCategory,
        multiplier: project.multiplier,
        tooltipMessage: project?.tooltipMessage,
      }));
      setProjectsOptions((prevProjectsOptions) =>
        prevProjectsOptions?.map((p) =>
          p.dayId === dayId ? { ...p, projects: projectsForClient } : p,
        ),
      );
    },
    [clearProjectField, getValues, setProjectsOptions, projectsData],
  );

  const isUserSettled = !!fullReportData?.settled;
  const isTimesheetDisabled = !myTimesheetLoaded || isUserSettled;

  const isLeaveDay = useCallback(
    (dayId: string) => {
      const days = getValues()?.days;
      if (days) {
        const selectOption = days.find((el) => el?.dayId === dayId)?.project
          ?.projectCategory;
        return (
          selectOption === ProjectCategories.medicalLeave ||
          selectOption === ProjectCategories.vacations ||
          selectOption === ProjectCategories.dayOff
        );
      } else if (fullReportData) {
        const selectOption = fullReportData?.days.find(
          (el) => el?.dayId === dayId,
        )?.projectBasicInfo?.projectCategory;

        return (
          selectOption === ProjectCategories.medicalLeave ||
          selectOption === ProjectCategories.vacations ||
          selectOption === ProjectCategories.dayOff
        );
      }
      return false;
    },
    [getValues, fullReportData],
  );

  const checkIfDayIsFree = useCallback(
    (dayId: string, entry: Partial<ArrayField<Record<string, any>>>) => {
      return (
        isLeaveDay(dayId) ||
        entry.dayOfWeek === 'SATURDAY' ||
        entry.dayOfWeek === 'SUNDAY' ||
        entry.holiday ||
        entry.bankHoliday
      );
    },
    [isLeaveDay],
  );

  const getDefaultValueForProjectOrClient = useCallback(
    (input: 'project' | 'client') => {
      switch (input) {
        case 'project':
          return defaultProject.id
            ? {
                client: {
                  id: defaultClient.id,
                  clientName: defaultClient.name,
                  status: 'ACTIVE',
                },
                contactPerson: '',
                multiplicator: defaultProject.multiplicatorValue,
                multiplier: defaultProject.multiplicator,
                name: defaultProject.name,
                projectCategory: defaultProject.projectCategory,
                projectName: defaultProject.name,
                reportingCustomData: false,
                status: 'ACTIVE',
                value: defaultProject.id,
              }
            : undefined;
        case 'client':
          return defaultClient.id
            ? {
                accountingNotes: undefined,
                clientName: defaultClient.name,
                name: defaultClient.name,
                status: 'ACTIVE',
                value: defaultClient.id,
              }
            : undefined;
      }
    },
    [defaultClient, defaultProject],
  );

  const getProjectsOptions = useCallback(
    (dayId?: string) => {
      return projectsOptions?.find((p) => p.dayId === dayId)?.projects;
    },
    [projectsOptions],
  );

  const checkWhatToRender = useCallback(
    (
      entry: Partial<ArrayField<Record<string, any>>>,
      input: 'project' | 'client',
    ) => {
      if (entry[input]) {
        if (input === 'client') {
          const result = clientsData?.find(
            (client) => entry[input].id === client.value,
          );

          return result
            ? {
                accountingNotes: undefined,
                clientName: result?.clientName,
                name: result?.clientName,
                status: result?.status,
                value: result?.value,
              }
            : entry[input];
        } else {
          const result = projectsData?.find(
            (project) => entry[input].id === project.value,
          );
          return result
            ? {
                client: {
                  id: result?.client.id,
                  clientName: result?.client.clientName,
                  status: result?.client.status,
                },
                contactPerson: '',
                multiplicator: result?.multiplicator,
                multiplier: result?.multiplier,
                name: entry[input].name,
                projectCategory: result?.projectCategory,
                projectName: result?.projectName,
                reportingCustomData: false,
                status: result?.status,
                value: entry[input].id,
              }
            : entry[input];
        }
      } else {
        if (!checkIfDayIsFree(entry.dayId, entry) && !entry.startTime) {
          return getDefaultValueForProjectOrClient(input);
        } else {
          return entry[input];
        }
      }
    },
    [
      checkIfDayIsFree,
      clientsData,
      getDefaultValueForProjectOrClient,
      projectsData,
    ],
  );

  const parseDays = useCallback(
    (days: FullReportDay[]) => {
      return days.map((entry) => ({
        date: entry.date,
        id: entry.id,
        startTime: returnHourString(entry.startTime),
        endTime: returnHourString(entry.endTime),
        breakTime: moment(entry.breakTime).isValid()
          ? mapStringToISODuration(entry.breakTime)
          : 'PT0S',
        multiplicator: 'STANDARD_100',
        client: entry.id
          ? entry.client || checkWhatToRender(entry, 'client')
          : entry.client,
        project: entry.id
          ? entry.project || checkWhatToRender(entry, 'project')
          : entry.project,
        comment: entry.comment || '',
        projectBasicInfo: getValues()
          ?.days.filter((day) => day.dayId === entry.dayId)
          .map((day) => day.projectBasicInfo)[0],
        parentId: entry?.parentId || undefined,
      }));
    },
    [checkWhatToRender, getValues],
  );

  useEffect(() => {
    if (areFilesSend) {
      setAreFilesSend(false);
      fetchFullReport(reset);
    }
  }, [areFilesSend, fetchFullReport, reset]);

  const handleFileUploadClick = useCallback(
    (entry: Partial<ArrayField<Record<string, any>>>) => () => {
      if (entry.dayId) {
        setUploadedDayId(entry.dayId);
      }
      setUploadedDayFiles(entry?.attachments);
      setOpenFileUploadArea();
    },
    [setOpenFileUploadArea, setUploadedDayFiles],
  );

  const sendFile = useCallback(
    async (file) => {
      const formFile = new FormData();
      formFile.append('file', file);

      await postFetch({
        url: `${timesheetsUrl}/${sheetId}/daily-times/${uploadedDayId}/files`,
        body: formFile,
        contentType: 'formdata',
        intl,
        label: 'ADD_FILES_TO_DAILYTIME',
      });
    },
    [sheetId, uploadedDayId, intl],
  );

  const deleteFile = useCallback(
    async (fileId: number) => {
      const { ok } = await deleteFetch({
        url: `${timesheetsUrl}/${sheetId}/daily-times/${uploadedDayId}/${fileId}`,
        intl,
        label: 'DELETE_FILES_FROM_DAILYTIME',
      });
      if (ok) {
        setFileDeleted(true);
      }
      return ok;
    },
    [intl, sheetId, uploadedDayId, setFileDeleted],
  );

  const handleFileDialogClose = () => {
    setOpenFileUploadArea();
    if (isFileDeleted) {
      setFileDeleted(false);
      fetchFullReport(reset);
    }
  };

  useEffect(() => {
    setIsFormedChanged(formState.isDirty);
  }, [formState.isDirty, setIsFormedChanged]);

  const addEntryToDailytime = useCallback(
    (entry: Partial<ArrayField<Record<string, any>, 'id'>>, index: number) =>
      async () => {
        const newEntry = {
          ...entry,
          dayId: nanoid(), // temporary id for dayId dependent logic
          id: null,
          parentId: entry.id,
          startTime: null,
          endTime: null,
          breakTime: null,
          manHours: '',
          comment: '',
          client: defaultClient,
          projectBasicInfo: defaultProject,
          project: { name: defaultProject?.name, id: defaultProject?.id },
          multiplicator: { label: 'STANDARD_100', name: 'Standard (100%)' },
        } as unknown as FullReportDay;

        insert(index + 1, newEntry);
        setProjectsOptions((prevState) => [
          ...(prevState ? prevState : []),
          getNewEntryProjects(newEntry, projectsData, defaultClient?.name),
        ]);
      },
    [insert, defaultClient, defaultProject, projectsData, setProjectsOptions],
  );

  const onSubmit = useCallback(async () => {
    setLoadingToDisableButton(true);
    const body = {
      month: date!.getMonth() + 1,
      year: date!.getFullYear(),
      days: getFormattedFullReportData(parseDays(getValues().days)),
    };

    const { ok } = await putFetch<SubmitResponseData>({
      url: `${timesheetsUrl}/${username}/${workTimeFullReportUrl}`,
      intl,
      body,
      label: 'WORK_TIME_TAB.FULL_REPORT.SUBMIT',
    });
    if (ok) {
      await fetchFullReport(reset);
      setIsFormedChanged(false);
    }
    setLoadingToDisableButton(false);
  }, [
    setLoadingToDisableButton,
    date,
    parseDays,
    getValues,
    username,
    intl,
    fetchFullReport,
    reset,
    setIsFormedChanged,
  ]);

  const onDateChange = useCallback(
    (date: SetStateAction<Date | null>) => {
      setDate(date);
    },
    [setDate],
  );
  const handleClearButton = useCallback(() => {
    setDate(moment().toDate());
  }, [setDate]);

  const handleSelectUser = useCallback(
    (e: OnChangeValue<TimesheetData, boolean>) => {
      if (e) {
        e = e as TimesheetData;
        setSelectedUser(e);
        if (e.username) {
          setTimesheetUsername(e.username);
        }
      } else {
        setTimesheetUsername('');
        setSelectedUser({ name: '', value: 0, username: '' });
      }
    },
    [setSelectedUser, setTimesheetUsername],
  );

  const getDefaultProject = useCallback(
    (entry: Partial<ArrayField<Record<string, any>, 'id'>>) => {
      if (!checkIfDayIsFree(entry.dayId, entry)) {
        return defaultProject.id ? defaultProject : undefined;
      } else {
        return undefined;
      }
    },
    [defaultProject, checkIfDayIsFree],
  );

  const onInvalidWorktime = useCallback(() => {
    const notEmptyDays = parseDays(getValues().days)?.filter(
      (day) => day.startTime !== '' || day.endTime !== '',
    );
    const invalidWorktimeNotEmptyDays = getInvalidWorktime(notEmptyDays);
    if (
      invalidWorktimeNotEmptyDays?.length ||
      (!invalidWorktimeNotEmptyDays?.length && invalidWorktime?.length)
    ) {
      setInvalidWorktime(invalidWorktimeNotEmptyDays);
    }
  }, [getValues, parseDays, invalidWorktime?.length]);

  const deleteEntryFromDailytime = useCallback(
    (_id: string, index: number) => async () => {
      setValue(`days[${index}].startTime`, '');
      setValue(`days[${index}].endTime`, '');
      onInvalidWorktime();
      remove(index);
    },
    [remove, setValue, onInvalidWorktime],
  );

  const handleRefreshAfterTimeChange = useCallback(
    (index: number) => () => {
      if (getValues().days?.length && getValues().days[index]) {
        const { startTime, endTime, breakTime } = getValues().days[index];
        if (startTime && endTime) {
          if (startTime < endTime) {
            setValue(
              `days[${index}].manHours`,
              mapISODurationToString(
                String(getWorkTimeDuration(startTime, endTime, breakTime)),
              ),
            );
          } else if (mapStringToDuration(endTime).asHours() === 0) {
            setValue(
              `days[${index}].manHours`,
              mapISODurationToString(
                String(getWorkTimeMidnightDuration(startTime, breakTime)),
              ),
            );
          }
        } else {
          setValue(`days[${index}].manHours`, '');
        }
      }
    },
    [getValues, setValue],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      rowRef?.current?.scrollIntoView?.({
        block: 'center',
        behavior: 'smooth',
      });
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [rowRef]);

  return (
    <>
      {formState.isDirty && (
        <Prompt
          when={formState.isDirty}
          message={intl.formatMessage({ id: 'PROMPT.LEAVE' })}
        />
      )}
      <GridWrapper
        container
        spacing={8}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item spacing={1} md={12} lg={6}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={5}
            style={{ maxWidth: upLg ? 250 : undefined }}
          >
            <GreyBorder>
              {reportAvailable &&
                `${intl.formatMessage({
                  id: 'SUMMARY.WORK_DAYS',
                })}: ${fullReportData?.workingDaysInMonth}`}
            </GreyBorder>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={5}>
            <Datepicker
              label={intl.formatMessage({
                id: 'TABLE_FILTER.LABEL.DATE',
              })}
              monthJumpButtons
              value={date}
              onChange={onDateChange}
              onClearButtonClick={handleClearButton}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={3}>
            {isUserAuthorized && (
              <ReactSelect
                name="users"
                label={intl.formatMessage({
                  id: 'WORK_TIME.SELECT_USER',
                })}
                options={timesheetData || []}
                defaultValue={
                  selectedUser?.name.length ? selectedUser : userData
                }
                handleChange={handleSelectUser}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item md={12} lg={6}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
          >
            <ContainedButton
              onClick={setIsDateRangeDialogOpen}
              endIcon={<DateRange />}
              style={{ marginRight: '1rem' }}
            >
              {intl.formatMessage({
                id: 'WORK_TIME_TAB.REPORT_RANGE',
              })}
            </ContainedButton>
            {isAccountingOrSales && (
              <DownloadFileButton
                marginRight="1rem"
                url={downloadExcelUrl}
                buttonLabel="BUTTON.LABEL.DOWNLOAD_AS_XLSX"
                errorLabel="DOWNLOAD_XLSX.TOAST_DENIED"
                fallbackFileName={`timesheet_${username}.xlsx`}
              />
            )}
            <AssignButton
              id="assignButton"
              endIcon={<AddIcon />}
              disabled={!reportAvailable || isTimesheetDisabled}
              size="large"
              onClick={handleAddChecked}
            >
              {intl.formatMessage({
                id: 'WORK_TIME_TAB.ASSIGN_BUTTON',
              })}
            </AssignButton>
          </Box>
        </Grid>
      </GridWrapper>
      <FullReportTableContainer component={Paper} $loading={loading}>
        <TableComponent size="small">
          <>
            <TableHeader>
              <TableRow $main>
                {WORK_TIME_FULL_REPORT_HEAD_DATA.map((headData, index) => (
                  <TableHeadCell
                    align={headData.align}
                    width={headData.width}
                    key={`${index} - ${headData.key}`}
                    $action={headData.key === 'action'}
                  >
                    {intl.formatMessage({ id: headData.label })}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {fields.length ? (
                fields.map((entry, index) => {
                  const isFreeDay = checkIfDayIsFree(entry.dayId, entry);
                  const isToday = moment().isSame(moment(entry.date), 'day');

                  const isInvalidWorktime = invalidWorktime
                    .map((day) => day.id)
                    .includes(Number(entry.id));

                  return (
                    <FullReportRow
                      key={`fullReportDay-${entry.dayId}`}
                      isFreeDay={isFreeDay}
                      isToday={isToday}
                      entry={entry}
                      index={index}
                      register={register}
                      rowRef={rowRef}
                      control={control}
                      isLeaveDay={isLeaveDay(entry.dayId)}
                      clientsData={clientsData}
                      projectOptions={getProjectsOptions(entry.dayId)}
                      invalidWorktimeTitle={getInvalidWorktimeTitle(
                        intl,
                        invalidWorktime,
                        Number(entry.dayId),
                      )}
                      isInvalidWorktime={isInvalidWorktime}
                      onInvalidWorktime={onInvalidWorktime}
                      defaultProject={getDefaultProject(entry)}
                      checkWhatToRender={checkWhatToRender}
                      handleClientChange={handleClientChange}
                      handleProjectChange={handleProjectChange}
                      isTimesheetDisabled={isTimesheetDisabled}
                      deleteEntryFromDailytime={deleteEntryFromDailytime}
                      handleFileUploadClick={handleFileUploadClick}
                      addEntryToDailytime={addEntryToDailytime}
                      handleRefreshAfterTimeChange={
                        handleRefreshAfterTimeChange
                      }
                      setValue={setValue}
                    />
                  );
                })
              ) : (
                <EmptyRow />
              )}
            </TableBody>
            {fullReportData && myTimesheetLoaded && (
              <TableFooter>
                <TableFooterRow>
                  {isEmploymentContract && (
                    <>
                      <TableFooterCell colSpan={2}>
                        {`${intl.formatMessage({
                          id: 'SUMMARY.MEDICAL_LEAVE',
                        })}: ${fullReportData.medicalLeaveDays}`}
                      </TableFooterCell>

                      <TableFooterCell>
                        {`${intl.formatMessage({
                          id: 'SUMMARY.VACATIONS',
                        })}: ${fullReportData.vacationDays}`}
                      </TableFooterCell>
                    </>
                  )}
                  <TableFooterCell
                    $whiteText
                    colSpan={isEmploymentContract ? 1 : 3}
                  >
                    {`${intl.formatMessage({
                      id: 'SUMMARY.DAYS_OFF',
                    })}: ${fullReportData.daysOff}`}
                  </TableFooterCell>
                  <TableFooterCell colSpan={isEmploymentContract ? 1 : 2}>
                    {`${intl.formatMessage({
                      id: 'TABLE.HEAD.REPORTED_DAYS',
                    })}: ${fullReportData.reportedDays}`}
                  </TableFooterCell>
                  <TableFooterCell colSpan={isUserSettled ? 1 : 3}>
                    {`${intl.formatMessage({
                      id: 'TABLE.HEAD.NOT_REPORTED_DAYS',
                    })}: ${fullReportData.unreportedDays}`}
                  </TableFooterCell>
                  {isUserSettled ? (
                    <TableFooterCell colSpan={2}>
                      <ToolTipCell
                        id="settledTooltip"
                        styles={{
                          cellTooltipStyles: { justifyContent: 'flex-end' },
                          wrapperStyles: {
                            marginTop: '0',
                            marginBottom: '0',
                          },
                        }}
                        bigger
                        text={intl.formatMessage({
                          id: 'WORK_TIME_SETTLED_TEXT',
                        })}
                        tooltip={intl.formatMessage({
                          id: 'WORK_TIME_SETTLED_TOOLTIP',
                        })}
                      />
                    </TableFooterCell>
                  ) : null}
                  <TableFooterCell />
                  <TableFooterCell $action />
                </TableFooterRow>
              </TableFooter>
            )}
          </>
        </TableComponent>
      </FullReportTableContainer>
      {fullReportData?.customDailyTimes.filter(
        (dailyTime) => dailyTime.reportingCustomData,
      ).length !== 0 && (
        <CustomProjectsContainer>
          <TableComponent size="small">
            <TableHeader>
              <TableRow $main>
                {headCustoms?.map((headData, index) => (
                  <TableHeadCell
                    align={headData.align}
                    width={headData.width}
                    key={`${index} - ${headData.key}`}
                  >
                    {intl.formatMessage({ id: headData.label })}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {fullReportData?.customDailyTimes
                .filter((dailyTime) => dailyTime.reportingCustomData === true)
                .map((custom) => (
                  <TableRow key={custom.projectId}>
                    <TableCell>{custom.projectName}</TableCell>
                    <TableCellCustom>
                      {custom.project.multiplicator}
                    </TableCellCustom>
                    <TableCellCustom>{custom.workUnit}</TableCellCustom>
                    <TableCellCustom>
                      {`${custom.reportingCustomDataType[0].toUpperCase()}${custom.reportingCustomDataType
                        .substring(1)
                        .toLowerCase()}`}
                    </TableCellCustom>
                  </TableRow>
                ))}
            </TableBody>
          </TableComponent>
        </CustomProjectsContainer>
      )}
      <Box display="flex" justifyContent="flex-end" marginTop="40px">
        <ContainedButton
          onClick={onSubmit}
          size="large"
          disabled={!formState.isDirty || loading || loadingToDisableButton}
        >
          {intl.formatMessage({
            id: `MODAL.BUTTON.SUBMIT`,
          })}
        </ContainedButton>
      </Box>
      {isDateRangeDialogOpen && (
        <ProjectDateRangeDialog
          isDialogOpen={isDateRangeDialogOpen}
          clientsData={clientsData}
          projectsData={projectsData}
          date={date}
          onClose={setIsDateRangeDialogOpen}
          fetchReport={fetchFullReport}
        />
      )}
      {addChecked && (
        <AssignProjectDialog
          addChecked={addChecked}
          handleAddChanged={handleAddChecked}
          fetchReport={fetchFullReport}
          fetchClientsAndProjects={fetchClientsAndProjects}
        />
      )}
      {openFileUploadArea && (
        <FilesUploadDialog
          files={[]}
          previouslyUploadedFiles={uploadedDayFiles}
          addChecked={openFileUploadArea}
          handleAddChanged={handleFileDialogClose}
          sendFile={sendFile}
          deleteFile={deleteFile}
          setAreFilesSend={setAreFilesSend}
        />
      )}
    </>
  );
};
export default memo(FullReportTable);
