import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { DownloadFileIcon } from 'components/Dialog/Dialog.css';
import { exportFile } from 'utils/exportFile';
import { DownloadFileButtonProps } from './types';
import * as S from './DownloadFileButton.css';

const DownloadFileButton = ({
  url,
  buttonLabel,
  errorLabel,
  fallbackFileName,
  marginRight,
}: DownloadFileButtonProps) => {
  const intl = useIntl();
  const token = localStorage.getItem('tpToken');
  const [loading, setLoading] = useState(false);

  const getFileName = (contentDisposition: string | null) => {
    if (contentDisposition) {
      return decodeURI(contentDisposition)
        .split('filename=')[1]
        .replace(/"/g, '');
    }
    return fallbackFileName;
  };

  const generateFile = async () => {
    setLoading(true);

    const res = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.ok) {
      const blob = await res.blob();
      const fileName = getFileName(res.headers.get('content-disposition'));
      exportFile(blob, fileName);
    } else {
      toast.error(intl.formatMessage({ id: errorLabel }));
    }
    setLoading(false);
  };

  return (
    <S.ContainedButton
      variant="contained"
      color="primary"
      style={{ marginRight }}
      onClick={generateFile}
      endIcon={
        loading ? (
          <S.CircularProgress color="secondary" size={29} />
        ) : (
          <DownloadFileIcon />
        )
      }
      disabled={loading}
    >
      {intl.formatMessage({ id: buttonLabel })}
    </S.ContainedButton>
  );
};

export default DownloadFileButton;
