import { Control } from 'react-hook-form';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface ControlledTimePickerProps {
  control: Control<Record<string, any>>;
  name: string;
  label?: string;
  required?: boolean;
  isDisabled?: boolean;
  type?: string;
  defaultValue?: string;
  showMidnight?: boolean;
  onChange?: (e: MaterialUiPickersDate) => void;
  onInvalidWorktime?: () => void;
  isError?: boolean;
  handleRefreshAfterTimeChange?: () => void;
  id: string;
  tooltipMessage?: string;
}

export enum TimePart {
  Hours,
  Minutes,
}
