import { useContext } from 'react';
import { useIntl } from 'react-intl';
import BenefitCheckbox from 'components/BenefitInputs/BenefitCheckbox';
import BenefitTextField from 'components/BenefitInputs/BenefitTextField';
import {
  ChildProperties,
  CostProperties,
  ErrorProperties,
  PersonCosts,
} from 'components/BenefitInputs/types';
import { getBenefitTypePropertyInput } from 'components/BenefitInputs/utils';
import { BenefitManagerContext } from 'modules/AdminPanel/Screens/BenefitsManager/BenefitManagerContext/Context';
import {
  getChildPropertyValue,
  getDefaultCostValue,
} from 'modules/AdminPanel/Screens/BenefitsManager/utils';
import { validate } from 'utils/formValidation';
import FormTooltip from '../FormTooltip';
import { getCheckboxLabel } from '../inputHelpers';
import { PersonInputsProps } from '../types';

const PersonInputs = ({
  index,
  isActive,
  control,
  errors,
  personProperty,
}: PersonInputsProps) => {
  const {
    benefitManagerState: {
      formState: { benefitTypes },
    },
  } = useContext(BenefitManagerContext);

  const intl = useIntl();
  const isEmployeeCost = personProperty === PersonCosts.employee;
  const isChildCost = personProperty === PersonCosts.child;

  const getDefaultValue = (childProperty: ChildProperties) => {
    if (isChildCost) {
      return getChildPropertyValue(
        benefitTypes,
        index,
        personProperty as PersonCosts.child,
        childProperty,
      );
    }
  };

  return (
    <>
      {isEmployeeCost ? (
        <FormTooltip
          id="employeeCostTooltip"
          label="EMPLOYEE"
          tooltip="EMPLOYEE_COST_TOOLTIP"
        />
      ) : (
        <BenefitCheckbox
          label={getCheckboxLabel(personProperty)}
          name={getBenefitTypePropertyInput(
            CostProperties.active,
            index,
            personProperty,
          )}
          control={control}
          isFullWidth={false}
          defaultChecked={benefitTypes?.[index]?.[personProperty]?.active}
        />
      )}

      {(isActive || isEmployeeCost) && (
        <>
          <BenefitTextField
            label="COST_WITH_CURRENCY"
            name={getBenefitTypePropertyInput(
              CostProperties.cost,
              index,
              personProperty,
            )}
            rules={validate(intl).positiveNumber}
            control={control}
            errors={errors}
            defaultValue={getDefaultCostValue(
              benefitTypes,
              index,
              personProperty,
            )}
            index={index}
            nameOfErrorProperty={ErrorProperties.BenefitTypes}
          />
          {isChildCost && (
            <>
              <BenefitTextField
                label="NUMBER_OF_CHILDREN"
                name={getBenefitTypePropertyInput(
                  ChildProperties.amount,
                  index,
                  personProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.amount)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
                isOnlyInteger
              />
              <BenefitTextField
                label="MIN_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  ChildProperties.minAge,
                  index,
                  personProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.minAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
              />
              <BenefitTextField
                label="MAX_CHILD_AGE"
                name={getBenefitTypePropertyInput(
                  ChildProperties.maxAge,
                  index,
                  personProperty,
                )}
                rules={validate(intl).unrequiredPositiveIntegers}
                control={control}
                errors={errors}
                defaultValue={getDefaultValue(ChildProperties.maxAge)}
                index={index}
                nameOfErrorProperty={ErrorProperties.BenefitTypes}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PersonInputs;
