import { useIntl } from 'react-intl';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { ContainedButton } from 'components/Button';
import { LinkWrapper } from 'components/Header/Header.css';
import { VIEW_MODES } from 'modules/UserProfile/consts';
import { getMissingInfoToShow } from '../utils/missingInfoDialogUtils';
import { MissingInfoType, MissingUserProfileProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
} from '../Dialog.css';

const MissingInfoDialog = ({
  onClose,
  handleAction,
  missingInfo,
  cost,
  type,
}: MissingUserProfileProps) => {
  const intl = useIntl();

  const handleClose = () => {
    onClose(false);
  };

  const handleButtonAction = () => {
    if (handleAction) {
      handleAction();
    }
    onClose(false);
  };

  const getMissingInfoMessage = () => {
    const missingFields = missingInfo?.missingFieldsList;
    let missingInfoToShow: string[] | undefined = [];
    switch (type) {
      case MissingInfoType.businessTrip:
        missingInfoToShow = missingFields?.filter(
          (el) => el === 'Contract Type' || el === 'Personal ID',
        );
        break;
      case MissingInfoType.summary:
        missingInfoToShow = missingFields;
        break;
    }

    missingInfoToShow = getMissingInfoToShow(type, missingInfoToShow)?.map(
      (el) => intl.formatMessage({ id: el }),
    );

    const getMessage = () => {
      if (
        type === MissingInfoType.businessTrip ||
        type === MissingInfoType.summary
      ) {
        return (
          <div>
            {intl.formatMessage({
              id: 'MISSING_INFO_IN_USER_PROFILE.TITLE.MESSAGE_FIRST',
            })}
            <span style={{ color: 'red' }}>
              {missingInfoToShow?.join(', ')}
            </span>
            {intl.formatMessage({
              id: 'MISSING_INFO_IN_USER_PROFILE.TITLE.MESSAGE_LAST',
            })}
          </div>
        );
      } else if (type === MissingInfoType.newBenefitCost) {
        const showCost = cost ? `${cost} PLN` : '';
        return missingInfoToShow?.join(', ') + showCost;
      }
      return missingInfoToShow;
    };

    return getMessage();
  };

  const getTitle = () => {
    switch (type) {
      case MissingInfoType.notInvoicedBT:
        return intl.formatMessage({
          id: 'MISSING_INVOICE_FOR_BUSINESS_TRIP.TITLE_MESSAGE',
        });
      case MissingInfoType.newBenefitCost:
        return intl.formatMessage({
          id: 'MISSING_NEW_BENEFIT_COST_ACCEPTANCE.TITLE',
        });
      default:
        return intl.formatMessage({
          id: 'MISSING_INFO_IN_USER_PROFILE.TITLE',
        });
    }
  };

  const getActionButtons = () => {
    switch (type) {
      case MissingInfoType.notInvoicedBT:
        return (
          <Grid container xs={12}>
            <GridItem xs={6} item>
              <ContainedButton
                id="cancelButton"
                onClick={handleClose}
                fullWidth
                size="large"
                color="secondary"
              >
                {intl.formatMessage({ id: 'STAY.BUTTON' })}
              </ContainedButton>
            </GridItem>
            <GridItem xs={6} item>
              <LinkWrapper to={'/businesstrips'}>
                <ContainedButton
                  id="takeToBusinessTrips"
                  size="large"
                >
                  {intl.formatMessage({
                    id: 'MISSING_INVOICE.FOR_BUSINESS_TRIP.BUTTON',
                  })}
                </ContainedButton>
              </LinkWrapper>
            </GridItem>
          </Grid>
        );
      case MissingInfoType.newBenefitCost:
        return (
          <GridItem xs={6} item>
            <ContainedButton
              id="acceptButton"
              onClick={handleButtonAction}
              fullWidth
              size="large"
            >
              {intl.formatMessage({ id: 'ACCEPT.BUTTON' })}
            </ContainedButton>
          </GridItem>
        );
      case MissingInfoType.companyInfo:
        return (
          <Grid container xs={12}>
            <GridItem xs={6} item>
              <ContainedButton
                id="cancelButton"
                onClick={handleClose}
                fullWidth
                size="large"
                color="secondary"
              >
                {intl.formatMessage({ id: 'STAY.BUTTON' })}
              </ContainedButton>
            </GridItem>
            <GridItem xs={6} item>
              <LinkWrapper to={`/profile?tab=${VIEW_MODES.COMPANY_INFO}`}>
                <ContainedButton
                  id="takeToUserProfile"
                  size="large"
                >
                  {intl.formatMessage({
                    id: 'MISSING_INFO_IN_COMPANY_INFO.BUTTON',
                  })}
                </ContainedButton>
              </LinkWrapper>
            </GridItem>
          </Grid>
        );
      default:
        return (
          <Grid container xs={12}>
            <GridItem xs={6} item>
              <ContainedButton
                id="cancelButton"
                onClick={handleClose}
                fullWidth
                size="large"
                color="secondary"
              >
                {intl.formatMessage({ id: 'STAY.BUTTON' })}
              </ContainedButton>
            </GridItem>
            <GridItem xs={6} item>
              <LinkWrapper to={'/profile'}>
                <ContainedButton
                  id="takeToUserProfile"
                  size="large"
                >
                  {intl.formatMessage({
                    id: 'MISSING_INFO_IN_USER_PROFILE.BUTTON',
                  })}
                </ContainedButton>
              </LinkWrapper>
            </GridItem>
          </Grid>
        );
    }
  };

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open
      onClose={handleClose}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={10} item container justify="flex-start">
            {getTitle()}
          </Grid>
          <Grid xs={12} sm={2} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <Grid style={{ margin: '2rem' }}>
          <Typography style={{ fontSize: '1.5rem' }}>
            {getMissingInfoMessage()}
          </Typography>
        </Grid>
      </DialogContentWrapper>
      <DialogActionWrapper>{getActionButtons()}</DialogActionWrapper>
    </DialogWrapper>
  );
};
export default MissingInfoDialog;
