import { useIntl } from 'react-intl';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { InformationIcon } from 'components/Table/Table.css';
import Menu from './Menu';
import MenuLink from './MenuLink';

interface Props {
  onClick: () => void;
  handleLogOut: () => void;
  anchorEl?: Element | ((element: Element) => Element) | null;
}

const AccountMenu = ({ onClick, handleLogOut, anchorEl }: Props) => {
  const intl = useIntl();
  return (
    <Menu anchorEl={anchorEl} onClose={onClick}>
      <MenuLink
        label={intl.formatMessage({ id: 'HEADER_MENU.MY_ACCOUNT' })}
        to="/profile"
        id="myAccountButton"
        onClick={onClick}
        icon={<AccountBoxIcon />}
      />
      <MenuLink
        label={intl.formatMessage({ id: 'HEADER_MENU.ABOUT' })}
        to="/about"
        id="AboutPageButton"
        onClick={onClick}
        icon={<InformationIcon $bigger />}
      />
      <MenuLink
        label={intl.formatMessage({ id: 'NAVBAR.LOGOUT_BUTTON' })}
        id="logoutButton"
        onClick={handleLogOut}
        icon={<ExitToAppIcon />}
      />
    </Menu>
  );
};

export default AccountMenu;
