import { Dispatch, SetStateAction } from 'react';
import { ArrayField, Control, DeepMap, FieldError } from 'react-hook-form';
import { FetchedFile } from 'components/Table/types';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { TransportationTypes } from './Dialog/types';

export interface BusinessTrip {
  [key: string]:
    | string
    | number
    | boolean
    | Client
    | Account
    | TripProject
    | ExpenseType[]
    | CountryDiet
    | AdditionalAccount[]
    | string[];
  id: number;
  tripReason: string;
  advance: boolean;
  city: string;
  contractType: string;
  country: string;
  currency: string;
  startTime: string;
  endTime: string;
  foreignBusinessTrip: boolean;
  insurance: boolean;
  insuranceProvidedBy: string;
  mealsProvided: boolean;
  name: string;
  surname: string;
  travelFrom: string;
  travelTo: string;
  travelReservationBy: string;
  reservationInformation: string;
  travelReservationLink: string;
  accommodationReservationBy: string;
  accommodationInformation: string;
  accommodationReservationLink: string;
  otherInformation: string;
  advanceDescription: string;
  placeToStay: string;
  numberOfNights: number;
  estimatedCostsOfStay: number;
  client: Client;
  account: Account;
  project: TripProject;
  status: TripStatuses;
  additionalAccounts: AdditionalAccount[];
  attachmentAmount: number;
  countryDiet: CountryDiet;
  summarised: boolean;
  businessTripLeadersUid: string[];
  transportation: ExpenseType[];
  billable: TripBillabilityStatus;
  billableDetails: string;
}

export enum TripBillabilityStatus {
  YES = 'YES',
  NO = 'NO',
  PARTLY = 'PARTLY',
}

interface ExpenseType {
  expenseType: string;
}
export interface TripProject {
  projectName: string;
  status: string;
}

export interface Account {
  id: number;
  username: string;
}

export interface CountryDiet {
  id: number;
  currency: string;
  diet: number;
  iso: string;
  name: string;
  value: string;
  engName: string;
}
export interface AdditionalAccount extends Account {
  name: string;
  surname: string;
  email: string;
}
export interface Client {
  clientName: string;
  status: string;
  description?: string;
}

export interface BusinessTripsFilters {
  employee?: {
    name: string;
    username: string;
    value: number;
  };
  client?: {
    value: number;
    name: string;
    clientName: string;
  };
  project?: {
    projectName: string;
    value: number;
    name: string;
  };
  status?: TripStatusesOption;
}

export interface BusinessTripDetailsProps {
  data: BusinessTrip;
  isAccountPrivileged?: boolean;
  tripsChanged: () => void;
  fetchTrips: () => Promise<void | null>;
  displayReminder: boolean;
  setDisplayReminder: Dispatch<SetStateAction<boolean>>;
  uploadedTripFiles?: FetchedFile[];
  fetchTripFiles: (id: number) => void;
  downloadFile: (fileId: number, fileName: string, tripId?: number) => void;
}

export enum TripStatuses {
  CANCELED = 'CANCELED',
  SETTLED = 'SETTLED',
  FINISHED = 'FINISHED',
  DOCS_TO_UPDATE = 'DOCS_TO_UPDATE',
  TO_BE_APPROVED_BY_LEADER = 'TO_BE_APPROVED_BY_LEADER',
  TO_BE_FINALLY_APPROVED_BY_LEADER = 'TO_BE_FINALLY_APPROVED_BY_LEADER',
  APPROVED = 'APPROVED',
  REJECTED_BY_LEADER = 'REJECTED_BY_LEADER',
  RESERVATION_TO_BE_DONE = 'RESERVATION_TO_BE_DONE',
  RESERVATION_DONE = 'RESERVATION_DONE',
  DOCS_TO_BE_VERIFIED = 'DOCS_TO_BE_VERIFIED',
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export interface TripStatusesOption {
  value: TripStatuses;
  name: string;
}

export interface BusinessTripBoxProps {
  isAccountPrivileged: boolean;
  loading: boolean;
  control: Control<BusinessTripsFilters>;
  employees: FilterOption[];
  clients: ClientsData[];
  projects: ProjectsData[];
  statusesOptions: TripStatusesOption[];
  setValue: (name: string, value: string) => void;
  onAddBusinessTrip: () => Promise<void>;
  isLeader: boolean;
  roles: string[];
  formData: BusinessTripsFilters;
  checkboxesState: CheckboxesState;
  setCheckboxesState: Dispatch<SetStateAction<CheckboxesState>>;
}

export interface QueryParams {
  pageNumber: number;
  pageSize: number;
  isAccountPrivileged: boolean;
  username?: string;
  employeeId?: number;
  clientId?: number;
  projectId?: number;
  businessTripId?: string;
  status?: TripStatuses;
  isLeader: boolean;
}

export interface CheckboxesState {
  showOnlyMine: boolean;
  showAccepted: boolean;
  showSettled: boolean;
  showReservationToBeDone: boolean;
  showToSettle: boolean;
  showToAccept: boolean;
}

export interface BusinessTripCheckboxesProps {
  shouldBeVisible: boolean;
  control: Control<BusinessTripsFilters>;
  loading: boolean;
  isLeader: boolean;
  roles: string[];
  setOpen: () => void;
  open: boolean;
  checkboxesState: CheckboxesState;
  setCheckboxesState: Dispatch<SetStateAction<CheckboxesState>>;
}
export interface ActionsProps {
  id: number;
  name: string;
  status: TripStatuses;
  fetchTrips: () => void;
  attachmentAmount: number;
  tripSummarised?: boolean;
  tripsChanged: () => void;
  handleToggleCalculator: () => void;
  displayReminder: boolean;
  setDisplayReminder: Dispatch<SetStateAction<boolean>>;
  tripBillingOpen: boolean;
  toggleTripBillingOpen: () => void;
  visibleForUser: boolean;
  isSummaryVisible: boolean;
  isLeaderOfBusinessTrip: boolean;
  forAdministration: boolean;
  forAccountancy: boolean;
}

export interface TransportationElement {
  expenseType: TransportationTypes;
}

export interface BusinessTripCalculatorProps {
  data?: BusinessTrip;
  showCalculator: boolean;
  handleToggleCalculator: () => void;
  fetchTrips?: () => void;
  status?: TripStatuses;
}
export interface TripCalculatorSummaryProps {
  handleToggleCalculator: () => void;
  fetchTrips: () => void;
  isVisiblePDF?: boolean;
}

export enum ChekboxesControllers {
  showOnlyMine = 'showOnlyMine',
  showAccepted = 'showAccepted',
  showSettled = 'showSettled',
  showReservationToBeDone = 'showReservationToBeDone',
  showToSettle = 'showToSettle',
  showToAccept = 'showToAccept',
}

export interface SelectDataForm<T> {
  value: T;
  name: string;
}

export interface CountryDietSelection extends SelectDataForm<number> {
  engName: string;
  currency: string;
}

export enum CalculatorTransportationOptions {
  PLANE = 'PLANE',
  TRAIN = 'TRAIN',
  SHIP = 'SHIP',
  COMPANY_CAR = 'COMPANY_CAR',
  PERSONAL_CAR = 'PERSONAL_CAR',
  BUS = 'BUS',
}

export interface TransportationRowProps {
  transportationOptions: SelectDataForm<CalculatorTransportationOptions>[];
  control: Control;
  index: number;
  transportationFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  transportationField: Partial<ArrayField<Record<string, any>, 'id'>>;
  remove: (index?: number) => void;
  getValues: {
    (): TripCalculatorFormValues;
    (payload: string): SelectDataForm<CalculatorTransportationOptions>;
  };
  clearErrors: (name?: string | string[]) => void;
  errors?: DeepMap<TripCalculatorFormValues, FieldError>;
  register: () => (instance: HTMLInputElement | null) => void;
}

export interface Selection {
  name: string;
  value: number | string | null;
}

export type CalculatorTransportationDataType = {
  id?: string;
  transportationId?: string;
  transportationType: SelectDataForm<CalculatorTransportationOptions> | null;
  transportationCosts: number | null;
  transportationCurrency: Selection | null;
};

export interface TripCalculatorFormValues {
  id: number;
  foreignBusinessTrip: boolean;
  country: string;
  city: string;
  currency: string;
  actualStartTime: string;
  actualEndTime: string;
  countryDietId: number;
  countryDietValue: number;
  countryDietCurrency: string;
  accomodationId?: string;
  accomodationCosts?: number;
  accomodationCurrency?: Selection;
  otherExpensesId?: string;
  otherExpensesCosts?: number;
  otherExpensesCurrency?: Selection;
  otherExpensesComment?: string;
  transportation: CalculatorTransportationDataType[];
  numberOfBreakfasts?: number;
  numberOfSuppers?: number;
  numberOfDinners?: number;
  countryDietPresent?: boolean;
  countryDietSelection?: CountryDietSelection;
  exchangeRateDate?: string;
}

export interface SummaryObject {
  price: number;
  currency: string;
  message?: string;
  type?: CalculatorTransportationOptions;
  id?: string;
}

export interface TripCalculatorSummary {
  id: number;
  rate?: {
    id: number;
    date: string;
    rate: number;
    sourceCurrency: string;
    targetCurrency: string;
  };
  tripDuration: string;
  durationStart: string;
  durationFinish: string;
  plnSummary: number;
  numberOfBreakfasts: number;
  numberOfDinners: number;
  numberOfSuppers: number;
  accommodationSummaries: SummaryObject[];
  transportationSummaries: SummaryObject[];
  otherExpensesSummaries: SummaryObject[];
  dietSummaries: SummaryObject[];
}

export interface TripCalculatorSectionProps {
  control: Control<TripCalculatorFormValues>;
  errors: DeepMap<TripCalculatorFormValues, FieldError>;
}
export interface ClearableCalculatorSectionProps
  extends TripCalculatorSectionProps {
  clearErrors: (name?: string | string[]) => void;
}

export interface CalculatorSummarySectionProps {
  businessTripSectionData: {
    name: string;
    content: string | number | null | undefined;
  }[];
  title: string;
  data?: string;
}
