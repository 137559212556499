import { Chip, Grid, GridProps } from '@material-ui/core';
import palette from 'assets/palette';
import styled from 'styled-components';

export const DetailLabel = styled.label`
  color: #676767;
  font-size: 0.85rem;
  padding: 0.5rem;
`;
export const DetailContent = styled.p`
  font-size: 0.9rem;
  flex: 1;
`;

export const GridWithLift = styled(Grid)<GridProps>`
  margin-top: -3px;
  margin-left: 3px;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -0.65rem;
`;

export const BoxDetails = styled.div`
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :last-of-type {
    border-bottom: 0;
  }
`;

export const TripLink = styled.a`
  color: black;
  text-decoration: none;
  :hover {
    color: ${palette.ACTION_DARK};
  }
`;

export const ActionsContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  align-items: center;
  &.MuiGrid-item {
    margin-left: 1rem;
    padding-left: 1rem;
  }
`;

export const FileChip = styled(Chip)`
  margin: 0 0.3rem;
`;
