import { useIntl } from 'react-intl';
import { Grid, TableCell } from '@material-ui/core';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import { useToggleState } from 'utils/hooks/useToggleState';
import { ActiveRequestTableCellsProps } from '../types';
import { EditIcon, HolidayIconButton, TrashIcon } from '../HolidaysTab.css';

const ActiveRequestsTableCells = ({
  leaderAccepted,
  requestId,
  deleteRequest,
}: ActiveRequestTableCellsProps) => {
  const [isDialogOpen, handleToggleDialog] = useToggleState(false);
  const intl = useIntl();

  const onSubmit = () => {
    deleteRequest && deleteRequest(requestId);
    handleToggleDialog();
  };

  return (
    <>
      {leaderAccepted && (
        <TableCell align="center">
          {intl.formatMessage({
            id: 'EMPLOYEES_HOLIDAY.YES',
          })}
        </TableCell>
      )}
      <TableCell align="center">
        <Grid container justify="center">
          <Grid item>
            <HolidayIconButton>
              <EditIcon />
            </HolidayIconButton>
          </Grid>
          <Grid item>
            <HolidayIconButton onClick={handleToggleDialog}>
              <TrashIcon />
            </HolidayIconButton>
          </Grid>
        </Grid>
      </TableCell>
      <ConfirmationDialog
        addChecked={isDialogOpen}
        handleAddChanged={handleToggleDialog}
        title={intl.formatMessage({
          id: 'LEAVE_REQUEST.CONFIRMATION_DIALOG.TITLE',
        })}
        content={intl.formatMessage({
          id: 'LEAVE_REQUEST.CONFIRMATION_DIALOG.CONTENT',
        })}
        handleSubmit={onSubmit}
      />
    </>
  );
};

export default ActiveRequestsTableCells;
