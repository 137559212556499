import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  StepGroup,
  StepTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { BenefitContext } from 'modules/UserProfile/Screens/Benefits/BenefitContext';
import PartnerData from './PartnerData';
import { getPartnersPersonalData } from './utils';

const Partners = () => {
  const intl = useIntl();
  const { state } = useContext(BenefitContext);

  return (
    <StepGroup container spacing={3} justifyStart>
      <StepTitle>
        {intl.formatMessage({
          id: 'USER_PROFILE.BENEFITS.FORM.SUMMARY.PARTNERS_DATA',
        })}
      </StepTitle>
      {getPartnersPersonalData(intl, state.benefitsData?.partners)?.map(
        (el, index) => {
          const isAddress = !!state?.benefitsData?.partners?.[index]?.address;
          return (
            <PartnerData
              key={`partnerSummary - ${index}`}
              summaryValues={el}
              isAddress={isAddress}
            />
          );
        },
      )}
    </StepGroup>
  );
};

export default Partners;
