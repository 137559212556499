import { Grid, List, Paper } from '@material-ui/core';
import TypographyComponent from '@material-ui/core/Typography';
import palette from 'assets/palette';
import styled from 'styled-components';

interface DateTextProps {
  $freeDay: boolean;
}

interface FreeDayAnnotationProps {
  $isCalendarHoliday: boolean;
}

export const PaperWrapper = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }
`;

export const GridWrapper = styled(Grid)`
  &.MuiGrid-container {
    margin-bottom: 20px;
  }
  display: flex;
  justify-content: center;
`;

export const FreeDaysTitle = styled(TypographyComponent)`
  &.MuiTypography-root {
    padding-top: 10px;
    font-size: 24px;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
`;

export const DayWrapper = styled.div`
  height: 50px;
  width: 70px;
  position: relative;
`;

export const DateTextWrapper = styled.div<DateTextProps>`
  padding-top: ${({ $freeDay }) => `${$freeDay ? 0 : '5px'}`};
  margin-top: ${({ $freeDay }) => `${$freeDay ? 0 : '15px'}`};
  font-size: 20px;
  bottom: 0;
  right: 0;
`;

export const FreeDayAnnotation = styled.div<FreeDayAnnotationProps>`
  color: ${palette.ACCENT_WHITE};
  background-color: ${({ $isCalendarHoliday }) =>
    `${$isCalendarHoliday ? palette.ACTION_RED : palette.ACTION_DARK}`};
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0;
`;

export const ListWrapper = styled(List)`
  &.MuiList-root {
    width: 100%;
    max-width: 600;
  }
`;
export const FreeDaysWrapper = styled.div`
  width: 600px;
  @media (max-width: 1400px) {
    width: 25rem;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1400px) {
    width: 25rem;
  }
  @media (max-width: 1300px) {
    margin-left: 2vw;
  }
`;
