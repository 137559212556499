import {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  MutableRefObject,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react';
import { ArrayField, Control } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import { TableCellProps } from '@material-ui/core/TableCell';
import { TableContainerProps as TableContainerInterface } from '@material-ui/core/TableContainer';
import { TableRowProps as TableRowInterface } from '@material-ui/core/TableRow';
import { TypographyProps } from '@material-ui/core/Typography';
import { AccountingNote } from 'components/AccountingNotesList/types';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import {
  Account,
  BusinessTrip,
  Client,
  CountryDiet,
  TripProject,
} from 'modules/BusinessTrips/types';
import { FormData as ProjectFormData } from 'modules/Projects/types';
import { Details } from 'modules/Timesheets/types';
import {
  ClientData,
  ProjectData,
  StatusTypes,
  TimesheetData,
} from 'modules/WorkTime/types';
import { FetchResponse } from 'utils/fetchFunctions';
import { TripStatuses } from './../../modules/BusinessTrips/types';
import { ProjectCategories } from './../Dialog/types';

export type ValueOf<T> = T[keyof T];

export interface DefaultProjectsData {
  name: string;
  value: number;
  clientName?: string;
}

export interface Report {
  [key: string]: any;
  reportedHours: number;
  coreHours: boolean;
  multiplier: string;
  project: string;
  client: string;
  month: number;
  name: string;
  workInHours: number;
  workInDays: number;
  year: number;
}

interface CustomProject {
  name: string;
  id: number;
  multiplicator: string;
  multiplicatorValue: number;
  projectCategory: string;
  workUnit: string;
}

export interface CustomDailyTime {
  clientId: number;
  clientName: string;
  id: number;
  multiplicator: number;
  projectDescription: string;
  projectId: number;
  projectName: string;
  reportingCustomData: boolean;
  reportingCustomDataType: string;
  workUnit: number;
  project: CustomProject;
}

export interface ProjectBasicInfo {
  id: number;
  name: string;
  multiplier: string;
  projectCategory: ProjectCategories;
}
export interface FullReport {
  month: string;
  year: string;
  workingDaysInMonth: number;
  medicalLeaveDays: number;
  vacationDays: number;
  reportedDays: number;
  unreportedDays: number;
  days: FullReportDay[];
  daysOff: number;
  defaultClientId: number;
  defaultClientName: string;
  defaultProjectId: number;
  defaultProjectName: string;
  settled: boolean | null;
  customDailyTimes: CustomDailyTime[];
  projectBasicInfo: ProjectBasicInfo;
}

export interface SubmitResponseData {
  workingTime: { workingTimeAsManDays: number };
  medicalLeaveDaysCount: number;
  vacationDaysCount: number;
  unreportedDays: number;
}

export interface FetchedFile {
  contentType: string;
  dailyTime: {
    clientName: string;
    comment: string;
    id: number;
    projectName: string;
  };
  id: number;
  originalFileName: string;
  uploadDate: string;
  uploadedBy: string;
  addedByUserWithRole: string;
  visibleForUserWithRole: string;
}

export interface AccountExclude {
  username: string;
  id: number;
}

export interface BankHolidayExclusions {
  id: number;
  title: string;
  type: string;
  date: string;
  description: string;
  client: DayClientProps | null;
  project: DayProjectProps | null;
  account: AccountExclude | null;
  applyToAllEmployees: boolean;
  exclusions: {
    excludedClients: Array<DayClientProps>;
    excludedProjects: Array<DayProjectProps>;
    excludedAccounts: Array<AccountExclude>;
  };
}
export interface FullReportDay {
  id: string;
  dayId: string;
  date: string;
  attachments: FetchedFile[];
  dayOfMonth: number;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
  manHours?: string;
  breakTime: string;
  parentId?: string;
  comment?: string;
  containsBankHoliday: boolean;
  bankHolidayDTOList: Array<BankHolidayExclusions>;
  multiplicator: string;
  project: DayProjectProps;
  client: DayClientProps;
  projectBasicInfo: ProjectBasicInfo;
}

export interface DayClientProps {
  id: number;
  name: string;
  value?: number;
  customFields?: CustomField[];
  description?: string;
  clientName?: string;
}
export interface DayProjectProps {
  id: number;
  name: string;
  value?: number;
  billable?: boolean;
  client?: {
    id: number;
    clientName: string;
    description: string;
  };
  contactPerson?: string;
  customFields?: CustomField[];
  projectName?: string;
  projectCategory?: ProjectCategories;
}

export interface DayProjectOptionValue {
  value: number;
  name: string;
  multiplier: string;
  status: StatusTypes;
  reportingCustomData: boolean;
  projectCategory: ProjectCategories;
  tooltipMessage?: string;
}

export interface ParsedDaysProps {
  date: string;
  id: string;
  startTime: string;
  endTime: string;
  breakTime: string;
  multiplicator?: string;
  client: DayClientProps;
  project: DayProjectProps;
  comment: string;
  parentId?: string;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface Multiplicator {
  value?: number;
  name: string;
  label: string;
}
export interface Summary {
  totalReportedDays: number;
  benefitsCostSummary: number | null;
  totalReportedHours: number;
  monthWorkdays: number;
  unreportedDays: number;
  medicalLeave: string;
  daysOff: string;
  vacations: string;
  status: SummaryStatus;
  mailStatus: EmailStatus;
  attachedFiles: string[];
  additionalInformation: string;
  settled: boolean;
  customDailyTimes: CustomDailyTime[];
}

export type SingleProject = {
  projectId: number;
  project: string;
  workTime: string;
  projectDescription: string;
  billable: boolean | null;
  approved: boolean;
};

export type SingleClient = {
  client: string;
  clientDescription: string;
  projectsList: SingleProject[];
};

export interface Timesheet {
  remoteWorkDaysCount: number;
  timesheetReportId: number;
  fullName: string;
  username: string;
  workingTimeSummaryDTO: SingleClient[];
  totalWorkingTimeDuration: string;
  overtimeDuration: string;
  unreportedDaysCount: number;
  vacationDaysCount: number;
  medicalLeaveDaysCount: number;
  daysOffCount: number;
  teamLeaders: TeamLeader[];
  approved: boolean;
  approverFullName: string;
  approvedByUser: boolean;
  explanationNotes?: string;
  approvalStatus: string;
  unapprovedBy?: string;
  settled: boolean;
}
export interface Project {
  [key: string]: string | number | boolean | CustomField[] | AccountingNote[];
  id: number;
  description: string;
  project: string;
  client: string;
  accountingNotes: AccountingNote[];
  addingUser: string;
  contactPerson: string;
  billable: boolean;
  clientCustomFields: CustomField[];
  projectCustomFields: CustomField[];
  isDeletable: boolean;
  status: StatusTypes;
  reportingCustomDataType: string;
  projectCategory: string;
}

export interface TeamLeader {
  fullName: string;
  userName: string;
}

export type TableCellData =
  | string
  | number
  | boolean
  | unknown[]
  | Client
  | Account
  | TripProject
  | CountryDiet;

type Align = 'left' | 'center' | 'right';

export interface TableHead {
  label: string;
  key: string;
  align?: Align;
  sortable: boolean;
  role: string;
  width?: string;
  style?: CSSProperties;
  tooltip?: string;
}

export interface Sorting {
  column: string;
  direction: 'asc' | 'desc';
}

export interface SortingData {
  sorting: Sorting;
  setSorting: Dispatch<SetStateAction<Sorting>>;
}

export interface AllTableProps {
  onMasterApprovalClick?: (id: number, teamLeaders: TeamLeader[]) => void;
  setRowsPerPage?: (event: ChangeEvent<HTMLInputElement>) => void;
  setCurrentPage?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  withPagination?: boolean;
  headCustoms?: Array<TableHead>;
  rowsPerPage?: number;
  currentPage?: number;
  pageCount?: number;
  loading?: boolean;
  limit?: number;
}

export interface TableProps extends AllTableProps {
  head: Array<TableHead>;
  data: Report[];
  sorting?: Sorting;
  setSorting?: Dispatch<SetStateAction<Sorting>>;
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  setShowMultiplicators?: Dispatch<SetStateAction<boolean>>;
  toggleWorktimeDisplay?: Dispatch<SetStateAction<boolean>>;
  toggleSorting: (
    column: string,
    sorting: {
      column: string;
      direction: 'asc' | 'desc';
    },
    setSorting: Dispatch<any>,
  ) => void;
}

export interface TableAdminPanelProps extends AllTableProps {
  head: Array<TableHead>;
  sorting?: Sorting;
  setSorting?: Dispatch<SetStateAction<Sorting>>;
}

export interface TableFullReportProps extends AllTableProps {
  userContract: ContractTypes | null;
  clientsData?: ClientData[];
  fetchClientsAndProjects: () => void;
  projectsData: ProjectData[];
  setTimesheetUsername: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<Date | null>>;
  date: Date | null;
  timesheetData?: TimesheetData[];
  setSelectedUser: Dispatch<SetStateAction<TimesheetData | undefined>>;
  setIsFormedChanged: Dispatch<SetStateAction<boolean>>;
  selectedUser?: TimesheetData;
  myTimesheetLoaded: boolean;
  isUserAuthorized: boolean;
  loading: boolean;
  reportAvailable: boolean;
  defaultProject: {
    id: string | null;
    name: string | null;
    multiplicator: string | null;
    multiplicatorValue: string | null;
    projectCategory: string | null;
  };
  rowRef: MutableRefObject<HTMLTableRowElement | null>;
  defaultClient: {
    id: string | null;
    name: string | null;
  };
  sheetId: number;
  fullReportData: FullReport;
  projectsOptions: ProjectOptions[] | undefined;
  setProjectsOptions: Dispatch<SetStateAction<ProjectOptions[] | undefined>>;
  fetchFullReport: (
    reset?: ((values?: FullReport | undefined) => void) | undefined,
  ) => Promise<void>;
}

export interface FullReportProps extends AllTableProps {
  clientsData?: ClientData[];
  fetchClientsAndProjects: () => void;
  projectsData: ProjectData[];
  renderEmptyDataInfo: JSX.Element;
  timesheetUsername: string;
  setTimesheetUsername: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<Date | null>>;
  date: Date | null;
  timesheetData?: TimesheetData[];
  setSelectedUser: Dispatch<SetStateAction<TimesheetData | undefined>>;
  setIsFormedChanged: Dispatch<SetStateAction<boolean>>;
  selectedUser?: TimesheetData;
  myTimesheetLoaded: boolean;
  isUserAuthorized: boolean;
  setIsUsersFetched: Dispatch<SetStateAction<boolean>>;
  userContract: ContractTypes | null;
}

export interface TableSummaryProps extends AllTableProps {
  renderEmptyDataInfo: JSX.Element;
  timesheetUsername: string;
  setTimesheetUsername: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<Date | null>>;
  date: Date | null;
  timesheetData?: TimesheetData[];
  setSelectedUser: Dispatch<SetStateAction<TimesheetData | undefined>>;
  setIsFormedChanged: Dispatch<SetStateAction<boolean>>;
  selectedUser?: TimesheetData;
  myTimesheetLoaded: boolean;
  isUserAuthorized: boolean;
  setApprovedAndSendLoading: Dispatch<SetStateAction<boolean>>;
  userContract: ContractTypes | null;
}

export interface TableProjectProps extends AllTableProps {
  head: Array<TableHead>;
  fetchProjects: () => Promise<void>;
  data: Project[];
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  addChecked: boolean;
  handleAddChanged: () => void;
  formData: ProjectFormData;
  deleteCustomField: (id: number, url: string) => Promise<void>;
  changeProjectStatus: (id: number) => Promise<void>;
  isAllowedForActions: boolean;
}

export interface TableBusinessTripsProps extends AllTableProps {
  head: Array<TableHead>;
  fetchTrips: () => Promise<void | null>;
  tripsChanged: () => void;
  data: BusinessTrip[];
  showDescription: boolean;
  selectedId: number;
  renderIcon: (id: number) => ReactNode;
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  isAccountPrivileged?: boolean;
  isLeader?: boolean;
  withNameColumn?: boolean;
  instantlyChangeTripState?: (id: number, state: TripStatuses) => void;
}

export interface TableClientProps extends AllTableProps {
  head: Array<TableHead>;
  fetchClients: () => Promise<void>;
  data: ClientProps[];
  sorting?: Sorting;
  setSorting?: Dispatch<SetStateAction<Sorting>>;
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => void;
  addChecked: boolean;
  handleAddChanged: () => void;
  deleteCustomField: (id: number, url: string) => Promise<void>;
  client?: {
    value?: number;
    name?: string;
  };
  changeClientStatus: (id: number) => Promise<void>;
  isAllowedForActions: boolean;
}

export interface TimesheetTableProps extends AllTableProps {
  head: Array<TableHead>;
  data: Timesheet[];
  emptyDataMessage?: string;
  onMasterUndoApprovalClick: (id: number) => void;
  onExplanationClick: (
    id: number,
    note: string,
    actionType: 'reminder' | 'explanation',
  ) => Promise<FetchResponse>;
  onLockIconClick: (username: string, settled: boolean) => void;
  onMasterApprovalClick: (id: number, teamLeaders: TeamLeader[]) => void;
  onLeaderPartialApprovalClick: (
    timesheetId: number,
    projectId: number,
    index: number,
  ) => void;
  onLeaderPartialUndoApprovalClick: (
    timesheetId: number,
    projectId: number,
    index: number,
  ) => void;
  detailsVisibility: Details;
  setDetailsVisibility: (id: number) => void;
  setCurrentPage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    value: number,
  ) => void;
  totalWorkingTime: string;
}

export interface TableContainerProps extends TableContainerInterface {
  component?: ReactNode;
  $loading?: boolean;
  overflow?: boolean;
}

export interface TableRowProps extends TableRowInterface {
  index?: number;
  $main?: boolean;
  $weekend?: boolean;
  $selected?: boolean;
  $today?: boolean;
}

export interface TableCellPropsExtended extends TableCellProps {
  position?: string;
  $whiteText?: boolean;
  $action?: boolean;
  $details?: boolean;
  $smaller?: boolean;
}

export interface TypographyPropsExtended extends TypographyProps {
  $fullWidth?: boolean;
}

export interface FormValues {
  accountingNotes: string;
  client?: {
    value: number;
    name: string;
  };
  contactPerson?: {
    value: number;
    name: string;
  };
  description: string;
  projectName: string;
  status: string;
}

export interface ClientProps {
  [key: string]: string | number | boolean | CustomField[] | AccountingNote[];
  accountingNotes: AccountingNote[];
  clientName: string;
  description: string;
  status: string;
  clientCustomFields: CustomField[];
  id: number;
  hasTimeReported: boolean;
  hasRelatedProjects: boolean;
}

export interface RuleProps {
  client?: {
    id: number;
    name: string;
    value: number;
    clientName: string;
    description: string;
  };
  project?: {
    id: number;
    name: string;
    value: number;
  };
  employee?: {
    id: number;
    name: string;
    surname: string;
    value: number;
  };
  leaders?: LeaderProps[];
  alwaysManualApproval: boolean;
  ifOvertime: boolean;
  ifWorktimeOnHoliday: boolean;
  ifWorktimeOnWeekend: boolean;
  ifECMinimalBreakViolated: boolean;
  id: number;
  status?: string;
}

interface LeaderProps {
  id: number;
  name: string;
  surname: string;
  username: string;
  value: number;
}

export type ApprovalStatus =
  | 'APPROVED'
  | 'OVERTIMES_REQUIRE_APPROVAL'
  | 'REPORT_REQUIRE_APPROVAL'
  | 'WAITING_FOR_USER_APPROVAL'
  | 'NOT_READY_FOR_APPROVAL';

export interface CustomField {
  id: number;
  obligatory: boolean;
  description: string;
  defaultValue: string | number | null;
  label: string;
  projectId: number | null;
  clientId: number | null;
  value: string | number | null;
}

export interface ProjectCustomFields {
  projectName: string;
  fields: CustomField[];
}

export interface RenderActionIconsProps<T> {
  renderEditIcon: (data: T, id: number) => ReactNode;
  setDialogData: (value: SetStateAction<any | undefined>) => void;
  index: number;
  data: T;
  intl: IntlShape;
  entry: Project | ClientProps | RuleProps;
  isAccountPrivileged?: boolean;
  handleShowConfimationDialog: () => void;
  isArchivable?: boolean;
  isDeletable?: boolean;
  elementToRemove?: 'project' | 'client';
  handleActivateClicked?: () => void;
  isAllowedForActions?: boolean;
  isJitOther?: boolean;
}

export interface ClientCustomFields {
  clientName: string;
  fields: CustomField[];
  projectsWithFields: ProjectCustomFields[];
}

export interface CustomFieldsTableProps {
  customFieldsData: ClientCustomFields[] | undefined;
  fetchCustomFields: () => Promise<void>;
  timesheetId?: number;
  isDisabled?: boolean;
  username?: string;
  summarySettled?: boolean;
}

export interface AccountUser {
  id: number;
  name: string;
  surname: string;
  username: string;
}

export interface CustomFieldsRowProps {
  intl: IntlShape;
  onAddCustomFieldClicked: (index: number) => void;
  index: number;
  isAccountPrivileged?: boolean;
}

export interface FullReportTableCellProps extends TableCellProps {
  $today?: boolean;
}

export interface InvalidWorktimeProps {
  type: 'overlapped' | 'equal';
  id: number;
}

export interface ProjectOptions {
  dayId: string | null;
  projects: DayProjectOptionValue[];
}
export interface FullReportRowProps {
  isFreeDay: boolean;
  isToday: boolean;
  isInvalidWorktime: boolean;
  isTimesheetDisabled: boolean;
  isLeaveDay: boolean;
  index: number;
  control: Control<FullReport>;
  clientsData: ClientData[] | undefined;
  invalidWorktimeTitle: string;
  projectOptions?: DayProjectOptionValue[];
  entry: Partial<ArrayField<Record<string, any>, 'id'>>;
  rowRef: MutableRefObject<HTMLTableRowElement | null>;
  defaultProject?: {
    id: string | null;
    name: string | null;
    multiplicator: string | null;
    multiplicatorValue: string | null;
    projectCategory: string | null;
  };
  register: () =>
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null
    | undefined;
  onInvalidWorktime: () => void;
  handleRefreshAfterTimeChange: (index: number) => () => void;
  handleClientChange: (
    dayId: string,
    index: number,
  ) => (_e: ClientData) => void;
  handleProjectChange: (
    dayId: string,
    index: number,
  ) => (e: any) => Promise<void>;
  deleteEntryFromDailytime: (id: string, index: number) => () => Promise<void>;
  addEntryToDailytime: (entry: any, index: number) => () => Promise<void>;
  handleFileUploadClick: (
    entry: Partial<ArrayField<Record<string, any>>>,
  ) => () => void;
  checkWhatToRender: (
    entry: Partial<ArrayField<Record<string, any>>>,
    input: 'project' | 'client',
  ) => any;
  setValue: <TFieldName extends string>(
    name: TFieldName,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void;
}

export enum GetProjectTypeEnum {
  addNew = 'addNew',
  addEntry = 'addEntry',
  deleteEntry = 'deleteEntry',
}

export enum SummaryStatus {
  NEEDS_LEADER_APPROVAL = 'NEEDS_LEADER_APPROVAL',
  WAITING_FOR_LEADER_APPROVAL = 'WAITING_FOR_LEADER_APPROVAL',
  READY_TO_BE_SENT_OUT = 'READY_TO_BE_SENT_OUT',
  SENT_OUT = 'SENT_OUT',
  SETTLED_AND_BLOCKED = 'SETTLED_AND_BLOCKED',
  NOT_READY_FOR_APPROVAL = 'NOT_READY_FOR_APPROVAL',
}

export enum EmailStatus {
  SENDING = 'SENDING',
  ERROR = 'ERROR',
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  SENT_BUT_EDITED = 'SENT_BUT_EDITED',
}
