import { SvgIconProps } from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import palette from 'assets/palette';
import styled from 'styled-components';

export const ReplayIcon = styled(Replay)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;
