import palette from 'assets/palette';
import styled from 'styled-components';

export const SummaryInformationWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 80px;
  margin-top: 48px;

  ::before {
    position: absolute;
    top: -24px;
    width: 100%;
    height: 1px;
    background-color: ${palette.ACCENT_LIGHT};
    content: '';
  }

  @media (max-width: 600px) {
    gap: 30px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 16px;
`;

export const CheckboxWrapper = styled.div`
  position: relative;

  .MuiIconButton-root.info-icon {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);

    @media (max-width: 600px) {
      display: none;
    }
  }
`;
