import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LinearProgress } from '@material-ui/core';
import { Block } from '@material-ui/icons';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableIconButton,
  TableRow,
  Tooltip,
} from 'components/Table/Table.css';
import { EXCLUDE_TIMESHEET_HEAD_DATA as head } from 'modules/AdminPanel/static_data';
import { ExcludedEmployeeData } from './types';
import { ReplayIcon } from './ExcludeTimeSheetTable.css';

interface Props {
  excludedEmployees: ExcludedEmployeeData[];
  loading: boolean;
  handleDelete: (username: string) => Promise<void>;
}

const ExcludeTimeSheetTable = ({
  excludedEmployees,
  loading,
  handleDelete,
}: Props) => {
  const intl = useIntl();

  const renderTableHeadRow = useMemo(
    () =>
      head.map((headData, index) => (
        <TableCell
          align={headData.align}
          key={`${index} - ${headData.key}`}
          width={headData.width}
        >
          {intl.formatMessage({ id: headData.label })}
        </TableCell>
      )),
    [intl],
  );

  return (
    <TableContainer component={Paper} $loading={loading}>
      {loading && <LinearProgress />}
      <Table>
        <TableHeader>
          <TableRow $main>{renderTableHeadRow}</TableRow>
        </TableHeader>
        <TableBody>
          {excludedEmployees?.length ? (
            excludedEmployees.map(
              ({ account: { name, surname, username } }, index) => (
                <TableRow key={` - ${index}`} index={index}>
                  <TableCell>{`${name} ${surname}`}</TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'ADMIN_PANEL.EXCLUDE_REPLAY_ACTION',
                      })}
                      placement="top"
                    >
                      {
                        <TableIconButton>
                          <ReplayIcon onClick={() => handleDelete(username)} />
                        </TableIconButton>
                      }
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ),
            )
          ) : (
            <TableRow>
              <TableCell align={'center'} colSpan={head.length}>
                <Block />
                <p>{intl.formatMessage({ id: 'TABLE.BODY.EMPTY_DATA' })}</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExcludeTimeSheetTable;
