import CircularProgressComponent from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { ContainedButton as ContainedButtonComponent } from 'components/Button';

export const CircularProgress = styled(CircularProgressComponent)`
  &.MuiCircularProgress-root {
    margin-left: 0.2rem;
  }
`;

export const ContainedButton = styled(ContainedButtonComponent)`
  && {
    /* float: right; */
  }
`;
