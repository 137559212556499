import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { patternForDecimalNumber } from 'utils/regExps';
import { ControlledTextFieldProps, EventType } from './types';
import { TextFieldComponent } from './ControlledTextField.css';

const ControlledTextField = ({
  control,
  label,
  labelTranslated,
  name,
  required,
  variant,
  isMulti,
  size,
  placeholder,
  type,
  defaultValue,
  isDisabled,
  handleChange,
  validate = false,
  rules,
  helperText,
  rows,
  isDisabledTotalTime,
  isOnlyInteger,
  isOnlyDecimals,
  isNonNegative = false,
  id,
  maxLength,
}: ControlledTextFieldProps) => {
  const intl = useIntl();

  const validationRules =
    validate || rules
      ? {
          required,
          validate: rules?.validate,
          maxLength: rules?.maxLength,
          minLength: rules?.minLength,
          pattern: rules?.pattern,
          min: rules?.min,
          max: rules?.max,
        }
      : { required };

  const isError = (props: { value: string }) =>
    (!isDisabled && validate && !props.value) || (rules && validate);

  const getRowsCount = () => {
    if (!isMulti) {
      return 1;
    }
    return rows ? rows : 3;
  };

  const getOnlyIntegers = (
    event: EventType,
    onChange: (...event: any[]) => void,
  ) => {
    const regex = /^\d*$/;
    const value = event.target.value;
    const onlyNumber = value
      .split('')
      .filter((el) => el.match(regex))
      .join('');
    onChange(Number(onlyNumber));
  };

  const getNonNegativeNumber = (
    event: EventType,
    onChange: (...event: string[]) => void,
  ) => {
    const value = event.target.value;
    const nonNegativeValue = value.replaceAll('-', '');
    onChange(nonNegativeValue);
  };

  const getDecimalNumbers = (
    event: EventType,
    onChange: (...event: string[]) => void,
  ) => {
    const value = event.target.value;
    const decimalNumber = value.match(patternForDecimalNumber)?.[0] ?? '';
    onChange(decimalNumber);
  };

  const handleChangeTextField = (
    e: EventType,
    onChange: (e: EventType | string) => void,
  ) => {
    onChange(e);
    if (isOnlyInteger) {
      getOnlyIntegers(e, onChange);
    }
    if (isOnlyDecimals) {
      getDecimalNumbers(e, onChange);
    }
    if (isNonNegative) {
      getNonNegativeNumber(e, onChange);
    }
    if (handleChange) {
      handleChange(e);
    }
  };

  const getCorrectDefaultValue = () => {
    if (isOnlyInteger) {
      return defaultValue;
    }
    return defaultValue || '';
  };

  return (
    <Controller
      name={name}
      rules={validationRules}
      control={control}
      type={name}
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: required || false,
        'aria-label': 'description',
      }}
      defaultValue={getCorrectDefaultValue()}
      render={({ onChange, ...props }) => (
        <TextFieldComponent
          fullWidth
          disabled={isDisabled}
          onChange={(e) => handleChangeTextField(e, onChange)}
          error={isError(props)}
          type={type}
          color="primary"
          variant={variant}
          multiline={isMulti}
          rows={getRowsCount()}
          rowsMax={getRowsCount()}
          size={size}
          placeholder={placeholder}
          label={
            labelTranslated || (label && intl.formatMessage({ id: label }))
          }
          {...props}
          helperText={helperText}
          disabledStyle={isDisabledTotalTime}
          id={id || name}
          inputProps={{ maxLength: maxLength }}
        />
      )}
    />
  );
};

export default ControlledTextField;
