import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const TIMEREPORTS_HEAD_DATA: TableHead[] = [
  {
    label: 'TABLE.HEAD.CLIENT',
    key: 'clientName',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.ADDING_USER',
    key: 'addingUser',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.DETAILS',
    key: 'description',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
  {
    label: 'TABLE.HEAD.ACTION',
    key: 'action',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
  },
];
