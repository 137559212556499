import {
  Dialog,
  Grid,
  IconButton,
  IconButtonProps,
  SvgIconProps,
  TableCell,
} from '@material-ui/core';
import { Reorder } from '@material-ui/icons';
import palette from 'assets/palette';
import styled from 'styled-components';
import { ContainedButton } from 'components/Button';
import { HolidayStatus, StatusStyleProps } from './types';

const handleColorType = (color: HolidayStatus | undefined) => {
  switch (color) {
    case HolidayStatus.ACCEPTED:
      return '#22be31';
    case HolidayStatus.REJECTED:
      return '#ff3636';
    case HolidayStatus.IN_PROGRESS:
      return `${palette.ACCENT_LIGHT}`;
    case HolidayStatus.TO_BE_REVIEWED:
      return `${palette.ACCENT_LIGHT}`;
    default:
      return '#000';
  }
};
interface StyledIconButtonProps extends IconButtonProps {
  $active?: boolean;
}
export const StatusGrid = styled(Grid)<StatusStyleProps>`
  color: ${({ color }) => handleColorType(color)};
  font-weight: 700;
`;

export const StatusTableCell = styled(TableCell)<StatusStyleProps>`
  && {
    color: ${({ color }) => handleColorType(color)};
  }
`;
export const DetailsIcon = styled(Reorder)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;
export const CommentGrid = styled(Grid)`
  font-size: 14px;
`;

export const InfoGrid = styled(Grid)`
  font-weight: 700;
`;

export const RejectContainedButton = styled(ContainedButton)`
  && {
    background-color: #000000dd;
    color: white;
    margin: 0 0.5rem;
    width: 100px;
  }
  &.MuiButton-containedPrimary:hover {
    background-color: #000;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const TabelWrapper = styled.div`
  padding: 2rem;
`;
export const FilterWrapper = styled.div`
  padding: 0 2rem;
`;

export const HeaderWrapper = styled.div`
  height: 30px;
  display: flex;
  background: ${palette.ACCENT_WHITE};
  border-bottom: #c4c4c4 1px solid;
  font-weight: 700;
  margin-bottom: 30px;
  position: relative;
`;
export const CloseIconButton = styled(IconButton)<StyledIconButtonProps>`
  &.MuiIconButton-root {
    color: rgb(0 0 0);
    padding: 0;
    position: absolute;
    right: 5px;
  }
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
  &.MuiIconButton-root.Mui-disabled {
    color: ${({ $active }) => $active && palette.ACTION_DARK};
  }
`;
export const StyledTableCell = styled(TableCell)`
  && {
    font-weight: 700;
  }
`;

export const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    background-color: transparent;
  }
  & .MuiDialog-scrollPaper {
    align-items: center;
    justify-content: center;
  }
  & .MuiDialog-paper {
    margin: 1rem;
    padding: 2rem;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 30vw;
    min-width: 300px;
  }
  & .MuiDialog-paper {
    overflow-y: hidden;
  }
`;
