import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { ShortText } from '@material-ui/icons';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import {
  CustomFieldsActionsWrapper,
  TableIconButton,
  TrashIcon,
} from 'components/Table/Table.css';
import { CustomField } from 'components/Table/types';
import { useToggleState } from 'utils/hooks/useToggleState';
import {
  CustomFieldsListWrapper,
  ListItemWrapper,
} from './CustomFieldsList.css';

interface CustomFieldListProps {
  data: CustomField[];
  deleteCustomField: (id: number, url: string) => Promise<void>;
  url: string;
  isAccountPrivileged: boolean;
  renderCustomFieldEditIcon?: (data: CustomField[], id: number) => ReactNode;
}

const CustomFieldsList = ({
  data,
  deleteCustomField,
  url,
  isAccountPrivileged,
  renderCustomFieldEditIcon,
}: CustomFieldListProps) => {
  const [showConfirmationDialog, handleToggleConfirmationDialog] =
    useToggleState(false);
  const [field, setField] = useState<CustomField>();
  const intl = useIntl();

  return (
    <CustomFieldsListWrapper>
      <List>
        {data.map((field: CustomField, index: number) => (
          <ListItemWrapper key={`accounting-note-${field.label}`}>
            <ListItem key={`accounting-note-item-${field.label}`}>
              <ListItemAvatar>
                <Avatar>
                  <ShortText />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={field.label}
                secondary={field.description}
              />
              {isAccountPrivileged && (
                <CustomFieldsActionsWrapper>
                  {renderCustomFieldEditIcon &&
                    renderCustomFieldEditIcon(data, index)}
                  <TableIconButton
                    onClick={() => {
                      setField(field);
                      handleToggleConfirmationDialog();
                    }}
                  >
                    <TrashIcon />
                  </TableIconButton>
                </CustomFieldsActionsWrapper>
              )}
            </ListItem>
          </ListItemWrapper>
        ))}
      </List>
      {handleToggleConfirmationDialog && (
        <ConfirmationDialog
          addChecked={showConfirmationDialog}
          handleAddChanged={handleToggleConfirmationDialog}
          handleSubmit={() => field && deleteCustomField(field.id, url)}
          title={intl.formatMessage({
            id: 'CUSTOM_FIELDS.DELETE_DIALOG_TITLE',
          })}
          content={intl.formatMessage({
            id: 'CUSTOM_FIELDS.DELETE_DIALOG_CONTENT',
          })}
        />
      )}
    </CustomFieldsListWrapper>
  );
};

export default CustomFieldsList;
