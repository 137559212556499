import { Link } from 'react-router-dom';
import { SvgIconProps, TextField } from '@material-ui/core';
import BoxComponent, { BoxProps } from '@material-ui/core/Box';
import CheckboxComponent, { CheckboxProps } from '@material-ui/core/Checkbox';
import CircularProgressComponent, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import Grid, { GridProps } from '@material-ui/core/Grid';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import PaperComponent, { PaperProps } from '@material-ui/core/Paper';
import TableComponent, { TableProps } from '@material-ui/core/Table';
import TableBodyComponent, {
  TableBodyProps,
} from '@material-ui/core/TableBody';
import TableCellComponent, {
  TableCellProps,
} from '@material-ui/core/TableCell';
import TableContainerComponent from '@material-ui/core/TableContainer';
import TableFooterComponent, {
  TableFooterProps,
} from '@material-ui/core/TableFooter';
import TableHeadComponent, {
  TableHeadProps,
} from '@material-ui/core/TableHead';
import TablePaginationComponent, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import TableRowComponent from '@material-ui/core/TableRow';
import TooltipComponent, { TooltipProps } from '@material-ui/core/Tooltip';
import TypographyComponent, {
  TypographyProps,
} from '@material-ui/core/Typography';
import {
  Add,
  Autorenew,
  Backup,
  CheckCircleOutline,
  Clear,
  Code,
  Comment,
  DateRange,
  Delete,
  Edit,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
  NoteAdd,
  NotificationsActive,
  PauseCircleOutlineOutlined,
  PlayCircleOutline,
  PlaylistAdd,
  Remove,
  SaveAlt,
  Stars,
  SyncAlt,
  Telegram,
} from '@material-ui/icons';
import { WarningAmber } from '@mui/icons-material';
import palette from 'assets/palette';
import styled, { css } from 'styled-components';
import ContainedButton from 'components/Button/ContainedButton';
import {
  FullReportTableCellProps,
  TableCellPropsExtended,
  TableContainerProps,
  TableRowProps,
  TypographyPropsExtended,
} from './types';

interface SortingIcon extends SvgIconProps {
  active?: 1 | 0;
  primaryColor?: 'black' | 'white';
}

interface InfoIcon extends SvgIconProps {
  $bigger?: boolean;
  $alignToEnd?: boolean;
}

interface BillabilityIconProps extends SvgIconProps {
  $alignToEnd?: boolean;
}

interface StyledComponent {
  $marginTop?: string;
  $marginBottom?: string;
  $justify?: string;
  $styles?: {};
}

interface StyledIconButtonProps extends IconButtonProps {
  $active?: boolean;
}

interface StyledSvgIconProps extends SvgIconProps {
  $disabled?: boolean;
}

export const TableContainer = styled(
  TableContainerComponent,
)<TableContainerProps>`
  &.MuiTableContainer-root {
    ${({ $loading }) => $loading === false && 'padding-bottom: 4px;'};
  }
`;

export const FullReportTableContainer = styled(
  TableContainer,
)<TableContainerProps>`
  max-height: 67vh;
  position: sticky;
  ::-webkit-scrollbar {
    display:none;
  &.MuiTableContainer-root {
    ${({ $loading }) => $loading === false && 'padding-bottom: 4px;'};
  }
`;

export const Table = styled(TableComponent)<TableProps>`
  border-collapse: separate;
  tr {
    border-style: none;
  }
`;

export const FullReportTableComponent = styled(TableComponent)<TableProps>``;

export const TableCell = styled(TableCellComponent)<TableCellPropsExtended>`
  && {
    min-width: 80px;
    color: ${({ $whiteText }) =>
      $whiteText ? palette.ACCENT_WHITE : '#000000'};
    ${({ $action }) => ($action ? 'right: 0;' : '')};
    ${({ $details }) => ($details ? 'background: rgb(250, 250, 250);' : '')};
  }
`;

export const TableCellCustom = styled(
  TableCellComponent,
)<TableCellPropsExtended>`
  && {
    min-width: 80px;
    padding-left: 20px;
  }
`;

export const TableCellWithIcon = styled(
  TableCellComponent,
)<TableCellPropsExtended>`
  && {
    min-width: 110px;
    display: flex;
    height: 60px;
    align-items: center;
    ${({ $smaller }) =>
      $smaller &&
      'min-width:90px; max-width:90px; justify-content:flex-start; padding-right:44px;'}
  }
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const ClearButton = styled.div`
  color: #acadad;
  &:hover {
    cursor: pointer;
  }
`;

export const CellFlex = styled(TableCell)<TableCellProps>`
  && {
    display: flex;
    justify-content: center;
    border: 0;
  }
`;

export const DivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TableActionCell = styled(TableCell)<TableCellPropsExtended>`
  && {
    position: sticky;
    min-width: 80px;
    justify-content: center;
    right: 0;
    z-index: 1;
    background-color: inherit;
    // box-shadow: -8px 8px 12px -2px rgba(0, 0, 0, 0.12);
    // Chrome doesn't render box-shadow here.
    filter: drop-shadow(-8px 12px 8px rgba(0, 0, 0, 0.12));
    border: white;
  }
`;

export const TableActionCellFlex = styled(TableCell)`
  && {
    justify-content: center;
    right: 0;
    z-index: 1;
    border: white;
    display: flex;
  }
`;

export const FormControlWrapper = styled(
  FormControlLabel,
)<FormControlLabelProps>`
  &.MuiFormControlLabel-root {
    margin: 0;
  }
`;

export const CustomProjectsContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  border-radius: 6px;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
`;

export const TableFooterCell = styled(TableCell)<TableCellPropsExtended>`
  && {
    position: sticky;
    bottom: -6px;
    background-color: black;
    z-index: 1;
    color: white;
  }
`;

export const FlexDivFullReport = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const TableSummaryCell = styled(TableCell)<TableCellProps>`
  && {
    padding: 0;
    border-bottom: none;
    text-align: center;
  }
`;

export const TablePaginationCell = styled(TableCell)<TableCellProps>`
  && {
    padding: 0;
    border-bottom: none;
    position: sticky;
    right: 0;
  }
`;

export const TableHeadCell = styled(TableCell)<TableCellPropsExtended>`
  && {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: inherit;
    color: white;
    ${({ $action }) => $action && 'border-left: 1px solid #313131'}
  }
`;

export const BoldedTableCell = styled(TableCellComponent)<TableCellProps>`
  && {
    background-color: ${palette.ACCENT_LIGHTEST};
    font-weight: bold;
  }
`;

export const BoldedTitleCell = styled(TableCellComponent)<TableCellProps>`
  && {
    font-weight: bold;
  }
`;

export const BoldedCellText = styled.strong``;

export const BoldedProjectTitleCell = styled(
  TableCellComponent,
)<TableCellProps>`
  && {
    background-color: #e5e6e8;
    font-weight: bold;
  }
`;

export const BoldedClientTitleCell = styled(TableCellComponent)<TableCellProps>`
  && {
    background-color: ${palette.ACCENT_LIGHT};
    font-weight: bold;
  }
`;

export const OvertimeInfo = styled.span`
  && {
    color: #d30000;
    font-weight: bold;
  }
`;

export const DetailsTitle = styled(TypographyComponent)`
  &.MuiTypography-root {
    color: ${palette.ACTION_DARKEST};
    font-weight: 500;
  }
`;

export const TitleWrapper = styled.span`
  color: ${palette.ACTION_DARKEST};
  font-weight: 500;
  width: 200px;
`;

export const TextValue = styled(TypographyComponent)`
  &.MuiTypography-root {
    font-weight: 500;
  }
`;

export const DescriptionText = styled(
  TypographyComponent,
)<TypographyPropsExtended>`
  &.MuiTypography-root {
    padding-top: 10px;
    word-wrap: break-word;
    width: ${({ $fullWidth }) => ($fullWidth ? 'auto' : '60vw;')};
  }
`;

export const RowDescriptionWrapper = styled.div`
  padding-top: 10px;
  width: 60vw;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
`;

export const ProjectDetailsBox = styled.div`
  width: 100%;
  border-top: 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
`;

export const FirstRowTableCell = styled(
  TableCellComponent,
)<TableCellPropsExtended>`
  &.MuiTableCell-root {
    text-align: ${({ align }) => align || 'left'};
    position: ${({ position }) => position || 'initial'};
    ${({ $details }) => ($details ? 'background: rgb(250, 250, 250);' : '')};
  }
`;

export const TableCellWithTooltip = styled.div<StyledComponent>((props) => ({
  display: 'flex',
  alignItems: 'center',
  ...props.$styles,
}));

export const Tooltip = styled(TooltipComponent)<TooltipProps>``;

export const TooltipWithMargin = styled(TooltipComponent)<TooltipProps>`
  margin-right: 10px;
`;

export const WarningTooltip = styled(TooltipComponent)<TooltipProps>``;

export const OverlappingHoursTooltip = styled(TooltipComponent)<TooltipProps>`
  position: absolute;
  top: 50%;
  left: -15%;
  transform: translateY(-50%);
`;

export const TableRow = styled(TableRowComponent)<TableRowProps>`
  background-color: ${({ index, $main, $weekend, $today }) => {
    if ($main) {
      return '#000';
    } else if ($weekend) {
      return '#e4e4e4';
    } else if (index && index === -1) {
      return palette.ACCENT_RED;
    } else if ($today) {
      return '#FFF0C7';
    } else {
      return palette.ACCENT_WHITE;
    }
  }};
  border-bottom: ${({ $selected }) =>
    $selected ? 'none' : '0.15rem solid rgba(0, 0, 0, 0.1)'};
`;

export const TableFooterRow = styled(TableRowComponent)<TableRowProps>``;

export const TableHeader = styled(TableHeadComponent)<TableHeadProps>`
  background-color: #000;
  & > ${TableRow} > ${TableCell} {
    color: white;
  }
`;

export const TableFooter = styled(TableFooterComponent)<TableFooterProps>`
  &.MuiTableFooter-root {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    bottom: -6px;
  }
`;

export const TableBody = styled(TableBodyComponent)<TableBodyProps>``;

export const Paper = styled(PaperComponent)<PaperProps>``;

export const TableIconButton = styled(IconButton)<StyledIconButtonProps>`
  &.MuiIconButton-root {
    color: rgb(0 0 0);
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
  }
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
  &.MuiIconButton-root.Mui-disabled {
    color: ${({ $active }) =>
      $active ? palette.ACTION_DARK : palette.ACCENT_DARKER};
  }
`;

export const ActionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const GridIconWrapper = styled(Grid)<GridProps>`
  &.MuiGrid-container {
    padding: 2rem;
    direction: 'column';
    align-items: 'center';
  }
`;

export const TableHideIconButton = styled(IconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    color: rgb(0 0 0);
    & > .MuiIconButton-label {
      display: flex;
      flex-direction: column;
      & > .MuiTypography-root {
        font-size: 0.8rem;
        line-height: 0.8;
      }
    }
  }
`;

export const TableHideButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const TableOvertimeCellMultiprojectText = styled.span`
  padding-right: 3.6rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
`;

export const TableOvertimeCellText = styled.span`
  padding: 0 3rem;
  font-size: 0.875rem;
`;

export const AdornmentIconButton = styled(IconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    color: rgb(0 0 0);
    position: absolute;
    right: 0%;
    top: 50%;
    & > .MuiIconButton-label {
      display: flex;
      flex-direction: column;
      & > .MuiTypography-root {
        font-size: 0.8rem;
        line-height: 0.8;
      }
    }
  }
`;

export const TableArrowIcon = styled(IconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    color: rgb(0 0 0);
    & > .MuiIconButton-label {
      display: flex;
      flex-direction: column;
      & > .MuiTypography-root {
        font-size: 0.8rem;
        line-height: 0.8;
      }
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const DateWrapper = styled.div`
  display: flex;
  text-overflow: hidden;
  white-space: nowrap;
  align-items: center;
`;

export const Checkbox = styled(CheckboxComponent)<CheckboxProps>`
  &.MuiCheckbox-root {
    color: ${({ disabled }) => (disabled ? '#0c0c0c59' : 'rgb(0,0,0)')};
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.54)' : palette.ACTION};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${palette.ACTION_LIGHTER};
  }
`;

export const CheckboxPartialApproval = styled(CheckboxComponent)<CheckboxProps>`
  &.MuiCheckbox-root {
    color: ${({ disabled }) => (disabled ? '#0c0c0c59' : 'rgb(0,0,0)')};
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.54)' : palette.ACTION};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.4)' : palette.ACTION_LIGHTER};
  }
`;

export const TablePagination = styled(
  TablePaginationComponent,
)<TablePaginationProps>``;

export const Typography = styled(TypographyComponent)<TypographyProps>``;

export const ReportTypography = styled(TypographyComponent)<TypographyProps>`
  &.MuiTypography-body1 {
    letter-spacing: 0.04em;
    display: inline;
    text-justify: center;
  }
`;

export const MarkAllBox = styled(BoxComponent)<BoxProps>`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
  }
`;

export const GreyBorder = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  margin-top: 4px;
`;

export const ExplanationIcon = styled(Comment)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;
export const SendIcon = styled(Telegram)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const ReminderIcon = styled(NotificationsActive)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const LockedIcon = styled(LockOutlined)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const UnlockedIcon = styled(LockOpenOutlined)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const AddIcon = styled(Add)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    padding-left: 10px;
    font-size: 30px;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const UploadFileIcon = styled(Backup)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    padding-left: 10px;
    font-size: 30px;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const AddIconNoHover = styled(Add)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    padding-left: 10px;
    font-size: 30px;
  }
`;

export const EditIcon = styled(Edit)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const CustomFieldIcon = styled(PlaylistAdd)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const AccountingNoteIcon = styled(NoteAdd)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const RemoveIcon = styled(Remove)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const ClearIcon = styled(Autorenew)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const TrashIcon = styled(Delete)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const DownloadIcon = styled(SaveAlt)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const DeactivateIcon = styled(PauseCircleOutlineOutlined)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const ActivateIcon = styled(PlayCircleOutline)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const SwitchIcon = styled(SyncAlt)<SvgIconProps>`
  &.MuiSvgIcon-root {
    transform: translateY(0.4rem);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    color: white;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const InformationIcon = styled(InfoOutlined)<InfoIcon>`
  &.MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 1rem;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
    ${(props) =>
      props.$bigger &&
      css`
        margin: 2px;
        width: 1.3em;
        height: 1.3em;
      `}
}
    ${(props) => (props.$alignToEnd ? 'margin-left: auto;' : '')}
  }
`;

export const TextWrapper = styled.p<StyledComponent>((props) => ({
  marginRight: '10px',
  ...props.$styles,
}));

export const WarningIcon = styled(WarningAmber)<SvgIconProps>`
  &.MuiSvgIcon-root {
    margin-left: auto;
    padding-left: 5px;
    font-size: 1rem;
    color: #d50000;
    margin-right: 5px;
  }
`;

export const InfoTooltipIcon = styled(ErrorOutline)<SvgIconProps>`
  &.MuiSvgIcon-root {
    margin-left: auto;
    padding-left: 5px;
    font-size: 1rem;
    color: #07bc0c;
    margin-right: 5px;
  }
`;

export const BillableIcon = styled(CheckCircleOutline)<BillabilityIconProps>`
  &.MuiSvgIcon-root {
    margin-left: 2px;
    padding-left: 5px;
    font-size: 1rem;
    color: #07bc0c;
    margin-right: auto;
    transition: all 0.2s;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
    ${(props) => (props.$alignToEnd ? 'margin-right: 0;' : '')}
  }
`;

export const DateRangeIcon = styled(DateRange)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const StarIcon = styled(Stars)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const NotBillableIcon = styled(ErrorOutline)<BillabilityIconProps>`
  &.MuiSvgIcon-root {
    margin-left: 2px;
    padding-left: 5px;
    font-size: 1rem;
    color: #d50000;
    margin-right: auto;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
    ${(props) => (props.$alignToEnd ? 'margin-right: 0;' : '')}
  }
`;

export const SortAsc = styled(ExpandLess)<SortingIcon>`
  &.MuiSvgIcon-root {
    transform: translateY(0.4rem);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    color: ${({ active, primaryColor = 'white' }) =>
      active ? palette.ACTION : primaryColor};
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const SortDesc = styled(ExpandMore)<SortingIcon>`
  &.MuiSvgIcon-root {
    transform: translateY(0.4rem);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    color: ${({ active, primaryColor = 'white' }) =>
      active ? palette.ACTION : primaryColor};
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const Unsorted = styled(Code)<SortingIcon>`
  &.MuiSvgIcon-root {
    transform: rotate(90deg) translate(0.4rem) scale(0.9);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    color: ${({ active }) => (active ? palette.ACTION : 'white')};
    transition: 0.25s ease-in-out;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const ShowIcon = styled(ExpandMore)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const HideIcon = styled(ExpandLess)<SvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${palette.ACTION_DARK};
    }
  }
`;

export const DefaultProjectInfo = styled.span`
  color: black;
  align-self: center;
  margin-left: 5px;
`;

export const DefaultProjectName = styled.span`
  font-weight: 600;
`;

export const DefaultProject = styled.div`
  display: flex;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledContainedButton = styled(ContainedButton)`
  &.MuiButton-contained.Mui-disabled {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.26);
  }
`;

export const ActionCircularProgress = styled(
  CircularProgressComponent,
)<CircularProgressProps>``;

export const SelectContainer = styled.div`
  width: 60%;
  margin-top: -4px;
`;

export const GridWithMargin = styled(Grid)`
  &.MuiGrid-item {
    margin-top: 17px;
  }
`;

export const StyledLink = styled(Link)`
  color: black;
  &:hover {
    color: ${palette.ACTION_DARK};
    transition: hover 0.5s ease-in-out;
  }
`;

export const StyledTableCell = styled(TableCell)`
  min-width: 100px;
`;

export const ButtonWrapper = styled.div`
  float: right;
`;

export const StyledTextField = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .MuiInputBase-root.Mui-disabled {
    border-bottom: 1px solid #ccc;
  }
`;

export const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > :first-child {
    margin-right: 5px;
  }
`;

export const RulesInstruction = styled.span`
  font-weight: bold;
  padding-left: 5px;
  color: grey;
`;

export const BorderRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 8px 15px;
  margin: 10px -16px 0px -16px;
  width: 200px;
`;

export const LeftCellWrapper = styled.span`
  flex: 90%;
  font-size: 16px;
  color: ${palette.ACTION_DARKEST};
  padding-top: 5px;
`;

export const RightCellWrapper = styled.span`
  flex: 10%;
  display: flex;
  justify-content: center;
`;

export const CustomFieldsActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  & > :first-child {
    margin-right: 15px;
  }
`;

export const FileCounter = styled.span`
  background-color: #ffc703;
  border-radius: 50%;
  padding: 0.3rem;
  margin-left: -0.65rem;
  margin-bottom: 0.65rem;
  display: block;
  width: 1rem;
  height: 1rem;
  font-size: 0.9rem;
  z-index: -1;
`;

export const FullReportStyledTableCell = styled(
  TableCell,
)<FullReportTableCellProps>`
  &.MuiTableCell-root {
    border-bottom: ${({ $today }) => {
      if ($today) {
        return 'none';
      }
    }};
  }
`;

export const StyledErrorBox = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2.5rem;
`;

export const BoldedText = styled.span`
  font-weight: bold;
`;

export const HeaderTooltipText = styled(Typography)`
  white-space: pre-line;
`;

export const DeleteIcon = styled(Clear)<StyledSvgIconProps>`
  &.MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${({ $disabled }) =>
        $disabled ? palette.ACCENT_DARKER : palette.ACTION_DARK};
      ${({ $disabled }) => $disabled && 'cursor: auto;'}
    }
  }
`;
