import { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { businessTripsUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import {
  GridItem,
  GridItemCentered,
  GridWrapper,
} from 'components/Dialog/Dialog.css';
import { AddIcon } from 'components/Table/Table.css';
import {
  TripCalculatorFormValues,
  TripCalculatorSummary,
} from 'modules/BusinessTrips/types';
import { postFetch } from 'utils/fetchFunctions';
import AccomodationSection from '../components/AccomodationSection';
import GeneralInfoSection from '../components/GeneralInfoSection';
import MealsSection from '../components/MealsSection';
import OtherExpensesSection from '../components/OtherExpensesSection';
import { TransportationRow } from '../components/TransportationRow';
import {
  CALCULATOR_TRANSPORTATION_OPTIONS,
  EMPTY_TRANSPORTATION_FIELD,
} from '../constants';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { ActionKind } from '../TripCalculatorContext/types';
import { getFormattedPostData, renderSelectOptions } from '../utils';
import {
  CalculatorContainer,
  FormSectionHeading,
  GridButtonsWrapper,
} from '../BusinessTripCalculator.css';

const TripCalculatorForm = () => {
  const intl = useIntl();
  const {
    state: { calculatorFormValues, isAccounting, isEmptyCalculation },
    dispatch,
  } = useContext(TripCalculatorContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues, errors, register, clearErrors } =
    useForm<TripCalculatorFormValues>({
      mode: 'onChange',
      defaultValues: calculatorFormValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'transportation',
  });

  const submitCalculatorForm = useCallback(async () => {
    const body = getFormattedPostData(getValues(), calculatorFormValues);
    dispatch({
      type: ActionKind.UpdateCalculatorFormValues,
      payload: { calculatorFormValues: getValues() },
    });

    const summariseUrl = isEmptyCalculation
      ? 'summarise-empty-calculator'
      : `${calculatorFormValues?.id}/summarise`;

    const { data, ok } = await postFetch({
      url: `${businessTripsUrl}/${summariseUrl}`,
      body: body,
      intl,
      label: 'BUSINESS_TRIP.CALCULATOR',
      setLoading: setLoading,
    });

    if (ok) {
      dispatch({
        type: ActionKind.SetTripCalculatorSummary,
        payload: { tripCalculatorSummary: data as TripCalculatorSummary },
      });
      dispatch({ type: ActionKind.RenderSummary });
    }
  }, [dispatch, intl, getValues, calculatorFormValues, isEmptyCalculation]);

  const allTransportationFieldsCreated =
    fields.length >= CALCULATOR_TRANSPORTATION_OPTIONS.length;

  const handleClick = () => {
    append(EMPTY_TRANSPORTATION_FIELD);
  };

  useEffect(() => {
    if (isAccounting) {
      dispatch({ type: ActionKind.RenderSummary });
    }
  }, [dispatch, isAccounting]);

  return (
    <CalculatorContainer>
      <GridWrapper
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <GeneralInfoSection control={control} errors={errors} />
        <AccomodationSection
          control={control}
          errors={errors}
          clearErrors={clearErrors}
        />
        <GridItem xs={12} item>
          <FormSectionHeading>
            {intl.formatMessage({
              id: 'BUSINESS_TRIP.CALCULATOR_TRANSPORTATION',
            })}
          </FormSectionHeading>
        </GridItem>
        {fields.map((transportationField, index) => (
          <TransportationRow
            key={transportationField.id}
            transportationOptions={renderSelectOptions(
              CALCULATOR_TRANSPORTATION_OPTIONS,
              intl,
            )}
            control={control}
            index={index}
            getValues={getValues}
            errors={errors}
            clearErrors={clearErrors}
            remove={remove}
            transportationFields={fields}
            transportationField={transportationField}
            register={register}
          />
        ))}
        {!allTransportationFieldsCreated && (
          <GridItemCentered xs={12} item>
            <ContainedButton onClick={handleClick} endIcon={<AddIcon />}>
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.CALCULATOR_ADD_TRANSPORTATION_TYPE',
              })}
            </ContainedButton>
          </GridItemCentered>
        )}
        <MealsSection control={control} errors={errors} />
        <OtherExpensesSection
          control={control}
          errors={errors}
          clearErrors={clearErrors}
        />
      </GridWrapper>
      <GridButtonsWrapper container item justify="flex-end" spacing={1} xs={12}>
        <Grid item>
          <ContainedButton
            onClick={handleSubmit(submitCalculatorForm)}
            disabled={loading}
          >
            {intl.formatMessage({
              id: 'SUMMARY',
            })}
          </ContainedButton>
        </Grid>
      </GridButtonsWrapper>
    </CalculatorContainer>
  );
};

export default TripCalculatorForm;
