import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { clientCustomFieldUrl, projectCustomFieldUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledTextField from 'components/ControlledTextField';
import { patchFetch, postFetch } from 'utils/fetchFunctions';
import { isCustomField } from 'utils/helpers/';
import { CustomFieldDialogProps, CustomFieldProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const CustomFieldDialog = ({
  addChecked,
  handleAddChanged,
  data,
  fetchData,
  editClicked,
}: CustomFieldDialogProps) => {
  const { control, handleSubmit, formState, reset } = useForm<CustomFieldProps>(
    {
      mode: 'onChange',
      defaultValues: {
        label: '',
        value: '',
        description: '',
      },
    },
  );

  const intl = useIntl();

  useEffect(() => {
    if (editClicked && isCustomField(data)) {
      reset({
        label: data?.label || '',
        value: data?.defaultValue || '',
        description: data?.description || '',
      });
    }
  }, [data, editClicked, reset]);

  const onSubmit = handleSubmit(async (formData) => {
    if (data) {
      let isResponseOk: boolean;
      if (editClicked && isCustomField(data)) {
        const { ok } = await patchFetch({
          url: `${
            data?.projectId ? projectCustomFieldUrl : clientCustomFieldUrl
          }/${data?.id}`,
          body: { ...formData, obligatory: true },
          intl,
          label: 'CUSTOM_FIELDS_EDIT',
        });
        isResponseOk = ok;
      } else {
        const { ok } = await postFetch({
          url: `${
            'project' in data ? projectCustomFieldUrl : clientCustomFieldUrl
          }/${data?.id}`,
          body: { ...formData, obligatory: true },
          intl,
          label: 'CUSTOM_FIELDS',
        });
        isResponseOk = ok;
      }
      if (isResponseOk) {
        fetchData();
      }

      handleAddChanged();
      reset({
        label: '',
        value: '',
        description: '',
      });
    }
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={addChecked}
      onClose={handleAddChanged}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: editClicked
                ? 'CUSTOM_FIELDS.EDIT_DIALOG_TITLE'
                : `CUSTOM_FIELDS.DIALOG_TITLE`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label="CUSTOM_FIELDS.LABEL"
                name="label"
                control={control}
                required
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                label="CUSTOM_FIELDS.EXPECTED_VALUE"
                name="value"
                control={control}
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                label="CUSTOM_FIELDS.DESCRIPTION"
                name="description"
                control={control}
                variant="outlined"
                isMulti
              />
            </GridItem>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="saveButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `CUSTOM_FIELDS.DIALOG_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default CustomFieldDialog;
