import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledSelect from 'components/ControlledSelect/ControlledSelect';
import ControlledTextField from 'components/ControlledTextField';
import { GridItem, GridWrapperGapped } from 'components/Dialog/Dialog.css';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { ClearableCalculatorSectionProps } from 'modules/BusinessTrips/types';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { renderErrorMessageForRequiredFields } from '../utils';
import { FormSectionHeading } from '../BusinessTripCalculator.css';

export const AccomodationSection = ({
  control,
  errors,
  clearErrors,
}: ClearableCalculatorSectionProps) => {
  const intl = useIntl();

  const {
    state: { calculatorFormValues, currencies },
  } = useContext(TripCalculatorContext);

  const accomodationCosts = useWatch({
    control,
    name: 'accomodationCosts',
    defaultValue: calculatorFormValues?.accomodationCosts,
  });

  const accomodationCurrency = useWatch({
    control,
    name: 'accomodationCurrency',
    defaultValue: calculatorFormValues?.accomodationCosts,
  });

  const isAccomodationRequired = !!accomodationCosts || !!accomodationCurrency;

  useEffect(() => {
    if (!isAccomodationRequired) {
      clearErrors(['accomodationCosts', 'accomodationCurrency']);
    }
  }, [
    isAccomodationRequired,
    clearErrors,
    errors?.accomodationCurrency,
    errors?.accomodationCosts,
  ]);

  return (
    <>
      <GridItem xs={12} item>
        <FormSectionHeading>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.CALCULATOR_ACCOMODATION',
          })}
        </FormSectionHeading>
      </GridItem>
      <GridItem item xs={12}>
        <GridWrapperGapped container wrap="nowrap" justify="space-between">
          <GridItem xs={8}>
            <ControlledTextField
              name="accomodationCosts"
              variant="outlined"
              control={control}
              required={isAccomodationRequired}
              label="BUSINESS_TRIP.CALCULATOR_ACCOMODATION_COSTS"
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={calculatorFormValues?.accomodationCosts}
              isOnlyDecimals
              validate={!!errors?.accomodationCosts}
              helperText={
                errors?.accomodationCosts &&
                renderErrorMessageForRequiredFields(intl)
              }
            />
          </GridItem>
          <GridItem xs={4}>
            <ControlledSelect
              label="BUSINESS_TRIP.CURRENCIES"
              name="accomodationCurrency"
              control={control}
              options={currencies}
              required={isAccomodationRequired}
              defaultValue={calculatorFormValues?.accomodationCurrency}
              errorMessage={
                errors?.accomodationCurrency &&
                renderErrorMessageForRequiredFields(intl)
              }
            />
          </GridItem>
        </GridWrapperGapped>
      </GridItem>
    </>
  );
};

export default AccomodationSection;
