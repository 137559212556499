import { useState } from 'react';
import { ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  InfoContainer,
  InputTitle,
} from 'modules/AdminPanel/Screens/BenefitsManager/BenefitsManager.css';
import { PartnerDataProps } from 'modules/UserProfile/Screens/Benefits/types';
import Address from './Address';
import { SummaryCollapse, SummaryGrid, SummaryListItem } from './Summary.css';

const PartnerData = ({ summaryValues, isAddress }: PartnerDataProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SummaryListItem button onClick={() => setOpen((prev) => !prev)}>
        <ListItemText
          primary={`${summaryValues?.[1].content} ${summaryValues?.[2].content}`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </SummaryListItem>
      <SummaryCollapse in={open}>
        <SummaryGrid container xs={12}>
          {summaryValues?.map(({ content, title }, index) =>
            content && title !== 'First name:' && title !== 'Surname:' ? (
              <InfoContainer item xs={6} key={`${title} - ${index}`}>
                <InputTitle>{title}</InputTitle>
                <span>{content}</span>
              </InfoContainer>
            ) : null,
          )}
        </SummaryGrid>
        {isAddress && (
          <SummaryGrid container $address>
            <Address />
          </SummaryGrid>
        )}
      </SummaryCollapse>
    </>
  );
};

export default PartnerData;
