import {
  ChangeEvent,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { GlobalContext } from 'globalContext/GlobalContext';
import moment from 'moment';
import {
  apiUrl,
  businessTripByClientUrl,
  businessTripByProjectUrl,
  businessTripByUserUrl,
  businessTripReportsUrl,
  employeesUrl,
  reportsUrl,
  workTimeByClientUrl,
  workTimeByProjectUrl,
  workTimeByUserUrl,
} from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import Datepicker from 'components/Datepicker';
import MenuBar from 'components/MenuBar';
import SwitchContainer from 'components/SwitchContainer';
import Table from 'components/Table/Table';
import { Report, Sorting, TableHead } from 'components/Table/types';
import TimesheetFilter from 'components/TimesheetFilter/TimesheetChipsFilter';
import { fetchClientList, fetchProjectList } from 'utils/commonFetches';
import {
  dateFormatBackend,
  dateFormatFrontend,
  formatDate,
  formatMonthsNumbersToStringNames,
} from 'utils/dateFormats';
import { getEncodedQueryParamsString } from 'utils/encoding/encodeQueryParamsString';
import { getFetch } from 'utils/fetchFunctions';
import { capitalizeFirstLetter } from 'utils/formatters';
import { returnLastMonthDays } from 'utils/helpers';
import {
  filterDropdownProjectData,
  getRenamedProjectsData,
  setClientsValue,
} from 'utils/helpers/filterDropdownData';
import {
  ClientsData,
  FilterOption,
  ProjectsData,
} from 'utils/helpers/renameKeys';
import { usePagination } from 'utils/hooks/usePagination';
import { useToggleState } from 'utils/hooks/useToggleState';
import {
  BUSINESS_TRIPS_HEAD_DATA,
  TAB_LABELS,
  TIMEREPORTS_HEAD_DATA,
} from './static_data';
import {
  FetchingUrlArgument,
  FilterComponent,
  Filtering,
  FormDataProps,
  ReportsFilter,
} from './types';
import { GridWrapperWithMargin, GridWrapperWithMargin2 } from './Reports.css';

const joinNames = (object: {
  username: string;
  name: string;
  surname: string;
}) => {
  return {
    value: object.username,
    name: `${object.name} ${object.surname}`,
  };
};

const Reports = () => {
  const [tableDataEmpty, setTableDataEmpty] = useState(false);
  const [employeesData, setEmployeesData] = useState<FilterOption[]>([]);
  const [projectsData, setProjectsData] = useState<ProjectsData[]>([]);
  const [tableHeadData, setTableHeadData] = useState<TableHead[]>([]);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const [showWorkTimeInDays, toggleWorktime] = useToggleState(true);

  const [filteredProjectsData, setFilteredProjectsData] = useState<
    ProjectsData[]
  >([]);
  const [tableData, setTableData] = useState<Report[]>([]);

  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(0);
  const [sorting, setSorting] = useState<Sorting>({
    column: 'name',
    direction: 'asc',
  });
  const [showMultiplicators, setShowMultiplicators] = useState(false);

  const handleSwitchChange = () =>
    setShowMultiplicators((prevState) => !prevState);

  const { firstDay, lastDay } = useMemo(() => returnLastMonthDays(), []);

  const {
    localization: { currentLocale },
  } = useContext(GlobalContext);

  const { control, setValue } = useForm<FormDataProps>({
    defaultValues: {
      project: { projectName: undefined },
      client: { clientName: undefined },
      name: { value: null, name: null },
      dateFrom: firstDay,
      dateTo: lastDay,
    },
  });

  const formData: FormDataProps = useWatch({ control }) as FormDataProps;
  const { name, client, project, dateFrom, dateTo } = formData;
  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
  } = usePagination<FormDataProps>({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 500,
    data: formData,
  });

  const intl = useIntl();

  const clearProjectAfterClearingClient = useCallback(() => {
    setValue('project', { projectName: undefined });
  }, [setValue]);

  const projectOptions = useMemo(
    () => getRenamedProjectsData(filteredProjectsData),
    [filteredProjectsData],
  );

  const REPORTS_FILTER_DATA = [
    {
      label: 'TABLE_FILTER.LABEL.CLIENT',
      type: Filtering.client,
      options: clientsData,
      component: FilterComponent.selectFilter,
      handleChange: clearProjectAfterClearingClient,
    },
    {
      label: 'TABLE_FILTER.LABEL.PROJECT',
      type: Filtering.project,
      options: projectOptions,
      component: FilterComponent.selectFilter,
    },
    {
      label: 'TABLE_FILTER.LABEL.NAME',
      type: Filtering.name,
      options: employeesData,
      component: FilterComponent.selectFilter,
    },
    {
      label: 'TABLE_FILTER.LABEL.DATE_FROM',
      type: Filtering.dateFrom,
      component: FilterComponent.datepicker,
    },
    {
      label: 'TABLE_FILTER.LABEL.DATE_TO',
      type: Filtering.dateTo,
      component: FilterComponent.datepicker,
    },
  ];

  const fetchingUrlsObject = ((viewType: number) => {
    const type = viewType === 0 ? 'timereports' : 'business_trips';
    const timeReportsUrls = {
      reports: reportsUrl,
      employees: workTimeByUserUrl,
      clients: workTimeByClientUrl,
      projects: workTimeByProjectUrl,
    };

    const businessTripsReportsUrls = {
      reports: businessTripReportsUrl,
      employees: businessTripByUserUrl,
      clients: businessTripByClientUrl,
      projects: businessTripByProjectUrl,
    };

    const switchObject = {
      timereports: timeReportsUrls,
      business_trips: businessTripsReportsUrls,
    };

    return switchObject[type];
  })(viewMode);

  const returnQueryParams = () => {
    return {
      page: `${pagination.pageNumber || ''}`,
      size: `${pagination.pageSize || ''}`,
      sort: `${sorting.column + ',' + sorting.direction}`,
      dateFrom: `${
        dateFrom ? `${formatDate(dateFrom, dateFormatBackend)}` : ''
      }`,
      dateTo: `${dateTo ? `${formatDate(dateTo, dateFormatBackend)}` : ''}`,
      username: `${name?.value ? `${name.value}` : ''}`,
      client: `${client?.clientName ? `${client.clientName}` : ''}`,
      project: `${project?.projectName ? `${project.projectName}` : ''}`,
      showMultipliers: `${showMultiplicators}`,
      showWorkTimeInDays: `${showWorkTimeInDays}`,
    };
  };

  const returnCurrentFetchingUrl = ({
    queryParams,
    typeOfFile,
  }: FetchingUrlArgument): string => {
    return (
      fetchingUrlsObject.reports +
      (typeOfFile ? '/' + typeOfFile : '') +
      getEncodedQueryParamsString(queryParams)
    );
  };

  const returnFileName = () => {
    const [formattedDateFrom, formattedDateTo] = [dateFrom, dateTo].map(
      (date) => formatDate(date, dateFormatFrontend),
    );

    const mode = capitalizeFirstLetter(
      viewMode === 0 ? 'timereports' : 'business trips',
    );

    const returnFileNameDate =
      `${dateFrom ? ` from ${formattedDateFrom}` : ''}` +
      `${dateTo ? ` to ${formattedDateTo}` : ''}`;

    const returnFileNameFilter =
      `${name?.name ? ` employee-${name.name}` : ''}` +
      `${client?.clientName ? ` client-${client.clientName}` : ''}` +
      `${project?.projectName ? ` project-${project.projectName}` : ''}`;

    if (returnFileNameFilter) {
      return `${mode}${returnFileNameDate}${returnFileNameFilter}${
        viewMode ? `.csv` : `.xlsx`
      }`;
    } else {
      return `General ${mode}${returnFileNameDate}${
        viewMode ? `.csv` : `.xlsx`
      }`;
    }
  };

  const exportFile = (response: BlobPart, fileTitle: string) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileTitle);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const download = (filename: string, text: any) => {
    const pom = document.createElement('a');
    pom.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(text),
    );
    pom.setAttribute('download', filename);
    if (document.createEvent) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  };

  const getReport = async () => {
    if (localStorage.getItem('tpToken')) {
      const typeOfFile = viewMode ? 'csv' : 'xlsx';

      const downloadUrl = returnCurrentFetchingUrl({
        queryParams: returnQueryParams(),
        typeOfFile,
      });
      setLoading(true);

      try {
        const response = await fetch(apiUrl + downloadUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tpToken')}`,
            Accept: '*/*',
          },
        });
        setLoading(false);
        if (viewMode) {
          const file = await response.text();
          const fileName = returnFileName();
          download(fileName, file);
        } else {
          const file = await response.blob();
          const fileName = returnFileName();
          exportFile(file, fileName);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const renderDownloadButton = () => {
    if (!viewMode) {
      return (
        <ContainedButton
          id="downloadExcelButton"
          disabled={!tableData.length || loading}
          endIcon={<SaveAlt />}
          onClick={getReport}
        >
          {intl.formatMessage({ id: 'BUTTON.LABEL.DOWNLOAD_AS_XLSX' })}
        </ContainedButton>
      );
    }
    return (
      <ContainedButton
        id="downloadCsvButton"
        disabled={!tableData.length}
        endIcon={<SaveAlt />}
        onClick={getReport}
      >
        {intl.formatMessage({ id: 'BUTTON.LABEL.DOWNLOAD_AS_CSV' })}
      </ContainedButton>
    );
  };

  const controllerRef = useRef<AbortController>();

  const fetchReportsList = async () => {
    controllerRef.current?.abort();
    controllerRef.current = new AbortController();

    const reportsResponse = await getFetch({
      url: fetchingUrlsObject.reports,
      setLoading,
      signal: controllerRef.current?.signal,
      queryParams: returnQueryParams(),
    });

    if (reportsResponse) {
      const { totalElements } = reportsResponse;

      if (pagination.totalElements !== totalElements) {
        setElementsCount(totalElements);
      }
      const dataWithFormattedMonths = formatMonthsNumbersToStringNames(
        reportsResponse.content,
      );
      setTableData(dataWithFormattedMonths);
      if (!dataWithFormattedMonths?.length) {
        setTableDataEmpty(true);
      }
    }
  };

  const setFilterDropdownData = useCallback(() => {
    setFilteredProjectsData(
      filterDropdownProjectData({
        projectsData,
        clientNameFromClient: client?.clientName,
      }),
    );
    setClientsValue({
      setValue,
      projectNameFromProject: project?.projectName,
      clientNameFromClient: client?.clientName,
      clientNameFromProject: project?.client?.clientName,
      clientIdFromProject: project?.client?.id,
    });
  }, [client, project, projectsData, setValue]);

  useEffect(() => {
    fetchReportsList();
    //	eslint-disable-next-line
  }, [
    viewMode,
    debouncedPage,
    pagination.pageSize,
    sorting.direction,
    sorting.column,
    currentLocale,
    dateFrom,
    project,
    dateTo,
    client,
    name,
    showMultiplicators,
  ]);

  useEffect(() => {
    const employeesFetch = async () => {
      const employeesResponse = await getFetch({ url: employeesUrl });
      setEmployeesData(employeesResponse.map(joinNames));
    };
    employeesFetch();
    fetchProjectList(setProjectsData);
    fetchClientList(setClientsData);
  }, []);

  const formatHeadData = useCallback(
    (viewMode: number) => {
      if (!viewMode) {
        return showMultiplicators
          ? TIMEREPORTS_HEAD_DATA
          : TIMEREPORTS_HEAD_DATA.filter(({ key }) => key !== 'multiplier');
      }
      return BUSINESS_TRIPS_HEAD_DATA;
    },
    [showMultiplicators],
  );

  useEffect(() => {
    setTableHeadData(formatHeadData(viewMode));
  }, [viewMode, formatHeadData]);

  useEffect(() => {
    setFilterDropdownData();
  }, [setFilterDropdownData]);

  const toggleSorting = useCallback(
    (
      column: string,
      sorting: { column: string; direction: 'asc' | 'desc' },
      setSorting: Dispatch<any>,
    ) => {
      setSorting({
        column,
        direction: sorting.direction === 'asc' ? 'desc' : 'asc',
      });
    },
    [],
  );

  const clearAllFilters = useCallback(() => {
    setValue('project', { projectName: undefined });
    setValue('name', { value: null, name: null });
    setValue('client', { clientName: undefined });
    setValue('dateFrom', firstDay);
    setValue('dateTo', lastDay);
  }, [firstDay, lastDay, setValue]);

  useEffect(() => {
    clearAllFilters();
    toggleSorting('name', { column: 'name', direction: 'desc' }, setSorting);
  }, [clearAllFilters, toggleSorting, viewMode]);

  const isReportLoading = loading || (!tableData.length && !tableDataEmpty);

  return (
    <>
      <MenuBar value={viewMode} setValue={setViewMode} options={TAB_LABELS} />
      <Box overflow="auto" padding="2rem" minHeight="calc(100vh - 200px)">
        <form>
          <GridWrapperWithMargin container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="space-between">
                {REPORTS_FILTER_DATA.map(
                  (
                    entry: ReportsFilter,
                    entryIndex: number,
                    entryArray: ReportsFilter[],
                  ): JSX.Element => (
                    <Grid
                      lg={
                        entryIndex === entryArray.length - 1 ||
                        entryIndex === entryArray.length - 2
                          ? 6
                          : 4
                      }
                      sm={entryIndex === 0 ? 12 : 6}
                      key={entry.type}
                      xl={true}
                      xs={12}
                      item
                    >
                      <Controller
                        name={entry.type}
                        control={control}
                        render={({ onChange, value, name }) => {
                          const handleChange = (
                            e: ChangeEvent<HTMLInputElement>,
                          ) => {
                            onChange(e);
                            if (entry.handleChange) {
                              entry.handleChange();
                            }
                          };
                          return entry.component ===
                            FilterComponent.selectFilter ? (
                            <TimesheetFilter
                              onChange={handleChange}
                              value={value}
                              label={intl.formatMessage({ id: entry.label })}
                              type={name}
                              options={entry.options ?? []}
                            />
                          ) : (
                            <Datepicker
                              value={value}
                              label={intl.formatMessage({ id: entry.label })}
                              type={name}
                              name={entry.type}
                              maxDate={
                                entry.type === Filtering.dateFrom && dateTo
                                  ? moment(dateTo)
                                  : undefined
                              }
                              minDate={
                                entry.type === Filtering.dateTo && dateFrom
                                  ? moment(dateFrom)
                                  : undefined
                              }
                              onChange={onChange}
                              onClearButtonClick={() => {
                                setValue(entry.type, null);
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          </GridWrapperWithMargin>
        </form>
        <GridWrapperWithMargin2
          xs={12}
          justifyContent="space-between"
          container
          placement={'top'}
        >
          {!viewMode && (
            <SwitchContainer
              name="switch-multipliers"
              id="switch-multipliers"
              label={intl.formatMessage({ id: 'TABLE.SWITCH.MULTIPLIERS' })}
              toggleSwitch={showMultiplicators}
              setToggleSwitch={handleSwitchChange}
            />
          )}
          <Grid item justifyContent="flex-end">
            {renderDownloadButton()}
          </Grid>
        </GridWrapperWithMargin2>

        <Table
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
          head={tableData.length ? tableHeadData : []}
          pageCount={pagination.totalElements}
          currentPage={pagination.pageNumber}
          withPagination={true}
          limit={pagination.pageSize}
          setSorting={setSorting}
          sorting={sorting}
          loading={isReportLoading}
          data={tableData}
          setShowMultiplicators={setShowMultiplicators}
          toggleWorktimeDisplay={toggleWorktime}
          toggleSorting={toggleSorting}
        />
      </Box>
    </>
  );
};

export default Reports;
