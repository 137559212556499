import { TableHead } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const MANUAL_ACCEPTANCE_HEAD_DATA: TableHead[] = [
  {
    label: 'ADMIN_PANEL.HEADER.CLIENT',
    key: 'client',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'ADMIN_PANEL.HEADER.PROJECT',
    key: 'project',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
  {
    label: 'ADMIN_PANEL.HEADER.EMPLOYEE',
    key: 'employee',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
  },
  {
    label: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_ALWAYS_MANUAL',
    key: 'alwaysManualAcceptance',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
    tooltip: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_ALWAYS_MANUAL_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_OVERTIME',
    key: 'overtimeAcceptance',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
    tooltip: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_OVERTIME_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_WEEKENDS',
    key: 'weekendsAcceptance',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
    tooltip: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_WEEKENDS_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_HOLIDAYS',
    key: 'holidaysAcceptance',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
    tooltip: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_HOLIDAYS_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_UOP_VIOLATED',
    key: 'contractViolatedAcceptance',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
    tooltip: 'ADMIN_PANEL.HEADER.MANUAL_ACCEPTANCE_IF_UOP_VIOLATED_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.LEADER',
    key: 'leader',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '15%',
    tooltip: 'ADMIN_PANEL.HEADER.LEADER_TOOLTIP',
  },
  {
    label: 'ADMIN_PANEL.HEADER.ACTIONS',
    key: 'actions',
    align: 'center',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '10%',
  },
];

export const EXCLUDE_TIMESHEET_HEAD_DATA: TableHead[] = [
  {
    label: 'ADMIN_PANEL.HEADER.EMPLOYEE',
    key: 'employee',
    align: 'left',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '6%',
  },
  {
    label: 'ADMIN_PANEL.HEADER.ACTIONS',
    key: 'actions',
    align: 'right',
    sortable: false,
    role: RoleTypes.RoleEmployee,
    width: '6%',
  },
];
export const TAB_LABELS = [
  { label: 'ADMIN_PANEL.MANUAL_ACCEPTANCE', key: 0 },
  { label: 'ADMIN_PANEL.CALENDAR', key: 1 },
  { label: 'ADMIN_PANEL.BENEFITS_MANAGER', key: 2 },
  { label: 'ADMIN_PANEL.PERMISSION_MANAGER', key: 3 },
  // { label: 'ADMIN_PANEL.HOLIDAYS', key: 4 },
  // { label: 'ADMIN_PANEL.EXCLUDE', key: 5 },
];
