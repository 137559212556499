import { RenderForm } from 'modules/UserProfile/Screens/Benefits/BenefitContext/types';
import { Action, ActionKind, State } from './types';

export const benefitInitialState: State = {
  renderForm: null,
  renderPartners: false,
  toggleFormDialog: false,
  toggleRemoveBenefitDialog: false,
  selectedBenefitToRemove: undefined,
  toggleRemovePartnerDialog: false,
  selectedBenefit: undefined,
  selectedBenefitType: undefined,
  benefitsData: undefined,
  numberOfMembers: 0,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionKind.AddNewBenefit:
      return {
        ...state,
        renderForm: RenderForm.Benefit,
        toggleFormDialog: !state.toggleFormDialog,
        selectedBenefit: action.payload.setBenefit,
        selectedBenefitType: action.payload.setBenefitType,
      };

    case ActionKind.AddNewPartner:
      return {
        ...state,
        renderForm: RenderForm.Partner,
        toggleFormDialog: !state.toggleFormDialog,
      };

    case ActionKind.EditData:
      return {
        ...state,
        renderForm: RenderForm.EditData,
        toggleFormDialog: !state.toggleFormDialog,
      };

    case ActionKind.RenderPartners:
      return { ...state, renderPartners: !state.renderPartners };

    case ActionKind.ToggleFormDialog:
      return {
        ...state,
        ...benefitInitialState,
      };

    case ActionKind.ToggleRemoveConfirmation:
      return {
        ...state,
        toggleRemoveBenefitDialog: !state.toggleRemoveBenefitDialog,
      };

    case ActionKind.RemoveBenefit:
      return {
        ...state,
        toggleRemoveBenefitDialog: !state.toggleRemoveBenefitDialog,
        selectedBenefitToRemove: action.payload.setSelectedBenefit,
      };

    case ActionKind.RemovePartner:
      return {
        ...state,
        toggleRemovePartnerDialog: !state.toggleRemovePartnerDialog,
      };

    case ActionKind.SetBenefitData:
      return {
        ...state,
        benefitsData: { ...state.benefitsData, ...action.payload },
      };

    case ActionKind.SetSelectedBenefitData:
      return {
        ...state,
        selectedBenefit: action.payload.setSelectedBenefit,
        selectedBenefitType: action.payload.setSelectedBenefitType,
      };

    case ActionKind.IncreaceNumberOfMembers:
      return {
        ...state,
        numberOfMembers: state.numberOfMembers + 1,
      };

    case ActionKind.DecreaseNumberOfMembers:
      return {
        ...state,
        numberOfMembers: state.numberOfMembers - 1,
      };

    case ActionKind.ResetNumberOfMembers:
      return {
        ...state,
        numberOfMembers: benefitInitialState.numberOfMembers,
      };

    case ActionKind.ResetState: {
      return { ...benefitInitialState };
    }

    default:
      return state;
  }
};
