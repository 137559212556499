import { Dispatch, SetStateAction, useEffect } from 'react';
import { EditIcon, TableIconButton } from 'components/Table/Table.css';
import { UseEditProps } from './types';
import { useToggleState } from './useToggleState';

export function useEditIcon<T extends Record<string, any>>(
  addChecked: boolean,
  handleAddChanged: () => void,
  setDialogData: Dispatch<SetStateAction<any>>,
): UseEditProps<T> {
  const [editClicked, handleEditClicked] = useToggleState(false);

  const renderEditIcon = (data: T, index: number) => {
    return (
      <TableIconButton
        id="editButton"
        onClick={() => {
          setDialogData(data[index]);
          handleAddChanged();
          handleEditClicked();
        }}
      >
        <EditIcon />
      </TableIconButton>
    );
  };

  useEffect(() => {
    if (!addChecked && editClicked) {
      handleEditClicked();
    }
  }, [addChecked, editClicked, handleEditClicked]);

  return [editClicked, renderEditIcon];
}
