import moment from 'moment';
import { capitalizeFirstLetter } from 'utils/formatters/';

export const dateFormatFrontend = 'DD.MM.YYYY';

export const dateShortFormatFrontend = 'DD.MM';

export const dateTimeFormatFrontend = 'DD.MM.YYYY HH:mm';

export const dateFormatBackend = 'DD-MM-YYYY';

export const dateFormatMoment = 'YYYY-MM-DD';

export const yearMonthFormatBackend = 'YYYY-MM';

export const formatDate = (
  date: Date | string | null | undefined,
  format: string,
): string => {
  if (date === null) {
    return '';
  }
  if (typeof date === 'string' && date.indexOf('/') !== -1) {
    return date;
  }
  const formattedDate = moment(date).format(format);
  return formattedDate === 'Invalid date' ? '' : formattedDate;
};

export const formatMonthsNumbersToStringNames = (
  array: Array<string> = [],
): Array<any> => {
  return array.map((element: any) => ({
    ...element,
    month: capitalizeFirstLetter(
      moment()
        .month(element.month - 1)
        .format('MMMM'),
    ),
  }));
};

export const formatMonthsToTwoDigits = (month: number) => {
  return `${month}`.padStart(2, '0');
};

export const changeDateFormatWithoutYear = (date: string) =>
  moment(date).format(dateShortFormatFrontend);

export const changeDateFormatWithYear = (date: string) =>
  moment(date).format(dateFormatFrontend);

export const showVacationDates = (startDate: string, endDate: string) => {
  if (
    moment(startDate).format(dateFormatMoment) ===
    moment(endDate).format(dateFormatMoment)
  ) {
    return changeDateFormatWithYear(startDate);
  } else {
    const minYear = moment(startDate).format('YYYY');
    const maxYear = moment(endDate).format('YYYY');
    const minDate =
      minYear === maxYear
        ? changeDateFormatWithoutYear(startDate)
        : changeDateFormatWithYear(startDate);
    const maxDate = changeDateFormatWithYear(endDate);
    return `${minDate} - ${maxDate}`;
  }
};
