import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ContainedButton } from 'components/Button';
import { useToggleState } from 'utils/hooks/useToggleState';
import AccordionRequestsHistory from './components/AccordionRequestsHistory';
import HolidayRequestDialog from './components/FormDialog/HolidayRequestDialog';

const HolidaysTab = () => {
  const [isDialogOpen, handleChangeDialogOpen] = useToggleState(false);
  const intl = useIntl();

  return (
    <Grid container direction="column" spacing={5}>
      <Grid container item justify="flex-end">
        <ContainedButton onClick={handleChangeDialogOpen} endIcon={<AddIcon />}>
          {intl.formatMessage({ id: 'LEAVE_REQUEST.NEW_REQUEST' })}
        </ContainedButton>
        <HolidayRequestDialog
          open={isDialogOpen}
          handleClose={handleChangeDialogOpen}
        />
      </Grid>
      <Grid item>
        <AccordionRequestsHistory />
      </Grid>
    </Grid>
  );
};

export default HolidaysTab;
