import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import { businessTripsUrl } from 'router/url';
import { ContainedButton } from 'components/Button';
import { DownloadFileIcon } from 'components/Dialog/Dialog.css';
import { TripCalculatorSummaryProps } from 'modules/BusinessTrips/types';
import { exportFile } from 'utils/exportFile';
import { postFetch, putFetch } from 'utils/fetchFunctions';
import { generateHeaders } from 'utils/getRequestHeadersForPDF';
import SummarySection from '../components/SummarySection';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { ActionKind } from '../TripCalculatorContext/types';
import {
  getFormattedPostData,
  renderTripCalculatorCosts,
  renderTripCalculatorCostsForeignCurrency,
  renderTripCalculatorDescription,
  renderTripCalculatorTotal,
} from '../utils';
import { CalculatorContainer } from '../BusinessTripCalculator.css';

const TripCalculatorSummary = ({
  handleToggleCalculator,
  fetchTrips,
  isVisiblePDF,
}: TripCalculatorSummaryProps) => {
  const intl = useIntl();
  const {
    state: {
      tripCalculatorSummary,
      calculatorFormValues,
      isEmptyCalculation,
      isAccounting,
      isEditing,
    },
    dispatch,
  } = useContext(TripCalculatorContext);
  const businessTripDescription = renderTripCalculatorDescription(
    tripCalculatorSummary,
    calculatorFormValues,
  );

  const businessTripCosts = renderTripCalculatorCosts(tripCalculatorSummary);
  const businessTripCostsForeign = renderTripCalculatorCostsForeignCurrency(
    tripCalculatorSummary,
  );
  const businessTripTotal = renderTripCalculatorTotal(tripCalculatorSummary);

  const submitSummary = async () => {
    const body = getFormattedPostData(calculatorFormValues);
    if (isEditing) {
      const { ok } = await putFetch({
        url: `${businessTripsUrl}/${calculatorFormValues?.id}/summary`,
        body: body,
        intl,
        label: 'BUSINESS_TRIP.CALCULATOR_SUMMARY_UPDATE',
      });

      if (ok) {
        await fetchTrips();
        handleToggleCalculator();
      }
    } else {
      const { ok } = await postFetch({
        url: `${businessTripsUrl}/${calculatorFormValues?.id}/summary`,
        body: body,
        intl,
        label: 'BUSINESS_TRIP.CALCULATOR_SUMMARY',
      });

      if (ok) {
        await fetchTrips();
        handleToggleCalculator();
      }
    }
  };

  const onGeneratePDF = useCallback(async () => {
    const res = await fetch(
      `${businessTripsUrl}/${calculatorFormValues?.id}/summary/pdf`,
      {
        method: 'GET',
        headers: generateHeaders(localStorage.getItem('tpToken')),
      },
    );
    if (res.ok) {
      const blob = await res.blob();
      exportFile(
        blob,
        `Business_trip_${
          calculatorFormValues?.country
        }_${calculatorFormValues?.actualStartTime.slice(0, 10)}.pdf`,
      );
    } else {
      toast.error(intl?.formatMessage({ id: `DOWNLOAD_PDF.TOAST_DENIED` }));
    }
  }, [
    calculatorFormValues?.actualStartTime,
    calculatorFormValues?.country,
    calculatorFormValues?.id,
    intl,
  ]);

  return (
    <CalculatorContainer>
      <SummarySection
        businessTripSectionData={businessTripDescription}
        title="BUSINESS_TRIP.CALCULATOR_DESCRIPTION"
      />
      <SummarySection
        businessTripSectionData={businessTripCosts}
        title="BUSINESS_TRIP.CALCULATOR_TRAVEL_EXPENSES"
      />
      {calculatorFormValues?.foreignBusinessTrip && (
        <SummarySection
          businessTripSectionData={businessTripCostsForeign}
          title="BUSINESS_TRIP.CALCULATOR_TRAVEL_EXPENSES_FOREIGN"
          data={tripCalculatorSummary?.rate?.sourceCurrency}
        />
      )}
      <SummarySection
        businessTripSectionData={businessTripTotal}
        title="BUSINESS_TRIP.CALCULATOR_SUMMARY_COSTS"
      />
      {isVisiblePDF && (
        <Grid container xs={12} justifyContent="space-between">
          <Grid item xs={3}>
            <ContainedButton
              id="cancelButton"
              fullWidth
              onClick={handleToggleCalculator}
              size="large"
              color="secondary"
              type="submit"
            >
              {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
            </ContainedButton>
          </Grid>
          <Grid item xs={3}>
            <ContainedButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={onGeneratePDF}
              endIcon={<DownloadFileIcon />}
              id="GeneratePDF"
            >
              {intl.formatMessage({ id: 'DOWNLOAD_PDF' })}
            </ContainedButton>
          </Grid>
        </Grid>
      )}
      {!isAccounting && (
        <Grid container justify="space-between" xs={12}>
          <Grid item>
            <ContainedButton
              onClick={() => dispatch({ type: ActionKind.RenderCalculator })}
              color="secondary"
            >
              {intl.formatMessage({
                id: 'BUSINESS_TRIP.CALCULATOR',
              })}
            </ContainedButton>
          </Grid>
          {!isEmptyCalculation && (
            <Grid item>
              <ContainedButton onClick={submitSummary}>
                {intl.formatMessage({
                  id: 'BUSINESS_TRIP.SAVE_SUMMARY',
                })}
              </ContainedButton>
            </Grid>
          )}
        </Grid>
      )}
    </CalculatorContainer>
  );
};

export default TripCalculatorSummary;
