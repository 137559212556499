import { GroupedFilesLists } from 'components/Dialog/types';
import { FetchedFile } from 'components/Table/types';
import { RoleTypes } from 'utils/rolesTypes';

export const groupFilesByUploader = (
  alreadyUploadedFiles: FetchedFile[],
  nameAndSurname: string,
) =>
  alreadyUploadedFiles.reduce(
    (result, file: FetchedFile) => {
      if (file.addedByUserWithRole === RoleTypes.RoleEmployee) {
        file.uploadedBy === nameAndSurname
          ? result.uploadedByMe.push(file)
          : result.uploadedByEmployee.push(file);
      } else if (
        file.addedByUserWithRole === RoleTypes.RoleAdministration &&
        file.visibleForUserWithRole === RoleTypes.RoleEmployee
      ) {
        result.uploadedByAdministrationForEmployee.push(file);
      }
      if (file.addedByUserWithRole === RoleTypes.RoleAdministration) {
        result.uploadedByAdministration.push(file);
      }
      return result;
    },
    {
      uploadedByMe: [],
      uploadedByEmployee: [],
      uploadedByAdministration: [],
      uploadedByAdministrationForEmployee: [],
    } as GroupedFilesLists,
  );
