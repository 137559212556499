import { IntlShape } from 'react-intl';
import moment from 'moment';
import { StepTitlesData } from 'components/ControlledStepper/types';
import { Data } from 'components/Dialog/types';
import {
  BusinessTripFormValues,
  SelectDataForm,
  TransportationTypes,
} from './types';

export const getStepTitles = (intl: IntlShape): StepTitlesData[] => [
  {
    label: intl.formatMessage({
      id: 'BUSINESS_TRIP.DIALOG_SECTION.GENERAL_INFO',
    }),
    id: 'descriptionLabel',
  },
  {
    label: intl.formatMessage({ id: 'BUSINESS_TRIP.DIALOG_SECTION.TRAVEL' }),
    id: 'travelLabel',
  },
  {
    label: intl.formatMessage({
      id: 'BUSINESS_TRIP.DIALOG_SECTION.ACCOMMODATION',
    }),
    id: 'accommodationLabel',
  },
  {
    label: intl.formatMessage({
      id: 'BUSINESS_TRIP.DIALOG_SECTION.OTHER',
    }),
    id: 'otherLabel',
  },
  {
    label: intl.formatMessage({ id: 'BUSINESS_TRIP.DIALOG_SECTION.SUMMARY' }),
    id: 'summaryLabel',
  },
];

export const renderBusinessTripGeneralInfoData = (
  formState: BusinessTripFormValues,
) => [
  { name: 'BUSINESS_TRIP.TITLE', content: formState.tripReason },
  { name: 'BUSINESS_TRIP.COUNTRY*', content: formState.country },
  { name: 'BUSINESS_TRIP.CITY', content: formState.city },
  { name: 'BUSINESS_TRIP.CLIENT', content: formState.client?.name },
  { name: 'BUSINESS_TRIP.PROJECT', content: formState.project?.name },
  {
    name: 'BUSINESS_TRIP.TRIP_START',
    content: moment(formState.startTime).format('DD-MM-YYYY HH:mm'),
  },
  {
    name: 'BUSINESS_TRIP.TRIP_END',
    content: moment(formState.endTime).format('DD-MM-YYYY HH:mm'),
  },
];

export const renderBusinessTripTravelData = (
  formState: BusinessTripFormValues,
) => [
  {
    name: 'BUSINESS_TRIP.TRANSPORTATION',
    content: formState.transportation?.name,
  },
  { name: 'BUSINESS_TRIP.TRAVEL_FROM', content: formState.travelFrom },
  { name: 'BUSINESS_TRIP.TRAVEL_TO', content: formState.travelTo },
  {
    name: 'BUSINESS_TRIP.TRAVEL_RESERVATION',
    content: formState.travelReservationBy?.name,
  },
  {
    name: 'BUSINESS_TRIP.RESERVATION_LINK',
    content: formState.travelReservationLink,
  },
  {
    name: 'BUSINESS_TRIP.TRAVEL_RESERVATION_INFORMATION',
    content: formState.reservationInformation,
  },
];

export const renderBusinessTripAccommodationData = (
  formState: BusinessTripFormValues,
) => [
  { name: 'BUSINESS_TRIP.NIGHTS_COUNT', content: formState.numberOfNights },
  { name: 'BUSINESS_TRIP.PLACE_TO_STAY', content: formState.placeToStay },
  {
    name: 'BUSINESS_TRIP.ESTIMATED_COST',
    content: formState.estimateCostsOfStay
      ? `${formState.estimateCostsOfStay} ${formState.currency?.name}`
      : null,
  },
  {
    name: 'BUSINESS_TRIP.ACCOMMODATION_RESERVATION',
    content: formState.accommodationReservationBy?.name,
  },
  {
    name: 'BUSINESS_TRIP.RESERVATION_LINK',
    content: formState.accommodationReservationLink,
  },
  {
    name: 'BUSINESS_TRIP.ACCOMMODATION_RESERVATION_INFORMATION',
    content: formState.accommodationInformation,
  },
];

export const renderBusinessTripOtherData = (
  formState: BusinessTripFormValues,
) => [
  {
    name: 'BUSINESS_TRIP.INSURANCE',
    content: formState.insuranceProvidedBy?.name,
  },
  {
    name: 'BUSINESS_TRIP.ACCORDION.ADVANCE',
    content: formState.advanceDescription,
  },
  { name: 'BUSINESS_TRIP.OTHER', content: formState.otherInformation },
];

export const renderSelectOptions = <T>(
  options: SelectDataForm<T>[],
  intl: IntlShape,
) =>
  options.map(({ value, name }) => ({
    value,
    name: intl.formatMessage({ id: name }),
  }));

export const checkNumberOfNights = (startTime: Date, endTime: Date) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);
  if (startDate && endDate) {
    return endDate.diff(startDate, 'days');
  }
  return 0;
};

export const renderErrorMessageForRequiredFields = (intl: IntlShape) =>
  intl.formatMessage({
    id: 'VALIDATION.REQUIRED',
  });

export const getFormattedPostData = (
  formState: BusinessTripFormValues,
  personalData: any,
  addedEmployees: Data[],
  acceptedByExternalLeader: boolean,
) => {
  const isAccommodation = Number(formState.numberOfNights) > 0;
  const isPersonalCar =
    formState.transportation?.value === TransportationTypes.PERSONAL_CAR;
  return {
    ...personalData,
    ...formState,
    country: formState.country?.engName,
    countryDietId: formState.country?.id,
    client: formState.client?.name,
    project: formState.project?.name || null,
    transportation: [formState.transportation?.value],
    travelReservationBy: isPersonalCar
      ? null
      : formState.travelReservationBy?.value,
    reservationInformation: isPersonalCar
      ? null
      : formState.reservationInformation || null,
    placeToStay: isAccommodation ? formState.placeToStay : null,
    estimateCostsOfStay: isAccommodation ? formState.estimateCostsOfStay : null,
    currency: isAccommodation ? formState.currency?.name : undefined,
    accommodationReservationBy: isAccommodation
      ? formState.accommodationReservationBy?.value
      : null,
    accommodationInformation: isAccommodation
      ? formState.accommodationInformation || null
      : null,
    insuranceProvidedBy: formState.insuranceProvidedBy?.value ?? 'NO_INSURANCE',
    acceptedByExternalLeader: acceptedByExternalLeader,
    additionalAccounts: addedEmployees.map((employee) => {
      return {
        id: employee.value,
      };
    }),
  };
};
