import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Block } from '@material-ui/icons';
import { DateContext } from 'globalContext/DateContext';
import { UserContext } from 'globalContext/UserContext';
import {
  businessMonthYearUrl,
  clientsUrl,
  employeesUrl,
  projectsUrl,
  userProfileUrl,
} from 'router/url';
import ClientDialog from 'components/Dialog/ClientDialog';
import MenuBar from 'components/MenuBar';
import FullReport from 'components/Table/FullReport';
import SummaryTable from 'components/Table/SummaryTable';
import { AccountUser } from 'components/Table/types';
import { ContractTypes } from 'modules/BusinessTrips/Dialog/types';
import { AccountResponse } from 'modules/UserProfile/types';
import QuickReportTable from 'modules/WorkTime/Screens/QuickReport';
import { getFetch } from 'utils/fetchFunctions';
import { renameKeys } from 'utils/helpers/renameKeys';
import { useToggleState } from 'utils/hooks/useToggleState';
import { RoleTypes } from 'utils/rolesTypes';
import HolidaysTab from './Screens/Holidays/HolidaysTab';
import { VIEW_MODES } from './consts';
import {
  TAB_LABELS,
  WORK_TIME_CUSTOM_PROJECTS_HEAD_DATA as tableCustomProjectsHeadData,
} from './static_data';
import {
  ClientData,
  ClientObject,
  ProjectData,
  ProjectObject,
  RouteParam,
  TimesheetData,
} from './types';
import { GridWithPointer, GridWrapper, SpinnerWrapper } from './WorkTime.css';

const WorkTime = () => {
  const intl = useIntl();
  const [viewMode, setViewMode] = useState(VIEW_MODES.FULLREPORT);
  const [addChecked, handleAddChecked] = useToggleState(false);
  const [clientsData, setClientsData] = useState<ClientData[]>();
  const [timesheetData, setTimesheetData] = useState<TimesheetData[]>();
  const [myTimesheetLoaded, setMyTimesheetLoaded] = useState(true);
  const { date, setDate } = useContext(DateContext);
  const { username, teamLeader, roles } = useContext(UserContext);
  const [projectsData, setProjectsData] = useState<ProjectData[]>([]);
  const [isUserFetched, setIsUserFetched] = useState(false);
  const [isFormedChanged, setIsFormedChanged] = useState(false);

  const { username: urlUsername } = useParams<RouteParam>();

  const [userContract, setUserContract] = useState<ContractTypes | null>(null);
  const [selectedUser, setSelectedUser] = useState<TimesheetData>();
  const [timesheetUsername, setTimesheetUsername] = useState(urlUsername);
  const [approveAndSendLoading, setApprovedAndSendLoading] = useState(false);

  const getTabLabels = () => {
    if (approveAndSendLoading) {
      return TAB_LABELS.map((el) =>
        el.key !== 2 ? { ...el, disabled: true } : el,
      );
    }
    return TAB_LABELS;
  };

  const isUserAuthorized = useMemo(() => {
    return (
      roles.includes(RoleTypes.RoleAccounting) ||
      roles.includes(RoleTypes.RoleDEVHR) ||
      teamLeader
    );
  }, [roles, teamLeader]);

  const fetchBusinessMonthYear = async () => {
    const monthYear = await getFetch({
      url: `${businessMonthYearUrl}`,
    });
    if (monthYear) {
      const { year, month } = monthYear;
      setDate(new Date(year, month - 1));
    }
  };

  const fetchClients = useCallback(async () => {
    if (!clientsData) {
      const clientsResponse = await getFetch({
        url: `${clientsUrl}?assignedToMe=true&active=true`,
      });
      if (clientsResponse) {
        setClientsData(
          renameKeys({
            filterOptionsArray: clientsResponse.map(
              ({ id, ...obj }: { id: number; obj: ClientObject }) => ({
                value: id,
                ...obj,
              }),
            ),
            oldKeyName: 'clientName',
            newKeyName: 'name',
          }),
        );
      }
    }
  }, [clientsData]);

  const fetchProjects = useCallback(async () => {
    if (!projectsData.length) {
      const projectsResponse = await getFetch({
        url: `${projectsUrl}?assignedToMe=true&active=true&includeSpecialAndCustom=true`,
      });

      if (projectsResponse) {
        const projectsRenamed = renameKeys({
          filterOptionsArray: projectsResponse.map(
            ({ id, ...obj }: { id: number; obj: ProjectObject }) => ({
              value: id,
              ...obj,
            }),
          ),
          oldKeyName: 'projectName',
          newKeyName: 'name',
        }) as unknown as ProjectData[];
        setProjectsData(projectsRenamed);
      }
    }
  }, [projectsData]);

  const fetchTimesheetUsers = useCallback(async () => {
    if (!timesheetData && isUserAuthorized) {
      const timesheetsResponse = await getFetch({
        url: `${employeesUrl}`,
      });
      const timesheetRenamed: TimesheetData[] = timesheetsResponse.map(
        (user: AccountUser) => ({
          value: user.id,
          name: `${user.name} ${user.surname}`,
          username: user.username,
        }),
      );
      setTimesheetData(
        timesheetRenamed.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [isUserAuthorized, timesheetData]);

  const findSelectedUser = useCallback(() => {
    if (timesheetUsername && isUserAuthorized && timesheetData?.length) {
      const filteredUsers = timesheetData.filter(
        (item: TimesheetData) => item.username === timesheetUsername,
      );
      if (filteredUsers[0].username === username) {
        setMyTimesheetLoaded(true);
      } else {
        setMyTimesheetLoaded(false);
      }
      setSelectedUser(filteredUsers[0]);
      setIsUserFetched(true);
    } else {
      setMyTimesheetLoaded(true);
      setIsUserFetched(true);
    }
  }, [isUserAuthorized, timesheetData, timesheetUsername, username]);

  const getUserData = useCallback(async () => {
    if (username) {
      const profile: AccountResponse = await getFetch({
        url: `${userProfileUrl}/${username}/profile`,
      });
      setUserContract(
        ContractTypes[profile.contractType as keyof typeof ContractTypes],
      );
    }
  }, [username]);

  const fetchClientsAndProjects = useCallback(() => {
    fetchClients();
    fetchProjects();
    // run once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchClients();
    fetchProjects();
    fetchTimesheetUsers();
    if (!date) {
      fetchBusinessMonthYear();
    }
    // run once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findSelectedUser();
  }, [findSelectedUser]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const renderEmptyDataInfo = useMemo(() => {
    return (
      <Grid container spacing={0} direction="column" alignItems="center">
        {!isUserFetched ? (
          <>
            <Grid item xs={6}>
              <Block />
            </Grid>
            <Grid item xs={5}>
              <p>
                {intl.formatMessage({
                  id: 'WORK_TIME_TAB.TIMESHEET_UNAVAILABLE',
                })}
              </p>
            </Grid>
            <GridWithPointer item xs={5}>
              <p onClick={() => window.location.reload()}>
                {intl.formatMessage({
                  id: 'WORK_TIME_TAB.TIMESHEET_RELOAD',
                })}
              </p>
            </GridWithPointer>
          </>
        ) : (
          <SpinnerWrapper>
            <CircularProgress size={100} />
          </SpinnerWrapper>
        )}
      </Grid>
    );
  }, [intl, isUserFetched]);

  const getContent = useCallback(
    (viewMode: VIEW_MODES) => {
      switch (viewMode) {
        case VIEW_MODES.QUICKREPORT:
          return (
            <>
              <GridWrapper
                container
                spacing={1}
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  {isUserFetched ? (
                    <QuickReportTable
                      fetchClientsAndProjects={fetchClientsAndProjects}
                      renderEmptyDataInfo={renderEmptyDataInfo}
                      timesheetUsername={timesheetUsername}
                      setTimesheetUsername={setTimesheetUsername}
                      date={date}
                      setDate={setDate}
                      timesheetData={timesheetData}
                      setSelectedUser={setSelectedUser}
                      setIsFormedChanged={setIsFormedChanged}
                      selectedUser={selectedUser}
                      myTimesheetLoaded={myTimesheetLoaded}
                      isUserAuthorized={isUserAuthorized}
                      userContract={userContract}
                    />
                  ) : (
                    renderEmptyDataInfo
                  )}
                </Grid>
              </GridWrapper>
            </>
          );
        case VIEW_MODES.FULLREPORT:
          return (
            <>
              <GridWrapper
                container
                spacing={1}
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  {isUserFetched ? (
                    <FullReport
                      headCustoms={tableCustomProjectsHeadData}
                      projectsData={projectsData}
                      fetchClientsAndProjects={fetchClientsAndProjects}
                      clientsData={clientsData}
                      renderEmptyDataInfo={renderEmptyDataInfo}
                      timesheetUsername={timesheetUsername}
                      setTimesheetUsername={setTimesheetUsername}
                      date={date}
                      setDate={setDate}
                      timesheetData={timesheetData}
                      setSelectedUser={setSelectedUser}
                      setIsFormedChanged={setIsFormedChanged}
                      selectedUser={selectedUser}
                      myTimesheetLoaded={myTimesheetLoaded}
                      isUserAuthorized={isUserAuthorized}
                      setIsUsersFetched={setIsUserFetched}
                      userContract={userContract}
                    />
                  ) : (
                    renderEmptyDataInfo
                  )}
                </Grid>
              </GridWrapper>
            </>
          );
        case VIEW_MODES.SUMMARY:
          return (
            <>
              <GridWrapper
                container
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  {isUserFetched ? (
                    <SummaryTable
                      renderEmptyDataInfo={renderEmptyDataInfo}
                      timesheetUsername={timesheetUsername}
                      setTimesheetUsername={setTimesheetUsername}
                      date={date}
                      setDate={setDate}
                      timesheetData={timesheetData}
                      setSelectedUser={setSelectedUser}
                      setIsFormedChanged={setIsFormedChanged}
                      selectedUser={selectedUser}
                      myTimesheetLoaded={myTimesheetLoaded}
                      isUserAuthorized={isUserAuthorized}
                      setApprovedAndSendLoading={setApprovedAndSendLoading}
                      userContract={userContract}
                    />
                  ) : (
                    renderEmptyDataInfo
                  )}
                </Grid>
              </GridWrapper>
              {addChecked && (
                <ClientDialog
                  addChecked={addChecked}
                  handleAddChanged={handleAddChecked}
                />
              )}
            </>
          );
        case VIEW_MODES.HOLIDAYS:
          return (
            <GridWrapper
              container
              spacing={1}
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                {isUserFetched ? <HolidaysTab /> : renderEmptyDataInfo}
              </Grid>
            </GridWrapper>
          );
      }
    },
    [
      isUserFetched,
      fetchClientsAndProjects,
      renderEmptyDataInfo,
      timesheetUsername,
      date,
      timesheetData,
      selectedUser,
      myTimesheetLoaded,
      isUserAuthorized,
      userContract,
      projectsData,
      clientsData,
      addChecked,
      handleAddChecked,
    ],
  );

  const loading = useMemo(() => {
    if (isUserAuthorized) {
      return !timesheetData || !clientsData || !projectsData || !username;
    }
    return !clientsData || !projectsData || !username;
  }, [clientsData, projectsData, timesheetData, username, isUserAuthorized]);

  return (
    <>
      <MenuBar
        value={viewMode}
        setValue={setViewMode}
        options={getTabLabels()}
        confirmCondition={isFormedChanged}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <Box overflow="auto" padding="2rem" minHeight="100%">
          {getContent(viewMode)}
        </Box>
      )}
    </>
  );
};

export default memo(WorkTime);
