import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import {
  ActivateIcon,
  AddIcon,
  DeactivateIcon,
  DownloadIcon,
  EditIcon,
  HeaderTooltipText,
  RemoveIcon,
  TableIconButton,
  Tooltip,
  TrashIcon,
} from 'components/Table/Table.css';
import { IconWithTooltipProps } from './types';

const IconWithTooltip = ({
  icon,
  handleClick,
  disabled,
  tooltipText,
  title,
}: IconWithTooltipProps) => {
  const intl = useIntl();
  let label, content;

  switch (icon) {
    case EditIcon:
      label = 'EDIT';
      content = <EditIcon />;
      break;
    case DownloadIcon:
      label = 'DOWNLOAD';
      content = <DownloadIcon />;
      break;
    case TrashIcon:
      label = 'REMOVE';
      content = <TrashIcon />;
      break;
    case DeactivateIcon:
      label = 'DEACTIVATE';
      content = <DeactivateIcon />;
      break;
    case ActivateIcon:
      label = 'ACTIVATE';
      content = <ActivateIcon />;
      break;
    case AddIcon:
      label = 'ADD';
      content = <AddIcon />;
      break;
    case RemoveIcon:
      label = 'REMOVE';
      content = <RemoveIcon />;
      break;
  }

  const id = `${label?.toLowerCase()}Button`;

  return (
    <Grid item xs={12}>
      <Tooltip
        title={
          <HeaderTooltipText>
            {title ||
              intl.formatMessage({
                id: `BENEFITS.${tooltipText || label}_ACTION`,
              })}
          </HeaderTooltipText>
        }
        placement="top"
      >
        <span>
          <TableIconButton onClick={handleClick} disabled={disabled} id={id}>
            {content}
          </TableIconButton>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default IconWithTooltip;
