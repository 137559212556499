import { DeepMap, FieldError } from 'react-hook-form';
import {
  BenefitManagerFormValues,
  BenefitTypesProps,
  ChildProperties,
  CostProperties,
  ErrorProperties,
  FormValues,
  Packages,
  Partner,
  PartnerProperties,
  PersonalData,
  PersonCosts,
  TimeRangeData,
  TimeRangeTypes,
} from './types';

export const checkTimeRangeErrors = (
  errors: DeepMap<FormValues, FieldError>,
  name: keyof FormValues,
) => {
  const [first, second] = name.split('.');
  return errors[first as TimeRangeTypes]?.[second as keyof TimeRangeData];
};

export const checkPartnerErrors = (
  errors: DeepMap<FormValues, FieldError>,
  name: keyof FormValues,
  index?: number,
) =>
  errors.partners?.map((el) => el?.[name.split('.')[1] as keyof Partner])[
    index ?? 0
  ];

export const checkEmployeeErrors = (
  errors: DeepMap<FormValues, FieldError>,
  name: keyof FormValues,
) => errors.employee?.[name.split('.')[1] as keyof PersonalData] as FieldError;

type AddressComponent =
  | 'city'
  | 'zipCode'
  | 'street'
  | 'houseNumber'
  | 'apartmentNumber';

export const checkAddressErrors = (
  errors: DeepMap<FormValues, FieldError>,
  name: keyof FormValues,
  addressType?: 'address' | 'crspAddress',
  index?: number,
) => {
  if (index !== undefined && addressType) {
    return errors.partners?.map(
      (el) => el?.[addressType]?.[name.split('.')[2] as AddressComponent],
    )[index ?? 0];
  }
  return (
    addressType &&
    errors?.employee?.[addressType]?.[name.split('.')[1] as AddressComponent]
  );
};

export const checkBenefitTypeErrors = (
  errors: DeepMap<FormValues, FieldError>,
  name: keyof FormValues,
  index?: number,
) => {
  const isPackage =
    name.includes(Packages.individual) || name.includes(Packages.partner);

  const isFamilyPackage = name.includes(Packages.family);

  const isCost =
    name.includes(PersonCosts.employee) || name.includes(PersonCosts.partner);

  const isChildCost = name.includes(PersonCosts.child);

  const benefitTypeProperty = name.split('.')[1];
  const costProperty = name.split('.')[2];

  if (isPackage) {
    return errors.benefitTypes?.map(
      (entry) =>
        entry?.[benefitTypeProperty as Packages]?.[
          costProperty as CostProperties
        ],
    )[index ?? 0];
  }

  if (isFamilyPackage) {
    return errors.benefitTypes?.map(
      (entry) =>
        entry?.[benefitTypeProperty as Packages.family]?.[
          costProperty as ChildProperties
        ],
    )[index ?? 0];
  }

  if (isCost) {
    return errors.benefitTypes?.map(
      (entry) =>
        entry?.[benefitTypeProperty as PersonCosts]?.[
          costProperty as CostProperties
        ],
    )[index ?? 0];
  }

  if (isChildCost) {
    return errors.benefitTypes?.map(
      (entry) =>
        entry?.[benefitTypeProperty as PersonCosts.child]?.[
          costProperty as ChildProperties
        ],
    )[index ?? 0];
  }

  return errors.benefitTypes?.map(
    (entry) => entry?.[name.split('.')[1] as keyof BenefitTypesProps],
  )[index ?? 0];
};

export const getBenefitTypePropertyInput = (
  name:
    | keyof BenefitTypesProps
    | CostProperties
    | ChildProperties
    | PartnerProperties,
  index?: number,
  property?: Packages | PersonCosts,
) => {
  if (property) {
    return `benefitTypes[${index}].${property}.${name}` as keyof BenefitManagerFormValues;
  } else {
    return `benefitTypes[${index}].${name}` as keyof BenefitManagerFormValues;
  }
};

export const getErrorProperty = (index?: number) =>
  index || index === 0 ? ErrorProperties.Partners : ErrorProperties.Employee;

export const getPropertyError = ({
  nameOfErrorProperty,
  partnersErrors,
  benefitTypesErrors,
  timeRangeErrors,
  addressErrors,
  employeeErrors,
}: {
  nameOfErrorProperty: ErrorProperties;
  partnersErrors?: FieldError;
  benefitTypesErrors?: FieldError;
  timeRangeErrors?: FieldError;
  addressErrors?: FieldError;
  employeeErrors?: FieldError;
}) => {
  switch (nameOfErrorProperty) {
    case ErrorProperties.Partners:
      return partnersErrors;
    case ErrorProperties.BenefitTypes:
      return benefitTypesErrors;
    case ErrorProperties.TimeRange:
      return timeRangeErrors;
    case ErrorProperties.Address:
      return addressErrors;
    case ErrorProperties.Employee:
      return employeeErrors;
    default:
      return undefined;
  }
};
