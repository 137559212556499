import { GridWithMargin } from 'components/Table/Table.css';
import Actions from './Actions';
import CostContainer from './CostContainer';
import General from './General';
import InputsContainer from './InputsContainer';

const Summary = () => {
  return (
    <GridWithMargin container>
      <General />
      <CostContainer />
      <InputsContainer inputType="employeeInputs" />
      <InputsContainer inputType="partnerInputs" />
      <InputsContainer inputType="childInputs" />
      <InputsContainer inputType="editableInputs" />
      <Actions />
    </GridWithMargin>
  );
};

export default Summary;
