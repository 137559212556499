import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { CloseIcon } from 'components/Dialog/Dialog.css';
import { RequestHolidaysDialogProps } from '../../types';
import LeaveRequestForm from './LeaveRequestForm';
import { DialogTitleWrapper, StyledDialog } from '../../HolidaysTab.css';

const HolidayRequestDialog = ({
  open,
  handleClose,
}: RequestHolidaysDialogProps) => {
  const intl = useIntl();

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitleWrapper>
        <Grid container alignItems="center" justify="space-between">
          <Grid xs={5} item>
            {intl.formatMessage({ id: 'LEAVE_REQUEST.TITLE' })}
          </Grid>
          <Grid xs={1} item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <LeaveRequestForm onClose={handleClose} />
    </StyledDialog>
  );
};

export default HolidayRequestDialog;
