import { useContext } from 'react';
import { useIntl } from 'react-intl';
import ControlledTextField from 'components/ControlledTextField';
import { GridItem, GridWrapperGapped } from 'components/Dialog/Dialog.css';
import { MAX_INPUT_LENGTH } from 'modules/BusinessTrips/static_data';
import { TripCalculatorSectionProps } from 'modules/BusinessTrips/types';
import { TripCalculatorContext } from '../TripCalculatorContext';
import { FormSectionHeading } from '../BusinessTripCalculator.css';

export const MealsSection = ({ control }: TripCalculatorSectionProps) => {
  const intl = useIntl();
  const {
    state: { calculatorFormValues },
  } = useContext(TripCalculatorContext);

  return (
    <>
      <GridItem xs={12} item>
        <FormSectionHeading>
          {intl.formatMessage({
            id: 'BUSINESS_TRIP.CALCULATOR_MEALS',
          })}
        </FormSectionHeading>
      </GridItem>
      <GridItem item xs={12}>
        <GridWrapperGapped container wrap="nowrap" justify="space-between">
          <GridItem xs={4}>
            <ControlledTextField
              name="numberOfBreakfasts"
              variant="outlined"
              control={control}
              isOnlyInteger
              label="BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_BREAKFASTS"
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={calculatorFormValues?.numberOfBreakfasts}
            />
          </GridItem>
          <GridItem xs={4}>
            <ControlledTextField
              name="numberOfDinners"
              variant="outlined"
              control={control}
              isOnlyInteger
              label="BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_DINNERS"
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={calculatorFormValues?.numberOfDinners}
            />
          </GridItem>
          <GridItem xs={4}>
            <ControlledTextField
              name="numberOfSuppers"
              variant="outlined"
              control={control}
              isOnlyInteger
              label="BUSINESS_TRIP.CALCULATOR_MEALS_NUMBER_OF_SUPPERS"
              maxLength={MAX_INPUT_LENGTH}
              defaultValue={calculatorFormValues?.numberOfSuppers}
            />
          </GridItem>
        </GridWrapperGapped>
      </GridItem>
    </>
  );
};

export default MealsSection;
