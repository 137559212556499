import Switch from '@material-ui/core/Switch';
import palette from 'assets/palette';
import styled from 'styled-components';

export const SwitchComponent = styled(Switch)`
  &.MuiTextField-root {
    padding-right: 0.5rem;
  }
  && .MuiInput-root:hover::before {
    border-color: ${palette.ACTION};
  }
  & .MuiOutlinedInput-root:hover fieldset {
    border: 2px solid ${palette.ACTION};
  }
`;
