import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { LeaveRequestsPaginationProps } from '../types';

const LeaveRequestsPagination = ({
  elementsCount,
  currentNumber,
  setCurrentPage,
  rowsPerPage,
}: LeaveRequestsPaginationProps) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        count={elementsCount}
        page={currentNumber}
        rowsPerPageOptions={[]}
        onPageChange={setCurrentPage}
        rowsPerPage={rowsPerPage}
        component="div"
      />
    </TableRow>
  </TableFooter>
);

export default LeaveRequestsPagination;
