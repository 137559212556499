import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { clientPermissionsUrl, clientsPagedUrl, clientsUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControllerSelect from 'components/ControlledSelect/ControlledSelect';
import ClientsTable from 'components/Table/ClientsTable';
import { ButtonWrapper } from 'components/Table/Table.css';
import { ClientProps } from 'components/Table/types';
import { BoxItem, BoxWrapper } from 'modules/Projects/Projects.css';
import { fetchClientList } from 'utils/commonFetches';
import { deleteFetch, getFetch, patchFetch } from 'utils/fetchFunctions';
import { ClientsData } from 'utils/helpers/renameKeys';
import { usePagination } from 'utils/hooks/usePagination';
import { useToggleState } from 'utils/hooks/useToggleState';
import { TIMEREPORTS_HEAD_DATA as tableHeadData } from './static_data';
import { FormData, FormProps } from './types';

const Clients = () => {
  const { control } = useForm<FormData>({
    defaultValues: {
      client: undefined,
    },
  });
  const client: FormProps = useWatch({ control, name: 'client' });
  const {
    debouncedPage,
    pagination,
    setRowsPerPage,
    setCurrentPage,
    setElementsCount,
  } = usePagination<FormProps>({
    initialPage: 0,
    initialRowsPerPage: 10,
    initialElementsCount: 10,
    debounceTime: 500,
    data: client,
  });

  const [tableData, setTableData] = useState<ClientProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addChecked, handleAddChanged] = useToggleState(false);
  const [clientsData, setClientsData] = useState<ClientsData[]>([]);
  const intl = useIntl();

  const [isAllowedForActions, setIsAllowedForActions] = useState(false);

  const returnQueryString = useCallback(
    (pageNumber: number, pageSize: number) =>
      `${clientsPagedUrl}?${new URLSearchParams({
        page: String(pageNumber),
        size: String(pageSize),
        ...(client?.name && { client: client.name }),
      })}`,
    [client],
  );
  const fetchClients = useCallback(async () => {
    setLoading(true);

    const clientResponse = await getFetch({
      url: returnQueryString(debouncedPage, pagination.pageSize),
    });
    setElementsCount(clientResponse?.totalElements);
    setTableData(clientResponse?.content);
    setLoading(false);
  }, [debouncedPage, pagination.pageSize, returnQueryString, setElementsCount]);

  const deleteCustomField = useCallback(
    async (id: number, url: string) => {
      setLoading(true);
      await deleteFetch({
        url: `${url}/${id}`,
        intl,
        label: 'CUSTOM_FIELDS_DELETE',
      });
      await fetchClients();
      setLoading(false);
    },
    [fetchClients, intl],
  );

  const changeClientStatus = useCallback(
    async (id: number) => {
      setLoading(true);
      await patchFetch({
        url: `${clientsUrl}/${id}`,
        intl,
        label: 'CLIENTS.STATUS_CHANGED',
      });
      await fetchClients();
      setLoading(false);
    },
    [fetchClients, intl],
  );

  const fetchPermissions = useCallback(async () => {
    const hasPermissions = await getFetch({
      url: clientPermissionsUrl,
    });
    setIsAllowedForActions(hasPermissions);
  }, []);

  useEffect(() => {
    fetchClients();
  }, [
    debouncedPage,
    pagination.pageSize,
    returnQueryString,
    setElementsCount,
    fetchClients,
  ]);

  useEffect(() => {
    fetchClientList(setClientsData);
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <BoxWrapper overflow="auto" marginTop="1.5rem" minHeight={'100%'}>
      <BoxItem>
        <Grid container spacing={2} justify="space-between">
          <Grid xs={12} sm={3} item>
            <ControllerSelect
              label="TABLE.HEAD.CLIENT"
              name="client"
              control={control}
              options={clientsData}
              isDisabled={loading}
            />
          </Grid>
          {isAllowedForActions && (
            <Grid xs={12} sm={6} item>
              <ButtonWrapper>
                <ContainedButton
                  id="addNewClientButton"
                  endIcon={<AddIcon />}
                  size="large"
                  onClick={handleAddChanged}
                  disabled={loading}
                >
                  {intl.formatMessage({ id: 'CLIENTS_DIALOG.ADD_NEW' })}
                </ContainedButton>
              </ButtonWrapper>
            </Grid>
          )}
        </Grid>
      </BoxItem>
      <ClientsTable
        fetchClients={fetchClients}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        addChecked={addChecked}
        handleAddChanged={handleAddChanged}
        pageCount={pagination.totalElements}
        currentPage={pagination.pageNumber}
        withPagination
        limit={pagination.pageSize}
        loading={loading}
        head={tableData?.length ? tableHeadData : []}
        data={tableData}
        client={client}
        deleteCustomField={deleteCustomField}
        changeClientStatus={changeClientStatus}
        isAllowedForActions={isAllowedForActions}
      />
    </BoxWrapper>
  );
};

export default Clients;
