import { useIntl } from 'react-intl';
import Select, { OnChangeValue, StylesConfig } from 'react-select';
import palette from 'assets/palette';
import { ReactSelectProps } from './types';

const generateCustomStyles = (isSmall = false, isHidden = false) => {
  const customStyles: StylesConfig = {
    container: () => ({
      paddingBottom: 5,
      visibility: isHidden ? 'hidden' : 'visible',
      marginTop: 4,
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: '150px',
      height: isSmall ? undefined : 56,
      border: state.isFocused
        ? `1px solid ${palette.ACTION}`
        : '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: state.isFocused
        ? `0 0 0 1px ${palette.ACTION}`
        : '1px solid #ddd',
      '&:hover': {
        border: state.isFocused
          ? `1px solid ${palette.ACTION}`
          : `1px solid rgba(0, 0, 0, 0.87)`,
      },
    }),
    placeholder: () => ({
      color: '#000',
      position: 'relative',
      top: '-15px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 11,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  return customStyles;
};
function ReactSelect<T>({
  name,
  options,
  label,
  isMulti,
  isDisabled,
  defaultValue,
  isSmall,
  isHidden,
  isClearable = true,
  handleChange,
}: ReactSelectProps<T>) {
  const intl = useIntl();

  return (
    <Select
      name={name}
      inputId={name}
      defaultValue={defaultValue}
      styles={generateCustomStyles(isSmall, isHidden)}
      getOptionLabel={(option: any) => option.name}
      noOptionsMessage={() =>
        intl.formatMessage({ id: 'TABLE_FILTER.NO_OPTION' })
      }
      placeholder={intl.formatMessage({ id: label })}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={isMulti}
      menuPortalTarget={document.body}
      options={options}
      onChange={(e) => handleChange(e as OnChangeValue<T, boolean>)}
    />
  );
}

export default ReactSelect;
