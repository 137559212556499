import CircularProgressComponent, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { ContainedButton } from 'components/Button';

export const StyledCircularProgress = styled(
  CircularProgressComponent,
)<CircularProgressProps>`
  && {
    margin: 0 7px;
  }
`;

export const CustomProjectsTable = styled.div`
  margin-top: 50px;
`;

export const CustomProjectsSaveButton = styled(ContainedButton)`
  && {
    margin-left: '1rem';
  }
`;

export const LoweredParagraphWithTooltip = styled.span`
  margin-left: -75px;
  position: relative;
`;
