import FormControlComponent from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SelectComponent from '@material-ui/core/Select';
import styled, { css } from 'styled-components';

interface SelectProps {
  $isSmall?: boolean;
}
export const Select = styled(SelectComponent)<SelectProps>`
  &.MuiInputBase-root {
    color: white;
    width: 100%;
    ${({ $isSmall }) =>
      $isSmall &&
      css`
        font-size: 0.8rem;
        font-weight: 500;
        font-family: inherit;
        text-transform: uppercase;
      `};
};
  }
  .MuiSelect-icon {
    color: white;
  }
`;

export const Label = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: inherit;
  }
`;

export const FormControl = styled(FormControlComponent)`
  &.MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;
  }
`;
