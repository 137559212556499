import { FetchedFile } from './../Table/types';

export interface DialogProps {
  addChecked: boolean;
  handleAddChanged: () => void;
  editClicked?: boolean;
  handleEditClicked?: () => void;
}

export interface Data {
  value: number;
  name: string;
}

export interface GroupedFilesLists {
  uploadedByMe: FetchedFile[];
  uploadedByEmployee: FetchedFile[];
  uploadedByAdministration: FetchedFile[];
  uploadedByAdministrationForEmployee: FetchedFile[];
}

export interface ClientData {
  id: number;
  clientName: string;
  description: string;
  status: string;
}

export interface ProjectData {
  project: string;
  client: string;
  addingUser: string;
  description: string;
  contactPerson: string;
  status: string;
  id: number;
  billable: boolean;
  isDeletable?: boolean;
  reportingCustomData?: boolean;
  reportingCustomDataType: string;
  projectCategory: string;
  multiplicator?: number;
  type?: ProjectType;
  typeId?: number;
}

interface ProjectType {
  value: string;
  name: string;
}

export interface FormDataProps {
  project?: {
    value: number;
    client?: {
      clientName: string;
      id: number;
      decription: string;
    };
  };
  client?: {
    clientName: string;
  };
}
export interface CalendarFormProps {
  title: string;
  description: string;
  exclude: boolean;
  client?: FetchedClientProps[];
  account?: FetchedAccountProps[];
}

export interface FetchedClientProps {
  value: number;
  name: string;
}

export interface FetchedAccountProps {
  value: number;
  name: string;
  surname: string;
  username: string;
}

export interface ForInvoiceProps {
  payRate: number | null;
  additionalExpenses: AdditionalExpense[];
  invoiceNumber: string;
}

export interface AdditionalExpense {
  name: string;
  value: number;
}

export type FreeDayType = 'all' | 'client' | 'account';

export enum ProjectCategories {
  standard = 'STANDARD',
  custom = 'CUSTOM',
  medicalLeave = 'MEDICAL_LEAVE',
  vacations = 'VACATION',
  dayOff = 'DAY_OFF',
}
