import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import { clientsUrl } from 'router/url';
import ContainedButton from 'components/Button/ContainedButton';
import ControlledTextField from 'components/ControlledTextField';
import { postFetch, putFetch } from 'utils/fetchFunctions';
import { ClientDialogData, ClientDialogProps } from './types';
import {
  CloseIcon,
  DialogActionWrapper,
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
  GridItem,
  GridWrapper,
} from '../Dialog.css';

const ClientDialog = ({
  fetchClients = () => {
    // intentional empty function
  },
  addChecked,
  handleAddChanged,
  editClicked,
  data,
}: ClientDialogProps) => {
  const { control, handleSubmit, formState, reset } = useForm<ClientDialogData>(
    {
      mode: 'onChange',
      defaultValues: {
        clientName: '',
        description: '',
        status: '',
        id: null,
      },
    },
  );

  const intl = useIntl();

  useEffect(() => {
    if (editClicked && data) {
      reset({
        clientName: data?.clientName,
        description: data?.description,
        status: 'active',
        id: data?.id,
      });
    }
  }, [data, editClicked, reset]);

  const onSubmit = handleSubmit(async (formData) => {
    const body = {
      clientName: formData?.clientName,
      description: formData?.description,
      status: formData?.status,
    };
    if (editClicked) {
      await putFetch({
        url: `${clientsUrl}/v2/${data?.id}`,
        body,
        intl,
        label: 'CLIENTS.EDIT',
      });
    } else {
      await postFetch({
        url: clientsUrl,
        body,
        intl,
        label: 'CLIENTS.ADD',
      });
    }

    fetchClients();

    handleAddChanged();
    reset({
      clientName: '',
      description: '',
      status: '',
      id: null,
    });
  });

  return (
    <DialogWrapper
      fullScreen={false}
      fullWidth
      maxWidth={'sm'}
      open={addChecked}
      onClose={handleAddChanged}
      style={{ textAlign: 'center' }}
    >
      <DialogTitleWrapper color="primary">
        <Grid container direction="row" alignItems="center">
          <Grid xs={12} sm={6} item container justify="flex-start">
            {intl.formatMessage({
              id: `CLIENT_DIALOG.${editClicked ? 'EDIT' : 'ADD_NEW'}`,
            })}
          </Grid>
          <Grid xs={12} sm={6} item container justify="flex-end">
            <IconButton
              id="closeButton"
              aria-label="close"
              onClick={handleAddChanged}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <form className="form" onSubmit={onSubmit}>
          <GridWrapper
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <GridItem xs={12} item>
              <ControlledTextField
                label="TABLE.HEAD.CLIENT"
                name="clientName"
                control={control}
                required={true}
                variant="outlined"
                isDisabled={data?.clientName === 'JIT Other'}
              />
            </GridItem>
            <GridItem xs={12} item>
              <ControlledTextField
                label="TABLE.HEAD.DESCRIPTION"
                name="description"
                control={control}
                variant="outlined"
                isMulti={true}
              />
            </GridItem>
          </GridWrapper>
        </form>
      </DialogContentWrapper>
      <DialogActionWrapper>
        <GridItem xs={12} item>
          <ContainedButton
            id="cancelButton"
            fullWidth
            onClick={handleAddChanged}
            size="large"
            color="secondary"
            type="submit"
          >
            {intl.formatMessage({ id: 'MODAL.BUTTON.CANCEL' })}
          </ContainedButton>
        </GridItem>
        <GridItem xs={12} item>
          <ContainedButton
            id="submitButton"
            disabled={!formState.isValid}
            fullWidth
            onClick={onSubmit}
            size="large"
            type="submit"
          >
            {intl.formatMessage({
              id: `CLIENTS.${editClicked ? 'EDIT' : 'SUBMIT'}_BUTTON`,
            })}
          </ContainedButton>
        </GridItem>
      </DialogActionWrapper>
    </DialogWrapper>
  );
};

export default ClientDialog;
