import { useIntl } from 'react-intl';
import { Box, Switch } from '@material-ui/core';
import { SwitchContainerProps } from './types';

const SwitchContainer = ({
  toggleSwitch,
  setToggleSwitch,
  label,
  name,
  style,
  disabled,
  color = 'primary',
  id,
}: SwitchContainerProps) => {
  const intl = useIntl();
  const handleChange = () => setToggleSwitch(!toggleSwitch);

  return (
    <Box style={style}>
      {intl.formatMessage({ id: label })}
      <Switch
        checked={toggleSwitch}
        color={color}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        id={id || name}
      />
    </Box>
  );
};

export default SwitchContainer;
