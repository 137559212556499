import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import BenefitSelectField from 'components/BenefitInputs/BenefitSelectField';
import { getHelperText } from 'utils/formValidation';
import { BenefitManagerContext } from '../../../BenefitManagerContext/Context';
import { AVAILABLE_INPUTS } from '../../../mock_data';
import { getFormattedName } from '../../../utils';
import { getInputOptions } from '../inputHelpers';
import { SelectFieldProps } from '../types';
import { InputSelectTitle } from '../../../BenefitsManager.css';

const SelectField = ({
  name,
  errors,
  control,
  rules,
  disabled,
}: SelectFieldProps) => {
  const intl = useIntl();
  const {
    benefitManagerState: { formState },
  } = useContext(BenefitManagerContext);

  const defaultValue = useMemo(
    () =>
      formState[name]?.length
        ? formState[name]?.map((input) => ({
            ...input,
            name: intl.formatMessage({
              id: `BENEFITS.FORM.${getFormattedName(input.type)}`,
            }),
          }))
        : AVAILABLE_INPUTS.filter(
            ({ type }) => type === 'firstName' || type === 'surname',
          ).map((input) => ({
            ...input,
            name: intl.formatMessage({
              id: `BENEFITS.FORM.${getFormattedName(input.type)}`,
            }),
          })),
    [formState, intl, name],
  );

  return (
    <>
      <InputSelectTitle>
        {intl.formatMessage({ id: `BENEFITS.FORM.${getFormattedName(name)}` })}
      </InputSelectTitle>
      <BenefitSelectField
        label={`${getFormattedName(name)}_SELECT`}
        name={name}
        options={getInputOptions(intl)}
        multiDefaultValue={defaultValue}
        errorMessage={getHelperText(intl).required}
        rules={rules}
        control={control}
        errors={errors}
        disabled={disabled}
        isMulti
        md={12}
      />
    </>
  );
};

export default SelectField;
